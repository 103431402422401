import { parseISO } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { useMemo } from 'react'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { type TranslationValues } from '~/i18n/types'
import { getTherapySessionDayAndMonthLong } from '~/utils/dates/getTherapySessionDayAndMonth'
import { getTherapySessionStartAt } from '~/utils/dates/getTherapySessionStartAt'
import { useChatMessage } from '../../hooks/useChatMessage'
import { CustomMessageWrapper } from './CustomMessageWrapper'

export const MessageRequestTherapySessionChange = () => {
  const group = useUserActingAsGroup()
  const { message } = useChatMessage<'SYSTEM_REQUEST_THERAPY_SESSION_CHANGE'>()

  const startAt = message.srns?.payload.startAt
  const therapistFullName = String(message.srns?.payload.therapistFullName)

  const values = useMemo((): TranslationValues => {
    if (!startAt) {
      return {}
    }

    if (group === 'patient') {
      return {
        therapistFullName,
        therapySessionsStartAtDay: pipe(startAt, parseISO, getTherapySessionDayAndMonthLong),
        therapySessionsStartAtTime: pipe(startAt, parseISO, getTherapySessionStartAt),
      }
    }

    return {
      therapySessionsStartAtDay: pipe(startAt, parseISO, getTherapySessionDayAndMonthLong),
    }
  }, [group, startAt, therapistFullName])

  if (!startAt || group === 'saas-therapist') {
    return null
  }

  return (
    <CustomMessageWrapper>
      <Flex pb={8}>
        <Text fontWeight="600" kind="caption">
          <Translation id={`systemMessage.${group}.requestTherapySessionChange.title`} />
        </Text>
      </Flex>

      <TranslationMarkdown
        id={`systemMessage.${group}.requestTherapySessionChange.body`}
        kind="caption"
        values={values}
      />
    </CustomMessageWrapper>
  )
}
