import { Flex } from 'cdk'
import { Link } from 'react-router-dom'
import { Link as RootLink } from 'react-router-dom-v5-compat'
import { Button, Divider, DrawerFooter, DrawerHeader, DrawerTitle, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { getRoute } from '~/utils/getRoute'
import { useManageSession } from '../hooks/useManageSession'
import { getManageSessionRoutes } from '../utils/getManageSessionRoutes'

export const RescheduleSessionAfterCancellationPolicyLimit = () => {
  const { session, sessionId, therapistId } = useManageSession()
  const trackClick = useTrackEventClick()

  return (
    <>
      <DrawerHeader>
        <DrawerTitle>
          <Translation id="therapySession.lessThan24Hours.edit.modal.title" />
        </DrawerTitle>
      </DrawerHeader>
      <TranslationMarkdown
        id="therapySession.lessThan24Hours.edit.modal.subtitle"
        pt={16}
        values={{ therapistFullName: String(session?.therapist?.fullName) }}
      />
      <DrawerFooter>
        <Flex $gap={12} $grow={1} $shrink={1}>
          <RootLink
            data-test-id="therapy-session-chat-button"
            onClick={() => {
              trackClick('therapy-session.go-to-professional-chat-for-rescheduling-after-cancellation-policy-limit', {
                therapistId,
                therapySessionId: sessionId,
              })
            }}
            to={getRoute(`/chat/${therapistId}`)}
          >
            <Button as="span" kind="primary">
              <Translation id="actions.open.chat" />
            </Button>
          </RootLink>
          <Divider colorName="neutral-40" />
          <Text colorName="neutral-70" textAlign="center">
            <Translation id="manageTherapySession.variant1.cannotReschedule" />
            &nbsp;
            <Link
              data-test-id="therapy-session-delete-link"
              to={
                session?.recurrency ? getManageSessionRoutes('/delete-recurrency') : getManageSessionRoutes('/delete')
              }
            >
              <Text kind="paragraph-strong">
                <Translation id="actions.erase" />
              </Text>
            </Link>
          </Text>
        </Flex>
      </DrawerFooter>
    </>
  )
}
