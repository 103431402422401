import { getSessionStorage } from '~/utils/sessionStorage/getSessionStorage'

type UtmSource = 'wellhub-srns' | 'multiversity-srns'

export const useIsUtmSource = (utmSource: UtmSource) => {
  const queryParam = getSessionStorage('query-params')

  if (queryParam == null) {
    return false
  }

  const parsedQueryParams = JSON.parse(queryParam)

  return parsedQueryParams?.utm_source === utmSource
}
