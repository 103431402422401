import { Flex } from 'cdk'
import {
  BORDER_RADIUS_MD,
  BORDER_WIDTH_1,
  COLOR_DARKER,
  COLOR_NEUTRAL_20,
  COLOR_PRIMARY_10,
  COLOR_PRIMARY_50,
  SPACING_3XS,
  SPACING_MD,
} from 'design-tokens'
import { Controller } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { Text } from 'ui'
import { useReactHookFormContext } from '~/domains/react-hook-form'

type LabelFlexProps = {
  $selected: boolean
}

const LabelFlex = styled(Flex)<LabelFlexProps>`
  color: ${COLOR_DARKER};
  cursor: pointer;

  ${({ $selected }) =>
    !$selected &&
    css`
      &:hover {
        background-color: ${COLOR_NEUTRAL_20};
      }
    `}

  ${({ $selected }) =>
    $selected &&
    css`
      border-color: ${COLOR_PRIMARY_50};
      background-color: ${COLOR_PRIMARY_10};
      color: ${COLOR_PRIMARY_50};
    `}
`

const HiddenRadio = styled.input`
  display: none;
`

type Props = {
  defaultValue?: string
  name: string
  text: string
  value: string
}

export const RadioChip = ({ defaultValue, name, text, value }: Props) => {
  const { control } = useReactHookFormContext()

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field }) => (
        <>
          <HiddenRadio {...field} id={`${name}-${value}`} type="radio" value={value} />

          <LabelFlex
            $align="center"
            $backgroundColorName="lighter"
            $borderColorName="neutral-40"
            $borderRadius={BORDER_RADIUS_MD}
            $borderSize={BORDER_WIDTH_1}
            $justify="center"
            $minHeight={32}
            $px={SPACING_MD}
            $py={SPACING_3XS}
            $selected={field.value === value}
            as="label"
            htmlFor={`${name}-${value}`}
          >
            <Text kind="paragraph" textAlign="center">
              {text}
            </Text>
          </LabelFlex>
        </>
      )}
    />
  )
}
