import { Flex, PositionAbsolute, PositionRelative } from 'cdk'
import { FONT_WEIGHT_500_VALUE, SPACING_XS, TIME_300 } from 'design-tokens'
import { CheckmarkFilledSolid, Icon, SerenisCalendarPlus } from 'icons'
import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { useIsNativeApp } from '~/domains/appNative/hooks/useIsNativeApp'
import { useReactNativePostMessage } from '~/domains/appNative/hooks/useReactNativePostMessage'
import { useToasts } from '~/hooks/useToasts'
import { useLazyGetTherapySessionIcs } from '../hooks/useGetTherapySessionIcs'
import { downloadCalendarEvent } from '../utils/downloadCalendarEvent'
import { getReservationRoutes } from '../utils/getReservationRoutes'

type PositionAbsoluteWithTransitionParams = {
  $show: boolean
}

const PositionAbsoluteWithTransition = styled(PositionAbsolute).attrs<PositionAbsoluteWithTransitionParams>(
  ({ $show }) => ({
    $direction: 'row',
    $grow: 1,
    $opacity: $show ? 1 : 0,
    $zIndex: $show ? 1 : -1,
  }),
)`
  transition: opacity ${TIME_300};
`

type Props = {
  therapySessionId: string
}

export const DownloadCalendarEventButton = ({ therapySessionId }: Props) => {
  const [showSuccess, setShowSuccess] = useState(false)
  const location = useLocation()

  const { addToast } = useToasts()
  const trackEventClick = useTrackEventClick()
  const lazyFetch = useLazyGetTherapySessionIcs()
  const isNativeApp = useIsNativeApp()
  const { downloadNativeIcsCalendar } = useReactNativePostMessage()

  const handleDownloadCalendarEvent = useCallback(async () => {
    const isThankYouPage = location.pathname.includes(getReservationRoutes('/thank-you-page'))
    if (isThankYouPage) {
      trackEventClick('thank-you-page.download-calendar-event', { therapySessionId })
    }

    const { data } = await lazyFetch({ variables: { id: therapySessionId } })
    const therapySessionIcs = data?.therapySessionIcs

    if (therapySessionIcs != null) {
      const { icsAsBase64, fileName } = therapySessionIcs

      if (isNativeApp) {
        downloadNativeIcsCalendar({ base64: icsAsBase64, fileName })
      } else {
        downloadCalendarEvent({ base64: icsAsBase64, fileName })
      }

      setShowSuccess(true)
      return
    }

    addToast({
      translationId: 'reservation.downloadCalendarEvent.toast.alert',
      type: 'alert',
    })
  }, [addToast, lazyFetch, location, therapySessionId, trackEventClick, isNativeApp, downloadNativeIcsCalendar])

  useEffect(() => {
    if (showSuccess) {
      const timeout = setTimeout(() => {
        setShowSuccess(false)
      }, 5000)
      return () => clearTimeout(timeout)
    }
  }, [showSuccess])

  return (
    <Flex>
      <PositionRelative $align="center" $minHeight={40}>
        <PositionAbsoluteWithTransition $show={!showSuccess}>
          <Button isGhost kind="primary" onClick={handleDownloadCalendarEvent}>
            <Flex $align="center" $direction="row" $gap={SPACING_XS}>
              <Icon Svg={SerenisCalendarPlus} size={24} />
              <Translation id="reservation.downloadCalendarEvent.cta" />
            </Flex>
          </Button>
        </PositionAbsoluteWithTransition>

        {showSuccess && (
          <PositionAbsoluteWithTransition $gap={SPACING_XS} $py={SPACING_XS} $show={showSuccess}>
            <Icon Svg={CheckmarkFilledSolid} colorName="white" fillColorName="green-60" size={24} />
            <Text colorName="green-60" fontWeight={FONT_WEIGHT_500_VALUE} kind="paragraph">
              <Translation id="reservation.downloadCalendarEvent.cta.success" />
            </Text>
          </PositionAbsoluteWithTransition>
        )}
      </PositionRelative>
      {showSuccess && (
        <TranslationMarkdown
          colorName="neutral-60"
          id="reservation.downloadCalendarEvent.description"
          kind="caption"
          textAlign="center"
        />
      )}
    </Flex>
  )
}
