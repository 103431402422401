import { type FoodJournalMealLocation, type FoodJournalMealMood } from '~/types/graphql'

export const mealLocationToEmoji: Record<FoodJournalMealLocation, string> = {
  HOME: '🏡',
  OFFICE: '👔',
  SCHOOL: '📚',
  GYM: '💪',
  RESTAURANT: '🍽️',
  BAR: '☕️',
  OTHER: '🧃',
}

export const mealMoodToEmoji: Record<FoodJournalMealMood, string> = {
  BAD: '🥺',
  JUST_OK: '😣',
  AVERAGE: '🙂',
  GOOD: '😊',
  VERY_GOOD: '🤩',
}
