import { Flex } from 'cdk'
import { format } from 'date-fns'
import { FormatDateEnum } from 'dates'
import { BORDER_RADIUS_3XS, SPACING_4XS, SPACING_LG, SPACING_MD, SPACING_SM, SPACING_XS } from 'design-tokens'
import styled from 'styled-components'
import { Card, DrawerProvider, ModalProvider, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { type TranslationId } from '~/i18n/types'
import { type FoodJournalMealHunger, type FoodJournalMealLocation, type FoodJournalMealMood } from '~/types/graphql'
import { mealLocationToEmoji, mealMoodToEmoji } from '../../constants'
import { ActionsDropdown } from './ActionsDropdown'

const hungerToTranslationId: { [key in FoodJournalMealHunger]: TranslationId } = {
  VERY_HUNGRY: 'nutrition.diary.mealDetails.hunger.veryHungry',
  MEDIUM_HUNGRY: 'nutrition.diary.mealDetails.hunger.mediumHungry',
  LITTLE_HUNGRY: 'nutrition.diary.mealDetails.hunger.littleHungry',
}

const Grid = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: repeat(3, auto);
  gap: ${SPACING_XS} ${SPACING_MD};
`

type Props = {
  id: string
  date: Date | null
  location: FoodJournalMealLocation
  description: string
  editable: boolean
  feelings: string | null
  hungerAfter: FoodJournalMealHunger | null
  hungerBefore: FoodJournalMealHunger | null
  moodAfter: FoodJournalMealMood | null
  moodBefore: FoodJournalMealMood | null
}

export const MealDetails = ({
  id,
  date,
  location,
  description,
  editable,
  feelings,
  hungerAfter,
  hungerBefore,
  moodAfter,
  moodBefore,
}: Props) => (
  <Card>
    <Flex $align="stretch" $direction="column" $gap={SPACING_LG} $justify="space-between" $lgDirection="row">
      <Flex $gap={SPACING_XS} $grow={1} $shrink={1}>
        <Flex $align="center" $direction="row" $gap={SPACING_XS}>
          {mealLocationToEmoji[location]}

          {date != null && (
            <>
              <Text colorName="darker" kind="paragraph-strong">
                •
              </Text>
              <Text colorName="darker" kind="paragraph-strong">
                {format(date, FormatDateEnum.HOURS_MINUTES)}
              </Text>
            </>
          )}
        </Flex>

        <Text colorName="darker" kind="paragraph">
          {description}
        </Text>

        {feelings != null && (
          <Flex $backgroundColorName="neutral-20" $borderRadius={BORDER_RADIUS_3XS} $gap={SPACING_4XS} $p={SPACING_SM}>
            <Text colorName="primary-50" kind="caption-strong">
              <Translation id="nutrition.diary.mealDetails.emotions" />
            </Text>
            <Text colorName="darker" fontStyle="italic" kind="caption">
              {feelings}
            </Text>
          </Flex>
        )}
      </Flex>

      <Flex $align="center" $direction="row" $gap={SPACING_LG} $grow={1} $justify="space-between" $lgGrow={0}>
        <Grid>
          <Flex $basis="10%">&nbsp;</Flex>

          <Text colorName="neutral-80" kind="caption">
            <Translation id="nutrition.diary.mealDetails.beforeEating" />
          </Text>

          <Text colorName="neutral-80" kind="caption">
            <Translation id="nutrition.diary.mealDetails.afterEating" />
          </Text>

          <Text colorName="neutral-80" kind="caption">
            <Translation id="nutrition.diary.mealDetails.hunger" />
          </Text>

          <Text colorName="darker" kind="caption">
            {hungerBefore != null ? <Translation id={hungerToTranslationId[hungerBefore]} /> : '-'}
          </Text>

          <Text colorName="darker" kind="caption">
            {hungerAfter != null ? <Translation id={hungerToTranslationId[hungerAfter]} /> : '-'}
          </Text>

          <Text colorName="neutral-80" kind="caption">
            <Translation id="nutrition.diary.mealDetails.mood" />
          </Text>

          <Text colorName="darker" kind="caption">
            {moodBefore != null ? mealMoodToEmoji[moodBefore] : '-'}
          </Text>

          <Text colorName="darker" kind="caption">
            {moodAfter != null ? mealMoodToEmoji[moodAfter] : '-'}
          </Text>
        </Grid>

        {editable && (
          <DrawerProvider>
            <ModalProvider>
              <ActionsDropdown mealId={id} />
            </ModalProvider>
          </DrawerProvider>
        )}
      </Flex>
    </Flex>
  </Card>
)
