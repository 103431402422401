import { Form } from 'cdk'
import { isValid } from 'date-fns'
import { useCallback, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { GLOBAL_VALUE_SEPARATOR } from '~/constants'
import { ReactHookFormProvider, useReactHookForm } from '~/domains/react-hook-form'
import { useToasts } from '~/hooks/useToasts'
import { useManageSession } from '../hooks/useManageSession'
import { type ManageSessionDeleteFormValues } from '../types'
import { getManageSessionRoutes } from '../utils/getManageSessionRoutes'
import { DeleteSession } from './DeleteSession'
import { DeleteSessionChurn } from './DeleteSessionChurn'
import { DeleteSessionMessage } from './DeleteSessionMessage'
import { DeleteSessionRecurrency } from './DeleteSessionRecurrency'
import { RescheduleSessionAfterCancellationPolicyLimit } from './RescheduleSessionAfterCancellationPolicyLimit'
import { RescheduleSessionMessage } from './RescheduleSessionMessage'
import { RescheduleSessionSlots } from './RescheduleSessionSlots'
import { RescheduleSessionSummary } from './RescheduleSessionSummary'

type RescheduleFormValues = {
  selectedTimeSlotWithTherapist: string | null
  message: string
}

export const ManageSessionRoutes = () => {
  const { addToast } = useToasts()
  const { deleteRecurrencySession, deleteSession, editSession } = useManageSession()
  const rescheduleForm = useReactHookForm<RescheduleFormValues>({
    defaultValues: {
      selectedTimeSlotWithTherapist: null,
    },
  })

  const form = useReactHookForm<ManageSessionDeleteFormValues>({
    defaultValues: {
      churnReason: undefined,
      deletedReason: undefined,
      deletedType: 'DELETE_SINGLE_THERAPY_SESSION',
      message: '',
    },
  })

  const handleDeleteSubmit = useCallback(
    ({ ...values }: ManageSessionDeleteFormValues) =>
      values.deletedType === 'DELETE_SINGLE_THERAPY_SESSION'
        ? deleteSession({ ...values })
        : deleteRecurrencySession({ ...values }),
    [deleteRecurrencySession, deleteSession],
  )

  useEffect(() => {
    rescheduleForm.register('selectedTimeSlotWithTherapist', { required: true })
    rescheduleForm.trigger('selectedTimeSlotWithTherapist')
  }, [rescheduleForm])

  const handleRescheduleSubmit = useCallback(
    ({ message, selectedTimeSlotWithTherapist }: RescheduleFormValues) => {
      if (!selectedTimeSlotWithTherapist) {
        addToast({ translationId: 'therapySession.alert.pickOneSlot', type: 'alert' })
        return
      }

      const [slot] = selectedTimeSlotWithTherapist.split(GLOBAL_VALUE_SEPARATOR)

      const startAt = new Date(slot)

      if (!isValid(startAt)) {
        return
      }

      editSession({ nextRoute: getManageSessionRoutes('/reschedule-summary'), startAt, message })
    },
    [addToast, editSession],
  )

  return (
    <>
      <ReactHookFormProvider {...form}>
        <Route exact path={getManageSessionRoutes('/delete-recurrency')}>
          <DeleteSessionRecurrency />
        </Route>
        <Route exact path={getManageSessionRoutes('/delete')}>
          <DeleteSession />
        </Route>
        <Route exact path={getManageSessionRoutes('/delete-churn')}>
          <DeleteSessionChurn />
        </Route>
        <Route exact path={getManageSessionRoutes('/delete-message')}>
          <Form $grow={1} $shrink={1} onSubmit={form.handleSubmit(handleDeleteSubmit)}>
            <DeleteSessionMessage />
          </Form>
        </Route>
      </ReactHookFormProvider>
      <ReactHookFormProvider {...rescheduleForm}>
        <Form $grow={1} $shrink={1} onSubmit={rescheduleForm.handleSubmit(handleRescheduleSubmit)}>
          <Route exact path={getManageSessionRoutes('/reschedule-session')}>
            <RescheduleSessionSlots />
          </Route>
          <Route exact path={getManageSessionRoutes('/reschedule-motivation')}>
            <RescheduleSessionMessage />
          </Route>
          <Route exact path={getManageSessionRoutes('/reschedule-summary')}>
            <RescheduleSessionSummary />
          </Route>
          <Route exact path={getManageSessionRoutes('/reschedule-after-cancellation-policy-limit')}>
            <RescheduleSessionAfterCancellationPolicyLimit />
          </Route>
        </Form>
      </ReactHookFormProvider>
    </>
  )
}
