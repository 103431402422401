import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { CarouselProvider } from '~/components/Carousel'
import { usePatientTherapiesQuery } from '~/domains/patient/hooks/usePatientTherapiesQuery'
import { getRoute } from '~/utils/getRoute'
import { type Page } from '../types'

export const PathsProvider: Page['PageProvider'] = ({ children }) => {
  const history = useHistory()
  const { therapies, loading, error } = usePatientTherapiesQuery()
  const length = Math.max(therapies.length + 1, 1)

  const handleOnSetCurrent = useCallback(
    (index: number) => {
      if (index === length - 1 && !loading && !error) {
        history.replace(getRoute('/paths/new'))

        return
      }

      const therapy = therapies[index]

      if (therapy != null) {
        history.replace(getRoute(`/paths/${therapy.id}`))

        return
      }
    },
    [error, history, length, loading, therapies],
  )

  return (
    <CarouselProvider length={length} onSetCurrent={handleOnSetCurrent}>
      {children}
    </CarouselProvider>
  )
}
