import { gql, useLazyQuery, useQuery, type WatchQueryFetchPolicy } from '@apollo/client'
import { type JournalingLogQuery, type JournalingLogQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query journalingLog($input: JournalingLogQueryInput!) {
    journalingLog(input: $input) {
      date
      id
      sharedWithTherapistUserId
      journalingActivities {
        id
        name
        slug
      }
      journalingMoods {
        id
        name
        slug
      }
      score
      sharedWithTherapistUserIds
      thankfulReasons
      todayThoughts
    }
  }
`

export const useJournalingLogById = (id: string, fetchPolicy: WatchQueryFetchPolicy = 'cache-first') => {
  const { data, error, loading, refetch } = useQuery<JournalingLogQuery, JournalingLogQueryVariables>(QUERY, {
    fetchPolicy,
    variables: { input: { id } },
  })

  return { error: error != null, loading, journalingLog: data?.journalingLog ?? null, refetch }
}

export const useLazyJournalingLogById = () => {
  const [getJournalingLogById] = useLazyQuery<JournalingLogQuery, JournalingLogQueryVariables>(QUERY)

  return { getJournalingLogById }
}
