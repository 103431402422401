import { useCallback, useState } from 'react'
import { useIsNativeApp } from '~/domains/appNative/hooks/useIsNativeApp'
import { useReactNativePostMessage } from '~/domains/appNative/hooks/useReactNativePostMessage'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'

export const useDownloadFile = () => {
  const [isDownloading, setIsDownloading] = useState(false)
  const { idTokenJwt } = useAuthState()
  const isNativeApp = useIsNativeApp()

  const { downloadNativeFile } = useReactNativePostMessage()

  const download = useCallback(
    (downloadUrl: string) => {
      if (downloadUrl == null || downloadUrl === '' || idTokenJwt == null) {
        return
      }

      setIsDownloading(true)
      const url = `${downloadUrl}?token=${idTokenJwt}`

      if (isNativeApp) {
        downloadNativeFile({
          url,
        })
      } else {
        window.open(url)
      }

      setIsDownloading(false)
    },
    [idTokenJwt, isNativeApp, downloadNativeFile],
  )

  return {
    isDownloading,
    download,
  }
}
