import { useCallback } from 'react'
import { Suggestion, Text, useModalContext } from 'ui'
import { Button, Flex, Form, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { ReactHookFormProvider, ReactHookFormTextArea, useReactHookForm } from '~/domains/react-hook-form'
import { useShareTherapyInformation } from '~/domains/therapies/hooks/shareTherapyInformation'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useTherapistTherapiesFetch } from '~/hooks/useTherapistTherapiesFetch'
import { useToasts } from '~/hooks/useToasts'
import { useTranslation } from '~/i18n/hooks/useTranslation'

type Props = {
  therapyId: string
}

type FormValues = {
  medicalHistory: string
  pastPsychiatricPath: string
  psychotropicDrugs: string
  reason: string
}

const max = 600

export const RequestPsychiatristForm = ({ therapyId }: Props) => {
  const [shareTherapyInformation] = useShareTherapyInformation()
  const { refetch } = useTherapistTherapiesFetch()

  const { close } = useModalContext()

  const { addToast } = useToasts()

  const form = useReactHookForm<FormValues>({
    defaultValues: {
      medicalHistory: '',
      pastPsychiatricPath: '',
      psychotropicDrugs: '',
      reason: '',
    },
    reValidateMode: 'onChange',
    mode: 'onChange',
  })

  const { errors } = useFormTranslations()

  const placeholder = useTranslation('patientDetail.requestPsychiatrist.modal.placeholder')
  const suggestionTitle = useTranslation('patientDetail.requestPsychiatrist.modal.suggestion.title')
  const suggestionSubtitle = useTranslation('patientDetail.requestPsychiatrist.modal.suggestion.subtitle')

  const onSubmit = useCallback(
    async ({ medicalHistory, pastPsychiatricPath, psychotropicDrugs, reason }: FormValues) => {
      const response = await shareTherapyInformation({
        variables: {
          input: {
            therapyId,
            type: 'MYSELF_PSYCHIATRY',
            medicalHistory,
            pastPsychiatricPath,
            psychotropicDrugs,
            reason,
          },
        },
      })

      if (!response.data?.shareTherapyInformation.ok) {
        addToast({ translationId: 'patientDetail.requestPsychiatrist.toast.error', type: 'alert' })

        return
      }

      refetch()

      addToast({ translationId: 'patientDetail.requestPsychiatrist.toast.success', type: 'success' })

      close()
    },
    [addToast, close, refetch, shareTherapyInformation, therapyId],
  )

  return (
    <ReactHookFormProvider {...form}>
      <Form grow={1} onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
        <OverflowAuto>
          <Flex pb={24}>
            <Text colorName="black" kind="paragraph">
              <Translation id="patientDetail.requestPsychiatrist.modal.subtitle" />
            </Text>
          </Flex>
          <Flex pb={24}>
            <Flex pb={4}>
              <Text colorName="black" fontWeight="600" kind="paragraph">
                <Translation id="patientDetail.requestPsychiatrist.modal.medicalHistory" />
              </Text>
            </Flex>
            <ReactHookFormTextArea
              max={max}
              minHeight="180px"
              name="medicalHistory"
              placeholder={placeholder}
              rules={{ max, required: errors.required }}
              validateOnSubmit={false}
            />
          </Flex>
          <Flex pb={24}>
            <Flex pb={4}>
              <Text colorName="black" fontWeight="600" kind="paragraph">
                <Translation id="patientDetail.requestPsychiatrist.modal.reason" />
              </Text>
            </Flex>
            <ReactHookFormTextArea
              max={max}
              minHeight="180px"
              name="reason"
              placeholder={placeholder}
              rules={{ max, required: errors.required }}
              validateOnSubmit={false}
            />
          </Flex>
          <Flex pb={24}>
            <Flex pb={4}>
              <Text colorName="black" fontWeight="600" kind="paragraph">
                <Translation id="patientDetail.requestPsychiatrist.modal.psychotropicDrugs" />
              </Text>
            </Flex>
            <ReactHookFormTextArea
              max={max}
              minHeight="180px"
              name="psychotropicDrugs"
              placeholder={placeholder}
              rules={{ max, required: errors.required }}
              validateOnSubmit={false}
            />
          </Flex>
          <Flex pb={4}>
            <Text colorName="black" fontWeight="600" kind="paragraph">
              <Translation id="patientDetail.requestPsychiatrist.modal.pastPsychiatricPath" />
            </Text>
          </Flex>
          <ReactHookFormTextArea
            max={max}
            minHeight="180px"
            name="pastPsychiatricPath"
            placeholder={placeholder}
            rules={{ max, required: errors.required }}
            validateOnSubmit={false}
          />
        </OverflowAuto>

        <Button disabled={!form.formState.isValid} kind="primary" loading={form.formState.isSubmitting} type="submit">
          <Translation id="actions.send" />
        </Button>

        <Flex pt={16}>
          <Suggestion description={suggestionSubtitle} kind="info" title={suggestionTitle} />
        </Flex>
      </Form>
    </ReactHookFormProvider>
  )
}
