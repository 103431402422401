import { MessageTimestamp } from 'stream-chat-react'
import { Text } from 'ui'
import { Flex, type FlexProps } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useChatMessage } from '~/domains/chat/hooks/useChatMessage'

export const MessageDeleted = () => {
  const { isMyMessage } = useChatMessage()

  const align: FlexProps['align'] = isMyMessage() ? 'flex-end' : 'flex-start'

  return (
    <Flex align={align} py={16}>
      <Flex background="grey02" borderRadius={16} px={12} py={8}>
        <Text fontWeight="400" kind="caption">
          <Translation id="chat.messageHasBeenDeleted" />
        </Text>
      </Flex>
      <Flex align={align} pt={4}>
        <Text colorName="grey-80" fontWeight="400" kind="footnote">
          <MessageTimestamp calendar />
        </Text>
      </Flex>
    </Flex>
  )
}
