import { useCallStateHooks } from '@stream-io/video-react-sdk'
import { Flex } from 'cdk'
import { SPACING_XS, TIME_300_VALUE } from 'design-tokens'
import { IconDocumentEdit } from 'icons'
import { useEffect, useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { IconWrapper } from '../IconWrapper'
import { SupplementaryInformation } from './SupplementaryInformation'

const TEXT_FADE_DURATION = 5000

const GlobalStyle = createGlobalStyle`
  @keyframes blink {
    0%, 100% {
        opacity: 0.5;
    }

    50% {
      opacity: 1;
    }
  }
`

const FlexWithTransition = styled(Flex).attrs({
  $align: 'center',
  $direction: 'row',
  $gap: SPACING_XS,
})`
  transition: opacity ${TIME_300_VALUE};

  &.blink {
    animation: blink 0.9s ease-in-out 3;
  }
`

export const TranscriptionPatient = () => {
  const [showText, setShowText] = useState(true)

  const callState = useCallStateHooks()
  const isTranscribing = callState.useIsCallTranscribingInProgress()

  useEffect(() => {
    if (!isTranscribing) {
      setShowText(false)
      return
    }

    setShowText(true)
    const timeoutId = setTimeout(() => setShowText(false), TEXT_FADE_DURATION)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [isTranscribing])

  return (
    <>
      <GlobalStyle />
      <FlexWithTransition $opacity={isTranscribing ? 1 : 0} className={isTranscribing ? 'blink' : ''}>
        <SupplementaryInformation>
          <Flex $grow={1}>
            <IconWrapper>
              <IconDocumentEdit colorName="lighter" size={20} />
            </IconWrapper>
          </Flex>
        </SupplementaryInformation>
        <FlexWithTransition $opacity={showText ? 1 : 0} style={{ cursor: 'default' }}>
          <TranslationMarkdown
            colorName="lighter"
            fontWeight="500"
            id="therapySessionLive.transcription.patient.label"
          />
        </FlexWithTransition>
      </FlexWithTransition>
    </>
  )
}
