import { gql, useQuery } from '@apollo/client'
import {
  type AvailableTimeSlotsFirstBookingQuery,
  type AvailableTimeSlotsFirstBookingQueryVariables,
} from '~/types/graphql'

const QUERY = gql`
  query availableTimeSlotsFirstBooking($userIds: [String!]!, $therapyPathType: TherapyTherapyPathType!) {
    availableTimeSlotsFirstBooking(userIds: $userIds, therapyPathType: $therapyPathType) {
      id
      endAt
      startAt
      userId
    }
  }
`

export const useAvailableTimeSlotsFirstBooking = ({
  userIds,
  therapyPathType,
}: AvailableTimeSlotsFirstBookingQueryVariables) => {
  const { data, error, loading, refetch, updateQuery } = useQuery<
    AvailableTimeSlotsFirstBookingQuery,
    AvailableTimeSlotsFirstBookingQueryVariables
  >(QUERY, {
    variables: {
      userIds,
      therapyPathType,
    },
    fetchPolicy: 'cache-first',
  })

  return {
    error: !!error,
    items: data?.availableTimeSlotsFirstBooking || [],
    loading,
    refetch,
    updateQuery,
  }
}
