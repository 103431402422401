import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertTherapist } from '~/domains/auth/components/AssertTherapist'
import { AssertFeatureFlagByUserIdIsVariant } from '~/domains/featureFlags/components/AssertFeatureFlagByUserIdIsVariant'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'

const LazyCreateNutritionPlanPage = loadable(() => import('./View'), {
  resolveComponent: (components) => components.CreateNutritionPlanPageView,
})

export const CreateNutritionPlanPage: Page = {
  exact: true,
  id: 'nutritionPlan.create',
  paths: ['/patients/:patientId/nutrition-plans/create'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: true,
    seoKey: 'nutritionPlan.create',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertFeatureFlagByUserIdIsVariant names={['ff_nutrition_plan']}>
        <AssertTherapist>{children}</AssertTherapist>
      </AssertFeatureFlagByUserIdIsVariant>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <LazyCreateNutritionPlanPage />,
}
