import { Flex } from 'cdk'
import { Button, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TechnicalFeedbackTrigger } from '~/domains/technicalFeedback/components/TechnicalFeedbackTrigger'
import { type TherapyTherapyPathType } from '~/types/graphql'

type Props = {
  onCompleted: () => void
  therapyPathType: TherapyTherapyPathType | null
  therapySessionId: string
}

export const TechnicalFeedbackToComplete = ({ onCompleted, therapyPathType, therapySessionId }: Props) => (
  <>
    <Flex $pb={8}>
      <Text fontWeight="600" kind="caption">
        <Translation id="systemMessage.technicalFeedback.title" />
      </Text>
    </Flex>
    <Text fontWeight="400" kind="caption">
      <Translation id="systemMessage.technicalFeedback.body" />
    </Text>
    <Flex $align="center" $direction="row" $pt={16} $wrap="wrap">
      <TechnicalFeedbackTrigger
        onCompleted={onCompleted}
        therapyPathType={therapyPathType}
        therapySessionId={therapySessionId}
        trigger={
          <Button kind="secondary" size="sm">
            <Translation id="systemMessage.technicalFeedback.cta" />
          </Button>
        }
      />
    </Flex>
  </>
)
