import { gql, useMutation } from '@apollo/client'
import { type ShareTherapyInformationMutation, type ShareTherapyInformationMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation shareTherapyInformation($input: ShareTherapyInformationInput!) {
    shareTherapyInformation(input: $input) {
      ok
    }
  }
`

export const useShareTherapyInformation = () =>
  useMutation<ShareTherapyInformationMutation, ShareTherapyInformationMutationVariables>(MUTATION)
