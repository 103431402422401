import { COLOR_GREEN_20, OPACITY_56 } from 'design-tokens'
import { type CSSProperties, useMemo } from 'react'
import { type EventWrapperProps } from 'react-big-calendar'
import { TherapistCalendarEventWrapper } from './TherapistCalendarEventWrapper'

type Props = Pick<EventWrapperProps, 'style'>

export const TherapistCalendarEventWeekly = ({ style }: Props) => {
  const wrapperStyle = useMemo(
    (): CSSProperties => ({
      ...style,
      backgroundColor: COLOR_GREEN_20,
      opacity: OPACITY_56,
      zIndex: 1,
    }),
    [style],
  )

  return <TherapistCalendarEventWrapper style={wrapperStyle} />
}
