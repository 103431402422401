import { Flex, MountOn, Pressable } from 'cdk'
import { SPACING_3XL, SPACING_LG, SPACING_MD, SPACING_SM, SPACING_XS } from 'design-tokens'
import { IconChevronRightCircle, IconSerenisChat, IconSerenisHome, IconSerenisSmartphone } from 'icons'
import NextLink from 'next/link'
import { useCallback } from 'react'
import { Button, Drawer, DrawerHeader, DrawerProvider, DrawerTrigger, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { useIsNativeApp } from '~/domains/appNative/hooks/useIsNativeApp'
import { useRootHistory } from '~/hooks/useRootHistory'
import { useTherapist } from '~/hooks/useTherapist'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { getEnv } from '~/utils/getEnv'
import { getRoute } from '~/utils/getRoute'
import { AppleStoreBadge } from '../svg/AppleStoreBadge'
import { PlayStoreBadge } from '../svg/PlayStoreBadge'
import { StoreQrCode } from '../svg/StoreQrCode'

const SERENIS_ONELINK = 'https://onelink.to/wq52ve'

type ThankYouPageActionsProps = {
  therapySessionId: string
  therapyPathType?: TherapyTherapyPathType | null
  isUnderage?: boolean
}

export const ThankYouPageSecondaryActions = ({
  therapyPathType,
  therapySessionId,
  isUnderage = false,
}: ThankYouPageActionsProps) => {
  const trackEventClick = useTrackEventClick()
  const { id: therapistId } = useTherapist()
  const isNative = useIsNativeApp()

  const AppleStoreUrl = getEnv('SERENIS_APP_STORE')
  const PlayStoreUrl = getEnv('SERENIS_PLAY_STORE')

  const rootHistory = useRootHistory()

  const handleOpenChat = useCallback(() => {
    trackEventClick('thank-you-page.open-chat', { therapySessionId })
    rootHistory.push(getRoute(`/chat/${therapistId}`))
  }, [rootHistory, therapistId, therapySessionId, trackEventClick])

  const handleDownloadApp = useCallback(() => {
    trackEventClick('thank-you-page.download-app', { therapySessionId })
    rootHistory.push(getRoute('/'))
  }, [rootHistory, therapySessionId, trackEventClick])

  const handleSecondaryAction = useCallback(() => {
    trackEventClick('thank-you-page.go-to-homepage', { therapySessionId })
    if (therapyPathType === 'COUPLES_PSYCHOTHERAPY') {
      rootHistory.push(getRoute('/onboarding/couples'))
      return
    }

    if (isUnderage) {
      rootHistory.push(getRoute('/onboarding/guardian'))
      return
    }

    rootHistory.push(getRoute('/'))
  }, [isUnderage, rootHistory, therapyPathType, therapySessionId, trackEventClick])

  return (
    <Flex $gap={SPACING_SM} $grow={1} $justify="center" $mdJustify="flex-start">
      <Text kind="h3">
        <Translation id="thankYouPage.therapist-card.secondary.title" />
      </Text>

      <Flex $gap={SPACING_XS}>
        <Button isGhost kind="secondary" onClick={handleOpenChat}>
          <Flex $align="space-between" $direction="row" $grow={1}>
            <Flex $direction="row" $gap={SPACING_SM} $grow={1}>
              <IconSerenisChat size={24} />
              <Translation id="thankYouPage.therapist-card.secondary.1.cta" />
            </Flex>
            <IconChevronRightCircle size={24} />
          </Flex>
        </Button>

        {!isNative && (
          <>
            <MountOn mediaQuery="lt-md">
              <Flex as={NextLink} href={SERENIS_ONELINK} target="_blank">
                <Button isGhost kind="secondary">
                  <Flex $align="space-between" $direction="row" $grow={1}>
                    <Flex $direction="row" $gap={SPACING_SM} $grow={1}>
                      <IconSerenisSmartphone size={24} />
                      <Translation id="thankYouPage.therapist-card.secondary.2.cta" />
                    </Flex>
                    <IconChevronRightCircle size={24} />
                  </Flex>
                </Button>
              </Flex>
            </MountOn>
            <MountOn mediaQuery="gt-sm">
              <DrawerProvider>
                <DrawerTrigger>
                  <Button isGhost kind="secondary">
                    <Flex $align="space-between" $direction="row" $grow={1}>
                      <Flex $direction="row" $gap={SPACING_SM} $grow={1}>
                        <IconSerenisSmartphone size={24} />
                        <Translation id="thankYouPage.therapist-card.secondary.2.cta" />
                      </Flex>
                      <IconChevronRightCircle size={24} />
                    </Flex>
                  </Button>
                </DrawerTrigger>
                <Drawer>
                  <DrawerHeader />
                  <Text kind="h1">
                    <Translation id="thankYouPage.therapist-card.secondary.2.title" />
                  </Text>
                  <Flex $gap={SPACING_3XL} $py={SPACING_LG}>
                    <Flex $gap={SPACING_MD}>
                      <Translation id="thankYouPage.therapist-card.secondary.2.description" />
                      <StoreQrCode />
                    </Flex>
                    <Pressable onClick={handleDownloadApp}>
                      <Flex $direction="row" $gap={SPACING_SM}>
                        <Flex as={NextLink} href={AppleStoreUrl} target="_blank">
                          <AppleStoreBadge />
                        </Flex>
                        <Flex as={NextLink} href={PlayStoreUrl} target="_blank">
                          <PlayStoreBadge />
                        </Flex>
                      </Flex>
                    </Pressable>
                  </Flex>
                </Drawer>
              </DrawerProvider>
            </MountOn>
          </>
        )}

        <Button data-test-id="thx-cta-homepage" isGhost kind="secondary" onClick={handleSecondaryAction}>
          <Flex $align="space-between" $direction="row" $grow={1}>
            <Flex $direction="row" $gap={SPACING_SM} $grow={1}>
              <IconSerenisHome size={24} />
              <Translation id="thankYouPage.therapist-card.secondary.3.cta" />
            </Flex>
            <IconChevronRightCircle size={24} />
          </Flex>
        </Button>
      </Flex>
    </Flex>
  )
}
