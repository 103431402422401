import { Flex } from 'cdk'
import { SPACING_XS } from 'design-tokens'
import { DrawerFooter, DrawerHeader } from 'ui'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { AppointmentCard, AppointmentSkeletonCard } from '~/domains/paths'
import { DownloadCalendarEventButton } from '~/domains/reservation/components/DownloadCalendarEventButton'
import { useManageSession } from '../hooks/useManageSession'

export const RescheduleSessionSummary = () => {
  const { rescheduledSession, sessionId, therapyPath } = useManageSession()

  const bookedSessionCard =
    rescheduledSession == null ? (
      <AppointmentSkeletonCard />
    ) : (
      <AppointmentCard
        endAt={rescheduledSession.endAt}
        price={rescheduledSession.cost ?? therapyPath?.cost}
        startAt={rescheduledSession.startAt}
        therapyPath={therapyPath.type}
      />
    )

  return (
    <>
      <DrawerHeader />
      <Flex $gap={SPACING_XS}>
        <TranslationMarkdown colorName="darker" id="therapySession.rescheduleSession.summary.title" kind="h3" />
        {bookedSessionCard}
      </Flex>
      <DrawerFooter>
        <Flex $grow={1} $shrink={1}>
          <DownloadCalendarEventButton therapySessionId={sessionId} />
        </Flex>
      </DrawerFooter>
    </>
  )
}
