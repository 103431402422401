import {
  CompositeButton,
  DeviceSelectorAudioInput,
  OwnCapability,
  PermissionNotification,
  Restricted,
  SpeakingWhileMutedNotification,
  useCallStateHooks,
  useRequestPermission,
} from '@stream-io/video-react-sdk'
import { isNeitherNullNorUndefined } from 'functions'
import { Icon, Microphone, MicrophoneMute, WarningCircleSolid } from 'icons'
import { type PropsWithChildren, useCallback, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Badge, Text } from 'ui'
import { Tooltip, TooltipContent, TooltipTrigger } from '~/components/FloatingUI/Tooltip'
import { Translation } from '~/components/Translation'
import { useIsNativeApp } from '~/domains/appNative/hooks/useIsNativeApp'
import { useVideocallEventLogger } from '~/domains/videocall'
import { useToasts } from '~/hooks/useToasts'
import { useTranslation } from '~/i18n/hooks/useTranslation'
import { type TranslationId } from '~/i18n/types'

/**
 * Recreated Toggle Microphone Button as suggested by Stream dev Oliver Lazoroski
 * @see https://github.com/GetStream/stream-video-js/blob/d61525733e87edec520e610d3e94c957eab207a0/packages/react-sdk/src/components/CallControls/ToggleAudioButton.tsx
 */
const ToggleAudioPublishingButton = ({ children }: PropsWithChildren) => {
  const { isAwaitingPermission } = useRequestPermission(OwnCapability.SEND_AUDIO)
  const { useMicrophoneState } = useCallStateHooks()
  const { devices, hasBrowserPermission, isMute, microphone, status } = useMicrophoneState()
  const { addToast } = useToasts()
  const isNativeApp = useIsNativeApp()
  const logFailure = useVideocallEventLogger()

  const [isLoading, setIsLoading] = useState(false)

  const messageApproved = useTranslation('videocall.audioInput.approved')
  const messageAwaitingApproval = useTranslation('videocall.audioInput.awaitingApproval')
  const messageRevoked = useTranslation('videocall.audioInput.revoked')
  const errorToast: Extract<TranslationId, `videocall.device.error.${string}`> =
    `videocall.device.error.${isNativeApp ? 'app' : 'browser'}`

  const handleClick = useCallback(async () => {
    if (hasBrowserPermission && isNeitherNullNorUndefined(status)) {
      try {
        setIsLoading(true)

        await microphone.toggle()
      } catch (error) {
        logFailure('videocall.microphone-control.toggle', error)

        addToast({
          translationId: errorToast,
          type: 'alert',
        })
      } finally {
        setIsLoading(false)
      }
    }
  }, [addToast, errorToast, hasBrowserPermission, microphone, status, logFailure])

  useHotkeys('ctrl+d', handleClick)

  return (
    <Restricted requiredGrants={[OwnCapability.SEND_AUDIO]}>
      <PermissionNotification
        isAwaitingApproval={isAwaitingPermission}
        messageApproved={messageApproved}
        messageAwaitingApproval={messageAwaitingApproval}
        messageRevoked={messageRevoked}
        permission={OwnCapability.SEND_AUDIO}
      >
        <CompositeButton
          Menu={
            hasBrowserPermission && isNeitherNullNorUndefined(status) && devices && devices?.length > 1
              ? DeviceSelectorAudioInput
              : undefined
          }
          active={isMute}
          className={hasBrowserPermission && isNeitherNullNorUndefined(status) ? '' : 'srns-permissions-denied-control'}
          disabled={isLoading || !hasBrowserPermission}
          onClick={handleClick}
        >
          {children}
        </CompositeButton>
      </PermissionNotification>
    </Restricted>
  )
}

export const MicrophoneControl = () => {
  const { useMicrophoneState } = useCallStateHooks()
  const { hasBrowserPermission, isMute, status } = useMicrophoneState()

  if (!hasBrowserPermission || !isNeitherNullNorUndefined(status)) {
    return (
      <Tooltip kind="title" placement="top">
        <TooltipTrigger disabled>
          <Badge sup={<Icon Svg={WarningCircleSolid} colorName="yellow-60" size={24} />}>
            <ToggleAudioPublishingButton>
              <Icon Svg={MicrophoneMute} colorName="white" size={24} />
            </ToggleAudioPublishingButton>
          </Badge>
        </TooltipTrigger>
        <TooltipContent style={{ marginTop: '-4px' }}>
          <Text fontWeight="400" kind="caption">
            <Translation id="videocall.audioInput.browserPermissions" />
          </Text>
        </TooltipContent>
      </Tooltip>
    )
  }

  return (
    <SpeakingWhileMutedNotification>
      <ToggleAudioPublishingButton>
        <Tooltip kind="title" placement="top">
          <TooltipTrigger>
            <Icon Svg={isMute ? MicrophoneMute : Microphone} colorName="white" size={24} />
          </TooltipTrigger>
          <TooltipContent style={{ marginTop: '-4px' }}>
            <Text fontWeight="400" kind="caption">
              <Translation id={isMute ? 'videocall.mic.on' : 'videocall.mic.off'} />
            </Text>
          </TooltipContent>
        </Tooltip>
      </ToggleAudioPublishingButton>
    </SpeakingWhileMutedNotification>
  )
}
