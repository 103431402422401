import { Flex, media, Pressable } from 'cdk'
import {
  FONT_WEIGHT_500_VALUE,
  SPACING_2XS,
  SPACING_4XL,
  SPACING_5XL,
  SPACING_LG,
  SPACING_MD,
  SPACING_SM,
  TIME_150,
} from 'design-tokens'
import { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { Button, Text, useDrawerContext } from 'ui'
import { CarouselProvider } from '~/components/Carousel'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { GLOBAL_VALUE_SEPARATOR } from '~/constants'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { getSignupWish, type Wish } from '~/domains/auth/hooks/useSignupWish'
import { useFeatureFlagsByFingerprint } from '~/domains/featureFlags'
import { useUserFormFlowResponseCompleted } from '~/domains/formFlow/hooks/useUserFormFlowResponseCompleted'
import { ReactHookFormProvider, useReactHookForm } from '~/domains/react-hook-form'
import { TherapistOverviewCard } from '~/domains/therapist/TherapistOverviewCard'
import { OpenResumeCTA } from '~/domains/therapist/TherapistOverviewCard/OpenResumeCTA'
import { useRootHistory } from '~/hooks/useRootHistory'
import { useTherapist } from '~/hooks/useTherapist'
import { type TherapyTherapyPath } from '~/types/graphql'
import { getRoute } from '~/utils/getRoute'
import { getSignupIntentByTherapyPath } from '~/utils/getSignupIntentByTherapyPath'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'
import { PreReservationImpressionTracker } from '../../components/PreReservationImpressionTracker'
import { useCarouselSlotsForFirstBooking } from '../../hooks/useCarouselSlotsForFirstBooking'
import { useSuspenseTherapyPathByIdQuery } from '../../hooks/useTherapyPathByIdQuery'
import { type ReservationFormValues } from '../../types'
import { ReservationDurationText } from '../BookBySuggestedTherapists/common/ReservationDurationText'
import { ReservationForm } from '../BookBySuggestedTherapists/common/ReservationForm'
import { ReservationSubmitButton } from '../BookBySuggestedTherapists/common/ReservationSubmitButton'
import { TherapistOverviewCardOpenCalendar } from '../BookBySuggestedTherapists/TherapistsOverviews'
import { TimeSlotDaysTherapists } from '../BookBySuggestedTherapists/TimeSlots/TimeSlotDaysTherapists'

type RedirectToSignupParams = {
  therapyPath: TherapyTherapyPath
}

const useRedirectToSignup = ({ therapyPath }: RedirectToSignupParams) => {
  const rootHistory = useRootHistory()

  const intent = getSignupIntentByTherapyPath(therapyPath.type) ?? ''
  const referrer = getRoute(`/booking/${therapyPath.id}`)

  const signupPage = getRoute('/signup')
  const signupIntent = intent != null ? `&intent=${intent}` : ''

  return useCallback(
    (wish: Wish) => {
      const signupWish = `wish=${getSignupWish(wish)}`

      rootHistory.push(`${signupPage}?${signupWish}${signupIntent}`, { referrer })
    },
    [referrer, rootHistory, signupIntent, signupPage],
  )
}

type TranslatedFlexProps = {
  $translateX: string
}

const TranslatedFlex = styled(Flex)<TranslatedFlexProps>`
  transition: transform ${TIME_150} ease-in-out;

  ${({ $translateX }) => media.md`
    transform: translateX(${$translateX});
  `}
`

type HaveDoubtsProps = {
  handleClick: () => void
}

const HaveDoubts = ({ handleClick }: HaveDoubtsProps) => (
  <Flex $direction="row" $gap={SPACING_2XS} $justify="center">
    <Text colorName="neutral-70" kind="paragraph">
      <Translation id="reservation.therapist.overviewCard.haveDoubts.text" />
    </Text>
    <Pressable data-test-id="have-doubts-cta" onClick={handleClick}>
      <TranslationMarkdown
        colorName="primary-50"
        fontWeight={FONT_WEIGHT_500_VALUE}
        id="reservation.therapist.overviewCard.haveDoubts.cta"
        kind="paragraph"
      />
    </Pressable>
  </Flex>
)

export const PreBookingTherapist = () => {
  const { userFormFlowResponseCompletedId: formFlowId } = useUserFormFlowResponseCompleted()
  const form = useReactHookForm<ReservationFormValues>({})
  const therapist = useTherapist()
  const trackEventClick = useTrackEventClick()
  const { therapyPath } = useSuspenseTherapyPathByIdQuery()
  const redirectToSignup = useRedirectToSignup({ therapyPath })
  const { isVariant1 } = useFeatureFlagsByFingerprint()
  const { isOpen } = useDrawerContext()
  const { data, length, slice } = useCarouselSlotsForFirstBooking({
    compareTherapists: false,
    selectedTherapist: therapist,
    suggestedTherapistsProfiles: [therapist],
    therapyPathType: therapyPath.type,
  })

  const suggestedTherapistsProfiles = useMemo(() => [therapist], [therapist])

  const ffPreSignupVariant1 = isVariant1('ff_pre_signup_therapists') || isVariant1('ff_pre_signup_nutritionists')

  form.register('selectedTimeSlotWithTherapist', { required: true })

  const onSubmit = ({
    selectedTimeSlotWithTherapist,
  }: Pick<ReservationFormValues, 'selectedTimeSlotWithTherapist'>) => {
    setLocalStorage('pre-booking-selected-time-slot', selectedTimeSlotWithTherapist)
    trackEventClick('pre-booking-wish-booking', {
      therapist: therapist.id,
      slot: selectedTimeSlotWithTherapist?.split(GLOBAL_VALUE_SEPARATOR)[0],
    })

    redirectToSignup('booking')
  }

  const handleOpenCalendar = () => {
    setLocalStorage('pre-booking-selected-therapist', `${therapist.id}${GLOBAL_VALUE_SEPARATOR}${therapist.firstName}`)
    trackEventClick('pre-booking-wish-calendar', { therapist: therapist.id })

    redirectToSignup('calendar')
  }

  const handleHaveDoubts = () => {
    setLocalStorage('pre-booking-compare-new-therapist', formFlowId)
    trackEventClick('pre-booking-wish-comparing', { therapist })

    redirectToSignup('comparing')
  }

  const handleOpenResume = () => {
    setLocalStorage('pre-booking-selected-therapist', `${therapist.id}${GLOBAL_VALUE_SEPARATOR}${therapist.firstName}`)
    trackEventClick('pre-booking-wish-curriculum', { therapist: therapist.id })

    redirectToSignup('curriculum')
  }

  const openResumeCTA = ffPreSignupVariant1 ? <OpenResumeCTA onClick={handleOpenResume} /> : null

  return (
    <>
      <PreReservationImpressionTracker selectedTherapist={therapist} />
      <ReactHookFormProvider {...form}>
        <Flex $grow={1} $maxWidth={1440} $shrink={1}>
          <TranslatedFlex
            $gap={SPACING_LG}
            $grow={1}
            $hide={false}
            $justify="center"
            $lgHide
            $translateX={isOpen ? '-33%' : '0'}
          >
            <Flex $maxWidth={350} $minWidth={350}>
              <TherapistOverviewCard
                calendarCTA={
                  ffPreSignupVariant1 ? (
                    <Button kind="primary" onClick={handleOpenCalendar} size="md">
                      <Translation id="therapist.overviewCard.cta.showCalendar" />
                    </Button>
                  ) : (
                    <TherapistOverviewCardOpenCalendar
                      compareTherapists={false}
                      onSubmit={onSubmit}
                      selectedTherapist={therapist}
                      suggestedTherapistsProfiles={suggestedTherapistsProfiles}
                      therapyPathType={therapyPath.type}
                    />
                  )
                }
                openResumeCTA={openResumeCTA}
                therapyPath={therapyPath.type}
              />
            </Flex>
            <HaveDoubts handleClick={handleHaveDoubts} />
          </TranslatedFlex>
          <ReservationForm onSubmit={onSubmit}>
            <Flex
              $direction="row"
              $gap={SPACING_5XL}
              $grow={1}
              $hide
              $justify="space-between"
              $lgHide={false}
              $pb={SPACING_MD}
              $pt={SPACING_4XL}
              $px={SPACING_4XL}
              $shrink={1}
            >
              <Flex $gap={SPACING_LG}>
                <TherapistOverviewCard
                  calendarCTA={null}
                  openResumeCTA={openResumeCTA}
                  therapyPath={therapyPath.type}
                />
                <HaveDoubts handleClick={handleHaveDoubts} />
              </Flex>
              <Flex $gap={SPACING_MD} $grow={1} $shrink={1}>
                <Flex $align="center" $direction="row" $justify="space-between" $wrap="wrap">
                  <TranslationMarkdown id="reservation.bookFreeTherapySession" kind="h2" />
                </Flex>
                <Flex $gap={SPACING_SM} $grow={1} $shrink={1}>
                  <CarouselProvider length={length}>
                    <TimeSlotDaysTherapists
                      compareTherapists={false}
                      length={length}
                      selectedTherapist={therapist}
                      slice={slice}
                      slots={data}
                      suggestedTherapistsProfiles={suggestedTherapistsProfiles}
                    />
                  </CarouselProvider>
                  <Flex $align="flex-end">
                    <ReservationDurationText />
                  </Flex>
                </Flex>
                <Flex $align="flex-end">
                  <ReservationSubmitButton />
                </Flex>
              </Flex>
            </Flex>
          </ReservationForm>
        </Flex>
      </ReactHookFormProvider>
    </>
  )
}
