import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useProductUserFeedbackAnswerUpsert } from '~/domains/productUserFeedback/hooks/useProductUserFeedbackAnswerUpsert'
import { ReactHookFormRating } from '~/domains/react-hook-form/fields/FieldRating'
import { useToasts } from '~/hooks/useToasts'
import { type StepProps, type TechnicalFeedbackPsychiatry } from '../../types'
import { getTechnicalFeedbackRoute } from '../../utils/getTechnicalFeedbackRoute'
import { BackButton } from '../BackButton'
import { NextButton } from '../NextButton'
import { TechnicalFeedbackForm } from '../TechnicalFeedbackForm'

export const TechnicalFeedbackStepContext = ({ technicalFeedbackId }: StepProps) => {
  const history = useHistory()

  const { addToast } = useToasts()

  const { productUserFeedbackAnswerUpsert } = useProductUserFeedbackAnswerUpsert(technicalFeedbackId)

  const onSubmit = useCallback(
    async (values: TechnicalFeedbackPsychiatry) => {
      try {
        const productUserFeedbackAnswerUpsertResponse = await productUserFeedbackAnswerUpsert({
          id: 'CONTEXT',
          value: values.context.toString(),
        })

        if (!productUserFeedbackAnswerUpsertResponse.data?.productUserFeedbackAnswerUpsert?.id) {
          addToast({
            translationId: 'reviews.askReviewModal.bad.unableToSaveResponse',
            type: 'alert',
          })

          return
        }

        history.push(getTechnicalFeedbackRoute('/message'))
      } catch (error) {
        addToast({
          translationId: 'reviews.askReviewModal.bad.unableToSaveResponse',
          type: 'alert',
        })
      }
    },
    [addToast, history, productUserFeedbackAnswerUpsert],
  )

  return (
    <TechnicalFeedbackForm onSubmit={onSubmit}>
      <OverflowAuto>
        <Flex pt={16}>
          <Text colorName="grey-60" kind="caption">
            3/3
          </Text>
        </Flex>
        <Flex pt={16}>
          <Text colorName="black" fontWeight="600" kind="h3">
            <Translation id="technicalFeedback.psychiatry.context.title" />
          </Text>
        </Flex>
        <Flex pt={16}>
          <Text colorName="black" kind="paragraph">
            <Translation id="technicalFeedback.psychiatry.context.body" />
          </Text>
        </Flex>
        <Flex pt={24}>
          <ReactHookFormRating name="context" rules={{ min: 1, max: 5 }} />
        </Flex>
      </OverflowAuto>
      <Flex pt={24}>
        <NextButton />
      </Flex>
      <Flex pt={8}>
        <BackButton />
      </Flex>
    </TechnicalFeedbackForm>
  )
}
