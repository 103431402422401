import { useCallback } from 'react'
import { Text } from 'ui'
import { Button, Flex } from 'ui-deprecated'
import { StopTherapySessionButton } from '~/components/TherapySessionCardTherapist/components/StopTherapySessionButton'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useChatMessage } from '~/domains/chat/hooks/useChatMessage'
import { useChatPatient } from '~/domains/chat/hooks/useChatPatient'
import { useRootHistory } from '~/hooks/useRootHistory'
import { CustomMessageWrapper } from './CustomMessageWrapper'

const Therapist = () => {
  const { message } = useChatMessage<'SYSTEM_THERAPIST_CHURN_MISSING_INFORMATION'>()
  const { user } = useChatPatient()
  const history = useRootHistory()

  const therapyId = message.srns?.payload.therapyId
  const therapySessionId = message.srns?.payload.therapySessionId

  const onScheduleNext = useCallback(() => {
    if (!therapySessionId || !therapyId || !user) {
      return
    }

    history.push(`/chat/${user.id}/detail/therapy-session/${therapyId}/schedule`)
  }, [history, therapyId, therapySessionId, user])

  if (!therapyId || !therapySessionId || !user) {
    return null
  }

  return (
    <CustomMessageWrapper>
      <Flex pb={8}>
        <Text fontWeight="600" kind="caption">
          <Translation id="systemMessage.therapist.therapistChurnMissingInformation.title" />
        </Text>
      </Flex>
      <TranslationMarkdown id="systemMessage.therapist.therapistChurnMissingInformation.body" kind="caption" />

      <Flex align="center" direction="row" pt={16} wrap="wrap">
        <Flex pb={8} pr={16}>
          <Button kind="secondary" onClick={onScheduleNext} size="small">
            <Translation id="actions.scheduleNextTherapySession" />
          </Button>
        </Flex>
        <Flex pb={8}>
          <StopTherapySessionButton id={therapySessionId} patient={user} />
        </Flex>
      </Flex>
    </CustomMessageWrapper>
  )
}

export const MessageTherapistChurnMissingInformation = () => {
  const group = useUserActingAsGroup()

  if (group === 'therapist') {
    return <Therapist />
  }

  return null
}
