/**
 * TODO (amin-khayam)
 * use new modals to handle NoMoreTherapistsModal and ChangeTherapistInfoModal
 */

import { Flex, MountOn, Pressable } from 'cdk'
import { isValid } from 'date-fns'
import {
  FONT_WEIGHT_500_VALUE,
  SPACING_2XS,
  SPACING_4XL,
  SPACING_LG,
  SPACING_MD,
  SPACING_SM,
  SPACING_XL,
} from 'design-tokens'
import { useCallback, useEffect } from 'react'
import { Text } from 'ui'
import { CarouselProvider } from '~/components/Carousel'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { GLOBAL_VALUE_SEPARATOR } from '~/constants'
import { useUserFormFlowResponseCompleted } from '~/domains/formFlow/hooks/useUserFormFlowResponseCompleted'
import { useModals } from '~/domains/modals'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { ScrollRestoreProvider } from '~/hooks/useScrollRestore'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { removeLocalStorage } from '~/utils/localStorage/removeLocalStorage'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'
import { isChangeTherapistSupported } from '~/utils/therapyPaths'
import { ChangeTherapistInfoModal } from '../../components/BookBySuggestedTherapistsView/ChangeTherapistInfoModal'
import { NoMoreTherapistsModal } from '../../components/NoMoreTherapistsModal'
import { AvailabilityDayDropdownProvider } from '../../hooks/useAvailabilityDayDropdown'
import { useCarouselSlotsForFirstBooking } from '../../hooks/useCarouselSlotsForFirstBooking'
import { useRegisterSelectedTimeSlot } from '../../hooks/useRegisterSelectedTimeSlot'
import { useReservationFlow } from '../../hooks/useReservationFlow'
import { useSuspenseTherapyPathByIdQuery } from '../../hooks/useTherapyPathByIdQuery'
import { type ReservationFormValues } from '../../types'
import { CompareCalendars } from './common/CompareCalendars'
import { ReservationDurationText } from './common/ReservationDurationText'
import { ReservationForm } from './common/ReservationForm'
import { ReservationSelectedSlot } from './common/ReservationSelectedSlot'
import { ReservationSubmitButton } from './common/ReservationSubmitButton'
import { BookBySuggestedTherapistsOverviews } from './TherapistsOverviews'
import { TimeSlotDaysTherapists } from './TimeSlots/TimeSlotDaysTherapists'

const HaveDoubts = () => {
  const { userFormFlowResponseCompletedId: formFlowId } = useUserFormFlowResponseCompleted()
  const preBookingCompareNewTherapist = getLocalStorage('pre-booking-compare-new-therapist')
  const { addTherapist } = useReservationFlow()
  const { open } = useModals()

  const onChangeTherapist = useCallback(() => {
    const hasNewTherapist = addTherapist()

    if (hasNewTherapist) {
      scrollTo()
      return
    }

    open('noMoreTherapists')
    return
  }, [addTherapist, open])

  const onRequestChangeTherapist = () => {
    if (!getLocalStorage('booking-change-therapist-info-shown')) {
      open('changeTherapistInfo')
      setLocalStorage('booking-change-therapist-info-shown', 'true')

      return
    }

    onChangeTherapist()
  }

  useEffect(() => {
    removeLocalStorage('pre-booking-compare-new-therapist')

    if (!preBookingCompareNewTherapist) {
      return
    }

    if (preBookingCompareNewTherapist !== formFlowId) {
      return
    }

    onChangeTherapist()
  }, [formFlowId, onChangeTherapist, preBookingCompareNewTherapist])

  return (
    <>
      <Flex $direction="row" $gap={SPACING_2XS} $justify="center" $wrap="wrap">
        <Text colorName="neutral-70" kind="paragraph">
          <Translation id="reservation.therapist.overviewCard.haveDoubts.text" />
        </Text>
        <Pressable onClick={onRequestChangeTherapist}>
          <TranslationMarkdown
            colorName="primary-50"
            fontWeight={FONT_WEIGHT_500_VALUE}
            id="reservation.therapist.overviewCard.haveDoubts.cta"
            kind="paragraph"
          />
        </Pressable>
      </Flex>
      <NoMoreTherapistsModal />
      <ChangeTherapistInfoModal onConfirm={onChangeTherapist} />
    </>
  )
}

export const BookBySuggestedTherapists = () => {
  useRegisterSelectedTimeSlot()
  const form = useReactHookFormContext<ReservationFormValues>()
  const {
    compareTherapists,
    goTo,
    onDayAndTimeSlotSelected,
    selectedTherapist,
    setAutoOpenCalendar,
    suggestedTherapistsProfiles,
    toggleCompareTherapists,
  } = useReservationFlow()
  const { therapyPath } = useSuspenseTherapyPathByIdQuery()
  const { data, length, slice } = useCarouselSlotsForFirstBooking({
    compareTherapists,
    selectedTherapist,
    suggestedTherapistsProfiles,
    therapyPathType: therapyPath.type,
  })

  const initialSelectedSlot = form.watch('selectedTimeSlotWithTherapist')
  const canChangeTherapist = isChangeTherapistSupported(therapyPath.type)
  const isPsychiatry = therapyPath.type === 'MYSELF_PSYCHIATRY'

  const onSubmit = useCallback(
    ({ selectedTimeSlotWithTherapist }: Pick<ReservationFormValues, 'selectedTimeSlotWithTherapist'>) => {
      if (!selectedTimeSlotWithTherapist) {
        return
      }

      const [slot, therapistId] = selectedTimeSlotWithTherapist.split(GLOBAL_VALUE_SEPARATOR)

      const date = new Date(slot)

      if (!isValid(date)) {
        return
      }

      if (!suggestedTherapistsProfiles.some(({ id }) => id === therapistId)) {
        return
      }

      form.setValue('selectedTimeSlot', date)
      form.setValue('therapistId', therapistId)

      onDayAndTimeSlotSelected(date)
    },
    [form, onDayAndTimeSlotSelected, suggestedTherapistsProfiles],
  )

  useEffect(() => {
    if (getLocalStorage('pre-booking-selected-time-slot') == null) {
      return
    }

    setAutoOpenCalendar(true)
    removeLocalStorage('pre-booking-selected-time-slot')
  }, [form, setAutoOpenCalendar])

  return (
    <ScrollRestoreProvider pb={0}>
      <CarouselProvider length={suggestedTherapistsProfiles.length} onSetCurrent={goTo}>
        <AvailabilityDayDropdownProvider>
          <Flex $grow={1} $maxWidth={1440} $shrink={1}>
            <MountOn mediaQuery="lt-lg">
              <Flex $gap={SPACING_LG} $grow={1} $justify="center" $py={SPACING_MD}>
                <BookBySuggestedTherapistsOverviews
                  initialSelectedSlot={initialSelectedSlot}
                  onSubmit={onSubmit}
                  toggleCompareTherapists={toggleCompareTherapists}
                />
                {canChangeTherapist && <HaveDoubts />}
              </Flex>
            </MountOn>
            <MountOn mediaQuery="gt-md">
              <ReservationForm onSubmit={onSubmit}>
                <Flex
                  $direction="row"
                  $gap={SPACING_XL}
                  $grow={1}
                  $justify="space-between"
                  $pb={SPACING_MD}
                  $pt={SPACING_4XL}
                  $px={SPACING_4XL}
                  $shrink={1}
                >
                  <Flex $gap={SPACING_LG}>
                    <BookBySuggestedTherapistsOverviews
                      onSubmit={onSubmit}
                      toggleCompareTherapists={toggleCompareTherapists}
                    />
                    {canChangeTherapist && <HaveDoubts />}
                  </Flex>
                  <Flex $gap={SPACING_MD} $grow={1} $shrink={1}>
                    <Flex $align="center" $direction="row" $justify="space-between" $pl={SPACING_LG} $wrap="wrap">
                      {isPsychiatry ? (
                        <TranslationMarkdown colorName="darker" id="reservation.bookPsychiatrySession" kind="h3" />
                      ) : (
                        <TranslationMarkdown colorName="darker" id="reservation.bookFreeTherapySession" kind="h3" />
                      )}
                      <CompareCalendars
                        compareTherapists={compareTherapists}
                        suggestedTherapistsProfiles={suggestedTherapistsProfiles}
                        toggleCompareTherapists={toggleCompareTherapists}
                      />
                    </Flex>
                    <Flex $gap={SPACING_SM} $grow={1} $shrink={1}>
                      <CarouselProvider length={length}>
                        <TimeSlotDaysTherapists
                          compareTherapists={compareTherapists}
                          initialSelectedSlot={initialSelectedSlot}
                          length={length}
                          selectedTherapist={selectedTherapist}
                          slice={slice}
                          slots={data}
                          suggestedTherapistsProfiles={suggestedTherapistsProfiles}
                        />
                      </CarouselProvider>
                      <Flex $align="flex-end" $gap={SPACING_2XS} $grow={1}>
                        <ReservationSelectedSlot
                          isFree={!isPsychiatry}
                          suggestedTherapistsProfiles={suggestedTherapistsProfiles}
                        />

                        <ReservationDurationText />
                      </Flex>
                    </Flex>
                    <Flex $direction="row" $justify="flex-end">
                      <Flex $basis={170}>
                        <ReservationSubmitButton />
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </ReservationForm>
            </MountOn>
          </Flex>
        </AvailabilityDayDropdownProvider>
      </CarouselProvider>
    </ScrollRestoreProvider>
  )
}
