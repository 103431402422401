import { range } from 'arrays'
import { Text } from 'ui'
import { type SelectOption } from '~/components/SelectNew/types'
import { Translation } from '~/components/Translation'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { ReactHookFormSelect } from '../components'

type Props = {
  defaultValue?: number
}

type SelectOptionNumber = SelectOption<number>

export const FieldTherapySessionRepeatCount = ({ defaultValue = 2 }: Props) => {
  const {
    errors: { required },
  } = useFormTranslations()

  const options: SelectOptionNumber[] = range(19, (value) => ({
    label: (value + 2).toString(),
    value: value + 2,
  }))

  return (
    <>
      <Text colorName="grey-80" fontWeight="400" kind="caption">
        <Translation id="therapySession.schedule.repeatCount" />
      </Text>

      <ReactHookFormSelect defaultValue={String(defaultValue)} name="repeatCount" rules={{ required }}>
        {options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </ReactHookFormSelect>
    </>
  )
}
