import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useChatMessage } from '../../hooks/useChatMessage'
import { CustomMessageWrapper } from './CustomMessageWrapper'

export const MessagePatientChurn = () => {
  const group = useUserActingAsGroup()

  const { message } = useChatMessage<'SYSTEM_PATIENT_CHURN'>()

  const churnReasonDefinitive = message.srns?.payload.churnReasonDefinitive
  const churnReason = message.srns?.payload.churnReason

  if (group === 'saas-therapist') {
    return null
  }

  if (!churnReasonDefinitive) {
    return (
      <CustomMessageWrapper>
        <Flex pb={8}>
          <Text fontWeight="600" kind="caption">
            <Translation id={`systemMessage.${group}.patientChurn.notDefinitive.title`} />
          </Text>
        </Flex>
        <TranslationMarkdown id={`systemMessage.${group}.patientChurn.notDefinitive.body`} kind="caption" />
      </CustomMessageWrapper>
    )
  }

  if (churnReason === 'SOLVED') {
    return (
      <CustomMessageWrapper>
        <Flex pb={8}>
          <Text fontWeight="600" kind="caption">
            <Translation id={`systemMessage.${group}.patientChurn.solved.title`} />
          </Text>
        </Flex>
        <TranslationMarkdown id={`systemMessage.${group}.patientChurn.solved.body`} kind="caption" />
      </CustomMessageWrapper>
    )
  }

  return (
    <CustomMessageWrapper>
      <Flex pb={8}>
        <Text fontWeight="600" kind="caption">
          <Translation id={`systemMessage.${group}.patientChurn.definitive.title`} />
        </Text>
      </Flex>
      <TranslationMarkdown id={`systemMessage.${group}.patientChurn.definitive.body`} kind="caption" />
    </CustomMessageWrapper>
  )
}
