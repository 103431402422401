import {
  PathCoaching,
  PathCouples,
  PathDCA,
  PathMyself,
  PathNutrition,
  PathPsychiatry,
  PathSexology,
} from 'illustrations'
import { type ReactElement } from 'react'
import { type TherapyTherapyPathType } from '~/types/graphql'

const THERAPY_PATH_ILLUSTRATION_BY_THERAPY_PATH_TYPE: Record<TherapyTherapyPathType, ReactElement> = {
  COUPLES_PSYCHOTHERAPY: <PathCouples />,
  MYSELF_PSYCHIATRY: <PathPsychiatry />,
  MYSELF_PSYCHOTHERAPY: <PathMyself />,
  PATH_ASSERTIVE_COMMUNICATION: <PathMyself />,
  PATH_COACHING: <PathCoaching />,
  PATH_SEXOLOGY: <PathSexology />,
  PATH_SLEEP: <PathMyself />,
  PATH_NUTRITION_WEIGHT_LOSS: <PathNutrition />,
  PATH_NUTRITION_DCA: <PathDCA />,
}

export const getTherapyPathIllustration = (therapyPathType: TherapyTherapyPathType) =>
  THERAPY_PATH_ILLUSTRATION_BY_THERAPY_PATH_TYPE[therapyPathType]
