import { Flex } from 'cdk'
import { SPACING_XS } from 'design-tokens'
import { useFeatureFlagsByFingerprint } from '~/domains/featureFlags'
import { FieldMarketingEmails } from '~/domains/react-hook-form/fields/FieldMarketingEmails'
import { FieldProfilation } from '~/domains/react-hook-form/fields/FieldProfilation'
import { FieldTermsAndConditions } from '~/domains/react-hook-form/fields/FieldTermsAndConditions'

export const SignupTermsAndConditions = () => {
  const { isVariant1 } = useFeatureFlagsByFingerprint()

  const ffSignupWithProfilationCheckbox = !isVariant1('ff_signup_without_profilation_checkbox')

  return (
    <Flex $gap={SPACING_XS}>
      <FieldMarketingEmails />
      <FieldTermsAndConditions />
      {ffSignupWithProfilationCheckbox && <FieldProfilation />}
    </Flex>
  )
}
