import { addSeconds, getHours, getMinutes, isAfter } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { useEffect, useMemo } from 'react'
import { type CalendarEventWithNonNullableTherapySession } from '~/domains/availabilities/calendar/types'
import { ReactHookFormProvider, useReactHookForm } from '~/domains/react-hook-form'
import { type BatchRecurrencyRepeat, type RecoverableTherapySession } from '~/types/graphql'
import { getTherapySessionCancellationPolicyInSeconds } from '~/utils/getTherapySessionCancellationPolicyInSeconds'
import { ModalBookTherapySessionByParent } from './components/ModalBookTherapySessionByParent'
import { ModalBookTherapySessionByParentUnder24 } from './components/ModalBookTherapySessionByParentUnder24'
import { type FormValues } from './types/formValues'

type Props = {
  onClose: () => void
  onScheduled: () => void
  recoverableTherapySession?: RecoverableTherapySession
  slot: CalendarEventWithNonNullableTherapySession
  therapyId: string
}

const repeatDefault: BatchRecurrencyRepeat = 'WEEK'

export const BookTherapySessionByParentModals = ({
  onClose,
  onScheduled,
  recoverableTherapySession,
  slot,
  therapyId,
}: Props) => {
  const isTherapySessionRecoverable = !!recoverableTherapySession

  const isRecoverableTherapySessionDeletedUnder24h =
    (isTherapySessionRecoverable &&
      recoverableTherapySession?.deletedAt &&
      pipe(
        recoverableTherapySession.deletedAt,
        addSeconds(getTherapySessionCancellationPolicyInSeconds(null)),
        isAfter(recoverableTherapySession.startAt),
      )) ||
    false

  const isTherapySessionRecoveryMandatory =
    !isRecoverableTherapySessionDeletedUnder24h ||
    recoverableTherapySession?.therapistEmergencyReason === 'HEALTH_ISSUE' ||
    recoverableTherapySession?.therapistEmergencyReason === 'FAMILY_ISSUE'

  const defaultStartAtHours = pipe(slot.start, getHours)

  const defaultStartAtMinutes = pipe(slot.start, getMinutes)

  const formDefaultValues = useMemo(
    () => ({
      recoverableTherapySessionId: recoverableTherapySession?.id,
      recoverTherapySession: isTherapySessionRecoverable && isTherapySessionRecoveryMandatory,
      recurringTherapy: false,
      repeat: repeatDefault,
      repeatCount: '2',
      repeatEvery: '1',
      startAtHours: defaultStartAtHours,
      startAtMinutes: defaultStartAtMinutes,
    }),
    [
      defaultStartAtHours,
      defaultStartAtMinutes,
      isTherapySessionRecoveryMandatory,
      isTherapySessionRecoverable,
      recoverableTherapySession,
    ],
  )

  const form = useReactHookForm<FormValues>({
    defaultValues: formDefaultValues,
  })

  // Note: we need to reset form when default values change because it means we are updating a new therapy session; without this the the fields will contain previous session data
  useEffect(() => {
    form.reset(formDefaultValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDefaultValues])

  return (
    <>
      <ReactHookFormProvider {...form}>
        <ModalBookTherapySessionByParent
          isTherapySessionRecoverable={isTherapySessionRecoverable}
          isTherapySessionRecoveryMandatory={isTherapySessionRecoveryMandatory}
          onBookingSuccess={onScheduled}
          onClose={onClose}
          slot={slot}
          therapyId={therapyId}
        />

        <ModalBookTherapySessionByParentUnder24
          onBookingSuccess={onScheduled}
          onClose={onClose}
          slot={slot}
          therapyId={therapyId}
        />
      </ReactHookFormProvider>
    </>
  )
}
