import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useProductUserFeedbackAnswerUpsert } from '~/domains/productUserFeedback/hooks/useProductUserFeedbackAnswerUpsert'
import { ReactHookFormTextArea } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useToasts } from '~/hooks/useToasts'
import { type StepProps, type TechnicalFeedbackPsychotherapy } from '../../types'
import { BackButton } from '../BackButton'
import { NextButton } from '../NextButton'
import { TechnicalFeedbackForm } from '../TechnicalFeedbackForm'

const max = 600

export const TechnicalFeedbackStepBad = ({ technicalFeedbackId }: StepProps) => {
  const history = useHistory()

  const { addToast } = useToasts()

  const { productUserFeedbackAnswerUpsert } = useProductUserFeedbackAnswerUpsert(technicalFeedbackId)

  const onSubmit = useCallback(
    async (values: TechnicalFeedbackPsychotherapy) => {
      try {
        const productUserFeedbackAnswerUpsertResponse = await productUserFeedbackAnswerUpsert({
          id: 'BAD',
          value: values.bad,
        })

        if (!productUserFeedbackAnswerUpsertResponse.data?.productUserFeedbackAnswerUpsert?.id) {
          addToast({
            translationId: 'reviews.askReviewModal.bad.unableToSaveResponse',
            type: 'alert',
          })

          return
        }

        history.push('/end')
      } catch (error) {
        addToast({
          translationId: 'reviews.askReviewModal.bad.unableToSaveResponse',
          type: 'alert',
        })
      }
    },
    [addToast, history, productUserFeedbackAnswerUpsert],
  )

  const { placeholders } = useFormTranslations()

  return (
    <TechnicalFeedbackForm onSubmit={onSubmit}>
      <OverflowAuto>
        <Flex pt={16}>
          <Text colorName="black" fontWeight="600" kind="h3">
            <Translation id="technicalFeedback.bad.title" />
          </Text>
        </Flex>
        <Flex pt={16}>
          <Text colorName="black" kind="paragraph">
            <Translation id="technicalFeedback.bad.body" />
          </Text>
        </Flex>
        <Flex pt={16}>
          <ReactHookFormTextArea
            max={max}
            minHeight="228px"
            name="bad"
            placeholder={placeholders.technicalFeedback}
            rules={{ max }}
          />
        </Flex>
      </OverflowAuto>
      <Flex pt={24}>
        <NextButton />
      </Flex>
      <Flex pt={8}>
        <BackButton />
      </Flex>
    </TechnicalFeedbackForm>
  )
}
