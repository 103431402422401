import { Text } from 'ui'
import { Button, Flex, Form, OverflowAuto } from 'ui-deprecated'
import {
  type FormValues,
  TherapistChurnReasonFields,
  type TherapistChurnReasonFieldsProps,
} from '~/components/TherapistChurnReasonFields'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useReactHookFormContext } from '~/domains/react-hook-form'

type Props = {
  loading: boolean
  onSubmit: (values: FormValues) => Promise<void>
  patientFullName: string
}

const options: TherapistChurnReasonFieldsProps['options'] = [
  {
    availableFor: [],
    description: 'therapistChurnReasonModal.option.description.pendingChangeTherapist',
    title: 'therapistChurnReasonModal.option.title.pendingChangeTherapist',
    value: 'PENDING_CHANGE_THERAPIST',
  },
  {
    availableFor: [],
    description: 'therapistChurnReasonModal.option.description.solved',
    title: 'therapistChurnReasonModal.option.title.solved',
    value: 'SOLVED',
  },
  {
    availableFor: ['COACH', 'PSYCHOTHERAPIST'],
    description: 'therapistChurnReasonModal.option.description.bonusPsicologo',
    title: 'therapistChurnReasonModal.option.title.bonusPsicologo',
    value: 'BONUS_PSICOLOGO',
  },
  {
    availableFor: [],
    description: 'therapistChurnReasonModal.option.description.ghosting',
    title: 'therapistChurnReasonModal.option.title.ghosting',
    value: 'GHOSTING',
  },
  {
    availableFor: [],
    description: 'therapistChurnReasonModal.option.description.pathologyOutOfScope',
    title: 'therapistChurnReasonModal.option.title.pathologyOutOfScope',
    value: 'PATHOLOGY_OUT_OF_SCOPE',
  },
  {
    availableFor: [],
    description: 'therapistChurnReasonModal.option.description.noInfo',
    title: 'therapistChurnReasonModal.option.title.noInfo',
    value: 'NO_INFO',
  },
  {
    availableFor: [],
    description: 'therapistChurnReasonModal.option.description.economic',
    title: 'therapistChurnReasonModal.option.title.economic',
    value: 'ECONOMIC',
  },
  {
    availableFor: [],
    description: 'therapistChurnReasonModal.option.description.therapyInPerson',
    title: 'therapistChurnReasonModal.option.title.therapyInPerson',
    value: 'THERAPY_IN_PERSON',
  },
  {
    availableFor: [],
    description: 'therapistChurnReasonModal.option.description.browsing',
    title: 'therapistChurnReasonModal.option.title.browsing',
    value: 'BROWSING',
  },
  {
    availableFor: [],
    description: 'therapistChurnReasonModal.option.description.missingAuthorizationUnderage',
    title: 'therapistChurnReasonModal.option.title.missingAuthorizationUnderage',
    value: 'MISSING_AUTHORIZATION_UNDERAGE',
  },
  {
    availableFor: ['PSYCHIATRIST'],
    description: 'therapistChurnReasonModal.option.description.nonContinuousTreatment',
    title: 'therapistChurnReasonModal.option.title.nonContinuousTreatment',
    value: 'NON_CONTINUOUS_TREATMENT',
  },
  {
    availableFor: ['PSYCHIATRIST'],
    description: 'therapistChurnReasonModal.option.description.neededCertificateOnly',
    title: 'therapistChurnReasonModal.option.title.neededCertificateOnly',
    value: 'NEEDED_CERTIFICATE_ONLY',
  },
]

export const TherapistChurnReasonModalStop = ({ loading = false, onSubmit, patientFullName }: Props) => {
  const form = useReactHookFormContext<FormValues>()

  return (
    <Form grow={1} onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
      <Text kind="paragraph">2 / 2</Text>
      <Flex py={8}>
        <Text fontWeight="600" kind="h2">
          <Translation id="therapistChurnReasonModal.proceed.title" />
        </Text>
      </Flex>
      <Flex pb={24}>
        <TranslationMarkdown
          colorName="black"
          id="therapistChurnReasonModal.proceed.subtitle"
          kind="paragraph"
          values={{ patientFullName }}
        />
      </Flex>

      <OverflowAuto>
        <TherapistChurnReasonFields options={options} />
      </OverflowAuto>

      <Flex pt={16}>
        <Button disabled={!form.formState.isValid} kind="primary" loading={loading} type="submit">
          <Translation id="actions.confirm" />
        </Button>
      </Flex>
    </Form>
  )
}
