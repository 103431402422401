import { Flex } from 'cdk'
import { SPACING_4XS, SPACING_SM } from 'design-tokens'
import { Icon, WarningCircle } from 'icons'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { MenuExternalLink } from './MenuExternalLink'

export const MenuItemEmergency = () => (
  <MenuExternalLink to="https://www.serenis.it/hai-una-emergenza">
    <Flex $pr={SPACING_SM}>
      <Icon Svg={WarningCircle} colorName="neutral-60" size={24} />
    </Flex>

    <Flex $grow={1} $pt={SPACING_4XS} $shrink={1}>
      <Text kind="paragraph">
        <Translation id="actions.emergency" />
      </Text>
    </Flex>
  </MenuExternalLink>
)
