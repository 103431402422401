import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertHasFeature } from '~/domains/auth/components/AssertHasFeature'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'

const LazyInvite = loadable(() => import('./View'), {
  resolveComponent: (components) => components.View,
})

export const InvitePage: Page = {
  exact: true,
  id: 'invite',
  paths: ['/invite', '/settings/invite'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: true,
    seoKey: 'invite',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient target="/">
        <AssertHasFeature feature="MGM" target="/">
          {children}
        </AssertHasFeature>
      </AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <LazyInvite />,
}
