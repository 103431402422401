import { Text } from 'ui'
import { Button, Flex, MaxWidth640px } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { type EndedViewProps } from '.'

type Props = Omit<
  EndedViewProps,
  'firstName' | 'isEvaluationCompleted' | 'onEvaluateTherapySessionClick' | 'onShareStickerClick'
>

export const EndedTherapist = ({ onGoBackClick }: Props) => (
  <MaxWidth640px align="stretch" grow={1} justify="center" p={24}>
    <Text fontWeight="600" kind="h2" textAlign="center">
      <Translation id="therapySessionLive.waitingRoom.therapist.ended.title" />
    </Text>
    <Flex pb={32} pt={8}>
      <Text fontWeight="400" kind="paragraph" newLine textAlign="center">
        <Translation id="therapySessionLive.waitingRoom.therapist.ended.paragraph" />
      </Text>
    </Flex>
    <Flex mdPx={104}>
      <Button kind="primary" onClick={onGoBackClick}>
        <Translation id="actions.backToPersonalArea" />
      </Button>
    </Flex>
  </MaxWidth640px>
)
