import { useMemo } from 'react'
import { useTherapistTherapies } from '~/domains/therapies/TherapistTherapiesProvider'
import { usePatientId } from '~/hooks/usePatientId'
import { type TherapistTherapiesQuery } from '~/types/graphql'

export const usePatientsManagementTherapyNullable = () => {
  const { therapies } = useTherapistTherapies()
  const patientId = usePatientId()

  return useMemo(() => therapies.find(({ patient }) => patient?.id === patientId) ?? null, [patientId, therapies])
}

export const usePatientsManagementTherapy = () => {
  const therapy = usePatientsManagementTherapyNullable()

  const patient = therapy?.patient as NonNullable<TherapistTherapiesQuery['therapies'][number]['patient']>

  return {
    ...(therapy as NonNullable<ReturnType<typeof usePatientsManagementTherapyNullable>>),
    patient,
  }
}

export const usePatientsManagementTherapyByPatientId = (patientId: string) => {
  const { therapies } = useTherapistTherapies()

  const therapy = useMemo(() => therapies.find(({ patient }) => patient?.id === patientId), [patientId, therapies])
  const patient = therapy?.patient as NonNullable<TherapistTherapiesQuery['therapies'][number]['patient']>

  return {
    ...(therapy as NonNullable<ReturnType<typeof usePatientsManagementTherapyNullable>>),
    patient,
  }
}
