import loadable from '@loadable/component'
import { AssertPatientOrAnonymous } from '~/domains/auth/components/AssertPatientOrAnonymous'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'

const LazyFlowStandardNutritionDca = loadable(() => import('~/domains/flow/flows/standard/nutritionDca'), {
  resolveComponent: (components) => components.FlowStandardNutritionDca,
})

export const StartStandardNutritionDcaPage: Page = {
  exact: true,
  id: 'start.form.nutritionDca.standard',
  paths: ['/start/standard/nutrition-dca'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: false,
    seoKey: 'start',
    title: null,
  },
  PageGuard: ({ children }) => <AssertPatientOrAnonymous target="/">{children}</AssertPatientOrAnonymous>,
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <LazyFlowStandardNutritionDca />,
}
