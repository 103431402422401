import { assertNever } from 'functions'
import { type TranslationId } from '~/i18n/types'
import { type ProductUserFeedbackClinicalBondAnswerId, type TherapyTherapyPathType } from '~/types/graphql'

type Return = Extract<
  TranslationId,
  `clinicalBond.modal.answer.${string}` | `clinicalBond.modal.answer.coaching.${string}`
>

type Props = {
  id: ProductUserFeedbackClinicalBondAnswerId
  therapyPathType: TherapyTherapyPathType
}

export const getClinicalBondAnswerTranslationId = ({ id, therapyPathType }: Props): Return => {
  if (therapyPathType === 'PATH_COACHING') {
    switch (id) {
      case 'INFO':
        return 'clinicalBond.modal.answer.coaching.info'
      case 'LISTEN':
        return 'clinicalBond.modal.answer.coaching.listen'
      case 'MANAGEMENT':
        return 'clinicalBond.modal.answer.coaching.management'
      case 'OVERALL':
        return 'clinicalBond.modal.answer.coaching.overall'
      case 'RESPECT':
        return 'clinicalBond.modal.answer.coaching.respect'
      default:
        return assertNever(id)
    }
  }

  switch (id) {
    case 'INFO':
      return 'clinicalBond.modal.answer.info'
    case 'LISTEN':
      return 'clinicalBond.modal.answer.listen'
    case 'MANAGEMENT':
      return 'clinicalBond.modal.answer.management'
    case 'OVERALL':
      return 'clinicalBond.modal.answer.overall'
    case 'RESPECT':
      return 'clinicalBond.modal.answer.respect'
    default:
      return assertNever(id)
  }
}
