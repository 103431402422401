import { gql, useQuery, type WatchQueryFetchPolicy } from '@apollo/client'
import { type GetClinicalDiaryByPatientIdQuery, type GetClinicalDiaryByPatientIdQueryVariables } from '~/types/graphql'
import { getTherapySessionsProxyApi } from '~/utils/getProxyApi'

const QUERY = gql`
  query getClinicalDiaryByPatientId($patientId: String!) {
    getClinicalDiaryByPatientId(patientId: $patientId) {
      patientId
      downloadUrl
      items {
        downloadUrl
        id
        notes
        startAt
      }
    }
  }
`

export const useClinicalDiary = (patientId: string, fetchPolicy: WatchQueryFetchPolicy = 'cache-first') => {
  const { data, error, loading, refetch, updateQuery } = useQuery<
    GetClinicalDiaryByPatientIdQuery,
    GetClinicalDiaryByPatientIdQueryVariables
  >(QUERY, {
    variables: {
      patientId,
    },
    fetchPolicy,
  })

  const items = (data?.getClinicalDiaryByPatientId?.items || []).map((item) => ({
    ...item,
    downloadUrl: getTherapySessionsProxyApi(item.downloadUrl),
  }))

  const downloadUrl =
    data?.getClinicalDiaryByPatientId?.downloadUrl &&
    getTherapySessionsProxyApi(data.getClinicalDiaryByPatientId.downloadUrl)

  return {
    error: !!error,
    items,
    downloadUrl,
    loading,
    refetch,
    updateQuery,
  }
}
