import { type ToolbarProps } from 'react-big-calendar'
import { Flex, Loader } from 'ui-deprecated'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { TherapistFetchProvider } from '~/hooks/useTherapistFetch'
import { useTherapistCalendar } from '../hooks/useTherapistCalendar'
import { TherapistCalendarMonthlyHours } from './TherapistCalendarMonthlyHours'
import { TherapistCalendarToolbarNavigation } from './TherapistCalendarToolbarNavigation'
import { TherapistCalendarWeeklyHours } from './TherapistCalendarWeeklyHours'

const Loading = () => null

type Props = Pick<ToolbarProps, 'label' | 'onNavigate'>

export const TherapistCalendarToolbar = ({ label, onNavigate }: Props) => {
  const { id } = useCurrentUser()
  const { loading } = useTherapistCalendar()

  return (
    <Flex lgAlign="center" lgDirection="row" lgJustify="space-between" lgWrap="wrap" pb={24}>
      <Flex lgBasis="40%">
        <TherapistCalendarToolbarNavigation label={label} onNavigate={onNavigate} />
      </Flex>

      {loading && (
        <Flex align="center" basis="48px" lgBasis="20%" lgPb={0} lgPr={0} lgPx={16} mdPr={16} pb={24}>
          <Loader colorName="purple08" size={24} />
        </Flex>
      )}

      <Flex align="flex-end" className="calendar-weekly-hours" lgBasis="40%">
        <TherapistFetchProvider id={id} loadingElement={<Loading />}>
          <TherapistCalendarWeeklyHours />

          <TherapistCalendarMonthlyHours />
        </TherapistFetchProvider>
      </Flex>
    </Flex>
  )
}
