import { gql, useQuery } from '@apollo/client'
import {
  type FindTherapySharedInformationQuery,
  type FindTherapySharedInformationQueryVariables,
} from '~/types/graphql'

const QUERY = gql`
  query findTherapySharedInformation($therapyId: String!, $type: TherapyTherapyPathType!) {
    findTherapySharedInformation(therapyId: $therapyId, type: $type) {
      id
      medicalHistory
      pastPsychiatricPath
      psychotropicDrugs
      reason
      therapist {
        id
        email
        fullName
      }
    }
  }
`

export const useFindTherapySharedInformation = ({ therapyId, type }: FindTherapySharedInformationQueryVariables) => {
  const { data, error, loading, refetch, updateQuery } = useQuery<
    FindTherapySharedInformationQuery,
    FindTherapySharedInformationQueryVariables
  >(QUERY, {
    variables: {
      therapyId,
      type,
    },
    fetchPolicy: 'cache-first',
  })

  return {
    error: !!error,
    data: data?.findTherapySharedInformation ?? null,
    loading,
    refetch,
    updateQuery,
  }
}
