import { OverflowAuto } from 'cdk'
import { useCallback, useEffect } from 'react'
import { Button, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useRootHistory } from '~/hooks/useRootHistory'
import { getRoute } from '~/utils/getRoute'
import { useProductUserFeedbackComplete } from '../productUserFeedback/hooks/useProductUserFeedbackComplete'

export const Route4to5Score = ({ therapyId, feedbackId }: { therapyId: string; feedbackId: string }) => {
  const rootHistory = useRootHistory()

  const { productUserFeedbackComplete } = useProductUserFeedbackComplete(feedbackId)

  const handleOnClick = useCallback(() => {
    rootHistory.push(getRoute('/'))
  }, [rootHistory])

  const handleBooking = useCallback(() => {
    rootHistory.push(getRoute(`/therapy-session/booking/${therapyId}`))
  }, [rootHistory, therapyId])

  useEffect(() => {
    productUserFeedbackComplete()
  }, [productUserFeedbackComplete])

  return (
    <OverflowAuto $gap={16}>
      <Text colorName="black" fontWeight="600" kind="h3">
        <Translation id="firstSessionFeedback.4to5.title" />
      </Text>
      <Text colorName="black" kind="paragraph">
        <Translation id="firstSessionFeedback.4to5.body" />
      </Text>
      <Button kind="primary" onClick={handleBooking}>
        <Translation id="actions.book" />
      </Button>
      <Button kind="tertiary" onClick={handleOnClick}>
        <Translation id="actions.backToPersonalArea" />
      </Button>
    </OverflowAuto>
  )
}
