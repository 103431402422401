import { type ReactNode } from 'react'
import { type SubmitHandler } from 'react-hook-form'
import { Form } from 'ui-deprecated'
import { useReactHookFormContext } from '~/domains/react-hook-form'

/**
 * @description `any` is needed since Form is used twice with different values
 */
type Props = {
  children?: ReactNode
  onSubmit: SubmitHandler<any>
}

export const TechnicalFeedbackForm = ({ children, onSubmit }: Props) => {
  const form = useReactHookFormContext()

  return (
    <Form grow={1} id="technicalFeedback" onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
      {children}
    </Form>
  )
}
