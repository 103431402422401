import { type PropsWithChildren } from 'react'
import { Channel, type ChannelProps } from 'stream-chat-react'
import { ChannelDateSeparator } from '~/domains/chat/components/ChannelDateSeparator'
import { FileUploadIcon } from '~/domains/chat/components/ChatFileUploadIcon'
import { CustomMessage } from './CustomMessage'

type Props = Omit<
  PropsWithChildren<ChannelProps>,
  'DateSeparator' | 'FileUploadIcon' | 'CustomMessage' | 'TypingIndicator'
>

const TypingIndicator = () => null

export const ChatChannel = (props: Props) => (
  <Channel
    {...props}
    DateSeparator={ChannelDateSeparator}
    FileUploadIcon={FileUploadIcon}
    TypingIndicator={TypingIndicator}
    VirtualMessage={CustomMessage}
  />
)
