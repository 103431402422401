type TechnicalFeedbackRoute =
  | '/bad'
  | '/context'
  | '/duration'
  | '/ease'
  | '/end'
  | '/message'
  | '/path'
  | '/punctuality'
  | '/quality'

export const getTechnicalFeedbackRoute = (route: TechnicalFeedbackRoute): TechnicalFeedbackRoute => route
