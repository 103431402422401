import { useEffect } from 'react'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { useLanguage } from '~/i18n/hooks/useLanguage'

const FeaturebaseSurveyWidgetTrigger = () => {
  const { language } = useLanguage()

  useEffect(() => {
    if (!(typeof window.Featurebase === 'function')) {
      return
    }

    window.Featurebase('initialize_survey_widget', {
      locale: language,
      placement: 'bottom-right',
      organization: 'serenis',
      theme: 'light',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export const FeaturebaseSurveyWidget = () => {
  const { isAuthenticated } = useAuthState()

  if (!isAuthenticated) {
    return null
  }

  return <FeaturebaseSurveyWidgetTrigger />
}
