import { Flex } from 'cdk'
import { type ComponentProps, useMemo } from 'react'
import { useDiagnosis } from '~/domains/diagnosis/hooks/useDiagnosis'
import { isNutrition } from '~/utils/therapyPaths'
import { type DiagnosisKeyValue } from './DiagnosisKeyValue'
import { DiagnosisKeyValuesList } from './DiagnosisKeyValuesList'
import { DiagnosisSuggestionBox } from './DiagnosisSuggestionBox'

export const DiagnosisPreview = () => {
  const { diagnosis, isEditable, therapyPathType } = useDiagnosis()

  const stateItems: ComponentProps<typeof DiagnosisKeyValue>[] = useMemo(
    () => [
      {
        keyTranslationId: 'patientDetail.diagnosis.state.facing',
        mandatory: true,
        value: diagnosis?.pathologies.map(({ pathology: { name } }) => name).join(', '),
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.state.otherInfo',
        value: diagnosis?.otherInfo,
      },
    ],
    [diagnosis],
  )

  const infoItems: ComponentProps<typeof DiagnosisKeyValue>[] = useMemo(
    () => [
      {
        keyTranslationId: 'patientDetail.diagnosis.info.genderIdentity',
        value: diagnosis?.genderIdentity?.name,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.age',
        value: diagnosis?.age,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.workProfession',
        value: diagnosis?.workProfession?.name,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.profession',
        value: diagnosis?.profession,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.degree',
        value: diagnosis?.degree,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.socioCulturalContext',
        value: diagnosis?.socioCulturalContext,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.country',
        value: diagnosis?.country?.name,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.language',
        value: diagnosis?.language?.name,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.pharmacologicalTreatment',
        value: diagnosis?.pharmacologicalTreatment,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.historicalDiagnosis',
        value: diagnosis?.historicalDiagnosis,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.healthIssue',
        value: diagnosis?.healthIssue,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.psychiatricVulnerability',
        value: diagnosis?.psychiatricVulnerability,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.socialAndFamilyNetwork',
        value: diagnosis?.socialAndFamilyNetwork,
      },
    ],
    [diagnosis],
  )

  const filteredItems = isNutrition(therapyPathType)
    ? infoItems.filter(
        ({ keyTranslationId }) =>
          keyTranslationId === 'patientDetail.diagnosis.info.psychiatricVulnerability' ||
          keyTranslationId === 'patientDetail.diagnosis.info.socioCulturalContext',
      )
    : infoItems

  return (
    <>
      <DiagnosisKeyValuesList
        isEditable={isEditable}
        items={stateItems}
        titleTranslationId="patientDetail.diagnosis.state"
      >
        {isEditable && (
          <Flex $pt={16}>
            <DiagnosisSuggestionBox bodyTranslationId="suggestionBox.diagnosis.body" />
          </Flex>
        )}
      </DiagnosisKeyValuesList>

      <Flex $pt={24}>
        <DiagnosisKeyValuesList
          isEditable={isEditable}
          items={filteredItems}
          titleTranslationId="patientDetail.diagnosis.info"
        />
      </Flex>
    </>
  )
}
