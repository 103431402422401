import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useChatPatient } from '~/domains/chat/hooks/useChatPatient'
import { CustomMessageWrapper } from './CustomMessageWrapper'

export const MessageTherapistPatientTurned18 = () => {
  const group = useUserActingAsGroup()
  const { user } = useChatPatient()

  if (!user) {
    return null
  }

  if (group === 'therapist') {
    return (
      <CustomMessageWrapper background="yellow-20" borderColor="yellow-40" fontColorName="black" testId="chat-message">
        <Flex pb={8}>
          <Text fontWeight="600" kind="caption">
            <Translation
              id="systemMessage.therapist.patientTurned18.title"
              values={{ patientFullName: user.fullName }}
            />{' '}
            🎂
          </Text>
        </Flex>
        <Text fontWeight="400" kind="caption">
          <TranslationMarkdown id="systemMessage.therapist.patientTurned18.body" kind="paragraph" />
        </Text>
      </CustomMessageWrapper>
    )
  }

  return null
}
