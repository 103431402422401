import { Icon, Xmark } from 'icons'
import { useCallback } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { Flex, PositionAbsolute, PositionRelative } from 'ui-deprecated'
import { TertiaryButtonIconOnly } from '~/components/TertiaryButton/TertiaryButtonIconOnly'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { AssertChatEligibleEmptyState } from '~/domains/chat/components/AssertChatEligibleEmptyState'
import { ChatChannelHeaderWrapper } from '~/domains/chat/components/ChatChannelHeaderWrapper'
import { ChatMessageInput } from '~/domains/chat/components/ChatMessageInput'
import { ChatMessageList } from '~/domains/chat/components/ChatMessageList'
import { ChatMessageListAttachments } from '~/domains/chat/components/ChatMessageListAttachments'
import { ChatMessageListMemoryRouter, routes } from '~/domains/chat/components/ChatMessageListMemoryRouter'
import { ChatTab } from '~/domains/chat/components/ChatTab'
import { ChannelHeaderPatient } from '~/domains/chat/components/PatientChat/ChannelHeaderPatient'
import { ChannelHeaderTherapist } from '~/domains/chat/components/TherapistChat/ChannelHeaderTherapist'
import { useVideocallSidePanel } from '../../../hooks/useVideocallSidePanel'

const StyledPositionAbsolute = styled(PositionAbsolute)`
  z-index: 2;
`

export const VideocallChat = () => {
  const group = useUserActingAsGroup()
  const isPatient = group === 'patient'

  const { setIsChatOpen } = useVideocallSidePanel()

  const onClick = useCallback(() => {
    setIsChatOpen(false)
  }, [setIsChatOpen])

  return (
    <PositionRelative grow={1} shrink={1}>
      <StyledPositionAbsolute right="8px" top="8px">
        <TertiaryButtonIconOnly onClick={onClick}>
          <Icon Svg={Xmark} size={24} />
        </TertiaryButtonIconOnly>
      </StyledPositionAbsolute>
      <Flex grow={1} mdBorderRadius={16} shrink={1}>
        <AssertChatEligibleEmptyState>
          <ChatMessageListMemoryRouter>
            <ChatChannelHeaderWrapper background={isPatient ? 'primary-10' : 'primary-10'}>
              <Flex pb={8}>{isPatient ? <ChannelHeaderPatient /> : <ChannelHeaderTherapist />}</Flex>
              <ChatTab />
            </ChatChannelHeaderWrapper>
            <Switch>
              <Route exact path={routes.messages}>
                <ChatMessageList />
                <ChatMessageInput />
              </Route>
              <Route exact path={routes.attachments}>
                <ChatMessageListAttachments />
              </Route>
            </Switch>
          </ChatMessageListMemoryRouter>
        </AssertChatEligibleEmptyState>
      </Flex>
    </PositionRelative>
  )
}
