import { addDays, differenceInDays, isSameDay, min, subDays } from 'date-fns'
import { format, FormatDateEnum, isBeforeOrEqual } from 'dates'
import { pipe } from 'fp-ts/function'
import { useCallback, useEffect, useState } from 'react'
import { type FoodJournalMood } from '~/types/graphql'
import { useLazyGetFoodJournalDays } from '../../hooks/useGetFoodJournalDays'
import { formatFoodJournalDay } from '../../utils/formatFoodJournalDay'
import { useFoodJournal } from '../FoodJournalContext'
import { ScrollableCalendar } from '../ScrollableCalendar'

const formatDay = format(FormatDateEnum.YEAR_MONTH_DAY)

export const CalendarSlider = () => {
  const [moods, setMoods] = useState<Record<string, FoodJournalMood | null>>({})
  const { patientId, currentDay } = useFoodJournal()

  const [fetchFoodJournalDays, { data }] = useLazyGetFoodJournalDays()

  useEffect(() => {
    const updatedMoods = data?.foodJournalDays.reduce(
      (acc, { day, mood }) => {
        acc[formatDay(new Date(day))] = mood
        return acc
      },
      {} as Record<string, FoodJournalMood | null>,
    )

    setMoods((prev) => ({ ...prev, ...updatedMoods }))
  }, [data])

  const handleCurrentDayChange = useCallback(
    async (visibleDays: Date[]) => {
      // We want to check absence of key, null keys are valid
      const datesToLoad = visibleDays.filter(
        (day) => !(formatDay(day) in moods) && pipe(day, isBeforeOrEqual(Date.now())),
      )

      if (datesToLoad.length > 0) {
        const earliestDate = datesToLoad[0]

        const extendedFromDate = subDays(earliestDate, 14)
        const extendedToDate = formatFoodJournalDay(min([addDays(currentDay, 14), Date.now()]))

        const { data } = await fetchFoodJournalDays({
          variables: {
            patientId,
            from: extendedFromDate,
            to: extendedToDate,
          },
        })

        const fetchedMoods = [...Array(differenceInDays(extendedToDate, extendedFromDate) + 1)].reduce(
          (acc, _, index) => {
            const current = addDays(extendedFromDate, index)

            acc[formatDay(current)] =
              data?.foodJournalDays.find(({ day }) => isSameDay(new Date(day), current))?.mood ?? null

            return acc
          },
          {} as Record<string, FoodJournalMood | null>,
        )

        setMoods((prev) => ({ ...prev, ...fetchedMoods }))
      }
    },
    [currentDay, fetchFoodJournalDays, moods, patientId],
  )

  return <ScrollableCalendar moods={moods} onCurrentDayChange={handleCurrentDayChange} />
}
