import { gql, useQuery } from '@apollo/client'
import { startOfDay } from 'date-fns'
import { type GetFoodJournalDayQuery, type GetFoodJournalDayQueryVariables } from '~/types/graphql'

export const GET_FOOD_JOURNAL_DAY_QUERY = gql`
  query getFoodJournalDay($patientId: String!, $day: DateTime!) {
    foodJournalDay(patientId: $patientId, day: $day) {
      day
      drinks {
        alcohol
        beverage
        water
      }
      id
      mood
      patientId
      meals {
        id
        description
        location
        date
        hungerBefore
        hungerAfter
        moodBefore
        moodAfter
        feelings
      }
    }
  }
`

type Params = {
  day: Date
  patientId: string
}

export const useGetFoodJournalDay = ({ day, patientId }: Params) => {
  const { data, loading } = useQuery<GetFoodJournalDayQuery, GetFoodJournalDayQueryVariables>(
    GET_FOOD_JOURNAL_DAY_QUERY,
    {
      variables: {
        day: startOfDay(day),
        patientId,
      },
      fetchPolicy: 'cache-first',
    },
  )
  const foodJournalDay = data?.foodJournalDay

  return { foodJournalDay, loading }
}
