import { gql, useQuery } from '@apollo/client'
import {
  type AvailableTimeSlotsByTherapistQuery,
  type AvailableTimeSlotsByTherapistQueryVariables,
} from '~/types/graphql'

const QUERY = gql`
  query availableTimeSlotsByTherapist(
    $endAt: Date!
    $startAt: Date!
    $therapyId: String!
    $therapyPathType: TherapyTherapyPathType!
    $userId: String!
  ) {
    availableTimeSlotsByTherapist(
      endAt: $endAt
      startAt: $startAt
      therapyId: $therapyId
      therapyPathType: $therapyPathType
      userId: $userId
    ) {
      endAt
      id
      startAt
      userId
    }
  }
`

export const useAvailableTimeSlotsByTherapist = ({
  endAt,
  startAt,
  therapyId,
  therapyPathType,
  userId,
}: AvailableTimeSlotsByTherapistQueryVariables) => {
  const { data, error, loading, refetch, updateQuery } = useQuery<
    AvailableTimeSlotsByTherapistQuery,
    AvailableTimeSlotsByTherapistQueryVariables
  >(QUERY, {
    variables: { endAt, startAt, therapyPathType, therapyId, userId },
    fetchPolicy: 'cache-and-network',
  })

  return {
    error: !!error,
    items: data?.availableTimeSlotsByTherapist || [],
    loading,
    refetch,
    updateQuery,
  }
}
