import { gql, useMutation } from '@apollo/client'
import {
  type AddFoodJournalMealMutation,
  type AddFoodJournalMealMutationVariables,
  type GetFoodJournalDayQuery,
  type GetFoodJournalDayQueryVariables,
} from '~/types/graphql'
import { GET_FOOD_JOURNAL_DAY_QUERY } from './useGetFoodJournalDay'

type PartialKeys<T, K extends keyof T> = Omit<T, K> & { [P in K]?: Partial<T[P]> }

const ADD_FOOD_JOURNAL_MEAL_MUTATION = gql`
  mutation addFoodJournalMeal($input: AddFoodJournalMealInput!) {
    addFoodJournalMeal(input: $input) {
      id
      day
      patientId
      meals {
        id
        description
        location
        date
        hungerBefore
        hungerAfter
        moodBefore
        moodAfter
        feelings
      }
    }
  }
`

export const useAddFoodJournalMeal = () => {
  const [addMeal] = useMutation<AddFoodJournalMealMutation, AddFoodJournalMealMutationVariables>(
    ADD_FOOD_JOURNAL_MEAL_MUTATION,
    {
      update: (cache, { data }) => {
        if (data == null) {
          return
        }

        const foodJournalDay = data.addFoodJournalMeal
        const { day, patientId } = foodJournalDay
        const variables = { patientId, day }

        const cachedFoodJournalDay = cache.readQuery<GetFoodJournalDayQuery, GetFoodJournalDayQueryVariables>({
          query: GET_FOOD_JOURNAL_DAY_QUERY,
          variables,
        })?.foodJournalDay

        if (cachedFoodJournalDay != null) {
          return
        }

        cache.writeQuery<PartialKeys<GetFoodJournalDayQuery, 'foodJournalDay'>, GetFoodJournalDayQueryVariables>({
          query: GET_FOOD_JOURNAL_DAY_QUERY,
          variables,
          data: { foodJournalDay },
        })
        cache.gc()
      },
    },
  )

  return addMeal
}
