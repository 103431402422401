import { gql, useQuery } from '@apollo/client'
import {
  type LatestClinicalBondByPatientIdQuery,
  type LatestClinicalBondByPatientIdQueryVariables,
} from '~/types/graphql'

const QUERY = gql`
  query latestClinicalBondByPatientId($patientId: String!) {
    latestClinicalBondByPatientId(patientId: $patientId) {
      id
      answers {
        __typename
        ... on LatestClinicalBondByPatientIdAnswerNumber {
          id
          valueNumber
        }
        ... on LatestClinicalBondByPatientIdAnswerString {
          id
          valueString
        }
      }
      score
    }
  }
`

export const useLatestClinicalBondByPatientId = (patientId: string) => {
  const { data, error, loading } = useQuery<
    LatestClinicalBondByPatientIdQuery,
    LatestClinicalBondByPatientIdQueryVariables
  >(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      patientId,
    },
  })

  return {
    error: error != null,
    latestClinicalBondByPatientId: data?.latestClinicalBondByPatientId ?? null,
    loading,
  }
}
