import { type ClinicalBondAverageRating } from '../utils/getClinicalBondAverageRating'

type ClinicalBondVariantRoute =
  | '/explaining'
  | '/feeling'
  | '/first'
  | '/other'
  | '/professionality'
  | `/end/${ClinicalBondAverageRating | ':score'}`

export const getClinicalBondVariantRoute = (route: ClinicalBondVariantRoute): ClinicalBondVariantRoute => route
