import { noop } from 'functions'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useProductUserFeedbackAnswerUpsert } from '~/domains/productUserFeedback/hooks/useProductUserFeedbackAnswerUpsert'
import { useProductUserFeedbackComplete } from '~/domains/productUserFeedback/hooks/useProductUserFeedbackComplete'
import { ReactHookFormTextArea } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useToasts } from '~/hooks/useToasts'
import { type ClinicalBondFormValues, type StepProps } from '../types'
import { getClinicalBondAverageRating } from '../utils/getClinicalBondAverageRating'
import { getClinicalBondRoute } from '../utils/getClinicalBondRoute'
import { BackButton } from './BackButton'
import { ClinicalBondForm } from './ClinicalBondForm'
import { NextButton } from './NextButton'

const max = 600

export const ClinicalBondStepInfo = ({ clinicalBondId }: StepProps) => {
  const history = useHistory()

  const { addToast } = useToasts()

  const { productUserFeedbackAnswerUpsert } = useProductUserFeedbackAnswerUpsert(clinicalBondId)
  const { productUserFeedbackComplete } = useProductUserFeedbackComplete(clinicalBondId)

  const onSubmit = useCallback(
    async (values: ClinicalBondFormValues) => {
      try {
        const productUserFeedbackAnswerUpsertResponse = await productUserFeedbackAnswerUpsert({
          id: 'INFO',
          value: values.info,
        })

        if (!productUserFeedbackAnswerUpsertResponse.data?.productUserFeedbackAnswerUpsert?.id) {
          addToast({
            translationId: 'reviews.askReviewModal.info.unableToSaveResponse',
            type: 'alert',
          })

          return
        }

        productUserFeedbackComplete().catch(noop)

        const rating = getClinicalBondAverageRating(values)

        history.push(getClinicalBondRoute(`/end/${rating}`))
      } catch (error) {
        addToast({
          translationId: 'reviews.askReviewModal.info.unableToSaveResponse',
          type: 'alert',
        })
      }
    },
    [addToast, history, productUserFeedbackAnswerUpsert, productUserFeedbackComplete],
  )

  const { placeholders } = useFormTranslations()

  return (
    <ClinicalBondForm onSubmit={onSubmit}>
      <OverflowAuto>
        <Text fontWeight="600" kind="h3">
          <Translation id="clinicalBond.info.title" />
        </Text>
        <Flex pt={16}>
          <ReactHookFormTextArea
            max={max}
            minHeight="228px"
            name="info"
            placeholder={placeholders.clinicalBond}
            rules={{ max }}
          />
        </Flex>
      </OverflowAuto>
      <Flex pt={24}>
        <NextButton />
      </Flex>
      <Flex pt={8}>
        <BackButton />
      </Flex>
    </ClinicalBondForm>
  )
}
