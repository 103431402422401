import { PaymentElement } from '@stripe/react-stripe-js'
import { type StripePaymentElementOptions } from '@stripe/stripe-js'
import { useCurrentUser } from '~/hooks/useCurrentUser'

const options: StripePaymentElementOptions = {
  fields: {
    billingDetails: 'auto',
  },
  layout: {
    type: 'accordion',
    radios: true,
  },
  terms: {
    card: 'never',
  },
  wallets: {
    applePay: 'auto',
    googlePay: 'auto',
  },
  paymentMethodOrder: ['card', 'apple_pay', 'google_pay', 'paypal'],
}

export const StripePaymentElement = () => {
  const { email, fullName } = useCurrentUser()

  return <PaymentElement options={{ ...options, defaultValues: { billingDetails: { email, name: fullName } } }} />
}
