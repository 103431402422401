import {
  Icon,
  type IconElement,
  type IconSize,
  PathCoachingSolid,
  PathCouplesSolid,
  PathMyselfSolid,
  PathNutritionDcaSolid,
  PathNutritionWeightLossSolid,
  PathPsychiatrySolid,
  PathSexologySolid,
} from 'icons'
import { type TherapyTherapyPathType } from '~/types/graphql'

type StartTherapyPathIconProps = {
  size: IconSize
  therapyPathType: TherapyTherapyPathType | null
}

const iconByTherapyPathIconVariant: Record<TherapyTherapyPathType, IconElement> = {
  COUPLES_PSYCHOTHERAPY: PathCouplesSolid,
  MYSELF_PSYCHIATRY: PathPsychiatrySolid,
  MYSELF_PSYCHOTHERAPY: PathMyselfSolid,
  PATH_COACHING: PathCoachingSolid,
  PATH_NUTRITION_DCA: PathNutritionDcaSolid,
  PATH_NUTRITION_WEIGHT_LOSS: PathNutritionWeightLossSolid,
  PATH_SEXOLOGY: PathSexologySolid,
  PATH_ASSERTIVE_COMMUNICATION: PathMyselfSolid,
  PATH_SLEEP: PathMyselfSolid,
}

export const StartTherapyPathIcon = ({ size, therapyPathType }: StartTherapyPathIconProps) => {
  const iconElement = therapyPathType ? iconByTherapyPathIconVariant[therapyPathType] : null

  if (!iconElement) {
    return null
  }

  if (iconElement) {
    return <Icon Svg={iconElement} size={size} />
  }
}
