import { Flex } from 'cdk'
import { useHistory } from 'react-router-dom'
import { Button, DrawerFooter, DrawerHeader, DrawerTitle } from 'ui'
import { Translation } from '~/components/Translation'
import { ReactHookFormTextArea, useReactHookFormContext } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useManageSession } from '../hooks/useManageSession'
import { type ManageSessionDeleteFormValues } from '../types'

const MIN_TEXTAREA_HEIGHT = '200px'
const MAX_MESSAGE_LENGTH = 600

export const DeleteSessionMessage = () => {
  const { loading, therapistName } = useManageSession()
  const { formState, resetField } = useReactHookFormContext<ManageSessionDeleteFormValues>()
  const history = useHistory()

  const {
    errors: { required },
    placeholders: { editTherapySession },
  } = useFormTranslations()

  return (
    <Flex $gap={16} $grow={1} $shrink={1}>
      <DrawerHeader />
      <Flex $gap={8}>
        <DrawerTitle>
          <Translation id="therapySession.deleteSession.message.title" values={{ therapistName }} />
        </DrawerTitle>
        <Translation id="therapySession.deleteSession.message.subtitle" />
      </Flex>
      <ReactHookFormTextArea
        max={MAX_MESSAGE_LENGTH}
        minHeight={MIN_TEXTAREA_HEIGHT}
        name="message"
        placeholder={editTherapySession}
        rules={{ max: MAX_MESSAGE_LENGTH, required }}
      />
      <DrawerFooter>
        <Flex $gap={8} $grow={1} $mdDirection="row-reverse" $shrink={1}>
          <Flex $basis="50%" $grow={1} $shrink={1}>
            <Button
              data-test-id="therapy-session-delete-button"
              disabled={!formState.isValid || loading}
              isLoading={loading}
              kind="error"
              type="submit"
            >
              <Translation id="therapySession.delete.therapySession" />
            </Button>
          </Flex>
          <Flex $basis="50%" $grow={1} $shrink={1}>
            <Button
              data-test-id="therapy-session-go-back-button"
              isGhost
              kind="primary"
              onClick={() => {
                resetField('churnReason')
                history.goBack()
              }}
            >
              <Translation id="actions.back" />
            </Button>
          </Flex>
        </Flex>
      </DrawerFooter>
    </Flex>
  )
}
