import { MemoryRouter, Route, Switch } from 'react-router-dom'
import { ReactHookFormProvider, useReactHookForm } from '~/domains/react-hook-form'
import { type ChangeTherapistReason } from '~/types/graphql'
import { getChangeTherapistModalRoutes } from '../utils/routes'
import { ChangeTherapistModalInfo } from './ChangeTherapistModalInfo'
import { ChangeTherapistReasonAuto } from './ChangeTherapistReasonAuto'
import { ChangeTherapistReasonManual } from './ChangeTherapistReasonManual'
import { ChangeTherapistReasonOpen } from './ChangeTherapistReasonOpen'

export type ChangeTherapistReasonValue = ChangeTherapistReason | 'OTHER_MANUAL'

export type FormValues = {
  changeTherapistReason: ChangeTherapistReasonValue
  changeTherapistMessage?: string
}

const initialEntries = [getChangeTherapistModalRoutes('/info')]

type ChangeTherapistModalContentProps = {
  therapyId: string
}

export const ChangeTherapistModalContent = ({ therapyId }: ChangeTherapistModalContentProps) => {
  const form = useReactHookForm<FormValues>({
    defaultValues: {
      changeTherapistReason: undefined,
      changeTherapistMessage: undefined,
    },
  })

  return (
    <ReactHookFormProvider {...form}>
      <MemoryRouter initialEntries={initialEntries}>
        <Switch>
          <Route path={getChangeTherapistModalRoutes('/info')}>
            <ChangeTherapistModalInfo therapyId={therapyId} />
          </Route>
          <Route path={getChangeTherapistModalRoutes('/changeTherapistReasonAuto')}>
            <ChangeTherapistReasonAuto therapyId={therapyId} />
          </Route>
          <Route path={getChangeTherapistModalRoutes('/changeTherapistReasonManual')}>
            <ChangeTherapistReasonManual />
          </Route>
          <Route path={getChangeTherapistModalRoutes('/changeTherapistReasonOpen')}>
            <ChangeTherapistReasonOpen therapyId={therapyId} />
          </Route>
        </Switch>
      </MemoryRouter>
    </ReactHookFormProvider>
  )
}
