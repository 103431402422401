import { Flex, Pressable } from 'cdk'
import { COLOR_PRIMARY, SPACING_XS } from 'design-tokens'
import { type ReactNode } from 'react'
import styled from 'styled-components'

const PressableWithHover = styled(Pressable)`
  &:hover {
    color: ${COLOR_PRIMARY};
  }
`

type Props = {
  children: ReactNode
  dataTestId?: string
  disabled?: boolean
  onClick: () => void
}

export const MenuAction = ({ children, dataTestId, disabled = false, onClick }: Props) => (
  <PressableWithHover data-test-id={dataTestId} disabled={disabled} onClick={onClick}>
    <Flex $align="center" $direction="row" $grow={1} $pr={SPACING_XS} $py={8} $shrink={1}>
      {children}
    </Flex>
  </PressableWithHover>
)
