import { Flex, Pressable } from 'cdk'
import { SPACING_MD, SPACING_XS } from 'design-tokens'
import { EditPencil, Icon, Trash } from 'icons'
import { Button, Drawer, DrawerTitle, DrawerTrigger, Modal, ModalTitle, ModalTrigger, useModalContext } from 'ui'
import { DropdownMenu } from '~/components/Dropdown/Menu'
import { Translation } from '~/components/Translation'
import { useToasts } from '~/hooks/useToasts'
import { useDeleteFoodJournalMeal } from '../../hooks/useDeleteFoodJournalMeal'
import { useGetFoodJournalDay } from '../../hooks/useGetFoodJournalDay'
import { useFoodJournal } from '../FoodJournalContext'
import { MealForm } from '../MealForm'

type Props = {
  mealId: string
}

export const ActionsDropdown = ({ mealId }: Props) => {
  const { addToast } = useToasts()
  const modal = useModalContext()
  const { patientId, currentDay } = useFoodJournal()

  const { foodJournalDay } = useGetFoodJournalDay({ day: currentDay, patientId })

  const meal = foodJournalDay?.meals.find(({ id }) => id === mealId)

  const deleteMeal = useDeleteFoodJournalMeal()

  const handleDeleteMeal = async () => {
    try {
      await deleteMeal({ variables: { input: { id: mealId } } })
      addToast({ type: 'success', translationId: 'nutrition.diary.mealDeleted' })
    } catch (error) {
      addToast({ type: 'alert', translationId: 'generic.errorRetryLater' })
    } finally {
      modal.close()
    }
  }

  return (
    <>
      <DropdownMenu withPadding={false}>
        <Flex>
          <DrawerTrigger>
            <Pressable>
              <Flex
                $align="center"
                $borderColorName="neutral-40"
                $borderSizeBottom={1}
                $direction="row"
                $gap={SPACING_XS}
                $p={SPACING_MD}
              >
                <Icon Svg={EditPencil} size={24} />
                <Translation id="nutrition.diary.editMealCta" />
              </Flex>
            </Pressable>
          </DrawerTrigger>

          <ModalTrigger>
            <Pressable>
              <Flex $align="center" $direction="row" $gap={SPACING_XS} $p={SPACING_MD}>
                <Icon Svg={Trash} size={24} />
                <Translation id="nutrition.diary.deleteMealCta" />
              </Flex>
            </Pressable>
          </ModalTrigger>
        </Flex>
      </DropdownMenu>

      <Modal>
        <ModalTitle>
          <Translation id="nutrition.diary.deleteMealModal.title" />
        </ModalTitle>

        <Flex $direction="row" $gap={SPACING_MD} $justify="flex-end" $pt={SPACING_MD}>
          <Button
            kind="tertiary"
            onClick={() => {
              modal.close()
            }}
          >
            <Translation id="actions.cancel" />
          </Button>

          <Button kind="primary" onClick={handleDeleteMeal}>
            <Translation id="nutrition.diary.deleteMealModal.confirm" />
          </Button>
        </Flex>
      </Modal>

      <Drawer>
        <DrawerTitle>
          <Translation id="nutrition.diary.editMealDrawer.title" />
        </DrawerTitle>

        <MealForm {...meal} />
      </Drawer>
    </>
  )
}
