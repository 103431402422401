import { parseISO } from 'date-fns/fp'
import { formatRelative, nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { useChatPatient } from '~/domains/chat/hooks/useChatPatient'
import { useTranslation } from '~/i18n/hooks/useTranslation'

export const useChatPatientLastActive = () => {
  const { user } = useChatPatient()
  const onlineMessage = useTranslation('chat.online')
  const offlineMessage = useTranslation('chat.offline')

  if (user?.online) {
    return {
      online: true,
      status: onlineMessage,
    }
  }

  if (user?.last_active) {
    return {
      online: false,
      status: pipe(user.last_active, parseISO, formatRelative()(nowInMilliseconds())),
    }
  }

  return {
    online: false,
    status: offlineMessage,
  }
}
