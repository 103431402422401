import {
  BORDER_WIDTH_1,
  COLOR_DARKER,
  COLOR_GREY_30,
  COLOR_GREY_80,
  COLOR_LIGHTER,
  COLOR_PRIMARY_10,
  COLOR_PRIMARY_30,
  COLOR_PRIMARY_40,
  COLOR_PRIMARY_50,
  COLOR_RED_60,
  FONT_FAMILY_DEGULAR_TEXT,
  SPACING_0,
  SPACING_3XS,
  SPACING_LG,
  SPACING_MD,
  SPACING_XS,
  TYPOGRAPHY_CAPTION_FONT_SIZE,
  TYPOGRAPHY_FOOTNOTE_FONT_SIZE,
} from 'design-tokens'
import { createGlobalStyle } from 'styled-components'

export const ChatStyle = createGlobalStyle`
  /* stylelint-disable custom-property-pattern, selector-class-pattern */
  .str-chat {
    --str-chat__active-primary-color: ${COLOR_PRIMARY_50};
    --str-chat__avatar-background-color: ${COLOR_PRIMARY_40};
    --str-chat__avatar-color: ${COLOR_LIGHTER};
    --str-chat__border-radius-lg: 32px;
    --str-chat__border-radius-md: 24px;
    --str-chat__channel-header-background-color: ${COLOR_PRIMARY_10};
    --str-chat__channel-preview-active-background-color: ${COLOR_PRIMARY_10};
    --str-chat__channel-preview-unread-badge-background-color: ${COLOR_RED_60};
    --str-chat__channel-search-bar-background-color: ${COLOR_PRIMARY_10};
    --str-chat__channel-search-input-wrapper-background-color: ${COLOR_PRIMARY_30};
    --str-chat__edit-message-modal-button-color: ${COLOR_PRIMARY_50};
    --str-chat__font-family: ${FONT_FAMILY_DEGULAR_TEXT};
    --str-chat__message-bubble-background-color: ${COLOR_LIGHTER};
    --str-chat__message-input-tools-color: transparent;
    --str-chat__message-link-color: ${COLOR_PRIMARY_50};
    --str-chat__message-list-background-color: ${COLOR_LIGHTER};
    --str-chat__message-options-button-size: 32px;
    --str-chat__message-send-color: ${COLOR_PRIMARY_50};
    --str-chat__own-message-bubble-background-color: ${COLOR_GREY_30};
    --str-chat__primary-color: ${COLOR_PRIMARY_40};
    --str-chat__primary-surface-color-low-emphasis: #edf7f7;
    --str-chat__primary-surface-color: ${COLOR_PRIMARY_10};
    --str-chat__secondary-surface-color: ${COLOR_LIGHTER};
    --str-chat__spacing-2_5: ${SPACING_XS};
    --str-chat__surface-color: ${COLOR_PRIMARY_40};
    --str-chat__text-color: ${COLOR_DARKER};
    --str-chat__text-low-emphasis-color: ${COLOR_GREY_80};
    --str-chat__typing-indicator-color: ${COLOR_GREY_80};
    --str-chat-message-options-size: 32px;
    --str-chat__message-with-attachment-max-width: 480px;

    flex-grow: 1;
    flex-shrink: 1;

    @media (hover: hover) and (pointer: fine) {
      .image-gallery-icon:hover {
        color: ${COLOR_PRIMARY_50};
      }
    }

    .str-chat__main-panel-inner {
      width: 100%;
      max-width: 100%;
    }

    .str-chat__virtual-list {
      .str-chat__message {
        margin-block-end: ${SPACING_MD} !important;
      }

      .str-chat__message-list-scroll > div {
        padding-top: ${SPACING_LG};
      }
    }

    .str-chat__message .str-chat__message-inner .str-chat__message-options .str-chat__message-actions-container {
      z-index: 10;
      display: none;
    }

    .str-chat__ul:not(.str-chat__message-options-in-bubble .str-chat__message-actions-container, .str-chat__message-with-touch-support) .str-chat__li:hover:not(:has(.str-chat__reaction-list:hover .str-chat__message-actions-container, .str-chat__modal--open)) .str-chat__message-options .str-chat__message-actions-container, .str-chat__ul:not(.str-chat__message-options-in-bubble .str-chat__message-actions-container, .str-chat__message-with-touch-support) .str-chat__li:focus-within:not(:has(.str-chat__reaction-list:focus-within .str-chat__message-actions-container, .str-chat__modal--open)) .str-chat__message-options .str-chat__message-actions-container, .str-chat__virtual-list:not(.str-chat__message-options-in-bubble .str-chat__message-actions-container, .str-chat__message-with-touch-support) .str-chat__li:hover:not(:has(.str-chat__reaction-list:hover .str-chat__message-actions-container, .str-chat__modal--open)) .str-chat__message-options .str-chat__message-actions-container, .str-chat__virtual-list:not(.str-chat__message-options-in-bubble .str-chat__message-actions-container, .str-chat__message-with-touch-support) .str-chat__li:focus-within:not(:has(.str-chat__reaction-list:focus-within .str-chat__message-actions-container, .str-chat__modal--open)) .str-chat__message-options .str-chat__message-actions-container {
      display: block;
    }

    .str-chat__modal--open .str-chat__modal__inner {
      width: 80%;
      min-width: 320px;
      max-width: 640px;
      height: 100%;
      max-height: 80vh;
    }

    .str-chat__list-notifications {
      display: none;
    }

    .str-chat__typing-indicator {
      font-size: ${TYPOGRAPHY_CAPTION_FONT_SIZE};
    }

    .str-chat__quoted-message-reply-to-message {
      display: none;
    }

    .str-chat__message-input .str-chat__quoted-message-preview-header {
      justify-content: flex-end;
    }

    .str-chat__channel-search-bar .str-chat__channel-search-bar-button {
      display: none;
    }

    .str-chat__channel-search-input--wrapper-active {
      border-color: ${COLOR_PRIMARY_50};
    }

    .str-chat__edit-message-form .str-chat__edit-message-form-options .str-chat__edit-message-send {
      text-transform: none;
    }

    .str-chat__message-bubble {
      border: ${BORDER_WIDTH_1} solid ${COLOR_PRIMARY_40};
    }

    .str-chat__message-input .str-chat__message-input-inner .str-chat__message-textarea-container {
      align-items: flex-start;
    }

    .str-chat__quoted-message-bubble {
      border: ${BORDER_WIDTH_1} solid ${COLOR_PRIMARY_40};
    }

    .str-chat__quoted-message-preview.mine .str-chat__quoted-message-bubble {
      border: ${BORDER_WIDTH_1} solid ${COLOR_PRIMARY_40};
      background: ${COLOR_LIGHTER};
    }

    .str-chat__channel-search {
      position: sticky;
      top: 0;
    }

    .str-chat__channel-search-bar {
      border-bottom: ${BORDER_WIDTH_1} solid ${COLOR_PRIMARY_40};
      background: ${COLOR_PRIMARY_10};
    }

    .str-chat__channel-preview-messenger {
      border-left: 4px solid transparent;

      &:hover {
        background: ${COLOR_PRIMARY_30};
      }
    }

    .str-chat__channel-preview-messenger--active {
      border-left: 4px solid ${COLOR_PRIMARY_40};
    }

    .str-chat__channel-preview-unread-badge {
      border-radius: 999px;
      font-weight: 600;
      font-size: ${TYPOGRAPHY_FOOTNOTE_FONT_SIZE};
    }

    .str-chat__message .str-chat__message-inner .str-chat__message-options {
      display: flex;
      width: 32px;
    }

    .str-chat__message--me .str-chat__message-inner {
      margin-inline: 0;
    }

    .str-chat__message--other .str-chat__message-inner:not(:has(.str-chat__message-options--active)) {
      margin-inline-end: 0;
    }

    .str-chat__attachment-list .str-chat__message-attachment--gallery .str-chat__gallery,
    .str-chat__attachment-list .str-chat__message-attachment--image:not(.str-chat__message-attachment--card) > img {
      min-width: 320px;
      min-height: 120px;
      max-height: 240px;
    }

    .str-chat__message-actions-box .str-chat__message-actions-list {
      padding: ${SPACING_3XS} 0;

      .str-chat__message-actions-list-item-button {
        padding: ${SPACING_3XS} 12px;
        font-size: ${TYPOGRAPHY_CAPTION_FONT_SIZE};
      }
    }

    .str-chat__message-input {
      padding: ${SPACING_MD} ${SPACING_XS};
      border-top: ${BORDER_WIDTH_1} solid ${COLOR_PRIMARY_40};
    }

    .str-chat__header-livestream-left.str-chat__channel-header-end {
      align-items: flex-start;
    }

    .str-chat__tooltip {
      display: none;
    }

    .str-chat__channel-search-input--clear-button {
      &:disabled {
        display: none;
      }

      svg path {
        fill: none;
      }
    }

    .str-chat__channel-search-input--wrapper {
      height: 40px;
    }

    .image-gallery-slide.image-gallery-center {
      position: relative;
    }

    .image-gallery-swipe {
      background-color: ${COLOR_DARKER};
    }

    .str-chat__message-attachment--card,
    .str-chat__message-attachment--image,
    .str-chat__message-attachment--gallery {
      .str-chat__modal--open .str-chat__modal__inner.str-chat-react__modal__inner {
        img {
          min-height: 60vh;
        }

        .fullscreen img {
          min-height: 100vh;
        }
      }
    }

    .image-gallery-icon:focus {
      outline: 0;
    }

    .image-gallery,
    .image-gallery-content.fullscreen {
      background-color: ${COLOR_DARKER};
    }

    .str-chat__channel-list-messenger-react {
      padding-bottom: ${SPACING_0};
    }

    .str-chat__message-actions-box:not(.str-chat__message-actions-box-angular) {
      &:not(.str-chat__message-actions-box--mine) {
        inset-block-end: 120%;
        inset-inline-end: -200%;
      }

      &.str-chat__message-actions-box--mine {
        inset-block-end: 120%;
        inset-inline-start: -200%;
      }
    }

    .str-chat__unread-messages-notification {
      z-index: 3;
    }

    .srns-show-clinical-tests .str-chat__send-button {
      display: none
    }
  }
`
