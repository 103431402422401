import { pipe } from 'fp-ts/function'
import { type ClinicalBondVariantFormValues } from './ClinicalBondVariantFormValues'

export type ClinicalBondVariantAverageRating = 'low' | 'medium' | 'high'

export const getClinicalBondVariantAverageRating = (
  values: ClinicalBondVariantFormValues,
): ClinicalBondVariantAverageRating => {
  const total = pipe(
    values.feeling,
    (value) => value + values.explaining,
    (value) => value + values.professionality,
    (value) => value / 3,
    (value) => Math.floor(value),
  )

  if (total > 4) {
    return 'high'
  }

  if (total > 3) {
    return 'medium'
  }

  return 'low'
}
