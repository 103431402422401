import { Flex } from 'cdk'
import { Button, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { FirstSessionFeedbackTrigger } from '~/domains/firstSessionFeedback/FirstSessionFeedbackTrigger'
import { type TherapyTherapyPathType } from '~/types/graphql'

type Props = {
  therapySessionId: string
  therapyId: string
  therapyPathType: TherapyTherapyPathType | null
  onComplete: () => void
}

export const MessageFirstSessionFeedbackToComplete = ({
  therapyPathType,
  therapySessionId,
  therapyId,
  onComplete,
}: Props) => (
  <Flex $gap={8}>
    <Text fontWeight="600" kind="caption">
      <Translation id="systemMessage.firstSessionFeedback.title" />
    </Text>
    <Text fontWeight="400" kind="caption">
      <Translation id="systemMessage.firstSessionFeedback.body" />
    </Text>
    <Flex $align="center" $direction="row" $pt={8} $wrap="wrap">
      <FirstSessionFeedbackTrigger
        onComplete={onComplete}
        therapyId={therapyId}
        therapyPathType={therapyPathType}
        therapySessionId={therapySessionId}
        trigger={
          <Button kind="secondary" size="sm">
            <Translation id="systemMessage.firstSessionFeedback.cta" />
          </Button>
        }
      />
    </Flex>
  </Flex>
)
