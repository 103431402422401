import loadable from '@loadable/component'
import { format, FormatDateEnum } from 'dates'
import { pipe } from 'fp-ts/function'
import { useCallback } from 'react'
import styled from 'styled-components'
import { Text } from 'ui'
import { Button, Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { usePatientId } from '~/hooks/usePatientId'
import { useRootHistory } from '~/hooks/useRootHistory'
import { useJournalingLogById } from '~/routes/journaling/hooks/useJournalingLogById'
import { useJournalingLogSharedWithTherapist } from '~/routes/journalingLogShared/hooks/useJournalingLogSharedWithTherapist'
import { getRoute } from '~/utils/getRoute'
import { useChatMessage } from '../../../hooks/useChatMessage'
import { CustomMessageWrapper } from '../CustomMessageWrapper'
import { MessageJournalingLogSharedMissingIcon } from './MessageJournalingLogSharedMissingIcon'

const Fallback = styled.div`
  width: 100px;
  height: 100px;
`

const LazyJournalingLogScoreIllustrationAnimated = loadable(
  () => import('~/routes/journalingTipsOfLove/components/JournalingLogScoreIllustrationAnimated/index.lazy'),
  {
    resolveComponent: (components) => components.JournalingLogScoreIllustrationAnimated,
    fallback: <Fallback />,
  },
)

type Props = {
  journalingLogId: string
}

const MessageJournalingLogSharedTherapist = ({ journalingLogId }: Props) => {
  const { journalingLog, loading, error } = useJournalingLogSharedWithTherapist(journalingLogId)

  const history = useRootHistory()

  const patientId = usePatientId()

  const handleOnClickOpen = useCallback(() => {
    if (!journalingLog?.id) {
      return
    }

    history.push(getRoute(`/journaling/logs/${journalingLog.id}/shared`))
  }, [history, journalingLog?.id])

  const handleOnClickHistory = useCallback(() => {
    if (!patientId) {
      return
    }

    history.push(getRoute(`/patients/${patientId}/journaling`))
  }, [history, patientId])

  if (loading) {
    return null
  }

  if (error || loading || !journalingLog) {
    return (
      <CustomMessageWrapper>
        <Flex direction="row">
          <Flex pr={8}>
            <MessageJournalingLogSharedMissingIcon />
          </Flex>
          <Flex>
            <Flex pb={8}>
              <Text fontWeight="600" kind="caption">
                <Translation id="systemMessage.therapist.journalingLogShared.noAccess.title" />
                {' 📖'}
              </Text>
            </Flex>
            <TranslationMarkdown id="systemMessage.therapist.journalingLogShared.noAccess.body" kind="caption" />
            <Flex direction="row" pt={16}>
              <Button kind="secondary" onClick={handleOnClickHistory} size="small">
                <Translation id="systemMessage.journalingLogShared.cta.history" />
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </CustomMessageWrapper>
    )
  }

  return (
    <CustomMessageWrapper>
      <Flex direction="row">
        <LazyJournalingLogScoreIllustrationAnimated score={journalingLog.score} size={100} />
        <Flex grow={1} shrink={1}>
          <Flex pb={8}>
            <Text fontWeight="600" kind="caption">
              <Translation id="systemMessage.therapist.journalingLogShared.title" />
              {' 📖'}
            </Text>
          </Flex>
          <TranslationMarkdown
            id="systemMessage.therapist.journalingLogShared.body"
            kind="caption"
            values={{
              journalingLogDate: pipe(journalingLog.date, format(FormatDateEnum.LONG_DAY_DATE_MONTH_NAME)),
            }}
          />
          <Flex align="center" direction="row" wrap="wrap">
            <Flex pt={16}>
              <Button kind="secondary" onClick={handleOnClickOpen} size="small">
                <Translation id="systemMessage.journalingLogShared.cta.open" />
              </Button>
            </Flex>
            <Flex pl={8} pt={16}>
              <Button kind="primary" onClick={handleOnClickHistory} size="small">
                <Translation id="systemMessage.journalingLogShared.cta.history" />
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </CustomMessageWrapper>
  )
}

const MessageJournalingLogSharedPatient = ({ journalingLogId: logId }: Props) => {
  const { journalingLog, loading, error } = useJournalingLogById(logId)

  const history = useRootHistory()

  const handleOnClick = useCallback(() => {
    if (!journalingLog?.id) {
      return
    }

    history.push(getRoute(`/journaling/logs/${journalingLog.id}`))
  }, [history, journalingLog?.id])

  if (
    error ||
    loading ||
    !journalingLog ||
    (!journalingLog.sharedWithTherapistUserId && !journalingLog.sharedWithTherapistUserIds?.length)
  ) {
    return null
  }

  return (
    <CustomMessageWrapper>
      <Flex direction="row">
        <LazyJournalingLogScoreIllustrationAnimated score={journalingLog.score} size={120} />
        <Flex grow={1} shrink={1}>
          <Flex>
            <Flex pb={8}>
              <Text fontWeight="600" kind="caption">
                <Translation id="systemMessage.patient.journalingLogShared.title" />
              </Text>
            </Flex>
            <TranslationMarkdown
              id="systemMessage.patient.journalingLogShared.body"
              kind="caption"
              values={{
                journalingLogDate: pipe(journalingLog.date, format(FormatDateEnum.LONG_DAY_DATE_MONTH_NAME)),
              }}
            />
          </Flex>
          <Flex direction="row" pt={16}>
            <Button kind="secondary" onClick={handleOnClick} size="small">
              <Translation id="systemMessage.journalingLogShared.cta.open" />
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </CustomMessageWrapper>
  )
}

export const MessageJournalingLogShared = () => {
  const group = useUserActingAsGroup()

  const { message } = useChatMessage<'SYSTEM_JOURNALING_LOG_SHARED'>()

  const journalingLogId = message.srns?.payload.journalingLogId

  if (!journalingLogId) {
    return null
  }

  if (group === 'therapist') {
    return <MessageJournalingLogSharedTherapist journalingLogId={journalingLogId} />
  }

  return <MessageJournalingLogSharedPatient journalingLogId={journalingLogId} />
}
