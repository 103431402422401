import { Button, Modal, ModalProvider, ModalTrigger } from 'ui'
import { ChangeProfessionalTranslation } from '~/components/ChangeProfessionalTranslation'
import { usePatientTherapiesQuery } from '~/domains/patient/hooks/usePatientTherapiesQuery'
import { useChangeNutritionist } from '~/hooks/useChangeNutritionist'
import { isNutrition } from '~/utils/therapyPaths'
import { ChangeTherapistModalContent } from './ChangeTherapistModal'

type Props = {
  therapyId: string
}

export const ChangeTherapistGhostButton = ({ therapyId }: Props) => {
  const { therapies } = usePatientTherapiesQuery()
  const changeNutritionist = useChangeNutritionist()
  const therapy = therapies.find((therapy) => therapy.id === therapyId)
  const therapyPathType = therapy?.therapyPath?.type

  if (therapyPathType != null && isNutrition(therapyPathType)) {
    return (
      <Button isGhost kind="secondary" onClick={changeNutritionist}>
        <ChangeProfessionalTranslation therapyPathType={therapyPathType} />
      </Button>
    )
  }

  return (
    <ModalProvider>
      <ModalTrigger>
        <Button data-test-id="change-therapist-cta" isGhost kind="secondary">
          <ChangeProfessionalTranslation therapyPathType={therapyPathType} />
        </Button>
      </ModalTrigger>
      <Modal>
        <ChangeTherapistModalContent therapyId={therapyId} />
      </Modal>
    </ModalProvider>
  )
}
