import { differenceInMinutes } from 'date-fns'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Text } from 'ui'
import { Flex, OverflowAuto } from 'ui-deprecated'
import { CenteredLoader } from '~/components/CenteredLoader'
import { Translation } from '~/components/Translation'
import { useProductUserFeedbackAnswerUpsert } from '~/domains/productUserFeedback/hooks/useProductUserFeedbackAnswerUpsert'
import { ReactHookFormRadioField } from '~/domains/react-hook-form'
import { useGetTherapySessionById } from '~/domains/therapy-session/hooks/useGetTherapySessionById'
import { useToasts } from '~/hooks/useToasts'
import { type StepProps, type TechnicalFeedbackPsychotherapy } from '../../types'
import { getTechnicalFeedbackRoute } from '../../utils/getTechnicalFeedbackRoute'
import { BackButton } from '../BackButton'
import { NextButton } from '../NextButton'
import { TechnicalFeedbackForm } from '../TechnicalFeedbackForm'

const FlexPointer = styled(Flex)`
  cursor: pointer;
`

type Props = StepProps & {
  therapySessionId: string
}

export const TechnicalFeedbackStepDuration = ({ technicalFeedbackId, therapySessionId }: Props) => {
  const history = useHistory()

  const { addToast } = useToasts()

  const { productUserFeedbackAnswerUpsert } = useProductUserFeedbackAnswerUpsert(technicalFeedbackId)
  const { item: therapySession } = useGetTherapySessionById(therapySessionId)

  const onSubmit = useCallback(
    async (values: TechnicalFeedbackPsychotherapy) => {
      try {
        if (values.duration === null) {
          return
        }

        const productUserFeedbackAnswerUpsertResponse = await productUserFeedbackAnswerUpsert({
          id: 'DURATION',
          value: values.duration === 'YES' ? 'true' : 'false',
        })

        if (!productUserFeedbackAnswerUpsertResponse.data?.productUserFeedbackAnswerUpsert?.id) {
          addToast({
            translationId: 'reviews.askReviewModal.bad.unableToSaveResponse',
            type: 'alert',
          })

          return
        }

        if (values.context < 4 || values.duration === 'NO' || values.punctuality < 4 || values.quality < 4) {
          history.push(getTechnicalFeedbackRoute('/bad'))

          return
        }

        history.push(getTechnicalFeedbackRoute('/end'))

        return
      } catch (error) {
        addToast({
          translationId: 'reviews.askReviewModal.bad.unableToSaveResponse',
          type: 'alert',
        })
      }
    },
    [addToast, history, productUserFeedbackAnswerUpsert],
  )

  if (therapySession == null) {
    return <CenteredLoader />
  }

  const { endAt, startAt } = therapySession
  const minutes = differenceInMinutes(endAt, startAt)

  return (
    <TechnicalFeedbackForm onSubmit={onSubmit}>
      <OverflowAuto>
        <Flex pt={16}>
          <Text colorName="grey-60" kind="caption">
            4/4
          </Text>
        </Flex>
        <Flex pt={16}>
          <Text colorName="black" fontWeight="600" kind="h3">
            <Translation id="technicalFeedback.duration.title" />
          </Text>
        </Flex>
        <Flex pt={16}>
          <Text colorName="black" kind="paragraph">
            <Translation id="technicalFeedback.duration.body" values={{ minutes }} />
          </Text>
        </Flex>
        <Flex pt={16}>
          <FlexPointer as="label" borderColor="purple06" borderRadius={16} borderSize={1} p={16}>
            <ReactHookFormRadioField name="duration" value="YES">
              <Flex grow={1} justify="center">
                <Text colorName="primary" fontWeight="600" itemType="label" kind="paragraph">
                  <Translation id="technicalFeedback.duration.yes" values={{ minutes }} />
                </Text>
              </Flex>
            </ReactHookFormRadioField>
          </FlexPointer>
        </Flex>
        <Flex pt={8}>
          <FlexPointer as="label" borderColor="purple06" borderRadius={16} borderSize={1} p={16}>
            <ReactHookFormRadioField name="duration" value="NO">
              <Flex grow={1} justify="center">
                <Text colorName="primary" fontWeight="600" kind="paragraph">
                  <Translation id="technicalFeedback.duration.no" values={{ minutes }} />
                </Text>
              </Flex>
            </ReactHookFormRadioField>
          </FlexPointer>
        </Flex>
      </OverflowAuto>
      <Flex pt={24}>
        <NextButton />
      </Flex>
      <Flex pt={8}>
        <BackButton />
      </Flex>
    </TechnicalFeedbackForm>
  )
}
