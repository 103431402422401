import { useBackgroundFilters, useCallStateHooks, usePersistedDevicePreferences } from '@stream-io/video-react-sdk'
import { Pressable } from 'cdk'
import { COLOR_GREY_100, COLOR_PRIMARY_30 } from 'design-tokens'
import { DeleteCircle, Icon } from 'icons'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Text } from 'ui'
import { Flex, MaxWidth400px, PositionAbsolute, PositionRelative } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useIsNativeApp } from '~/domains/appNative/hooks/useIsNativeApp'
import { useVideocallEventLogger } from '~/domains/videocall'
import { useToasts } from '~/hooks/useToasts'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'
import { CurrentUserCamera } from '../../components/CurrentUserCamera'
import { PermissionsAlert } from '../../components/PermissionsAlert'
import { BackgroundFilterControl } from '../../components/VideocallControls/BackgroundFilterControl'
import { CameraControl } from '../../components/VideocallControls/CameraControl'
import { MicrophoneControl } from '../../components/VideocallControls/MicrophoneControl'
import { SpeakerControl } from '../../components/VideocallControls/SpeakerControl'

const SpeakerHelpContainer = styled(Flex)`
  background-color: ${COLOR_GREY_100};
`

export const Preview = () => {
  const { isSupported } = useBackgroundFilters()
  const { useMicrophoneState, useCameraState, useSpeakerState } = useCallStateHooks()
  const { devices } = useSpeakerState()
  const { microphone } = useMicrophoneState()
  const { camera } = useCameraState()
  const { addToast } = useToasts()
  const isNativeApp = useIsNativeApp()
  const logFailure = useVideocallEventLogger()

  usePersistedDevicePreferences('srns-videocall-preferences')

  const [videocallSpeakerHelp, setVideocallSpeakerHelp] = useState(!getLocalStorage('videocall-speaker-help'))

  const showSpeakerHelp = useMemo(
    () => videocallSpeakerHelp && devices?.length > 1,
    [devices?.length, videocallSpeakerHelp],
  )

  const handleOnClick = useCallback(() => {
    setVideocallSpeakerHelp(false)
    setLocalStorage('videocall-speaker-help', true)
  }, [])

  useEffect(() => {
    const run = async () => {
      await microphone.enable()
      await camera.enable()
    }

    run().catch((error) => {
      logFailure('videocall.preview.devices-enabler', error)

      addToast({
        translationId: `videocall.device.error.${isNativeApp ? 'app' : 'browser'}`,
        type: 'alert',
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex background="black" borderRadius={8} grow={1} shrink={1}>
      <Flex p={16} pb={0}>
        <CurrentUserCamera>
          <PositionAbsolute bottom="0" left="0" py={8} right="0">
            <MaxWidth400px>
              <PermissionsAlert />
            </MaxWidth400px>
          </PositionAbsolute>
        </CurrentUserCamera>
        <Flex align="center" direction="row" justify="center" pb={8} pt={8} wrap="wrap">
          <Flex className="srns-microphone-button" px={8} py={8}>
            <MicrophoneControl />
          </Flex>
          <Flex className="srns-camera-button" px={8} py={8}>
            <CameraControl />
          </Flex>
          {isSupported && (
            <Flex className="srns-background-filter-button" px={8} py={8}>
              <BackgroundFilterControl />
            </Flex>
          )}
          <PositionRelative className="srns-audio-output-button" px={8} py={8}>
            <SpeakerControl />
            {showSpeakerHelp && (
              <PositionAbsolute right="-10px" top="35px">
                <svg fill="none" viewBox="0 0 14 37" width={15} xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.92.93A1 1 0 0 1 4 0l8.98.65a1 1 0 0 1-.15 2l-7.98-.58-.58 7.98a1 1 0 0 1-1.99-.15L2.92.93ZM.73 35.1A23.7 23.7 0 0 0 3.16 1.65L4.68.35A25.7 25.7 0 0 1 2.04 36.6L.74 35.1Z"
                    fill={COLOR_PRIMARY_30}
                  />
                </svg>
              </PositionAbsolute>
            )}
          </PositionRelative>
        </Flex>
      </Flex>
      {showSpeakerHelp && (
        <SpeakerHelpContainer align="center" p={16}>
          <Text colorName="white" kind="caption" textAlign="center">
            <Translation id="videocall.speaker.help" />
          </Text>
          <Flex py={2} />
          <Pressable onClick={handleOnClick}>
            <Flex align="center" direction="row" px={8} py={4}>
              <Text colorName="white">
                <Translation id="videocall.speaker.help.hide" />
              </Text>
              <Flex px={2} />
              <Icon Svg={DeleteCircle} colorName="white" size={24} />
            </Flex>
          </Pressable>
        </SpeakerHelpContainer>
      )}
    </Flex>
  )
}
