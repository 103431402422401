import {
  THERAPY_SESSION_COST_ONLINE_COACHING,
  THERAPY_SESSION_COST_ONLINE_COACHING_FIRST,
  THERAPY_SESSION_COST_ONLINE_COUPLE,
  THERAPY_SESSION_COST_ONLINE_COUPLE_FIRST,
  THERAPY_SESSION_COST_ONLINE_DCA,
  THERAPY_SESSION_COST_ONLINE_DCA_FIRST,
  THERAPY_SESSION_COST_ONLINE_MYSELF,
  THERAPY_SESSION_COST_ONLINE_MYSELF_FIRST,
  THERAPY_SESSION_COST_ONLINE_PSYCHIATRY,
  THERAPY_SESSION_COST_ONLINE_PSYCHIATRY_FIRST,
  THERAPY_SESSION_COST_ONLINE_SEXOLOGY,
  THERAPY_SESSION_COST_ONLINE_SEXOLOGY_FIRST,
  THERAPY_SESSION_COST_ONLINE_WEIGHT_LOSS,
  THERAPY_SESSION_COST_ONLINE_WEIGHT_LOSS_FIRST,
} from '~/constants'
import { type TherapyTherapyPathType } from '~/types/graphql'

type Costs = {
  therapySessionCost: number
  firstTherapySessionCost: number
}

export const getTherapySessionCostsByTherapyPath = (therapyPath: TherapyTherapyPathType): Costs => {
  switch (therapyPath) {
    case 'PATH_ASSERTIVE_COMMUNICATION':
    case 'PATH_SLEEP':
    case 'MYSELF_PSYCHOTHERAPY':
      return {
        firstTherapySessionCost: THERAPY_SESSION_COST_ONLINE_MYSELF_FIRST,
        therapySessionCost: THERAPY_SESSION_COST_ONLINE_MYSELF,
      }
    case 'PATH_COACHING':
      return {
        firstTherapySessionCost: THERAPY_SESSION_COST_ONLINE_COACHING_FIRST,
        therapySessionCost: THERAPY_SESSION_COST_ONLINE_COACHING,
      }
    case 'PATH_NUTRITION_DCA':
      return {
        firstTherapySessionCost: THERAPY_SESSION_COST_ONLINE_DCA_FIRST,
        therapySessionCost: THERAPY_SESSION_COST_ONLINE_DCA,
      }
    case 'PATH_NUTRITION_WEIGHT_LOSS':
      return {
        firstTherapySessionCost: THERAPY_SESSION_COST_ONLINE_WEIGHT_LOSS_FIRST,
        therapySessionCost: THERAPY_SESSION_COST_ONLINE_WEIGHT_LOSS,
      }
    case 'MYSELF_PSYCHIATRY':
      return {
        firstTherapySessionCost: THERAPY_SESSION_COST_ONLINE_PSYCHIATRY_FIRST,
        therapySessionCost: THERAPY_SESSION_COST_ONLINE_PSYCHIATRY,
      }
    case 'PATH_SEXOLOGY':
      return {
        firstTherapySessionCost: THERAPY_SESSION_COST_ONLINE_SEXOLOGY_FIRST,
        therapySessionCost: THERAPY_SESSION_COST_ONLINE_SEXOLOGY,
      }
    case 'COUPLES_PSYCHOTHERAPY':
      return {
        firstTherapySessionCost: THERAPY_SESSION_COST_ONLINE_COUPLE_FIRST,
        therapySessionCost: THERAPY_SESSION_COST_ONLINE_COUPLE,
      }
  }
}
