import {
  COLOR_DARKER,
  COLOR_GREY_50,
  COLOR_LIGHTER,
  TYPOGRAPHY_PARAGRAPH_FONT_SIZE,
  TYPOGRAPHY_PARAGRAPH_LINE_HEIGHT,
} from 'design-tokens'
import { type ChangeEvent, forwardRef, type Ref, useCallback } from 'react'
import styled from 'styled-components'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { type TextAreaProps, type TextAreaStyleProps } from './types'

const Wrapper = styled(Flex)`
  width: 100%;
`

const StyledTextArea = styled.textarea<TextAreaStyleProps>`
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  border-width: 0;
  background-color: ${COLOR_LIGHTER};
  color: ${COLOR_DARKER};
  outline: none;
  font-size: ${TYPOGRAPHY_PARAGRAPH_FONT_SIZE};
  line-height: ${TYPOGRAPHY_PARAGRAPH_LINE_HEIGHT};
  opacity: 1;
  resize: none;
  -webkit-text-fill-color: ${COLOR_DARKER};
  appearance: none;

  ${({ minHeight }) => (minHeight ? `min-height: ${minHeight};` : '')}

  &::placeholder {
    color: ${COLOR_GREY_50};
    -webkit-text-fill-color: ${COLOR_GREY_50};
  }
`

export const TextArea = forwardRef(
  (
    {
      defaultValue,
      disabled = false,
      helperText,
      id,
      invalid = false,
      max = 1000,
      minHeight = '250px',
      name,
      onBlur,
      onChange,
      onFocus,
      placeholder = '',
      required = false,
      value,
    }: TextAreaProps,
    ref: Ref<HTMLTextAreaElement>,
  ) => {
    const inputId = id || name

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLTextAreaElement>) => {
        if (onChange) {
          onChange(event.target.value)
        }
      },
      [onChange],
    )

    return (
      <Wrapper>
        <StyledTextArea
          ref={ref}
          defaultValue={defaultValue}
          disabled={disabled}
          id={inputId}
          invalid={invalid}
          maxLength={max}
          minHeight={minHeight}
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          onFocus={onFocus}
          placeholder={placeholder}
          required={required}
          value={value}
        />

        {helperText && (
          <Flex pt={8}>
            <Text colorName="grey-80" fontWeight="400" kind="caption">
              {helperText}
            </Text>
          </Flex>
        )}
      </Wrapper>
    )
  },
)
