import { gql, useQuery, type WatchQueryFetchPolicy } from '@apollo/client'
import {
  type TherapistAgendaByPatientIdForBookByParentQuery,
  type TherapistAgendaByPatientIdForBookByParentQueryVariables,
} from '~/types/graphql'

const QUERY = gql`
  query therapistAgendaByPatientIdForBookByParent($patientId: String!) {
    therapistAgendaByPatientIdForBookByParent(patientId: $patientId) {
      bonus
      churn
      id
      notes
      parentTherapySessionId
      patient {
        fullName
        firstName
        email
        phoneNumber
        id
      }
      provider
      recurrency
      repeatEveryWeek
      startAt
      status
      therapist {
        fullName
        id
      }
      therapyPathType
      typeCost
      url
    }
  }
`

export const useTherapistAgendaByPatientIdForBookByParent = (
  patientId: string,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first',
) => {
  const { data, error, loading, refetch, updateQuery } = useQuery<
    TherapistAgendaByPatientIdForBookByParentQuery,
    TherapistAgendaByPatientIdForBookByParentQueryVariables
  >(QUERY, {
    variables: {
      patientId,
    },
    fetchPolicy,
  })

  return {
    error: error != null,
    therapySession: data?.therapistAgendaByPatientIdForBookByParent ?? null,
    loading,
    refetch,
    updateQuery,
  }
}
