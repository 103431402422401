import { Flex, Form, OverflowAuto, Pressable } from 'cdk'
import { addSeconds, isBefore, setHours, setMinutes } from 'date-fns/fp'
import { format, FormatDateEnum, nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { noop } from 'functions'
import { Icon, Xmark } from 'icons'
import { useCallback, useEffect, useMemo } from 'react'
import { Button, Divider, Text } from 'ui'
import { Opacity } from '~/components/Opacity'
import { Translation } from '~/components/Translation'
import { CalendarAvailabilityFormSelect } from '~/domains/availabilities/calendar/components/CalendarAvailabilityFormSelect'
import { type CalendarEventWithNonNullableTherapySession } from '~/domains/availabilities/calendar/types'
import { Modal, useModals } from '~/domains/modals'
import { ReactHookFormCheckboxField, useReactHookFormContext } from '~/domains/react-hook-form'
import { FieldTherapySessionRepeat } from '~/domains/react-hook-form/fields/FieldTherapySessionRepeat'
import { FieldTherapySessionRepeatCount } from '~/domains/react-hook-form/fields/FieldTherapySessionRepeatCount'
import { FieldTherapySessionRepeatEvery } from '~/domains/react-hook-form/fields/FieldTherapySessionRepeatEvery'
import { useLoadingState } from '~/hooks/useLoadingState'
import { getEnv } from '~/utils/getEnv'
import { isNutrition } from '~/utils/therapyPaths'
import { useBookTherapySessionByParentOnSubmit } from '../hooks/useBookTherapySessionByParentOnSubmit'
import { usePatientIsAvailable } from '../hooks/usePatientIsAvailable'
import { type FormValues } from '../types/formValues'
import { RecurringTherapyField } from './RecurringTherapyField'

type Props = {
  isTherapySessionRecoveryMandatory: boolean
  isTherapySessionRecoverable: boolean
  onBookingSuccess: () => void
  onClose: () => void
  slot: CalendarEventWithNonNullableTherapySession
  therapyId: string
}

export const ModalBookTherapySessionByParent = ({
  isTherapySessionRecoveryMandatory,
  isTherapySessionRecoverable,
  onBookingSuccess,
  onClose,
  slot: { start, resource },
  therapyId,
}: Props) => {
  const modalId = `bookTherapySessionByParent:${therapyId}` as const

  const { loading } = useLoadingState()
  const { open, close, dismiss } = useModals()

  const bookTherapySessionByParentOnSubmit = useBookTherapySessionByParentOnSubmit({
    patientId: resource.therapySession.patient.id,
    therapyId,
  })

  const { isPatientAvailable, refetch } = usePatientIsAvailable({
    patientId: resource.therapySession.patient.id,
    startAt: start,
  })

  const form = useReactHookFormContext<FormValues>()

  const handleCancel = useCallback(() => {
    dismiss(modalId)

    onClose()
  }, [dismiss, onClose, modalId])

  const onSuccess = useCallback(() => {
    close(modalId)

    onBookingSuccess()
  }, [close, onBookingSuccess, modalId])

  const values = form.watch()

  const therapySessionNextStartAt = useMemo(
    () => pipe(start, setHours(Number(values.startAtHours)), setMinutes(Number(values.startAtMinutes))),
    [start, values],
  )

  const therapyPathType = resource.therapySession.therapyPathType

  useEffect(() => {
    const startAt = pipe(start, setHours(Number(values.startAtHours)), setMinutes(Number(values.startAtMinutes)))

    refetch({
      patientId: resource.therapySession.patient.id,
      startAt,
    }).catch(noop)
  }, [values, refetch, resource.therapySession.patient.id, start])

  const onSubmit = useCallback(
    async ({
      recoverTherapySession,
      recoverableTherapySessionId,
      recurringTherapy,
      repeat,
      repeatCount,
      repeatEvery,
      startAtHours,
      startAtMinutes,
    }: FormValues) => {
      if (!resource.id) {
        return
      }

      const parentTherapySessionId = resource.id

      const startAt = pipe(start, setHours(Number(startAtHours)), setMinutes(Number(startAtMinutes)))

      if (
        pipe(
          startAt,
          isBefore(
            pipe(nowInMilliseconds(), addSeconds(Number(getEnv('SERENIS_THERAPY_SESSION_CANCELLATION_POLICY')))),
          ),
        )
      ) {
        close(modalId)

        open(`bookTherapySessionByParentUnder24:${therapyId}`)

        return
      }

      // NOTE: we are blocking the therapist from booking a recurring therapy session if he is going to recover a therapy session
      await bookTherapySessionByParentOnSubmit({
        onSuccess,
        parentTherapySessionId,
        recoverTherapySession,
        recoverableTherapySessionId,
        recurringTherapy: recurringTherapy && !recoverTherapySession,
        repeat,
        repeatCount,
        repeatEvery,
        startAt,
      })
    },
    [bookTherapySessionByParentOnSubmit, close, modalId, open, onSuccess, resource.id, therapyId, start],
  )

  return (
    <Modal id={modalId}>
      <Form $grow={1} $shrink={1} onSubmit={form.handleSubmit(onSubmit)}>
        <Flex $align="flex-end">
          <Pressable disabled={loading} onClick={handleCancel}>
            <Icon Svg={Xmark} colorName="neutral-60" size={24} />
          </Pressable>
        </Flex>

        <OverflowAuto>
          <Text kind="h3" textAlign="center">
            <Translation id="therapySession.schedule.modal.title" />
          </Text>

          <Text kind="h3" textAlign="center">
            {resource.therapySession?.patient.fullName}
          </Text>

          <Flex $pt={4}>
            <Text colorName="neutral-80" kind="paragraph" textAlign="center">
              <Translation
                id="generic.fullDateAndHour"
                values={{
                  dateWithDayAndMonth: pipe(therapySessionNextStartAt, format(FormatDateEnum.DAY_MONTH_YEAR)),
                  hoursAndMinutes: pipe(therapySessionNextStartAt, format(FormatDateEnum.HOURS_MINUTES)),
                }}
              />
            </Text>
          </Flex>

          <Flex $pt={24}>
            <CalendarAvailabilityFormSelect isPatientAvailable={isPatientAvailable} type="startAt" />
          </Flex>

          {!isPatientAvailable && (
            <Flex $pt={16}>
              <Text colorName="red-80" kind="caption">
                <Translation id="forms.errors.patientIsNotAvailable" />
              </Text>
            </Flex>
          )}

          {isTherapySessionRecoverable && !isTherapySessionRecoveryMandatory && (
            <Flex $gap={20} $pt={40}>
              <Opacity opacity={values.recurringTherapy ? 0.4 : 1}>
                <ReactHookFormCheckboxField disabled={values.recurringTherapy} name="recoverTherapySession">
                  <Flex $pt={2}>
                    <Text as="label" colorName="darker" kind="caption">
                      <Translation id="therapySession.schedule.modal.recoverTherapySession" />
                    </Text>
                  </Flex>
                </ReactHookFormCheckboxField>
              </Opacity>

              <Divider />
            </Flex>
          )}

          {therapyPathType == null ||
            (!isNutrition(therapyPathType) && therapyPathType !== 'MYSELF_PSYCHIATRY' && (
              <Opacity opacity={values.recoverTherapySession ? 0.4 : 1}>
                <RecurringTherapyField disabled={values.recoverTherapySession} />
              </Opacity>
            ))}

          {values.recurringTherapy && !values.recoverTherapySession && (
            <>
              <Flex $direction="row" $gap={16} $pt={24}>
                <Flex>
                  <FieldTherapySessionRepeatEvery />
                </Flex>

                <Flex $grow={1} $pt={16}>
                  <FieldTherapySessionRepeat />
                </Flex>
              </Flex>

              <Flex $grow={1} $pt={32}>
                <FieldTherapySessionRepeatCount />
              </Flex>
            </>
          )}
        </OverflowAuto>

        <Flex $pt={24}>
          <Button isLoading={loading} kind="primary" type="submit">
            <Translation id="actions.confirm" />
          </Button>
        </Flex>
      </Form>
    </Modal>
  )
}
