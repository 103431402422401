import { ApolloError } from '@apollo/client'
import { noop } from 'functions'
import { useCallback } from 'react'
import { useTherapistCalendar } from '~/domains/availabilities/calendar/hooks/useTherapistCalendar'
import { useTherapySessionFindManyFutureForTherapistByPatientId } from '~/domains/patientsManagement/hooks/useTherapySessionFindManyFutureForTherapistByPatientId'
import { useTherapySessionsTherapistAgenda } from '~/domains/therapy-session/hooks/useTherapySessionsTherapistAgenda'
import { useBookTherapySessionByParent } from '~/domains/therapy-session/schedule/hooks/useBookTherapySessionByParent'
import { useLoadingState } from '~/hooks/useLoadingState'
import { useToasts } from '~/hooks/useToasts'

type Param = {
  onSuccess: () => void
  recoverTherapySession?: boolean
  recoverableTherapySessionId?: string | null
  parentTherapySessionId: string
  startAt: Date
}

type Props = {
  patientId: string
  therapyId: string
}

export const useCreateSpotTherapySession = ({ patientId, therapyId }: Props) => {
  const [bookTherapySessionByParent] = useBookTherapySessionByParent()
  const { addToast } = useToasts()
  const { refetch: refetchTherapistCalendar } = useTherapistCalendar()
  const { startLoading, stopLoading } = useLoadingState()
  const { refetch } = useTherapySessionsTherapistAgenda()
  const { refetch: refetchTherapySessionFindManyFutureForTherapistByPatientId } =
    useTherapySessionFindManyFutureForTherapistByPatientId(patientId)

  return useCallback(
    async ({
      onSuccess,
      parentTherapySessionId,
      recoverTherapySession,
      recoverableTherapySessionId,
      startAt,
    }: Param) => {
      startLoading('local')

      try {
        const response = await bookTherapySessionByParent({
          variables: {
            input: {
              parentTherapySessionId,
              recoverTherapySession,
              recoverableTherapySessionId,
              startAt,
              therapyId,
            },
          },
        })

        stopLoading()

        onSuccess()

        addToast({ translationId: 'booking.therapySession.bookedByParent', type: 'success' })

        const availability = response.data?.bookTherapySessionByParent

        if (!availability) {
          return
        }

        refetch().catch(noop)

        refetchTherapistCalendar().catch(noop)

        refetchTherapySessionFindManyFutureForTherapistByPatientId().catch(noop)
      } catch (error) {
        if (error instanceof ApolloError) {
          const message = error.message
          if (message === 'therapysession.first.alreadybooked') {
            addToast({
              translationId: 'booking.therapySession.failedBookingByParent.firstAlreadyExists',
              type: 'alert',
            })
            stopLoading()
            return
          }
        }

        addToast({ translationId: 'booking.therapySession.failedBookingByParent', type: 'alert' })
        stopLoading()
      }
    },
    [
      addToast,
      bookTherapySessionByParent,
      refetch,
      refetchTherapistCalendar,
      refetchTherapySessionFindManyFutureForTherapistByPatientId,
      startLoading,
      stopLoading,
      therapyId,
    ],
  )
}
