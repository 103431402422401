import { groupByToEntries } from 'arrays'
import { format, FormatDateEnum } from 'dates'
import { pipe } from 'fp-ts/function'
import { Download, EditPencil, Icon } from 'icons'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Text } from 'ui'
import { Flex, Loader, PositionAbsolute, PositionRelative, Pressable } from 'ui-deprecated'
import { CenteredLoader } from '~/components/CenteredLoader'
import { TimelineItem, VerticalDash } from '~/components/Timeline'
import { Translation } from '~/components/Translation'
import { TherapySessionNotes } from '~/domains/clinicalDiary/components/TherapySessionNotes'
import { useClinicalDiary } from '~/domains/clinicalDiary/hooks/useClinicalDiary'
import { PatientDetailMaxWidth } from '~/domains/patientsManagement/components/PatientDetailMaxWidth'
import { useDownloadFile } from '~/hooks/useDownloadFile'

type Props = {
  patientId: string
}

export const ClinicalDiary = ({ patientId }: Props) => {
  const { download, isDownloading } = useDownloadFile()

  const { therapySessionId } = useParams<{ therapySessionId?: string }>()

  const { loading, items, downloadUrl, error } = useClinicalDiary(patientId)

  const itemsByMonth = useMemo(
    () => groupByToEntries(items, ({ startAt }) => pipe(startAt, format(FormatDateEnum.MONTH_NAME_YEAR))),
    [items],
  )

  const downloadDiary = useCallback(() => {
    if (!downloadUrl) {
      return
    }

    download(downloadUrl)
  }, [download, downloadUrl])

  if (loading) {
    return <CenteredLoader />
  }

  if (error) {
    return null
  }

  if (!items.length) {
    return (
      <PatientDetailMaxWidth align="center" mdPt={24} pt={16}>
        <Flex align="center">
          <Icon Svg={EditPencil} colorName="grey-60" size={56} />
        </Flex>

        <Flex pt={16}>
          <Text colorName="grey-80" fontWeight="600" kind="caption" textAlign="center">
            <Translation id="patientDetail.noNotes" />
          </Text>
        </Flex>
      </PatientDetailMaxWidth>
    )
  }

  return (
    <PatientDetailMaxWidth grow={1} mdPt={24} pt={16}>
      <Flex pb={16}>
        <Flex direction="row">
          <Flex grow={1}>
            <Text colorName="primary" fontWeight="600" kind="paragraph">
              <Translation id="patientDetail.clinicalDiary.history" />
            </Text>
          </Flex>

          {downloadUrl && !isDownloading && (
            <Pressable
              align="center"
              autoWidth
              colorName={isDownloading ? 'purple04' : 'purple08'}
              direction="row"
              disabled={isDownloading}
              onClick={downloadDiary}
            >
              <Icon Svg={Download} colorName={isDownloading ? 'primary-30' : 'primary'} size={24} />
              <Text fontWeight="600" kind="paragraph">
                <Translation id="patientDetail.actions.downloadAll" />
              </Text>
            </Pressable>
          )}
          {isDownloading && <Loader colorName="purple08" size={24} />}
        </Flex>
      </Flex>

      <PositionRelative grow={1}>
        <PositionAbsolute bottom="0" top="0">
          <Flex pt={16} />

          <VerticalDash />
        </PositionAbsolute>

        <Flex>
          {itemsByMonth.map(([key, values], groupIndex) => (
            <TimelineItem key={groupIndex} circleInnerColorName="primary-30" circleOuterColorName="primary-40">
              <Text colorName="grey-80" fontWeight="600" kind="caption">
                {key}
              </Text>
              {values.map(({ id, notes, startAt, downloadUrl }, noteIndex) => (
                <Flex key={noteIndex} py={8}>
                  <TherapySessionNotes
                    downloadUrl={downloadUrl}
                    id={id}
                    initialOpenState={therapySessionId ? id === therapySessionId : groupIndex === 0 && noteIndex === 0}
                    notes={notes}
                    patientId={patientId}
                    startAt={startAt}
                  />
                </Flex>
              ))}
            </TimelineItem>
          ))}
        </Flex>
      </PositionRelative>
    </PatientDetailMaxWidth>
  )
}
