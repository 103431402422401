import {
  THERAPY_SESSION_DURATION_ONLINE_COACHING,
  THERAPY_SESSION_DURATION_ONLINE_COACHING_FIRST,
  THERAPY_SESSION_DURATION_ONLINE_COUPLE,
  THERAPY_SESSION_DURATION_ONLINE_COUPLE_FIRST,
  THERAPY_SESSION_DURATION_ONLINE_DCA,
  THERAPY_SESSION_DURATION_ONLINE_DCA_FIRST,
  THERAPY_SESSION_DURATION_ONLINE_DCA_ONBOARDING,
  THERAPY_SESSION_DURATION_ONLINE_MYSELF,
  THERAPY_SESSION_DURATION_ONLINE_MYSELF_FIRST,
  THERAPY_SESSION_DURATION_ONLINE_PSYCHIATRY,
  THERAPY_SESSION_DURATION_ONLINE_PSYCHIATRY_FIRST,
  THERAPY_SESSION_DURATION_ONLINE_SEXOLOGY,
  THERAPY_SESSION_DURATION_ONLINE_SEXOLOGY_FIRST,
  THERAPY_SESSION_DURATION_ONLINE_WEIGHT_LOSS,
  THERAPY_SESSION_DURATION_ONLINE_WEIGHT_LOSS_FIRST,
  THERAPY_SESSION_DURATION_ONLINE_WEIGHT_LOSS_ONBOARDING,
} from '~/constants'
import { type TherapyTherapyPathType } from '~/types/graphql'

type GetTherapyDurationBySessionsDoneProps = {
  therapyPath: TherapyTherapyPathType
  sessionsCount: number
}

export const getTherapyDurationBySessionsBooked = ({
  therapyPath,
  sessionsCount,
}: GetTherapyDurationBySessionsDoneProps): number => {
  switch (therapyPath) {
    case 'PATH_ASSERTIVE_COMMUNICATION':
    case 'PATH_SLEEP':
    case 'MYSELF_PSYCHOTHERAPY':
      return sessionsCount === 0 ? THERAPY_SESSION_DURATION_ONLINE_MYSELF_FIRST : THERAPY_SESSION_DURATION_ONLINE_MYSELF
    case 'PATH_COACHING':
      return sessionsCount === 0
        ? THERAPY_SESSION_DURATION_ONLINE_COACHING_FIRST
        : THERAPY_SESSION_DURATION_ONLINE_COACHING
    case 'PATH_NUTRITION_DCA':
      if (sessionsCount === 0) {
        return THERAPY_SESSION_DURATION_ONLINE_DCA_ONBOARDING
      }
      return sessionsCount === 1 ? THERAPY_SESSION_DURATION_ONLINE_DCA_FIRST : THERAPY_SESSION_DURATION_ONLINE_DCA
    case 'PATH_NUTRITION_WEIGHT_LOSS':
      if (sessionsCount === 0) {
        return THERAPY_SESSION_DURATION_ONLINE_WEIGHT_LOSS_ONBOARDING
      }
      return sessionsCount === 1
        ? THERAPY_SESSION_DURATION_ONLINE_WEIGHT_LOSS_FIRST
        : THERAPY_SESSION_DURATION_ONLINE_WEIGHT_LOSS
    case 'MYSELF_PSYCHIATRY':
      return sessionsCount === 0
        ? THERAPY_SESSION_DURATION_ONLINE_PSYCHIATRY_FIRST
        : THERAPY_SESSION_DURATION_ONLINE_PSYCHIATRY

    case 'PATH_SEXOLOGY':
      return sessionsCount === 0
        ? THERAPY_SESSION_DURATION_ONLINE_SEXOLOGY_FIRST
        : THERAPY_SESSION_DURATION_ONLINE_SEXOLOGY
    case 'COUPLES_PSYCHOTHERAPY':
      return sessionsCount === 0 ? THERAPY_SESSION_DURATION_ONLINE_COUPLE_FIRST : THERAPY_SESSION_DURATION_ONLINE_COUPLE
  }
}
