import { type ReactNode } from 'react'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { LocationReloadButton } from '~/components/LocationReloadButton'
import { Translation } from '~/components/Translation'
import { useChatEligible } from '~/domains/chat/hooks/useChatEligible'

type Props = {
  children: ReactNode
}

export const AssertChatEligibleEmptyState = ({ children }: Props) => {
  const eligible = useChatEligible()

  if (!eligible) {
    return (
      <Flex align="center" grow={1} justify="center" shrink={1}>
        <Text colorName="grey-80" fontWeight="600" kind="paragraph">
          <Translation id="chat.errors.emptyState.title" />
        </Text>
        <Flex pt={16}>
          <Text colorName="grey-80" fontWeight="600" kind="paragraph">
            <Translation id="chat.errors.emptyState.body" />
          </Text>
        </Flex>
        <Flex pt={40}>
          <LocationReloadButton kind="secondary" />
        </Flex>
      </Flex>
    )
  }

  return <>{children}</>
}
