import { Flex } from 'ui-deprecated'
import { ReactHookFormTextFieldNew, useReactHookFormContext } from '~/domains/react-hook-form'
import { ReactHookFormSelectNew } from '~/domains/react-hook-form/components/ReactHookFormSelectNew'
import { useTranslation } from '~/i18n/hooks/useTranslation'
import { useDiagnosisCouples } from '../hooks/useDiagnosisCouples'

const toLabelValuePairs = ({ id: value, name: label }: { id: string; name: string }) => ({
  label,
  value,
})

export const EditDiagnosisCouplesFieldsPatient = () => {
  const { countries, genderIdentities, pathologiesMyself, languages, workProfessions } = useDiagnosisCouples()
  const form = useReactHookFormContext()

  const age = useTranslation('patientDetail.diagnosis.info.age')
  const country = useTranslation('patientDetail.diagnosis.info.country')
  const degree = useTranslation('patientDetail.diagnosis.info.degree')
  const facing = useTranslation('patientDetail.diagnosis.state.facing')
  const genderIdentity = useTranslation('patientDetail.diagnosis.info.genderIdentity')
  const healthIssue = useTranslation('patientDetail.diagnosis.info.healthIssue')
  const historicalDiagnosis = useTranslation('patientDetail.diagnosis.info.historicalDiagnosis')
  const language = useTranslation('patientDetail.diagnosis.info.language')
  const pharmacologicalTreatment = useTranslation('patientDetail.diagnosis.info.pharmacologicalTreatment')
  const profession = useTranslation('patientDetail.diagnosis.info.profession')
  const psychiatricVulnerability = useTranslation('patientDetail.diagnosis.info.psychiatricVulnerability')
  const socialAndFamilyNetwork = useTranslation('patientDetail.diagnosis.info.socialAndFamilyNetwork')
  const socioCulturalContext = useTranslation('patientDetail.diagnosis.info.socioCulturalContext')
  const workProfession = useTranslation('patientDetail.diagnosis.info.workProfession')

  const selectedWorkProfession = form.watch('userWorkProfession')

  return (
    <>
      <Flex pt={16}>
        <ReactHookFormSelectNew
          multiple
          name="pathologiesMyselfPatient"
          options={pathologiesMyself.map(toLabelValuePairs)}
          placeholder={facing}
        />
      </Flex>

      <Flex pt={16}>
        <ReactHookFormSelectNew
          name="userGenderIdentity"
          options={genderIdentities.map(toLabelValuePairs)}
          placeholder={genderIdentity}
        />
      </Flex>

      <Flex pt={16}>
        <ReactHookFormTextFieldNew name="userAge" placeholder={age} type="number" />
      </Flex>
      <Flex pt={16}>
        <ReactHookFormSelectNew
          name="userWorkProfession"
          options={workProfessions.map(toLabelValuePairs)}
          placeholder={workProfession}
        />
      </Flex>

      <Flex pt={16}>
        <ReactHookFormTextFieldNew
          disabled={!selectedWorkProfession}
          name="userProfession"
          placeholder={profession}
          type="text"
        />
      </Flex>

      <Flex pt={16}>
        <ReactHookFormTextFieldNew name="userDegree" placeholder={degree} type="text" />
      </Flex>
      <Flex pt={16}>
        <ReactHookFormTextFieldNew name="userSocioCulturalContext" placeholder={socioCulturalContext} type="text" />
      </Flex>
      <Flex pt={16}>
        <ReactHookFormSelectNew name="userCountry" options={countries.map(toLabelValuePairs)} placeholder={country} />
      </Flex>
      <Flex pt={16}>
        <ReactHookFormSelectNew name="userLanguage" options={languages.map(toLabelValuePairs)} placeholder={language} />
      </Flex>
      <Flex pt={16}>
        <ReactHookFormTextFieldNew
          name="userPharmacologicalTreatment"
          placeholder={pharmacologicalTreatment}
          type="text"
        />
      </Flex>
      <Flex pt={16}>
        <ReactHookFormTextFieldNew name="userHistoricalDiagnosis" placeholder={historicalDiagnosis} type="text" />
      </Flex>
      <Flex pt={16}>
        <ReactHookFormTextFieldNew name="userHealthIssue" placeholder={healthIssue} type="text" />
      </Flex>
      <Flex pt={16}>
        <ReactHookFormTextFieldNew
          name="userPsychiatricVulnerability"
          placeholder={psychiatricVulnerability}
          type="text"
        />
      </Flex>
      <Flex pt={16}>
        <ReactHookFormTextFieldNew name="userSocialAndFamilyNetwork" placeholder={socialAndFamilyNetwork} type="text" />
      </Flex>
    </>
  )
}
