import { Flex } from 'cdk'
import { startOfDay } from 'date-fns'
import { SPACING_XS } from 'design-tokens'
import { useToasts } from '~/hooks/useToasts'
import { useGetFoodJournalDay } from '../../hooks/useGetFoodJournalDay'
import { useSetFoodJournalDrinks } from '../../hooks/useSetFoodJournalDrinks'
import { useFoodJournal } from '../FoodJournalContext'
import { type DrinkKind, DrinkTrackerOption } from './DrinkTrackerOption'

type Props = {
  editable?: boolean
}

export const DrinksTracker = ({ editable = false }: Props) => {
  const { addToast } = useToasts()
  const { patientId, currentDay } = useFoodJournal()

  const { foodJournalDay, loading } = useGetFoodJournalDay({
    day: currentDay,
    patientId,
  })
  const setDrinks = useSetFoodJournalDrinks()

  const amounts: Record<DrinkKind, number> = {
    water: foodJournalDay?.drinks.water ?? 0,
    alcohol: foodJournalDay?.drinks.alcohol ?? 0,
    beverage: foodJournalDay?.drinks.beverage ?? 0,
  }

  // TODO(comes): add throttle
  const updateDrink = async (kind: DrinkKind, newAmount: number) => {
    try {
      await setDrinks({
        variables: {
          input: {
            day: startOfDay(currentDay),
            patientId,
            [kind]: newAmount,
          },
        },
        ...(foodJournalDay != null
          ? {
              optimisticResponse: {
                setFoodJournalDrinks: {
                  __typename: 'FoodJournalDay',
                  ...foodJournalDay,
                  drinks: {
                    ...foodJournalDay.drinks,
                    [kind]: newAmount,
                  },
                },
              },
            }
          : {}),
      })
    } catch (error) {
      addToast({ type: 'alert', translationId: 'generic.errorRetryLater' })
    }
  }

  return (
    <Flex $gap={SPACING_XS} $lgDirection="row">
      <DrinkTrackerOption
        amount={amounts.water}
        kind="water"
        loading={loading}
        {...(editable ? { editable, updateDrink } : { editable })}
      />

      <DrinkTrackerOption
        amount={amounts.alcohol}
        kind="alcohol"
        loading={loading}
        {...(editable ? { editable, updateDrink } : { editable })}
      />

      <DrinkTrackerOption
        amount={amounts.beverage}
        kind="beverage"
        loading={loading}
        {...(editable ? { editable, updateDrink } : { editable })}
      />
    </Flex>
  )
}
