import { Flex } from 'cdk'
import { useMemo } from 'react'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useChatMessage } from '~/domains/chat/hooks/useChatMessage'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { useProductUserFeedbackCompleted } from '~/domains/productUserFeedback/hooks/useProductUserFeedbackCompleted'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { CustomMessageWrapper } from '../CustomMessageWrapper'
import { MessageClinicalBondToComplete } from './MessageClinicalBondToComplete'

export const MessageClinicalBond = () => {
  const group = useUserActingAsGroup()
  const { firstName } = useCurrentUser()
  const { therapies } = usePatientTherapies()

  const { message } = useChatMessage<'SYSTEM_CLINICAL_BOND'>()

  const therapistId = message.srns?.payload.therapistId
  const therapySessionId = message.srns?.payload.therapySessionId

  const { completed, error, loading, refetch } = useProductUserFeedbackCompleted('CLINICAL_BOND', {
    therapistId,
    therapySessionId,
  })

  const therapy = useMemo(
    () => therapies.find((therapy) => therapy.therapist?.id === therapistId),
    [therapies, therapistId],
  )

  if (
    !therapistId ||
    !therapySessionId ||
    group === 'therapist' ||
    error ||
    loading ||
    therapy?.therapyPath?.type === 'MYSELF_PSYCHIATRY'
  ) {
    return null
  }

  if (completed) {
    return (
      <CustomMessageWrapper publicMessage>
        <Flex $pb={8}>
          <Text fontWeight="600" kind="caption">
            <Translation id="systemMessage.clinicalBond.done.title" values={{ patientFirstName: firstName }} /> 🗳️
          </Text>
        </Flex>
        <Text fontWeight="400" kind="caption">
          <Translation id="systemMessage.clinicalBond.done.body" />
        </Text>
      </CustomMessageWrapper>
    )
  }

  return (
    <CustomMessageWrapper publicMessage>
      <MessageClinicalBondToComplete
        onCompleted={refetch}
        therapistFullName={therapy?.therapist?.fullName ?? ''}
        therapistId={therapistId}
        therapySessionId={therapySessionId}
      />
    </CustomMessageWrapper>
  )
}
