import { MemoryRouter, Route, Switch } from 'react-router-dom'
import { Modal } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { ReactHookFormProvider, useReactHookForm } from '~/domains/react-hook-form'
import { getTechnicalFeedbackRoute } from '~/domains/technicalFeedback/utils/getTechnicalFeedbackRoute'
import { type TechnicalFeedbackPsychiatry } from '../types'
import { TechnicalFeedbackStepContext } from './Psychiatry/TechnicalFeedbackStepContext'
import { TechnicalFeedbackStepEase } from './Psychiatry/TechnicalFeedbackStepEase'
import { TechnicalFeedbackStepEnd } from './Psychiatry/TechnicalFeedbackStepEnd'
import { TechnicalFeedbackStepMessage } from './Psychiatry/TechnicalFeedbackStepMessage'
import { TechnicalFeedbackStepPath } from './Psychiatry/TechnicalFeedbackStepPath'

const initialEntries = [
  getTechnicalFeedbackRoute('/ease'),
  getTechnicalFeedbackRoute('/path'),
  getTechnicalFeedbackRoute('/context'),
  getTechnicalFeedbackRoute('/message'),
]

type Props = {
  onCompleted: () => void
  technicalFeedbackId: string
}

export const TechnicalFeedbackPsychiatryModal = ({ onCompleted, technicalFeedbackId }: Props) => {
  const form = useReactHookForm<TechnicalFeedbackPsychiatry>({
    mode: 'all',
    defaultValues: {
      context: 0,
      ease: 0,
      message: '',
      path: 0,
    },
  })

  return (
    <ReactHookFormProvider {...form}>
      <Modal id="technicalFeedback">
        <ModalCloseButton id="technicalFeedback" onClose={onCompleted} />

        <MemoryRouter initialEntries={initialEntries} initialIndex={0}>
          <Switch>
            <Route exact path={getTechnicalFeedbackRoute('/ease')}>
              <TechnicalFeedbackStepEase technicalFeedbackId={technicalFeedbackId} />
            </Route>
            <Route exact path={getTechnicalFeedbackRoute('/path')}>
              <TechnicalFeedbackStepPath technicalFeedbackId={technicalFeedbackId} />
            </Route>
            <Route exact path={getTechnicalFeedbackRoute('/context')}>
              <TechnicalFeedbackStepContext technicalFeedbackId={technicalFeedbackId} />
            </Route>
            <Route exact path={getTechnicalFeedbackRoute('/message')}>
              <TechnicalFeedbackStepMessage technicalFeedbackId={technicalFeedbackId} />
            </Route>
            <Route exact path={getTechnicalFeedbackRoute('/end')}>
              <TechnicalFeedbackStepEnd onCompleted={onCompleted} technicalFeedbackId={technicalFeedbackId} />
            </Route>
          </Switch>
        </MemoryRouter>
      </Modal>
    </ReactHookFormProvider>
  )
}
