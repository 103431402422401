import { MessageSimple } from 'stream-chat-react'
import { MessageClinicalTestFilledByPatient, MessageClinicalTestTherapistSendToPatient } from '~/domains/clinicalTests'
import { useChatMessage } from '../../hooks/useChatMessage'
import { MessageClinicalBond } from './MessageClinicalBond'
import { MessageDeleted } from './MessageDeleted'
import { MessageFirstSessionBooked } from './MessageFirstSessionBooked'
import { MessageFirstSessionFeedback } from './MessageFirstSessionFeedback'
import { MessageJournalingLogShared } from './MessageJournalingLogShared'
import { MessageNotesAdded } from './MessageNotesAdded'
import { MessagePatientChurn } from './MessagePatientChurn'
import { MessagePatientRecurrencyDeleted } from './MessagePatientRecurrencyDeleted'
import { MessageRecurrencyCreated } from './MessageRecurrencyCreated'
import { MessageRecurrencyDeleted } from './MessageRecurrencyDeleted'
import { MessageRequestTherapySessionChange } from './MessageRequestTherapySessionChange'
import { MessageSessionBooked } from './MessageSessionBooked'
import { MessageSessionDeletedMissingInformedConsent } from './MessageSessionDeletedMissingInformedConsent'
import { MessageSessionDeletedNoShow } from './MessageSessionDeletedNoShow'
import { MessageSessionDeletedOnTime } from './MessageSessionDeletedOnTime'
import { MessageSessionDeletedOutOfTime } from './MessageSessionDeletedOutOfTime'
import { MessageSessionDeletedPaymentFailed } from './MessageSessionDeletedPaymentFailed'
import { MessageSessionDeletedTherapistEmergency } from './MessageSessionDeletedTherapistEmergency'
import { MessageSessionRescheduledByOthers } from './MessageSessionRescheduledByOthers'
import { MessageSessionRescheduledByPatient } from './MessageSessionRescheduledByPatient'
import { MessageSessionRescheduledByTherapist } from './MessageSessionRescheduledByTherapist'
import { MessageSystemWelcomeMessage } from './MessageSystemWelcomeMessage'
import { MessageTechnicalFeedback } from './MessageTechnicalFeedback'
import { MessageTherapistChurn } from './MessageTherapistChurn'
import { MessageTherapistChurnMissingInformation } from './MessageTherapistChurnMissingInformation'
import { MessageTherapistDiagnosisMissing } from './MessageTherapistDiagnosisMissing'
import { MessageTherapistPatientTurned18 } from './MessageTherapistPatientTurned18'
import { MessageTherapistRecurrencyDeleted } from './MessageTherapistRecurrencyDeleted'

export const CustomMessage = () => {
  const { message } = useChatMessage()

  if (message?.srns?.type === 'SYSTEM_FIRST_SESSION_BOOKED') {
    return <MessageFirstSessionBooked />
  }

  if (message?.srns?.type === 'SYSTEM_SESSION_BOOKED') {
    return <MessageSessionBooked />
  }

  if (message?.srns?.type === 'SYSTEM_SESSION_DELETED_NO_SHOW') {
    return <MessageSessionDeletedNoShow />
  }

  if (message?.srns?.type === 'SYSTEM_SESSION_DELETED_PAYMENT_FAILED') {
    return <MessageSessionDeletedPaymentFailed />
  }

  if (message?.srns?.type === 'SYSTEM_SESSION_DELETED_THERAPIST_EMERGENCY') {
    return <MessageSessionDeletedTherapistEmergency reason={message?.srns?.payload.emergencyReason} />
  }

  if (message?.srns?.type === 'SYSTEM_PATIENT_CHURN') {
    return <MessagePatientChurn />
  }

  if (message?.srns?.type === 'SYSTEM_THERAPIST_CHURN') {
    return <MessageTherapistChurn />
  }

  if (message?.srns?.type === 'SYSTEM_THERAPIST_CHURN_MISSING_INFORMATION') {
    return <MessageTherapistChurnMissingInformation />
  }

  if (message?.srns?.type === 'SYSTEM_PATIENT_TURNED_18') {
    return <MessageTherapistPatientTurned18 />
  }

  if (message?.srns?.type === 'SYSTEM_SESSION_DELETED_MISSING_INFORMED_CONSENT') {
    return <MessageSessionDeletedMissingInformedConsent />
  }

  if (message?.srns?.type === 'SYSTEM_SESSION_DELETED_ON_TIME') {
    return <MessageSessionDeletedOnTime />
  }

  if (message?.srns?.type === 'SYSTEM_SESSION_DELETED_OUT_OF_TIME') {
    return <MessageSessionDeletedOutOfTime />
  }

  if (message?.srns?.type === 'SYSTEM_SESSION_RESCHEDULED_BY_PATIENT') {
    return <MessageSessionRescheduledByPatient />
  }

  if (message?.srns?.type === 'SYSTEM_SESSION_RESCHEDULED_BY_THERAPIST') {
    return <MessageSessionRescheduledByTherapist />
  }

  if (message?.srns?.type === 'SYSTEM_SESSION_RESCHEDULED_BY_OTHERS') {
    return <MessageSessionRescheduledByOthers />
  }

  if (message?.srns?.type === 'SYSTEM_RECURRENCY_CREATED') {
    return <MessageRecurrencyCreated />
  }

  if (message?.srns?.type === 'SYSTEM_RECURRENCY_DELETED') {
    return <MessageRecurrencyDeleted />
  }

  if (message?.srns?.type === 'SYSTEM_THERAPIST_RECURRENCY_DELETED') {
    return <MessageTherapistRecurrencyDeleted />
  }

  if (message?.srns?.type === 'SYSTEM_PATIENT_RECURRENCY_DELETED') {
    return <MessagePatientRecurrencyDeleted />
  }

  if (message?.srns?.type === 'SYSTEM_REQUEST_THERAPY_SESSION_CHANGE') {
    return <MessageRequestTherapySessionChange />
  }

  if (message?.srns?.type === 'SYSTEM_TECHNICAL_FEEDBACK') {
    return <MessageTechnicalFeedback />
  }

  if (message?.srns?.type === 'SYSTEM_FIRST_SESSION_FEEDBACK') {
    return <MessageFirstSessionFeedback />
  }

  if (message?.srns?.type === 'SYSTEM_CLINICAL_BOND') {
    return <MessageClinicalBond />
  }

  if (message?.srns?.type === 'SYSTEM_THERAPIST_DIAGNOSIS_MISSING') {
    return <MessageTherapistDiagnosisMissing />
  }

  if (message?.srns?.type === 'SYSTEM_WELCOME_MESSAGE') {
    return <MessageSystemWelcomeMessage />
  }

  if (message?.srns?.type === 'SYSTEM_JOURNALING_LOG_SHARED') {
    return <MessageJournalingLogShared />
  }

  if (message?.srns?.type === 'SYSTEM_CLINICAL_TEST_THERAPIST_SEND_TO_PATIENT') {
    return <MessageClinicalTestTherapistSendToPatient />
  }

  if (message?.srns?.type === 'SYSTEM_CLINICAL_TEST_FILLED_BY_PATIENT') {
    return <MessageClinicalTestFilledByPatient />
  }

  if (message?.srns?.type === 'SYSTEM_PATIENT_NOTES_ADDED' || message?.srns?.type === 'SYSTEM_THERAPIST_NOTES_ADDED') {
    return <MessageNotesAdded />
  }

  if (message?.type === 'deleted') {
    return <MessageDeleted />
  }

  return <MessageSimple />
}
