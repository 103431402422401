import { Flex, OverflowAuto } from 'cdk'
import { useCallback } from 'react'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { ReactHookFormRating } from '~/domains/react-hook-form/fields/FieldRating'
import { FirstSessionFeedbackForm } from './FirstSessionFeedbackForm'
import { FormNextButton } from './FormNextButton'
import { type FirstSessionFeedback, type FirstSessionFeedbackOnSubmit } from './types'

export const RouteOverallScore = ({ onSubmit }: { onSubmit: FirstSessionFeedbackOnSubmit }) => {
  const handleSubmitWithRoute = useCallback(
    (values: FirstSessionFeedback) => {
      const value = values.overallScore
      onSubmit(value.toString(), 'overallScore', value >= 4 ? '/4-to-5-score' : '/1-to-3-score')
    },
    [onSubmit],
  )

  return (
    <FirstSessionFeedbackForm onSubmit={handleSubmitWithRoute}>
      <OverflowAuto $gap={16}>
        <Text colorName="black" fontWeight="600" kind="h3">
          <Translation id="firstSessionFeedback.overallScore.title" />
        </Text>
        <Text colorName="black" kind="paragraph">
          <Translation id="firstSessionFeedback.overallScore.body" />
        </Text>
        <ReactHookFormRating name="overallScore" rules={{ min: 1, max: 5 }} />
      </OverflowAuto>
      <Flex $pt={24}>
        <FormNextButton />
      </Flex>
    </FirstSessionFeedbackForm>
  )
}
