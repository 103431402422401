export type ManageSessionRoute =
  | '/'
  | '/delete'
  | '/delete-churn'
  | '/delete-message'
  | '/delete-recurrency'
  | '/reschedule-after-cancellation-policy-limit'
  | '/reschedule-motivation'
  | '/reschedule-session'
  | '/reschedule-summary'

export const getManageSessionRoutes = (route: ManageSessionRoute): ManageSessionRoute => route
