import { PatientTherapiesProvider } from '~/domains/patient/hooks/usePatientTherapies'
import { ClinicalBondModalVariant } from '../variant/ClinicalBondModalVariant'

type ClinicalBondModalProps = {
  clinicalBondId: string
  onCompleted: () => void
  therapistId: string
  therapySessionIndex: number
}

export const ClinicalBondModal = ({
  clinicalBondId,
  onCompleted,
  therapistId,
  therapySessionIndex,
}: ClinicalBondModalProps) => (
  <PatientTherapiesProvider>
    <ClinicalBondModalVariant
      clinicalBondId={clinicalBondId}
      onCompleted={onCompleted}
      therapistId={therapistId}
      therapySessionIndex={therapySessionIndex}
    />
  </PatientTherapiesProvider>
)
