import { setHours, setMinutes } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { useCallback } from 'react'
import { Text } from 'ui'
import { Button, Flex, Form, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { type CalendarEventWithNonNullableTherapySession } from '~/domains/availabilities/calendar/types'
import { Modal, useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { useLoadingState } from '~/hooks/useLoadingState'
import { useBookTherapySessionByParentOnSubmit } from '../hooks/useBookTherapySessionByParentOnSubmit'
import { type FormValues } from '../types/formValues'

type Props = {
  onClose: () => void
  onBookingSuccess: () => void
  slot: CalendarEventWithNonNullableTherapySession
  therapyId: string
}

export const ModalBookTherapySessionByParentUnder24 = ({
  onBookingSuccess,
  onClose,
  slot: { start, resource },
  therapyId,
}: Props) => {
  const modalId = `bookTherapySessionByParentUnder24:${therapyId}` as const

  const { close, dismiss } = useModals()
  const { loading } = useLoadingState()

  const bookTherapySessionByParentOnSubmit = useBookTherapySessionByParentOnSubmit({
    patientId: resource.therapySession.patient.id,
    therapyId,
  })

  const form = useReactHookFormContext<FormValues>()

  const handleCancel = useCallback(() => {
    dismiss(modalId)

    onClose()
  }, [dismiss, onClose, modalId])

  const onSuccess = useCallback(() => {
    close(modalId)

    onBookingSuccess()
  }, [close, modalId, onBookingSuccess])

  const onSubmit = useCallback(
    async ({
      recoverTherapySession,
      recoverableTherapySessionId,
      recurringTherapy,
      repeat,
      repeatCount,
      repeatEvery,
      startAtHours,
      startAtMinutes,
    }: FormValues) => {
      if (!resource.id) {
        return
      }

      const parentTherapySessionId = resource.id

      const startAt = pipe(start, setHours(Number(startAtHours)), setMinutes(Number(startAtMinutes)))

      await bookTherapySessionByParentOnSubmit({
        onSuccess,
        parentTherapySessionId,
        recoverTherapySession,
        recoverableTherapySessionId,
        recurringTherapy,
        repeat,
        repeatCount,
        repeatEvery,
        startAt,
      })
    },
    [bookTherapySessionByParentOnSubmit, onSuccess, resource.id, start],
  )

  return (
    <Modal id={modalId}>
      <Form grow={1} onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
        <ModalCloseButton id={modalId} />

        <OverflowAuto lgPt={40} mdPt={32} pt={24} px={8}>
          <Text fontWeight="600" kind="h2">
            <Translation id="availabilities.calendar.rescheduleTherapySessionUnder24.title" />
          </Text>

          <Flex pt={16}>
            <Text fontWeight="400" kind="paragraph">
              <Translation id="availabilities.calendar.rescheduleTherapySessionUnder24.description" />
            </Text>
          </Flex>
        </OverflowAuto>

        <Flex pt={16} px={8}>
          <Flex pt={16}>
            <Button kind="primary" loading={loading} type="submit">
              <Translation id="actions.gotIt" />
            </Button>
            <Flex pt={8}>
              <Button disabled={loading} kind="secondary" onClick={handleCancel}>
                <Translation id="actions.cancel" />
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Form>
    </Modal>
  )
}
