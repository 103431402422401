import { groupByToEntries } from 'arrays'
import { formatISO, startOfDay } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { useMemo } from 'react'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { mapAvailabilitiesToSlotsByDayAndTherapist } from '../utils/mapAvailabilitiesToSlotsByDayAndTherapist'
import { padSlotsByDay } from '../utils/padSlotsByDay'

type UseTimeSlotsParams = {
  items: {
    id: string
    endAt: Date
    startAt: Date
    userId: string
  }[]
  therapyPathType: TherapyTherapyPathType
}

const useTimeSlots = ({ items, therapyPathType }: UseTimeSlotsParams) => {
  const slotsByDayAndTherapist = useMemo(() => {
    const availabilitiesByDay: [string, typeof items][] = groupByToEntries(items, ({ startAt }) =>
      pipe(startAt, startOfDay, formatISO),
    )

    return mapAvailabilitiesToSlotsByDayAndTherapist(availabilitiesByDay, therapyPathType)
  }, [items, therapyPathType])

  return {
    slotsByDayAndTherapist,
  }
}

type UseAvailableTimeSlotsByDayParams = {
  items: {
    id: string
    endAt: Date
    startAt: Date
    userId: string
  }[]
  therapyPathType: TherapyTherapyPathType
}

export const useAvailableTimeSlotsByDay = ({ items, therapyPathType }: UseAvailableTimeSlotsByDayParams) => {
  const { slotsByDayAndTherapist } = useTimeSlots({
    items,
    therapyPathType,
  })

  const slotsByDay = useMemo(() => padSlotsByDay(slotsByDayAndTherapist), [slotsByDayAndTherapist])

  return {
    slotsByDay,
  }
}
