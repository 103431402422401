import { SfuModels, useCallStateHooks } from '@stream-io/video-react-sdk'
import { useDebounce } from 'hooks'
import { Icon, ReportsPoor } from 'icons'
import { Alert } from 'ui'
import { Translation } from '~/components/Translation'

export const BadQualityConnectionAlert = () => {
  const { useLocalParticipant } = useCallStateHooks()
  const localParticipant = useLocalParticipant()
  const isConnectionPoorForMoreThanFiveSeconds = useDebounce(
    localParticipant?.connectionQuality === SfuModels.ConnectionQuality.POOR,
    5000,
  )

  if (!isConnectionPoorForMoreThanFiveSeconds) {
    return null
  }

  return (
    <Alert endChildren={<></>} kind="error" startChildren={<Icon Svg={ReportsPoor} size={24} />}>
      <Translation id="videocall.connectionQuality.poor.help" />
    </Alert>
  )
}
