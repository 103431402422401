import { isSameDay, isWeekend } from 'date-fns/fp'
import { format, FormatDateEnum, nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { useMemo } from 'react'
import { type HeaderProps } from 'react-big-calendar'
import styled from 'styled-components'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'

const Dot = styled(Flex)`
  width: 32px;
  height: 32px;
`

export const TherapistCalendarHeader = ({ date }: HeaderProps) => {
  const { dayNumber, today, weekDay, weekend } = useMemo(
    () => ({
      today: pipe(date, isSameDay(nowInMilliseconds())),
      dayNumber: pipe(date, format(FormatDateEnum.DAY_OF_MONTH)),
      weekDay: pipe(date, format(FormatDateEnum.WEEK_DAY_SHORT)),
      weekend: pipe(date, isWeekend),
    }),
    [date],
  )

  return (
    <Flex align="flex-start" background={weekend ? 'grey02' : 'transparent'} p={8}>
      <Text colorName="grey-80" fontWeight="400" kind="caption" textTransform="uppercase">
        {weekDay}
      </Text>

      <Flex pt={4}>
        <Dot
          align={today ? 'center' : 'flex-start'}
          background={today ? 'purple08' : 'transparent'}
          borderRadius={100}
          justify="center"
        >
          <Text colorName={today ? 'white' : 'grey-80'} fontWeight="600" kind="paragraph">
            {dayNumber}
          </Text>
        </Dot>
      </Flex>
    </Flex>
  )
}
