import { Suspense } from 'react'
import { MemoryRouter, useParams } from 'react-router-dom'
import { CenteredLoader } from '~/components/CenteredLoader'
import { AssertAnonymous } from '~/domains/auth/components/AssertAnonymous'
import { AssertFeatureFlagByFingerprintIsVariant } from '~/domains/featureFlags/components/AssertFeatureFlagByFingerprintIsVariant'
import { AssertAtLeastOneFormFlow } from '~/domains/reservation/components/AssertAtLeastOneFormFlow'
import { PreBooking } from '~/domains/reservation/pages/preBooking'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'

export const PreBookingPage: Page = {
  exact: true,
  id: 'reservation.pre-booking',
  paths: ['/pre-booking/:therapyPathId'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: false,
    seoKey: 'reservation.preBooking',
    title: null,
  },
  PageGuard: ({ children }) => {
    const { therapyPathId } = useParams<{ therapyPathId: string }>()

    return (
      <AssertFeatureFlagByFingerprintIsVariant names={['ff_pre_signup_therapists', 'ff_pre_signup_nutritionists']}>
        <AssertAnonymous target={`/booking/${therapyPathId}`}>
          <AssertAtLeastOneFormFlow>{children}</AssertAtLeastOneFormFlow>
        </AssertAnonymous>
      </AssertFeatureFlagByFingerprintIsVariant>
    )
  },
  PageLoaded: PageNoop,
  PageProvider: ({ children }) => (
    <MemoryRouter initialEntries={['/book-by-suggested-therapist']} initialIndex={0}>
      <Suspense fallback={<CenteredLoader />}>{children}</Suspense>
    </MemoryRouter>
  ),
  PageView: PreBooking,
}
