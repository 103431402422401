import loadable from '@loadable/component'
import { useParams } from 'react-router-dom'
import { CenteredLoader } from '~/components/CenteredLoader'
import { useTherapySessionIndex } from '~/hooks/useTherapySessionIndex'
import { type EndedViewProps } from '.'

const LazyEndedPatientAfterEighthSession = loadable(() => import('./EndedPatientAfterEighthSession.lazy'), {
  resolveComponent: (components) => components.EndedPatientAfterEighthSession,
})

const LazyEndedPatientAfterSecondSession = loadable(() => import('./EndedPatientAfterSecondSession.lazy'), {
  resolveComponent: (components) => components.EndedPatientAfterSecondSession,
})

const LazyEndedPatientAfterSixthSession = loadable(() => import('./EndedPatientAfterSixthSession.lazy'), {
  resolveComponent: (components) => components.EndedPatientAfterSixthSession,
})

const LazyEndedPatientFallback = loadable(() => import('./EndedPatientFallback.lazy'), {
  resolveComponent: (components) => components.EndedPatientFallback,
})

const LazyEndedPatientFirstSession = loadable(() => import('./EndedPatientFirstSession.lazy'), {
  resolveComponent: (components) => components.EndedPatientFirstSession,
})

type Param = {
  therapySessionId: string
}

type Props = Pick<EndedViewProps, 'firstName' | 'onGoBackClick'>

export const EndedPatient = ({ firstName, onGoBackClick }: Props) => {
  const { therapySessionId } = useParams<Param>()
  const { error, index, loading } = useTherapySessionIndex(therapySessionId)

  if (loading) {
    return <CenteredLoader />
  }

  if (error || !index) {
    return <LazyEndedPatientFallback firstName={firstName} onGoBackClick={onGoBackClick} />
  }

  if (index === 1) {
    return <LazyEndedPatientFirstSession onGoBackClick={onGoBackClick} />
  }

  if (index >= 8) {
    return <LazyEndedPatientAfterEighthSession onGoBackClick={onGoBackClick} />
  }

  if (index >= 6) {
    return <LazyEndedPatientAfterSixthSession onGoBackClick={onGoBackClick} />
  }

  if (index >= 2) {
    return <LazyEndedPatientAfterSecondSession firstName={firstName} index={index} onGoBackClick={onGoBackClick} />
  }

  return <LazyEndedPatientFallback firstName={firstName} onGoBackClick={onGoBackClick} />
}
