import { Flex, Form, OverflowAuto } from 'cdk'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, ModalDescription, ModalTitle, Radio, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useTrackEventView } from '~/domains/analytics/hooks/useTrackEventView'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { type TranslationId } from '~/i18n/types'
import { type ChangeTherapistReason } from '~/types/graphql'
import { getChangeTherapistModalRoutes } from '../utils/routes'

type FormValues = {
  changeTherapistReason: ChangeTherapistReason
}

type Option = {
  id: TranslationId
  value: ChangeTherapistReason
}

export const CHANGE_THERAPIST_REASON_MANUAL_OPTIONS: Option[] = [
  { id: 'changeTherapist.reason.variant.badExperience', value: 'BAD_EXPERIENCE' },
  { id: 'changeTherapist.reason.variant.therapistLocation', value: 'THERAPIST_LOCATION' },
  { id: 'changeTherapist.reason.variant.lackOfExperience', value: 'LACK_OF_EXPERIENCE' },
  { id: 'changeTherapist.reason.variant.needToChange', value: 'NEED_TO_CHANGE' },
  { id: 'changeTherapist.reason.variant.other.step.2', value: 'OTHER' },
]

const ChangeTherapistReasonVariantManualReasons = () => {
  const {
    errors: { required },
  } = useFormTranslations()
  const { formState, register } = useReactHookFormContext<FormValues>()

  const hasError = !!formState.errors.changeTherapistReason?.message

  return (
    <Flex $gap={16}>
      {hasError && (
        <Text colorName="error" kind="caption">
          {required}
        </Text>
      )}

      {CHANGE_THERAPIST_REASON_MANUAL_OPTIONS.map(({ id, value }) => (
        <Flex
          key={id}
          $align="center"
          $direction="row"
          $gap={8}
          $px={8}
          as="label"
          htmlFor={`changeTherapistReason-${value}`}
        >
          <Radio
            {...register('changeTherapistReason', { required })}
            id={`changeTherapistReason-${value}`}
            required
            value={value}
          />
          <Translation id={id} />
        </Flex>
      ))}
    </Flex>
  )
}

export const ChangeTherapistReasonManual = () => {
  const history = useHistory()
  const form = useReactHookFormContext<FormValues>()

  const onSubmit = useCallback(() => {
    history.push(getChangeTherapistModalRoutes('/changeTherapistReasonOpen'))
  }, [history])

  const onBackClick = useCallback(() => {
    history.goBack()
  }, [history])

  useTrackEventView('changeTherapist.modal.manual')

  return (
    <Form $gap={16} $grow={1} $shrink={1} onSubmit={form.handleSubmit(onSubmit)}>
      <OverflowAuto $gap={16}>
        <ModalTitle>
          <Translation id="changeTherapist.modal.variant.step.2.title" />
        </ModalTitle>
        <ModalDescription>
          <Translation id="changeTherapist.modal.variant.step.2.description" />
        </ModalDescription>
        <ChangeTherapistReasonVariantManualReasons />
      </OverflowAuto>
      <Flex $gap={8}>
        <Button kind="primary" type="submit">
          <Translation id="actions.proceed" />
        </Button>
        <Button isGhost kind="primary" onClick={onBackClick}>
          <Translation id="actions.back" />
        </Button>
      </Flex>
    </Form>
  )
}
