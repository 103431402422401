import { isAfter } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { useCallback, useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Button, Flex } from 'ui-deprecated'
import { StopTherapySessionButton } from '~/components/TherapySessionCardTherapist/components/StopTherapySessionButton'
import { Translation } from '~/components/Translation'
import { type TherapistAgendaTherapySession } from '~/domains/agenda/types'
import { useTherapySessionsTherapistAgenda } from '~/domains/therapy-session/hooks/useTherapySessionsTherapistAgenda'
import { type TherapySessionStatus } from '~/types/graphql'
import { getRoute } from '~/utils/getRoute'

type BookNextButtonProps = {
  therapyId: string
}

const BookNextButton = ({ therapyId }: BookNextButtonProps) => {
  const history = useHistory()

  const match = useRouteMatch()

  const onClick = useCallback(() => {
    if (!therapyId) {
      return
    }

    history.push(`${match.url}${getRoute(`/therapy-session/${therapyId}/schedule`)}`)
  }, [history, match.url, therapyId])

  return (
    <Button kind="primary" onClick={onClick} size="small">
      <Translation id="chat.therapist.scheduleNewSession" />
    </Button>
  )
}

type Props = Pick<TherapistAgendaTherapySession, 'churn' | 'id' | 'recurrency' | 'startAt' | 'status' | 'therapist'> & {
  patient: Pick<TherapistAgendaTherapySession['patient'], 'id' | 'fullName'>
  therapyId: string
}

export const PatientDetailEmptyStateActions = ({
  churn,
  id,
  patient,
  recurrency,
  startAt,
  status,
  therapist,
  therapyId,
}: Props) => {
  const { items } = useTherapySessionsTherapistAgenda()

  const futureTherapySession = useMemo(
    () =>
      items.find(
        (future) =>
          future.parentTherapySessionId === id ||
          (future.patient.id === patient.id &&
            future.therapist &&
            therapist &&
            future.therapist.id === therapist.id &&
            pipe(future.startAt, isAfter(startAt))),
      ),
    [id, items, patient.id, startAt, therapist],
  )

  const showBookNext = useMemo(
    () => (['ENDED', 'ONGOING', 'NO_SHOW'] as TherapySessionStatus[]).includes(status) && !futureTherapySession,
    [futureTherapySession, status],
  )

  const showStopTherapySession = useMemo(
    () =>
      (['ENDED', 'ONGOING'] as TherapySessionStatus[]).includes(status) &&
      !churn &&
      (!futureTherapySession || (futureTherapySession && recurrency)),
    [churn, futureTherapySession, recurrency, status],
  )

  return (
    <>
      {showBookNext && <BookNextButton therapyId={therapyId} />}

      {showStopTherapySession && (
        <Flex pt={showBookNext ? 16 : 0}>
          <StopTherapySessionButton id={id} patient={patient} />
        </Flex>
      )}
    </>
  )
}
