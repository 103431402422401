import { Flex, Pressable } from 'cdk'
import { Icon, NavArrowDown, NavArrowUp, PageFlip } from 'icons'
import { useCallback, useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Card, Link, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'

type Props = {
  accordionAlways?: boolean
}

export const HowItWorksInvoice = ({ accordionAlways = false }: Props) => {
  const [hidden, setHidden] = useState(true)

  const onClick = useCallback(() => {
    setHidden((value) => !value)
  }, [])

  return (
    <Card>
      <Pressable onClick={onClick}>
        <Flex $align="center" $direction="row" $gap={8} $justify="space-between" $mdHide={!accordionAlways}>
          <Text fontWeight="600" kind="paragraph">
            <Translation id="payments.howItWorks.settings.invoices.title" />
          </Text>

          <Flex $align="flex-end">
            {hidden && <Icon Svg={NavArrowDown} colorName="primary" size={24} />}
            {!hidden && <Icon Svg={NavArrowUp} colorName="primary" size={24} />}
          </Flex>
        </Flex>
      </Pressable>

      <Flex $hide $mdHide={accordionAlways}>
        <Text colorName="primary" fontWeight="600" kind="paragraph">
          <Translation id="payments.howItWorks.settings.invoices.title" />
        </Text>
      </Flex>

      <Flex $direction="row" $gap={16} $hide={hidden} $mdHide={accordionAlways ? hidden : accordionAlways} $pt={20}>
        <Flex>
          <Icon Svg={PageFlip} colorName="neutral-60" size={24} />
        </Flex>
        <Flex $gap={8} $grow={1} $shrink={1}>
          <TranslationMarkdown
            colorName="darker"
            id="payments.howItWorks.settings.invoices.description"
            kind="paragraph"
          />

          <Link as={ReactRouterLink} to="/settings/billing">
            <Text kind="paragraph" textDecoration="underline">
              <Translation id="actions.addYourBillingInfo" />
            </Text>
          </Link>
        </Flex>
      </Flex>
    </Card>
  )
}
