import { gql, useLazyQuery } from '@apollo/client'
import { type GetFoodJournalDaysQuery, type GetFoodJournalDaysQueryVariables } from '~/types/graphql'

export const GET_FOOD_JOURNAL_DAYS_QUERY = gql`
  query getFoodJournalDays($patientId: String!, $from: DateTime!, $to: DateTime!) {
    foodJournalDays(patientId: $patientId, from: $from, to: $to) {
      id
      day
      mood
    }
  }
`

export const useLazyGetFoodJournalDays = () => {
  const result = useLazyQuery<GetFoodJournalDaysQuery, GetFoodJournalDaysQueryVariables>(GET_FOOD_JOURNAL_DAYS_QUERY, {
    fetchPolicy: 'cache-first',
  })

  return result
}
