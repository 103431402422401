import { useMemo } from 'react'
import { MemoryRouter, Route, Switch } from 'react-router-dom'
import { Modal } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { ReactHookFormProvider, useReactHookForm } from '~/domains/react-hook-form'
import { ClinicalBondStepEnd } from '../components/ClinicalBondStepEnd'
import { ClinicalBondStepInfo } from '../components/ClinicalBondStepInfo'
import { ClinicalBondStepListen } from '../components/ClinicalBondStepListen'
import { ClinicalBondStepManagement } from '../components/ClinicalBondStepManagement'
import { ClinicalBondStepOverall } from '../components/ClinicalBondStepOverall'
import { ClinicalBondStepRespect } from '../components/ClinicalBondStepRespect'
import { type ClinicalBondFormValues } from '../types'
import { getClinicalBondRoute } from '../utils/getClinicalBondRoute'
import { ClinicalBondStepEndVariant } from './ClinicalBondStepEndVariant'
import { ClinicalBondStepExplaining } from './ClinicalBondStepExplaining'
import { ClinicalBondStepFeeling } from './ClinicalBondStepFeeling'
import { ClinicalBondStepFirst } from './ClinicalBondStepFirst'
import { ClinicalBondStepOther } from './ClinicalBondStepOther'
import { ClinicalBondStepProfessionality } from './ClinicalBondStepProfessionality'
import { type ClinicalBondVariantFormValues } from './ClinicalBondVariantFormValues'
import { getClinicalBondVariantRoute } from './getClinicalBondVariantRoute'

type Props = {
  onCompleted: () => void
  clinicalBondId: string
  therapistId: string
  therapySessionIndex: number
}

export const ClinicalBondModalVariant = ({ clinicalBondId, onCompleted, therapistId, therapySessionIndex }: Props) => {
  const form = useReactHookForm<ClinicalBondVariantFormValues | ClinicalBondFormValues>({
    mode: 'all',
    defaultValues: {},
  })

  const { therapies } = usePatientTherapies()

  const therapy = useMemo(
    () => therapies.find(({ therapist }) => therapist?.id === therapistId),
    [therapies, therapistId],
  )

  if (!therapy) {
    return null
  }

  return therapySessionIndex === 1 ? (
    <ReactHookFormProvider {...form}>
      <Modal id="clinicalBond">
        <ModalCloseButton id="clinicalBond" onClose={onCompleted} />

        <MemoryRouter
          initialEntries={[
            getClinicalBondVariantRoute(therapy?.therapyPath?.type === 'PATH_COACHING' ? '/feeling' : '/first'),
          ]}
          initialIndex={0}
        >
          <Switch>
            <Route exact path={getClinicalBondVariantRoute('/first')}>
              <ClinicalBondStepFirst />
            </Route>
            <Route exact path={getClinicalBondVariantRoute('/feeling')}>
              <ClinicalBondStepFeeling clinicalBondId={clinicalBondId} therapy={therapy} />
            </Route>
            <Route exact path={getClinicalBondVariantRoute('/explaining')}>
              <ClinicalBondStepExplaining clinicalBondId={clinicalBondId} />
            </Route>
            <Route exact path={getClinicalBondVariantRoute('/professionality')}>
              <ClinicalBondStepProfessionality clinicalBondId={clinicalBondId} />
            </Route>
            <Route exact path={getClinicalBondVariantRoute('/other')}>
              <ClinicalBondStepOther clinicalBondId={clinicalBondId} />
            </Route>
            <Route exact path={getClinicalBondVariantRoute('/end/:score')}>
              <ClinicalBondStepEndVariant onCompleted={onCompleted} />
            </Route>
          </Switch>
        </MemoryRouter>
      </Modal>
    </ReactHookFormProvider>
  ) : (
    <ReactHookFormProvider {...form}>
      <Modal id="clinicalBond">
        <ModalCloseButton id="clinicalBond" onClose={onCompleted} />

        <MemoryRouter initialEntries={[getClinicalBondRoute('/respect')]} initialIndex={0}>
          <Switch>
            <Route exact path={getClinicalBondRoute('/respect')}>
              <ClinicalBondStepRespect clinicalBondId={clinicalBondId} therapy={therapy} />
            </Route>
            <Route exact path={getClinicalBondRoute('/listen')}>
              <ClinicalBondStepListen clinicalBondId={clinicalBondId} therapy={therapy} />
            </Route>
            <Route exact path={getClinicalBondRoute('/management')}>
              <ClinicalBondStepManagement clinicalBondId={clinicalBondId} therapy={therapy} />
            </Route>
            <Route exact path={getClinicalBondRoute('/overall')}>
              <ClinicalBondStepOverall clinicalBondId={clinicalBondId} therapy={therapy} />
            </Route>
            <Route exact path={getClinicalBondRoute('/info')}>
              <ClinicalBondStepInfo clinicalBondId={clinicalBondId} />
            </Route>
            <Route exact path={getClinicalBondRoute('/end/:score')}>
              <ClinicalBondStepEnd onCompleted={onCompleted} />
            </Route>
          </Switch>
        </MemoryRouter>
      </Modal>
    </ReactHookFormProvider>
  )
}
