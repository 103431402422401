import { Form } from 'cdk'
import { isValid } from 'date-fns'
import { useCallback, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { GLOBAL_VALUE_SEPARATOR } from '~/constants'
import { ReactHookFormProvider, useReactHookForm } from '~/domains/react-hook-form'
import { useBookSession } from '../hooks/useBookSession'
import { getBookSessionRoutes } from '../utils/getBookSessionRoutes'
import { BookSessionSlots } from './BookSessionSlots'
import { BookSessionSummary } from './BookSessionSummary'

type FormValues = {
  selectedTimeSlotWithTherapist: string | null
}

export const BookSessionRoutes = () => {
  const { bookNewSession } = useBookSession()

  const form = useReactHookForm<FormValues>({
    defaultValues: {
      selectedTimeSlotWithTherapist: null,
    },
  })

  useEffect(() => {
    form.register('selectedTimeSlotWithTherapist', { required: true })
    form.trigger('selectedTimeSlotWithTherapist')
  }, [form])

  const handleSubmit = useCallback(
    ({ selectedTimeSlotWithTherapist }: FormValues) => {
      if (!selectedTimeSlotWithTherapist) {
        return
      }

      const [slot] = selectedTimeSlotWithTherapist.split(GLOBAL_VALUE_SEPARATOR)

      const date = new Date(slot)

      if (!isValid(date)) {
        return
      }

      bookNewSession({ nextRoute: getBookSessionRoutes('/summary'), selectedSlot: date })
    },
    [bookNewSession],
  )

  return (
    <ReactHookFormProvider {...form}>
      <Form $grow={1} $shrink={1} onSubmit={form.handleSubmit(handleSubmit)}>
        <Route exact path={getBookSessionRoutes('/book-therapy-session')}>
          <BookSessionSlots />
        </Route>
        <Route exact path={getBookSessionRoutes('/summary')}>
          <BookSessionSummary />
        </Route>
      </Form>
    </ReactHookFormProvider>
  )
}
