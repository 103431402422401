import { gql, useQuery } from '@apollo/client'
import { type GetCourierTokenQuery, type GetCourierTokenQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query getCourierToken {
    getCourierToken {
      token
    }
  }
`

export const useCourierToken = () => {
  const { data, loading } = useQuery<GetCourierTokenQuery, GetCourierTokenQueryVariables>(QUERY, {
    fetchPolicy: 'cache-first',
  })

  return { token: data?.getCourierToken?.token, loading }
}
