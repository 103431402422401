import { Space } from 'cdk'
import { Icon, Xmark } from 'icons'
import { useCallback } from 'react'
import { Text } from 'ui'
import { Flex, PositionAbsolute, PositionRelative } from 'ui-deprecated'
import { TertiaryButtonIconOnly } from '~/components/TertiaryButton/TertiaryButtonIconOnly'
import { Translation } from '~/components/Translation'
import { useTherapySessionLive, useVideocallSidePanel } from '~/domains/videocall'
import { usePatientStatusLabels } from '~/hooks/usePatientStatusLabels'

export const PatientDetailRootHeader = () => {
  const { patient } = useTherapySessionLive()
  const patientStatusLabels = usePatientStatusLabels(patient.id)
  const { setIsPatientDetailOpen } = useVideocallSidePanel()

  const onClick = useCallback(() => {
    setIsPatientDetailOpen(false)
  }, [setIsPatientDetailOpen])

  return (
    <PositionRelative grow={1} shrink={1}>
      <PositionAbsolute right="0" top="0">
        <TertiaryButtonIconOnly onClick={onClick}>
          <Icon Svg={Xmark} size={24} />
        </TertiaryButtonIconOnly>
      </PositionAbsolute>

      <Flex align="center" grow={1} justify="center" shrink={1}>
        <Text fontWeight="600" kind="paragraph">
          {patient?.fullName}
        </Text>
        {!!patientStatusLabels?.status && (
          <Text colorName="grey-80" fontWeight="400" kind="paragraph">
            <Text colorName="grey-80" fontWeight="400" kind="paragraph">
              <Translation id="patientsManagement.status" />
            </Text>
            <Space />
            <Text colorName={patientStatusLabels.status.colorName} fontWeight="600" kind="paragraph">
              <Translation id={patientStatusLabels.status.key} />
            </Text>
          </Text>
        )}
      </Flex>
    </PositionRelative>
  )
}
