import { gql, useQuery } from '@apollo/client'
import {
  type PatientIsAvailableQuery,
  type PatientIsAvailableQueryVariables,
  type QueryPatientIsAvailableArgs,
} from '~/types/graphql'

const QUERY = gql`
  query patientIsAvailable($patientId: String!, $startAt: Date!) {
    patientIsAvailable(patientId: $patientId, startAt: $startAt)
  }
`

export const usePatientIsAvailable = ({ patientId, startAt }: QueryPatientIsAvailableArgs) => {
  const { data, error, loading, refetch } = useQuery<PatientIsAvailableQuery, PatientIsAvailableQueryVariables>(QUERY, {
    variables: {
      patientId,
      startAt,
    },
    fetchPolicy: 'cache-first',
  })

  return {
    error: !!error,
    loading,
    refetch,
    isPatientAvailable: data?.patientIsAvailable,
  }
}
