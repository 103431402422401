import { ApolloError } from '@apollo/client'
import { differenceInMinutes } from 'date-fns'
import { createContext, type PropsWithChildren, useCallback, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { usePatientTherapiesQuery } from '~/domains/patient/hooks/usePatientTherapiesQuery'
import { useDeleteTherapySession } from '~/domains/therapy-session/hooks/useDeleteTherapySession'
import { useGetTherapySessionById } from '~/domains/therapy-session/hooks/useGetTherapySessionById'
import { useRescheduleTherapySession } from '~/domains/therapy-session/hooks/useRescheduleTherapySession'
import { useDeleteRecurrency } from '~/hooks/useDeleteRecurrency'
import { usePatientAgenda } from '~/hooks/usePatientAgenda'
import { TherapistFetchProvider } from '~/hooks/useTherapistFetch'
import { useToasts } from '~/hooks/useToasts'
import { type RescheduleTherapySessionMutation } from '~/types/graphql'
import { isGraphQLResponse } from '~/utils/graphql'
import { type ManageSessionDeleteFormValues } from '../types'
import { type ManageSessionRoute } from '../utils/getManageSessionRoutes'

type DeleteSessionParams = ManageSessionDeleteFormValues & {}

type EditSessionParams = {
  message: string
  nextRoute: ManageSessionRoute
  startAt: Date
}

type RescheduledSession =
  | Extract<RescheduleTherapySessionMutation['rescheduleTherapySession'], { __typename: 'TherapySession' }>
  | undefined
  | null
type Context = {
  deleteRecurrencySession: (params: DeleteSessionParams) => Promise<void>
  deleteSession: (params: DeleteSessionParams) => Promise<void>
  editSession: (params: EditSessionParams) => Promise<void>
  loading: boolean
  minutes: number | null
  rescheduledSession: RescheduledSession
  session: ReturnType<typeof useGetTherapySessionById>['item']
  sessionId: string
  startAt: Date
  therapistId: string
  therapistName: string
  therapy: ReturnType<typeof usePatientTherapiesQuery>['therapies'][0]
  therapyId: string
  therapyPath: ReturnType<typeof usePatientTherapiesQuery>['therapies'][0]['therapyPath']
}

type Props = PropsWithChildren<{
  sessionId: string
  startAt: Date
  therapyId: string
}>

const ManageSessionContext = createContext<Context | null>(null)

export const ManageSessionProvider = ({ children, sessionId, startAt, therapyId }: Props) => {
  const [loading, setLoading] = useState(false)

  const [deleteTherapySession] = useDeleteTherapySession()
  const [deleteRecurrency] = useDeleteRecurrency()

  const [rescheduledSession, setRescheduledSession] = useState<RescheduledSession>(null)
  const [rescheduleTherapySession] = useRescheduleTherapySession()

  const { addToast } = useToasts()
  const { refetch: refetchPatientAgenda } = usePatientAgenda()
  const { therapies } = usePatientTherapiesQuery()
  const { item: session } = useGetTherapySessionById(sessionId)

  const history = useHistory()

  const therapy = therapies.find(({ id }) => id === therapyId)!
  const therapyPath = therapy.therapyPath

  const therapistId = therapy.therapist!.id
  const therapistName = therapy.therapist!.firstName

  const minutes = session?.endAt && session?.startAt ? differenceInMinutes(session.endAt, session.startAt) : null

  const deleteSession: Context['deleteSession'] = useCallback(
    async ({ churnReason, deletedReason, message }) => {
      setLoading(true)

      try {
        const response = await deleteTherapySession({
          variables: {
            input: {
              churnReason: deletedReason === 'CHURN' ? churnReason : null,
              deletedReason,
              id: sessionId,
              message,
            },
          },
        })
        const therapySession = response.data?.deleteTherapySession

        if (therapySession == null) {
          addToast({ translationId: 'therapySession.delete.cannotDeleteTherapySession', type: 'alert' })

          return
        }

        addToast({ translationId: 'therapySession.delete.therapySessionDeleted', type: 'success' })

        refetchPatientAgenda()
      } catch (error) {
        addToast({ translationId: 'therapySession.delete.cannotDeleteTherapySession', type: 'alert' })
      } finally {
        setLoading(false)
      }
    },
    [addToast, deleteTherapySession, refetchPatientAgenda, sessionId],
  )

  const deleteRecurrencySession: Context['deleteRecurrencySession'] = useCallback(
    async ({ message }) => {
      setLoading(true)

      try {
        const { data, errors } = await deleteRecurrency({ variables: { input: { id: sessionId, message } } })

        if (errors || data?.deleteRecurrency == null) {
          throw new Error('Failed to delete recurrency')
        }

        addToast({ translationId: 'therapySession.deleteRecurrency.success', type: 'success' })

        refetchPatientAgenda()
      } catch (error) {
        addToast({ translationId: 'therapySession.deleteRecurrency.error', type: 'alert' })
      } finally {
        setLoading(false)
      }
    },
    [addToast, deleteRecurrency, refetchPatientAgenda, sessionId],
  )

  const editSession: Context['editSession'] = useCallback(
    async ({ message, nextRoute, startAt }) => {
      if (startAt == null) {
        return
      }

      setLoading(true)

      try {
        if (therapy?.id == null) {
          return
        }

        const { data } = await rescheduleTherapySession({
          variables: {
            input: { id: sessionId, message, startAt },
          },
        })

        if (!isGraphQLResponse(data?.rescheduleTherapySession, 'TherapySession')) {
          throw new Error('Failed to reschedule therapy session')
        }

        refetchPatientAgenda()
        setRescheduledSession(data.rescheduleTherapySession)

        addToast({ translationId: 'therapySession.booking.therapySessionBooked', type: 'success' })

        history.push(nextRoute)
      } catch (error) {
        const showApolloError = error instanceof ApolloError && error.message === 'therapysession.first.alreadybooked'

        addToast({
          translationId: showApolloError
            ? 'therapySession.edit.startAt.cannotEditTherapySession.firstAlreadyExists'
            : 'therapySession.booking.cannotBookTherapySession',
          type: 'alert',
        })
      } finally {
        setLoading(false)
      }
    },
    [addToast, history, refetchPatientAgenda, rescheduleTherapySession, sessionId, therapy?.id],
  )

  if (!therapy?.therapist) {
    return null
  }

  const value = {
    deleteRecurrencySession,
    deleteSession,
    editSession,
    loading,
    minutes,
    rescheduledSession,
    session,
    sessionId,
    startAt,
    therapistId,
    therapistName,
    therapy,
    therapyId,
    therapyPath,
  }

  return (
    <TherapistFetchProvider id={therapy?.therapist.id}>
      <ManageSessionContext.Provider value={value}>{children}</ManageSessionContext.Provider>
    </TherapistFetchProvider>
  )
}

export const useManageSession = () => {
  const context = useContext(ManageSessionContext)

  if (!context) {
    throw new Error('The `useManageSession` should be wrapped with `ManageSessionProvider`.')
  }

  return context
}
