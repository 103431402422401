import { Flex, MountOn } from 'cdk'
import { BORDER_RADIUS_CIRCLE, SPACING_LG } from 'design-tokens'
import { Card, Text } from 'ui'
import { Image } from '~/components/Image'
import { Translation } from '~/components/Translation'
import { AddMealCTA } from './AddMealCTA'

export const MealsEmptyState = () => (
  <Card $align="center" $direction="row" $gap={SPACING_LG} $justify="space-between" $kind="placeholder">
    <Flex $align="center" $direction="row" $gap={SPACING_LG} $shrink={1}>
      <Flex
        $align="center"
        $backgroundColorName="neutral-20"
        $borderRadius={BORDER_RADIUS_CIRCLE}
        $justify="center"
        $minHeight={100}
        $minWidth={100}
      >
        <Image alt="add_meal_icon" height={50} src="/nutrition/apple.png" width={50} />
      </Flex>

      <Text colorName="neutral-80" kind="paragraph">
        <Translation id="nutrition.diary.emptyState.description" />
      </Text>
    </Flex>

    <MountOn mediaQuery="gt-md">
      <AddMealCTA />
    </MountOn>
  </Card>
)
