import { assertNever } from 'functions'
import { type TranslationId } from '~/i18n/types'
import { type Therapist, type TherapyTherapyPathType } from '~/types/graphql'

type Options = {
  isNutritionist: boolean
  isPsychiatrist: boolean
}

/**
 * @note Using param `options` based on Profession Types is safe for Nutritionist and Psychiatrist.
 * It avoids showing a wrong job title if by any chance `therapyPathType` is wrong
 * Will be definetely fixed in this effor
 * @link https://linear.app/serenis/issue/ENG-1346/formflow-all-flows-depend-on-implicit-relation-with-latest-form-flow
 */
export const getTherapistProfessionTranslationKey = (
  therapyPathType: TherapyTherapyPathType,
  sex: Therapist['sex'],
  options?: Options,
): Extract<TranslationId, `therapist.jobTitle.${string}`> => {
  if (options?.isPsychiatrist === true) {
    return 'therapist.jobTitle.psychiatry'
  }

  if (options?.isNutritionist === true) {
    return sex === 1 ? 'therapist.jobTitle.nutritionist.male' : 'therapist.jobTitle.nutritionist.female'
  }

  switch (therapyPathType) {
    case 'PATH_COACHING':
      return 'therapist.jobTitle.coach'
    case 'COUPLES_PSYCHOTHERAPY':
    case 'MYSELF_PSYCHOTHERAPY':
    case 'PATH_ASSERTIVE_COMMUNICATION':
    case 'PATH_SEXOLOGY':
    case 'PATH_SLEEP':
      return sex === 1 ? 'therapist.jobTitle.male' : 'therapist.jobTitle.female'
    case 'MYSELF_PSYCHIATRY':
      return 'therapist.jobTitle.psychiatry'
    case 'PATH_NUTRITION_DCA':
    case 'PATH_NUTRITION_WEIGHT_LOSS':
      return sex === 1 ? 'therapist.jobTitle.nutritionist.male' : 'therapist.jobTitle.nutritionist.female'
    default:
      return assertNever(therapyPathType)
  }
}
