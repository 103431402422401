import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import {
  Button,
  Divider,
  Flex,
  Form,
  MaxWidth320px,
  OverflowAuto,
  PageLayout,
  Pressable,
  SuggestionBox,
} from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { usePartnerUpsert } from '~/domains/partner/hooks/usePartnerUpsert'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import {
  ReactHookFormProvider,
  ReactHookFormSwitchField,
  ReactHookFormTextFieldNew,
  useReactHookForm,
} from '~/domains/react-hook-form'
import { FieldEmailWithConfirmationPartner } from '~/domains/react-hook-form/fields/FieldEmailWithConfirmationPartner'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useToasts } from '~/hooks/useToasts'
import { getRoute } from '~/utils/getRoute'

type FormValues = {
  firstName: string
  lastName: string
  email: string
  notificationsEnabled: boolean
}

export const OnboardingCouplesPageView = () => {
  const [partnerUpsert, { loading }] = usePartnerUpsert()
  const { therapies } = usePatientTherapies()
  const { addToast } = useToasts()
  const form = useReactHookForm<FormValues>()
  const history = useHistory()

  const therapyId = therapies.find(({ therapyPath }) => therapyPath.type === 'COUPLES_PSYCHOTHERAPY')?.id

  const {
    errors: { required },
    placeholders,
  } = useFormTranslations()

  const goToRoot = useCallback(() => {
    history.push(getRoute('/'))
  }, [history])

  const onSubmit = useCallback(
    async ({ email, firstName, lastName, notificationsEnabled }: FormValues) => {
      if (!therapyId) {
        addToast({ translationId: 'onboardingCouples.partnerCreate.failure', type: 'alert' })

        return
      }

      const response = await partnerUpsert({
        variables: {
          input: {
            email,
            firstName,
            lastName,
            notificationsEnabled,
            therapyId,
          },
        },
      })

      if (!response.data?.partnerUpsert.ok) {
        addToast({ translationId: 'onboardingCouples.partnerCreate.failure', type: 'alert' })

        return
      }

      addToast({ translationId: 'onboardingCouples.partnerCreate.success', type: 'success' })

      goToRoot()
    },
    [addToast, goToRoot, partnerUpsert, therapyId],
  )

  return (
    <Form grow={1} onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
      <PageLayout background="white" grow={1} maxWidth="640px" pb={0} pt={0} shrink={1}>
        <ReactHookFormProvider {...form}>
          <OverflowAuto pb={32}>
            <Flex pt={32}>
              <Text fontWeight="600" kind="h1">
                <Translation id="onboardingCouples.title" />
              </Text>
            </Flex>

            <Flex pt={16}>
              <Text colorName="black" kind="paragraph">
                <Translation id="onboardingCouples.description" />
              </Text>
            </Flex>

            <Flex pt={16}>
              <ReactHookFormTextFieldNew
                autoComplete="given-name"
                name="firstName"
                placeholder={placeholders.partnerFirstName}
                rules={{ required }}
                type="text"
              />
            </Flex>

            <Flex pt={16}>
              <ReactHookFormTextFieldNew
                autoComplete="family-name"
                name="lastName"
                placeholder={placeholders.partnerLastName}
                rules={{ required }}
                type="text"
              />
            </Flex>

            <Flex pt={16}>
              <FieldEmailWithConfirmationPartner />
            </Flex>

            <Flex pt={24}>
              <ReactHookFormSwitchField name="notificationsEnabled">
                <Flex pt={2}>
                  <Text as="label" colorName="black" htmlFor="notificationsEnabled" kind="paragraph">
                    <Translation id="forms.labels.partnerNotificationsEnabled" />
                  </Text>
                </Flex>
              </ReactHookFormSwitchField>
            </Flex>

            <Flex pt={24}>
              <SuggestionBox kind="info">
                <Text colorName="black" kind="caption">
                  <Translation id="onboardingCouples.insertLater" />
                </Text>
              </SuggestionBox>
            </Flex>
          </OverflowAuto>
        </ReactHookFormProvider>
      </PageLayout>

      <Divider borderColor="grey06" />

      <Flex background="white" px={48} py={24}>
        <MaxWidth320px>
          <Button kind="primary" loading={loading} type="submit">
            <Translation id="onboardingCouples.saveAndProceed" />
          </Button>

          <Flex align="center" pt={16}>
            <Pressable onClick={goToRoot}>
              <Text colorName="primary" fontWeight="600" kind="paragraph" textDecoration="underline">
                <Translation id="onboardingCouples.skip" />
              </Text>
            </Pressable>
          </Flex>
        </MaxWidth320px>
      </Flex>
    </Form>
  )
}
