import { type INoiseCancellation } from '@stream-io/audio-filters-web'
import {
  BackgroundFiltersProvider,
  type Call,
  ErrorFromResponse,
  type LogLevel,
  type MemberRequest,
  NoiseCancellationProvider,
  StreamCall,
  StreamVideo,
  StreamVideoClient,
  type TranslationsMap,
} from '@stream-io/video-react-sdk'
import { format, FormatDateEnum } from 'dates'
import { pipe } from 'fp-ts/function'
import { type ReactNode, useEffect, useRef, useState } from 'react'
import { useToasts } from '~/hooks/useToasts'
import { useLanguage } from '~/i18n/hooks/useLanguage'
import { getTranslation } from '~/i18n/hooks/useTranslation'
import { getEnv } from '~/utils/getEnv'
import { useVideocallEventLogger } from '../../hooks/useVideocallEventLogger'
import { useVideocallLogger } from '../../hooks/useVideocallLogger'
import { VideocallStyles } from './VideocallStyles'

const en = getTranslation('en')
const it = getTranslation('it')

const translationsOverrides: TranslationsMap = {
  en: {
    'Call Latency': en('videocall.stats.latency'),
    'Call performance': en('videocall.stats.other'),
    Good: en('videocall.stats.good'),
    Ok: en('videocall.stats.ok'),
    Bad: en('videocall.stats.bad'),
    'Review the key data points below to assess call performance': en('videocall.stats.other.description'),
    'Very high latency values may reduce call qualeny, cause lag, and make the call less enjoyable.': en(
      'videocall.stats.latency.description',
    ),
    'You are muted. Unmute to speak.': en('videocall.youAreMuted'),
  },
  it: {
    'Call Latency': it('videocall.stats.latency'),
    'Call performance': it('videocall.stats.other'),
    Good: it('videocall.stats.good'),
    Ok: it('videocall.stats.ok'),
    Bad: it('videocall.stats.bad'),
    'Review the key data points below to assess call performance': it('videocall.stats.other.description'),
    'Very high latency values may reduce call quality, cause lag, and make the call less enjoyable.': it(
      'videocall.stats.latency.description',
    ),
    'You are muted. Unmute to speak.': it('videocall.youAreMuted'),
  },
}

export type BackgroundImageFilter = 'BRAND_1' | 'BRAND_2' | 'LOGO_1' | 'LOGO_2'

export const backgrounds: Record<BackgroundImageFilter, `/background/${string}.jpg`> = {
  BRAND_1: '/background/brand-1.jpg',
  BRAND_2: '/background/brand-2.jpg',
  LOGO_1: '/background/logo-1.jpg',
  LOGO_2: '/background/logo-2.jpg',
}

const backgroundItems = Object.values(backgrounds)

type VideocallProviderProps = {
  children: ReactNode
  callId: string
  currentUser: {
    id: string
    firstName: string
    profileImage?: {
      s: string
      m: string
      l: string
    } | null
  }
  endAt?: Date
  logLevel?: LogLevel
  members?: MemberRequest[]
  startAt?: Date
  streamToken: string
  therapySessionId?: string
}

export const VideocallProvider = ({
  callId,
  children,
  currentUser,
  endAt,
  logLevel = 'info',
  members,
  startAt,
  streamToken,
  therapySessionId,
}: VideocallProviderProps) => {
  const { language } = useLanguage()
  const { addToast } = useToasts()
  const logFailure = useVideocallEventLogger()
  const logger = useVideocallLogger({ endAt, therapySessionId })

  const [loading, setLoading] = useState(true)
  const [noiseCancellation, setNoiseCancellation] = useState<INoiseCancellation>()

  const loader = useRef<Promise<void>>()

  const [client, setClient] = useState<StreamVideoClient | null>(null)
  const [call, setCall] = useState<Call | null>(null)

  useEffect(() => {
    if (client != null) {
      return
    }

    try {
      setClient(
        new StreamVideoClient({
          apiKey: getEnv('STREAM_CHAT_KEY'),
          user: {
            image: currentUser.profileImage?.s,
            id: currentUser.id,
            name: currentUser.firstName,
          },
          token: streamToken,
          options: {
            logger,
            logLevel,
          },
        }),
      )
    } catch (error) {
      logFailure('videocall.client.create', error)
    }
  }, [client, currentUser, streamToken, logger, logLevel, logFailure])

  useEffect(
    () => () => {
      if (client != null) {
        client.disconnectUser()
      }
    },
    [client],
  )

  useEffect(() => {
    ;(async () => {
      if (client == null || call != null) {
        return
      }

      const callInstance = client.call('default', callId)

      try {
        await callInstance.getOrCreate({
          data: {
            members,
            ...(startAt ? { starts_at: pipe(startAt, format(FormatDateEnum.ATOM)) } : {}),
          },
        })
        setCall(callInstance)
      } catch (error) {
        const isRecoverable = !(error instanceof ErrorFromResponse && error.unrecoverable)

        logFailure(isRecoverable ? 'videocall.call.get-or-create' : 'videocall.call.get-or-create.unrecoverable', error)

        addToast({
          translationId: 'generic.error',
          type: 'alert',
        })
      }
    })()
  }, [call, client, callId, members, startAt, logFailure, addToast])

  useEffect(() => {
    const load = (loader.current || Promise.resolve())
      .then(() => import('@stream-io/audio-filters-web'))
      .then(({ NoiseCancellation }) => {
        setNoiseCancellation(new NoiseCancellation())
      })
      .finally(() => {
        setLoading(false)
      })

    return () => {
      loader.current = load.then(() => setNoiseCancellation(undefined))
    }
  }, [])

  if (!client || !call || loading) {
    return null
  }

  return (
    <>
      <StreamVideo client={client} language={language} translationsOverrides={translationsOverrides}>
        <StreamCall call={call}>
          <BackgroundFiltersProvider
            backgroundImages={backgroundItems}
            onError={() => {
              addToast({
                translationId: 'videocall.backgroundFilterError',
                type: 'warning',
              })
            }}
          >
            {noiseCancellation ? (
              <NoiseCancellationProvider noiseCancellation={noiseCancellation}>{children}</NoiseCancellationProvider>
            ) : (
              children
            )}
          </BackgroundFiltersProvider>
        </StreamCall>
      </StreamVideo>
      <VideocallStyles />
    </>
  )
}
