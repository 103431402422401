import { Flex } from 'cdk'
import { isValid } from 'date-fns'
import { toDateMonthName, toHours, toMinutes, toWeekDay } from 'dates'
import { SPACING_3XS } from 'design-tokens'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { GLOBAL_VALUE_SEPARATOR } from '~/constants'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { type ReservationFormValues } from '~/domains/reservation/types'
import { type UserTherapist } from '~/domains/therapist/types'

type Props = {
  isFree: boolean
  suggestedTherapistsProfiles: UserTherapist[]
}

export const ReservationSelectedSlot = ({ isFree, suggestedTherapistsProfiles }: Props) => {
  const { getValues } = useReactHookFormContext<ReservationFormValues>()

  const [slot, therapistId] = getValues('selectedTimeSlotWithTherapist')?.split(GLOBAL_VALUE_SEPARATOR) ?? []

  const date = new Date(slot)
  const therapistProfile = suggestedTherapistsProfiles.find(({ id }) => id === therapistId)

  const extendedDate = isValid(date) ? `${toWeekDay(date)} ${toDateMonthName(date)}` : ''
  const extendedTime = isValid(date) ? `${toHours(date)}:${toMinutes(date)}` : ''

  return (
    <Flex
      $align="flex-start"
      $grow={1}
      $mdAlign="flex-end"
      $mdDirection="row"
      $mdGap={SPACING_3XS}
      $opacity={!isValid(date) || therapistProfile == null ? 0 : 1}
      $shrink={1}
      $wrap="wrap"
    >
      <TranslationMarkdown
        id={
          isFree
            ? 'reservation.bookBySuggestedTherapist.selectedSlot.free'
            : 'reservation.bookBySuggestedTherapist.selectedSlot'
        }
        values={{
          date: extendedDate,
          time: extendedTime,
        }}
      />
      <TranslationMarkdown
        id="reservation.bookBySuggestedTherapist.selectedTherapist"
        values={{ fullName: therapistProfile?.fullName || '' }}
      />
    </Flex>
  )
}
