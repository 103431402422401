import { CallingState, useCallStateHooks } from '@stream-io/video-react-sdk'
import { assertNever } from 'functions'
import { useTherapySessionLive } from '../../hooks/useTherapySessionLive'
import { WaitingRoomProvider } from '../../providers/WaitingRoomProvider'
import { Ended } from './Ended'
import { Error } from './Error'
import { Live } from './Live'
import { WaitingRoom } from './WaitingRoom'

export const VideoCallView = () => {
  const { endAt, startAt, therapyPathType } = useTherapySessionLive()
  const { useCallCallingState } = useCallStateHooks()
  const callingState = useCallCallingState()

  switch (callingState) {
    case CallingState.IDLE:
    case CallingState.UNKNOWN:
      return (
        <WaitingRoomProvider endAt={endAt} startAt={startAt}>
          <WaitingRoom therapyPathType={therapyPathType} />
        </WaitingRoomProvider>
      )

    case CallingState.JOINED:
    case CallingState.JOINING:
    case CallingState.MIGRATING:
    case CallingState.OFFLINE:
    case CallingState.RECONNECTING:
    case CallingState.RINGING:
      return <Live />

    case CallingState.LEFT:
      return <Ended />

    case CallingState.RECONNECTING_FAILED:
      return <Error />

    default:
      assertNever(callingState)
  }
}
