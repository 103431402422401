import { Flex } from 'cdk'
import { SPACING_SM } from 'design-tokens'
import { DrawerFooter, DrawerHeader } from 'ui'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { AppointmentCard, AppointmentSkeletonCard } from '~/domains/paths'
import { DownloadCalendarEventButton } from '~/domains/reservation/components/DownloadCalendarEventButton'
import { useBookSession } from '../hooks/useBookSession'

export const BookSessionSummary = () => {
  const { bookedSession, therapyPath } = useBookSession()
  const bookedSessionCard =
    bookedSession?.bookTherapySessionByParent == null ? (
      <AppointmentSkeletonCard />
    ) : (
      <AppointmentCard
        endAt={bookedSession.bookTherapySessionByParent.endAt}
        price={bookedSession.bookTherapySessionByParent.cost ?? therapyPath?.cost}
        startAt={bookedSession.bookTherapySessionByParent.startAt}
        therapyPath={therapyPath.type}
      />
    )

  return (
    <>
      <DrawerHeader />
      <Flex $gap={SPACING_SM}>
        <TranslationMarkdown colorName="darker" id="therapySession.bookSession.booked" kind="paragraph-strong" />
        {bookedSessionCard}
      </Flex>
      <DrawerFooter>
        {bookedSession?.bookTherapySessionByParent != null && (
          <Flex $grow={1}>
            <DownloadCalendarEventButton therapySessionId={bookedSession.bookTherapySessionByParent.id} />
          </Flex>
        )}
      </DrawerFooter>
    </>
  )
}
