import { Flex, PositionRelative } from 'cdk'
import { SPACING_SM } from 'design-tokens'
import { IconPenTool } from 'icons'
import { memo, useCallback, useEffect } from 'react'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useLanguage } from '~/i18n/hooks/useLanguage'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'
import { useTabNavigationMenu } from '../../hooks/useTabNavigationMenu'
import { MenuAction } from '../common/MenuAction'
import { UnreadDot } from '../common/UnreadDot'

export const MenuItemFeedback = memo(() => {
  const { id } = useCurrentUser()
  const { language } = useLanguage()

  const { closeMenu } = useTabNavigationMenu()

  useEffect(() => {
    if (!(typeof window.Featurebase === 'function')) {
      return
    }

    window.Featurebase(
      'initialize_feedback_widget',
      {
        locale: language,
        metadata: {
          id,
        },
        organization: 'serenis',
        theme: 'light',
      },
      (_, callback) => {
        if (callback?.action === 'feedbackSubmitted') {
          closeMenu()
        }
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = useCallback(() => {
    setLocalStorage('unread-dot-submit-feedback-seen', 'true')

    window.postMessage({
      target: 'FeaturebaseWidget',
      data: {
        action: 'openFeedbackWidget',
      },
    })
  }, [])

  const unreadDotSubmitFeedbackSeen = getLocalStorage('unread-dot-submit-feedback-seen') === 'true'

  return (
    <MenuAction onClick={handleClick}>
      <Flex $pr={SPACING_SM}>
        <PositionRelative>
          <IconPenTool colorName="neutral-60" size={24} />
          {!unreadDotSubmitFeedbackSeen && <UnreadDot $left={0} />}
        </PositionRelative>
      </Flex>

      <Flex $align="flex-start" $grow={1} $shrink={1}>
        <Text kind="paragraph">
          <Translation id="menu.help.feedback" />
        </Text>
      </Flex>
    </MenuAction>
  )
})

MenuItemFeedback.displayName = 'MenuItemFeedback'
