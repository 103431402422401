import { Flex, OverflowAuto } from 'cdk'
import { useCallback, useEffect } from 'react'
import { Button, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useIntercom } from '~/domains/intercom/hooks/useIntercom'
import { useRootHistory } from '~/hooks/useRootHistory'
import { getRoute } from '~/utils/getRoute'
import { useProductUserFeedbackComplete } from '../productUserFeedback/hooks/useProductUserFeedbackComplete'

export const RouteWithEmpathy = ({ feedbackId }: { feedbackId: string }) => {
  const rootHistory = useRootHistory()
  const { open } = useIntercom()

  const { productUserFeedbackComplete } = useProductUserFeedbackComplete(feedbackId)

  const handleOnClick = useCallback(() => {
    rootHistory.push(getRoute('/'))
  }, [rootHistory])

  const openIntercom = useCallback(() => {
    open()
  }, [open])

  useEffect(() => {
    productUserFeedbackComplete()
  }, [productUserFeedbackComplete])

  return (
    <OverflowAuto $gap={16}>
      <Text colorName="black" fontWeight="600" kind="h3">
        <Translation id="firstSessionFeedback.empathy.title" />
      </Text>
      <Text colorName="black" kind="paragraph">
        <Translation id="firstSessionFeedback.empathy.body" />
      </Text>
      <Flex $gap={8} $pt={24}>
        <Button kind="primary" onClick={openIntercom}>
          <Translation id="actions.contactUs" />
        </Button>
        <Button kind="tertiary" onClick={handleOnClick}>
          <Translation id="actions.backToPersonalArea" />
        </Button>
      </Flex>
    </OverflowAuto>
  )
}
