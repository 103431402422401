import { useCallStateHooks } from '@stream-io/video-react-sdk'
import { OverflowAuto, Pressable } from 'cdk'
import { isNeitherNullNorUndefined } from 'functions'
import { Icon, WarningCircle } from 'icons'
import { useMemo } from 'react'
import { Alert, Modal, ModalProvider, ModalTrigger } from 'ui'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useIsNativeApp } from '~/domains/appNative/hooks/useIsNativeApp'
import { type TranslationId } from '~/i18n/types'

export const PermissionsAlert = () => {
  const isNativeApp = useIsNativeApp()
  const { useCameraState, useMicrophoneState } = useCallStateHooks()
  const { hasBrowserPermission: microphoneHasBrowserPermission, status: microphoneStatus } = useMicrophoneState()
  const { hasBrowserPermission: cameraHasBrowserPermission, status: cameraStatus } = useCameraState()

  const permissionsAlertTranslation = useMemo<TranslationId | null>(() => {
    const device = isNativeApp ? 'app' : 'browser'

    if (
      (!cameraHasBrowserPermission || !isNeitherNullNorUndefined(cameraStatus)) &&
      (!microphoneHasBrowserPermission || !isNeitherNullNorUndefined(microphoneStatus))
    ) {
      return `videocall.permissions.${device}.cameraAndMicrophone`
    }

    if (!cameraHasBrowserPermission || !isNeitherNullNorUndefined(cameraStatus)) {
      return `videocall.permissions.${device}.camera`
    }

    if (!microphoneHasBrowserPermission || !isNeitherNullNorUndefined(microphoneStatus)) {
      return `videocall.permissions.${device}.microphone`
    }

    return null
  }, [cameraHasBrowserPermission, cameraStatus, isNativeApp, microphoneHasBrowserPermission, microphoneStatus])

  if (!permissionsAlertTranslation) {
    return null
  }

  return (
    <ModalProvider>
      <ModalTrigger>
        <Pressable>
          <Alert endChildren={<></>} kind="warning" startChildren={<Icon Svg={WarningCircle} size={24} />}>
            <TranslationMarkdown id={permissionsAlertTranslation} />
          </Alert>
        </Pressable>
      </ModalTrigger>
      <Modal>
        <OverflowAuto>
          <TranslationMarkdown
            id={isNativeApp ? 'videocall.permissions.app.help' : 'videocall.permissions.browser.help'}
            pt={16}
          />
        </OverflowAuto>
      </Modal>
    </ModalProvider>
  )
}
