import { format, FormatDateEnum } from 'dates'
import { type ColorName, cssvarColor } from 'design-tokens'
import { pipe } from 'fp-ts/function'
import { Download, EditPencil, Icon, NavArrowDown, NavArrowUp } from 'icons'
import { type Dispatch, type SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { useFormState } from 'react-hook-form'
import styled from 'styled-components'
import { Chip, Text } from 'ui'
import { Flex, Loader, Pressable } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { useDownloadFile } from '~/hooks/useDownloadFile'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useTranslation } from '~/i18n/hooks/useTranslation'
import { type TherapySession } from '~/types/graphql'
import { TherapySessionNotesTextArea } from './TherapySessionNotesTextArea'

type DividerProps = {
  borderColor: ColorName
}

const Divider = styled.div<DividerProps>`
  margin: 0 -16px;
  border-bottom: 1px solid ${({ borderColor }) => cssvarColor(borderColor)};
`

const max = 5000

type Props = Pick<TherapySession, 'notes' | 'startAt'> & {
  downloadUrl: string
  initialOpenState?: boolean
  isEditing: boolean
  setIsEditing: Dispatch<SetStateAction<boolean>>
}

type UpdateNotesForm = {
  notes: string
}

export const TherapySessionNotesAccordion = ({
  downloadUrl,
  initialOpenState,
  isEditing,
  notes,
  setIsEditing,
  startAt,
}: Props) => {
  const formState = useFormState<UpdateNotesForm>()

  const form = useReactHookFormContext<UpdateNotesForm>()

  const [open, setOpen] = useState(initialOpenState)

  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const { download, isDownloading } = useDownloadFile()

  const savedNotes = notes || ''

  const {
    errors: { required },
  } = useFormTranslations()

  const draft = form.watch('notes')

  const helperText = useTranslation('therapySession.therapist.notes.count', {
    current: draft.length || '0',
    max,
  })

  const placeholder = useTranslation('therapySession.therapist.notes.placeholder')

  const onClose = useCallback(() => {
    setOpen(false)
    setIsEditing(false)
  }, [setIsEditing])

  const onOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const onEdit = useCallback(() => {
    setIsEditing(true)
  }, [setIsEditing])

  const onEditCancel = useCallback(() => {
    setIsEditing(false)
    setOpen(false)
    form.reset({ notes: savedNotes })
  }, [form, savedNotes, setIsEditing])

  const downloadNotes = useCallback(() => {
    if (!downloadUrl) {
      return
    }

    download(downloadUrl)
  }, [download, downloadUrl])

  useEffect(() => {
    if (isEditing) {
      textAreaRef.current?.focus()
    }
  }, [isEditing])

  const isSubmitting = form.formState.isSubmitting

  const hasDraft = draft.length > 0

  const showDraftLabel = hasDraft && draft !== savedNotes && !isSubmitting

  const submitDisabled = isSubmitting || (hasDraft && draft === savedNotes)

  const borderColor = formState.errors.notes ? 'red-60' : isEditing ? 'primary' : 'primary-40'
  const oldBorderColor = formState.errors.notes ? 'coral08' : isEditing ? 'purple08' : 'purple06'

  const formattedDay = `${pipe(startAt, format(FormatDateEnum.WEEK_DAY))} ${pipe(
    startAt,
    format(FormatDateEnum.DAY_OF_MONTH),
  )}`

  return (
    <>
      <Flex borderColor={oldBorderColor} borderRadius={8} borderSize={1} justify="center" mdP={16} p={12}>
        <Flex direction="row">
          <Flex align="center" direction="row" grow={1} shrink={1}>
            <Flex lgBasis="100px">
              <Text fontWeight="600" kind="caption">
                {formattedDay}
              </Text>
            </Flex>

            {showDraftLabel && (
              <Flex pl={8}>
                <Text colorName="grey-80" fontStyle="italic" fontWeight="600" kind="footnote">
                  <Translation id="therapySession.therapist.notes.draft" />
                </Text>
              </Flex>
            )}

            {!notes && !open && (
              <Flex pl={8} shrink={1}>
                <Chip kind="warning">
                  <Translation id="patientDetail.missingNotes" />
                </Chip>
              </Flex>
            )}
          </Flex>

          {open && !isEditing && (
            <Flex pr={16}>
              <Pressable align="center" autoWidth direction="row" onClick={onEdit}>
                <Icon Svg={EditPencil} colorName="primary" size={24} />
              </Pressable>
            </Flex>
          )}

          {open && !isDownloading && !isEditing && (
            <Flex pr={16}>
              <Pressable align="center" autoWidth direction="row" onClick={downloadNotes}>
                <Icon Svg={Download} colorName="primary" size={24} />
              </Pressable>
            </Flex>
          )}
          {isDownloading && (
            <Flex pr={16}>
              <Loader colorName="purple08" size={24} />
            </Flex>
          )}

          {!open && (
            <Pressable align="center" autoWidth direction="row" onClick={onOpen}>
              <Icon Svg={NavArrowDown} colorName="primary" size={24} />
            </Pressable>
          )}
          {open && (
            <Pressable align="center" autoWidth direction="row" onClick={onClose}>
              <Icon Svg={NavArrowUp} colorName="primary" size={24} />
            </Pressable>
          )}
        </Flex>
        {open && (
          <Flex mdPt={16} pt={12}>
            <Divider borderColor={borderColor} />
          </Flex>
        )}

        {open && (
          <Flex mdPt={16} pt={12}>
            <TherapySessionNotesTextArea
              ref={textAreaRef}
              disabled={!isEditing}
              helperText={isEditing ? helperText : undefined}
              max={max}
              minHeight="132px"
              name="notes"
              placeholder={placeholder}
              rules={{ required }}
            />
          </Flex>
        )}
      </Flex>
      {formState.errors.notes && (
        <Flex pt={8}>
          <Text colorName="red-60" kind="caption">
            {formState.errors.notes.message}
          </Text>
        </Flex>
      )}
      {open && isEditing && (
        <Flex align="center" direction="row" justify="flex-end" pb={16} pt={8}>
          {isSubmitting && <Loader colorName="purple08" size={16} />}

          <Flex pl={16}>
            <Pressable disabled={isSubmitting} onClick={onEditCancel}>
              <Text fontWeight="400" kind="paragraph">
                <Translation id="actions.cancel" />
              </Text>
            </Pressable>
          </Flex>

          <Flex pl={16}>
            <Pressable colorName="purple08" disabled={submitDisabled} type="submit">
              <Text fontWeight="400" kind="paragraph">
                <Translation id="actions.save" />
              </Text>
            </Pressable>
          </Flex>
        </Flex>
      )}
    </>
  )
}
