import { gql, useLazyQuery } from '@apollo/client'
import {
  type TherapySessionLivePermissionQuery,
  type TherapySessionLivePermissionQueryVariables,
} from '~/types/graphql'

const GET_THERAPY_SESSION_LIVE_PERMISSION = gql`
  query therapySessionLivePermission($id: String!, $email: String!) {
    therapySessionLivePermission(id: $id, email: $email) {
      ok
      message
    }
  }
`

export const useGetTherapySessionLivePermission = () => {
  const [getTherapySessionLivePermission, { data, error, loading }] = useLazyQuery<
    TherapySessionLivePermissionQuery,
    TherapySessionLivePermissionQueryVariables
  >(GET_THERAPY_SESSION_LIVE_PERMISSION, {
    fetchPolicy: 'cache-first',
  })

  return {
    getTherapySessionLivePermission,
    error: !!error,
    data,
    loading,
  }
}
