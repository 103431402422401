import { Flex } from 'cdk'
import { SPACING_LG, SPACING_MD } from 'design-tokens'
import { ArrowLeft, Icon } from 'icons'
import { useState } from 'react'
import { Button } from 'ui'
import { PatientDetailMaxWidth } from '../patientsManagement/components/PatientDetailMaxWidth'
import { FoodJournalProvider } from './components/FoodJournalContext'
import { MonthlyCalendar } from './components/MonthlyCalendar'
import { TherapistFoodJournalDay } from './TherapistFoodJournalDay'

type Props = {
  patientId?: string
  withMonthlyCalendar?: boolean
}

export const TherapistFoodJournal = ({ patientId, withMonthlyCalendar = false }: Props) => {
  const [isCalendarView, setIsCalendarView] = useState(withMonthlyCalendar)

  const backToCalendar = () => {
    setIsCalendarView(true)
  }

  const handleDayClick = () => {
    setIsCalendarView(false)
  }

  return (
    <FoodJournalProvider patientId={patientId}>
      <PatientDetailMaxWidth align="center" mdPt={24} pt={16}>
        <Flex $pb={SPACING_LG}>
          {isCalendarView && <MonthlyCalendar onDayClick={handleDayClick} />}

          {!isCalendarView && (
            <>
              {withMonthlyCalendar && (
                <Flex $direction="row" $pb={SPACING_MD}>
                  <Button data-test-id="food-journal-calendar-prev" isRound kind="secondary" onClick={backToCalendar}>
                    <Icon Svg={ArrowLeft} size={24} />
                  </Button>
                </Flex>
              )}

              <TherapistFoodJournalDay />
            </>
          )}
        </Flex>
      </PatientDetailMaxWidth>
    </FoodJournalProvider>
  )
}
