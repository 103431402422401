import { Flex, OverflowAuto } from 'cdk'
import { useCallback } from 'react'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { ReactHookFormRadioField } from '../react-hook-form'
import { FirstSessionFeedbackForm } from './FirstSessionFeedbackForm'
import { FormBackButton } from './FormBackButton'
import { FormNextButton } from './FormNextButton'
import { type FirstSessionFeedback, type FirstSessionFeedbackOnSubmit } from './types'

export const Route1to3Score = ({ onSubmit }: { onSubmit: FirstSessionFeedbackOnSubmit }) => {
  const handleSubmitWithRoute = useCallback(
    (values: FirstSessionFeedback) => {
      const value = values.empathy
      if (value === undefined) {
        return
      }

      onSubmit(value.toString(), 'empathy', value === 'YES' ? '/with-empathy' : '/without-empathy')
    },
    [onSubmit],
  )

  return (
    <FirstSessionFeedbackForm onSubmit={handleSubmitWithRoute}>
      <OverflowAuto $gap={16}>
        <Text colorName="black" fontWeight="600" kind="h3">
          <Translation id="firstSessionFeedback.1to3.title" />
        </Text>
        <Text colorName="black" kind="paragraph">
          <Translation id="firstSessionFeedback.1to3.body" />
        </Text>
        <ReactHookFormRadioField name="empathy" value="YES">
          <Flex $grow={1} $justify="center">
            <Text colorName="primary" fontWeight="600" itemType="label" kind="paragraph">
              <Translation id="common.yes" />
            </Text>
          </Flex>
        </ReactHookFormRadioField>
        <ReactHookFormRadioField name="empathy" value="NO">
          <Flex $grow={1} $justify="center">
            <Text colorName="primary" fontWeight="600" itemType="label" kind="paragraph">
              <Translation id="common.no" />
            </Text>
          </Flex>
        </ReactHookFormRadioField>
      </OverflowAuto>
      <Flex $gap={8} $pt={24}>
        <FormNextButton />
        <FormBackButton />
      </Flex>
    </FirstSessionFeedbackForm>
  )
}
