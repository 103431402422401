import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { useChatTherapist } from '~/domains/chat/hooks/useChatTherapist'

export const ChannelHeaderPatient = () => {
  const { user } = useChatTherapist()

  return (
    <Flex align="center">
      <Text fontWeight="600" kind="paragraph" textAlign="center">
        {user?.fullName}
      </Text>
    </Flex>
  )
}
