import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { Text } from 'ui'
import { Button, Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useModals } from '~/domains/modals'
import { type TranslationId } from '~/i18n/types'
import { type ClinicalBondAverageRating } from '../utils/getClinicalBondAverageRating'

type Param = {
  score: ClinicalBondAverageRating
}

type Props = {
  onCompleted: () => void
}

export const ClinicalBondStepEnd = ({ onCompleted }: Props) => {
  const { score } = useParams<Param>()

  const { close } = useModals()

  const onClick = useCallback(() => {
    close('clinicalBond')

    onCompleted()
  }, [close, onCompleted])

  const titleTranslationId: TranslationId = `clinicalBond.end.${score}.title`
  const bodyTranslationId: TranslationId = `clinicalBond.end.${score}.body`

  return (
    <>
      <Text fontWeight="600" kind="h3">
        <Translation id={titleTranslationId} />
      </Text>
      <TranslationMarkdown colorName="black" id={bodyTranslationId} kind="paragraph" pt={16} />
      <Flex pt={24}>
        <Button kind="primary" onClick={onClick}>
          <Translation id="actions.close" />
        </Button>
      </Flex>
    </>
  )
}
