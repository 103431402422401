import { type ReactNode } from 'react'
import { MemoryRouter } from 'react-router-dom'
import { Flex } from 'ui-deprecated'

export const routes = {
  attachments: '/attachments',
  messages: '/',
}

const initialEntries = [routes.messages, routes.attachments]

type Props = {
  children: ReactNode
}

export const ChatMessageListMemoryRouter = ({ children }: Props) => (
  <Flex grow={1} shrink={1}>
    <MemoryRouter initialEntries={initialEntries}>{children}</MemoryRouter>
  </Flex>
)
