import { Icon, NavArrowLeft, NavArrowRight } from 'icons'
import { useCallback } from 'react'
import { type ToolbarProps } from 'react-big-calendar'
import { Text } from 'ui'
import { Flex, Pressable } from 'ui-deprecated'
import { Translation } from '~/components/Translation'

type Props = Pick<ToolbarProps, 'label' | 'onNavigate'>

export const TherapistCalendarToolbarNavigation = ({ label, onNavigate }: Props) => {
  const onNext = useCallback(() => onNavigate('NEXT'), [onNavigate])

  const onPrevious = useCallback(() => onNavigate('PREV'), [onNavigate])

  const onToday = useCallback(() => onNavigate('TODAY'), [onNavigate])

  return (
    <Flex align="center" justify="space-between" lgJustify="flex-start" lgPb={0} mdDirection="row" pb={24}>
      <Flex direction="row" justify="flex-start">
        <Flex direction="row" pr={24}>
          <Flex background="grey02" backgroundHover="purple04" borderRadius={8}>
            <Pressable onClick={onToday}>
              <Flex align="center" grow={1} justify="center" px={32} py={4} shrink={1}>
                <Text fontWeight="400" kind="paragraph" textAlign="center">
                  <Translation id="generic.today" />
                </Text>
              </Flex>
            </Pressable>
          </Flex>
        </Flex>

        <Flex
          background="grey02"
          backgroundHover="purple04"
          basis="32px"
          borderRadiusBottomLeft={8}
          borderRadiusTopLeft={8}
        >
          <Pressable onClick={onPrevious}>
            <Flex align="center" p={4}>
              <Icon Svg={NavArrowLeft} colorName="black" size={24} />
            </Flex>
          </Pressable>
        </Flex>

        <Flex background="white" basis="2px" />

        <Flex
          background="grey02"
          backgroundHover="purple04"
          basis="32px"
          borderRadiusBottomRight={8}
          borderRadiusTopRight={8}
        >
          <Pressable onClick={onNext}>
            <Flex align="center" p={4}>
              <Icon Svg={NavArrowRight} colorName="black" size={24} />
            </Flex>
          </Pressable>
        </Flex>
      </Flex>

      <Flex mdPt={0} pl={24} pt={16}>
        <Text fontWeight="600" kind="paragraph">
          {label}
        </Text>
      </Flex>
    </Flex>
  )
}
