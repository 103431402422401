import { useHistory } from 'react-router-dom'
import { Button } from 'ui'
import { Translation } from '~/components/Translation'

export const FormBackButton = () => {
  const history = useHistory()

  return (
    <Button kind="tertiary" onClick={history.goBack}>
      <Translation id="actions.back" />
    </Button>
  )
}
