import { gql, useQuery } from '@apollo/client'
import { type PartnerFindByPatientIdQuery, type PartnerFindByPatientIdQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query partnerFindByPatientId($id: String!) {
    partnerFindByPatientId(id: $id) {
      firstName
      id
      lastName
    }
  }
`

export const usePartnerFindByPatientId = (id: string) => {
  const { data, error, loading } = useQuery<PartnerFindByPatientIdQuery, PartnerFindByPatientIdQueryVariables>(QUERY, {
    variables: {
      id,
    },
  })

  return {
    error: !!error,
    partner: data?.partnerFindByPatientId || null,
    loading,
  }
}
