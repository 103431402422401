import { type TherapyTherapyPathType } from '~/types/graphql'
import { getEnv } from '~/utils/getEnv'
import { isNutrition } from './therapyPaths'

export const getTherapySessionAvailabilityDurationInSeconds = (therapyPathType: TherapyTherapyPathType) => {
  if (isNutrition(therapyPathType)) {
    return Number(getEnv('SERENIS_THERAPY_SESSION_NUTRITION_AVAILABILITY_DURATION'))
  }

  return Number(getEnv('SERENIS_THERAPY_SESSION_DEFAULT_AVAILABILITY_DURATION'))
}
