import { useCallback, useState } from 'react'
import { Form } from 'ui-deprecated'
import { useClinicalDiary } from '~/domains/clinicalDiary/hooks/useClinicalDiary'
import { ReactHookFormProvider, useReactHookForm } from '~/domains/react-hook-form'
import { useToasts } from '~/hooks/useToasts'
import { useUpdateTherapySessionNotes } from '~/hooks/useUpdateTherapySessionNotes'
import { type TherapySession } from '~/types/graphql'
import { TherapySessionNotesAccordion } from './TherapySessionNotesAccordion'

type UpdateNotesForm = {
  notes: string
}

type Props = Pick<TherapySession, 'id' | 'notes' | 'startAt' | 'patientId'> & {
  initialOpenState?: boolean
  downloadUrl: string
}
export const TherapySessionNotes = ({ id, notes, startAt, initialOpenState, patientId, downloadUrl }: Props) => {
  const [updateTherapySessionNotes] = useUpdateTherapySessionNotes()

  const { updateQuery: updateClinicalDiary } = useClinicalDiary(patientId)

  const { addToast } = useToasts()

  const savedNotes = notes || ''

  const form = useReactHookForm<UpdateNotesForm>({ defaultValues: { notes: savedNotes } })

  const [isEditing, setIsEditing] = useState(!notes && !!initialOpenState)

  const onSubmit = useCallback(
    async ({ notes: value }: UpdateNotesForm) => {
      try {
        await updateTherapySessionNotes({ variables: { input: { id, notes: value } } })

        updateClinicalDiary((state) => ({
          getClinicalDiaryByPatientId: {
            ...state.getClinicalDiaryByPatientId!,
            items:
              state.getClinicalDiaryByPatientId?.items?.map((item) => {
                if (item.id === id) {
                  return { id, notes: value, startAt, downloadUrl: item.downloadUrl }
                }
                return item
              }) ?? [],
          },
        }))

        setIsEditing(false)
      } catch (_error) {
        addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })
      }
    },
    [addToast, id, startAt, updateClinicalDiary, updateTherapySessionNotes],
  )

  return (
    <ReactHookFormProvider {...form}>
      <Form id="clinical-diary" onSubmit={form.handleSubmit(onSubmit)}>
        <TherapySessionNotesAccordion
          downloadUrl={downloadUrl}
          initialOpenState={initialOpenState}
          isEditing={isEditing}
          notes={notes}
          setIsEditing={setIsEditing}
          startAt={startAt}
        />
      </Form>
    </ReactHookFormProvider>
  )
}
