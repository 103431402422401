import { gql, useQuery } from '@apollo/client'
import { type GetCountriesQuery, type GetCountriesQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query getCountries {
    getCountries {
      id
      code
      name
    }
  }
`

export const useGetCountries = () => {
  const { data, loading } = useQuery<GetCountriesQuery, GetCountriesQueryVariables>(QUERY, {
    fetchPolicy: 'cache-first',
  })

  return {
    countries: data?.getCountries ?? [],
    getCountriesLoading: loading,
  }
}
