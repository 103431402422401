import { usePathname } from 'next/navigation'
import { useEffect } from 'react'
import { useSeoMeta } from '~/hooks/useSeoMeta'
import { type Page } from '~/routes/types'

type Props = {
  id: NonNullable<Page['options']['seoKey']>
}

export const PageHead = ({ id }: Props) => {
  const { description, title } = useSeoMeta(id)
  const pathname = usePathname()

  useEffect(() => {
    document.title = title

    const updateMetaTag = (name: string, content: string, isProperty = false) => {
      const selector = isProperty ? `meta[property='${name}']` : `meta[name='${name}']`
      const metaElementInHead = document.querySelector(selector)
      const metaElement = metaElementInHead ?? document.createElement('meta')
      metaElement.setAttribute(isProperty ? 'property' : 'name', name)
      metaElement.setAttribute('content', content)

      if (metaElementInHead == null) {
        document.head.appendChild(metaElement)
      }
    }

    updateMetaTag('description', description)
    updateMetaTag('og:title', title, true)
    updateMetaTag('twitter:title', title, true)
    updateMetaTag('og:description', description, true)
    updateMetaTag('twitter:description', description, true)
    updateMetaTag('og:url', pathname, true)

    const canonicalElementInHead = document.querySelector("link[rel='canonical']")
    const canonicalElement = canonicalElementInHead ?? document.createElement('link')
    canonicalElement.setAttribute('rel', 'canonical')
    canonicalElement.setAttribute('href', pathname)

    if (canonicalElementInHead == null) {
      document.head.appendChild(canonicalElement)
    }
  }, [pathname, title, description])

  return null
}
