import { format, FormatDateEnum } from 'dates'
import { pipe } from 'fp-ts/function'
import { type CalendarRangeInterval } from '../types'
import { parseEndAndStartFromCalendar } from './parseEndAndStartFromCalendar'

export const getDateRangeLabel = (range: CalendarRangeInterval) => {
  const { end, start } = parseEndAndStartFromCalendar(range)

  return [pipe(start, format(FormatDateEnum.HOURS_MINUTES)), pipe(end, format(FormatDateEnum.HOURS_MINUTES))].join(
    ' - ',
  )
}
