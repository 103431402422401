import { type ClinicalBondAverageRating } from './getClinicalBondAverageRating'

type ClinicalBondRoute =
  | '/respect'
  | '/listen'
  | '/management'
  | '/overall'
  | '/info'
  | `/end/${ClinicalBondAverageRating | ':score'}`

export const getClinicalBondRoute = (route: ClinicalBondRoute): ClinicalBondRoute => route
