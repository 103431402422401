import { gql, useQuery } from '@apollo/client'
import { type GetDiagnosisByPatientIdQuery, type GetDiagnosisByPatientIdQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query getDiagnosisByPatientId($input: GetDiagnosisByPatientIdInput!) {
    getDiagnosisByPatientId(input: $input) {
      age
      country {
        id
        code
        name
      }
      degree
      genderIdentity {
        id
        name
      }
      healthIssue
      historicalDiagnosis
      language {
        id
        code
        name
      }
      otherInfo
      pathologies {
        pathology {
          id
          name
        }
      }
      pharmacologicalTreatment
      profession
      psychiatricVulnerability
      socialAndFamilyNetwork
      socioCulturalContext
      workProfession {
        id
        name
      }
    }
  }
`

export const useGetDiagnosisByPatientId = (patientId: string) => {
  const { data, loading, refetch } = useQuery<GetDiagnosisByPatientIdQuery, GetDiagnosisByPatientIdQueryVariables>(
    QUERY,
    {
      fetchPolicy: 'cache-first',
      variables: {
        input: {
          patientId,
        },
      },
    },
  )

  return {
    diagnosis: data?.getDiagnosisByPatientId ?? null,
    getDiagnosisByPatientIdLoading: loading,
    refetch,
  }
}
