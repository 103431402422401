import { Text } from 'ui'
import { Flex, Pressable } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useDiagnosisCouples } from '../hooks/useDiagnosisCouples'

type Props = {
  activeTab: string
  switchToUserTab: () => void
  switchToPartnerTab: () => void
}

export const DiagnosisCouplesTabs = ({ activeTab, switchToUserTab, switchToPartnerTab }: Props) => {
  const { partner, patient } = useDiagnosisCouples()

  return (
    <Flex align="center" direction="row">
      <Pressable
        autoWidth
        colorName={activeTab === 'user' ? 'white' : 'purple08'}
        grow={1}
        lgGrow={0}
        onClick={switchToUserTab}
        shrink={1}
      >
        <Flex
          background={activeTab === 'user' ? 'purple08' : 'white'}
          borderColor="purple06"
          borderRadiusBottomLeft={8}
          borderRadiusTopLeft={8}
          borderSize={1}
          mdPx={32}
          px={16}
          py={8}
        >
          <Text fontWeight={activeTab ? '600' : '400'} kind="caption">
            {patient?.firstName}
          </Text>
        </Flex>
      </Pressable>

      <Pressable
        autoWidth
        colorName={activeTab === 'partner' ? 'white' : 'purple08'}
        disabled={!partner}
        grow={1}
        lgGrow={0}
        onClick={switchToPartnerTab}
        shrink={1}
      >
        <Flex
          background={activeTab === 'partner' ? 'purple08' : 'white'}
          borderColor="purple06"
          borderRadiusBottomRight={8}
          borderRadiusTopRight={8}
          borderSize={1}
          mdPx={32}
          px={16}
          py={8}
        >
          <Text fontWeight={activeTab ? '600' : '400'} kind="caption">
            {partner?.firstName ?? <Translation id="patientDetail.diagnosis.nd" />}
          </Text>
        </Flex>
      </Pressable>
    </Flex>
  )
}
