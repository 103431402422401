import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useGetTherapySessionById } from '~/domains/therapy-session/hooks/useGetTherapySessionById'
import { useIsTranscribeFeatured } from '../../../hooks/useIsTranscribeFeatured'
import { TranscriptionPatient } from './TranscriptionPatient'
import { TranscriptionTherapist } from './TranscriptionTherapist'

export const Transcription = () => {
  const { item: data } = useGetTherapySessionById()
  const isTherapist = useUserActingAsGroup() === 'therapist'
  const transcriptionEnabled = useIsTranscribeFeatured({ therapy_id: data?.therapyId })

  if (!transcriptionEnabled) {
    return null
  }

  if (isTherapist) {
    return <TranscriptionTherapist />
  }

  return <TranscriptionPatient />
}
