import { useMemo } from 'react'
import { usePatientsManagementTherapyByPatientId } from '~/domains/patientsManagement/hooks/usePatientsManagementTherapy'
import { therapyToPatientTherapyStatus } from '~/domains/patientsManagement/utils/therapyToPatientTherapyStatus'
import { getChurnReasonLabel } from '~/utils/getChurnReasonLabel'
import { getStatusLabel } from '~/utils/getStatusLabel'

export const usePatientStatusLabels = (patientId: string) => {
  const therapy = usePatientsManagementTherapyByPatientId(patientId)

  return useMemo(
    () => ({
      churnReason: therapy.churnReason != null ? getChurnReasonLabel(therapy.churnReason) : null,
      status: getStatusLabel(therapyToPatientTherapyStatus(therapy)),
    }),
    [therapy],
  )
}
