import { createContext, type ReactNode, useCallback, useContext, useState } from 'react'
import { useTherapySessionLive } from '../hooks/useTherapySessionLive'

type Context = {
  isChatOpen: boolean
  isPatientDetailOpen: boolean
  patient: {
    id: string
    firstName: string
    fullName: string
  }
  setIsChatOpen: (value: boolean) => void
  setIsPatientDetailOpen: (value: boolean) => void
}

const VideocallSidePanelContext = createContext<Context | null>(null)

type Props = {
  children: ReactNode
  isPatientDetailOpen?: boolean
}

export const VideocallSidePanelProvider = ({ children, isPatientDetailOpen = false }: Props) => {
  const { patient } = useTherapySessionLive()
  const [isPatientDetailOpenState, setIsPatientDetailOpenState] = useState(isPatientDetailOpen)
  const [isChatOpenState, setIsChatOpenState] = useState(false)

  const handleSetIsPatientDetailOpen = useCallback((value: boolean) => {
    setIsPatientDetailOpenState(value)
    setIsChatOpenState(false)
  }, [])

  const handleSetIsChatOpen = useCallback((value: boolean) => {
    setIsChatOpenState(value)
    setIsPatientDetailOpenState(false)
  }, [])

  return (
    <VideocallSidePanelContext.Provider
      value={{
        isChatOpen: isChatOpenState,
        isPatientDetailOpen: isPatientDetailOpenState,
        patient,
        setIsChatOpen: handleSetIsChatOpen,
        setIsPatientDetailOpen: handleSetIsPatientDetailOpen,
      }}
    >
      {children}
    </VideocallSidePanelContext.Provider>
  )
}

export const useVideocallSidePanel = () => {
  const context = useContext(VideocallSidePanelContext)

  if (!context) {
    throw new Error('useVideocallSidePanel must be used within a VideocallSidePanelProvider')
  }

  return context
}
