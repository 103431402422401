import { Icon, Intercom } from 'icons'
import { Badge } from 'ui'
import { useIntercomState } from '~/domains/intercom/hooks/useIntercomState'

export const IntercomWithBadge = () => {
  const { unreadCount } = useIntercomState()

  return (
    <Badge sup={unreadCount}>
      <Icon Svg={Intercom} colorName="grey-60" size={20} />
    </Badge>
  )
}
