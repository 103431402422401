import { useCallback, useMemo } from 'react'
import { Text } from 'ui'
import { Button, Flex, OverflowAuto } from 'ui-deprecated'
import { Markdown } from '~/components/Markdown'
import { Rating } from '~/components/Rating'
import { Translation } from '~/components/Translation'
import { getClinicalBondAnswerTranslationId } from '~/domains/clinicalBond/utils/getClinicalBondAnswerTranslationId'
import { Modal, useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { useTherapistTherapies } from '~/domains/therapies/TherapistTherapiesProvider'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useLatestClinicalBondByPatientId } from '~/hooks/useLatestClinicalBondByPatientId'

type Props = {
  patientId: string
}

export const ProductUserFeedbackClinicalBondModal = ({ patientId }: Props) => {
  const { latestClinicalBondByPatientId } = useLatestClinicalBondByPatientId(patientId)

  const { close } = useModals()

  const { therapies } = useTherapistTherapies()

  const therapy = useMemo(() => therapies.find(({ patient }) => patient?.id === patientId), [patientId, therapies])

  const { fullName } = useCurrentUser()

  const onClose = useCallback(() => {
    close('clinicalBondView')
  }, [close])

  if (!latestClinicalBondByPatientId || !therapy) {
    return null
  }

  return (
    <Modal id="clinicalBondView">
      <ModalCloseButton id="clinicalBondView" />

      <OverflowAuto>
        <Text fontWeight="600" kind="paragraph">
          <Translation id="clinicalBond.modal.title" />
        </Text>
        <Flex pt={8}>
          <Text fontWeight="400" kind="paragraph">
            <Translation id="clinicalBond.modal.subtitle" />
          </Text>
        </Flex>

        {latestClinicalBondByPatientId.answers.map((answer) => {
          if (answer.__typename === 'LatestClinicalBondByPatientIdAnswerString' && !answer.valueString) {
            return null
          }

          return (
            <Flex key={answer.id} pt={24}>
              <Text fontWeight="600" kind="paragraph">
                <Translation
                  id={getClinicalBondAnswerTranslationId({ id: answer.id, therapyPathType: therapy.therapyPath.type })}
                  values={{ therapistFullName: fullName }}
                />
              </Text>

              <Flex pt={8}>
                {answer.__typename === 'LatestClinicalBondByPatientIdAnswerNumber' && (
                  <Rating initialRating={answer.valueNumber} size={40} />
                )}

                {answer.__typename === 'LatestClinicalBondByPatientIdAnswerString' && (
                  <Markdown colorName="black" kind="paragraph">
                    {answer.valueString}
                  </Markdown>
                )}
              </Flex>
            </Flex>
          )
        })}
      </OverflowAuto>

      <Flex pt={16}>
        <Button kind="primary" onClick={onClose}>
          <Translation id="actions.close" />
        </Button>
      </Flex>
    </Modal>
  )
}
