import { type TherapyTherapyPathType } from '~/types/graphql'
import { TechnicalFeedbackPsychiatryModal } from './TechnicalFeedbackPsychiatryModal'
import { TechnicalFeedbackPsychotherapyModal } from './TechnicalFeedbackPsychotherapyModal'

type Props = {
  onCompleted: () => void
  technicalFeedbackId: string
  therapyPathType: TherapyTherapyPathType | null
  therapySessionId: string
}

export const TechnicalFeedbackModal = ({
  onCompleted,
  technicalFeedbackId,
  therapyPathType,
  therapySessionId,
}: Props) => {
  if (therapyPathType === 'MYSELF_PSYCHIATRY') {
    return <TechnicalFeedbackPsychiatryModal onCompleted={onCompleted} technicalFeedbackId={technicalFeedbackId} />
  }

  return (
    <TechnicalFeedbackPsychotherapyModal
      onCompleted={onCompleted}
      technicalFeedbackId={technicalFeedbackId}
      therapySessionId={therapySessionId}
    />
  )
}
