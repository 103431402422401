import { Flex } from 'cdk'
import { addMonths, format, isAfter, subMonths } from 'date-fns'
import { FormatDateEnum } from 'dates'
import { SPACING_MD, SPACING_XS } from 'design-tokens'
import styled from 'styled-components'
import { useCalendarMoods } from '../hooks/useCalendarMoods'
import { useCalendarRows } from '../hooks/useCalendarRows'
import { CalendarDay } from './CalendarDay'
import { CalendarHeader } from './CalendarHeader'
import { useFoodJournal } from './FoodJournalContext'
import { FoodJournalMoodLegend } from './FoodJournalMoodLegend'

const today = new Date()

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: ${SPACING_XS};
`

type Props = {
  onDayClick: VoidFunction
}

export const MonthlyCalendar = ({ onDayClick }: Props) => {
  const { currentCalendarMonth, setCurrentCalendarMonth, setCurrentDay } = useFoodJournal()
  const { moods } = useCalendarMoods()

  const handlePreviousMonth = () => {
    setCurrentCalendarMonth((prev) => subMonths(prev, 1))
  }

  const handleNextMonth = () => {
    setCurrentCalendarMonth((prev) => addMonths(prev, 1))
  }

  const calendarRows = useCalendarRows()

  return (
    <Flex $gap={16}>
      <CalendarHeader currentDate={currentCalendarMonth} onNext={handleNextMonth} onPrev={handlePreviousMonth} />

      <Grid>
        {calendarRows.map((week) =>
          week.map(({ date, isCurrentMonth, isToday }) => {
            const formattedDay = format(date, FormatDateEnum.YEAR_MONTH_DAY)
            const disabled = isAfter(date, today)
            const loading = !disabled && !(formattedDay in moods)

            return (
              <CalendarDay
                key={date.toDateString()}
                clickableIfSelected
                compactOnMobile
                day={formattedDay}
                disabled={disabled}
                isOutsideCurrentMonth={!isCurrentMonth}
                loading={loading}
                onClick={() => {
                  setCurrentDay(date)
                  onDayClick()
                }}
                selected={isToday}
                type={moods[formattedDay]}
              />
            )
          }),
        )}
      </Grid>

      <Flex $pt={SPACING_MD}>
        <FoodJournalMoodLegend />
      </Flex>
    </Flex>
  )
}
