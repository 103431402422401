import { noop } from 'functions'
import { useCallback } from 'react'
import { useTherapySessionFindManyFutureForTherapistByPatientId } from '~/domains/patientsManagement/hooks/useTherapySessionFindManyFutureForTherapistByPatientId'
import { useTherapySessionsTherapistAgenda } from '~/domains/therapy-session/hooks/useTherapySessionsTherapistAgenda'
import { useLoadingState } from '~/hooks/useLoadingState'
import { useToasts } from '~/hooks/useToasts'
import { type BatchRecurrencyRepeat } from '~/types/graphql'
import { useCreateBatchRecurrency } from './useCreateBatchRecurrency'

type Param = {
  onSuccess: () => void
  parentTherapySessionId: string
  repeat: BatchRecurrencyRepeat
  repeatCount: number
  repeatEvery: number
  startAt: Date
}

type Props = {
  patientId: string
  therapyId: string
}

export const useCreateBatchRecurringTherapySession = ({ patientId, therapyId }: Props) => {
  const [createBatchRecurrency] = useCreateBatchRecurrency()
  const { addToast } = useToasts()
  const { startLoading, stopLoading } = useLoadingState()
  const { refetch } = useTherapySessionsTherapistAgenda()
  const { refetch: refetchTherapySessionFindManyFutureForTherapistByPatientId } =
    useTherapySessionFindManyFutureForTherapistByPatientId(patientId)

  return useCallback(
    async ({ onSuccess, parentTherapySessionId, startAt, repeat, repeatCount, repeatEvery }: Param) => {
      if (!repeat || !repeatCount || !repeatEvery) {
        return
      }

      startLoading('local')

      try {
        const response = await createBatchRecurrency({
          variables: {
            input: {
              from: startAt,
              parentTherapySessionId,
              repeat,
              repeatCount,
              repeatEvery,
              therapyId,
            },
          },
        })

        stopLoading()

        onSuccess()

        addToast({ translationId: 'booking.therapySession.bookedByParent', type: 'success' })

        const availabilities = response.data?.createBatchRecurrency || []

        if (!availabilities.length) {
          return
        }

        await refetch()

        await refetchTherapySessionFindManyFutureForTherapistByPatientId().catch(noop)
      } catch (error) {
        addToast({ translationId: 'booking.therapySession.failedBookingByParentRecurring', type: 'alert' })
      } finally {
        stopLoading()
      }
    },
    [
      addToast,
      createBatchRecurrency,
      refetch,
      refetchTherapySessionFindManyFutureForTherapistByPatientId,
      startLoading,
      stopLoading,
      therapyId,
    ],
  )
}
