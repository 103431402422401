import { type TherapistTherapiesQuery } from '~/types/graphql'
import { type TherapiesFilter } from './getGroupFilterTranslation'

export const isTherapyInactive = (therapy: TherapistTherapiesQuery['therapies'][number] | null) =>
  !therapy || !!therapy.data?.isChurnReasonDefinitive || !!therapy.data?.isDeletedReasonDefinitive

export const therapiesToFilterGroups = (therapies: TherapistTherapiesQuery['therapies']) =>
  therapies.reduce(
    ({ all, withAppointment, noAppointment, noClinicalDiary, noDiagnosis, inactive }, therapy) => {
      const isInactive = isTherapyInactive(therapy)
      const isActiveWithNoAppointment = !isInactive && !therapy.data?.upcomingTherapySessionsCounter
      const isActiveWithNoClinicalDiary = !isInactive && !!therapy.data?.missingNotesCounter
      const isActiveWithNoDiagnosis = !isInactive && !therapy.data?.hasSituation
      const isActiveWithAppointment = !isInactive && !!therapy.data?.upcomingTherapySessionsCounter

      return {
        all: [...all, therapy],
        inactive: [...inactive, ...(isInactive ? [therapy] : [])],
        noAppointment: [...noAppointment, ...(isActiveWithNoAppointment ? [therapy] : [])],
        noClinicalDiary: [...noClinicalDiary, ...(isActiveWithNoClinicalDiary ? [therapy] : [])],
        noDiagnosis: [...noDiagnosis, ...(isActiveWithNoDiagnosis ? [therapy] : [])],
        withAppointment: [...withAppointment, ...(isActiveWithAppointment ? [therapy] : [])],
      }
    },
    {
      all: [],
      withAppointment: [],
      noAppointment: [],
      noClinicalDiary: [],
      noDiagnosis: [],
      inactive: [],
    } as Record<TherapiesFilter, TherapistTherapiesQuery['therapies']>,
  )
