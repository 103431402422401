import { noop } from 'functions'
import { createContext, type ReactNode, useContext, useEffect } from 'react'
import { CenteredLoader } from '~/components/CenteredLoader'
import { usePatientTherapiesQuery } from './usePatientTherapiesQuery'

type Props = {
  children: ReactNode
  onError?: () => void
}

type Context = ReturnType<typeof usePatientTherapiesQuery>

export type PatientTherapy = NonNullable<Context['therapies'][number]>

const PatientTherapiesContext = createContext<Context>({
  error: false,
  loading: true,
  therapies: [],
  therapists: [],
  refetch: noop,
  updateQuery: noop,
})

export const PatientTherapiesProvider = ({ children, onError = noop }: Props) => {
  const { error, loading, refetch, therapies, therapists, updateQuery } = usePatientTherapiesQuery()

  useEffect(() => {
    if (error) {
      onError()
    }
  }, [error, onError])

  if (loading) {
    return <CenteredLoader />
  }

  return (
    <PatientTherapiesContext.Provider value={{ error, loading, refetch, therapies, therapists, updateQuery }}>
      {children}
    </PatientTherapiesContext.Provider>
  )
}

export const usePatientTherapies = () => {
  const context = useContext(PatientTherapiesContext)

  return context
}
