import { type ComponentProps } from 'react'
import { type TextFieldNew } from 'ui-deprecated'
import { ReactHookFormTextField } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useFormValidation } from '~/hooks/useFormValidation'

type Props = {
  disabled?: boolean
  hideLabel?: boolean
  placeholderType?: 'text' | 'example-number'
  readOnly?: boolean
  textAlign?: ComponentProps<typeof ReactHookFormTextField>['textAlign']
  size?: ComponentProps<typeof TextFieldNew>['size']
  required?: boolean
}

export const FieldPhoneNumber = ({
  disabled = false,
  hideLabel = false,
  textAlign = 'left',
  placeholderType = 'example-number',
}: Props) => {
  const {
    errors: { required },
    labels,
    placeholders,
  } = useFormTranslations()

  const { isMobilePhone } = useFormValidation()

  const placeholder = placeholderType === 'text' ? placeholders.phoneNumberLongText : placeholders.phoneNumberExample

  return (
    <ReactHookFormTextField
      autoComplete="tel-national"
      disabled={disabled}
      label={hideLabel ? '' : labels.phoneNumber}
      name="phoneNumber"
      placeholder={placeholder}
      rules={{ required, validate: isMobilePhone }}
      textAlign={textAlign}
      type="tel"
    />
  )
}
