import { Flex } from 'cdk'
import { BORDER_RADIUS_CIRCLE_VALUE, BORDER_WIDTH_1_VALUE, COLOR_NEUTRAL_90 } from 'design-tokens'
import styled from 'styled-components'

const WRAPPER_SIZE = 38

export const IconWrapper = styled(Flex).attrs({
  $align: 'center',
  $justify: 'center',
  $borderRadius: BORDER_RADIUS_CIRCLE_VALUE,
  $borderSize: BORDER_WIDTH_1_VALUE,
  $borderColorName: 'primary',
  $maxHeight: `${WRAPPER_SIZE}px`,
  $maxWidth: `${WRAPPER_SIZE}px`,
  $minHeight: `${WRAPPER_SIZE}px`,
  $minWidth: `${WRAPPER_SIZE}px`,
})`
  &:hover {
    border-color: ${COLOR_NEUTRAL_90};
    background-color: ${COLOR_NEUTRAL_90};
  }
`
