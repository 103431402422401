import { gql, useQuery } from '@apollo/client'
import { type GetTherapySessionIndexByIdQuery, type GetTherapySessionIndexByIdQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query getTherapySessionIndexById($id: String!) {
    getTherapySessionIndexById(id: $id)
  }
`

export const useTherapySessionIndex = (id: string) => {
  const { data, error, loading } = useQuery<GetTherapySessionIndexByIdQuery, GetTherapySessionIndexByIdQueryVariables>(
    QUERY,
    {
      variables: { id },
    },
  )

  return {
    error: error != null,
    loading,
    index: data?.getTherapySessionIndexById,
  }
}
