import { type ComponentProps, type ReactNode } from 'react'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { type TranslationId } from '~/i18n/types'
import { DiagnosisKeyValue } from './DiagnosisKeyValue'

type Props = {
  children?: ReactNode
  items: ComponentProps<typeof DiagnosisKeyValue>[]
  titleTranslationId: Extract<
    TranslationId,
    'patientDetail.diagnosis.state' | 'patientDetail.diagnosis.couples.state' | 'patientDetail.diagnosis.info'
  >
  isEditable: boolean
}

export const DiagnosisKeyValuesList = ({ children, items, isEditable, titleTranslationId }: Props) => (
  <Flex>
    <Text colorName="primary" fontWeight="600" kind="paragraph">
      <Translation id={titleTranslationId} />
    </Text>

    {items.map((item) => (
      <Flex key={item.keyTranslationId} pt={16}>
        <DiagnosisKeyValue isEditable={isEditable} {...item} />
      </Flex>
    ))}

    {children}
  </Flex>
)
