import { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { DrawerProvider } from 'ui'
import { CenteredLoader } from '~/components/CenteredLoader'
import { FlowWrapper } from '~/components/Flow/FlowWrapper'
import { ReservationFlowProgressBar } from '~/domains/reservation/components/ReservationFlowProgressBar'
import { getReservationRoutes } from '~/domains/reservation/utils/getReservationRoutes'
import { useRootHistory } from '~/hooks/useRootHistory'
import { ScrollRestoreProvider } from '~/hooks/useScrollRestore'
import { useSuggestedTherapists } from '~/hooks/useSuggestedTherapists'
import { TherapistFetchProvider } from '~/hooks/useTherapistFetch'
import { getRoute } from '~/utils/getRoute'
import { PreBookingTherapist } from './PreBookingTherapist'

/**
 * TODO: (amin-khayam)
 * Add ReservationImpressionTracker
 * https://linear.app/serenis/issue/ENG-805/add-reservationimpressiontracker-to-pre-booking-page
 */

export const PreBooking = () => {
  const { data, error } = useSuggestedTherapists()
  const rootHistory = useRootHistory()

  useEffect(() => {
    if (error) {
      rootHistory.push(getRoute('/start'))
    }
  }, [error, rootHistory])

  useEffect(() => {
    if (data != null && data.suggestedTherapists.therapistsIds.length === 0) {
      rootHistory.push(getRoute('/pre-booking-no-matches'))
    }
  }, [rootHistory, data])

  if (data == null) {
    return <CenteredLoader />
  }

  if (data.suggestedTherapists.therapistsIds.length === 0) {
    return null
  }

  return (
    <FlowWrapper>
      <ReservationFlowProgressBar />

      <ScrollRestoreProvider background="white" pb={0}>
        <TherapistFetchProvider id={data?.suggestedTherapists.therapistsIds[0]}>
          <Route exact path={getReservationRoutes('/book-by-suggested-therapist')}>
            <DrawerProvider>
              <PreBookingTherapist />
            </DrawerProvider>
          </Route>
        </TherapistFetchProvider>
      </ScrollRestoreProvider>
    </FlowWrapper>
  )
}
