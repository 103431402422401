import { addMonths, endOfDay, endOfMonth } from 'date-fns'
import { useBreakpoints } from 'hooks'
import { useMemo } from 'react'
import { useAvailableTimeSlotsByTherapist } from '~/domains/availabilities/calendar/hooks/useAvailableTimeSlotsByTherapist'
import { type UserTherapist } from '~/domains/therapist/types'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { useAvailableTimeSlotsByDay } from './useTimeSlots'

const VISIBLE_DAYS: { [length: number]: { small: number; large: number; extraLarge: number } } = {
  3: { small: 1, large: 2, extraLarge: 2 },
  2: { small: 1, large: 3, extraLarge: 3 },
  1: { small: 3, large: 5, extraLarge: 7 },
}

type CarouselSlotsProps = {
  maxVisibleColumns?: number
  selectedTherapist: UserTherapist
  therapyId: string
  therapyPathType: TherapyTherapyPathType
}

export const useCarouselSlots = ({
  maxVisibleColumns,
  selectedTherapist,
  therapyId,
  therapyPathType,
}: CarouselSlotsProps) => {
  const { isSmallOnly, isMediumOnly, isLargeOnly } = useBreakpoints()
  const { items, loading, error } = useAvailableTimeSlotsByTherapist({
    startAt: endOfDay(new Date()),
    endAt: endOfMonth(addMonths(new Date(), 1)),
    userId: selectedTherapist.id,
    therapyPathType,
    therapyId,
  })
  const { slotsByDay } = useAvailableTimeSlotsByDay({
    items,
    therapyPathType,
  })

  const data = useMemo(
    () =>
      slotsByDay
        .map(([day, therapists]) => [day, therapists.filter(([id]) => selectedTherapist?.id === id)])
        .filter(([, therapists]) => therapists.length > 0) as [string, [string, Date[]][]][],
    [selectedTherapist, slotsByDay],
  )

  const currentBreakpoint = isSmallOnly || isMediumOnly ? 'small' : isLargeOnly ? 'large' : 'extraLarge'

  let slice = VISIBLE_DAYS[1][currentBreakpoint]

  if (maxVisibleColumns != null) {
    slice = slice > maxVisibleColumns ? maxVisibleColumns : slice
  }

  return {
    error,
    length: Math.ceil(data.length / slice),
    loading,
    slice,
    data,
  }
}
