import { Flex, Form } from 'cdk'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Card, DrawerFooter, DrawerHeader, DrawerTitle } from 'ui'
import { Translation } from '~/components/Translation'
import { useIsNativeAppAndroid } from '~/domains/appNative/hooks/useIsNativeAppAndroid'
import { useIsNativeAppIOS } from '~/domains/appNative/hooks/useIsNativeAppIOS'
import { useChangeTherapist } from '~/domains/changeTherapist/hooks/useChangeTherapist'
import { useIntercom } from '~/domains/intercom/hooks/useIntercom'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { ReactHookFormRadioFieldVariant } from '~/domains/react-hook-form/components/ReactHookFormRadioFieldVariant'
import { useChangeNutritionist } from '~/hooks/useChangeNutritionist'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useRootHistory } from '~/hooks/useRootHistory'
import { useToasts } from '~/hooks/useToasts'
import { type TranslationId } from '~/i18n/types'
import { type ChangeTherapistReason, type ChurnReason } from '~/types/graphql'
import { getEnv } from '~/utils/getEnv'
import { getRoute } from '~/utils/getRoute'
import { isChangeTherapistSupported, isNutrition } from '~/utils/therapyPaths'
import { useManageSession } from '../hooks/useManageSession'
import { type ManageSessionDeleteFormValues } from '../types'
import { getManageSessionRoutes } from '../utils/getManageSessionRoutes'

type ChurnReasonType = {
  reason: Extract<TranslationId, `therapySession.churnReason.patient.${string}`>
  alt: Extract<TranslationId, Extract<TranslationId, `therapySession.churnReason.patient.${string}.alt`>>
  altCta: Extract<TranslationId, `actions.${string}`>
  value: Extract<ChurnReason, 'SOLVED' | 'ECONOMIC' | 'THERAPY_IN_PERSON' | 'THERAPIST_ISSUE' | 'SCHEDULING_ISSUE'>
}

const CHURN_REASONS: ChurnReasonType[] = [
  {
    reason: 'therapySession.churnReason.patient.solved',
    alt: 'therapySession.churnReason.patient.solved.alt',
    altCta: 'actions.review',
    value: 'SOLVED',
  },
  {
    reason: 'therapySession.churnReason.patient.economic',
    alt: 'therapySession.churnReason.patient.economic.alt',
    altCta: 'actions.contactUs',
    value: 'ECONOMIC',
  },
  {
    reason: 'therapySession.churnReason.patient.therapyInPerson',
    alt: 'therapySession.churnReason.patient.therapyInPerson.alt',
    altCta: 'actions.tryThem',
    value: 'THERAPY_IN_PERSON',
  },
  {
    reason: 'therapySession.churnReason.patient.therpistIssue',
    alt: 'therapySession.churnReason.patient.therpistIssue.alt',
    altCta: 'actions.changeProfessional',
    value: 'THERAPIST_ISSUE',
  },
  {
    reason: 'therapySession.churnReason.patient.schedulingIssue',
    alt: 'therapySession.churnReason.patient.schedulingIssue.alt',
    altCta: 'actions.changeProfessional',
    value: 'SCHEDULING_ISSUE',
  },
]

const useReviewURL = () => {
  const isNativeAppAndroid = useIsNativeAppAndroid()
  const isNativeAppIOS = useIsNativeAppIOS()

  if (isNativeAppAndroid) {
    return `${getEnv('SERENIS_PLAY_STORE')}&showAllReviews=true`
  }

  if (isNativeAppIOS) {
    return `${getEnv('SERENIS_APP_STORE')}?action=write-review`
  }

  return getEnv('SERENIS_TRUST_PILOT')
}

const useHandleAlternativeCTA = () => {
  const [changeTherapist] = useChangeTherapist()
  const { addToast } = useToasts()
  const { open: openIntercom } = useIntercom()
  const { therapyPath, therapyId } = useManageSession()
  const { watch } = useReactHookFormContext<ManageSessionDeleteFormValues>()
  const changeNutritionist = useChangeNutritionist()
  const reviewURL = useReviewURL()
  const rootHistory = useRootHistory()

  const churnReason = watch('churnReason')

  const handleChangeTherapist = useCallback(
    async (changeTherapistReason: ChangeTherapistReason) => {
      try {
        if (isNutrition(therapyPath.type)) {
          return changeNutritionist()
        }

        if (!isChangeTherapistSupported(therapyPath.type)) {
          return openIntercom()
        }

        const { data, errors } = await changeTherapist({ variables: { input: { changeTherapistReason, therapyId } } })

        if (errors || data?.changeTherapist == null) {
          throw new Error('Failed to change therapist')
        }

        rootHistory.push(getRoute(`/change-therapist/${therapyId}`))
      } catch (error) {
        addToast({ translationId: 'generic.error', type: 'alert' })
      }
    },
    [addToast, changeNutritionist, changeTherapist, openIntercom, rootHistory, therapyId, therapyPath.type],
  )

  return useCallback(async () => {
    if (churnReason === 'SOLVED') {
      return window.open(reviewURL, '_blank')
    }

    if (churnReason === 'ECONOMIC') {
      return openIntercom()
    }

    if (churnReason === 'THERAPIST_ISSUE') {
      return await handleChangeTherapist('NEED_TO_KNOW_MULTIPLE_THERAPISTS')
    }

    if (churnReason === 'SCHEDULING_ISSUE') {
      return await handleChangeTherapist('BAD_TIME_SLOTS')
    }
  }, [churnReason, handleChangeTherapist, openIntercom, reviewURL])
}

export const DeleteSessionChurn = () => {
  const { errors } = useFormTranslations()
  const { formState, handleSubmit, resetField, watch } = useReactHookFormContext<ManageSessionDeleteFormValues>()
  const handleAlternativeCTA = useHandleAlternativeCTA()
  const history = useHistory()

  const churnReason = watch('churnReason')

  return (
    <Form
      $gap={16}
      $grow={1}
      $shrink={1}
      onSubmit={handleSubmit(() => {
        history.push(getManageSessionRoutes('/delete-message'))
      })}
    >
      <DrawerHeader />
      <Flex $gap={8}>
        <DrawerTitle>
          <Translation id="therapySession.churnReason.patient.title" />
        </DrawerTitle>
        <Translation id="actions.pickAnOption" />
      </Flex>
      <Flex $gap={8}>
        {CHURN_REASONS.map(({ reason, value, alt, altCta }) => (
          <Flex key={value} $gap={8}>
            <ReactHookFormRadioFieldVariant name="churnReason" rules={{ required: errors.required }} value={value}>
              <Translation id={reason} />
            </ReactHookFormRadioFieldVariant>
            {churnReason === value && churnReason !== 'THERAPY_IN_PERSON' && (
              <Card $backgroundColorName="neutral-20" $elevationName="0" $gap={8}>
                <Translation id={alt} />
                <Button kind="primary" onClick={handleAlternativeCTA}>
                  <Translation id={altCta} />
                </Button>
              </Card>
            )}
          </Flex>
        ))}
      </Flex>
      <DrawerFooter>
        <Flex $gap={8} $grow={1} $mdDirection="row-reverse" $shrink={1}>
          <Flex $basis="50%" $grow={1} $shrink={1}>
            <Button disabled={!formState.isValid} kind="primary" type="submit">
              <Translation id="actions.proceed" />
            </Button>
          </Flex>
          <Flex $basis="50%" $grow={1} $shrink={1}>
            <Button
              isGhost
              kind="primary"
              onClick={() => {
                resetField('deletedReason')
                history.goBack()
              }}
            >
              <Translation id="actions.back" />
            </Button>
          </Flex>
        </Flex>
      </DrawerFooter>
    </Form>
  )
}
