import { Flex } from 'cdk'
import { BORDER_RADIUS_MD, BORDER_WIDTH_1, type ColorName, SPACING_SM } from 'design-tokens'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { type TranslationId } from '~/i18n/types'
import { type FoodJournalMood } from '~/types/graphql'
import { useGetFoodJournalDay } from '../hooks/useGetFoodJournalDay'
import { useFoodJournal } from './FoodJournalContext'

const configuration: Record<
  FoodJournalMood,
  {
    borderColor: ColorName
    backgroundColor: ColorName
    color: ColorName
    translationId: TranslationId
  }
> = {
  EXCELLENT: {
    borderColor: 'green-60',
    backgroundColor: 'green-20',
    color: 'grey-80',
    translationId: 'nutrition.diary.dayRecap.option.excellent',
  },
  AVERAGE: {
    borderColor: 'yellow-80',
    backgroundColor: 'yellow-20',
    color: 'yellow-80',
    translationId: 'nutrition.diary.dayRecap.option.average',
  },
  JUST_OK: {
    borderColor: 'red-80',
    backgroundColor: 'red-20',
    color: 'red-80',
    translationId: 'nutrition.diary.dayRecap.option.justOk',
  },
}

export const TherapistFoodJournalMood = () => {
  const { patientId, currentDay } = useFoodJournal()

  const { foodJournalDay, loading } = useGetFoodJournalDay({
    day: currentDay,
    patientId,
  })

  const selectedMood = foodJournalDay?.mood

  if (loading) {
    return null
  }

  if (selectedMood == null) {
    return null
  }

  const config = configuration[selectedMood]

  return (
    <Flex
      $align="center"
      $backgroundColorName={config.backgroundColor}
      $borderColorName={config.borderColor}
      $borderRadius={BORDER_RADIUS_MD}
      $borderSize={BORDER_WIDTH_1}
      $p={SPACING_SM}
    >
      <Text colorName={config.color} kind="caption-strong">
        <Translation id={config.translationId} />
      </Text>
    </Flex>
  )
}
