import { useBreakpoints } from 'hooks'
import { isEqual } from 'lodash-es'
import { useCallback, useMemo, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Text } from 'ui'
import { Button, Flex, Form, MaxWidth840px, OverflowAuto } from 'ui-deprecated'
import { CenteredLoader } from '~/components/CenteredLoader'
import { PageRoute } from '~/components/PageRoute'
import { type SelectOption } from '~/components/SelectNew/types'
import { Translation } from '~/components/Translation'
import { DiagnosisMandatoryFieldBadge } from '~/domains/diagnosis/components/DiagnosisMandatoryFieldBadge'
import { ReactHookFormProvider, ReactHookFormTextArea, useReactHookForm } from '~/domains/react-hook-form'
import { ReactHookFormSelectNew } from '~/domains/react-hook-form/components/ReactHookFormSelectNew'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useToasts } from '~/hooks/useToasts'
import { useTranslation } from '~/i18n/hooks/useTranslation'
import { type DiagnosisCouplesUpdateInput } from '~/types/graphql'
import { getRoute } from '~/utils/getRoute'
import { useDiagnosisCouples } from '../hooks/useDiagnosisCouples'
import { DiagnosisCouplesSuggestionBox } from './DiagnosisCouplesSuggestionBox'
import { DiagnosisCouplesTabs } from './DiagnosisCouplesTabs'
import { EditDiagnosisCouplesFieldsPartner } from './EditDiagnosisCouplesFieldsPartner'
import { EditDiagnosisCouplesFieldsPatient } from './EditDiagnosisCouplesFieldsPatient'

const BoxShadow = styled(Flex)`
  box-shadow: 0 -4px 10px 0 rgb(0 0 0 / 5%);
`

const toFormValuesPathologies = ({
  pathology: { id: value, name: label },
}: {
  pathology: { id: string; name: string }
}) => ({
  label,
  value,
})

type SelectOptionString = SelectOption<string>

const toDiagnosisCouplesUpdatePathologies = ({
  pathologiesCouples,
  pathologiesMyselfPartner,
  pathologiesMyselfPatient,
}: {
  pathologiesCouples: SelectOptionString[]
  pathologiesMyselfPartner: SelectOptionString[]
  pathologiesMyselfPatient: SelectOptionString[]
}) => [
  ...pathologiesCouples.map(({ value: id }) => ({ id, target: 'COUPLE' }) as const),
  ...pathologiesMyselfPartner.map(({ value: id }) => ({ id, target: 'PARTNER' }) as const),
  ...pathologiesMyselfPatient.map(({ value: id }) => ({ id, target: 'PATIENT' }) as const),
]

type FormValues = {
  otherInfo?: string
  partnerAge?: string
  partnerCountry?: SelectOptionString
  partnerDegree?: string
  partnerGenderIdentity?: SelectOptionString
  partnerHealthIssue?: string
  partnerHistoricalDiagnosis?: string
  partnerLanguage?: SelectOptionString
  partnerPharmacologicalTreatment?: string
  partnerProfession?: string
  partnerPsychiatricVulnerability?: string
  partnerSocialAndFamilyNetwork?: string
  partnerSocioCulturalContext?: string
  partnerWorkProfession?: SelectOptionString
  pathologiesCouples: SelectOptionString[]
  pathologiesMyselfPartner: SelectOptionString[]
  pathologiesMyselfPatient: SelectOptionString[]
  userAge?: string
  userCountry?: SelectOptionString
  userDegree?: string
  userGenderIdentity?: SelectOptionString
  userHealthIssue?: string
  userHistoricalDiagnosis?: string
  userLanguage?: SelectOptionString
  userPharmacologicalTreatment?: string
  userProfession?: string
  userPsychiatricVulnerability?: string
  userSocialAndFamilyNetwork?: string
  userSocioCulturalContext?: string
  userWorkProfession?: SelectOptionString
}

type Tab = 'user' | 'partner'

const View = () => {
  const [activeTab, setActiveTab] = useState<Tab>('user')
  const [loading, setLoading] = useState(false)
  const { addToast } = useToasts()
  const { diagnosisCouples, diagnosisCouplesUpdate, partner, pathologiesCouples, patientId } = useDiagnosisCouples()
  const { isMedium } = useBreakpoints()
  const facingCouples = useTranslation('patientDetail.diagnosis.couples.state.facing')
  const otherInfo = useTranslation('patientDetail.diagnosis.couples.state.otherInfo')
  const history = useHistory()

  const {
    errors: { required },
  } = useFormTranslations()

  const defaultValues = useMemo(
    (): FormValues => ({
      otherInfo: diagnosisCouples?.otherInfo || '',
      partnerAge: diagnosisCouples?.partnerAge || '',
      partnerCountry: diagnosisCouples?.partnerCountry
        ? { label: diagnosisCouples?.partnerCountry.name, value: diagnosisCouples?.partnerCountry.id }
        : undefined,
      partnerDegree: diagnosisCouples?.partnerDegree || '',
      partnerGenderIdentity: diagnosisCouples?.partnerGenderIdentity
        ? { label: diagnosisCouples?.partnerGenderIdentity.name, value: diagnosisCouples?.partnerGenderIdentity.id }
        : undefined,
      partnerHealthIssue: diagnosisCouples?.partnerHealthIssue || '',
      partnerHistoricalDiagnosis: diagnosisCouples?.partnerHistoricalDiagnosis || '',
      partnerLanguage: diagnosisCouples?.partnerLanguage
        ? { label: diagnosisCouples?.partnerLanguage.name, value: diagnosisCouples?.partnerLanguage.id }
        : undefined,
      partnerPharmacologicalTreatment: diagnosisCouples?.partnerPharmacologicalTreatment || '',
      partnerProfession: diagnosisCouples?.partnerProfession || '',
      partnerPsychiatricVulnerability: diagnosisCouples?.partnerPsychiatricVulnerability || '',
      partnerSocialAndFamilyNetwork: diagnosisCouples?.partnerSocialAndFamilyNetwork || '',
      partnerSocioCulturalContext: diagnosisCouples?.partnerSocioCulturalContext || '',
      partnerWorkProfession: diagnosisCouples?.partnerWorkProfession
        ? { label: diagnosisCouples?.partnerWorkProfession.name, value: diagnosisCouples?.partnerWorkProfession.id }
        : undefined,
      pathologiesCouples:
        diagnosisCouples?.pathologies.filter(({ target }) => target === 'COUPLE').map(toFormValuesPathologies) || [],
      pathologiesMyselfPartner:
        diagnosisCouples?.pathologies.filter(({ target }) => target === 'PARTNER').map(toFormValuesPathologies) || [],
      pathologiesMyselfPatient:
        diagnosisCouples?.pathologies.filter(({ target }) => target === 'PATIENT').map(toFormValuesPathologies) || [],
      userAge: diagnosisCouples?.userAge || '',
      userCountry: diagnosisCouples?.userCountry
        ? { label: diagnosisCouples?.userCountry.name, value: diagnosisCouples?.userCountry.id }
        : undefined,
      userDegree: diagnosisCouples?.userDegree || '',
      userGenderIdentity: diagnosisCouples?.userGenderIdentity
        ? { label: diagnosisCouples?.userGenderIdentity.name, value: diagnosisCouples?.userGenderIdentity.id }
        : undefined,
      userHealthIssue: diagnosisCouples?.userHealthIssue || '',
      userHistoricalDiagnosis: diagnosisCouples?.userHistoricalDiagnosis || '',
      userLanguage: diagnosisCouples?.userLanguage
        ? { label: diagnosisCouples?.userLanguage.name, value: diagnosisCouples?.userLanguage.id }
        : undefined,
      userPharmacologicalTreatment: diagnosisCouples?.userPharmacologicalTreatment || '',
      userProfession: diagnosisCouples?.userProfession || '',
      userPsychiatricVulnerability: diagnosisCouples?.userPsychiatricVulnerability || '',
      userSocialAndFamilyNetwork: diagnosisCouples?.userSocialAndFamilyNetwork || '',
      userSocioCulturalContext: diagnosisCouples?.userSocioCulturalContext || '',
      userWorkProfession: diagnosisCouples?.userWorkProfession
        ? { label: diagnosisCouples?.userWorkProfession.name, value: diagnosisCouples?.userWorkProfession.id }
        : undefined,
    }),
    [diagnosisCouples],
  )

  const form = useReactHookForm<FormValues>({
    defaultValues,
  })

  const switchToUserTab = useCallback(() => setActiveTab('user'), [])

  const switchToPartnerTab = useCallback(() => setActiveTab('partner'), [])

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      if (isEqual(formValues, defaultValues)) {
        addToast({ translationId: 'diagnosis.success.update', type: 'success' })

        history.goBack()

        return
      }

      setLoading(true)

      const {
        partnerCountry,
        partnerGenderIdentity,
        partnerLanguage,
        partnerWorkProfession,
        pathologiesCouples,
        pathologiesMyselfPartner,
        pathologiesMyselfPatient,
        userCountry,
        userGenderIdentity,
        userLanguage,
        userWorkProfession,
        ...values
      } = formValues

      const input: DiagnosisCouplesUpdateInput = {
        ...values,
        partnerId: partner?.id,
        partnerCountryId: partnerCountry?.value,
        partnerGenderIdentityId: partnerGenderIdentity?.value,
        partnerLanguageId: partnerLanguage?.value,
        partnerWorkProfessionId: partnerWorkProfession?.value,
        pathologies: toDiagnosisCouplesUpdatePathologies({
          pathologiesCouples,
          pathologiesMyselfPartner,
          pathologiesMyselfPatient,
        }),
        userId: patientId,
        userCountryId: userCountry?.value,
        userGenderIdentityId: userGenderIdentity?.value,
        userLanguageId: userLanguage?.value,
        userWorkProfessionId: userWorkProfession?.value,
      }

      try {
        const { ok } = await diagnosisCouplesUpdate(input)

        if (!ok) {
          addToast({ translationId: 'diagnosis.alert.update', type: 'alert' })

          setLoading(false)

          return
        }

        addToast({ translationId: 'diagnosis.success.update', type: 'success' })

        history.goBack()
      } catch (error) {
        addToast({ translationId: 'diagnosis.alert.update', type: 'alert' })

        setLoading(false)
      }
    },
    [addToast, defaultValues, diagnosisCouplesUpdate, history, partner, patientId, setLoading],
  )

  const draft = form.watch('otherInfo')

  const helperText = useTranslation('forms.helperTexts.textareaCounter', {
    current: draft?.length || '0',
    max: 1000,
  })

  return (
    <ReactHookFormProvider {...form}>
      <Form id="edit-diagnosis-couples" onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
        <Flex background="white" grow={1} shrink={1}>
          <OverflowAuto grow={1} mdPx={24} px={16} shrink={1}>
            <MaxWidth840px mdPt={32} pt={24}>
              <Flex align="center" direction="row">
                <Flex pr={16}>
                  <Text colorName="primary" fontWeight="600" kind="paragraph">
                    <Translation id="patientDetail.diagnosis.state" />
                  </Text>
                </Flex>

                <DiagnosisMandatoryFieldBadge />
              </Flex>

              <Flex pt={16}>
                <DiagnosisCouplesSuggestionBox bodyTranslationId="patientDetail.diagnosis.edit.infoDescription" />
              </Flex>

              <Flex pt={16}>
                <ReactHookFormSelectNew
                  multiple
                  name="pathologiesCouples"
                  options={pathologiesCouples.map(({ id: value, name: label }) => ({ label, value }))}
                  placeholder={facingCouples}
                  rules={{ required }}
                />
              </Flex>

              <Flex pt={16}>
                <ReactHookFormTextArea
                  helperText={helperText}
                  minHeight="120px"
                  name="otherInfo"
                  placeholder={otherInfo}
                  rules={{ maxLength: 1000 }}
                />
              </Flex>

              <Flex pt={24}>
                <Text colorName="primary" fontWeight="600" kind="paragraph">
                  <Translation id="patientDetail.diagnosis.info" />
                </Text>
              </Flex>

              <Flex pt={16}>
                <DiagnosisCouplesTabs
                  activeTab={activeTab}
                  switchToPartnerTab={switchToPartnerTab}
                  switchToUserTab={switchToUserTab}
                />
              </Flex>

              {activeTab === 'user' ? <EditDiagnosisCouplesFieldsPatient /> : <EditDiagnosisCouplesFieldsPartner />}
            </MaxWidth840px>
          </OverflowAuto>

          <BoxShadow mdPx={24} px={16} py={16}>
            <MaxWidth840px>
              <Button
                disabled={!form.formState.isValid}
                kind="primary"
                loading={loading}
                size={isMedium ? 'medium' : 'small'}
                type="submit"
              >
                <Translation id="patientDetail.diagnosis.save" />
              </Button>
            </MaxWidth840px>
          </BoxShadow>
        </Flex>
      </Form>
    </ReactHookFormProvider>
  )
}

export const EditDiagnosisCouples = () => {
  const { error, loading, patientId } = useDiagnosisCouples()

  if (loading) {
    return <CenteredLoader />
  }

  if (error) {
    return <Redirect to={getRoute(`/chat/${patientId}`)} />
  }

  return (
    <PageRoute id="diagnosis.edit">
      <View />
    </PageRoute>
  )
}
