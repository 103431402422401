import { useSearchParams } from 'next/navigation'
import { matchPath } from 'react-router-dom'
import { useRootHistory } from '~/hooks/useRootHistory'
import { getRoute } from '~/utils/getRoute'
import { useGetTherapySessionLive } from '../graphql/useGetTherapySessionLive'

type Params = {
  therapySessionId: string
}

export const useTherapySessionLive = () => {
  const history = useRootHistory()
  const searchParams = useSearchParams()

  const partnerEmail = searchParams.get('email')

  // Can't use useParams since this hook is called inside a MemoryRouter
  const match = matchPath<Params>(history.location.pathname, {
    path: [
      getRoute('/therapy-session/:therapySessionId/live'),
      getRoute('/therapy-session/:therapySessionId/live/external'),
    ],
    exact: true,
    strict: false,
  })
  const therapySessionId = match?.params?.therapySessionId

  const { therapySessionLive } = useGetTherapySessionLive({
    id: therapySessionId!,
    partnerEmail: typeof partnerEmail === 'string' ? partnerEmail : null,
  })

  return therapySessionLive
}
