import { Flex } from 'cdk'
import { SPACING_4XS, SPACING_SM } from 'design-tokens'
import { IconDocument } from 'icons'
import { matchPath, useLocation } from 'react-router-dom'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { MenuLink } from '../common/MenuLink'

const to = '/settings/billing'

export const MenuItemBilling = () => {
  const { pathname } = useLocation()
  const active = !!matchPath(pathname, { path: to })

  return (
    <MenuLink dataTestId="menu-billing" to={to}>
      <Flex $pr={SPACING_SM}>
        <IconDocument colorName="neutral-60" size={24} />
      </Flex>

      <Flex $grow={1} $pt={SPACING_4XS} $shrink={1}>
        <Text colorName={active ? 'primary' : undefined} kind="paragraph">
          <Translation id="menu.billing" />
        </Text>
      </Flex>
    </MenuLink>
  )
}
