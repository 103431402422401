import { useCallStateHooks } from '@stream-io/video-react-sdk'
import { Space } from 'cdk'
import { BORDER_RADIUS_CIRCLE, BORDER_WIDTH_1, COLOR_PRIMARY_40, SPACING_XS } from 'design-tokens'
import { Expand, Icon, Microphone, MicrophoneMute, Reduce, VideoCamera, VideoCameraOff } from 'icons'
import { useCallback, useState } from 'react'
import styled from 'styled-components'
import { Chip, Text } from 'ui'
import { Flex, PositionAbsolute, Pressable } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { CurrentUserCamera } from '../../components/CurrentUserCamera'
import { ParticipantConnection } from './ParticipantConnection'
import { VideoPlaceholder } from './VideoPlaceholder'

const LabelContainer = styled(Flex)`
  .srns-quality-connection {
    line-height: 1;
  }
`

const IconContainer = styled.div`
  padding: ${SPACING_XS};
  border: ${BORDER_WIDTH_1} solid ${COLOR_PRIMARY_40};
  border-radius: ${BORDER_RADIUS_CIRCLE};
  line-height: 0;
`

export const CurrentUserParticipantViewUI = () => {
  const { useLocalParticipant } = useCallStateHooks()
  const localParticipant = useLocalParticipant()
  const [isCameraVisible, setIsCameraVisible] = useState(true)

  const handleToggleReduce = useCallback(() => {
    setIsCameraVisible(!isCameraVisible)
  }, [isCameraVisible])

  if (!localParticipant) {
    return <VideoPlaceholder />
  }

  if (!isCameraVisible) {
    return (
      <Flex
        align="center"
        background="black"
        borderRadius={8}
        className={localParticipant.isSpeaking ? 'str-video__participant-view--speaking' : ''}
        direction="row"
        justify="center"
        p={8}
      >
        <Text colorName="white" fontWeight="600" kind="paragraph">
          <Translation id="videocall.you" />
        </Text>
        <Flex pl={8}>
          <ParticipantConnection participant={localParticipant} />
        </Flex>
        <Flex pl={8}>
          <Icon Svg={localParticipant.audioStream ? Microphone : MicrophoneMute} colorName="grey-80" size={16} />
        </Flex>
        <Flex pl={8}>
          <Icon Svg={localParticipant.videoStream ? VideoCamera : VideoCameraOff} colorName="grey-80" size={16} />
        </Flex>
        <Flex pl={16}>
          <Pressable onClick={handleToggleReduce}>
            <IconContainer>
              <Icon Svg={Expand} colorName="white" size={16} />
            </IconContainer>
          </Pressable>
        </Flex>
      </Flex>
    )
  }

  return (
    <CurrentUserCamera>
      <PositionAbsolute right="8px" top="8px">
        <Pressable onClick={handleToggleReduce}>
          <IconContainer>
            <Icon Svg={Reduce} colorName="white" size={16} />
          </IconContainer>
        </Pressable>
      </PositionAbsolute>
      <PositionAbsolute align="flex-start" bottom="0" left="0" p={8} right="0">
        <Chip kind="black">
          <LabelContainer direction="row">
            <Translation id="videocall.you" />
            <Space />
            <ParticipantConnection participant={localParticipant} />
          </LabelContainer>
        </Chip>
      </PositionAbsolute>
    </CurrentUserCamera>
  )
}
