import { parseISO } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useFeatureFlagsByFingerprint } from '~/domains/featureFlags'
import { ensureMobilePhoneNumberWithPrefix } from '~/domains/phoneNumbers/utils/ensureMobilePhoneNumberWithPrefix'
import { useDataLayer } from '~/hooks/useDataLayer'
import { useIntl } from '~/i18n/hooks/useIntl'
import { type CreatePatientInput } from '~/types/graphql'
import { getRoute, type Route } from '~/utils/getRoute'
import { hash } from '~/utils/hash'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { useAuthMethods } from './useAuthMethods'
import { useCreatePatientMutation } from './useCreatePatientMutation'
import { type Intent, signupIntentShouldRedirectToHome } from './useSignupIntent'

type LocationState = {
  referrer?: Route
}

export const useSignUp = (intent: Intent) => {
  const [createPatient] = useCreatePatientMutation()
  const dataLayer = useDataLayer()
  const { signIn } = useAuthMethods()
  const history = useHistory()
  const { timeZone } = useIntl()
  const { state } = useLocation<LocationState>()

  const birthdate = getLocalStorage('birthdate')

  const { isVariant1 } = useFeatureFlagsByFingerprint()

  const ffSignupWithProfilationCheckbox = !isVariant1('ff_signup_without_profilation_checkbox')

  const nextRoute: Route = useMemo(() => {
    if (signupIntentShouldRedirectToHome(intent)) {
      return getRoute('/')
    }

    if (state?.referrer) {
      return state.referrer
    }

    return getRoute('/booking')
  }, [intent, state])

  return useCallback(
    async ({
      firstName,
      lastName,
      email,
      marketingEmails,
      password,
      phoneNumber = null,
      profilation,
      referralCode,
      termsAndConditions,
    }: CreatePatientInput) => {
      const response = await createPatient({
        variables: {
          input: {
            birthdate: birthdate ? pipe(birthdate, parseISO) : null,
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            email: email.trim(),
            marketingEmails,
            password: password.trim(),
            phoneNumber: phoneNumber ? ensureMobilePhoneNumberWithPrefix(phoneNumber) : null,
            profilation: ffSignupWithProfilationCheckbox ? profilation : termsAndConditions,
            referralCode,
            termsAndConditions,
            timeZone,
          },
        },
      })

      const userId = response.data?.createPatient?.id

      if (!userId) {
        throw new Error('Unable to create user')
      }

      dataLayer.add(
        {
          userEmail: hash(email),
          userId,
        },
        'user-info',
      )

      dataLayer.add({ event: 'USER_CREATED', type: 'LOCAL' }, 'user-created')

      await signIn(email, password)

      history.replace(nextRoute)
    },
    [birthdate, createPatient, dataLayer, ffSignupWithProfilationCheckbox, history, nextRoute, signIn, timeZone],
  )
}
