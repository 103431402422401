import { Flex } from 'cdk'
import { FONT_WEIGHT_600, SPACING_2XS } from 'design-tokens'
import { type PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Text } from 'ui'
import { Tooltip, TooltipContent, TooltipTrigger } from '~/components/FloatingUI/Tooltip'
import { Translation } from '~/components/Translation'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'

const TOOLTIP_CONTENT_WIDTH = '360px'
const SUPPLEMENTARY_INFORMATION_PZ = 'https://serenis.notion.site/guida-scribe-user'
const SUPPLEMENTARY_INFORMATION_TP = 'https://serenis.notion.site/informativa-tp'

const Link = styled(Text).attrs({ colorName: 'primary', kind: 'footnote' })`
  font-weight: ${FONT_WEIGHT_600};

  &:hover {
    text-decoration: underline;
  }
`

export const SupplementaryInformation = ({ children }: PropsWithChildren) => {
  const isTherapist = useUserActingAsGroup() === 'therapist'

  const documentLink = isTherapist ? SUPPLEMENTARY_INFORMATION_TP : SUPPLEMENTARY_INFORMATION_PZ

  return (
    <Tooltip>
      <TooltipTrigger>{children}</TooltipTrigger>
      <TooltipContent style={{ minWidth: TOOLTIP_CONTENT_WIDTH, maxWidth: TOOLTIP_CONTENT_WIDTH }}>
        <Flex $gap={SPACING_2XS} $grow={1}>
          <Text fontWeight="400" kind="footnote" style={{ lineHeight: '20px' }}>
            <Translation id="therapySessionLive.transcription.supplementaryInformation" />
          </Text>
          <Flex>
            <Link as="a" href={documentLink} target="_blank">
              <Translation id="therapySessionLive.transcription.supplementaryInformation.openDocument" />
            </Link>
          </Flex>
        </Flex>
      </TooltipContent>
    </Tooltip>
  )
}
