import { type TherapistTherapiesQuery } from '~/types/graphql'
import { isTherapyInactive } from './therapiesToFilterGroups'

type Therapy = TherapistTherapiesQuery['therapies'][number]

export type TherapyStatus = 'INACTIVE' | 'MANAGED' | 'TO_BE_MANAGED'

export const therapyToPatientTherapyStatus = (therapy: Therapy | null): TherapyStatus => {
  if (isTherapyInactive(therapy)) {
    return 'INACTIVE'
  }

  return !therapy?.data?.upcomingTherapySessionsCounter ? 'TO_BE_MANAGED' : 'MANAGED'
}
