import { Flex } from 'cdk'
import { addDays, isAfter, isSameDay, subDays } from 'date-fns'
import { format, FormatDateEnum, nowInMilliseconds } from 'dates'
import { SPACING_MD, SPACING_XS } from 'design-tokens'
import { useBreakpoints } from 'hooks'
import { useEffect, useState } from 'react'
import { type FoodJournalMood } from '~/types/graphql'
import { CalendarDay } from './CalendarDay'
import { CalendarHeader } from './CalendarHeader'
import { useFoodJournal } from './FoodJournalContext'
import { FoodJournalMoodLegend } from './FoodJournalMoodLegend'

const getNumberOfVisibleDays = ({
  isSmallOnly,
  isMediumOnly,
}: Pick<ReturnType<typeof useBreakpoints>, 'isSmallOnly' | 'isMediumOnly'>) => {
  if (isSmallOnly) {
    return 3
  }

  if (isMediumOnly) {
    return 5
  }

  return 7
}

const getVisibleDays = (date: Date, numberOfVisibleDays: number) => {
  const days: Date[] = []
  const half = Math.floor(numberOfVisibleDays / 2)

  for (let i = -half; i <= half; i++) {
    days.push(addDays(date, i))
  }

  return days
}

const formatDay = format(FormatDateEnum.YEAR_MONTH_DAY)

type Props = {
  moods: Record<string, FoodJournalMood | null>
  onCurrentDayChange: (visibleDays: Date[]) => void
}

export const ScrollableCalendar = ({ moods, onCurrentDayChange }: Props) => {
  const { isSmallOnly, isMediumOnly } = useBreakpoints()
  const { currentDay, setCurrentDay } = useFoodJournal()

  const [numberOfVisibleDays, setNumberOfVisibleDays] = useState(getNumberOfVisibleDays({ isSmallOnly, isMediumOnly }))

  useEffect(() => {
    onCurrentDayChange(getVisibleDays(new Date(currentDay), numberOfVisibleDays))
  }, [onCurrentDayChange, currentDay, numberOfVisibleDays])

  useEffect(() => {
    setNumberOfVisibleDays(getNumberOfVisibleDays({ isSmallOnly, isMediumOnly }))
  }, [isSmallOnly, isMediumOnly])

  const goPrevDay = () => {
    setCurrentDay((date) => subDays(date, 1))
  }

  const goNextDay = () => {
    setCurrentDay((date) => addDays(date, 1))
  }

  const days = getVisibleDays(currentDay, numberOfVisibleDays)

  return (
    <Flex $gap={SPACING_MD}>
      <CalendarHeader currentDate={currentDay} onNext={goNextDay} onPrev={goPrevDay} />

      <Flex $direction="row" $gap={SPACING_XS} $justify="space-between">
        {days.map((day) => {
          const formattedDay = formatDay(day)
          const disabled = isAfter(day, nowInMilliseconds())
          const loading = !disabled && !(formattedDay in moods)

          return (
            <CalendarDay
              key={day.toDateString()}
              day={formattedDay}
              disabled={disabled}
              loading={loading}
              onClick={() => {
                setCurrentDay(day)
              }}
              selected={isSameDay(day, currentDay)}
              type={moods[formattedDay]}
            />
          )
        })}
      </Flex>

      <FoodJournalMoodLegend />
    </Flex>
  )
}
