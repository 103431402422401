import { gql, useQuery } from '@apollo/client'
import { type GetGenderIdentitiesQuery, type GetGenderIdentitiesQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query getGenderIdentities {
    getGenderIdentities {
      id
      name
    }
  }
`

export const useGetGenderIdentities = () => {
  const { data, loading } = useQuery<GetGenderIdentitiesQuery, GetGenderIdentitiesQueryVariables>(QUERY, {
    fetchPolicy: 'cache-first',
  })

  return {
    genderIdentities: data?.getGenderIdentities ?? [],
    getGenderIdentitiesLoading: loading,
  }
}
