import { useParticipantViewContext } from '@stream-io/video-react-sdk'
import { forwardRef, type Ref } from 'react'
import styled from 'styled-components'
import { Avatar } from 'ui'
import { Flex } from 'ui-deprecated'

const VideoPlaceholderContainer = styled(Flex)`
  min-width: 200px;
  min-height: 120px;
  aspect-ratio: 16/9;
`

export const VideoPlaceholder = forwardRef((_props, ref: Ref<HTMLDivElement>) => {
  const participantView = useParticipantViewContext()

  return (
    <VideoPlaceholderContainer ref={ref} align="center" grow={1} justify="center" shrink={1}>
      <Avatar image={participantView?.participant.image} name={participantView?.participant.name} size="lg" />
    </VideoPlaceholderContainer>
  )
})
