import { gql, useQuery } from '@apollo/client'
import { type GetWorkProfessionsQuery, type GetWorkProfessionsQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query getWorkProfessions {
    getWorkProfessions {
      id
      name
    }
  }
`

export const useGetWorkProfessions = () => {
  const { data, loading } = useQuery<GetWorkProfessionsQuery, GetWorkProfessionsQueryVariables>(QUERY, {
    fetchPolicy: 'cache-first',
  })

  return {
    workProfessions: data?.getWorkProfessions ?? [],
    getWorkProfessionsLoading: loading,
  }
}
