import { Flex } from 'cdk'
import { format } from 'date-fns'
import { FormatDateEnum } from 'dates'
import { ArrowLeft, ArrowRight, Icon } from 'icons'
import { Button, Text } from 'ui'

type Props = {
  currentDate: Date
  onNext: VoidFunction
  onPrev: VoidFunction
}

export const CalendarHeader = ({ currentDate, onNext, onPrev }: Props) => (
  <Flex $align="center" $direction="row" $justify="space-between">
    <Flex>
      <Button data-test-id="food-journal-calendar-prev" isRound kind="tertiary" onClick={onPrev}>
        <Icon Svg={ArrowLeft} size={24} />
      </Button>
    </Flex>

    <Text colorName="darker" kind="paragraph-strong">
      {format(currentDate, FormatDateEnum.MONTH_NAME_YEAR)}
    </Text>

    <Flex>
      <Button data-test-id="food-journal-calendar-next" isRound kind="tertiary" onClick={onNext}>
        <Icon Svg={ArrowRight} size={24} />
      </Button>
    </Flex>
  </Flex>
)
