import { CallingState } from '@stream-io/video-react-sdk'
import { Loader, Text } from 'ui'
import { Flex, MaxWidth400px, PositionAbsolute, PositionRelative } from 'ui-deprecated'
import { Opacity } from '~/components/Opacity'
import { Translation } from '~/components/Translation'

type CallingStateAlertProps = {
  callingState: CallingState.OFFLINE | CallingState.RECONNECTING
}

export const CallingStateAlert = ({ callingState }: CallingStateAlertProps) => (
  <PositionAbsolute align="center" bottom="0" direction="row" justify="center" left="0" right="0" top="0" zIndex={1}>
    <PositionAbsolute bottom="0" grow={1} left="0" right="0" shrink={1} top="0">
      <Opacity background="black" grow={1} opacity={0.7} shrink={1} />
    </PositionAbsolute>
    <PositionRelative grow={1} shrink={1}>
      <MaxWidth400px align="center" justify="center" px={24}>
        <Loader colorName="primary-30" size={200} />
        <Flex pt={48}>
          <Text colorName="white" kind="h2" textAlign="center">
            {callingState === CallingState.OFFLINE && <Translation id="videocall.offline" />}
            {callingState === CallingState.RECONNECTING && <Translation id="videocall.reconnecting" />}
          </Text>
        </Flex>
      </MaxWidth400px>
    </PositionRelative>
  </PositionAbsolute>
)
