import * as Sentry from '@sentry/nextjs'
import { isObject } from 'functions'
import { getSessionStorage } from '~/utils/sessionStorage/getSessionStorage'

const PARTNERSHIPS: Record<string, string> = {
  pwc: 'PwC Italia',
}

export const usePartnershipQueryParam = () => {
  const queryParam = getSessionStorage('query-params')

  if (queryParam == null) {
    return null
  }

  try {
    const parsedQueryParams = JSON.parse(queryParam)

    if (!isObject(parsedQueryParams)) {
      return null
    }

    if (typeof parsedQueryParams.srns_partnership === 'string') {
      const partnershipKey = parsedQueryParams.srns_partnership
      return PARTNERSHIPS[partnershipKey] ?? null
    }

    if (typeof parsedQueryParams.firstLandingPage !== 'string') {
      return null
    }

    const [, partnership] = parsedQueryParams.firstLandingPage.match(/partnership\/([^/]+)$/) ?? []

    return partnership ?? null
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        type: 'library',
        library: 'url-parser',
      },
    })

    return null
  }
}
