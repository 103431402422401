import { StreamTheme, useCall } from '@stream-io/video-react-sdk'
import '@stream-io/video-react-sdk/dist/css/styles.css'
import { noop } from 'functions'
import { useCallback, useEffect, useState } from 'react'
import { type Channel as ChannelType } from 'stream-chat'
import { Chat as StreamChat } from 'stream-chat-react'
import { Flex, OverflowHidden, PositionRelative } from 'ui-deprecated'
import { useSound } from 'use-sound'
import { ChatChannel } from '~/domains/chat/components/ChatChannel'
import { ChatStyle } from '~/domains/chat/components/ChatStyle'
import { useChat } from '~/domains/chat/hooks/useChat'
import { useTherapySessionLive, useVideocallSidePanel } from '~/domains/videocall'
import { useCurrentUserNullable } from '~/hooks/useCurrentUser'
import { PageTrackEffect } from '~/routes/PageTrackEffect'
import { SidePanelActions } from '../../VideocallSidePanel/SidePanelActions'
import { SidePanelContent } from '../../VideocallSidePanel/SidePanelContent'
import { VideocallControls } from '../components/VideocallControls'
import { VideocallLayout } from './VideocallLayout'

const MessageStatus = () => null

export const Live = () => {
  const { id } = useCurrentUserNullable()
  const { patient, therapist } = useTherapySessionLive()
  const [channel, setChannel] = useState<ChannelType>()
  const { client, i18n } = useChat()
  const { isChatOpen, isPatientDetailOpen } = useVideocallSidePanel()
  const call = useCall()
  const [playJoin] = useSound('/sounds/join.mp3', { volume: 0.5 })
  const [playLeft] = useSound('/sounds/left.mp3', { volume: 0.5 })
  const [playNewMessage] = useSound('/sounds/new-message.mp3', { volume: 0.5 })

  const getChannel = useCallback(async () => {
    const channel = client.getChannelByMembers('messaging', {
      members: [patient.id, therapist.id],
    })

    await channel.watch()

    setChannel(channel)
  }, [client, patient.id, therapist.id])

  useEffect(() => {
    if (client.user) {
      getChannel().then(() => {
        channel?.on('message.new', (e) => {
          if (e.user?.id !== id) {
            playNewMessage()
          }
        })
      })
    }

    return () => {
      channel?.off('message.new', noop)
    }
  }, [getChannel, client, channel, playNewMessage, id])

  useEffect(() => {
    if (!call) {
      return
    }

    call.on('call.session_participant_joined', () => {
      playJoin()
    })

    call.on('call.session_participant_left', () => {
      playLeft()
    })

    return () => {
      call.off('call.session_participant_joined', noop)
      call.off('call.session_participant_left', noop)
    }
  }, [call, playJoin, playLeft])

  if (!client.user) {
    return (
      <>
        <PageTrackEffect id="therapy-sessions.live.succeeded" />

        <OverflowHidden background="black" direction="row" grow={1} shrink={1}>
          <Flex grow={1} hide={isChatOpen || isPatientDetailOpen} mdHide={false} shrink={1}>
            <PositionRelative
              align="center"
              direction="row"
              justify="flex-end"
              pr={isChatOpen || isPatientDetailOpen ? 0 : 24}
              px={24}
              py={16}
              zIndex={1}
            >
              <SidePanelActions />
            </PositionRelative>

            <Flex grow={1} justify="center" shrink={1}>
              <StreamTheme>
                <VideocallLayout />
                <VideocallControls />
              </StreamTheme>
            </Flex>
          </Flex>
          <SidePanelContent />
        </OverflowHidden>
      </>
    )
  }

  return (
    <>
      <PageTrackEffect id="therapy-sessions.live.succeeded" />
      <StreamChat client={client} i18nInstance={i18n}>
        <ChatStyle />
        <ChatChannel MessageStatus={MessageStatus} channel={channel}>
          <OverflowHidden background="black" direction="row" grow={1} shrink={1}>
            <Flex grow={1} hide={isChatOpen || isPatientDetailOpen} mdHide={false} shrink={1}>
              <PositionRelative
                align="center"
                direction="row"
                justify="flex-end"
                pr={isChatOpen || isPatientDetailOpen ? 0 : 24}
                px={24}
                py={16}
                zIndex={1}
              >
                <SidePanelActions />
              </PositionRelative>

              <Flex grow={1} justify="center" shrink={1}>
                <StreamTheme>
                  <VideocallLayout />
                  <VideocallControls />
                </StreamTheme>
              </Flex>
            </Flex>
            <SidePanelContent />
          </OverflowHidden>
        </ChatChannel>
      </StreamChat>
    </>
  )
}
