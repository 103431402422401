import { gql, useQuery } from '@apollo/client'
import { isNeitherNullNorUndefined } from 'functions'
import { useMemo } from 'react'
import { type PatientTherapiesQuery, type PatientTherapiesQueryVariables } from '~/types/graphql'

export const PATIENT_THERAPIES_QUERY = gql`
  query patientTherapies {
    therapies {
      changeTherapistReason
      id
      isUnderage
      patient {
        id
      }
      therapist {
        firstName
        fullName
        id
        profileImage {
          s
          m
          l
        }
      }
      therapyPath {
        cost
        costFirst
        duration
        id
        type
      }
      withTherapyBundle
      therapySessionsBookedCount
    }
  }
`

export const usePatientTherapiesQuery = () => {
  const { data, error, loading, refetch, updateQuery } = useQuery<
    PatientTherapiesQuery,
    PatientTherapiesQueryVariables
  >(PATIENT_THERAPIES_QUERY)

  const { therapies, therapists } = useMemo(() => {
    const therapies = data?.therapies ?? []

    return {
      therapies,
      therapists: therapies.map((therapy) => therapy.therapist).filter(isNeitherNullNorUndefined),
    }
  }, [data?.therapies])

  return {
    error: !!error,
    loading,
    therapies,
    therapists,
    refetch,
    updateQuery,
  }
}
