import { OverflowAuto, Pressable } from 'cdk'
import { useCallback } from 'react'
import { Modal, ModalDescription, ModalProvider, ModalTitle, ModalTrigger, Text, Tile, useModalContext } from 'ui'
import { Translation } from '~/components/Translation'
import { useIntercom } from '~/domains/intercom/hooks/useIntercom'
import { useTranslation } from '~/i18n/hooks/useTranslation'

const videocallIssues = ['audio', 'videocamera', 'stucking', 'dropping', 'joining'] as const

type OpenSupportItemProps = {
  kind: (typeof videocallIssues)[number]
}

const OpenSupportItem = ({ kind }: OpenSupportItemProps) => {
  const { openWithMessage, update } = useIntercom()
  const issue = useTranslation(`videocall.support.item.${kind}`)
  const { close } = useModalContext()

  const openIntercom = useCallback(() => {
    update({
      customAttributes: {
        srns_videocall_issue: true,
      },
    })

    openWithMessage('videocall.support.intercomMessage', { issue })

    close()
  }, [close, issue, openWithMessage, update])

  return (
    <Pressable onClick={openIntercom}>
      <Tile>
        <Text fontWeight="600">{issue}</Text>
      </Tile>
    </Pressable>
  )
}

export const OpenSupport = () => (
  <ModalProvider>
    <ModalTrigger>
      <Pressable>
        <Text colorName="white" fontWeight="600">
          <Translation id="videocall.support" />
        </Text>
      </Pressable>
    </ModalTrigger>
    <Modal>
      <OverflowAuto $gap={8}>
        <ModalTitle>
          <Translation id="videocall.support.title" />
        </ModalTitle>
        <ModalDescription>
          <Translation id="videocall.support.description" />
        </ModalDescription>
        {videocallIssues.map((videocallIssue) => (
          <OpenSupportItem key={videocallIssue} kind={videocallIssue} />
        ))}
      </OverflowAuto>
    </Modal>
  </ModalProvider>
)
