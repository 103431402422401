import { MemoryRouter, Route, Switch } from 'react-router-dom'
import { OverflowAuto } from 'ui-deprecated'
import { PatientDetailCalendar } from '~/components/patientDetail/PatientDetailCalendar'
import { useTherapySessionLive } from '~/domains/videocall'
import { EditDiagnosisVideocall } from '../EditDiagnosisVideocall'
import { PatientDetailRoot } from './PatientDetailRoot'

export const VideocallPatientDetail = () => {
  const { patient } = useTherapySessionLive()

  return (
    <OverflowAuto background="white" mdBorderRadius={16} pb={16}>
      <MemoryRouter initialEntries={['/detail']}>
        <Switch>
          <Route path="/detail/therapy-session/:therapyId/schedule">
            <PatientDetailCalendar patientId={patient.id} />
          </Route>

          <Route path="/diagnosis/edit">
            <EditDiagnosisVideocall patientId={patient.id} />
          </Route>

          <Route>
            <PatientDetailRoot />
          </Route>
        </Switch>
      </MemoryRouter>
    </OverflowAuto>
  )
}
