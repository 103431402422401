import { CompositeButton, DeviceSelectorAudioOutput, useCallStateHooks } from '@stream-io/video-react-sdk'
import { Icon, SoundHigh, SoundOff } from 'icons'
import { type PropsWithChildren, useCallback, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Text } from 'ui'
import { Tooltip, TooltipContent, TooltipTrigger } from '~/components/FloatingUI/Tooltip'
import { Translation } from '~/components/Translation'

/**
 * Recreated Toggle Speaker Button as suggested by Stream dev Oliver Lazoroski
 * @see https://github.com/GetStream/stream-video-js/blob/d61525733e87edec520e610d3e94c957eab207a0/packages/react-sdk/src/components/CallControls/ToggleAudioOutputButton.tsx
 */
const ToggleAudioOutputButton = ({ children }: PropsWithChildren) => {
  const { useSpeakerState } = useCallStateHooks()
  const { devices, speaker } = useSpeakerState()

  return (
    <CompositeButton
      Menu={devices && devices?.length > 1 ? DeviceSelectorAudioOutput : undefined}
      active={speaker.state.volume === 0}
    >
      {children}
    </CompositeButton>
  )
}

export const SpeakerControl = () => {
  const { useSpeakerState } = useCallStateHooks()
  const { speaker } = useSpeakerState()

  const [isSpeakerActive, setIsSpeakerActive] = useState(true)

  const handleClick = useCallback(() => {
    speaker.setVolume(isSpeakerActive ? 0 : 1)

    setIsSpeakerActive(!isSpeakerActive)
  }, [isSpeakerActive, speaker])

  useHotkeys('ctrl+c', handleClick)

  return (
    <ToggleAudioOutputButton>
      <Tooltip kind="title" placement="top">
        <TooltipTrigger onClick={handleClick}>
          <Icon Svg={isSpeakerActive ? SoundHigh : SoundOff} colorName="white" size={24} />
        </TooltipTrigger>
        <TooltipContent style={{ marginTop: '-4px' }}>
          <Text fontWeight="400" kind="caption">
            <Translation id={isSpeakerActive ? 'videocall.speaker.off' : 'videocall.speaker.on'} />
          </Text>
        </TooltipContent>
      </Tooltip>
    </ToggleAudioOutputButton>
  )
}
