import { Flex, OverflowAuto, Space } from 'cdk'
import { toCurrencyIntOrDecimal } from 'numbers'
import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Alert, Button, ModalDescription, ModalTitle, Skeleton, Suggestion } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useTrackEventView } from '~/domains/analytics/hooks/useTrackEventView'
import { usePatientTherapiesQuery } from '~/domains/patient/hooks/usePatientTherapiesQuery'
import { useAvailableChangeTherapistsFree } from '../hooks/useAvailableChangeTherapistsFree'
import { getChangeTherapistModalRoutes } from '../utils/routes'

type Props = {
  therapyId: string
}

export const ChangeTherapistModalInfo = ({ therapyId }: Props) => {
  const { availableChangeTherapistsFree, error, loading } = useAvailableChangeTherapistsFree()
  const { therapies } = usePatientTherapiesQuery()
  const history = useHistory()

  const therapy = useMemo(() => therapies.find((therapy) => therapy.id === therapyId), [therapies, therapyId])
  const hasError = availableChangeTherapistsFree == null || error == null || therapy == null

  const handleOnClick = useCallback(() => {
    history.push(getChangeTherapistModalRoutes('/changeTherapistReasonAuto'))
  }, [history])

  useTrackEventView('changeTherapist.modal.info')

  return (
    <>
      <OverflowAuto $gap={8}>
        <ModalTitle kind="h1">
          <Translation id="changeTherapist.modal.info.title" />
        </ModalTitle>
        <ModalDescription kind="paragraph">
          <Translation id="changeTherapist.modal.info.body.1" />
          <Space />
          😊
          <Translation id="changeTherapist.modal.info.body.2" />
        </ModalDescription>
        {loading ? (
          <Suggestion kind="info">
            <Flex $gap={8}>
              <Skeleton $active $backgroundColorName="primary-30" />
              <Skeleton $active $backgroundColorName="primary-30" />
              <Skeleton $active $backgroundColorName="primary-30" />
              <Skeleton $active $backgroundColorName="primary-30" $maxWidth="40%" />
            </Flex>
          </Suggestion>
        ) : hasError ? (
          <Alert endChildren={<></>} kind="error" startChildren={<></>}>
            <Translation id="generic.error" />
          </Alert>
        ) : (
          <Suggestion kind="info">
            {availableChangeTherapistsFree > 0 ? (
              <TranslationMarkdown
                colorName="darker"
                id="changeTherapist.modal.info.toast.free"
                kind="paragraph"
                values={{ therapySessionCost: toCurrencyIntOrDecimal(therapy.therapyPath.cost) }}
              />
            ) : (
              <TranslationMarkdown
                colorName="darker"
                id="changeTherapist.modal.info.toast.payed"
                kind="paragraph"
                values={{ therapySessionCost: toCurrencyIntOrDecimal(therapy.therapyPath.cost) }}
              />
            )}
          </Suggestion>
        )}
      </OverflowAuto>
      <Flex $pt={16}>
        <Button disabled={hasError || loading} kind="primary" onClick={handleOnClick}>
          <Translation id="actions.confirm" />
        </Button>
      </Flex>
    </>
  )
}
