import { Flex } from 'cdk'
import { SPACING_4XS, SPACING_SM } from 'design-tokens'
import { CreditCard, Icon } from 'icons'
import { matchPath, useLocation } from 'react-router-dom'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { MenuLink } from '../common/MenuLink'

const to = '/settings/payments'

export const MenuItemPayments = () => {
  const { pathname } = useLocation()
  const active = !!matchPath(pathname, { path: to })
  const { isVariant1 } = useFeatureFlagsByUserId()

  return (
    <MenuLink dataTestId="menu-payments" to={to}>
      <Flex $pr={SPACING_SM}>
        <Icon Svg={CreditCard} colorName="neutral-60" size={24} />
      </Flex>

      <Flex $grow={1} $pt={SPACING_4XS} $shrink={1}>
        <Text colorName={active ? 'primary' : undefined} kind="paragraph">
          <Translation id={isVariant1('ff_new_patient_settings_payments') ? 'menu.payments.method' : 'menu.payments'} />
        </Text>
      </Flex>
    </MenuLink>
  )
}
