import { secondsToMilliseconds, secondsToMinutes } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { createContext, type ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { useModals } from '~/domains/modals'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useDataLayer } from '~/hooks/useDataLayer'
import { useToasts } from '~/hooks/useToasts'
import { type ActionResponse, type MeQuery } from '~/types/graphql'
import { getEnv } from '~/utils/getEnv'
import { isGraphQLResponse } from '~/utils/graphql'
import { hash } from '~/utils/hash'
import { PhoneNumberAlreadyInUseException } from '../exceptions/phoneNumberAlreadyInUse'
import { UnableToParsePhoneNumberWithPrefixException } from '../exceptions/unableToParsePhoneNumberWithPrefix'
import { UnableToSendVerificationCodeException } from '../exceptions/unableToSendVerificationCode'
import { ensureMobilePhoneNumberWithPrefix } from '../utils/ensureMobilePhoneNumberWithPrefix'
import { useCheckVerificationCode } from './useCheckVerificationCode'
import { useSendVerificationCode } from './useSendVerificationCode'

type Context = {
  checkVerificationCode: (code: string, shouldShowSuccessToast?: boolean) => Promise<ActionResponse>
  countdown: number
  loading: boolean
  sending: boolean
  sendVerificationCode: (phone: string, shouldShowSuccessToast?: boolean) => Promise<ActionResponse>
}

type Props = {
  children: ReactNode
}

const PhoneVerificationContext = createContext<Context | null>(null)

const waitToResetAttemptsInSeconds = 180

const waitForNextAttemptInSeconds = 30

let countdownTimeout: undefined | number

export const PhoneVerificationContextProvider = ({ children }: Props) => {
  const [sending, setSending] = useState(false)
  const [countdown, setCountdown] = useState(0)
  const [attempts, setAttempts] = useState(0)
  const [checkVerificationCode] = useCheckVerificationCode()
  const [loading, setLoading] = useState(false)
  const [sendVerificationCode] = useSendVerificationCode()
  const { addToast } = useToasts()
  const { close } = useModals()
  const { id, phoneNumber, updateQuery } = useCurrentUser()
  const { add } = useDataLayer()

  useEffect(() => {
    if (countdown === 0) {
      window.clearTimeout(countdownTimeout)

      return
    }

    countdownTimeout = window.setTimeout(
      () => {
        setCountdown(countdown - 1)
      },
      pipe(1, secondsToMilliseconds),
    )
  }, [countdown])

  useEffect(() => {
    if (sending) {
      window.setTimeout(
        () => {
          setSending(false)
        },
        pipe(waitForNextAttemptInSeconds, secondsToMilliseconds),
      )

      setCountdown(waitForNextAttemptInSeconds)
    }
  }, [sending])

  const handleSendVerificationCode = useCallback(
    async (value: string, shouldShowSuccessToast = true) => {
      if (sending) {
        return { ok: false }
      }

      setLoading(true)

      if (attempts >= pipe('SERENIS_PHONE_NUMBER_VERIFICATION_MAX_ATTEMPTS', getEnv, Number)) {
        addToast({
          type: 'alert',
          translationId: 'phoneNumbers.phoneVerification.send.waitToRetry',
          translationValues: { count: pipe(waitToResetAttemptsInSeconds, secondsToMinutes) },
        })

        setTimeout(
          () => {
            setAttempts(0)
          },
          pipe(waitToResetAttemptsInSeconds, secondsToMilliseconds),
        )

        setLoading(false)

        return { ok: false }
      }

      try {
        const phone = ensureMobilePhoneNumberWithPrefix(value)

        if (!phone) {
          throw new UnableToParsePhoneNumberWithPrefixException(value)
        }

        const { data } = await sendVerificationCode({
          variables: {
            input: {
              phoneNumber: phone,
            },
          },
        })

        if (isGraphQLResponse(data?.sendVerificationCode, 'PhoneNumberAlreadyVerifiedErrorResponse')) {
          throw new PhoneNumberAlreadyInUseException(phone)
        }

        if (!data?.sendVerificationCode?.ok) {
          throw new UnableToSendVerificationCodeException(phone)
        }

        setSending(true)

        setAttempts((a) => a + 1)

        updateQuery((state) => ({
          ...state,
          me: {
            ...(state.me as unknown as NonNullable<MeQuery['me']>),
            phoneNumber: phone,
            phoneNumberVerifiedAt: null,
          },
        }))

        setLoading(false)

        if (shouldShowSuccessToast) {
          addToast({
            type: 'success',
            translationId: 'phoneNumbers.phoneVerification.send.success',
            translationValues: { phoneNumber: phone },
          })
        }

        return data.sendVerificationCode
      } catch (error) {
        addToast({
          type: 'alert',
          translationId:
            error instanceof PhoneNumberAlreadyInUseException
              ? 'phoneNumbers.phoneVerification.send.failure'
              : 'generic.errorRetryLater',
        })

        setLoading(false)

        setSending(false)

        return { ok: false }
      }
    },
    [sending, attempts, addToast, sendVerificationCode, updateQuery],
  )

  const handleCheckVerificationCode = useCallback(
    async (code: string, shouldShowSuccessToast = true) => {
      if (loading || code.length !== 4) {
        return { ok: false }
      }

      setLoading(true)

      try {
        const { data } = await checkVerificationCode({
          variables: {
            input: {
              code,
            },
          },
        })

        if (!data?.checkVerificationCode?.ok) {
          setLoading(false)

          addToast({ type: 'alert', translationId: 'phoneNumbers.phoneVerification.check.failure' })

          return { ok: false }
        }

        updateQuery((state) => ({
          ...state,
          me: {
            ...(state.me as unknown as NonNullable<MeQuery['me']>),
            phoneNumberVerifiedAt: new Date(),
          },
        }))

        if (shouldShowSuccessToast) {
          addToast({ type: 'success', translationId: 'phoneNumbers.phoneVerification.check.success' })
        }

        const userPhoneNumber = phoneNumber ? hash(phoneNumber) : null

        if (userPhoneNumber != null) {
          add(
            {
              userPhoneNumber,
              userId: id,
            },
            'user-phone-number',
          )
        }

        setLoading(false)

        setSending(false)

        close('phoneVerification')

        return { ok: true }
      } catch (error) {
        setLoading(false)

        return { ok: false }
      }
    },
    [add, addToast, checkVerificationCode, close, id, loading, phoneNumber, updateQuery],
  )

  const context = {
    checkVerificationCode: handleCheckVerificationCode,
    countdown,
    loading,
    sending,
    sendVerificationCode: handleSendVerificationCode,
  }

  return <PhoneVerificationContext.Provider value={context}>{children}</PhoneVerificationContext.Provider>
}

export const usePhoneVerificationContext = () => {
  const context = useContext(PhoneVerificationContext)

  if (!context) {
    throw new Error('The `usePhoneVerificationContext` should be wrapped with `PhoneVerificationContextProvider`.')
  }

  return context
}
