import { parseISO } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { useMemo } from 'react'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useChatMessage } from '~/domains/chat/hooks/useChatMessage'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { getTherapySessionDayAndMonthLong } from '~/utils/dates/getTherapySessionDayAndMonth'
import { CustomMessageWrapper } from './CustomMessageWrapper'

const NoShowTherapist = () => {
  const { message } = useChatMessage<'SYSTEM_SESSION_DELETED_NO_SHOW'>()

  const startAt = message.srns?.payload.startAt

  if (!startAt) {
    return null
  }

  return (
    <>
      <Flex pb={8}>
        <Text fontWeight="600" kind="caption">
          <Translation id="systemMessage.therapist.noShow.title" />
        </Text>
      </Flex>
      <TranslationMarkdown
        id="systemMessage.therapist.noShow.body"
        kind="caption"
        values={{
          therapySessionsStartAtDay: pipe(startAt, parseISO, getTherapySessionDayAndMonthLong),
        }}
      />
    </>
  )
}

const NoShowPatient = () => {
  const { message } = useChatMessage<'SYSTEM_SESSION_DELETED_NO_SHOW'>()

  const startAt = message.srns?.payload.startAt
  const therapistId = message.srns?.payload.therapistId

  const { therapies } = usePatientTherapies()

  const therapy = useMemo(
    () => therapies.find((therapy) => therapy.therapist?.id === therapistId),
    [therapies, therapistId],
  )

  if (!startAt || !therapy?.therapist) {
    return null
  }

  return (
    <>
      <Flex pb={8}>
        <Text fontWeight="600" kind="caption">
          <Translation id="systemMessage.patient.noShow.title" />
        </Text>
      </Flex>
      <TranslationMarkdown
        id="systemMessage.patient.noShow.body"
        kind="caption"
        values={{
          therapistFullName: therapy.therapist.fullName,
          therapySessionsStartAtDay: pipe(startAt, parseISO, getTherapySessionDayAndMonthLong),
        }}
      />
    </>
  )
}

export const MessageSessionDeletedNoShow = () => {
  const group = useUserActingAsGroup()

  return <CustomMessageWrapper>{group === 'therapist' ? <NoShowTherapist /> : <NoShowPatient />}</CustomMessageWrapper>
}
