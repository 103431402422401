import { gql, useMutation } from '@apollo/client'
import {
  type BookTherapySessionByParentMutation,
  type BookTherapySessionByParentMutationVariables,
} from '~/types/graphql'

const BOOK_THERAPY_SESSION_BY_PARENT = gql`
  mutation bookTherapySessionByParent($input: BookTherapySessionByParentInput!) {
    bookTherapySessionByParent(input: $input) {
      cost
      endAt
      endAtSlot
      id
      patient {
        id
        firstName
        fullName
      }
      startAt
      status
      typeCost
    }
  }
`

export const useBookTherapySessionByParent = () =>
  useMutation<BookTherapySessionByParentMutation, BookTherapySessionByParentMutationVariables>(
    BOOK_THERAPY_SESSION_BY_PARENT,
  )
