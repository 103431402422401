import { Flex, Form } from 'cdk'
import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Card, DrawerFooter, DrawerHeader, DrawerTitle } from 'ui'
import { Translation } from '~/components/Translation'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { ReactHookFormRadioFieldVariant } from '~/domains/react-hook-form/components/ReactHookFormRadioFieldVariant'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useRootHistory } from '~/hooks/useRootHistory'
import { type TranslationId } from '~/i18n/types'
import { type DeletedReason } from '~/types/graphql'
import { isBeforeCancellationPolicyLimit } from '~/utils/dates/isBeforeCancellationPolicyLimit'
import { getRoute } from '~/utils/getRoute'
import { useManageSession } from '../hooks/useManageSession'
import { type ManageSessionDeleteFormValues } from '../types'
import { getManageSessionRoutes } from '../utils/getManageSessionRoutes'

type DeleteReason = {
  reason: Extract<TranslationId, `therapySession.deleteReason.patient.${string}`>
  alt?: Extract<TranslationId, Extract<TranslationId, `therapySession.deleteReason.patient.${string}.alt`>>
  altCta?: Extract<TranslationId, 'actions.open.chat' | 'actions.rescheduleAppointment'>
  value: Extract<DeletedReason, 'SCHEDULING_ISSUE' | 'PENDING_RESCHEDULE' | 'NOT_INTERESTED' | 'CHURN'>
}

const DELETE_REASONS: DeleteReason[] = [
  {
    reason: 'therapySession.deleteReason.patient.schedulingIssue',
    alt: 'therapySession.deleteReason.patient.schedulingIssue.alt',
    altCta: 'actions.open.chat',
    value: 'SCHEDULING_ISSUE',
  },
  {
    reason: 'therapySession.deleteReason.patient.pendingReschedule',
    alt: 'therapySession.deleteReason.patient.pendingReschedule.alt',
    altCta: 'actions.rescheduleAppointment',
    value: 'PENDING_RESCHEDULE',
  },
  {
    reason: 'therapySession.deleteReason.patient.notInterested',
    alt: 'therapySession.deleteReason.patient.notInterested.alt',
    altCta: 'actions.rescheduleAppointment',
    value: 'NOT_INTERESTED',
  },
  {
    reason: 'therapySession.deleteReason.patient.churn',
    value: 'CHURN',
  },
]

const useHandleAlternativeCTA = () => {
  const { therapistId } = useManageSession()
  const history = useHistory()
  const rootHistory = useRootHistory()
  const { watch } = useReactHookFormContext<ManageSessionDeleteFormValues>()

  const deletedReason = watch('deletedReason')

  useEffect(() => {
    if (deletedReason === 'CHURN') {
      history.push(getManageSessionRoutes('/delete-churn'))
    }
  }, [deletedReason, history])

  return useCallback(() => {
    if (deletedReason === 'NOT_INTERESTED' || deletedReason === 'PENDING_RESCHEDULE') {
      history.goBack()
    }

    if (deletedReason === 'SCHEDULING_ISSUE') {
      rootHistory.push(getRoute(`/chat/${therapistId}`))
    }
  }, [deletedReason, history, rootHistory, therapistId])
}

export const DeleteSession = () => {
  const { errors } = useFormTranslations()
  const { therapistName, startAt } = useManageSession()
  const { formState, handleSubmit, watch } = useReactHookFormContext<ManageSessionDeleteFormValues>()
  const handleAlternativeCTA = useHandleAlternativeCTA()
  const history = useHistory()

  const deletedReason = watch('deletedReason')
  const showAlternativeCTA = isBeforeCancellationPolicyLimit(startAt)

  return (
    <Form
      $gap={16}
      $grow={1}
      $shrink={1}
      onSubmit={handleSubmit(() => {
        history.push(getManageSessionRoutes('/delete-message'))
      })}
    >
      <DrawerHeader />
      <Flex $gap={8}>
        <DrawerTitle>
          <Translation id="therapySession.deleteReason.patient.title" />
        </DrawerTitle>
        <Translation id="actions.pickAnOption" />
      </Flex>
      <Flex $gap={8}>
        {DELETE_REASONS.map(({ reason, value, altCta, alt }) => (
          <Flex key={value} $gap={8}>
            <ReactHookFormRadioFieldVariant name="deletedReason" rules={{ required: errors.required }} value={value}>
              <Translation id={reason} />
            </ReactHookFormRadioFieldVariant>
            {showAlternativeCTA && alt != null && altCta != null && deletedReason === value && value !== 'CHURN' && (
              <Card $backgroundColorName="neutral-20" $elevationName="0" $gap={8}>
                <Translation id={alt} values={{ therapistFirstName: therapistName }} />
                <Button kind="primary" onClick={handleAlternativeCTA}>
                  <Translation id={altCta} />
                </Button>
              </Card>
            )}
          </Flex>
        ))}
      </Flex>
      <DrawerFooter>
        <Flex $gap={8} $grow={1} $mdDirection="row-reverse" $shrink={1}>
          <Flex $basis="50%" $grow={1} $shrink={1}>
            <Button
              data-test-id="therapy-session-delete-proceed-button"
              disabled={!formState.isValid}
              kind="primary"
              type="submit"
            >
              <Translation id="actions.proceed" />
            </Button>
          </Flex>
          <Flex $basis="50%" $grow={1} $shrink={1}>
            <Button
              data-test-id="therapy-session-delete-go-back-button"
              isGhost
              kind="primary"
              onClick={history.goBack}
            >
              <Translation id="actions.back" />
            </Button>
          </Flex>
        </Flex>
      </DrawerFooter>
    </Form>
  )
}
