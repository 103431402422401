import { Flex, PositionRelative } from 'cdk'
import { SPACING_SM } from 'design-tokens'
import { IconSerenisShippingBox } from 'icons'
import { memo, useCallback } from 'react'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'
import { MenuAction } from '../common/MenuAction'
import { UnreadDot } from '../common/UnreadDot'

export const MenuItemChangelog = memo(() => {
  const handleClick = useCallback(() => {
    if (!(typeof window.Featurebase === 'function')) {
      return
    }

    setLocalStorage('unread-dot-changelog-seen', 'true')

    window.Featurebase('manually_open_changelog_popup')
  }, [])

  const unreadDotChangelogSeen = getLocalStorage('unread-dot-changelog-seen') === 'true'

  return (
    <MenuAction onClick={handleClick}>
      <Flex $pr={SPACING_SM}>
        <PositionRelative>
          <IconSerenisShippingBox colorName="neutral-60" size={24} />
          {!unreadDotChangelogSeen && <UnreadDot $left={0} />}
        </PositionRelative>
      </Flex>

      <Flex $align="flex-start" $grow={1} $shrink={1}>
        <Text kind="paragraph">
          <Translation id="menu.help.changelog" />
        </Text>
      </Flex>
    </MenuAction>
  )
})

MenuItemChangelog.displayName = 'MenuItemChangelog'
