import { Flex } from 'cdk'
import { SPACING_LG, SPACING_MD } from 'design-tokens'
import { Card } from 'ui'
import { useTherapist } from '~/hooks/useTherapist'
import { useTherapistProfessionTypeValue } from '~/hooks/useTherapistProfessionTypeValue'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { getTherapistProfessionTranslationKey } from '~/utils/getTherapistProfessionTranslationKey'
import { TherapistCardAvatar } from './TherapistCardAvatar'
import { TherapistCardDescription } from './TherapistCardDescription'
import { TherapistCardFree } from './TherapistCardFree'
import { TherapistCardSession } from './TherapistCardSession'

type TherapistCardProps = {
  free?: boolean
  endAt: Date
  startAt: Date
  therapyPathType: TherapyTherapyPathType
}

export const TherapistCard = ({ free, ...props }: TherapistCardProps) => {
  const { fullName, therapist } = useTherapist()
  const { isNutritionist, isPsychiatrist } = useTherapistProfessionTypeValue()

  const titleId = getTherapistProfessionTranslationKey(props.therapyPathType ?? 'MYSELF_PSYCHOTHERAPY', therapist.sex, {
    isNutritionist,
    isPsychiatrist,
  })

  return (
    <Card $direction="column" $gap={SPACING_LG} $justify="space-between" $overflow="hidden">
      <Flex $direction="row" $justify="space-between">
        <TherapistCardSession {...props} />
        {free && <TherapistCardFree />}
      </Flex>
      <Flex $direction="row" $gap={SPACING_MD} $justify="space-between">
        <TherapistCardDescription fullName={fullName} titleId={titleId} />
        <TherapistCardAvatar image={therapist.profileImage?.s} name={fullName} />
      </Flex>
    </Card>
  )
}
