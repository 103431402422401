import { Redirect, useParams } from 'react-router-dom'
import { usePatientTherapiesQuery } from '~/domains/patient/hooks/usePatientTherapiesQuery'
import { getRoute } from '~/utils/getRoute'
import { type Page } from '../types'
import { type PathsParams } from '.'

export const PathsGuard: Page['PageGuard'] = ({ children }) => {
  const { therapyId } = useParams<PathsParams>()
  const { therapies, loading, error } = usePatientTherapiesQuery()

  if (therapyId == null && therapies.length === 0 && !loading) {
    return <Redirect to={getRoute('/paths/new')} />
  }

  if (therapyId == null && therapies.length > 0 && !loading && !error) {
    return <Redirect to={getRoute(`/paths/${therapies.at(0)?.id}`)} />
  }

  return children
}
