import { noop } from 'functions'
import { createContext, type ReactNode, useMemo } from 'react'
import { type UpdateDiagnosisParam, useUpdateDiagnosis } from '~/domains/diagnosis/mutations/updateDiagnosis'
import { useGetCountries } from '~/domains/diagnosis/queries/getCountries'
import { useGetDiagnosablePathologies } from '~/domains/diagnosis/queries/getDiagnosablePathologies'
import { useGetDiagnosisByPatientId } from '~/domains/diagnosis/queries/getDiagnosisByPatientId'
import { useGetGenderIdentities } from '~/domains/diagnosis/queries/getGenderIdentities'
import { useGetLanguages } from '~/domains/diagnosis/queries/getLanguages'
import {
  type GetCountriesQuery,
  type GetDiagnosablePathologiesQuery,
  type GetDiagnosisByPatientIdQuery,
  type GetGenderIdentitiesQuery,
  type GetLanguagesQuery,
  type GetWorkProfessionsQuery,
  type PathologyType,
  type TherapyTherapyPathType,
} from '~/types/graphql'
import { useGetWorkProfessions } from '../queries/getWorkProfessions'

const THERAPY_PATH_BY_PATHOLOGY_TYPE: Record<TherapyTherapyPathType, PathologyType | null> = {
  COUPLES_PSYCHOTHERAPY: 'COUPLES',
  MYSELF_PSYCHIATRY: null,
  MYSELF_PSYCHOTHERAPY: null,
  PATH_ASSERTIVE_COMMUNICATION: null,
  PATH_COACHING: null,
  PATH_NUTRITION_DCA: 'NUTRITION',
  PATH_NUTRITION_WEIGHT_LOSS: 'NUTRITION',
  PATH_SEXOLOGY: null,
  PATH_SLEEP: null,
}

type Context = {
  countries: NonNullable<GetCountriesQuery['getCountries']>
  diagnosis: GetDiagnosisByPatientIdQuery['getDiagnosisByPatientId']
  error: boolean
  genderIdentities: NonNullable<GetGenderIdentitiesQuery['getGenderIdentities']>
  languages: NonNullable<GetLanguagesQuery['getLanguages']>
  loading: boolean
  pathologies: NonNullable<GetDiagnosablePathologiesQuery['getDiagnosablePathologies']>
  patientId: string
  updateDiagnosis: (values: UpdateDiagnosisParam) => Promise<{ ok: boolean }>
  workProfessions: NonNullable<GetWorkProfessionsQuery['getWorkProfessions']>
  isEditable: boolean
  therapyPathType: TherapyTherapyPathType
}

const defaultContext: Omit<Context, 'error' | 'loading' | 'patientId'> = {
  countries: [],
  diagnosis: null,
  genderIdentities: [],
  languages: [],
  pathologies: [],
  updateDiagnosis: noop,
  workProfessions: [],
  isEditable: true,
  therapyPathType: 'MYSELF_PSYCHOTHERAPY',
}

export const DiagnosisContext = createContext<Context | null>(null)

type Props = {
  children: ReactNode
  patientId: string
  isEditable?: boolean
  therapyPathType: TherapyTherapyPathType
}

export const DiagnosisProvider = ({ children, patientId, therapyPathType, isEditable = true }: Props) => {
  const { countries, getCountriesLoading } = useGetCountries()
  const { diagnosis, getDiagnosisByPatientIdLoading } = useGetDiagnosisByPatientId(patientId)
  const { genderIdentities, getGenderIdentitiesLoading } = useGetGenderIdentities()
  const { languages, getLanguagesLoading } = useGetLanguages()
  const { pathologies, getDiagnosablePathologiesLoading } = useGetDiagnosablePathologies()
  const { updateDiagnosis } = useUpdateDiagnosis(patientId)
  const { workProfessions, getWorkProfessionsLoading } = useGetWorkProfessions()

  const value = useMemo(() => {
    if (
      getCountriesLoading ||
      getDiagnosablePathologiesLoading ||
      getDiagnosisByPatientIdLoading ||
      getGenderIdentitiesLoading ||
      getLanguagesLoading ||
      getWorkProfessionsLoading
    ) {
      return {
        ...defaultContext,
        error: false,
        loading: true,
        patientId,
      }
    }

    if (
      !countries.length ||
      !genderIdentities.length ||
      !languages.length ||
      !pathologies.length ||
      !workProfessions.length
    ) {
      return {
        ...defaultContext,
        error: true,
        loading: false,
        patientId,
      }
    }

    return {
      countries,
      diagnosis,
      error: false,
      genderIdentities,
      languages,
      loading: false,
      pathologies: pathologies.filter(({ type }) => type === THERAPY_PATH_BY_PATHOLOGY_TYPE[therapyPathType]),
      patientId,
      updateDiagnosis,
      workProfessions,
      isEditable,
      therapyPathType,
    }
  }, [
    countries,
    diagnosis,
    genderIdentities,
    getCountriesLoading,
    getDiagnosablePathologiesLoading,
    getDiagnosisByPatientIdLoading,
    getGenderIdentitiesLoading,
    getLanguagesLoading,
    getWorkProfessionsLoading,
    languages,
    pathologies,
    patientId,
    updateDiagnosis,
    workProfessions,
    isEditable,
    therapyPathType,
  ])

  return <DiagnosisContext.Provider value={value}>{children}</DiagnosisContext.Provider>
}
