import { useContext } from 'react'
import { DiagnosisContext } from '~/domains/diagnosis/providers/DiagnosisProvider'

export const useDiagnosis = () => {
  const state = useContext(DiagnosisContext)

  if (!state) {
    throw new Error('The `useDiagnosis` should be wrapped with `DiagnosisProvider`.')
  }

  return state
}
