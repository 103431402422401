import { Flex, Form, OverflowAuto } from 'cdk'
import { startOfDay } from 'date-fns/fp'
import { format, FormatDateEnum, nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { noop } from 'functions'
import { type ComponentProps, useCallback, useState } from 'react'
import styled from 'styled-components'
import { Button, Link, Suggestion, Text } from 'ui'
import { Calendar } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { CalendarAvailabilityFormSelect } from '~/domains/availabilities/calendar/components/CalendarAvailabilityFormSelect'
import { useTherapistCalendar } from '~/domains/availabilities/calendar/hooks/useTherapistCalendar'
import { Modal, useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { useLanguage } from '~/i18n/hooks/useLanguage'
import { type FormValues } from '../types/formValues'

const CalendarWrapper = styled(Flex)`
  margin: 0 -24px;
`
const disabledBeforeDate = pipe(nowInMilliseconds(), startOfDay)

type Day = Parameters<ComponentProps<typeof Calendar>['onChange']>[0]

type Props = {
  onClose?: () => void
  id: string
  initialStartAt: Date
  onSelectedDay: (param: Day) => void
  patientFullName: string
  patientId?: string
  recurrency?: boolean | null
  selectedDay: Date
}

export const ModalRescheduleTherapySession = ({
  id,
  initialStartAt,
  onClose = noop,
  onSelectedDay,
  patientFullName,
  recurrency = false,
}: Props) => {
  const modalId = `rescheduleTherapySession:${id}` as const

  const [showCalendar, setShowCalendar] = useState(false)
  const { events } = useTherapistCalendar()
  const { open, close, dismiss } = useModals()
  const { language } = useLanguage()

  const form = useReactHookFormContext<FormValues>()

  const handleCancel = useCallback(() => {
    dismiss(modalId)

    onClose()
  }, [dismiss, onClose, modalId])

  const onDeleteRecurrency = useCallback(() => {
    close(modalId)

    open(`deleteBatchRecurrency:${id}`)
  }, [close, id, modalId, open])

  const onSubmit = useCallback(() => {
    close(modalId)

    open(`rescheduleTherapySessionConfirm:${id}`)
  }, [close, id, modalId, open])

  const onShowCalendar = useCallback(() => {
    if (showCalendar) {
      setShowCalendar(false)

      return
    }

    setShowCalendar(true)
  }, [setShowCalendar, showCalendar])

  return (
    <Modal id={modalId}>
      <Form $grow={1} $shrink={1} onSubmit={form.handleSubmit(onSubmit)}>
        <ModalCloseButton id={modalId} onClose={handleCancel} />
        <OverflowAuto>
          <Text kind="h2">
            <Translation
              id={`availabilities.calendar.rescheduleTherapySession.title.${patientFullName ? '1' : '2'}.variant`}
              values={{ patientFullName: patientFullName || '' }}
            />
          </Text>

          <Flex $pt={16}>
            <Text colorName="neutral-80" kind="caption">
              <Translation id="generic.date" />
            </Text>
          </Flex>
          <Flex $pt={4}>
            <Link colorName="darker" onClick={onShowCalendar}>
              <Text kind="paragraph" textDecoration="underline">
                <Translation
                  id="availabilities.calendar.rescheduleTherapySession.date"
                  values={{
                    dateWithDayAndMonth: pipe(initialStartAt, format(FormatDateEnum.LONG_DAY_DATE_MONTH_NAME)),
                  }}
                />
              </Text>
            </Link>
          </Flex>

          {showCalendar && (
            <CalendarWrapper $pt={16}>
              <Calendar
                disabledBeforeDate={disabledBeforeDate}
                events={events.slots}
                initialDate={initialStartAt}
                language={language}
                onChange={onSelectedDay}
                sundaysEnabled
              />
            </CalendarWrapper>
          )}

          <Flex $pt={16}>
            <CalendarAvailabilityFormSelect type="startAt" />
          </Flex>

          {recurrency && (
            <Flex $pt={32}>
              <Suggestion kind="info">
                <Text as="p" fontWeight="600" kind="paragraph">
                  <Translation id="forms.labels.therapySessionsBatchRecurrencySuggestion.title" />
                </Text>
                <Flex $pt={8}>
                  <Text fontWeight="400" kind="paragraph">
                    <Translation id="forms.labels.therapySessionsBatchRecurrencySuggestion.description" />
                  </Text>
                </Flex>
              </Suggestion>
            </Flex>
          )}
        </OverflowAuto>

        <Flex $pt={16}>
          <Button kind="primary" type="submit">
            <Translation id="actions.confirm" />
          </Button>

          {recurrency && (
            <Flex $pt={8}>
              <Button kind="secondary" onClick={onDeleteRecurrency}>
                <Translation id="actions.deleteRecurrency" />
              </Button>
            </Flex>
          )}
        </Flex>
      </Form>
    </Modal>
  )
}
