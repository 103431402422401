import {
  THERAPY_SESSION_DURATION_ONLINE_COACHING,
  THERAPY_SESSION_DURATION_ONLINE_COACHING_FIRST,
  THERAPY_SESSION_DURATION_ONLINE_COUPLE,
  THERAPY_SESSION_DURATION_ONLINE_COUPLE_FIRST,
  THERAPY_SESSION_DURATION_ONLINE_DCA,
  THERAPY_SESSION_DURATION_ONLINE_DCA_FIRST,
  THERAPY_SESSION_DURATION_ONLINE_DCA_ONBOARDING,
  THERAPY_SESSION_DURATION_ONLINE_MYSELF,
  THERAPY_SESSION_DURATION_ONLINE_MYSELF_FIRST,
  THERAPY_SESSION_DURATION_ONLINE_PSYCHIATRY,
  THERAPY_SESSION_DURATION_ONLINE_PSYCHIATRY_FIRST,
  THERAPY_SESSION_DURATION_ONLINE_SEXOLOGY,
  THERAPY_SESSION_DURATION_ONLINE_SEXOLOGY_FIRST,
  THERAPY_SESSION_DURATION_ONLINE_WEIGHT_LOSS,
  THERAPY_SESSION_DURATION_ONLINE_WEIGHT_LOSS_FIRST,
  THERAPY_SESSION_DURATION_ONLINE_WEIGHT_LOSS_ONBOARDING,
} from '~/constants'
import { type TherapyTherapyPathType } from '~/types/graphql'

type Duration = {
  therapySessionDuration: number
  firstTherapySessionDuration: number
  onboardingTherapySessionDuration?: number
}

export const getTherapySessionDurationByTherapyPath = (therapyPath: TherapyTherapyPathType): Duration => {
  switch (therapyPath) {
    case 'PATH_ASSERTIVE_COMMUNICATION':
    case 'PATH_SLEEP':
    case 'MYSELF_PSYCHOTHERAPY':
      return {
        firstTherapySessionDuration: THERAPY_SESSION_DURATION_ONLINE_MYSELF_FIRST,
        therapySessionDuration: THERAPY_SESSION_DURATION_ONLINE_MYSELF,
      }
    case 'PATH_COACHING':
      return {
        firstTherapySessionDuration: THERAPY_SESSION_DURATION_ONLINE_COACHING_FIRST,
        therapySessionDuration: THERAPY_SESSION_DURATION_ONLINE_COACHING,
      }
    case 'PATH_NUTRITION_DCA':
      return {
        onboardingTherapySessionDuration: THERAPY_SESSION_DURATION_ONLINE_DCA_ONBOARDING,
        firstTherapySessionDuration: THERAPY_SESSION_DURATION_ONLINE_DCA_FIRST,
        therapySessionDuration: THERAPY_SESSION_DURATION_ONLINE_DCA,
      }
    case 'PATH_NUTRITION_WEIGHT_LOSS':
      return {
        onboardingTherapySessionDuration: THERAPY_SESSION_DURATION_ONLINE_WEIGHT_LOSS_ONBOARDING,
        firstTherapySessionDuration: THERAPY_SESSION_DURATION_ONLINE_WEIGHT_LOSS_FIRST,
        therapySessionDuration: THERAPY_SESSION_DURATION_ONLINE_WEIGHT_LOSS,
      }
    case 'MYSELF_PSYCHIATRY':
      return {
        firstTherapySessionDuration: THERAPY_SESSION_DURATION_ONLINE_PSYCHIATRY_FIRST,
        therapySessionDuration: THERAPY_SESSION_DURATION_ONLINE_PSYCHIATRY,
      }
    case 'PATH_SEXOLOGY':
      return {
        firstTherapySessionDuration: THERAPY_SESSION_DURATION_ONLINE_SEXOLOGY_FIRST,
        therapySessionDuration: THERAPY_SESSION_DURATION_ONLINE_SEXOLOGY,
      }
    case 'COUPLES_PSYCHOTHERAPY':
      return {
        firstTherapySessionDuration: THERAPY_SESSION_DURATION_ONLINE_COUPLE_FIRST,
        therapySessionDuration: THERAPY_SESSION_DURATION_ONLINE_COUPLE,
      }
  }
}
