import { ChatMessageList } from '~/domains/chat/components/ChatMessageList'
import { useChatChannel } from '~/domains/chat/hooks/useChatChannel'

export const ChatMessageListAttachments = () => {
  const { messages = [] } = useChatChannel()

  const items = messages.filter(({ attachments, deleted_at }) => !!attachments?.length && !deleted_at)

  return <ChatMessageList messages={items} />
}
