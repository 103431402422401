import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Text } from 'ui'
import { Flex, Pressable } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { routes } from '~/domains/chat/components/ChatMessageListMemoryRouter'

export const ChatTab = () => {
  const history = useHistory()
  const { pathname } = useLocation()

  const goToChat = useCallback(() => {
    history.push(routes.messages)
  }, [history])

  const goToAttachments = useCallback(() => {
    history.push(routes.attachments)
  }, [history])

  const isChatActive = useMemo(() => pathname === routes.messages, [pathname])
  const isAttachmentsActive = useMemo(() => pathname === routes.attachments, [pathname])

  return (
    <Flex align="center" direction="row">
      <Pressable autoWidth colorName={isChatActive ? 'white' : 'purple08'} onClick={goToChat}>
        <Flex
          background={isChatActive ? 'purple08' : 'white'}
          borderColor="purple06"
          borderRadiusBottomLeft={8}
          borderRadiusTopLeft={8}
          borderSize={1}
          px={16}
          py={8}
        >
          <Text fontWeight={isChatActive ? '600' : '400'} kind="caption">
            <Translation id="chat.allMessages" />
          </Text>
        </Flex>
      </Pressable>
      <Pressable autoWidth colorName={isAttachmentsActive ? 'white' : 'purple08'} onClick={goToAttachments}>
        <Flex
          background={isAttachmentsActive ? 'purple08' : 'white'}
          borderColor="purple06"
          borderRadiusBottomRight={8}
          borderRadiusTopRight={8}
          borderSize={1}
          px={16}
          py={8}
          style={{ borderLeft: 'none' }}
        >
          <Text fontWeight={isAttachmentsActive ? '600' : '400'} kind="caption">
            <Translation id="chat.attachments" />
          </Text>
        </Flex>
      </Pressable>
    </Flex>
  )
}
