import { Flex } from 'cdk'
import { SPACING_SM } from 'design-tokens'
import { Icon } from 'icons'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { type TranslationId } from '~/i18n/types'
import { type Therapist } from '~/types/graphql'
import { getCertificationIcon } from '~/utils/getCertificationIcon'

type Props = {
  certifications: Therapist['therapistCertifications']
  sex: number
}

const getCertificationDescription = (id: string, name: string): TranslationId | undefined => {
  if (id === 'crt_ubmnjbow31vu45ns' || name === 'Terapia online: qualità e best practice') {
    return 'therapist.overviewCard.certifications.online.description'
  }
}

export const TherapistOverviewCardDetailContentCertifications = ({ certifications }: Props) => {
  if (certifications === null || certifications.length === 0) {
    return null
  }

  const certs = certifications.map(({ certification }) => ({
    id: certification.id,
    name: certification.name,
    description: certification.description,
    translationId: getCertificationDescription(certification.id, certification.name),
  }))

  if (certs.length === 0) {
    return null
  }

  return (
    <Flex $gap={SPACING_SM}>
      <Text kind="h3">
        <Translation id="therapist.overviewCard.certifications.title" />
      </Text>
      {certs.map(({ id, translationId, description }) => (
        <Flex key={id} $align="flex-start" $direction="row" $gap={SPACING_SM}>
          <Flex>
            <Icon Svg={getCertificationIcon(id)} size={24} />
          </Flex>
          <Flex $shrink={1}>
            {translationId ? (
              <TranslationMarkdown colorName="darker" id={translationId} />
            ) : (
              <Text> {description} </Text>
            )}
          </Flex>
        </Flex>
      ))}
    </Flex>
  )
}
