import { useMemo } from 'react'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { ReactHookFormRadioField, useReactHookFormContext } from '~/domains/react-hook-form'
import { useCurrentUserProfessionTypeValue } from '~/hooks/useCurrentUserProfessionTypeValue'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { type TranslationId } from '~/i18n/types'
import { type ChurnReason, type ProfessionTypeValue } from '~/types/graphql'
import { TranslationMarkdown } from './TranslationMarkdown'

export type FormValues = {
  churnReason: ChurnReason
}

export type TherapistChurnReasonFieldsProps = {
  options: {
    availableFor: ProfessionTypeValue[]
    description: Extract<TranslationId, `therapistChurnReasonModal.option.description.${string}`>
    title: Extract<TranslationId, `therapistChurnReasonModal.option.title.${string}`>
    value: ChurnReason
  }[]
}

export const TherapistChurnReasonFields = ({ options }: TherapistChurnReasonFieldsProps) => {
  const {
    errors: { required },
  } = useFormTranslations()

  const form = useReactHookFormContext<FormValues>()

  const { isPsychiatrist, isNutritionist } = useCurrentUserProfessionTypeValue()

  const churnReasonOptions = useMemo(() => {
    if (isNutritionist) {
      return options.filter(
        ({ availableFor }) =>
          !(['PSYCHIATRIST', 'COACH', 'PSYCHOTHERAPIST'] satisfies ProfessionTypeValue[]).some((role) =>
            availableFor.includes(role),
          ),
      )
    }

    if (isPsychiatrist) {
      return options.filter(({ availableFor }) => availableFor.length === 0 || availableFor.includes('PSYCHIATRIST'))
    }

    return options.filter(({ availableFor }) => availableFor.length === 0 || !availableFor.includes('PSYCHIATRIST'))
  }, [isPsychiatrist, isNutritionist, options])

  const churnReason = form.watch('churnReason')

  return (
    <>
      {churnReasonOptions.map(({ title, description, value }, index) => (
        <Flex key={value} pt={index === 0 ? 0 : 8}>
          <Flex
            as="label"
            borderColor={value === churnReason ? 'purple08' : 'purple04'}
            borderRadius={16}
            borderSize={1}
            p={16}
          >
            <ReactHookFormRadioField name="churnReason" rules={{ required }} value={value}>
              <Text colorName="black" fontWeight="600" kind="paragraph">
                <Translation id={title} />
              </Text>
              <TranslationMarkdown colorName="black" id={description} kind="paragraph" />
            </ReactHookFormRadioField>
          </Flex>
        </Flex>
      ))}
    </>
  )
}
