import { Flex, Form, MaxWidth840px, OverflowAuto } from 'cdk'
import { useBreakpoints } from 'hooks'
import { isEqual } from 'lodash-es'
import { useCallback, useMemo, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { Button, Text } from 'ui'
import { CenteredLoader } from '~/components/CenteredLoader'
import { PageRoute } from '~/components/PageRoute'
import { type SelectOption } from '~/components/SelectNew/types'
import { Translation } from '~/components/Translation'
import { useDiagnosis } from '~/domains/diagnosis/hooks/useDiagnosis'
import {
  ReactHookFormProvider,
  ReactHookFormTextArea,
  ReactHookFormTextFieldNew,
  useReactHookForm,
} from '~/domains/react-hook-form'
import { ReactHookFormSelectNew } from '~/domains/react-hook-form/components/ReactHookFormSelectNew'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useToasts } from '~/hooks/useToasts'
import { useTranslation } from '~/i18n/hooks/useTranslation'
import { getRoute } from '~/utils/getRoute'
import { isNutrition } from '~/utils/therapyPaths'
import { DiagnosisMandatoryFieldBadge } from './DiagnosisMandatoryFieldBadge'
import { DiagnosisSuggestionBox } from './DiagnosisSuggestionBox'

type SelectOptionString = SelectOption<string>

type FormValues = {
  age?: string
  country?: SelectOptionString
  degree?: string
  genderIdentity?: SelectOptionString
  healthIssue?: string
  historicalDiagnosis?: string
  language?: SelectOptionString
  otherInfo?: string
  pathologies: SelectOptionString[]
  pharmacologicalTreatment?: string
  profession?: string
  psychiatricVulnerability?: string
  socialAndFamilyNetwork?: string
  socioCulturalContext?: string
  workProfession?: SelectOptionString
}

const View = () => {
  const [loading, setLoading] = useState(false)
  const {
    countries,
    diagnosis,
    genderIdentities,
    languages,
    pathologies,
    workProfessions,
    updateDiagnosis,
    therapyPathType,
  } = useDiagnosis()
  const { addToast } = useToasts()
  const history = useHistory()
  const { isMedium } = useBreakpoints()

  const {
    errors: { required },
  } = useFormTranslations()

  const facing = useTranslation('patientDetail.diagnosis.state.facing')
  const genderIdentity = useTranslation('patientDetail.diagnosis.info.genderIdentity')
  const age = useTranslation('patientDetail.diagnosis.info.age')
  const profession = useTranslation('patientDetail.diagnosis.info.profession')
  const degree = useTranslation('patientDetail.diagnosis.info.degree')
  const socioCulturalContext = useTranslation('patientDetail.diagnosis.info.socioCulturalContext')
  const country = useTranslation('patientDetail.diagnosis.info.country')
  const language = useTranslation('patientDetail.diagnosis.info.language')
  const otherInfo = useTranslation('patientDetail.diagnosis.info.otherInfo')
  const pharmacologicalTreatment = useTranslation('patientDetail.diagnosis.info.pharmacologicalTreatment')
  const historicalDiagnosis = useTranslation('patientDetail.diagnosis.info.historicalDiagnosis')
  const healthIssue = useTranslation('patientDetail.diagnosis.info.healthIssue')
  const psychiatricVulnerability = useTranslation('patientDetail.diagnosis.info.psychiatricVulnerability')
  const socialAndFamilyNetwork = useTranslation('patientDetail.diagnosis.info.socialAndFamilyNetwork')
  const workProfession = useTranslation('patientDetail.diagnosis.info.workProfession')

  const defaultValues: FormValues = useMemo(
    () => ({
      age: diagnosis?.age || '',
      country: diagnosis?.country ? { label: diagnosis?.country.name, value: diagnosis?.country.id } : undefined,
      degree: diagnosis?.degree || '',
      genderIdentity: diagnosis?.genderIdentity
        ? { label: diagnosis?.genderIdentity.name, value: diagnosis?.genderIdentity.id }
        : undefined,
      healthIssue: diagnosis?.healthIssue || '',
      historicalDiagnosis: diagnosis?.historicalDiagnosis || '',
      language: diagnosis?.language ? { label: diagnosis?.language.name, value: diagnosis?.language.id } : undefined,
      otherInfo: diagnosis?.otherInfo || '',
      pharmacologicalTreatment: diagnosis?.pharmacologicalTreatment || '',
      profession: diagnosis?.profession || '',
      psychiatricVulnerability: diagnosis?.psychiatricVulnerability || '',
      socialAndFamilyNetwork: diagnosis?.socialAndFamilyNetwork || '',
      socioCulturalContext: diagnosis?.socioCulturalContext || '',
      pathologies: diagnosis?.pathologies?.map(({ pathology: { id: value, name: label } }) => ({ label, value })) || [],
      workProfession: diagnosis?.workProfession
        ? { label: diagnosis?.workProfession.name, value: diagnosis?.workProfession.id }
        : undefined,
    }),
    [diagnosis],
  )

  const form = useReactHookForm<FormValues>({
    defaultValues,
  })

  const selectedWorkProfession = form.watch('workProfession')

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      if (isEqual(formValues, defaultValues)) {
        addToast({ translationId: 'diagnosis.success.update', type: 'success' })

        history.goBack()

        return
      }

      setLoading(true)

      const { country, genderIdentity, language, pathologies, workProfession, ...values } = formValues

      const { ok } = await updateDiagnosis({
        ...values,
        countryId: country?.value,
        genderIdentityId: genderIdentity?.value,
        languageId: language?.value,
        pathologiesId: pathologies.map(({ value }) => value),
        workProfessionId: workProfession?.value,
      })

      if (!ok) {
        addToast({ translationId: 'diagnosis.alert.update', type: 'alert' })

        setLoading(false)

        return
      }

      addToast({ translationId: 'diagnosis.success.update', type: 'success' })

      history.goBack()
    },
    [defaultValues, updateDiagnosis, addToast, history],
  )

  const draft = form.watch('otherInfo')

  const helperText = useTranslation('forms.helperTexts.textareaCounter', {
    current: draft?.length || '0',
    max: 1000,
  })

  return (
    <ReactHookFormProvider {...form}>
      <Form $shrink={1} id="edit-diagnosis" onSubmit={form.handleSubmit(onSubmit)}>
        <Flex $backgroundColorName="lighter" $grow={1} $shrink={1}>
          <OverflowAuto $grow={1} $mdPx={24} $px={16} $shrink={1}>
            <MaxWidth840px $gap={16} $mdPt={32} $pt={24}>
              <Text colorName="primary" fontWeight="600" kind="paragraph">
                <Translation id="patientDetail.diagnosis.state" />
              </Text>
              <DiagnosisSuggestionBox bodyTranslationId="patientDetail.diagnosis.edit.infoDescription" />
              <DiagnosisMandatoryFieldBadge />
              <ReactHookFormSelectNew
                multiple
                name="pathologies"
                options={pathologies.map(({ id: value, name: label }) => ({ label, value }))}
                placeholder={facing}
                rules={{ required }}
              />
              <ReactHookFormTextArea
                helperText={helperText}
                minHeight="120px"
                name="otherInfo"
                placeholder={otherInfo}
                rules={{ maxLength: 1000 }}
              />

              <Text colorName="primary" fontWeight="600" kind="paragraph">
                <Translation id="patientDetail.diagnosis.info" />
              </Text>
              <ReactHookFormSelectNew
                name="genderIdentity"
                options={genderIdentities.map(({ id: value, name: label }) => ({ label, value }))}
                placeholder={genderIdentity}
              />
              <ReactHookFormTextFieldNew name="age" placeholder={age} type="number" />
              <ReactHookFormSelectNew
                name="workProfession"
                options={workProfessions.map(({ id: value, name: label }) => ({ label, value }))}
                placeholder={workProfession}
              />
              <ReactHookFormTextFieldNew
                disabled={!selectedWorkProfession}
                name="profession"
                placeholder={profession}
                type="text"
              />
              <ReactHookFormTextFieldNew name="degree" placeholder={degree} type="text" />
              <ReactHookFormTextFieldNew name="socioCulturalContext" placeholder={socioCulturalContext} type="text" />
              <ReactHookFormSelectNew
                name="country"
                options={countries.map(({ id: value, name: label }) => ({ label, value }))}
                placeholder={country}
              />
              <ReactHookFormSelectNew
                name="language"
                options={languages.map(({ id: value, name: label }) => ({ label, value }))}
                placeholder={language}
              />
              <ReactHookFormTextFieldNew
                name="pharmacologicalTreatment"
                placeholder={pharmacologicalTreatment}
                type="text"
              />
              <ReactHookFormTextFieldNew name="historicalDiagnosis" placeholder={historicalDiagnosis} type="text" />
              <ReactHookFormTextFieldNew name="healthIssue" placeholder={healthIssue} type="text" />
              {!isNutrition(therapyPathType) && (
                <>
                  <ReactHookFormTextFieldNew
                    name="psychiatricVulnerability"
                    placeholder={psychiatricVulnerability}
                    type="text"
                  />

                  <ReactHookFormTextFieldNew
                    name="socialAndFamilyNetwork"
                    placeholder={socialAndFamilyNetwork}
                    type="text"
                  />
                </>
              )}
            </MaxWidth840px>
          </OverflowAuto>
          <Flex $elevationName="xxs" $py={16}>
            <MaxWidth840px>
              <Button
                disabled={!form.formState.isValid || loading}
                isLoading={loading}
                kind="primary"
                size={isMedium ? 'md' : 'sm'}
                type="submit"
              >
                <Translation id="patientDetail.diagnosis.save" />
              </Button>
            </MaxWidth840px>
          </Flex>
        </Flex>
      </Form>
    </ReactHookFormProvider>
  )
}

export const EditDiagnosisMyself = () => {
  const { error, loading, patientId } = useDiagnosis()

  if (loading) {
    return <CenteredLoader />
  }

  if (error) {
    return <Redirect to={getRoute(`/chat/${patientId}`)} />
  }

  return (
    <PageRoute id="diagnosis.edit">
      <View />
    </PageRoute>
  )
}
