import { COLOR_PRIMARY_40, type ColorName, cssvarColor } from 'design-tokens'
import { type ReactNode } from 'react'
import styled from 'styled-components'
import { shouldForwardProp } from 'ui'
import { Flex, PositionRelative } from 'ui-deprecated'

type WrapperProps = {
  backgroundName: ColorName
}

const Wrapper = styled(Flex).withConfig({ shouldForwardProp })<WrapperProps>`
  height: 112px;
  border-bottom: 1px solid ${COLOR_PRIMARY_40};
  background-color: ${({ backgroundName }) => cssvarColor(backgroundName)};
`

type Props = {
  background: Extract<ColorName, 'primary-10'>
  children: ReactNode
}

export const ChatChannelHeaderWrapper = ({ background, children }: Props) => (
  <PositionRelative>
    <Wrapper align="center" backgroundName={background} justify="center" px={16}>
      {children}
    </Wrapper>
  </PositionRelative>
)
