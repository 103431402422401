import { AssertAnonymous } from '~/domains/auth/components/AssertAnonymous'
import { AssertFeatureFlagByFingerprintIsVariant } from '~/domains/featureFlags/components/AssertFeatureFlagByFingerprintIsVariant'
import { AssertAtLeastOneFormFlow } from '~/domains/reservation/components/AssertAtLeastOneFormFlow'
import { NoMatchedTherapists } from '~/domains/reservation/pages/NoMatchedTherapists'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'
import { getRoute } from '~/utils/getRoute'

export const PreBookingNoMatchesPage: Page = {
  exact: true,
  id: 'reservation.pre-booking-no-matches',
  paths: ['/pre-booking-no-matches'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: false,
    seoKey: 'reservation.preBooking',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertFeatureFlagByFingerprintIsVariant names={['ff_pre_signup_therapists', 'ff_pre_signup_nutritionists']}>
      <AssertAnonymous target={getRoute('/')}>
        <AssertAtLeastOneFormFlow>{children}</AssertAtLeastOneFormFlow>
      </AssertAnonymous>
    </AssertFeatureFlagByFingerprintIsVariant>
  ),
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: NoMatchedTherapists,
}
