import { gql, useMutation } from '@apollo/client'
import { type DeleteFoodJournalMealMutation, type DeleteFoodJournalMealMutationVariables } from '~/types/graphql'

const DELETE_FOOD_JOURNAL_MEAL_MUTATION = gql`
  mutation deleteFoodJournalMeal($input: DeleteFoodJournalMealInput!) {
    deleteFoodJournalMeal(input: $input) {
      id
    }
  }
`

export const useDeleteFoodJournalMeal = () => {
  const [deleteMeal] = useMutation<DeleteFoodJournalMealMutation, DeleteFoodJournalMealMutationVariables>(
    DELETE_FOOD_JOURNAL_MEAL_MUTATION,
    {
      update(cache, { data }) {
        const deletedMealId = data?.deleteFoodJournalMeal?.id

        if (deletedMealId != null) {
          cache.evict({ id: cache.identify({ __typename: 'FoodJournalMeal', id: deletedMealId }) })
          cache.gc()
        }
      },
    },
  )

  return deleteMeal
}
