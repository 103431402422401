import { Flex } from 'cdk'
import { format, FormatDateEnum } from 'dates'
import { type ColorName } from 'design-tokens'
import { pipe } from 'fp-ts/function'
import { noop } from 'functions'
import { type CSSProperties, useMemo } from 'react'
import { type EventWrapperProps } from 'react-big-calendar'
import { Text } from 'ui'
import { type CalendarEvent } from '~/domains/availabilities/calendar/types'
import { parseEndAndStartFromCalendar } from '~/domains/availabilities/calendar/utils/parseEndAndStartFromCalendar'
import { isCalendarEventPast } from '../utils/isCalendarEventPast'
import { TherapistCalendarEventWrapper } from './TherapistCalendarEventWrapper'

type Props = Pick<EventWrapperProps<CalendarEvent>, 'event' | 'onClick' | 'style'>

type SlotProps = {
  background: ColorName
  foreground: ColorName
  handleClick: Props['onClick']
}

export const TherapistCalendarEventTherapySession = ({ event, onClick, style }: Props) => {
  const wrapperStyle = useMemo(
    (): CSSProperties => ({
      ...style,
      zIndex: 4,
    }),
    [style],
  )

  const label = useMemo(
    () => pipe(event, parseEndAndStartFromCalendar, ({ start }) => start, format(FormatDateEnum.HOURS_MINUTES)),
    [event],
  )

  const { background, foreground, handleClick } = useMemo((): SlotProps => {
    if (isCalendarEventPast(event)) {
      return {
        background: 'primary-10',
        foreground: 'primary-40',
        handleClick: noop,
      }
    }

    return {
      background: 'primary',
      foreground: 'white',
      handleClick: onClick,
    }
  }, [event, onClick])

  const patientFullName = event.resource.therapySession?.patient.fullName
  const title = `${label} · ${patientFullName}`

  return (
    <TherapistCalendarEventWrapper onClick={handleClick} pr={1} style={wrapperStyle} title={title}>
      <Flex
        $backgroundColorName={background}
        $borderColorName={background}
        $borderRadius={4}
        $borderSize={1}
        $grow={1}
        $justify="center"
        $px={4}
        $py={1}
        $shrink={1}
      >
        <Flex $align="center" $direction="row">
          <Text colorName={foreground} fontWeight="600" kind="footnote">
            {label}
          </Text>
          <Flex $grow={1} $pl={4} $shrink={1}>
            <Text colorName={foreground} ellipsis kind="caption">
              {patientFullName}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </TherapistCalendarEventWrapper>
  )
}
