import { Flex, MountOn } from 'cdk'
import { SPACING_XS } from 'design-tokens'
import { useGetFoodJournalDay } from '../../hooks/useGetFoodJournalDay'
import { useFoodJournal } from '../FoodJournalContext'
import { FloatingAddMealCTA } from './FloatingAddMealCTA'
import { MealDetails } from './MealDetails'
import { MealsEmptyState } from './MealsEmptyState'
import { MealsSkeleton } from './MealsSkeleton'

type Props = {
  editable?: boolean
}

export const FoodJournalMeals = ({ editable = false }: Props) => {
  const { patientId, currentDay } = useFoodJournal()

  const { foodJournalDay, loading } = useGetFoodJournalDay({
    day: currentDay,
    patientId,
  })

  const meals = foodJournalDay?.meals ?? []

  if (loading) {
    return <MealsSkeleton />
  }

  if (editable && meals.length === 0) {
    return (
      <>
        <MealsEmptyState />

        <MountOn mediaQuery="lt-lg">
          <FloatingAddMealCTA />
        </MountOn>
      </>
    )
  }

  return (
    <>
      <Flex $gap={SPACING_XS}>
        {meals.map((meal) => (
          <MealDetails key={meal.id} {...meal} editable={editable} />
        ))}
      </Flex>

      {editable && <FloatingAddMealCTA />}
    </>
  )
}
