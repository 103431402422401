import { THERAPY_SESSION_COLOR_NAME_MENTAL, THERAPY_SESSION_COLOR_NAME_NUTRITION } from '~/constants'
import { type TherapyTherapyPathType } from '~/types/graphql'

export const getTherapySessionColorNameByTherapyPath = (therapyPathType: TherapyTherapyPathType) => {
  switch (therapyPathType) {
    case 'COUPLES_PSYCHOTHERAPY':
    case 'MYSELF_PSYCHIATRY':
    case 'MYSELF_PSYCHOTHERAPY':
    case 'PATH_ASSERTIVE_COMMUNICATION':
    case 'PATH_COACHING':
    case 'PATH_SEXOLOGY':
    case 'PATH_SLEEP':
      return THERAPY_SESSION_COLOR_NAME_MENTAL
    case 'PATH_NUTRITION_DCA':
    case 'PATH_NUTRITION_WEIGHT_LOSS':
      return THERAPY_SESSION_COLOR_NAME_NUTRITION
  }
}
