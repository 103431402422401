import { useContext } from 'react'
import { FeatureFlagsByUserIdContext } from '~/domains/featureFlags/components/FeatureFlagsByUserId'
import { useFeatureFlagsClient } from '~/domains/featureFlags/hooks/useFeatureFlagsClient'

type Params = {
  therapy_id?: string
}

type ParsedValues = {
  therapyIds?: string[]
}

export const useIsTranscribeFeatured = ({ therapy_id }: Params): boolean => {
  const context = useContext(FeatureFlagsByUserIdContext)

  const client = useFeatureFlagsClient(context)
  const value = client.getValue('ff_live_session_transcribing')

  if (value == null || therapy_id == null) {
    return false
  }

  try {
    const feature = JSON.parse(value.toString()) as ParsedValues
    return feature?.therapyIds?.includes(therapy_id) ?? false
  } catch (error) {
    return false
  }
}
