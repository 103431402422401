import { gql, useMutation } from '@apollo/client'
import { useCallback } from 'react'
import {
  type DiagnosisCouplesUpdateInput,
  type DiagnosisCouplesUpdateMutation,
  type DiagnosisCouplesUpdateMutationVariables,
} from '~/types/graphql'
import { useDiagnosisCouplesQuery } from './useDiagnosisCouplesQuery'

const MUTATION = gql`
  mutation diagnosisCouplesUpdate($input: DiagnosisCouplesUpdateInput!) {
    diagnosisCouplesUpdate(input: $input) {
      ok
    }
  }
`

export const useDiagnosisCoupleUpdate = (patientId: string) => {
  const [diagnosisCouplesUpdateMutation, { loading }] = useMutation<
    DiagnosisCouplesUpdateMutation,
    DiagnosisCouplesUpdateMutationVariables
  >(MUTATION)

  const { refetch } = useDiagnosisCouplesQuery(patientId)

  const diagnosisCouplesUpdate = useCallback(
    async (input: DiagnosisCouplesUpdateInput) => {
      try {
        const response = await diagnosisCouplesUpdateMutation({
          variables: { input },
        })

        if (!response.data?.diagnosisCouplesUpdate.ok) {
          return { ok: false }
        }

        await refetch()

        return { ok: true }
      } catch (e) {
        return { ok: false }
      }
    },
    [diagnosisCouplesUpdateMutation, refetch],
  )

  return { diagnosisCouplesUpdate, loading }
}
