import { assertNever } from 'functions'
import { type TranslationId } from '~/i18n/types'
import { type ChurnReason } from '~/types/graphql'

export const getChurnReasonLabel = (
  churnReason: ChurnReason | null,
): Extract<
  TranslationId,
  `therapySession.churnReason.therapist.${string}` | 'patientsManagement.status.missingChurnReason'
> => {
  switch (churnReason) {
    case 'BONUS_PSICOLOGO':
      return 'therapySession.churnReason.therapist.bonusPsicologo'
    case 'BROWSING':
      return 'therapySession.churnReason.therapist.browsing'
    case 'ECONOMIC':
      return 'therapySession.churnReason.therapist.economic'
    case 'GHOSTING':
      return 'therapySession.churnReason.therapist.ghosting'
    case 'MAINTAINING':
      return 'therapySession.churnReason.therapist.maintaining'
    case 'NEEDED_CERTIFICATE_ONLY':
      return 'therapySession.churnReason.therapist.neededCertificateOnly'
    case 'NON_CONTINUOUS_TREATMENT':
      return 'therapySession.churnReason.therapist.nonContinuousTreatment'
    case 'NO_INFO':
      return 'therapySession.churnReason.therapist.noInfo'
    case 'OTHER':
      return 'therapySession.churnReason.therapist.other'
    case 'PATHOLOGY_OUT_OF_SCOPE':
      return 'therapySession.churnReason.therapist.pathologyOutOfScope'
    case 'PENDING_CHANGE_THERAPIST':
    case 'THERAPIST_ISSUE':
      return 'therapySession.churnReason.therapist.pendingChangeTherapist'
    case 'PENDING_RESCHEDULE':
      return 'therapySession.churnReason.therapist.pendingReschedule'
    case 'SCHEDULING_ISSUE':
      return 'therapySession.churnReason.therapist.schedulingIssue'
    case 'SOLVED':
      return 'therapySession.churnReason.therapist.solved'
    case 'THERAPY_IN_PERSON':
      return 'therapySession.churnReason.therapist.therapyInPerson'
    case 'MISSING_AUTHORIZATION':
    case 'MISSING_AUTHORIZATION_UNDERAGE':
      return 'therapySession.churnReason.therapist.missingAuthorization'
    case null:
      return 'patientsManagement.status.missingChurnReason'
    default: {
      return assertNever(churnReason)
    }
  }
}
