import { forwardRef } from 'react'
import { Button } from 'ui'
import { Translation } from '~/components/Translation'

type Props = {
  onClick?: VoidFunction
}

export const OpenResumeCTA = forwardRef<HTMLButtonElement, Props>(({ onClick }, ref) => (
  <Button ref={ref} data-test-id="open-therapist-resume-cta" isGhost kind="primary" onClick={onClick} size="md">
    <Translation id="therapist.overviewCard.cta.readCv" />
  </Button>
))
