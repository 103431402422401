import { MemoryRouter, Route, Switch } from 'react-router-dom'
import { Modal } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { ReactHookFormProvider, useReactHookForm } from '~/domains/react-hook-form'
import { getTechnicalFeedbackRoute } from '~/domains/technicalFeedback/utils/getTechnicalFeedbackRoute'
import { type TechnicalFeedbackPsychotherapy } from '../types'
import { TechnicalFeedbackStepBad } from './Psychotherapy/TechnicalFeedbackStepBad'
import { TechnicalFeedbackStepContext } from './Psychotherapy/TechnicalFeedbackStepContext'
import { TechnicalFeedbackStepDuration } from './Psychotherapy/TechnicalFeedbackStepDuration'
import { TechnicalFeedbackStepEnd } from './Psychotherapy/TechnicalFeedbackStepEnd'
import { TechnicalFeedbackStepPunctuality } from './Psychotherapy/TechnicalFeedbackStepPunctuality'
import { TechnicalFeedbackStepQuality } from './Psychotherapy/TechnicalFeedbackStepQuality'

const initialEntries = [
  getTechnicalFeedbackRoute('/quality'),
  getTechnicalFeedbackRoute('/context'),
  getTechnicalFeedbackRoute('/punctuality'),
  getTechnicalFeedbackRoute('/duration'),
]

type Props = {
  onCompleted: () => void
  technicalFeedbackId: string
  therapySessionId: string
}

export const TechnicalFeedbackPsychotherapyModal = ({ onCompleted, technicalFeedbackId, therapySessionId }: Props) => {
  const form = useReactHookForm<TechnicalFeedbackPsychotherapy>({
    mode: 'all',
    defaultValues: {
      bad: '',
      context: 0,
      duration: null,
      punctuality: 0,
      quality: 0,
    },
  })

  return (
    <ReactHookFormProvider {...form}>
      <Modal id="technicalFeedback">
        <ModalCloseButton id="technicalFeedback" onClose={onCompleted} />

        <MemoryRouter initialEntries={initialEntries} initialIndex={0}>
          <Switch>
            <Route exact path={getTechnicalFeedbackRoute('/quality')}>
              <TechnicalFeedbackStepQuality technicalFeedbackId={technicalFeedbackId} />
            </Route>
            <Route exact path={getTechnicalFeedbackRoute('/context')}>
              <TechnicalFeedbackStepContext technicalFeedbackId={technicalFeedbackId} />
            </Route>
            <Route exact path={getTechnicalFeedbackRoute('/punctuality')}>
              <TechnicalFeedbackStepPunctuality technicalFeedbackId={technicalFeedbackId} />
            </Route>
            <Route exact path={getTechnicalFeedbackRoute('/duration')}>
              <TechnicalFeedbackStepDuration
                technicalFeedbackId={technicalFeedbackId}
                therapySessionId={therapySessionId}
              />
            </Route>
            <Route exact path={getTechnicalFeedbackRoute('/bad')}>
              <TechnicalFeedbackStepBad technicalFeedbackId={technicalFeedbackId} />
            </Route>
            <Route exact path={getTechnicalFeedbackRoute('/end')}>
              <TechnicalFeedbackStepEnd onCompleted={onCompleted} technicalFeedbackId={technicalFeedbackId} />
            </Route>
          </Switch>
        </MemoryRouter>
      </Modal>
    </ReactHookFormProvider>
  )
}
