import { Flex, Form, OverflowAuto } from 'cdk'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, ModalDescription, ModalTitle } from 'ui'
import { Translation } from '~/components/Translation'
import { useTrackEvent } from '~/domains/analytics/hooks/useTrackEvent'
import { useTrackEventView } from '~/domains/analytics/hooks/useTrackEventView'
import { useUserFormFlowResponseCompleted } from '~/domains/formFlow/hooks/useUserFormFlowResponseCompleted'
import { ReactHookFormTextArea, useReactHookFormContext } from '~/domains/react-hook-form'
import { useLoadingState } from '~/hooks/useLoadingState'
import { useRootHistory } from '~/hooks/useRootHistory'
import { useToasts } from '~/hooks/useToasts'
import { useTranslation } from '~/i18n/hooks/useTranslation'
import { getRoute } from '~/utils/getRoute'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'
import { useChangeTherapist } from '../hooks/useChangeTherapist'
import { type FormValues } from './ChangeTherapistModal'

type Props = {
  therapyId: string
}

export const ChangeTherapistReasonOpen = ({ therapyId }: Props) => {
  const { loading, startLoading, stopLoading } = useLoadingState()
  const trackClick = useTrackEvent('CLICK')
  const history = useHistory()
  const rootHistory = useRootHistory()
  const { addToast } = useToasts()
  const [changeTherapist] = useChangeTherapist()
  const { userFormFlowResponseCompletedId } = useUserFormFlowResponseCompleted()
  const form = useReactHookFormContext<FormValues>()
  const changeTherapistPlaceHolder = useTranslation('changeTherapist.modal.variant.step.3.placeholder')

  const onSubmit = useCallback(
    async ({ changeTherapistReason, changeTherapistMessage }: FormValues) => {
      // just for type check
      if (changeTherapistReason === 'OTHER_MANUAL') {
        return
      }

      if (changeTherapistMessage) {
        setLocalStorage('change-therapist-custom-message', changeTherapistMessage)
      }

      startLoading('local')

      try {
        await changeTherapist({
          variables: {
            input: {
              changeTherapistReason,
              therapyId,
            },
          },
        })

        if (userFormFlowResponseCompletedId) {
          rootHistory.push(getRoute(`/change-therapist/${therapyId}/preferences`))

          return
        }

        rootHistory.push(getRoute('/start'))
      } catch {
        addToast({ type: 'alert', translationId: 'generic.error' })
      } finally {
        stopLoading()

        trackClick({ name: 'clicks.support.i-want-to-change-therapist' })
      }
    },
    [
      addToast,
      changeTherapist,
      rootHistory,
      startLoading,
      stopLoading,
      therapyId,
      trackClick,
      userFormFlowResponseCompletedId,
    ],
  )

  const onBackClick = useCallback(() => {
    history.goBack()
  }, [history])

  useTrackEventView('changeTherapist.modal.open')

  return (
    <Form $gap={16} $grow={1} $shrink={1} onSubmit={form.handleSubmit(onSubmit)}>
      <OverflowAuto $gap={16}>
        <ModalTitle>
          <Translation id="changeTherapist.modal.variant.step.3.title" />
        </ModalTitle>
        <ModalDescription>
          <Translation id="changeTherapist.modal.variant.step.3.description" />
        </ModalDescription>
        <ReactHookFormTextArea
          max={500}
          minHeight="200px"
          name="changeTherapistMessage"
          placeholder={changeTherapistPlaceHolder}
          rules={{ max: 500 }}
        />
      </OverflowAuto>
      <Flex $gap={8}>
        <Button isLoading={loading} kind="primary" type="submit">
          <Translation id="actions.proceed" />
        </Button>
        <Button isGhost kind="primary" onClick={onBackClick}>
          <Translation id="actions.back" />
        </Button>
      </Flex>
    </Form>
  )
}
