import { useMemo } from 'react'
import { CenteredLoader } from '~/components/CenteredLoader'
import { EditDiagnosisCouples } from '~/domains/diagnosisCouples/components/EditDiagnosisCouples'
import { DiagnosisCouplesProvider } from '~/domains/diagnosisCouples/hooks/useDiagnosisCouples'
import { GenericErrorContent } from '~/domains/error/GenericError'
import { useTherapistTherapies } from '~/domains/therapies/TherapistTherapiesProvider'
import { DiagnosisProvider } from '../providers/DiagnosisProvider'
import { EditDiagnosisMyself } from './EditDiagnosisMyself'

type Props = {
  patientId: string
}

export const EditDiagnosis = ({ patientId }: Props) => {
  const { loading, therapies } = useTherapistTherapies()

  const therapy = useMemo(() => therapies.find(({ patient }) => patient?.id === patientId), [patientId, therapies])

  if (loading) {
    return <CenteredLoader />
  }

  if (therapy == null) {
    return <GenericErrorContent />
  }

  const therapyPathType = therapy.therapyPath.type

  if (therapy?.therapyPath.type === 'COUPLES_PSYCHOTHERAPY') {
    return (
      <DiagnosisCouplesProvider patientId={patientId}>
        <EditDiagnosisCouples />
      </DiagnosisCouplesProvider>
    )
  }

  return (
    <DiagnosisProvider patientId={patientId} therapyPathType={therapyPathType}>
      <EditDiagnosisMyself />
    </DiagnosisProvider>
  )
}
