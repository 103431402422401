import { type ColorName } from 'design-tokens'
import { Icon, XmarkCircle } from 'icons'
import { useMemo } from 'react'
import { type ClearIndicatorProps, components } from 'react-select'
import { type SelectOption } from '~/components/SelectNew/types'

export const ClearIndicator = (props: ClearIndicatorProps<SelectOption>) => {
  const colorName: ColorName = useMemo(() => {
    if (props.isFocused) {
      return 'primary-80'
    }

    return 'primary'
  }, [props])

  return (
    <components.ClearIndicator {...props}>
      <Icon Svg={XmarkCircle} colorName={colorName} size={24} />
    </components.ClearIndicator>
  )
}
