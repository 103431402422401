import { useCallback } from 'react'
import { Text } from 'ui'
import { Button, Flex, MaxWidth320px, MaxWidth640px } from 'ui-deprecated'
import { LocationReloadButton } from '~/components/LocationReloadButton'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useIntercom } from '~/domains/intercom/hooks/useIntercom'
import { PageTrackEffect } from '~/routes/PageTrackEffect'
import { LeaveVideocall } from './LeaveVideocall'

export const Error = () => {
  const { openWithMessage, update } = useIntercom()

  const handleContactUsPressed = useCallback(() => {
    update({
      customAttributes: {
        srns_videocall_issue: true,
      },
    })

    openWithMessage('intercom.iTriedConnectToLiveTherapySession.message')
  }, [openWithMessage, update])

  return (
    <>
      <PageTrackEffect id="therapy-sessions.live.error.generic" />

      <Flex background="black" grow={1} shrink={1}>
        <Flex align="center" direction="row" justify="space-between" px={24} py={16}>
          <LeaveVideocall />
        </Flex>
        <Flex align="center" grow={1} justify="center" pb={40} shrink={1}>
          <MaxWidth640px align="stretch" justify="center" p={24}>
            <Text colorName="white" fontWeight="600" kind="h1" textAlign="center">
              <Translation id="therapySessionLive.error.thereIsATechnicalIssue.title" />
            </Text>
            <Flex pb={32} pt={8}>
              <Text colorName="white" fontWeight="400" kind="paragraph" newLine textAlign="center">
                <TranslationMarkdown colorName="white" id="therapySessionLive.error.thereIsATechnicalIssue.paragraph" />
              </Text>
            </Flex>
            <MaxWidth320px mdDirection="row" mdJustify="center">
              <Button kind="none" onClick={handleContactUsPressed}>
                <Text colorName="white">
                  <Translation id="actions.writeUs" />
                </Text>
              </Button>
              <Flex p={8} />
              <LocationReloadButton />
            </MaxWidth320px>
          </MaxWidth640px>
        </Flex>
      </Flex>
    </>
  )
}
