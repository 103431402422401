import { gql, useMutation } from '@apollo/client'
import { type EditFoodJournalMealMutation, type EditFoodJournalMealMutationVariables } from '~/types/graphql'

const EDIT_FOOD_JOURNAL_MEAL_MUTATION = gql`
  mutation editFoodJournalMeal($input: EditFoodJournalMealInput!) {
    editFoodJournalMeal(input: $input) {
      id
      description
      location
      date
      hungerBefore
      hungerAfter
      moodBefore
      moodAfter
      feelings
    }
  }
`

export const useEditFoodJournalMeal = () => {
  const [editMeal] = useMutation<EditFoodJournalMealMutation, EditFoodJournalMealMutationVariables>(
    EDIT_FOOD_JOURNAL_MEAL_MUTATION,
  )

  return editMeal
}
