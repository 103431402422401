import { pipe } from 'fp-ts/function'
import {} from 'fp-ts/string'
import { getEnv } from './getEnv'

type PROXY_SERVICE_ID = 'service-payments' | 'service-therapy-sessions'

const composeProxyUrl = (serviceId: PROXY_SERVICE_ID, target: string) =>
  pipe(
    getEnv('API_GATEWAY_PROXY_URL'),
    (value) => `${value}?serviceId=${serviceId}`,
    (value) => `${value}&target=${target}`,
  )

export const getPaymentsProxyApi = (target: string) => composeProxyUrl('service-payments', target)

export const getTherapySessionsProxyApi = (target: string) => composeProxyUrl('service-therapy-sessions', target)
