import { Flex, OverflowAuto } from 'cdk'
import { Route } from 'react-router-dom'
import { PatientDetailTherapySessions } from '~/components/patientDetail/PatientDetailTherapySessions'
import { ClinicalDiary } from '~/domains/clinicalDiary/components'
import { PatientDetailClinicalTestForTherapist, PatientDetailClinicalTests } from '~/domains/clinicalTests'
import { DiagnosisDetailByPatientType } from '~/domains/diagnosis/components/DiagnosisDetailByPatientType'
import { TherapistFoodJournal } from '~/domains/nutrition/TherapistFoodJournal'
import { useTherapySessionLive } from '~/domains/videocall'
import { useCurrentUserProfessionTypeValue } from '~/hooks/useCurrentUserProfessionTypeValue'
import { getPatientDetailRoute } from '../../../../utils/getPatientDetailRoute'
import { PatientDetailRootHeader } from './PatientDetailRootHeader'
import { PatientDetailTabs } from './PatientDetailTabs'

export const PatientDetailRoot = () => {
  const { patient } = useTherapySessionLive()
  const { isNutritionist } = useCurrentUserProfessionTypeValue()

  return (
    <Flex $grow={1} $shrink={1}>
      <Flex
        $backgroundColorName="primary-10"
        $basis={112}
        $borderColorName="primary-40"
        $borderSizeBottom={1}
        $px={16}
        $py={8}
      >
        <PatientDetailRootHeader />

        <Flex $pt={8}>
          <PatientDetailTabs />
        </Flex>
      </Flex>

      <OverflowAuto $pb={0}>
        <Route exact path={[getPatientDetailRoute('/clinical-tests')]}>
          <PatientDetailClinicalTests patientId={patient.id} />
        </Route>

        <Route path={[getPatientDetailRoute('/clinical-tests/:clinicalTestAnswerId')]}>
          <PatientDetailClinicalTestForTherapist patientId={patient.id} />
        </Route>

        <Route path={[getPatientDetailRoute('/diary')]}>
          <ClinicalDiary patientId={patient.id} />
        </Route>

        <Route path={[getPatientDetailRoute('/diagnosis')]}>
          <DiagnosisDetailByPatientType patientId={patient.id} rootPath="/chat" />
        </Route>

        <Route exact path={[getPatientDetailRoute('/detail')]}>
          <PatientDetailTherapySessions isInTherapySessionLive patientId={patient.id} />
        </Route>

        {isNutritionist && (
          <Route exact path={[getPatientDetailRoute('/food-journal')]}>
            <TherapistFoodJournal patientId={patient.id} withMonthlyCalendar />
          </Route>
        )}
      </OverflowAuto>
    </Flex>
  )
}
