import { Flex, PositionAbsolute } from 'cdk'
import { BORDER_RADIUS_CIRCLE, TYPOGRAPHY_PUBLIC_H1_LINE_HEIGHT } from 'design-tokens'
import { Icon, Plus } from 'icons'
import { Suspense } from 'react'
import { Avatar, Card, Skeleton, Text } from 'ui'
import { ErrorBoundary } from '~/components/ErrorBoundary'
import { Translation } from '~/components/Translation'
import { useTherapistSuspense } from '~/hooks/useTherapistFetch'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { getTherapistProfessionTranslationKey } from '~/utils/getTherapistProfessionTranslationKey'
import { getTherapyPathIllustration } from '~/utils/getTherapyPathIllustration'
import { getTherapyPathTranslationKey } from '~/utils/getTherapyPathTranslationKey'

type PathCardLoadedProps = {
  therapistId: string
  therapyPathType: TherapyTherapyPathType
}

const PathCardLoaded = ({ therapistId, therapyPathType }: PathCardLoadedProps) => {
  const { data } = useTherapistSuspense(therapistId)

  return (
    <Card $backgroundColorName="neutral-20" $justify="space-between" $minHeight={228} $p={0}>
      <Flex $direction="row" $justify="space-between">
        <Flex $grow={1} $pt={16} $px={16} $shrink={1}>
          <Text kind="public-h1">
            <Translation id={getTherapyPathTranslationKey(therapyPathType)} />
          </Text>
        </Flex>
        <Flex>{getTherapyPathIllustration(therapyPathType)}</Flex>
      </Flex>
      <Flex $direction="row" $gap={8} $pb={16} $px={16}>
        <Avatar
          image={data.findTherapistProfileById!.therapist?.profileImage?.m}
          name={data.findTherapistProfileById!.fullName}
          size="md"
        />
        <Flex $grow={1} $shrink={1}>
          <Text ellipsis kind="paragraph-strong">
            {data.findTherapistProfileById!.fullName}
          </Text>
          <Text ellipsis>
            <Translation
              id={getTherapistProfessionTranslationKey(therapyPathType, data.findTherapistProfileById!.therapist!.sex)}
            />
          </Text>
        </Flex>
      </Flex>
    </Card>
  )
}

type PathCardSkeletonProps = {
  isLoading?: boolean
}

export const PathCardSkeleton = ({ isLoading }: PathCardSkeletonProps) => (
  <Card $backgroundColorName="neutral-20" $justify="space-between" $minHeight={228} aria-busy={isLoading}>
    <Skeleton
      $active={isLoading}
      $backgroundColorName="neutral-40"
      $maxWidth={260}
      $minHeight={TYPOGRAPHY_PUBLIC_H1_LINE_HEIGHT}
    />
    <Flex $direction="row" $gap={8}>
      <Skeleton
        $active={isLoading}
        $backgroundColorName="neutral-40"
        $borderRadius={BORDER_RADIUS_CIRCLE}
        $minHeight={48}
        $minWidth={48}
      />
      <Flex $gap={8} $grow={1} $justify="space-between" $shrink={1}>
        <Skeleton $active={isLoading} $backgroundColorName="neutral-40" $grow={1} $maxWidth={180} />
        <Skeleton $active={isLoading} $backgroundColorName="neutral-40" $grow={1} $maxWidth={240} />
      </Flex>
    </Flex>
  </Card>
)

type PathCardProps = {
  therapistId: string
  therapyPathType: TherapyTherapyPathType
}

export const PathCard = ({ therapistId, therapyPathType }: PathCardProps) => (
  <ErrorBoundary fallback={<PathCardSkeleton />}>
    <Suspense fallback={<PathCardSkeleton isLoading />}>
      <PathCardLoaded therapistId={therapistId} therapyPathType={therapyPathType} />
    </Suspense>
  </ErrorBoundary>
)

export const NewPathCard = () => (
  <Card $backgroundColorName="lighter" $justify="space-between" $minHeight={228} $position="relative">
    <Skeleton $backgroundColorName="neutral-30" $maxWidth={260} $minHeight={TYPOGRAPHY_PUBLIC_H1_LINE_HEIGHT} />
    <Flex $direction="row" $gap={8}>
      <Skeleton $backgroundColorName="neutral-30" $borderRadius={BORDER_RADIUS_CIRCLE} $minHeight={48} $minWidth={48} />
      <Flex $gap={8} $grow={1} $justify="space-between" $shrink={1}>
        <Skeleton $backgroundColorName="neutral-30" $grow={1} $maxWidth={180} />
        <Skeleton $backgroundColorName="neutral-30" $grow={1} $maxWidth={240} />
      </Flex>
    </Flex>
    <PositionAbsolute $align="center" $bottom={0} $justify="center" $left={0} $right={0} $top={0}>
      <Flex $backgroundColorName="primary" $borderRadius={BORDER_RADIUS_CIRCLE} $p={8}>
        <Icon Svg={Plus} colorName="white" size={24} />
      </Flex>
    </PositionAbsolute>
  </Card>
)
