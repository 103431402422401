import { Flex } from 'cdk'
import { Icon, PageEdit } from 'icons'
import { useCallback } from 'react'
import { Text } from 'ui'
import { ActionWrapper } from '~/components/patientDetail/ActionWrapper'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useModals } from '~/domains/modals'
import { useLatestClinicalBondByPatientId } from '~/hooks/useLatestClinicalBondByPatientId'
import { ProductUserFeedbackClinicalBondModal } from './ProductUserFeedbackClinicalBondModal'

type Props = {
  patientId: string
}

export const ProductUserFeedbackClinicalBond = ({ patientId }: Props) => {
  const { latestClinicalBondByPatientId, loading } = useLatestClinicalBondByPatientId(patientId)
  const { open } = useModals()

  const onClick = useCallback(() => {
    open('clinicalBondView')
  }, [open])

  if (!latestClinicalBondByPatientId) {
    return null
  }

  return (
    <>
      <Flex $pt={8}>
        <ActionWrapper disabled={loading} onClick={onClick}>
          <Flex>
            <Icon Svg={PageEdit} colorName="neutral-80" size={24} />
          </Flex>
          <Flex $gap={4} $grow={1} $shrink={1}>
            <Text fontWeight="600" kind="caption">
              <Translation id="clinicalBond.title" />
            </Text>
            <TranslationMarkdown
              colorName="neutral-80"
              colorNameBold="black"
              id="clinicalBond.score"
              kind="footnote"
              textAlign="left"
              values={{ score: latestClinicalBondByPatientId.score }}
            />
          </Flex>
          <Text colorName="primary" fontWeight="600" kind="caption">
            <Translation id="actions.read" />
          </Text>
        </ActionWrapper>
      </Flex>
      <ProductUserFeedbackClinicalBondModal patientId={patientId} />
    </>
  )
}
