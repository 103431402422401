import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { type TranslationId } from '~/i18n/types'
import { type TherapyTherapyPathType } from '~/types/graphql'

const translationIdByTherapyPathType: Record<TherapyTherapyPathType, TranslationId> = {
  PATH_ASSERTIVE_COMMUNICATION: 'patientAgenda.therapySessionCard.therapySessionType.path.assertiveCommunication',
  PATH_COACHING: 'patientAgenda.therapySessionCard.therapySessionType.path.coaching',
  PATH_SLEEP: 'patientAgenda.therapySessionCard.therapySessionType.path.sleep',
  COUPLES_PSYCHOTHERAPY: 'patientAgenda.therapySessionCard.therapySessionType.couples',
  MYSELF_PSYCHIATRY: 'patientAgenda.therapySessionCard.therapySessionType.psychiatry',
  MYSELF_PSYCHOTHERAPY: 'patientAgenda.therapySessionCard.therapySessionType.individual',
  PATH_SEXOLOGY: 'patientAgenda.therapySessionCard.therapySessionType.individual',
  PATH_NUTRITION_DCA: 'patientAgenda.therapySessionCard.therapySessionType.dca',
  PATH_NUTRITION_WEIGHT_LOSS: 'patientAgenda.therapySessionCard.therapySessionType.weightLoss',
}

type Props = {
  therapyPathType: TherapyTherapyPathType | null
}

export const TherapySessionCardType = ({ therapyPathType }: Props) => {
  if (therapyPathType == null) {
    return null
  }

  return (
    <Text fontWeight="400" kind="paragraph">
      <Translation id={translationIdByTherapyPathType[therapyPathType]} />
    </Text>
  )
}
