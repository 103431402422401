import { useMemo } from 'react'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useTranslate } from '~/i18n/hooks/useTranslation'
import { type BatchRecurrencyRepeat } from '~/types/graphql'
import { ReactHookFormSelect } from '../components'

type Props = {
  defaultValue?: BatchRecurrencyRepeat
}

export const FieldTherapySessionRepeat = ({ defaultValue = 'WEEK' }: Props) => {
  const {
    errors: { required },
  } = useFormTranslations()
  const translate = useTranslate()

  const options = useMemo(
    (): { label: string; value: BatchRecurrencyRepeat }[] => [
      {
        label: translate('forms.labels.therapySessionsRepeatEveryDay'),
        value: 'DAY',
      },
      {
        label: translate('forms.labels.therapySessionsRepeatEveryWeek'),
        value: 'WEEK',
      },
      {
        label: translate('forms.labels.therapySessionsRepeatEveryMonth'),
        value: 'MONTH',
      },
    ],
    [translate],
  )

  return (
    <ReactHookFormSelect defaultValue={String(defaultValue)} name="repeat" rules={{ required }}>
      {options.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </ReactHookFormSelect>
  )
}
