import { Flex, OverflowAuto } from 'cdk'
import { memo } from 'react'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { MenuFooter } from './common/MenuFooter'
import { MenuGroup } from './common/MenuGroup'
import { MenuGroupTitle } from './common/MenuGroupTitle'
import { MenuGroupVoices } from './common/MenuGroupVoices'
import { MenuHeader } from './items/MenuHeader'
import { MenuItemAccount } from './items/MenuItemAccount'
import { MenuItemBilling } from './items/MenuItemBilling'
import { MenuItemChangelog } from './items/MenuItemChangelog'
import { MenuItemConvention } from './items/MenuItemConvention'
import { MenuItemEmergency } from './items/MenuItemEmergency'
import { MenuItemFAQ } from './items/MenuItemFAQ'
import { MenuItemFeedback } from './items/MenuItemFeedback'
import { MenuItemInvoices } from './items/MenuItemInvoices'
import { MenuItemNotifications } from './items/MenuItemNotifications'
import { MenuItemPayments } from './items/MenuItemPayments'
import { MenuItemPrivacyPolicy } from './items/MenuItemPrivacyPolicy'
import { MenuItemTermsAndConditions } from './items/MenuItemTermsAndConditions'
import { MenuItemWriteUs } from './items/MenuItemWriteUs'

export const PatientTabMenu = memo(({ handleAutoClose }: { handleAutoClose?: () => void }) => {
  const { isVariant1 } = useFeatureFlagsByUserId()

  return (
    <>
      <MenuHeader />

      <OverflowAuto $justify="space-between" $p={16} data-test-id="navigation-tab-menu">
        <Flex $gap={32} $grow={1}>
          <MenuGroup>
            <MenuGroupTitle id="menu.menu" />

            <MenuGroupVoices>
              <Flex onClick={handleAutoClose}>
                <MenuItemAccount />
              </Flex>

              <Flex onClick={handleAutoClose}>
                <MenuItemPayments />
              </Flex>

              {isVariant1('ff_new_patient_settings_payments') && (
                <Flex onClick={handleAutoClose}>
                  <MenuItemBilling />
                </Flex>
              )}

              <Flex onClick={handleAutoClose}>
                <MenuItemInvoices />
              </Flex>

              <Flex onClick={handleAutoClose}>
                <MenuItemNotifications />
              </Flex>

              <Flex onClick={handleAutoClose}>
                <MenuItemConvention />
              </Flex>
            </MenuGroupVoices>
          </MenuGroup>

          <MenuGroup>
            <MenuGroupTitle id="menu.help" />

            <MenuGroupVoices>
              <Flex onClick={handleAutoClose}>
                <MenuItemFAQ />
              </Flex>

              <Flex onClick={handleAutoClose}>
                <MenuItemWriteUs />
              </Flex>

              <MenuItemEmergency />

              <MenuItemFeedback />

              <MenuItemChangelog />
            </MenuGroupVoices>
          </MenuGroup>

          <MenuGroup>
            <MenuGroupTitle id="menu.legal" />

            <MenuGroupVoices>
              <MenuItemTermsAndConditions />
              <MenuItemPrivacyPolicy />
            </MenuGroupVoices>
          </MenuGroup>
        </Flex>

        <MenuFooter />
      </OverflowAuto>
    </>
  )
})

PatientTabMenu.displayName = 'PatientTabMenu'
