import { Flex } from 'cdk'
import { noop } from 'functions'
import { Icon, PharmacyCrossTag } from 'icons'
import { Modal, ModalProvider, ModalTrigger, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { ActionWrapper } from './ActionWrapper'
import { RequestPsychiatristForm } from './RequestPsychiatristForm'

type Props = {
  therapyId: string
}

export const RequestPsychiatrist = ({ therapyId }: Props) => (
  <ModalProvider>
    <ModalTrigger>
      <ActionWrapper disabled={false} onClick={noop}>
        <Flex>
          <Icon Svg={PharmacyCrossTag} colorName="neutral-80" size={24} />
        </Flex>
        <Flex $grow={1} $shrink={1}>
          <Text fontWeight="600" kind="caption">
            <Translation id="patientDetail.requestPsychiatrist.title" />
          </Text>
          <Text kind="caption">
            <Translation id="patientDetail.requestPsychiatrist.subtitle" />
          </Text>
        </Flex>
        <Text colorName="primary" fontWeight="600" kind="caption">
          <Translation id="patientDetail.requestPsychiatrist.action" />
        </Text>
      </ActionWrapper>
    </ModalTrigger>

    <Modal>
      <Text colorName="primary" fontWeight="600" kind="paragraph">
        <Translation id="patientDetail.requestPsychiatrist.modal.title" />
      </Text>
      <RequestPsychiatristForm therapyId={therapyId} />
    </Modal>
  </ModalProvider>
)
