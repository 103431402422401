import { Icon, Repeat } from 'icons'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { ReactHookFormSwitchField } from '~/domains/react-hook-form'

type Props = {
  disabled?: boolean
}

export const RecurringTherapyField = ({ disabled = false }: Props) => (
  <Flex align="center" direction="row" pt={40}>
    <Icon Svg={Repeat} colorName="grey-80" size={24} />
    <Flex pl={8} pr={24}>
      <Text colorName="grey-80" fontWeight="400" kind="paragraph">
        <Translation id="forms.label.recurringTherapy" />
      </Text>
    </Flex>
    <ReactHookFormSwitchField disabled={disabled} name="recurringTherapy" />
  </Flex>
)
