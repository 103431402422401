import { CompositeButton, ErrorFromResponse, useCall } from '@stream-io/video-react-sdk'
import { Flex, OverflowAuto } from 'cdk'
import { Icon, Phone } from 'icons'
import { useCallback, useEffect, useState } from 'react'
import { Prompt } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Modal, ModalDescription, ModalProvider, ModalTitle, useModalContext } from 'ui'
import { Translation } from '~/components/Translation'
import { useVideocallEventLogger } from '~/domains/videocall'
import { usePatientAgenda } from '~/hooks/usePatientAgenda'

const Rotated = styled.div`
  display: flex;
  transform: rotate(139deg);
`

const CancelClosing = () => {
  const { close } = useModalContext()

  return (
    <Button kind="primary" onClick={close}>
      <Translation id="actions.stayVideocall" />
    </Button>
  )
}

export const CloseVideocall = () => {
  const { refetch: refetchPatientAgenda } = usePatientAgenda()
  const call = useCall()
  const [isLeaving, setIsLeaving] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const logFailure = useVideocallEventLogger()

  const handleClick = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleLeave = useCallback(async () => {
    setIsLeaving(true)

    // NOTE: because the agenda status depends on various factors and it's calculated backend side the safest option is to refetch
    await refetchPatientAgenda()

    try {
      await call?.leave({ reason: 'user clicks on "Close" button' })
    } catch (error) {
      const isRecoverable = !(error instanceof ErrorFromResponse && error.unrecoverable)
      logFailure(isRecoverable ? 'videocall.call.leave' : 'videocall.call.leave.unrecoverable', error)
    }

    setIsLeaving(false)
  }, [call, refetchPatientAgenda, logFailure])

  const handleLeaveFromPrompt = useCallback(() => {
    handleClick()

    return isLeaving
  }, [handleClick, isLeaving])

  useEffect(() => {
    const onBeforeUnload = (event: Event) => {
      event.preventDefault()
    }

    window.addEventListener('beforeunload', onBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [call, handleLeaveFromPrompt])

  return (
    <>
      <Prompt message={handleLeaveFromPrompt} />
      <ModalProvider isOpen={isOpen} onIsOpenChange={setIsOpen}>
        <CompositeButton active onClick={handleClick}>
          <Rotated>
            <Icon Svg={Phone} colorName="white" size={24} />
          </Rotated>
        </CompositeButton>
        <Modal>
          <OverflowAuto>
            <ModalTitle>
              <Translation id="videocall.ended.title" />
            </ModalTitle>
            <Flex $pt={8} />
            <ModalDescription>
              <Translation id="videocall.ended.description" />
            </ModalDescription>
          </OverflowAuto>
          <Flex $pt={8}>
            <CancelClosing />
            <Flex $pt={8}>
              <Button isGhost isLoading={isLeaving} kind="primary" onClick={handleLeave}>
                <Translation id="actions.leaveVideocall" />
              </Button>
            </Flex>
          </Flex>
        </Modal>
      </ModalProvider>
    </>
  )
}
