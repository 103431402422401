import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Button, Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { getClinicalBondVariantRoute } from './getClinicalBondVariantRoute'

export const ClinicalBondStepFirst = () => {
  const history = useHistory()

  const handleNextClick = useCallback(() => {
    history.push(getClinicalBondVariantRoute('/feeling'))
  }, [history])

  return (
    <>
      <OverflowAuto>
        <Flex pt={16}>
          <Text fontWeight="600" kind="h3">
            <Translation id="clinicalBond.first.title" />
          </Text>
        </Flex>
        <TranslationMarkdown id="clinicalBond.first.body" kind="paragraph" pt={16} />
      </OverflowAuto>
      <Flex pt={24}>
        <Button kind="primary" onClick={handleNextClick}>
          <Translation id="actions.continue" />
        </Button>
      </Flex>
    </>
  )
}
