import { Icon, User } from 'icons'
import { useCallback } from 'react'
import { TertiaryButtonIconOnly } from '~/components/TertiaryButton/TertiaryButtonIconOnly'
import { useVideocallSidePanel } from '../../../hooks/useVideocallSidePanel'

export const OpenPatientDetail = () => {
  const { setIsPatientDetailOpen, isPatientDetailOpen } = useVideocallSidePanel()

  const handleOnClick = useCallback(() => {
    setIsPatientDetailOpen(!isPatientDetailOpen)
  }, [isPatientDetailOpen, setIsPatientDetailOpen])

  return (
    <TertiaryButtonIconOnly
      background={isPatientDetailOpen ? 'primary' : undefined}
      iconColorName="white"
      onClick={handleOnClick}
    >
      <Icon Svg={User} size={24} />
    </TertiaryButtonIconOnly>
  )
}
