import { Button } from 'ui'
import { Translation } from '~/components/Translation'
import { useReactHookFormContext } from '../react-hook-form'

export const FormNextButton = () => {
  const form = useReactHookFormContext()

  return (
    <Button
      disabled={!form.formState.isValid}
      form="firstSessionFeedback"
      isLoading={form.formState.isSubmitting}
      kind="primary"
      type="submit"
    >
      <Translation id="actions.continue" />
    </Button>
  )
}
