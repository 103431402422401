import { Icon, Plus } from 'icons'
import { Button, Drawer, DrawerHeader, DrawerProvider, DrawerTitle, DrawerTrigger } from 'ui'
import { Translation } from '~/components/Translation'
import { MealForm } from '../MealForm'

export const AddMealCTA = () => (
  <DrawerProvider>
    <DrawerTrigger>
      <Button kind="primary" size="md">
        <Icon Svg={Plus} size={24} />
        <Translation id="nutrition.diary.addMealCta" />
      </Button>
    </DrawerTrigger>

    <Drawer>
      <DrawerHeader>
        <DrawerTitle>
          <Translation id="nutrition.diary.addMealDrawer.title" />
        </DrawerTitle>
      </DrawerHeader>

      <MealForm />
    </Drawer>
  </DrawerProvider>
)
