import { Flex } from 'cdk'
import { COLOR_NEUTRAL_30, COLOR_NEUTRAL_40 } from 'design-tokens'
import styled, { keyframes } from 'styled-components'

const animation = keyframes`
  0% { background-position: 200% 100% }
  100% { background-position: -200% 100% }
`

export const Skeleton = styled(Flex)`
  margin: 0;
  background: ${COLOR_NEUTRAL_30};
  background: linear-gradient(90deg, ${COLOR_NEUTRAL_40} 0%, ${COLOR_NEUTRAL_30} 50%, ${COLOR_NEUTRAL_40} 100%);
  background-size: 200% 100%;
  animation: 2s ${animation} linear infinite;
`
