import { type ReactNode } from 'react'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useUserRedirectEffect } from '~/hooks/useUserRedirectEffect'
import { type UserFeature } from '~/types/graphql'
import { type Route } from '~/utils/getRoute'

type Props = {
  children: ReactNode
  feature: UserFeature
  target?: Route
}

const assertHasFeature = (features: UserFeature[], feature: UserFeature) => features.includes(feature)

export const AssertHasFeature = ({ children, feature, target }: Props) => {
  const { features } = useCurrentUser()

  const isAuthorized = assertHasFeature(features, feature)

  useUserRedirectEffect(!isAuthorized, target)

  if (!isAuthorized) {
    return null
  }

  return <>{children}</>
}
