import { Flex, Form, OverflowAuto } from 'cdk'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { slugify } from 'strings'
import { Button, ModalDescription, ModalTitle, Radio, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useTrackEvent } from '~/domains/analytics/hooks/useTrackEvent'
import { useTrackEventView } from '~/domains/analytics/hooks/useTrackEventView'
import { useUserFormFlowResponseCompleted } from '~/domains/formFlow/hooks/useUserFormFlowResponseCompleted'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useLoadingState } from '~/hooks/useLoadingState'
import { useRootHistory } from '~/hooks/useRootHistory'
import { useToasts } from '~/hooks/useToasts'
import { type TranslationId } from '~/i18n/types'
import { getRoute } from '~/utils/getRoute'
import { useChangeTherapist } from '../hooks/useChangeTherapist'
import { getChangeTherapistModalRoutes } from '../utils/routes'
import { type ChangeTherapistReasonValue, type FormValues } from './ChangeTherapistModal'

type Option = {
  id: TranslationId
  value: ChangeTherapistReasonValue
}

const CHANGE_THERAPIST_REASON_OPTIONS: Option[] = [
  { id: 'changeTherapist.reason.variant.badTimeSlots', value: 'BAD_TIME_SLOTS' },
  { id: 'changeTherapist.reason.variant.needToKnowMultipleTherapists', value: 'NEED_TO_KNOW_MULTIPLE_THERAPISTS' },
  { id: 'changeTherapist.reason.variant.therapyOrientation', value: 'THERAPY_ORIENTATION' },
  { id: 'changeTherapist.reason.variant.changedGoals', value: 'CHANGED_GOALS' },
  { id: 'changeTherapist.reason.variant.therapistAge', value: 'THERAPIST_AGE' },
  { id: 'changeTherapist.reason.variant.therapistGender', value: 'THERAPIST_GENDER' },
  { id: 'changeTherapist.reason.variant.badProfilePicture', value: 'BAD_PROFILE_PICTURE' },
  { id: 'changeTherapist.reason.variant.other.step.1', value: 'OTHER_MANUAL' },
]

const ChangeTherapistReasonVariantAutoReasons = () => {
  const {
    errors: { required },
  } = useFormTranslations()
  const { formState, register } = useReactHookFormContext<FormValues>()

  const hasError = !!formState.errors.changeTherapistReason?.message

  return (
    <Flex $gap={16}>
      {hasError && (
        <Text colorName="error" kind="caption">
          {required}
        </Text>
      )}
      {CHANGE_THERAPIST_REASON_OPTIONS.map(({ id, value }) => (
        <Flex
          key={id}
          $align="center"
          $direction="row"
          $gap={8}
          $px={8}
          as="label"
          data-test-id={`change-therapist-reason-${slugify(value)}`}
          htmlFor={`changeTherapistReason-${value}`}
        >
          <Radio
            {...register('changeTherapistReason', { required })}
            id={`changeTherapistReason-${value}`}
            required
            value={value}
          />
          <Translation id={id} />
        </Flex>
      ))}
    </Flex>
  )
}

type Props = {
  therapyId: string
}

export const ChangeTherapistReasonAuto = ({ therapyId }: Props) => {
  const { loading, startLoading, stopLoading } = useLoadingState()
  const history = useHistory()
  const rootHistory = useRootHistory()
  const { addToast } = useToasts()
  const trackClick = useTrackEvent('CLICK')
  const [changeTherapist] = useChangeTherapist()
  const { userFormFlowResponseCompletedId, loading: loadingFormFlowCompleted } = useUserFormFlowResponseCompleted()

  const form = useReactHookFormContext<FormValues>()

  const onSubmit = useCallback(
    async ({ changeTherapistReason }: FormValues) => {
      if (changeTherapistReason === 'OTHER_MANUAL') {
        history.push(getChangeTherapistModalRoutes('/changeTherapistReasonManual'))

        return
      }

      startLoading('local')

      try {
        await changeTherapist({
          variables: {
            input: {
              changeTherapistReason,
              therapyId,
            },
          },
        })

        if (userFormFlowResponseCompletedId && changeTherapistReason === 'CHANGED_GOALS') {
          rootHistory.push(getRoute(`/change-therapist/${therapyId}`))

          return
        }

        if (userFormFlowResponseCompletedId) {
          rootHistory.push(getRoute(`/change-therapist/${therapyId}/preferences`))

          return
        }

        rootHistory.push(getRoute('/start'))
      } catch {
        addToast({ type: 'alert', translationId: 'generic.error' })
      } finally {
        stopLoading()

        trackClick({ name: 'clicks.support.i-want-to-change-therapist' })
      }
    },
    [
      addToast,
      changeTherapist,
      history,
      rootHistory,
      startLoading,
      stopLoading,
      therapyId,
      trackClick,
      userFormFlowResponseCompletedId,
    ],
  )

  useTrackEventView('changeTherapist.modal.auto')

  return (
    <Form $gap={16} $grow={1} $shrink={1} onSubmit={form.handleSubmit(onSubmit)}>
      <OverflowAuto $gap={16}>
        <ModalTitle>
          <Translation id="changeTherapist.modal.variant.step.1.title" />
        </ModalTitle>
        <ModalDescription>
          <Translation id="changeTherapist.modal.variant.step.1.description" />
        </ModalDescription>
        <ChangeTherapistReasonVariantAutoReasons />
      </OverflowAuto>
      <Button
        disabled={loading || loadingFormFlowCompleted}
        isLoading={loading || loadingFormFlowCompleted}
        kind="primary"
        type="submit"
      >
        <Translation id="actions.proceed" />
      </Button>
    </Form>
  )
}
