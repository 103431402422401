import { parseISO } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { useMemo } from 'react'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useChatMessage } from '~/domains/chat/hooks/useChatMessage'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { getTherapySessionDayAndMonthLong } from '~/utils/dates/getTherapySessionDayAndMonth'
import { getTherapySessionStartAt } from '~/utils/dates/getTherapySessionStartAt'
import { useChatPatient } from '../../hooks/useChatPatient'
import { CustomMessageWrapper } from './CustomMessageWrapper'

const RecurrencyDeletedTherapist = () => {
  const { message } = useChatMessage<'SYSTEM_PATIENT_RECURRENCY_DELETED'>()

  const patient = useChatPatient()

  const startAt = message.srns?.payload.startAt

  if (!startAt || !patient.user) {
    return null
  }

  return (
    <>
      <Flex pb={8}>
        <Text fontWeight="600" kind="caption">
          <Translation id="systemMessage.therapist.recurrencyDeletedByPatient.title" />
        </Text>
      </Flex>
      <TranslationMarkdown
        id="systemMessage.therapist.recurrencyDeletedByPatient.body"
        kind="caption"
        values={{
          therapySessionsStartAtTime: pipe(startAt, parseISO, getTherapySessionStartAt),
          therapySessionsStartAtDay: pipe(startAt, parseISO, getTherapySessionDayAndMonthLong),
        }}
      />
    </>
  )
}

const RecurrencyDeletedPatient = () => {
  const { message } = useChatMessage<'SYSTEM_PATIENT_RECURRENCY_DELETED'>()

  const startAt = message.srns?.payload.startAt
  const therapistId = message.srns?.payload.therapistId

  const { therapies } = usePatientTherapies()

  const therapy = useMemo(
    () => therapies.find((therapy) => therapy.therapist?.id === therapistId),
    [therapies, therapistId],
  )

  if (!startAt || !therapy?.therapist) {
    return null
  }

  return (
    <>
      <Flex pb={8}>
        <Text fontWeight="600" kind="caption">
          <Translation id="systemMessage.patient.recurrencyDeletedByPatient.title" />
        </Text>
      </Flex>
      <TranslationMarkdown
        id="systemMessage.patient.recurrencyDeletedByPatient.body"
        kind="caption"
        values={{
          therapySessionsStartAtTime: pipe(startAt, parseISO, getTherapySessionStartAt),
          therapySessionsStartAtDay: pipe(startAt, parseISO, getTherapySessionDayAndMonthLong),
        }}
      />
    </>
  )
}

export const MessagePatientRecurrencyDeleted = () => {
  const group = useUserActingAsGroup()

  return (
    <CustomMessageWrapper>
      {group === 'therapist' ? <RecurrencyDeletedTherapist /> : <RecurrencyDeletedPatient />}
    </CustomMessageWrapper>
  )
}
