import { type ChannelMemberResponse } from 'stream-chat'
import { useChatChannel } from '~/domains/chat/hooks/useChatChannel'
import { type ChatProps } from '~/domains/chat/types'

export const useChatTherapistNullable = () => {
  const { channel } = useChatChannel()

  const therapistId = channel?.data?.srns?.therapist_id

  if (!therapistId) {
    return null
  }

  const therapist = channel.state.members[therapistId]

  if (!therapist) {
    return null
  }

  return therapist
}

export const useChatTherapist = () => {
  const therapist = useChatTherapistNullable()

  return therapist as NonNullable<ChannelMemberResponse<ChatProps>>
}
