import { useHistory } from 'react-router-dom'
import { Button } from 'ui-deprecated'
import { Translation } from '~/components/Translation'

export const BackButton = () => {
  const history = useHistory()

  return (
    <Button kind="white" onClick={history.goBack}>
      <Translation id="actions.back" />
    </Button>
  )
}
