import { gql, useQuery } from '@apollo/client'
import { type GetDiagnosablePathologiesQuery, type GetDiagnosablePathologiesQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query getDiagnosablePathologies {
    getDiagnosablePathologies {
      id
      name
      type
    }
  }
`

export const useGetDiagnosablePathologies = () => {
  const { data, loading } = useQuery<GetDiagnosablePathologiesQuery, GetDiagnosablePathologiesQueryVariables>(QUERY, {
    fetchPolicy: 'cache-first',
  })

  return {
    pathologies: data?.getDiagnosablePathologies ?? [],
    getDiagnosablePathologiesLoading: loading,
  }
}
