import { Flex, MountOn } from 'cdk'
import { SPACING_SM } from 'design-tokens'
import { useFormContext } from 'react-hook-form'
import { Button } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useLoadingState } from '~/hooks/useLoadingState'

export const SignupFormCTAs = () => {
  const { formState } = useFormContext()
  const { loadingKey } = useLoadingState()

  const localLoading = formState.isSubmitting || loadingKey === 'local'

  return (
    <>
      <Button disabled={localLoading} id="signup-button" kind="primary" size="lg" type="submit">
        <Translation id="actions.createAccountAndContinue" />
      </Button>

      <MountOn mediaQuery="lt-lg">
        <Flex $align="center" $pt={SPACING_SM}>
          <TranslationMarkdown colorName="darker" id="generic.signup.notASubscription" kind="caption" />
        </Flex>
      </MountOn>
    </>
  )
}
