import { noop } from 'functions'
import { createContext, type ReactNode, useContext, useMemo } from 'react'
import { useGetCountries } from '~/domains/diagnosis/queries/getCountries'
import { useGetDiagnosablePathologies } from '~/domains/diagnosis/queries/getDiagnosablePathologies'
import { useGetGenderIdentities } from '~/domains/diagnosis/queries/getGenderIdentities'
import { useGetLanguages } from '~/domains/diagnosis/queries/getLanguages'
import { useGetWorkProfessions } from '~/domains/diagnosis/queries/getWorkProfessions'
import { usePartnerFindByPatientId } from '~/domains/partner/hooks/usePartnerFindByPatientId'
import { usePatientFindById } from '~/hooks/usePatientFindById'
import {
  type ActionResponse,
  type DiagnosisCouplesQuery,
  type DiagnosisCouplesUpdateInput,
  type GetCountriesQuery,
  type GetDiagnosablePathologiesQuery,
  type GetGenderIdentitiesQuery,
  type GetLanguagesQuery,
  type GetWorkProfessionsQuery,
  type PartnerFindByPatientIdQuery,
  type PatientFindByIdQuery,
} from '~/types/graphql'
import { useDiagnosisCouplesQuery } from './useDiagnosisCouplesQuery'
import { useDiagnosisCoupleUpdate } from './useDiagnosisCoupleUpdate'

type Context = {
  countries: NonNullable<GetCountriesQuery['getCountries']>
  diagnosisCouples: DiagnosisCouplesQuery['diagnosisCouples']
  diagnosisCouplesUpdate: (values: DiagnosisCouplesUpdateInput) => Promise<ActionResponse>
  error: boolean
  genderIdentities: NonNullable<GetGenderIdentitiesQuery['getGenderIdentities']>
  languages: NonNullable<GetLanguagesQuery['getLanguages']>
  loading: boolean
  pathologiesCouples: NonNullable<GetDiagnosablePathologiesQuery['getDiagnosablePathologies']>
  pathologiesMyself: NonNullable<GetDiagnosablePathologiesQuery['getDiagnosablePathologies']>
  partner: PartnerFindByPatientIdQuery['partnerFindByPatientId']
  patient: PatientFindByIdQuery['patientFindById']
  patientId: string
  workProfessions: NonNullable<GetWorkProfessionsQuery['getWorkProfessions']>
  isEditable: boolean
}

const defaultContext: Omit<Context, 'error' | 'loading' | 'patientId'> = {
  countries: [],
  diagnosisCouples: null,
  diagnosisCouplesUpdate: noop,
  genderIdentities: [],
  languages: [],
  partner: null,
  pathologiesCouples: [],
  pathologiesMyself: [],
  patient: null,
  workProfessions: [],
  isEditable: true,
}

const DiagnosisCouplesContext = createContext<Context | null>(null)

type Props = {
  children: ReactNode
  patientId: string
  isEditable?: boolean
}

export const DiagnosisCouplesProvider = ({ children, patientId, isEditable = true }: Props) => {
  const { countries, getCountriesLoading } = useGetCountries()
  const { diagnosisCouples, loading: diagnosisCouplesLoading } = useDiagnosisCouplesQuery(patientId)
  const { diagnosisCouplesUpdate } = useDiagnosisCoupleUpdate(patientId)
  const { genderIdentities, getGenderIdentitiesLoading } = useGetGenderIdentities()
  const { languages, getLanguagesLoading } = useGetLanguages()
  const { pathologies, getDiagnosablePathologiesLoading } = useGetDiagnosablePathologies()
  const { workProfessions, getWorkProfessionsLoading } = useGetWorkProfessions()
  const { patient, loading: patientLoading } = usePatientFindById(patientId)
  const { partner, loading: partnerLoading } = usePartnerFindByPatientId(patientId)

  const value = useMemo((): Context => {
    if (
      getCountriesLoading ||
      getDiagnosablePathologiesLoading ||
      diagnosisCouplesLoading ||
      getGenderIdentitiesLoading ||
      getLanguagesLoading ||
      getWorkProfessionsLoading ||
      patientLoading ||
      partnerLoading
    ) {
      return {
        ...defaultContext,
        error: false,
        loading: true,
        patientId,
      }
    }

    if (
      !countries.length ||
      !genderIdentities.length ||
      !languages.length ||
      !pathologies.length ||
      !workProfessions.length
    ) {
      return {
        ...defaultContext,
        error: true,
        loading: false,
        partner,
        patient,
        patientId,
      }
    }

    return {
      countries,
      diagnosisCouples,
      diagnosisCouplesUpdate,
      error: false,
      genderIdentities,
      languages,
      loading: false,
      pathologiesCouples: pathologies.filter((p) => p.type === 'COUPLES'),
      pathologiesMyself: pathologies.filter((p) => p.type !== 'COUPLES'),
      patient,
      patientId,
      partner,
      workProfessions,
      isEditable,
    }
  }, [
    countries,
    diagnosisCouples,
    diagnosisCouplesLoading,
    diagnosisCouplesUpdate,
    genderIdentities,
    getCountriesLoading,
    getDiagnosablePathologiesLoading,
    getGenderIdentitiesLoading,
    getLanguagesLoading,
    getWorkProfessionsLoading,
    languages,
    partner,
    partnerLoading,
    pathologies,
    patient,
    patientId,
    patientLoading,
    workProfessions,
    isEditable,
  ])

  return <DiagnosisCouplesContext.Provider value={value}>{children}</DiagnosisCouplesContext.Provider>
}

export const useDiagnosisCouples = () => {
  const state = useContext(DiagnosisCouplesContext)

  if (!state) {
    throw new Error('The `useDiagnosisCouples` should be wrapped with `DiagnosisCouplesProvider`.')
  }

  return state
}
