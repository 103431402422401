import { type TranslationId } from '~/i18n/types'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { Translation } from './Translation'

const CHANGE_PROFESSIONAL_TRANSLATION_ID: Record<TherapyTherapyPathType, TranslationId> = {
  COUPLES_PSYCHOTHERAPY: 'actions.changeProfessional',
  MYSELF_PSYCHIATRY: 'actions.changeProfessional',
  MYSELF_PSYCHOTHERAPY: 'actions.changeProfessional',
  PATH_ASSERTIVE_COMMUNICATION: 'actions.changeProfessional',
  PATH_COACHING: 'actions.changeProfessional.coach',
  PATH_NUTRITION_DCA: 'actions.changeProfessional.nutritionist',
  PATH_NUTRITION_WEIGHT_LOSS: 'actions.changeProfessional.nutritionist',
  PATH_SEXOLOGY: 'actions.changeProfessional',
  PATH_SLEEP: 'actions.changeProfessional',
}

type Props = {
  // @TODO(comes): https://linear.app/serenis/issue/ENG-555
  therapyPathType: TherapyTherapyPathType | null | undefined
}

export const ChangeProfessionalTranslation = ({ therapyPathType }: Props) => (
  <Translation id={CHANGE_PROFESSIONAL_TRANSLATION_ID[therapyPathType ?? 'MYSELF_PSYCHOTHERAPY']} />
)
