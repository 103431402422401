import { Flex } from 'cdk'
import { SPACING_MD, SPACING_SM, SPACING_XS } from 'design-tokens'
import { useHistory } from 'react-router-dom'
import { Button, DrawerFooter, DrawerHeader } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { ReactHookFormTextArea, useReactHookFormContext } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useManageSession } from '../hooks/useManageSession'

const MIN_TEXTAREA_HEIGHT = '200px'
const MAX_MESSAGE_LENGTH = 600

export const RescheduleSessionMessage = () => {
  const { loading, therapistName } = useManageSession()
  const { formState } = useReactHookFormContext()
  const history = useHistory()

  const {
    errors: { required },
    placeholders: { editTherapySession },
  } = useFormTranslations()

  return (
    <>
      <DrawerHeader />
      <Flex $gap={SPACING_XS} $pb={SPACING_SM}>
        <TranslationMarkdown
          colorName="darker"
          id="therapySession.rescheduleSession.message.title"
          kind="h3"
          values={{ therapistName }}
        />
        <TranslationMarkdown colorName="darker" id="therapySession.rescheduleSession.message.subtitle" />
      </Flex>
      <ReactHookFormTextArea
        max={MAX_MESSAGE_LENGTH}
        minHeight={MIN_TEXTAREA_HEIGHT}
        name="message"
        placeholder={editTherapySession}
        rules={{ max: MAX_MESSAGE_LENGTH, required }}
      />
      <DrawerFooter>
        <Flex $direction="row" $gap={SPACING_MD} $grow={1} $justify="space-between" $shrink={1}>
          <Flex $grow={1} $shrink={1}>
            <Button data-test-id="therapy-session-go-back-button" isGhost kind="primary" onClick={history.goBack}>
              <Translation id="actions.goBack" />
            </Button>
          </Flex>
          <Flex $grow={1} $shrink={1}>
            <Button
              data-test-id="therapy-session-send-and-move-button"
              disabled={!formState.isValid}
              isLoading={loading}
              kind="primary"
              type="submit"
            >
              <Translation id="paths.rescheduleSession.cta" />
            </Button>
          </Flex>
        </Flex>
      </DrawerFooter>
    </>
  )
}
