import { Flex, OverflowAuto, Pressable } from 'cdk'
import { noop } from 'functions'
import { useCopyToClipboard } from 'hooks'
import { Copy, Icon, User } from 'icons'
import { useCallback } from 'react'
import { Modal, ModalProvider, ModalTrigger, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useFindTherapySharedInformation } from '~/domains/therapies/hooks/findTherapySharedInformation'
import { useToasts } from '~/hooks/useToasts'
import { Markdown } from '../Markdown'
import { TranslationMarkdown } from '../TranslationMarkdown'
import { ActionWrapper } from './ActionWrapper'

type Props = {
  therapyId: string
}

const toastIdError = 'PsychiatricTherapySharedInformationError'
const toastIdSuccess = 'PsychiatricTherapySharedInformationSuccess'

export const PsychiatricTherapySharedInformation = ({ therapyId }: Props) => {
  const { data, loading } = useFindTherapySharedInformation({ therapyId, type: 'MYSELF_PSYCHIATRY' })
  const [, copy] = useCopyToClipboard()
  const { addToast } = useToasts()

  const handleCopy = useCallback(async () => {
    if (!data?.therapist?.email) {
      return
    }

    const result = await copy(data.therapist.email)

    if (result) {
      addToast({ id: toastIdSuccess, translationId: 'generic.text.copied.success', type: 'success' })

      return
    }

    addToast({ id: toastIdError, translationId: 'generic.text.copied.error', type: 'alert' })
  }, [addToast, copy, data])

  if (loading || !data) {
    return null
  }

  return (
    <Flex $pt={16}>
      <ModalProvider>
        <ModalTrigger>
          <ActionWrapper disabled={false} onClick={noop}>
            <Flex>
              <Icon Svg={User} colorName="neutral-80" size={24} />
            </Flex>
            <Flex $grow={1} $shrink={1}>
              <Text fontWeight="600" kind="caption">
                <Translation id="patientDetail.psychiatricTherapySharedInformation.title" />
              </Text>
            </Flex>
            <Text colorName="primary" fontWeight="600" kind="caption">
              <Translation id="patientDetail.psychiatricTherapySharedInformation.action" />
            </Text>
          </ActionWrapper>
        </ModalTrigger>

        <Modal>
          <Flex $pb={16}>
            <Text colorName="primary" fontWeight="600" kind="paragraph">
              <Translation id="patientDetail.psychiatricTherapySharedInformation.modal.title" />
            </Text>
            {!!data.therapist && (
              <Flex>
                <TranslationMarkdown
                  colorName="darker"
                  id="patientDetail.psychiatricTherapySharedInformation.modal.subtitle"
                  kind="paragraph"
                  pt={2}
                  values={{ therapistFullName: data.therapist.fullName }}
                />
                <Flex $align="center" $direction="row">
                  <Flex $pr={4}>
                    <Text colorName="neutral-80" kind="caption">
                      {data.therapist.email}
                    </Text>
                  </Flex>
                  <Pressable onClick={handleCopy}>
                    <Icon Svg={Copy} colorName="neutral-80" size={12} />
                  </Pressable>
                </Flex>
              </Flex>
            )}
          </Flex>
          <OverflowAuto>
            <Flex $pb={24}>
              <Flex $pb={4}>
                <Text fontWeight="600" kind="paragraph">
                  <Translation id="patientDetail.psychiatricTherapySharedInformation.modal.medicalHistory" />
                </Text>
              </Flex>
              <Markdown colorName="darker" kind="paragraph">
                {data.medicalHistory}
              </Markdown>
            </Flex>
            <Flex $pb={24}>
              <Flex $pb={4}>
                <Text fontWeight="600" kind="paragraph">
                  <Translation id="patientDetail.psychiatricTherapySharedInformation.modal.reason" />
                </Text>
              </Flex>
              <Markdown colorName="darker" kind="paragraph">
                {data.reason}
              </Markdown>
            </Flex>
            <Flex $pb={24}>
              <Flex $pb={4}>
                <Text fontWeight="600" kind="paragraph">
                  <Translation id="patientDetail.psychiatricTherapySharedInformation.modal.psychotropicDrugs" />
                </Text>
              </Flex>
              <Markdown colorName="darker" kind="paragraph">
                {data.psychotropicDrugs}
              </Markdown>
            </Flex>
            <Flex $pb={4}>
              <Text fontWeight="600" kind="paragraph">
                <Translation id="patientDetail.psychiatricTherapySharedInformation.modal.pastPsychiatricPath" />
              </Text>
            </Flex>
            <Markdown colorName="darker" kind="paragraph">
              {data.pastPsychiatricPath}
            </Markdown>
          </OverflowAuto>
        </Modal>
      </ModalProvider>
    </Flex>
  )
}
