import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useChatMessage } from '~/domains/chat/hooks/useChatMessage'
import { CustomMessageWrapper } from './CustomMessageWrapper'

export const MessageSystemWelcomeMessage = () => {
  const group = useUserActingAsGroup()
  const { message } = useChatMessage<'SYSTEM_WELCOME_MESSAGE'>()

  if (group === 'therapist') {
    return null
  }

  if (!message.srns?.payload.patientFirstName || !message.srns?.payload.therapistFullName) {
    return null
  }

  const patientFirstName = message.srns.payload.patientFirstName
  const therapistFullName = message.srns.payload.therapistFullName

  return (
    <CustomMessageWrapper>
      <Flex pb={8}>
        <Text fontWeight="600" kind="caption">
          <Translation id="systemMessage.patient.welcomeMessage.title" values={{ patientFirstName }} />
        </Text>
      </Flex>
      <TranslationMarkdown
        id="systemMessage.patient.welcomeMessage.body"
        kind="caption"
        values={{ therapistFullName }}
      />
    </CustomMessageWrapper>
  )
}
