import { gql, useQuery } from '@apollo/client'
import {
  type JournalingLogSharedWithTherapistQuery,
  type JournalingLogSharedWithTherapistQueryVariables,
} from '~/types/graphql'

const QUERY = gql`
  query journalingLogSharedWithTherapist($input: JournalingLogSharedWithTherapistInput!) {
    journalingLogSharedWithTherapist(input: $input) {
      date
      id
      userId
      journalingActivities {
        id
        name
        slug
      }
      journalingMoods {
        id
        name
        slug
      }
      score
      thankfulReasons
      todayThoughts
    }
  }
`

export const useJournalingLogSharedWithTherapist = (journalingLogId: string) => {
  const { data, error, loading } = useQuery<
    JournalingLogSharedWithTherapistQuery,
    JournalingLogSharedWithTherapistQueryVariables
  >(QUERY, {
    fetchPolicy: 'cache-first',
    variables: {
      input: {
        journalingLogId,
      },
    },
  })

  return {
    journalingLog: data?.journalingLogSharedWithTherapist,
    error,
    loading,
  }
}
