import { Flex } from 'cdk'
import { CreditCard, Icon } from 'icons'
import { title } from 'strings'
import { Card, Text } from 'ui'
import { usePaymentMethods } from '~/domains/payments/hooks/usePaymentMethods'
import { PaymentMethodElementSkeleton } from './PaymentMethodElementSkeleton'

type Props = {
  paymentMethodId: string
}

export const PaymentMethodElement = ({ paymentMethodId }: Props) => {
  const { items } = usePaymentMethods()

  const paymentMethod = items.find(({ id }) => paymentMethodId === id)

  if (!paymentMethod) {
    return <PaymentMethodElementSkeleton />
  }

  return (
    <Card $align="center" $backgroundColorName="primary-10" $direction="row" $gap={16}>
      <Flex>
        <Icon Svg={CreditCard} colorName="primary" size={24} />
      </Flex>
      <Flex $align="center" $direction="row" $grow={1} $justify="space-between" $shrink={1}>
        <Flex>
          <Flex $direction="row" $gap={4} $pb={8}>
            <Text fontWeight="600" kind="paragraph">
              {paymentMethod.type === 'paypal' ? 'Paypal' : title(String(paymentMethod.brand))}
            </Text>
            {paymentMethod.exp_month && paymentMethod.exp_year && (
              <Text kind="paragraph">
                {paymentMethod.exp_month}/{paymentMethod.exp_year}
              </Text>
            )}
          </Flex>
          <Flex>
            <Text fontWeight={paymentMethod.last4 ? '600' : '400'} kind="paragraph">
              {paymentMethod.last4 ? `**** **** **** ${paymentMethod.last4}` : paymentMethod.verified_email}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  )
}
