import { gql, useQuery, type WatchQueryFetchPolicy } from '@apollo/client'
import {
  type TherapySessionLatestByTherapistIdAndPatientIdQuery,
  type TherapySessionLatestByTherapistIdAndPatientIdQueryVariables,
} from '~/types/graphql'

const QUERY = gql`
  query therapySessionLatestByTherapistIdAndPatientId($patientId: String!) {
    therapySessionLatestByTherapistIdAndPatientId(patientId: $patientId) {
      churn
      id
      patient {
        fullName
        id
      }
      recurrency
      startAt
      status
      therapist {
        fullName
        id
      }
    }
  }
`

export const useTherapySessionLatestByTherapistIdAndPatientId = (
  patientId: string,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first',
) => {
  const { data, error, loading, refetch, updateQuery } = useQuery<
    TherapySessionLatestByTherapistIdAndPatientIdQuery,
    TherapySessionLatestByTherapistIdAndPatientIdQueryVariables
  >(QUERY, {
    variables: {
      patientId,
    },
    fetchPolicy,
  })

  return {
    error: error != null,
    loading,
    refetch,
    therapySession: data?.therapySessionLatestByTherapistIdAndPatientId ?? null,
    updateQuery,
  }
}
