import { type TranslationId } from '~/i18n/types'
import { type TherapyTherapyPathType } from '~/types/graphql'

const THERAPY_PATH_TRANSLATION_KEYS_BY_THERAPY_PATH_TYPE: Record<
  TherapyTherapyPathType,
  Extract<TranslationId, `therapies.therapyPath.title.${string}`>
> = {
  COUPLES_PSYCHOTHERAPY: 'therapies.therapyPath.title.couplesPsychotherapy',
  MYSELF_PSYCHIATRY: 'therapies.therapyPath.title.myselfPsychiatry',
  MYSELF_PSYCHOTHERAPY: 'therapies.therapyPath.title.myselfPsychotherapy',
  PATH_ASSERTIVE_COMMUNICATION: 'therapies.therapyPath.title.pathAssertiveCommunication',
  PATH_COACHING: 'therapies.therapyPath.title.pathCoaching',
  PATH_SEXOLOGY: 'therapies.therapyPath.title.sexology',
  PATH_SLEEP: 'therapies.therapyPath.title.pathSleep',
  PATH_NUTRITION_WEIGHT_LOSS: 'therapies.therapyPath.title.nutritionWeightLoss',
  PATH_NUTRITION_DCA: 'therapies.therapyPath.title.nutritionDca',
}

export const getTherapyPathTranslationKey = (therapyPathType: TherapyTherapyPathType) =>
  THERAPY_PATH_TRANSLATION_KEYS_BY_THERAPY_PATH_TYPE[therapyPathType]
