import { Flex, Space } from 'cdk'
import { secondsToHours } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { useMemo } from 'react'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useTherapist } from '~/hooks/useTherapist'
import { useTherapistCalendar } from '../hooks/useTherapistCalendar'
import { computeTotalAvailabilitiesInSeconds } from '../utils/computeTotalAvailabilitiesInSeconds'

export const TherapistCalendarWeeklyHours = () => {
  const {
    therapist: { availableHoursPerWeek },
  } = useTherapist()
  const { events, loading } = useTherapistCalendar()

  const total = useMemo(() => pipe(events, computeTotalAvailabilitiesInSeconds, secondsToHours), [events])

  return (
    <Flex $align="center" $direction="row" $justify="flex-end" $wrap="wrap">
      <TranslationMarkdown
        colorName="black"
        colorNameBold="black"
        id="availabilities.calendar.myWeeklyHours.label"
        kind="paragraph"
      />
      <Space />
      <Text colorName={total < availableHoursPerWeek ? 'red-80' : 'green-80'} fontWeight="600" kind="paragraph">
        {loading ? '--' : String(total).padStart(2, '0')}
      </Text>
      <Space />
      <Translation id="generic.hoursOn" />
      <Space />
      <Text colorName="primary" fontWeight="600" kind="paragraph">
        {availableHoursPerWeek}
      </Text>
    </Flex>
  )
}
