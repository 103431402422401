import { ErrorFromResponse, useCall } from '@stream-io/video-react-sdk'
import { TransitionLeft } from 'icons'
import { useCallback } from 'react'
import { Prompt, useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { TertiaryButtonIconLeft } from '~/components/TertiaryButton/TertiaryButtonIconLeft'
import { Translation } from '~/components/Translation'
import { getRoute } from '~/utils/getRoute'
import { useVideocallEventLogger } from '../../hooks/useVideocallEventLogger'

export const LeaveVideocall = () => {
  const call = useCall()
  const history = useHistory()
  const logFailure = useVideocallEventLogger()

  const onClick = useCallback(() => {
    history.push(getRoute('/'))
  }, [history])

  const handleLeaveFromPrompt = useCallback(async () => {
    try {
      await call?.leave({ reason: 'user leaves where "Exit" button exists' })
    } catch (error) {
      const isRecoverable = !(error instanceof ErrorFromResponse && error.unrecoverable)
      logFailure(isRecoverable ? 'videocall.call.leave' : 'videocall.call.leave.unrecoverable', error)
    }
  }, [call, logFailure])

  return (
    <>
      <Prompt
        message={() => {
          handleLeaveFromPrompt()
          return true
        }}
      />
      <TertiaryButtonIconLeft
        iconBackground="primary"
        iconBackgroundHover="primary-10"
        iconColorName="white"
        iconColorNameHover="primary"
        iconElement={TransitionLeft}
        onClick={onClick}
      >
        <Text fontWeight="600" kind="paragraph">
          <Translation id="videocall.exit" />
        </Text>
      </TertiaryButtonIconLeft>
    </>
  )
}
