import { Flex } from 'cdk'
import { addSeconds, parseISO } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useChatMessage } from '~/domains/chat/hooks/useChatMessage'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { getTherapySessionDayAndMonthLong } from '~/utils/dates/getTherapySessionDayAndMonth'
import { getTherapySessionEndAt } from '~/utils/dates/getTherapySessionEndAt'
import { getTherapySessionStartAt } from '~/utils/dates/getTherapySessionStartAt'
import { getTherapySessionDurationByTherapyPath } from '~/utils/getTherapySessionDurationByTherapyPath'
import { CustomMessageWrapper } from './CustomMessageWrapper'

export const MessageSessionBooked = () => {
  const group = useUserActingAsGroup()

  const { message } = useChatMessage<'SYSTEM_SESSION_BOOKED'>()

  const endAtString = message.srns?.payload.endAt
  const startAtString = message.srns?.payload.startAt
  const type = message.srns?.payload.type || 'MYSELF'
  const therapyPathTypeFromPayload = message.srns?.payload.therapyPathType
  const isRecoveredTherapySession = !!message.srns?.payload.recoveredTherapySessionId

  if (!startAtString || group === 'saas-therapist') {
    return null
  }

  const therapyPathType: TherapyTherapyPathType =
    therapyPathTypeFromPayload ?? (type === 'COUPLES' ? 'COUPLES_PSYCHOTHERAPY' : 'MYSELF_PSYCHOTHERAPY')

  const startAt = pipe(startAtString, parseISO)
  const endAt = endAtString
    ? pipe(endAtString, parseISO)
    : pipe(startAt, addSeconds(getTherapySessionDurationByTherapyPath(therapyPathType).therapySessionDuration))

  return (
    <CustomMessageWrapper>
      <Flex $pb={8}>
        <Text fontWeight="600" kind="caption">
          <Translation id={`systemMessage.${group}.booking.title`} />
        </Text>
      </Flex>
      <TranslationMarkdown
        id={
          isRecoveredTherapySession
            ? `systemMessage.${group}.booking.recoveredTherapySession.body`
            : `systemMessage.${group}.booking.body`
        }
        kind="caption"
        values={{
          therapySessionsEndAtTime: pipe(endAt, getTherapySessionEndAt),
          therapySessionsStartAtTime: pipe(startAt, getTherapySessionStartAt),
          therapySessionsStartAtDay: pipe(startAt, getTherapySessionDayAndMonthLong),
        }}
      />
    </CustomMessageWrapper>
  )
}
