import { Flex } from 'cdk'
import { BORDER_RADIUS_XS, SPACING_MD, SPACING_SM } from 'design-tokens'
import { EmptyCalendar } from 'illustrations'
import { Link as RootLink } from 'react-router-dom-v5-compat'
import { Button, DrawerFooter, DrawerHeader, Skeleton, useDrawerContext } from 'ui'
import { CarouselProvider } from '~/components/Carousel'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { useCarouselSlots } from '~/domains/reservation/hooks/useCarouselSlots'
import { TimeSlotDaysTherapists } from '~/domains/reservation/pages/BookBySuggestedTherapists/TimeSlots/TimeSlotDaysTherapists'
import { TimeSlotsSkeleton } from '~/domains/therapy-session/components/TimeSlotsSkeleton'
import { useTherapist } from '~/hooks/useTherapist'
import { getRoute } from '~/utils/getRoute'
import { useBookSession } from '../hooks/useBookSession'

const MAX_VISIBLE_COLUMNS = 5

export const BookSessionSlots = () => {
  const { minutes, loading: bookLoading, isRecover, therapy, therapyPath } = useBookSession()
  const { close } = useDrawerContext()
  const { formState } = useReactHookFormContext()
  const therapist = useTherapist()
  const {
    data,
    error: slotsError,
    length,
    loading: slotsLoading,
    slice,
  } = useCarouselSlots({
    maxVisibleColumns: MAX_VISIBLE_COLUMNS,
    selectedTherapist: therapist,
    therapyPathType: therapyPath.type,
    therapyId: therapy.id,
  })
  const trackClick = useTrackEventClick()

  if (slotsLoading || slotsError) {
    return (
      <>
        <DrawerHeader>
          <Flex $align="center" $grow={1} $shrink={1}>
            <TranslationMarkdown colorName="darker" id="therapySession.bookSession.title" kind="h3" />
            {isRecover && (
              <Flex $align="center">
                <TranslationMarkdown colorName="darker" id="therapySession.bookSession.isRecover" kind="caption" />
              </Flex>
            )}
          </Flex>
        </DrawerHeader>
        <TimeSlotsSkeleton isLoading={slotsLoading} />
        <DrawerFooter>
          <Flex $gap={SPACING_SM} $grow={1} $shrink={1}>
            <Flex $direction="row" $gap={SPACING_MD} $grow={1} $justify="space-between" $shrink={1}>
              <Flex $grow={1} $shrink={1}>
                {slotsLoading ? (
                  <Skeleton
                    $active
                    $backgroundColorName="neutral-20"
                    $borderRadius={BORDER_RADIUS_XS}
                    $minHeight={40}
                  />
                ) : (
                  <Button isGhost kind="primary" onClick={close}>
                    <Translation id="actions.cancel" />
                  </Button>
                )}
              </Flex>
              <Flex $grow={1} $shrink={1}>
                {slotsLoading ? (
                  <Skeleton
                    $active
                    $backgroundColorName="primary-10"
                    $borderRadius={BORDER_RADIUS_XS}
                    $minHeight={40}
                  />
                ) : (
                  <Button disabled kind="primary">
                    <Translation id="actions.book" />
                  </Button>
                )}
              </Flex>
            </Flex>
          </Flex>
        </DrawerFooter>
      </>
    )
  }

  if (length === 0) {
    return (
      <>
        <DrawerHeader>
          <Flex $align="center" $grow={1} $shrink={1}>
            <TranslationMarkdown colorName="darker" id="therapySession.bookSession.title" kind="h3" />
            {isRecover && (
              <Flex $align="center">
                <TranslationMarkdown colorName="darker" id="therapySession.bookSession.isRecover" kind="caption" />
              </Flex>
            )}
          </Flex>
        </DrawerHeader>
        <Flex $align="center" $pb={24}>
          <EmptyCalendar />
        </Flex>
        <TranslationMarkdown
          colorName="darker"
          id="therapySession.bookSession.empty"
          values={{
            therapistFullName: therapist.fullName,
          }}
        />
        <DrawerFooter>
          <Flex $grow={1} $shrink={1}>
            <RootLink
              data-test-id="therapy-session-chat-button"
              onClick={() => {
                trackClick('therapy-session.go-to-professional-chat-for-booking', {
                  therapistId: therapist.id,
                  therapyId: therapy.id,
                })
              }}
              to={getRoute(`/chat/${therapist.id}`)}
            >
              <Button as="span" kind="primary">
                <Translation id="actions.open.chat" />
              </Button>
            </RootLink>
          </Flex>
        </DrawerFooter>
      </>
    )
  }

  return (
    <>
      <DrawerHeader>
        <Flex $align="center" $grow={1} $shrink={1}>
          <TranslationMarkdown colorName="darker" id="therapySession.bookSession.title" kind="h3" />
          {isRecover && (
            <Flex $align="center">
              <TranslationMarkdown colorName="darker" id="therapySession.bookSession.isRecover" kind="caption" />
            </Flex>
          )}
        </Flex>
      </DrawerHeader>
      <Flex $grow={1} $minHeight={300} $shrink={1}>
        <CarouselProvider length={length}>
          <TimeSlotDaysTherapists
            compareTherapists={false}
            length={length}
            selectedTherapist={therapist}
            slice={slice}
            slots={data}
            suggestedTherapistsProfiles={[therapist]}
          />
        </CarouselProvider>
      </Flex>
      {minutes != null && (
        <Flex $align="center">
          <TranslationMarkdown
            colorName="neutral-70"
            id="reservation.bookBySuggestedTherapist.duration"
            kind="caption"
            values={{ minutes }}
          />
        </Flex>
      )}
      <DrawerFooter>
        <Flex $gap={SPACING_SM} $grow={1} $shrink={1}>
          <Flex $direction="row" $gap={SPACING_MD} $grow={1} $justify="space-between" $shrink={1}>
            <Flex $grow={1} $shrink={1}>
              <Button isGhost kind="primary" onClick={close}>
                <Translation id="actions.cancel" />
              </Button>
            </Flex>
            <Flex $grow={1} $shrink={1}>
              <Button disabled={!formState.isValid} isLoading={bookLoading} kind="primary" type="submit">
                <Translation id="actions.book" />
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </DrawerFooter>
    </>
  )
}
