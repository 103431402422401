import { ArrowLeft } from 'icons'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { TertiaryButtonIconLeft } from '~/components/TertiaryButton/TertiaryButtonIconLeft'
import { Translation } from '~/components/Translation'
import { useReservationFlow } from '~/domains/reservation/hooks/useReservationFlow'
import { getReservationRoutes } from '../utils/getReservationRoutes'

export const GoRootButton = () => {
  const { loading } = useReservationFlow()
  const history = useHistory()

  const onClick = useCallback(() => {
    history.replace(getReservationRoutes('/book-by-suggested-therapist'))
  }, [history])

  return (
    <TertiaryButtonIconLeft disabled={loading} iconElement={ArrowLeft} onClick={onClick} size="sm" tabIndex={-1}>
      <Translation id="actions.goBack" />
    </TertiaryButtonIconLeft>
  )
}
