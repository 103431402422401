import { Flex } from 'cdk'
import { toDayOfMonth, toHoursMinutes, toMonthName, toWeekDay } from 'dates'
import { SPACING_4XS, SPACING_SM } from 'design-tokens'
import styled from 'styled-components'
import { Text } from 'ui'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { getTherapySessionColorNameByTherapyPath } from '~/utils/getTherapySessionColorNameByTherapyPath'

const Container = styled(Flex)`
  @keyframes slide-thx-page-session {
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  opacity: 0;
  transform: translateX(-30%);
  animation: slide-thx-page-session 1.5s ease forwards;
  animation-delay: 0.3s;
`

type TherapistCardSessionProps = {
  endAt: Date
  startAt: Date
  therapyPathType: TherapyTherapyPathType
}

export const TherapistCardSession = ({ endAt, startAt, therapyPathType }: TherapistCardSessionProps) => (
  <Container $direction="row" $gap={SPACING_SM}>
    <Flex
      $backgroundColorName={getTherapySessionColorNameByTherapyPath(therapyPathType)}
      $borderRadius={2}
      $minWidth={8}
    />
    <Flex $gap={SPACING_4XS}>
      <Text kind="paragraph-strong">{toWeekDay(startAt)}</Text>
      <Text kind="h1">
        {toDayOfMonth(startAt)} {toMonthName(startAt)}
      </Text>
      <TranslationMarkdown
        id="thankYouPage.therapist-card.sessionTime"
        pt={8}
        values={{ startAt: toHoursMinutes(startAt), endAt: toHoursMinutes(endAt) }}
      />
    </Flex>
  </Container>
)
