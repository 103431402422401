import loadable from '@loadable/component'
import {
  CallingState,
  ParticipantView,
  SfuModels,
  type StreamVideoParticipant,
  useCallStateHooks,
} from '@stream-io/video-react-sdk'
import { Flex, MaxWidth400px, PositionAbsolute, PositionRelative } from 'cdk'
import { BORDER_RADIUS_XS, ELEVATION_MD, SPACING_XS } from 'design-tokens'
import { domMax, LazyMotion, m } from 'framer-motion'
import { useCallback, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { Button, Card, Text } from 'ui'
import { CenteredLoader } from '~/components/CenteredLoader'
import { Translation } from '~/components/Translation'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { BadQualityConnectionAlert } from '../../components/BadQualityConnectionAlert'
import { PermissionsAlert } from '../../components/PermissionsAlert'
import { CallingStateAlert } from './CallingStateAlert'
import { CurrentUserParticipantViewUI } from './CurrentUserParticipantViewUI'
import { ParticipantViewUI } from './ParticipantViewUI'
import { VideoPlaceholder } from './VideoPlaceholder'

const LazyMessagesAnimation = loadable(() => import('./MessagesAnimation/index.lazy'), {
  resolveComponent: (components) => components.MessagesAnimation,
})

const DragArea = styled.div`
  position: absolute;
  inset: 16px 32px;
`

const DragElement = styled(m.div)`
  position: absolute;
  right: 32px;
  bottom: 16px;
  box-shadow: ${ELEVATION_MD};

  /* stylelint-disable-next-line selector-class-pattern */
  .str-video__video-preview-container.str-video__video-preview-container {
    width: 200px;
    max-width: min(40vw, 200px);
    max-height: min(20svh, 200px);
  }
`

const RemoteParticipantsContainer = styled(Flex)`
  max-width: 100%;
  max-height: 100%;
`

const ScreenShareAlert = () => {
  const { useScreenShareState } = useCallStateHooks()
  const { screenShare } = useScreenShareState()

  const handleClick = useCallback(async () => {
    await screenShare.disable()
  }, [screenShare])

  return (
    <PositionAbsolute $align="center" $left={8} $right={8} $top={8}>
      <Card
        $align="center"
        $borderRadius={BORDER_RADIUS_XS}
        $direction="row"
        $gap={8}
        $justify="space-between"
        $maxWidth="100%"
        $minWidth={364}
        $p={SPACING_XS}
      >
        <Text kind="caption">
          <Translation id="videocall.youAreSharing" />
        </Text>
        <Button kind="primary" onClick={handleClick}>
          <Translation id="videocall.stopShare" />
        </Button>
      </Card>
    </PositionAbsolute>
  )
}

const hasScreenShare = (p?: StreamVideoParticipant) => !!p?.publishedTracks.includes(SfuModels.TrackType.SCREEN_SHARE)

export const VideocallLayout = () => {
  const { useCallCallingState, useParticipants, useRemoteParticipants, useCallMembers } = useCallStateHooks()
  const members = useCallMembers()
  const isPatient = useUserActingAsGroup() === 'patient'
  const callingState = useCallCallingState()
  const remoteParticipants = useRemoteParticipants()
  const [participantInSpotlight] = useParticipants()
  const constraintsRef = useRef(null)
  const isSpeakerScreenSharing = hasScreenShare(participantInSpotlight)

  const therapistFullName = useMemo(
    () => members.find((member) => member.role === 'host')?.user.custom.fullName ?? '',
    [members],
  )

  return (
    <LazyMotion features={domMax}>
      <PositionRelative $grow={1} $px={16} $shrink={1}>
        <DragArea ref={constraintsRef} />

        <PositionRelative
          $align="center"
          $direction="column"
          $gap={16}
          $grow={1}
          $justify="center"
          $mdDirection="row"
          $shrink={1}
        >
          {callingState === CallingState.JOINING ? (
            <CenteredLoader />
          ) : isSpeakerScreenSharing ? (
            <>
              <ParticipantView
                ParticipantViewUI={participantInSpotlight.isLocalParticipant ? ScreenShareAlert : undefined}
                VideoPlaceholder={VideoPlaceholder}
                muteAudio={true}
                participant={participantInSpotlight}
                trackType="screenShareTrack"
              />
              {!!remoteParticipants.length && (
                <>
                  <RemoteParticipantsContainer $basis="25%" $direction="row" $mdDirection="column">
                    {remoteParticipants.map((participant) => (
                      <ParticipantView
                        key={participant.userId}
                        ParticipantViewUI={<ParticipantViewUI label={participant.name} size="md" />}
                        VideoPlaceholder={VideoPlaceholder}
                        className={remoteParticipants.length > 1 ? 'srns-partecipant-view-4-3' : ''}
                        participant={participant}
                      />
                    ))}
                  </RemoteParticipantsContainer>
                </>
              )}
            </>
          ) : remoteParticipants.length ? (
            remoteParticipants.map((participant) => (
              <ParticipantView
                key={participant.userId}
                ParticipantViewUI={<ParticipantViewUI label={participant.name} size="md" withWatermark />}
                VideoPlaceholder={VideoPlaceholder}
                className={remoteParticipants.length > 1 ? 'srns-partecipant-view-4-3' : ''}
                participant={participant}
              />
            ))
          ) : (
            isPatient && (
              <Flex $align="center" $grow={1} $justify="center" $lgDirection="row" $shrink={1}>
                <Flex
                  $gap={16}
                  $grow={1}
                  $justify="center"
                  $lgBasis="30%"
                  $lgGrow={0}
                  $lgShrink={0}
                  $mdP={32}
                  $shrink={1}
                >
                  <Text colorName="white" kind="h1">
                    <Translation id="videocall.therapistIsComing.title" values={{ fullName: therapistFullName }} />
                  </Text>
                  <Text colorName="white" fontWeight="400" kind="h3">
                    <Translation id="videocall.therapistIsComing.description" />
                  </Text>
                </Flex>
                <Flex
                  $align="center"
                  $grow={1}
                  $justify="flex-start"
                  $lgBasis="60%"
                  $lgGrow={0}
                  $lgJustify="center"
                  $lgShrink={0}
                  $shrink={1}
                >
                  <LazyMessagesAnimation />
                </Flex>
              </Flex>
            )
          )}
          {(callingState === CallingState.OFFLINE || callingState === CallingState.RECONNECTING) && (
            <CallingStateAlert callingState={callingState} />
          )}
          <DragElement
            drag
            dragConstraints={constraintsRef}
            dragElastic={0.1}
            dragMomentum
            dragTransition={{ timeConstant: 100, power: 0.1 }}
            whileTap={{ boxShadow: ELEVATION_MD }}
          >
            <CurrentUserParticipantViewUI />
          </DragElement>
          <PositionAbsolute $bottom={0} $left={0} $py={8} $right={0}>
            <MaxWidth400px $gap={8}>
              <BadQualityConnectionAlert />
              <PermissionsAlert />
            </MaxWidth400px>
          </PositionAbsolute>
        </PositionRelative>
      </PositionRelative>
    </LazyMotion>
  )
}
