import { gql, useLazyQuery } from '@apollo/client'
import { type FormFlowResponseDownloadQuery, type FormFlowResponseDownloadQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query formFlowResponseDownload($input: FormFlowResponseDownloadInput!) {
    formFlowResponseDownload(input: $input) {
      id
      downloadUrl
    }
  }
`

export const useFormFlowResponseDownload = (responseId: string) =>
  useLazyQuery<FormFlowResponseDownloadQuery, FormFlowResponseDownloadQueryVariables>(QUERY, {
    variables: {
      input: {
        responseId,
      },
    },
  })
