import { Flex } from 'cdk'
import { SPACING_SM } from 'design-tokens'
import { Icon, PercentageCircle } from 'icons'
import { memo, useCallback } from 'react'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useModals } from '~/domains/modals'
import { useTabNavigationMenu } from '../../hooks/useTabNavigationMenu'
import { MenuAction } from '../common/MenuAction'

export const MenuItemConvention = memo(() => {
  const { open } = useModals()
  const { closeMenu } = useTabNavigationMenu()

  const onActivateConvention = useCallback(() => {
    closeMenu()

    open('activateConvention')
  }, [closeMenu, open])

  return (
    <MenuAction onClick={onActivateConvention}>
      <Flex $pr={SPACING_SM}>
        <Icon Svg={PercentageCircle} colorName="neutral-60" size={24} />
      </Flex>

      <Flex $align="flex-start" $grow={1} $shrink={1}>
        <TranslationMarkdown id="menu.activateConvention" kind="paragraph" textAlign="left" />
      </Flex>
    </MenuAction>
  )
})

MenuItemConvention.displayName = 'MenuItemConvention'
