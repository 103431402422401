import { Flex } from 'cdk'
import { BORDER_RADIUS_CIRCLE, type ColorName, SPACING_SM, SPACING_XS } from 'design-tokens'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { type TranslationId } from '~/i18n/types'

const ITEMS: { colorName: ColorName; translationId: TranslationId }[] = [
  {
    colorName: 'green-60',
    translationId: 'nutrition.diary.dayRecap.option.excellent',
  },
  {
    colorName: 'yellow-60',
    translationId: 'nutrition.diary.dayRecap.option.average',
  },
  {
    colorName: 'red-80',
    translationId: 'nutrition.diary.dayRecap.option.justOk',
  },
  {
    colorName: 'neutral-60',
    translationId: 'nutrition.diary.dayRecap.option.unfilled',
  },
]

export const FoodJournalMoodLegend = () => (
  <Flex $direction="row" $gap={SPACING_SM} $justify="center">
    {ITEMS.map(({ colorName, translationId }) => (
      <Flex key={translationId} $direction="row">
        <Flex $justify="center" $pr={SPACING_XS}>
          <Flex $backgroundColorName={colorName} $borderRadius={BORDER_RADIUS_CIRCLE} $minHeight={8} $minWidth={8} />
        </Flex>

        <Text kind="micro">
          <Translation id={translationId} />
        </Text>
      </Flex>
    ))}
  </Flex>
)
