import { gql, useQuery } from '@apollo/client'
import { type GetLanguagesQuery, type GetLanguagesQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query getLanguages {
    getLanguages {
      id
      code
      name
    }
  }
`

export const useGetLanguages = () => {
  const { data, loading } = useQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(QUERY, {
    fetchPolicy: 'cache-first',
  })

  return {
    languages: data?.getLanguages ?? [],
    getLanguagesLoading: loading,
  }
}
