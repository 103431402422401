import { useCallback } from 'react'
import { Text } from 'ui'
import { Button, Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useChatPatient } from '~/domains/chat/hooks/useChatPatient'
import { HowToAddDiagnosisModal } from '~/domains/diagnosis/components/HowToAddDiagnosisModal'
import { useModals } from '~/domains/modals'
import { useRootHistory } from '~/hooks/useRootHistory'
import { getRoute } from '~/utils/getRoute'
import { CustomMessageWrapper } from './CustomMessageWrapper'

export const MessageTherapistDiagnosisMissing = () => {
  const group = useUserActingAsGroup()
  const { user } = useChatPatient()
  const { open } = useModals()
  const history = useRootHistory()

  const onClick = useCallback(() => {
    if (!user) {
      return
    }

    history.push(getRoute(`/chat/${user.id}/diagnosis`))
  }, [history, user])

  const onOpenModal = useCallback(() => {
    open('howToAddDiagnosis')
  }, [open])

  if (!user) {
    return null
  }

  if (group === 'therapist') {
    return (
      <>
        <CustomMessageWrapper
          background="yellow-20"
          borderColor="yellow-40"
          fontColorName="black"
          testId="chat-message"
        >
          <Flex pb={8}>
            <Text fontWeight="600" kind="caption">
              <Translation
                id="systemMessage.therapist.therapistDiagnosisMissing.title"
                values={{ patientFullName: user.fullName }}
              />
            </Text>
          </Flex>
          <Text fontWeight="400" kind="caption">
            <TranslationMarkdown id="systemMessage.therapist.therapistDiagnosisMissing.body" kind="paragraph" />
          </Text>

          <Flex align="center" direction="row" pt={16} wrap="wrap">
            <Flex pb={8} pr={16}>
              <Button kind="secondary" onClick={onOpenModal} size="small" testId="diagnosis-missing-modal-button">
                <Translation id="systemMessage.therapist.therapistDiagnosisMissing.iDontKnowYet" />
              </Button>
            </Flex>
            <Flex pb={8}>
              <Button kind="primary" onClick={onClick} size="small" testId="diagnosis-missing-confirm-button">
                <Translation id="systemMessage.therapist.therapistDiagnosisMissing.answer" />
              </Button>
            </Flex>
          </Flex>
        </CustomMessageWrapper>

        <HowToAddDiagnosisModal />
      </>
    )
  }

  return null
}
