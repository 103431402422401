import {
  COLOR_GREEN_60,
  COLOR_GREY_40,
  COLOR_GREY_80,
  COLOR_LIGHTER,
  COLOR_PRIMARY_50,
  FONT_FAMILY_DEGULAR_TEXT,
} from 'design-tokens'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import styled from 'styled-components'
import { View } from 'ui-deprecated'

export const TherapistCalendarStyle = styled(View)`
  .rbc-event-content,
  .rbc-event-label {
    font-size: 12px;
    font-family: ${FONT_FAMILY_DEGULAR_TEXT};
  }

  .rbc-event-label {
    font-weight: 600;
  }

  .rbc-time-view {
    border: none;
  }

  .rbc-timeslot-group {
    min-height: 48px;
  }

  .rbc-time-gutter {
    .rbc-timeslot-group {
      border-bottom: none;

      .rbc-label {
        padding: 0 16px;
        color: ${COLOR_GREY_80};
        font-size: 14px;
        font-family: ${FONT_FAMILY_DEGULAR_TEXT};
      }
    }
  }

  .rbc-day-slot .rbc-time-slot {
    border-top: none;
  }

  .rbc-day-slot {
    .rbc-timeslot-group {
      z-index: 2;

      .rbc-time-slot {
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: ${COLOR_GREEN_60};
        }
      }
    }
  }

  .rbc-time-header-gutter {
    box-sizing: border-box;
    padding: 0 8px;
  }

  .rbc-header {
    padding: 0;
    border-bottom: none;
  }

  .rbc-header + .rbc-header {
    border-color: ${COLOR_GREY_40};
  }

  .rbc-header .rbc-button-link {
    width: 100%;
  }

  .rbc-time-content {
    border-top: none;
    border-right: 1px solid #ddd;
  }

  .rbc-day-slot .rbc-events-container {
    margin-right: 0;
  }

  .rbc-time-header-content {
    border-bottom: 1px solid ${COLOR_GREY_40};
    border-left: none;
  }

  .rbc-allday-cell {
    display: none;
  }

  .rbc-today {
    background-color: transparent;
  }

  .rbc-slot-selection {
    border-color: transparent;
    border-radius: 4px;
    background-color: ${COLOR_GREEN_60};

    span {
      padding: 0 4px;
      color: ${COLOR_LIGHTER};
      font-weight: 600;
      font-size: 14px;
      font-family: ${FONT_FAMILY_DEGULAR_TEXT};
    }
  }

  .rbc-current-time-indicator {
    z-index: 5;
    height: 2px;
    background-color: ${COLOR_PRIMARY_50};
  }
`

TherapistCalendarStyle.displayName = 'TherapistCalendarStyle'
