import { differenceInSeconds } from 'date-fns/fp'
import {
  isIntervalOverlappingLeft,
  isIntervalOverlappingRight,
  isIntervalWithinInterval,
  isSameInterval,
  sortByDateAsc,
} from 'dates'
import { pipe } from 'fp-ts/function'
import { type TherapistCalendarParam } from '../hooks/useTherapistCalendar'

type CaledarEvent = TherapistCalendarParam['events']['available'][number]

type Param = {
  available: CaledarEvent[]
  busy: CaledarEvent[]
}

const mergeOverlappingAndSortedByStartAt = (accumulator: CaledarEvent[], current: CaledarEvent) => {
  if (!accumulator.length) {
    return [...accumulator, current]
  }

  const previous = accumulator.pop() as CaledarEvent

  const previousInterval = { start: previous.start, end: previous.end }

  const currentInterval = { start: current.start, end: current.end }

  if (pipe(currentInterval, isIntervalWithinInterval(previousInterval))) {
    return [...accumulator, previous]
  }

  if (pipe(currentInterval, isIntervalOverlappingRight(previousInterval))) {
    return [
      ...accumulator,
      {
        ...previous,
        end: current.end,
      },
    ]
  }

  return [...accumulator, previous, current]
}

export const computeTotalAvailabilitiesInSeconds = ({ available, busy }: Param) => {
  const therapySessions = busy.filter(({ resource }) => resource.type === 'THERAPY_SESSION')

  const allAvailabilities = available
    .concat(therapySessions)
    .sort(sortByDateAsc('start'))
    .reduce(mergeOverlappingAndSortedByStartAt, [])

  const spotUnavailabilities = busy
    .filter(({ resource }) => resource.type === 'SPOT')
    .sort(sortByDateAsc('start'))
    .reduce(mergeOverlappingAndSortedByStartAt, [])

  const total = allAvailabilities.reduce((sum, { end, start }) => sum + pipe(end, differenceInSeconds(start)), 0)

  return allAvailabilities.reduce(
    (sum, availability) =>
      spotUnavailabilities.reduce((count, unavailability) => {
        if (pipe(unavailability, isSameInterval(availability))) {
          return count - pipe(unavailability.end, differenceInSeconds(unavailability.start))
        }

        if (pipe(unavailability, isIntervalWithinInterval(availability))) {
          return count - pipe(unavailability.end, differenceInSeconds(unavailability.start))
        }

        if (pipe(unavailability, isIntervalOverlappingRight(availability))) {
          return count - pipe(availability.end, differenceInSeconds(unavailability.start))
        }

        if (pipe(unavailability, isIntervalOverlappingLeft(availability))) {
          return count - pipe(unavailability.end, differenceInSeconds(availability.start))
        }

        return count
      }, sum),
    total,
  )
}
