import { noop } from 'functions'
import { type CSSProperties, useMemo } from 'react'
import { type EventWrapperProps } from 'react-big-calendar'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { getDateRangeLabel } from '../utils/getDateRangeLabel'
import { isCalendarEventPast } from '../utils/isCalendarEventPast'
import { TherapistCalendarEventWrapper } from './TherapistCalendarEventWrapper'

type Props = Pick<EventWrapperProps, 'event' | 'onClick' | 'style'>

export const TherapistCalendarEventUnavailable = ({ event, onClick, style }: Props) => {
  const wrapperStyle = useMemo(
    (): CSSProperties => ({
      ...style,
      zIndex: 3,
    }),
    [style],
  )

  const label = useMemo(() => getDateRangeLabel(event), [event])

  const handleClick = useMemo(() => (isCalendarEventPast(event) ? noop : onClick), [event, onClick])

  return (
    <TherapistCalendarEventWrapper onClick={handleClick} pr={1} style={wrapperStyle}>
      <Flex background="white" borderColor="grey06" borderRadius={4} borderSize={1} direction="row" grow={1} shrink={1}>
        <Flex background="grey06" basis="3px" />

        <Flex grow={1} p={4} shrink={1}>
          <Text colorName="grey-80" fontWeight="600" kind="caption">
            {label}
          </Text>
          <Text colorName="grey-80" fontWeight="400" kind="caption">
            <Translation id="availabilities.calendar.eventSlot.unavailable.title" />
          </Text>
        </Flex>
      </Flex>
    </TherapistCalendarEventWrapper>
  )
}
