import { Flex } from 'cdk'
import { SPACING_LG, SPACING_SM } from 'design-tokens'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { OpenLiveChat } from './actions/OpenLiveChat'
import { OpenPatientDetail } from './actions/OpenPatientDetail'
import { OpenSupport } from './actions/OpenSupport'
import { Transcription } from './Transcription'

export const SidePanelActions = () => {
  const { isAuthenticated } = useAuthState()
  const isTherapist = useUserActingAsGroup() === 'therapist'

  if (!isAuthenticated) {
    return null
  }

  return (
    <Flex $align="center" $direction="row" $gap={SPACING_LG} $grow={1} $justify="space-between">
      <Transcription />

      <Flex $direction="row" $gap={SPACING_SM} $grow={1} $justify="flex-end">
        <OpenSupport />
        <OpenLiveChat />
        {isTherapist && <OpenPatientDetail />}
      </Flex>
    </Flex>
  )
}
