import { type ComponentProps } from 'react'
import { Controller, type RegisterOptions, useFormContext } from 'react-hook-form'
import { Rating } from '~/components/Rating'

type RatingProps = ComponentProps<typeof Rating>

type Props = Pick<RatingProps, 'initialRating'> & {
  name: string
  rules?: RegisterOptions
}

export const ReactHookFormRating = ({ initialRating, name, rules }: Props) => {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      defaultValue={initialRating}
      name={name}
      render={({ field: { onChange, value } }) => <Rating initialRating={value} onRate={onChange} />}
      rules={rules}
    />
  )
}
