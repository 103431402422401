import { Flex } from 'cdk'
import { BORDER_RADIUS_3XS, SPACING_XS } from 'design-tokens'
import { Card } from 'ui'
import { Skeleton } from '../Skeleton'

export const MealsSkeleton = () => (
  <Card data-test-id="food-journal-meals-loading">
    <Flex $gap={SPACING_XS}>
      <Skeleton $borderRadius={BORDER_RADIUS_3XS} $minHeight={22} />
      <Skeleton $borderRadius={BORDER_RADIUS_3XS} $minHeight={20} />
      <Skeleton $borderRadius={BORDER_RADIUS_3XS} $minHeight={58} />
    </Flex>
  </Card>
)
