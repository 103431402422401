import { gql, useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { useGetDiagnosisByPatientId } from '~/domains/diagnosis/queries/getDiagnosisByPatientId'
import {
  type UpdateDiagnosisInput,
  type UpdateDiagnosisMutation,
  type UpdateDiagnosisMutationVariables,
} from '~/types/graphql'

const MUTATION = gql`
  mutation updateDiagnosis($input: UpdateDiagnosisInput!) {
    updateDiagnosis(input: $input) {
      ok
    }
  }
`

export type UpdateDiagnosisParam = Omit<UpdateDiagnosisInput, 'patientId'>

export const useUpdateDiagnosis = (patientId: string) => {
  const [updateDiagnosis] = useMutation<UpdateDiagnosisMutation, UpdateDiagnosisMutationVariables>(MUTATION)
  const { refetch } = useGetDiagnosisByPatientId(patientId)

  const update = useCallback(
    async (values: UpdateDiagnosisParam) => {
      try {
        const response = await updateDiagnosis({
          variables: {
            input: {
              ...values,
              patientId,
            },
          },
        })

        if (response.data?.updateDiagnosis.ok) {
          await refetch()

          return { ok: true }
        }

        return { ok: false }
      } catch (e) {
        return { ok: false }
      }
    },
    [patientId, refetch, updateDiagnosis],
  )

  return {
    updateDiagnosis: update,
  }
}
