import { type TranslationId } from '~/i18n/types'
import { type TherapyTherapyPathType } from '~/types/graphql'

type TherapyPathTypeTranslation = Record<
  TherapyTherapyPathType,
  {
    title: Extract<TranslationId, `reservation.therapyAlreadyOngoing.title.${string}`>
  }
>

export const mapTherapyAlreadyOngoingType: TherapyPathTypeTranslation = {
  MYSELF_PSYCHOTHERAPY: {
    title: 'reservation.therapyAlreadyOngoing.title.individual',
  },
  COUPLES_PSYCHOTHERAPY: {
    title: 'reservation.therapyAlreadyOngoing.title.couples',
  },
  PATH_COACHING: {
    title: 'reservation.therapyAlreadyOngoing.title.coaching',
  },
  MYSELF_PSYCHIATRY: {
    title: 'reservation.therapyAlreadyOngoing.title.psychiatry',
  },
  PATH_SEXOLOGY: {
    title: 'reservation.therapyAlreadyOngoing.title.individual',
  },
  PATH_SLEEP: {
    title: 'reservation.therapyAlreadyOngoing.title.sleep',
  },
  PATH_ASSERTIVE_COMMUNICATION: {
    title: 'reservation.therapyAlreadyOngoing.title.assertive',
  },
  PATH_NUTRITION_WEIGHT_LOSS: {
    title: 'reservation.therapyAlreadyOngoing.title.nutritionWeightLoss',
  },
  PATH_NUTRITION_DCA: {
    title: 'reservation.therapyAlreadyOngoing.title.nutritionDca',
  },
}
