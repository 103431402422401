import { Flex } from 'cdk'
import { Icon, PathSexology } from 'icons'
import { useMemo } from 'react'
import { Chip } from 'ui'
import { Translation } from '~/components/Translation'
import { useTherapist } from '~/hooks/useTherapist'
import { type TranslationId } from '~/i18n/types'

type LabelTranslationId = Extract<TranslationId, `therapist.presentationCard.certifications.${string}.title.${string}`>

export const TherapistChipSexology = () => {
  const {
    therapist: { sex },
  } = useTherapist()
  const labelId = useMemo((): LabelTranslationId => {
    const gender = sex === 1 ? 'male' : 'female'
    return `therapist.presentationCard.certifications.sexology.title.${gender}`
  }, [sex])

  return (
    <Chip kind="sexology">
      <Flex $align="center" $direction="row" $gap={4} $wrap="nowrap">
        <Icon Svg={PathSexology} size={16} />
        <Translation id={labelId} />
      </Flex>
    </Chip>
  )
}
