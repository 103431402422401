import { Flex, Form } from 'cdk'
import { format, set } from 'date-fns'
import { FormatDateEnum } from 'dates'
import { SPACING_2XL, SPACING_2XS, SPACING_LG, SPACING_MD, SPACING_XS } from 'design-tokens'
import { useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { type SingleValue } from 'react-select'
import { Button, DrawerFooter, Text, TextInput, useDrawerContext } from 'ui'
import { type SelectOption } from '~/components/SelectNew/types'
import { Translation } from '~/components/Translation'
import { ReactHookFormTextArea } from '~/domains/react-hook-form'
import { ReactHookFormSelectNew } from '~/domains/react-hook-form/components/ReactHookFormSelectNew'
import { useToasts } from '~/hooks/useToasts'
import { useTranslate } from '~/i18n/hooks/useTranslation'
import { type TranslationId } from '~/i18n/types'
import {
  type FoodJournalMeal,
  type FoodJournalMealHunger,
  type FoodJournalMealLocation,
  type FoodJournalMealMood,
} from '~/types/graphql'
import { mealLocationToEmoji, mealMoodToEmoji } from '../constants'
import { useAddFoodJournalMeal } from '../hooks/useAddFoodJournalMeal'
import { useEditFoodJournalMeal } from '../hooks/useEditFoodJournalMeal'
import { useFoodJournal } from './FoodJournalContext'
import { RadioChip } from './RadioChip'

const isEmptyInput = (value: string | undefined) => value == null || value === ''

const hungerToTranslationId: Record<FoodJournalMealHunger, TranslationId> = {
  VERY_HUNGRY: 'nutrition.diary.addMealForm.hunger.veryHungry',
  MEDIUM_HUNGRY: 'nutrition.diary.addMealForm.hunger.mediumHungry',
  LITTLE_HUNGRY: 'nutrition.diary.addMealForm.hunger.littleHungry',
}

type HungerRadioChipsProps = {
  name: string
  type: 'HUNGER' | 'MOOD'
}

const RadioChips = ({ name, type }: HungerRadioChipsProps) => {
  const translate = useTranslate()

  const options = useMemo(
    () =>
      Object.entries(type === 'HUNGER' ? hungerToTranslationId : mealMoodToEmoji).map(([key, value]) => ({
        text: type === 'HUNGER' ? translate(value as TranslationId) : value,
        value: key,
      })),
    [translate, type],
  )

  return (
    <Flex $direction="row" $gap={8} $wrap="wrap">
      {options.map(({ text, value }) => (
        <RadioChip key={`${name}-${value}`} name={name} text={text} value={value} />
      ))}
    </Flex>
  )
}

const locationToTranslationId: Record<FoodJournalMealLocation, TranslationId> = {
  HOME: 'nutrition.diary.addMealForm.eatingPlace.home',
  OFFICE: 'nutrition.diary.addMealForm.eatingPlace.office',
  SCHOOL: 'nutrition.diary.addMealForm.eatingPlace.school',
  GYM: 'nutrition.diary.addMealForm.eatingPlace.gym',
  RESTAURANT: 'nutrition.diary.addMealForm.eatingPlace.restaurant',
  BAR: 'nutrition.diary.addMealForm.eatingPlace.bar',
  OTHER: 'nutrition.diary.addMealForm.eatingPlace.other',
}

const useGetLocationOptionLabel = () => {
  const translate = useTranslate()

  return useCallback(
    (location: FoodJournalMealLocation) =>
      `${mealLocationToEmoji[location]} ${translate(locationToTranslationId[location])}`,
    [translate],
  )
}

type FormValues = {
  description: string
  locationOption: NonNullable<SingleValue<SelectOption<FoodJournalMealLocation>>>
  time: string
  hungerBefore?: FoodJournalMealHunger
  hungerAfter?: FoodJournalMealHunger
  moodBefore?: FoodJournalMealMood
  moodAfter?: FoodJournalMealMood
  feelings: string
}

type Props = Partial<FoodJournalMeal>

export const MealForm = ({
  id,
  description,
  location,
  date,
  hungerBefore,
  hungerAfter,
  moodBefore,
  moodAfter,
  feelings,
}: Props) => {
  const translate = useTranslate()
  const { addToast } = useToasts()
  const { patientId, currentDay } = useFoodJournal()
  const drawer = useDrawerContext()
  const getLocationOptionLabel = useGetLocationOptionLabel()

  const form = useForm<FormValues>({
    defaultValues: {
      description: description ?? undefined,
      locationOption:
        location != null
          ? {
              label: getLocationOptionLabel(location),
              value: location,
            }
          : undefined,
      time: date != null ? format(date, FormatDateEnum.HOURS_MINUTES) : undefined,
      hungerBefore: hungerBefore ?? undefined,
      hungerAfter: hungerAfter ?? undefined,
      moodBefore: moodBefore ?? undefined,
      moodAfter: moodAfter ?? undefined,
      feelings: feelings ?? '',
    },
  })
  const { isSubmitting } = form.formState

  const addMeal = useAddFoodJournalMeal()
  const editMeal = useEditFoodJournalMeal()

  const locationOptions = useMemo(
    () =>
      Object.keys(locationToTranslationId).map((key) => ({
        label: getLocationOptionLabel(key as FoodJournalMealLocation),
        value: key,
      })),
    [getLocationOptionLabel],
  )

  const handleSubmit = form.handleSubmit(async ({ locationOption, time, feelings, ...values }) => {
    const [hours, minutes] = (time ?? '').split(':').map(Number)
    const date = !isEmptyInput(time) ? set(new Date(), { hours, minutes }) : undefined
    const isEdit = id != null

    try {
      if (isEdit) {
        await editMeal({
          variables: {
            input: {
              id,
              date,
              location: locationOption.value,
              // On edit, if feelings is empty, we want to set it to null to remove the field from the meal
              feelings: !isEmptyInput(feelings) ? feelings : null,
              ...values,
            },
          },
        })
        addToast({ type: 'success', translationId: 'nutrition.diary.mealEdited' })
      } else {
        await addMeal({
          variables: {
            input: {
              day: currentDay,
              patientId,
              date,
              location: locationOption.value,
              feelings: !isEmptyInput(feelings) ? feelings : undefined,
              ...values,
            },
          },
        })
        addToast({ type: 'success', translationId: 'nutrition.diary.mealAdded' })
      }

      drawer.close()
    } catch (error) {
      addToast({ type: 'alert', translationId: 'generic.errorRetryLater' })
    }
  })

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit}>
        <Flex $gap={SPACING_2XL} $pt={SPACING_LG}>
          <Flex $gap={SPACING_MD}>
            <Text colorName="darker" kind="paragraph">
              <Translation id="nutrition.diary.addMealForm.description" />
            </Text>

            <ReactHookFormTextArea
              max={300}
              minHeight="120px"
              name="description"
              placeholder={translate('nutrition.diary.addMealForm.description.placeholder')}
              rules={{ required: translate('forms.errors.required') }}
            />

            <ReactHookFormSelectNew
              name="locationOption"
              options={locationOptions}
              placeholder={translate('nutrition.diary.addMealForm.eatingPlace.placeholder')}
              rules={{ required: translate('forms.errors.required') }}
              searchable={false}
            />

            <Flex $gap={SPACING_XS}>
              <Text colorName="darker" kind="paragraph">
                <Translation id="nutrition.diary.addMealForm.hour.placeholder" />
              </Text>

              <TextInput {...form.register('time')} type="time" />
            </Flex>
          </Flex>

          <Flex $gap={SPACING_MD}>
            <Text colorName="darker" kind="paragraph">
              <Translation id="nutrition.diary.addMealForm.beforeMeal" />
            </Text>

            <Flex $gap={SPACING_2XS}>
              <Text colorName="neutral-80" kind="caption">
                <Translation id="nutrition.diary.addMealForm.howMuchHunger" />
              </Text>

              <RadioChips name="hungerBefore" type="HUNGER" />
            </Flex>

            <Flex $gap={SPACING_2XS}>
              <Text colorName="neutral-80" kind="caption">
                <Translation id="nutrition.diary.addMealForm.howYouFelt" />
              </Text>

              <RadioChips name="moodBefore" type="MOOD" />
            </Flex>
          </Flex>

          <Flex $gap={SPACING_MD}>
            <Text colorName="darker" kind="paragraph">
              <Translation id="nutrition.diary.addMealForm.afterMeal" />
            </Text>

            <Flex $gap={SPACING_2XS}>
              <Text colorName="neutral-80" kind="caption">
                <Translation id="nutrition.diary.addMealForm.howMuchHunger" />
              </Text>

              <RadioChips name="hungerAfter" type="HUNGER" />
            </Flex>

            <Flex $gap={SPACING_2XS}>
              <Text colorName="neutral-80" kind="caption">
                <Translation id="nutrition.diary.addMealForm.howYouFelt" />
              </Text>

              <RadioChips name="moodAfter" type="MOOD" />
            </Flex>
          </Flex>

          <Flex $gap={SPACING_MD}>
            <Text colorName="darker" kind="paragraph">
              <Translation id="nutrition.diary.addMealForm.moodDescription" />
            </Text>

            <ReactHookFormTextArea
              max={300}
              minHeight="120px"
              name="feelings"
              placeholder={translate('nutrition.diary.addMealForm.moodDescription.placeholder')}
            />
          </Flex>

          <DrawerFooter>
            <Flex $grow={1}>
              <Button isLoading={isSubmitting} kind="primary" type="submit">
                <Translation id="actions.save" />
              </Button>
            </Flex>
          </DrawerFooter>
        </Flex>
      </Form>
    </FormProvider>
  )
}
