import { noop } from 'functions'
import { useCallback, useEffect } from 'react'
import { Text } from 'ui'
import { Button, Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useModals } from '~/domains/modals'
import { useProductUserFeedbackComplete } from '~/domains/productUserFeedback/hooks/useProductUserFeedbackComplete'
import { type StepProps } from '~/domains/technicalFeedback/types'

type Props = StepProps & {
  onCompleted: () => void
}

export const TechnicalFeedbackStepEnd = ({ onCompleted, technicalFeedbackId }: Props) => {
  const { productUserFeedbackComplete } = useProductUserFeedbackComplete(technicalFeedbackId)

  useEffect(() => {
    productUserFeedbackComplete().catch(noop)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { close } = useModals()

  const onClick = useCallback(() => {
    close('technicalFeedback')

    onCompleted()
  }, [close, onCompleted])

  return (
    <>
      <OverflowAuto>
        <Flex pt={16}>
          <Text colorName="black" fontWeight="600" kind="h3">
            <Translation id="technicalFeedback.end.title" />
          </Text>
        </Flex>
        <Flex pt={16}>
          <Text colorName="black" kind="paragraph">
            <Translation id="technicalFeedback.end.body" />
          </Text>
        </Flex>
      </OverflowAuto>
      <Flex pt={24}>
        <Button kind="primary" onClick={onClick}>
          <Translation id="actions.close" />
        </Button>
      </Flex>
    </>
  )
}
