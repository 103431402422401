import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { type ReactNode, useEffect } from 'react'
import { CenteredLoader } from '~/components/CenteredLoader'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { getRoute } from '~/utils/getRoute'
import { getPath } from '~/utils/nextjsRouting'

type Props = {
  children: ReactNode
  to?: string
}

export const AssertAuthenticatedRedirect = ({ children, to }: Props) => {
  const { isAuthenticated, isAuthenticating } = useAuthState()
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    if (isAuthenticating) {
      return
    }

    if (isAuthenticated) {
      return
    }

    const defaultReferrer = !/\/chat\/\w+/.test(pathname) ? pathname : getRoute('/chat')
    const redirectTo = to ?? defaultReferrer
    const params = new URLSearchParams(searchParams.toString())
    params.set('redirectTo', redirectTo)

    router.replace(`${getPath('/login')}?${params.toString()}`)
  }, [isAuthenticated, isAuthenticating, pathname, searchParams, router, to])

  if (isAuthenticating || !isAuthenticated) {
    return <CenteredLoader />
  }

  return <>{children}</>
}
