import { Flex, OverflowAuto } from 'cdk'
import { useCallback, useEffect } from 'react'
import { Button, Text } from 'ui'
import { ChangeProfessionalTranslation } from '~/components/ChangeProfessionalTranslation'
import { Translation } from '~/components/Translation'
import { useRootHistory } from '~/hooks/useRootHistory'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { getRoute } from '~/utils/getRoute'
import { isNutrition } from '~/utils/therapyPaths'
import { useProductUserFeedbackComplete } from '../productUserFeedback/hooks/useProductUserFeedbackComplete'

type Props = {
  therapyId: string
  therapyPathType: TherapyTherapyPathType | null
  feedbackId: string
}

export const RouteWithoutEmpathy = ({ therapyId, therapyPathType, feedbackId }: Props) => {
  const rootHistory = useRootHistory()

  const { productUserFeedbackComplete } = useProductUserFeedbackComplete(feedbackId)

  const handleOnGoBack = useCallback(() => {
    rootHistory.push(getRoute('/'))
  }, [rootHistory])

  const handleOnChangeTherapist = useCallback(() => {
    rootHistory.push(getRoute(`/therapies/${therapyId}?changeTherapist=true`))
  }, [rootHistory, therapyId])

  useEffect(() => {
    productUserFeedbackComplete()
  }, [productUserFeedbackComplete])

  const title =
    therapyPathType != null && isNutrition(therapyPathType)
      ? 'firstSessionFeedback.withoutEmpathy.title.nutritionist'
      : 'firstSessionFeedback.withoutEmpathy.title.therapist'

  return (
    <OverflowAuto $gap={16}>
      <Text colorName="black" fontWeight="600" kind="h3">
        <Translation id={title} />
      </Text>
      <Text colorName="black" kind="paragraph">
        <Translation id="firstSessionFeedback.withoutEmpathy.body" />
      </Text>
      <Flex $gap={8} $pt={24}>
        <Button kind="primary" onClick={handleOnChangeTherapist}>
          <ChangeProfessionalTranslation therapyPathType={therapyPathType} />
        </Button>
        <Button kind="tertiary" onClick={handleOnGoBack}>
          <Translation id="actions.backToPersonalArea" />
        </Button>
      </Flex>
    </OverflowAuto>
  )
}
