import { Text } from 'ui'
import { Flex, SuggestionBox } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { type TranslationId } from '~/i18n/types'

type Props = {
  bodyTranslationId: Extract<
    TranslationId,
    'suggestionBox.diagnosis.body' | 'patientDetail.diagnosis.edit.infoDescription'
  >
}

export const DiagnosisCouplesSuggestionBox = ({ bodyTranslationId }: Props) => (
  <SuggestionBox kind="info-light">
    <Text fontWeight="600" kind="caption">
      <Translation id="suggestionBox.diagnosis.couples.title" />
    </Text>
    <Flex pt={4}>
      <Text fontWeight="400" kind="caption">
        <Translation id={bodyTranslationId} />
      </Text>
    </Flex>
  </SuggestionBox>
)
