import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { Text } from 'ui'
import { Button, Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useModals } from '~/domains/modals'
import { type ClinicalBondVariantAverageRating } from './getClinicalBondVariantAverageRating'

type Param = {
  score: ClinicalBondVariantAverageRating
}

type Props = {
  onCompleted: () => void
}

export const ClinicalBondStepEndVariant = ({ onCompleted }: Props) => {
  const { score } = useParams<Param>()
  const { close } = useModals()

  const onClick = useCallback(() => {
    close('clinicalBond')

    onCompleted()
  }, [close, onCompleted])

  return (
    <>
      <Text fontWeight="600" kind="h3">
        <Translation id={`clinicalBond.endVariant.${score}.title`} />
      </Text>
      <TranslationMarkdown colorName="black" id={`clinicalBond.endVariant.${score}.body`} kind="paragraph" pt={16} />
      <Flex pt={24}>
        <Button kind="primary" onClick={onClick}>
          <Translation id="actions.close" />
        </Button>
      </Flex>
    </>
  )
}
