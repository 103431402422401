import { ArrowLeft, Icon } from 'icons'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { styled } from 'styled-components'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { TertiaryButtonIconOnly } from '~/components/TertiaryButton/TertiaryButtonIconOnly'
import { Translation } from '~/components/Translation'
import { EditDiagnosis } from '~/domains/diagnosis/components/EditDiagnosis'

const Sticky = styled(Flex).attrs({
  background: 'white',
  direction: 'row',
  pl: 16,
  py: 12,
})`
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
  box-shadow: 0 4px 4px rgb(80 57 216 / 10%);
`

type Props = {
  patientId: string
}

export const EditDiagnosisVideocall = ({ patientId }: Props) => {
  const history = useHistory()

  const handleGoBack = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <>
      <Sticky>
        <TertiaryButtonIconOnly onClick={handleGoBack}>
          <Icon Svg={ArrowLeft} size={24} />
        </TertiaryButtonIconOnly>
        <Flex align="center" grow={1} justify="center">
          <Text fontWeight="600" kind="paragraph">
            <Translation id="patientDetail.diagnosis" />
          </Text>
        </Flex>
      </Sticky>
      <EditDiagnosis patientId={patientId} />
    </>
  )
}
