import { format, parse, startOfWeek } from 'date-fns'
import { startOfDay } from 'date-fns/fp'
import { it } from 'date-fns/locale'
import { nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { useMemo } from 'react'
import { Calendar, type Components, dateFnsLocalizer, type SlotInfo } from 'react-big-calendar'
import { Card } from 'ui'
import { useTherapistCalendar } from '../hooks/useTherapistCalendar'
import { type CalendarEvent } from '../types'
import { TherapistCalendarEvent } from './TherapistCalendarEvent'
import { TherapistCalendarHeader } from './TherapistCalendarHeader'
import { TherapistCalendarStyle } from './TherapistCalendarStyle'
import { TherapistCalendarToolbar } from './TherapistCalendarToolbar'

const locales = {
  it,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay: () => 1,
  locales,
})

type Props = {
  onSelectEvent: (payload: CalendarEvent) => void
  onSelectSlot: (payload: SlotInfo) => void
}

const components: Components<CalendarEvent> = {
  event: () => null,
  eventWrapper: TherapistCalendarEvent,
  header: TherapistCalendarHeader,
  toolbar: TherapistCalendarToolbar,
}

export const TherapistCalendar = ({ onSelectEvent, onSelectSlot }: Props) => {
  const { dateRange, events, onRangeChange, onSelecting, options } = useTherapistCalendar()

  const defaultDate = useMemo(
    () => (options.defaultView === 'day' ? pipe(nowInMilliseconds(), startOfDay) : new Date(dateRange.start)),
    [dateRange.start, options.defaultView],
  )

  return (
    <TherapistCalendarStyle>
      <Card>
        <Calendar
          backgroundEvents={events.available}
          components={components}
          defaultDate={defaultDate}
          events={events.busy}
          localizer={localizer}
          onRangeChange={onRangeChange}
          onSelectEvent={onSelectEvent}
          onSelectSlot={onSelectSlot}
          onSelecting={onSelecting}
          {...options}
        />
      </Card>
    </TherapistCalendarStyle>
  )
}
