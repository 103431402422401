import { pipe } from 'fp-ts/function'
import { noop } from 'functions'
import { useCallback, useEffect, useMemo } from 'react'
import { Text } from 'ui'
import { Button, Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useModals } from '~/domains/modals'
import { useProductUserFeedbackComplete } from '~/domains/productUserFeedback/hooks/useProductUserFeedbackComplete'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { type StepProps, type TechnicalFeedbackPsychiatry } from '~/domains/technicalFeedback/types'

type Props = StepProps & {
  onCompleted: () => void
}

type TechnicalFeedbackAverageRating = 'low' | 'medium' | 'high'

const getTechnicalFeedbackAverageRating = ({
  context,
  ease,
  path,
}: Omit<TechnicalFeedbackPsychiatry, 'message'>): TechnicalFeedbackAverageRating => {
  const total = pipe(
    ease,
    (value) => value + path,
    (value) => value + context,
    (value) => value / 3,
    (value) => Math.floor(value),
  )

  if (total > 4) {
    return 'high'
  }

  if (total > 3) {
    return 'medium'
  }

  return 'low'
}

export const TechnicalFeedbackStepEnd = ({ onCompleted, technicalFeedbackId }: Props) => {
  const form = useReactHookFormContext<TechnicalFeedbackPsychiatry>()

  const { productUserFeedbackComplete } = useProductUserFeedbackComplete(technicalFeedbackId)

  useEffect(() => {
    productUserFeedbackComplete().catch(noop)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { close } = useModals()

  const onClick = useCallback(() => {
    close('technicalFeedback')

    onCompleted()
  }, [close, onCompleted])

  const value = useMemo(() => {
    const { context, ease, path } = form.getValues()

    return getTechnicalFeedbackAverageRating({ context, ease, path })
  }, [form])

  return (
    <>
      <OverflowAuto>
        <Flex pt={16}>
          <Text colorName="black" fontWeight="600" kind="h3">
            <Translation id={`technicalFeedback.psychiatry.end.${value}.title`} />
          </Text>
        </Flex>
        <TranslationMarkdown
          colorName="black"
          id={`technicalFeedback.psychiatry.end.${value}.body`}
          kind="paragraph"
          pt={16}
        />
      </OverflowAuto>
      <Flex pt={24}>
        <Button kind="primary" onClick={onClick}>
          <Translation id="actions.close" />
        </Button>
      </Flex>
    </>
  )
}
