import { Flex } from 'cdk'
import { COLOR_PRIMARY_50 } from 'design-tokens'
import styled from 'styled-components'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { ReactHookFormCheckboxFieldVariant } from '../components/ReactHookFormCheckboxFieldVariant'

// NOTE This is a temporary solution. All future form fields will be handled within the CheckboxButton component
const FlexPointer = styled(Flex)`
  cursor: pointer;

  &:hover {
    color: ${COLOR_PRIMARY_50};
  }
`

export const FieldStsEnabled = () => {
  const { labels } = useFormTranslations()

  return (
    <Flex $direction="row" $gap={8}>
      <Flex $align="center">
        <Text kind="paragraph">
          <ReactHookFormCheckboxFieldVariant name="stsEnabled" />
        </Text>
      </Flex>
      <FlexPointer $gap={8} $shrink={1} as="label" htmlFor="stsEnabled">
        <Text fontWeight="600" kind="paragraph">
          {labels.stsEnabled}
        </Text>
        <Text fontWeight="400" kind="paragraph">
          <Translation id="settings.payments.billing.invoice.details.description" />
        </Text>
      </FlexPointer>
    </Flex>
  )
}
