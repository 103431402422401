import { Flex } from 'cdk'
import { Icon, JournalPage } from 'icons'
import { useCallback, useState } from 'react'
import { Text } from 'ui'
import { ActionWrapper } from '~/components/patientDetail/ActionWrapper'
import { Translation } from '~/components/Translation'
import { type TherapistAgendaTherapySession } from '~/domains/agenda/types'
import { useSendInformedConsentDocument } from '~/hooks/useSendInformedConsentDocument'
import { useToasts } from '~/hooks/useToasts'

type Props = Pick<TherapistAgendaTherapySession, 'id'>

export const SendInformedConsent = ({ id }: Props) => {
  const [alreadySentOnce, setAlreadySentOnce] = useState(false)
  const { addToast } = useToasts()
  const [sendInformedConsentDocument, { loading }] = useSendInformedConsentDocument()

  const onClick = useCallback(async () => {
    try {
      await sendInformedConsentDocument({
        variables: {
          input: {
            therapySessionId: id,
          },
        },
      })

      setAlreadySentOnce(true)

      addToast({ translationId: 'therapySession.informedConsentSent.success', type: 'success' })
    } catch (error) {
      addToast({ translationId: 'therapySession.informedConsentSent.error', type: 'alert' })
    }
  }, [addToast, id, sendInformedConsentDocument, setAlreadySentOnce])

  return (
    <ActionWrapper disabled={alreadySentOnce || loading} onClick={onClick}>
      <Flex>
        <Icon Svg={JournalPage} colorName="neutral-80" size={24} />
      </Flex>
      <Flex $gap={4} $grow={1} $shrink={1}>
        <Text fontWeight="600" kind="caption">
          <Translation id="informedConsent.title" />
        </Text>
        <Text fontWeight="400" kind="footnote">
          <Translation id="informedConsent.description" />
        </Text>
      </Flex>
      <Text colorName="primary" fontWeight="600" kind="caption">
        <Translation id="actions.send" />
      </Text>
    </ActionWrapper>
  )
}
