import { CenteredLoader } from '~/components/CenteredLoader'
import { PatientDetailDiagnosis } from '~/domains/diagnosis/components/PatientDetailDiagnosis'
import { DiagnosisProvider } from '~/domains/diagnosis/providers/DiagnosisProvider'
import { PatientDetailDiagnosisCouples } from '~/domains/diagnosisCouples/components/PatientDetailDiagnosisCouples'
import { DiagnosisCouplesProvider } from '~/domains/diagnosisCouples/hooks/useDiagnosisCouples'
import { GenericErrorContent } from '~/domains/error/GenericError'
import { useTherapistTherapies } from '~/domains/therapies/TherapistTherapiesProvider'
import { type Route as AppRoute } from '~/utils/getRoute'

type Props = {
  patientId: string
  rootPath: Extract<AppRoute, '/chat' | '/patients'>
  isEditable?: boolean
}

export const DiagnosisDetailByPatientType = ({ patientId, rootPath, isEditable = true }: Props) => {
  const { loading, therapies } = useTherapistTherapies()

  const therapy = therapies.find(({ patient }) => patient?.id === patientId)

  if (loading) {
    return <CenteredLoader />
  }

  if (therapy == null) {
    return <GenericErrorContent />
  }

  const therapyPathType = therapy.therapyPath.type

  if (therapyPathType === 'COUPLES_PSYCHOTHERAPY') {
    return (
      <DiagnosisCouplesProvider isEditable={isEditable} patientId={patientId}>
        <PatientDetailDiagnosisCouples rootPath={rootPath} />
      </DiagnosisCouplesProvider>
    )
  }

  return (
    <DiagnosisProvider isEditable={isEditable} patientId={patientId} therapyPathType={therapyPathType}>
      <PatientDetailDiagnosis rootPath={rootPath} />
    </DiagnosisProvider>
  )
}
