import { useCallStateHooks } from '@stream-io/video-react-sdk'
import { createContext, type PropsWithChildren, useContext, useEffect, useState } from 'react'
import { timeRelatedStatusByStartAtAndDuration } from '../components/VideocallView/WaitingRoom/utils'

type WaitingRoomStatus =
  | 'LOADING'
  | 'PARTICIPANT_ARRIVES_LATE'
  | 'PARTICIPANT_ARRIVES_EARLY'
  | 'PARTICIPANT_CAN_JOIN'
  | 'PARTICIPANT_CAN_START'

type ContextType = WaitingRoomStatus

const WaitingRoomContext = createContext<ContextType | null>(null)

type WaitingRoomProvider = PropsWithChildren<{
  endAt: Date
  startAt: Date
}>

export const WaitingRoomProvider = ({ children, endAt, startAt }: WaitingRoomProvider) => {
  const [callState, setCallState] = useState<WaitingRoomStatus>('LOADING')
  const { useCallSession } = useCallStateHooks()
  const session = useCallSession()

  useEffect(() => {
    const timeRelatedStatus = timeRelatedStatusByStartAtAndDuration(startAt, endAt)

    switch (timeRelatedStatus) {
      case 'IS_LATE':
        setCallState('PARTICIPANT_ARRIVES_LATE')
        break

      case 'IS_EARLY':
        setCallState('PARTICIPANT_ARRIVES_EARLY')
        break

      case 'IS_JOINABLE': {
        const isSomeoneAlreadyIn = Boolean((session?.participants.length ?? 0) > 0)

        if (isSomeoneAlreadyIn) {
          setCallState('PARTICIPANT_CAN_JOIN')
        } else {
          setCallState('PARTICIPANT_CAN_START')
        }
        break
      }
    }
  }, [endAt, session?.participants.length, startAt])

  return <WaitingRoomContext.Provider value={callState}>{children}</WaitingRoomContext.Provider>
}

export const useWaitingRoom = () => {
  const context = useContext(WaitingRoomContext)

  if (!context) {
    throw new Error('useWaitingRoom hook must be wrapped in <WaitingRoomProvider />')
  }

  return context
}
