import { gql, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { type GetTherapySessionByIdQuery, type GetTherapySessionByIdQueryVariables } from '~/types/graphql'

const GET_THERAPY_SESSION_BY_ID = gql`
  query getTherapySessionById($id: String!) {
    getTherapySessionById(id: $id) {
      endAt
      endAtSlot
      id
      patient {
        firstName
        fullName
        id
      }
      provider
      recurrency
      therapist {
        fullName
        firstName
        id
      }
      startAt
      status
      therapyId
      therapyPathType
      typeCost
      url
    }
  }
`

type Param = {
  therapySessionId: string
}

export const useGetTherapySessionById = (id?: string) => {
  const { therapySessionId } = useParams<Param>()

  const { data, error, loading, refetch } = useQuery<GetTherapySessionByIdQuery, GetTherapySessionByIdQueryVariables>(
    GET_THERAPY_SESSION_BY_ID,
    {
      variables: {
        id: id || therapySessionId,
      },
    },
  )

  return {
    error: !!error,
    item: data?.getTherapySessionById,
    loading,
    refetch,
  }
}
