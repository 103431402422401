import { Flex, PositionAbsolute, PositionRelative, Pressable } from 'cdk'
import { toDateMonthName, toHoursMinutes, toWeekDay } from 'dates'
import {
  BORDER_RADIUS_CIRCLE,
  BORDER_RADIUS_SM,
  COLOR_LIGHTER,
  COLOR_NEUTRAL_10,
  COLOR_NEUTRAL_50,
  COLOR_PRIMARY_70,
  OPACITY_24,
  OPACITY_32,
  TIME_150,
  TYPOGRAPHY_H1_LINE_HEIGHT,
} from 'design-tokens'
import { Icon, Plus } from 'icons'
import { fromCents, toCurrencyIntOrDecimal } from 'numbers'
import { Suspense, useState } from 'react'
import { Link, MemoryRouter, Route } from 'react-router-dom'
import { Link as RootLink } from 'react-router-dom-v5-compat'
import styled, { css } from 'styled-components'
import {
  Button,
  Card,
  Divider,
  Drawer,
  DrawerFooter,
  DrawerHeader,
  DrawerProvider,
  DrawerTitle,
  DrawerTrigger,
  Skeleton,
  Text,
} from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { BookSessionProvider, BookSessionRoutes, getBookSessionRoutes } from '~/domains/therapy-session/BookSession'
import {
  getManageSessionRoutes,
  ManageSessionProvider,
  ManageSessionRoutes,
} from '~/domains/therapy-session/ManageSession'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { usePatientAgenda } from '~/hooks/usePatientAgenda'
import { useToasts } from '~/hooks/useToasts'
import { type TherapySessionStatus, type TherapyTherapyPathType } from '~/types/graphql'
import { isBeforeManagingPolicyLimit } from '~/utils/dates/isBeforeManagingPolicyLimit'
import { getGoogleMeetUrlWithEmail } from '~/utils/getGoogleMeetUrlWithEmail'
import { getRoute } from '~/utils/getRoute'
import { getTherapySessionColorNameByTherapyPath } from '~/utils/getTherapySessionColorNameByTherapyPath'
import { getTherapySessionCostsByTherapyPath } from '~/utils/getTherapySessionCostsByTherapyPath'
import { useTrackEventClick } from '../analytics/hooks/useTrackEventClick'
import { DownloadCalendarEventButton } from '../reservation/components/DownloadCalendarEventButton'
import { TimeSlotsSkeleton } from '../therapy-session/components/TimeSlotsSkeleton'
import { useGetTherapySessionById } from '../therapy-session/hooks/useGetTherapySessionById'
import { useTriggerPayment } from '../triggerPayment/useTriggerPayment'

const SHOW_PAY_NOW_STATUS: TherapySessionStatus[] = [
  'CONFIRMED',
  'NO_PAYMENT_METHOD',
  'PAYMENT_FAILED',
  'PAYMENT_FAILED_FIRST_ATTEMPT',
  'PAYMENT_FAILED_FIRST_ATTEMPT_NO_PAYMENT_METHOD',
  'SCHEDULED',
  'TO_BE_CONFIRMED',
]

type TherapySessionButtonProps = {
  onClick?: VoidFunction
  therapySessionId: string
}

type TearSignProps = {
  $featured?: boolean
}

const TearSign = styled(Flex)<TearSignProps>`
  &::before {
    content: '';
    position: absolute;
    top: calc(${BORDER_RADIUS_SM} * -1);
    bottom: calc(${BORDER_RADIUS_SM} * -1);
    left: 0;
    width: 24px;
    border-top-left-radius: ${BORDER_RADIUS_SM};
    border-bottom-left-radius: ${BORDER_RADIUS_SM};
    background: ${COLOR_LIGHTER};
    transition: background-color ${TIME_150} ease-in-out;

    ${({ $featured }) =>
      !$featured &&
      css`
        box-shadow:
          inset 0 0.5px 0 0 rgb(from ${COLOR_PRIMARY_70} r g b / ${OPACITY_24}),
          inset 0 -0.5px 0 0 rgb(from ${COLOR_PRIMARY_70} r g b / ${OPACITY_32});

        .srns-serenis-dark & {
          box-shadow:
            inset 0 0.5px 0 0 ${COLOR_NEUTRAL_50},
            inset 0 -0.5px 0 0 ${COLOR_NEUTRAL_10};
        }
      `}
  }

  &::after {
    content: '';
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: -1px;
    border-width: 1px;
    border-style: dashed;
    border-color: ${COLOR_NEUTRAL_50};
  }
`

type PayAppointmentButtonProps = TherapySessionButtonProps

const PayAppointmentButton = ({ onClick, therapySessionId }: PayAppointmentButtonProps) => {
  const [triggerPayment, { loading: triggerPaymentLoading, error: triggerPaymentError }] = useTriggerPayment()
  const {
    item,
    loading: therapySessionLoading,
    error: therapySessionError,
    refetch: therapySessionRefetch,
  } = useGetTherapySessionById(therapySessionId)
  const { refetch: patientAgendaRefetch } = usePatientAgenda()
  const { addToast } = useToasts()
  const trackClick = useTrackEventClick()

  const [error, setError] = useState<unknown>(null)

  if (
    error != null ||
    item == null ||
    therapySessionLoading ||
    therapySessionError ||
    triggerPaymentLoading ||
    triggerPaymentError != null
  ) {
    return (
      <Button
        data-test-id="therapy-session-pay-button"
        disabled
        isLoading={therapySessionLoading || triggerPaymentLoading}
        kind="primary"
      >
        <Translation id="patientAgenda.therapySessionCard.scheduled.cta" />
      </Button>
    )
  }

  if (item.status === 'NO_PAYMENT_METHOD') {
    return (
      <RootLink
        data-test-id="therapy-session-add-payment-method-button"
        onClick={() => {
          trackClick('therapy-session.go-to-adding-payment-method', { therapySessionId })
          onClick?.()
        }}
        to={getRoute('/settings/payments/add')}
      >
        <Button as="span" kind="primary">
          <Translation id="patientAgenda.therapySessionCard.scheduled.cta" />
        </Button>
      </RootLink>
    )
  }

  return (
    <Button
      data-test-id="therapy-session-pay-button"
      kind="primary"
      onClick={async () => {
        try {
          trackClick('therapy-session.pay', { therapySessionId })
          onClick?.()

          const response = await triggerPayment({ variables: { input: { therapySessionId } } })

          if (response.data?.triggerPayment.ok) {
            addToast({ type: 'success', translationId: 'triggerPaymentModal.success.title' })
          } else {
            addToast({ type: 'alert', translationId: 'triggerPaymentModal.failure.title' })
            setError(error)
          }
        } catch (error) {
          addToast({ type: 'alert', translationId: 'triggerPaymentModal.failure.title' })
          setError(error)
        }

        therapySessionRefetch()
        patientAgendaRefetch()
      }}
    >
      <Translation id="patientAgenda.therapySessionCard.scheduled.cta" />
    </Button>
  )
}

type JoinAppointmentButtonProps = TherapySessionButtonProps

const JoinAppointmentButton = ({ onClick, therapySessionId }: JoinAppointmentButtonProps) => {
  const { email } = useCurrentUser()
  const { item, loading, error } = useGetTherapySessionById(therapySessionId)
  const trackClick = useTrackEventClick()

  if (error || loading || item?.url == null) {
    return (
      <Button data-test-id="therapy-session-join-button" disabled isLoading={loading} kind="primary">
        <Translation id="therapySession.generic.ongoing.goToTherapySession" />
      </Button>
    )
  }

  if (item.provider === 'MEET') {
    return (
      <a
        data-test-id="therapy-session-join-button"
        href={getGoogleMeetUrlWithEmail(item.url, email)}
        onClick={() => {
          trackClick('therapy-session.go-to-videocall', { therapySessionId, provider: item.provider })
          onClick?.()
        }}
        rel="noreferrer"
        target="_blank"
      >
        <Button as="span" kind="primary">
          <Translation id="therapySession.generic.ongoing.goToTherapySession" />
        </Button>
      </a>
    )
  }

  return (
    <RootLink
      data-test-id="therapy-session-join-button"
      onClick={() => {
        trackClick('therapy-session.go-to-videocall', { therapySessionId, provider: item.provider })
        onClick?.()
      }}
      to={getRoute(`/therapy-session/${therapySessionId}/live`)}
    >
      <Button as="span" kind="primary">
        <Translation id="therapySession.generic.ongoing.goToTherapySession" />
      </Button>
    </RootLink>
  )
}

type PayOrJoinAppointmentButtonProps = TherapySessionButtonProps & {
  price: number
  status: TherapySessionStatus
}

export const PayOrJoinAppointmentButton = ({
  onClick,
  price,
  status,
  therapySessionId,
}: PayOrJoinAppointmentButtonProps) =>
  SHOW_PAY_NOW_STATUS.includes(status) && price > 0 ? (
    <PayAppointmentButton onClick={onClick} therapySessionId={therapySessionId} />
  ) : (
    <JoinAppointmentButton onClick={onClick} therapySessionId={therapySessionId} />
  )

type ManageAppointmentButtonProps = TherapySessionButtonProps & {
  startAt: Date
  therapyPath: TherapyTherapyPathType
}

const ManageAppointmentButton = ({ startAt, therapyPath, therapySessionId }: ManageAppointmentButtonProps) => {
  const trackClick = useTrackEventClick()

  if (isBeforeManagingPolicyLimit(startAt, therapyPath)) {
    return (
      <Link
        data-test-id="therapy-session-manage-button"
        onClick={() => {
          trackClick('therapy-session.manage', { therapySessionId })
        }}
        to={getManageSessionRoutes('/reschedule-session')}
      >
        <Button as="span" isGhost kind="primary">
          <Translation id="manageTherapySession.variant1.manage" />
        </Button>
      </Link>
    )
  }

  return (
    <Link
      data-test-id="therapy-session-manage-button"
      onClick={() => {
        trackClick('therapy-session.manage', { therapySessionId })
      }}
      to={getManageSessionRoutes('/reschedule-after-cancellation-policy-limit')}
    >
      <Button as="span" isGhost kind="primary">
        <Translation id="manageTherapySession.variant1.manage" />
      </Button>
    </Link>
  )
}

type GoToProfessionalChatButtonProps = TherapySessionButtonProps

const GoToProfessionalChatButton = ({ therapySessionId }: GoToProfessionalChatButtonProps) => {
  const { error, item, loading } = useGetTherapySessionById(therapySessionId)
  const trackClick = useTrackEventClick()
  const therapistId = item?.therapist?.id

  if (error || loading || therapistId == null) {
    return (
      <Button data-test-id="therapy-session-chat-button" disabled isLoading={loading} kind="primary">
        <Translation id="actions.open.chat" />
      </Button>
    )
  }

  return (
    <RootLink
      data-test-id="therapy-session-chat-button"
      onClick={() => {
        trackClick('therapy-session.go-to-professional-chat', { therapistId, therapySessionId })
      }}
      to={getRoute(`/chat/${therapistId}`)}
    >
      <Button as="span" kind="primary">
        <Translation id="actions.open.chat" />
      </Button>
    </RootLink>
  )
}

type ManageFeaturedAppointmentButtonProps = TherapySessionButtonProps & {
  startAt: Date
  therapyId: string
}

const ManageFeaturedAppointmentButton = ({
  startAt,
  therapyId,
  therapySessionId,
}: ManageFeaturedAppointmentButtonProps) => {
  const { error, item, loading } = useGetTherapySessionById(therapySessionId)
  const trackClick = useTrackEventClick()

  return (
    <DrawerProvider>
      <DrawerTrigger>
        <Button
          data-test-id="therapy-session-manage-button"
          disabled={error || loading}
          isGhost
          isLoading={loading}
          kind="primary"
          onClick={() => {
            trackClick('therapy-session.manage', { therapySessionId })
          }}
        >
          <Translation id="manageTherapySession.variant1.manage" />
        </Button>
      </DrawerTrigger>
      <Drawer>
        <MemoryRouter initialEntries={[getManageSessionRoutes('/')]}>
          <Route exact path={getManageSessionRoutes('/')}>
            <DrawerHeader>
              <DrawerTitle>
                <Translation id="therapySession.lessThan24Hours.edit.modal.title" />
              </DrawerTitle>
            </DrawerHeader>
            <TranslationMarkdown
              id="therapySession.lessThan24Hours.edit.modal.subtitle"
              pt={16}
              values={{ therapistFullName: String(item?.therapist?.fullName) }}
            />
            <DrawerFooter>
              <Flex $gap={12} $grow={1} $shrink={1}>
                <GoToProfessionalChatButton therapySessionId={therapySessionId} />
                <Divider colorName="neutral-40" />
                <Text colorName="neutral-70" textAlign="center">
                  <Translation id="manageTherapySession.variant1.cannotReschedule" />
                  &nbsp;
                  <Link
                    data-test-id="therapy-session-delete-link"
                    to={
                      item?.recurrency
                        ? getManageSessionRoutes('/delete-recurrency')
                        : getManageSessionRoutes('/delete')
                    }
                  >
                    <Text kind="paragraph-strong">
                      <Translation id="actions.erase" />
                    </Text>
                  </Link>
                </Text>
              </Flex>
            </DrawerFooter>
          </Route>
          <Suspense fallback={<TimeSlotsSkeleton isLoading />}>
            <ManageSessionProvider sessionId={therapySessionId} startAt={startAt} therapyId={therapyId}>
              <ManageSessionRoutes />
            </ManageSessionProvider>
          </Suspense>
        </MemoryRouter>
      </Drawer>
    </DrawerProvider>
  )
}

type AppointmentCardSkeletonProps = {
  isLoading?: boolean
  isPlaceholder?: boolean
}

export const AppointmentSkeletonCard = ({ isLoading, isPlaceholder }: AppointmentCardSkeletonProps) => (
  <Card $direction="row" $gap={12} $kind={isPlaceholder ? 'placeholder' : undefined} aria-busy={isLoading}>
    <Skeleton $active={isLoading} $backgroundColorName="neutral-30" $maxWidth={8} $minHeight="100%" />
    <Flex $gap={12} $grow={1} $shrink={1}>
      <Flex $gap={8}>
        <Skeleton $active={isLoading} $backgroundColorName="neutral-30" $maxWidth={100} />
        <Skeleton
          $active={isLoading}
          $backgroundColorName="neutral-30"
          $maxWidth={140}
          $minHeight={TYPOGRAPHY_H1_LINE_HEIGHT}
        />
      </Flex>
      <Skeleton $active={isLoading} $backgroundColorName="neutral-30" $maxWidth={170} />
    </Flex>
  </Card>
)

type AppointmentCardProps = {
  endAt: Date
  featured?: boolean
  price: number
  startAt: Date
  therapyPath: TherapyTherapyPathType
}

type AppointmentCardContentProps = Pick<AppointmentCardProps, 'endAt' | 'featured' | 'startAt'>

const AppointmentCardContent = ({ endAt, startAt, featured }: AppointmentCardContentProps) => (
  <Flex $gap={8} $grow={1} $position="relative" $shrink={1}>
    <Flex>
      <Text kind="paragraph-strong">{toWeekDay(startAt)}</Text>
      <Text kind="h1">{toDateMonthName(startAt)}</Text>
    </Flex>
    {featured ? (
      <Text kind="paragraph-strong">
        <Text colorName="primary">
          <Translation id="appointments.now" />
        </Text>
        &nbsp;
        <Translation
          id="appointments.nowTime"
          values={{ startAt: toHoursMinutes(startAt), endAt: toHoursMinutes(endAt) }}
        />
      </Text>
    ) : (
      <TranslationMarkdown
        colorName="darker"
        id="thankYouPage.therapist-card.sessionTime"
        values={{ startAt: toHoursMinutes(startAt), endAt: toHoursMinutes(endAt) }}
      />
    )}
  </Flex>
)

type AppointmentCardSignProps = Pick<AppointmentCardProps, 'therapyPath'>

const AppointmentCardSign = ({ therapyPath }: AppointmentCardSignProps) => (
  <Flex $backgroundColorName={getTherapySessionColorNameByTherapyPath(therapyPath)} $borderRadius={2} $minWidth={8} />
)

const DiscountedAppointmentCard = ({ endAt, featured, price, startAt, therapyPath }: AppointmentCardProps) => (
  <PositionRelative $direction="row">
    <Card
      $direction="row"
      $gap={12}
      $grow={1}
      $isSelected={featured}
      $kind={featured ? 'banner' : undefined}
      $position="relative"
      $pr={24}
      $shrink={1}
    >
      <TearSign
        $bottom={BORDER_RADIUS_SM}
        $featured={featured}
        $position="absolute"
        $right={0}
        $top={BORDER_RADIUS_SM}
      />
      <AppointmentCardSign therapyPath={therapyPath} />
      <AppointmentCardContent endAt={endAt} featured={featured} startAt={startAt} />
    </Card>
    <Card
      $align="baseline"
      $basis={128}
      $direction="row"
      $gap={4}
      $isSelected={featured}
      $justify="flex-end"
      $kind={featured ? 'banner' : undefined}
      $pl={24}
    >
      <Text kind="h2" textDecoration="line-through">
        &nbsp;
        {fromCents(getTherapySessionCostsByTherapyPath(therapyPath).therapySessionCost)}
        &nbsp;
      </Text>
      <Text colorName="primary" kind="h2">
        {toCurrencyIntOrDecimal(price)}
      </Text>
    </Card>
  </PositionRelative>
)

export const AppointmentCard = ({ endAt, featured, price, startAt, therapyPath }: AppointmentCardProps) => {
  const hasDiscount = price < getTherapySessionCostsByTherapyPath(therapyPath).therapySessionCost
  const pathColorName = getTherapySessionColorNameByTherapyPath(therapyPath)

  if (hasDiscount) {
    return (
      <DiscountedAppointmentCard
        endAt={endAt}
        featured={featured}
        price={price}
        startAt={startAt}
        therapyPath={therapyPath}
      />
    )
  }

  return (
    <Card
      $direction="row"
      $gap={24}
      $isSelected={featured}
      $kind={featured ? 'banner' : undefined}
      $position="relative"
    >
      <Flex $direction="row" $gap={12} $grow={1} $shrink={1}>
        <Flex $backgroundColorName={pathColorName} $borderRadius={2} $minWidth={8} />
        <Flex $gap={8} $grow={1} $position="relative" $shrink={1}>
          <Flex>
            <Text kind="paragraph-strong">{toWeekDay(startAt)}</Text>
            <Text kind="h1">{toDateMonthName(startAt)}</Text>
          </Flex>
          {featured ? (
            <Text kind="paragraph-strong">
              <Text colorName="primary">
                <Translation id="appointments.now" />
              </Text>
              &nbsp;
              <Translation
                id="appointments.nowTime"
                values={{ startAt: toHoursMinutes(startAt), endAt: toHoursMinutes(endAt) }}
              />
            </Text>
          ) : (
            <TranslationMarkdown
              colorName="darker"
              id="thankYouPage.therapist-card.sessionTime"
              values={{ startAt: toHoursMinutes(startAt), endAt: toHoursMinutes(endAt) }}
            />
          )}
        </Flex>
      </Flex>
      <Flex $align="baseline" $direction="row" $gap={4} $justify="flex-end">
        <Text kind="h2">{toCurrencyIntOrDecimal(price)}</Text>
      </Flex>
    </Card>
  )
}

type NextAppointmentProps = Omit<AppointmentCardProps, 'featured'> & {
  id: string
  status: TherapySessionStatus
  therapyId: string
}

export const NextAppointment = ({
  endAt,
  id,
  price,
  startAt,
  status,
  therapyId,
  therapyPath,
}: NextAppointmentProps) => {
  const trackClick = useTrackEventClick()

  return (
    <DrawerProvider>
      <DrawerTrigger>
        <Pressable
          data-test-id="therapy-session-card"
          onClick={() => {
            trackClick('therapy-session.open-detail', { therapySessionId: id })
          }}
        >
          <AppointmentCard endAt={endAt} price={price} startAt={startAt} therapyPath={therapyPath} />
        </Pressable>
      </DrawerTrigger>
      <Drawer>
        <MemoryRouter initialEntries={[getManageSessionRoutes('/')]}>
          <Route exact path={getManageSessionRoutes('/')}>
            <DrawerHeader>
              <DrawerTitle>
                <Translation id="appointments.manage.title" />
              </DrawerTitle>
            </DrawerHeader>
            <AppointmentCard endAt={endAt} price={price} startAt={startAt} therapyPath={therapyPath} />
            <DrawerFooter>
              <Flex $gap={16} $grow={1} $pt={16} $shrink={1}>
                <Flex $direction="row-reverse" $gap={8}>
                  <Flex $basis="50%" $grow={1} $shrink={1}>
                    <PayOrJoinAppointmentButton price={price} status={status} therapySessionId={id} />
                  </Flex>
                  <Flex $basis="50%" $grow={1} $shrink={1}>
                    <ManageAppointmentButton startAt={startAt} therapyPath={therapyPath} therapySessionId={id} />
                  </Flex>
                </Flex>
                <DownloadCalendarEventButton therapySessionId={id} />
              </Flex>
            </DrawerFooter>
          </Route>
          <Suspense fallback={<TimeSlotsSkeleton isLoading />}>
            <ManageSessionProvider sessionId={id} startAt={startAt} therapyId={therapyId}>
              <ManageSessionRoutes />
            </ManageSessionProvider>
          </Suspense>
        </MemoryRouter>
      </Drawer>
    </DrawerProvider>
  )
}

type FeaturedAppointmentProps = NextAppointmentProps

export const FeaturedAppointment = ({
  endAt,
  id,
  price,
  startAt,
  status,
  therapyId,
  therapyPath,
}: FeaturedAppointmentProps) => (
  <Flex $gap={16}>
    <AppointmentCard endAt={endAt} featured price={price} startAt={startAt} therapyPath={therapyPath} />
    <Flex $direction="row-reverse" $gap={16}>
      <Flex $basis="50%" $grow={1} $shrink={1}>
        <PayOrJoinAppointmentButton price={price} status={status} therapySessionId={id} />
      </Flex>
      <Flex $basis="50%" $grow={1} $shrink={1}>
        <ManageFeaturedAppointmentButton startAt={startAt} therapyId={therapyId} therapySessionId={id} />
      </Flex>
    </Flex>
  </Flex>
)

type NewAppointmentProps = {
  therapyId: string
}

export const NewAppointment = ({ therapyId }: NewAppointmentProps) => {
  const trackClick = useTrackEventClick()

  return (
    <DrawerProvider>
      <DrawerTrigger>
        <Pressable
          onClick={() => {
            trackClick('therapy-session.start-booking-new', { therapyId })
          }}
        >
          <PositionRelative>
            <AppointmentSkeletonCard isPlaceholder />
            <PositionAbsolute $align="center" $bottom={0} $justify="center" $left={0} $right={0} $top={0}>
              <Flex $backgroundColorName="primary" $borderRadius={BORDER_RADIUS_CIRCLE} $p={8}>
                <Icon Svg={Plus} colorName="white" size={24} />
              </Flex>
            </PositionAbsolute>
          </PositionRelative>
        </Pressable>
      </DrawerTrigger>

      <Drawer>
        <MemoryRouter initialEntries={[getBookSessionRoutes('/book-therapy-session')]}>
          <Suspense fallback={<TimeSlotsSkeleton isLoading />}>
            <BookSessionProvider therapyId={therapyId}>
              <BookSessionRoutes />
            </BookSessionProvider>
          </Suspense>
        </MemoryRouter>
      </Drawer>
    </DrawerProvider>
  )
}
