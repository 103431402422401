import loadable from '@loadable/component'
import { AssertPatientOrAnonymous } from '~/domains/auth/components/AssertPatientOrAnonymous'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'

const LazyFlowShortMyself = loadable(() => import('~/domains/flow/flows/short/myself'), {
  resolveComponent: (components) => components.FlowShortMyself,
})

export const StartShortMyselfPage: Page = {
  exact: true,
  id: 'start.form.myself.short',
  paths: ['/start/short/myself'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: false,
    seoKey: 'start',
    title: null,
  },
  PageGuard: ({ children }) => <AssertPatientOrAnonymous target="/">{children}</AssertPatientOrAnonymous>,
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <LazyFlowShortMyself />,
}
