import { Flex } from 'cdk'
import { Button, Link, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useChatMessage } from '../../hooks/useChatMessage'
import { useChatPatient } from '../../hooks/useChatPatient'
import { CustomMessageWrapper } from './CustomMessageWrapper'

export const MessageNotesAdded = () => {
  const userActingAsGroup = useUserActingAsGroup()
  const { message } = useChatMessage<'SYSTEM_THERAPIST_NOTES_ADDED' | 'SYSTEM_PATIENT_NOTES_ADDED'>()
  const notesLink = message.srns?.payload.notesLink

  const { user } = useChatPatient()

  if (userActingAsGroup !== 'therapist' || !notesLink || !user) {
    return null
  }

  const isTherapistNotes = message.srns?.type === 'SYSTEM_THERAPIST_NOTES_ADDED'

  return (
    <CustomMessageWrapper>
      <Flex $direction="column" $gap={16}>
        <Text>
          <Translation
            id={
              isTherapistNotes
                ? 'systemMessage.therapist.therapistNotesAdded.title'
                : 'systemMessage.therapist.patientNotesAdded.title'
            }
            values={{ patientFirstName: user.firstName }}
          />
        </Text>
        <Text kind="caption">
          <Translation
            id={
              isTherapistNotes
                ? 'systemMessage.therapist.therapistNotesAdded.body'
                : 'systemMessage.therapist.patientNotesAdded.body'
            }
          />
        </Text>
        <Link href={notesLink} target="_blank">
          <Button kind="primary" size="sm">
            <Translation id="systemMessage.therapist.notesAdded.cta" />
          </Button>
        </Link>
      </Flex>
    </CustomMessageWrapper>
  )
}
