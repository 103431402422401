import { Flex } from 'cdk'
import { SPACING_4XS, SPACING_SM } from 'design-tokens'
import { CreditCard, Icon } from 'icons'
import { matchPath, useLocation } from 'react-router-dom'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { type Route } from '~/utils/getRoute'
import { MenuLink } from '../common/MenuLink'

const to: Route = '/historical-therapy-sessions'

export const MenuItemHistoricalTherapySessions = () => {
  const { pathname } = useLocation()
  const active = !!matchPath(pathname, { path: to })

  return (
    <MenuLink to={to}>
      <Flex $pr={SPACING_SM}>
        <Icon Svg={CreditCard} colorName="neutral-60" size={24} />
      </Flex>

      <Flex $grow={1} $pt={SPACING_4XS} $shrink={1}>
        <Text colorName={active ? 'primary' : undefined} kind="paragraph">
          <Translation id="menu.historicalTherapySessions" />
        </Text>
      </Flex>
    </MenuLink>
  )
}
