import { gql, useQuery, useSuspenseQuery } from '@apollo/client'
import { noop } from 'functions'
import { createContext, type JSXElementConstructor, type ReactElement, type ReactNode, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { CenteredLoader } from '~/components/CenteredLoader'
import { isUserTherapist, type UserMaybeTherapist } from '~/domains/therapist/types'
import { type FindTherapistProfileByIdQuery, type FindTherapistProfileByIdQueryVariables } from '~/types/graphql'
import { TherapistProvider } from './useTherapist'

type Param = {
  therapistId: string
}

type Props = {
  children: ReactNode
  id?: string
  loadingElement?: ReactElement<any, string | JSXElementConstructor<any>>
  onError?: () => void
}

const QUERY = gql`
  query findTherapistProfileById($id: String!) {
    findTherapistProfileById(id: $id) {
      id
      firstName
      fullName
      therapist {
        about
        aboutCoach
        age
        availableHoursPerWeek
        id
        numberOfPatients
        registerRegistrationRegion
        registerRegistrationYearPsychologist
        sex
        userId
        professionTypes {
          professionType {
            id
            value
          }
        }
        profileImage {
          s
          m
          l
        }
        professionalExperiences {
          id
          name
          type
        }
        therapistCertifications {
          certification {
            description
            icon
            id
            name
            slug
          }
        }
        therapistProfessionalStyles {
          score
          type
        }
        therapistWorkplaces {
          workplace {
            id
            name
          }
        }
        therapySchool {
          name
          orientation
        }
        workRegion
      }
    }
  }
`

export const useTherapistFetch = (id: string) => {
  const { data, error, loading, refetch } = useQuery<
    FindTherapistProfileByIdQuery,
    FindTherapistProfileByIdQueryVariables
  >(QUERY, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-first',
  })

  return { data, error, loading, refetch }
}

export const useTherapistSuspense = (id: string) => {
  const { data, error, refetch } = useSuspenseQuery<
    FindTherapistProfileByIdQuery,
    FindTherapistProfileByIdQueryVariables
  >(QUERY, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-first',
    errorPolicy: 'none',
  })

  return { data, error, refetch }
}

const TherapistFetchContext = createContext<UserMaybeTherapist | null>(null)

export const TherapistFetchProvider = ({ children, id = '', loadingElement, onError = noop }: Props) => {
  const { therapistId } = useParams<Param>()
  const { data, error, loading } = useTherapistFetch(id ?? therapistId)

  const user = data?.findTherapistProfileById

  useEffect(() => {
    if (error != null || (user != null && !isUserTherapist(user))) {
      onError()
    }
  }, [error, onError, user])

  if (loading || user == null || !isUserTherapist(user)) {
    return loadingElement ?? <CenteredLoader />
  }

  return (
    <TherapistFetchContext.Provider value={user}>
      <TherapistProvider therapist={user}>{children}</TherapistProvider>
    </TherapistFetchContext.Provider>
  )
}
