import { Pressable } from 'cdk'
import { type ReactNode } from 'react'
import styled from 'styled-components'
import { Card } from 'ui'

const WrapperCard = styled(Card).attrs({
  $align: 'center',
  $direction: 'row',
  $gap: 8,
  $justify: 'center',
  $minHeight: 64,
})`
  /* stylelint-disable-next-line no-empty-source */
`

type Props = {
  children: ReactNode
  disabled: boolean
  onClick?: () => void
  href?: HTMLAnchorElement['href']
  download?: boolean
}

export const ActionWrapper = ({ children, disabled, onClick }: Props) => (
  <Pressable disabled={disabled} onClick={onClick}>
    <WrapperCard>{children}</WrapperCard>
  </Pressable>
)
