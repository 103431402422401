import { Flex, MountOn } from 'cdk'
import { type PropsWithChildren } from 'react'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { type EventViewId } from '~/domains/analytics/hooks/useTrackEventView'
import { type TranslationId } from '~/i18n/types'
import { PageTrackEffect } from '~/routes/PageTrackEffect'
import { JoinButton } from './JoinButton'
import { Preview } from './Preview'

type BaseLayoutProps = PropsWithChildren<{
  description: TranslationId
  eventViewId: EventViewId
  isJoinable?: boolean
  title: TranslationId
}>

export const BaseLayout = ({ children, description, eventViewId, isJoinable = false, title }: BaseLayoutProps) => (
  <Flex $mdAlign="center" $mdDirection="row" $mdGrow={1} $mdPx={32} $px={24} $shrink={1}>
    <PageTrackEffect id={eventViewId} />
    <Flex $mdGrow={1} $mdShrink={1} $p={16}>
      <Text fontWeight="600" kind="h1">
        <Translation id={title} />
      </Text>
      <Flex $pt={8}>
        <Text fontWeight="400" kind="paragraph" newLine>
          <Translation id={description} />
        </Text>
      </Flex>
      {children}
      {isJoinable && (
        <MountOn mediaQuery="gt-sm">
          <Flex $align="flex-start" $pt={32}>
            <JoinButton />
          </Flex>
        </MountOn>
      )}
    </Flex>
    <Flex $mdBasis={490}>
      <Preview />
    </Flex>
    {isJoinable && (
      <MountOn mediaQuery="sm">
        <Flex $py={32}>
          <JoinButton />
        </Flex>
      </MountOn>
    )}
  </Flex>
)
