import { toDate } from 'date-fns'
import { useEffect, useMemo } from 'react'
import { matchPath, MemoryRouter, useHistory, useLocation } from 'react-router-dom'
import { EmailVerificationModal } from '~/domains/emailVerification/components/EmailVerificationModal'
import { type PatientTherapy } from '~/domains/patient/hooks/usePatientTherapies'
import { PhoneVerificationContextProvider } from '~/domains/phoneNumbers/hooks/usePhoneVerificationContext'
import { ReactHookFormProvider, useReactHookForm } from '~/domains/react-hook-form'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useRootLocation } from '~/hooks/useRootHistory'
import { getRoute } from '~/utils/getRoute'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { isNutrition } from '~/utils/therapyPaths'
import { ReservationFlowProvider } from '../hooks/useReservationFlow'
import { useSuspenseTherapyPathByIdQuery } from '../hooks/useTherapyPathByIdQuery'
import { ReservationRoutes } from '../pages/ReservationRoutes'
import { type ReservationFormValues } from '../types'
import { getReservationRoutes } from '../utils/getReservationRoutes'

type Props = {
  paymentMethodsIds: string[]
  suggestedTherapistsIds: string[]
  therapies: PatientTherapy[]
}

export const Reservation = ({ paymentMethodsIds, suggestedTherapistsIds, therapies }: Props) => {
  const { phoneNumber, emailVerified } = useCurrentUser()
  const { therapyPath } = useSuspenseTherapyPathByIdQuery()

  const rootLocation = useRootLocation()
  const queryParams = new URLSearchParams(rootLocation.search)
  const location = useLocation()

  const history = useHistory()

  const therapistIdParam = queryParams.get('therapistId')
  const selectedTimeSlotParam = queryParams.get('selectedTimeSlot')

  /**
   * @description Filter therapies with `changeTherapistReason` to avoid blocking the user during change therapist flow
   */
  const ongoingTherapy = therapies
    .filter(({ changeTherapistReason }) => !changeTherapistReason)
    .find((therapy) =>
      isNutrition(therapyPath.type)
        ? isNutrition(therapy.therapyPath.type)
        : therapy.therapyPath.type === therapyPath.type,
    )

  const form = useReactHookForm<ReservationFormValues>({
    defaultValues: {
      selectedTimeSlotWithTherapist: getLocalStorage('pre-booking-selected-time-slot') ?? undefined,
      phoneNumber: phoneNumber || '',
    },
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    if (!!therapistIdParam && !!selectedTimeSlotParam && location.pathname !== getReservationRoutes('/submit')) {
      form.setValue('selectedTimeSlot', toDate(Number(selectedTimeSlotParam)))
      form.setValue('therapistId', therapistIdParam)
    }
  }, [history, form, therapistIdParam, selectedTimeSlotParam, location])

  const selectedTherapistId = suggestedTherapistsIds.at(0)

  const { pathname } = useRootLocation()
  const isChangeTherapistBooking = !!matchPath(pathname, {
    path: getRoute('/change-therapist/:therapyId/booking'),
  })

  const initialEntries = useMemo(() => {
    if (ongoingTherapy && ongoingTherapy.therapyPath?.type && !isChangeTherapistBooking) {
      return [getReservationRoutes('/therapy-already-ongoing')]
    }

    if (
      !!therapistIdParam &&
      !!selectedTimeSlotParam &&
      location.pathname !== getReservationRoutes('/submit') &&
      selectedTherapistId
    ) {
      return [getReservationRoutes(paymentMethodsIds.length ? '/submit' : '/insert-payment-method')]
    }

    if (selectedTherapistId) {
      return [getReservationRoutes('/book-by-suggested-therapist')]
    }

    return [getReservationRoutes('/no-matched-therapists')]
  }, [
    ongoingTherapy,
    isChangeTherapistBooking,
    selectedTherapistId,
    therapistIdParam,
    selectedTimeSlotParam,
    location.pathname,
    paymentMethodsIds.length,
  ])

  return (
    <MemoryRouter initialEntries={initialEntries}>
      <ReactHookFormProvider {...form}>
        <PhoneVerificationContextProvider>
          <ReservationFlowProvider suggestedTherapistsIds={suggestedTherapistsIds}>
            <ReservationRoutes
              therapistFullName={ongoingTherapy?.therapist?.fullName}
              therapistId={ongoingTherapy?.therapist?.id}
              therapyId={ongoingTherapy?.id}
              therapyPathType={ongoingTherapy?.therapyPath?.type}
            />

            {!emailVerified && <EmailVerificationModal />}
          </ReservationFlowProvider>
        </PhoneVerificationContextProvider>
      </ReactHookFormProvider>
    </MemoryRouter>
  )
}
