import { Flex, MaxWidth1024px } from 'cdk'
import { isSameDay } from 'date-fns/fp'
import { Icon, OpenInWindow } from 'icons'
import { useMemo } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Card, Link, Text } from 'ui'
import { Calendar } from 'ui-deprecated'
import { FeaturebaseChangelogWidget } from '~/components/Featurebase/ChangelogWidget'
import { TherapySessionCardSkeleton } from '~/components/TherapySessionCardSkeleton'
import { TherapySessionCardTherapist } from '~/components/TherapySessionCardTherapist'
import { Translation } from '~/components/Translation'
import { useTherapistAgenda } from '~/domains/agenda/hooks/useTherapistAgenda'
import { PushNotificationRequest } from '~/domains/appNative/components/PushNotificationRequest'
import { AvailabilitiesInsertionReminder } from '~/domains/availabilities/reminders'
import { IntercomTrackEvent } from '~/domains/intercom/components/IntercomTrackEvent'
import { TherapistProfileImage } from '~/domains/therapist/components/TherapistProfileImage'
import { useTherapySessionsTherapistAgenda } from '~/domains/therapy-session/hooks/useTherapySessionsTherapistAgenda'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useLanguage } from '~/i18n/hooks/useLanguage'
import { useShowAvailabilitiesInsertionReminder } from '../availabilities/reminders/hooks/useShowAvailabilitiesInsertionReminder'
import { PatientsFiltersRecap } from '../patientsManagement/components/PatientsFiltersRecap'
import { AgendaPollingEffect } from './AgendaPollingEffect'

export const TherapistView = () => {
  const { disabledBeforeDate, onSelectDay, selectedDate } = useTherapistAgenda()
  const { error, events, items, loading, startPolling, stopPolling } = useTherapySessionsTherapistAgenda()
  const { firstName } = useCurrentUser()
  const showAvailabilitiesInsertionReminder = useShowAvailabilitiesInsertionReminder()
  const { language } = useLanguage()

  const trackIntercomEvent = useMemo(
    () => !!items.filter(({ churn, status }) => status === 'ENDED' && !churn).length,
    [items],
  )

  return (
    <MaxWidth1024px $gap={24} $px={16} $py={16}>
      <FeaturebaseChangelogWidget />

      {!loading && <PushNotificationRequest />}

      <AgendaPollingEffect startPolling={startPolling} stopPolling={stopPolling} />

      {showAvailabilitiesInsertionReminder && <AvailabilitiesInsertionReminder />}

      <Flex $align="center" $direction="row" $gap={16}>
        <TherapistProfileImage size="sm" />
        <Flex $align="center" $direction="row" $gap={8} $wrap="wrap" data-test-id="welcome-message">
          <Text kind="h3">
            <Translation id="agenda.welcomeBack" values={{ firstName }} />
          </Text>
          <Text kind="h1">😊</Text>
        </Flex>
      </Flex>

      <Flex $direction="column" $gap={48} $grow={1} $justify="space-between" $mdDirection="row" $shrink={1}>
        <Flex $gap={32} $mdBasis="40%">
          <Card $px={0}>
            <Calendar
              disabledBeforeDate={disabledBeforeDate}
              events={events}
              language={language}
              onChange={onSelectDay}
              sundaysEnabled
            />
            <Flex $align="center" $direction="row" $gap={8} $justify="flex-end" $px={24}>
              <Link as={ReactRouterLink} data-test-id="go-to-calendar-link" to="/availabilities/calendar">
                <Text kind="caption" textDecoration="underline">
                  <Translation id="actions.goToCalendar" />
                </Text>
              </Link>
              <Flex>
                <Icon Svg={OpenInWindow} colorName="primary" size={16} />
              </Flex>
            </Flex>
          </Card>
          <PatientsFiltersRecap />
        </Flex>
        <Flex $gap={16} $mdBasis="50%">
          {error && (
            <Text colorName="red-80" kind="caption">
              <Translation id="generic.errorOccurred.title" />
            </Text>
          )}

          {loading && (
            <>
              <TherapySessionCardSkeleton />
              <TherapySessionCardSkeleton />
            </>
          )}

          {!loading && !items.length && (
            <Text kind="h3" textAlign="center">
              <Translation id="agenda.noTherapySessions" />
            </Text>
          )}

          {trackIntercomEvent && <IntercomTrackEvent name="banners.therapist-delete-reason" />}

          {!!items.length && (
            <Text kind="caption">
              <Translation id="agenda.todayTherapySessions" />
              <Text fontWeight="600" kind="caption">
                {items.filter(({ startAt }) => isSameDay(startAt, selectedDate)).length}
              </Text>
            </Text>
          )}

          {!loading &&
            !error &&
            items
              .filter(({ startAt }) => isSameDay(selectedDate, startAt))
              .map(
                ({
                  bonus,
                  churn,
                  endAt,
                  free,
                  id,
                  notes,
                  patient,
                  provider,
                  recurrency,
                  repeatEveryWeek,
                  startAt,
                  status,
                  therapist,
                  therapyPathType,
                  typeCost,
                  url,
                }) => (
                  <TherapySessionCardTherapist
                    key={id}
                    bonus={bonus}
                    churn={churn}
                    endAt={endAt}
                    free={free}
                    id={id}
                    notes={notes}
                    patient={patient}
                    provider={provider}
                    recurrency={recurrency}
                    repeatEveryWeek={repeatEveryWeek}
                    startAt={startAt}
                    status={status}
                    therapist={therapist}
                    therapyPathType={therapyPathType}
                    typeCost={typeCost}
                    url={url}
                  />
                ),
              )}
        </Flex>
      </Flex>
    </MaxWidth1024px>
  )
}
