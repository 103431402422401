import { type ReactNode } from 'react'
import { Route } from 'react-router-dom'
import { type FirstSessionFeedbackPath } from './types'

type Props = {
  path: FirstSessionFeedbackPath
  children: ReactNode
}

export const FirstSessionFeedbackRoute = ({ path, children }: Props) => <Route path={path}>{children}</Route>
