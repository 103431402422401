import { media, PositionSticky } from 'cdk'
import { SPACING_SM } from 'design-tokens'
import styled from 'styled-components'
import { AddMealCTA } from './AddMealCTA'

const StyledPositionSticky = styled(PositionSticky)`
  ${media.lg`
    position: static;
  `}
`

export const FloatingAddMealCTA = () => (
  <StyledPositionSticky $bottom={0} $direction="row" $justify="center" $lgJustify="flex-start" $pb={SPACING_SM}>
    <AddMealCTA />
  </StyledPositionSticky>
)
