import { Flex, Pressable } from 'cdk'
import { Alarm, CreditCard, CreditCardSlash, Icon, NavArrowDown, NavArrowUp } from 'icons'
import { useCallback, useState } from 'react'
import { Card, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'

type Props = {
  accordionAlways?: boolean
}

export const HowItWorksPayment = ({ accordionAlways = false }: Props) => {
  const [hidden, setHidden] = useState(true)

  const onClick = useCallback(() => {
    setHidden((value) => !value)
  }, [])

  return (
    <Card>
      <Pressable onClick={onClick}>
        <Flex $align="center" $direction="row" $gap={8} $justify="space-between" $mdHide={!accordionAlways}>
          <Text fontWeight="600" kind="paragraph">
            <Translation id="payments.howItWorks.settings.title" />
          </Text>

          <Flex $align="flex-end">
            {hidden && <Icon Svg={NavArrowDown} colorName="primary" size={24} />}
            {!hidden && <Icon Svg={NavArrowUp} colorName="primary" size={24} />}
          </Flex>
        </Flex>
      </Pressable>

      <Flex $hide $mdHide={accordionAlways}>
        <Text colorName="primary" fontWeight="600" kind="paragraph">
          <Translation id="payments.howItWorks.settings.title" />
        </Text>
      </Flex>

      <Flex $gap={20} $hide={hidden} $mdHide={accordionAlways ? hidden : accordionAlways}>
        <Flex $direction="row" $gap={16} $pt={20}>
          <Flex>
            <Icon Svg={CreditCard} colorName="neutral-60" size={24} />
          </Flex>
          <Flex $grow={1} $shrink={1}>
            <TranslationMarkdown
              colorName="darker"
              id="payments.howItWorks.settings.chargeFirstAttempt"
              kind="paragraph"
            />
          </Flex>
        </Flex>

        <Flex $direction="row" $gap={16}>
          <Flex>
            <Icon Svg={Alarm} colorName="neutral-60" size={24} />
          </Flex>

          <Flex $grow={1} $shrink={1}>
            <TranslationMarkdown
              colorName="darker"
              id="payments.howItWorks.settings.chargeFirstAttemptFailure"
              kind="paragraph"
            />
          </Flex>
        </Flex>

        <Flex $direction="row" $gap={16}>
          <Flex>
            <Icon Svg={CreditCardSlash} colorName="neutral-60" size={24} />
          </Flex>

          <Flex $grow={1} $shrink={1}>
            <TranslationMarkdown
              colorName="darker"
              id="payments.howItWorks.settings.chargeSecondAttempt"
              kind="paragraph"
            />
          </Flex>
        </Flex>
      </Flex>
    </Card>
  )
}
