import { gql, useQuery } from '@apollo/client'
import { type DiagnosisCouplesQuery, type DiagnosisCouplesQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query diagnosisCouples($patientId: String!) {
    diagnosisCouples(patientId: $patientId) {
      otherInfo
      partnerAge
      partnerCountry {
        id
        code
        name
      }
      partnerDegree
      partnerGenderIdentity {
        id
        name
      }
      partnerHealthIssue
      partnerHistoricalDiagnosis
      partnerLanguage {
        id
        code
        name
      }
      partnerPharmacologicalTreatment
      partnerProfession
      partnerPsychiatricVulnerability
      partnerSocialAndFamilyNetwork
      partnerSocioCulturalContext
      partnerWorkProfession {
        id
        name
      }
      pathologies {
        pathology {
          id
          name
        }
        target
      }
      userAge
      userCountry {
        id
        code
        name
      }
      userDegree
      userGenderIdentity {
        id
        name
      }
      userHealthIssue
      userHistoricalDiagnosis
      userLanguage {
        id
        code
        name
      }
      userPharmacologicalTreatment
      userProfession
      userPsychiatricVulnerability
      userSocialAndFamilyNetwork
      userSocioCulturalContext
      userWorkProfession {
        id
        name
      }
    }
  }
`

export const useDiagnosisCouplesQuery = (patientId: string) => {
  const { data, loading, refetch } = useQuery<DiagnosisCouplesQuery, DiagnosisCouplesQueryVariables>(QUERY, {
    variables: {
      patientId,
    },
  })

  return {
    diagnosisCouples: data?.diagnosisCouples ?? null,
    loading,
    refetch,
  }
}
