import { ApolloError } from '@apollo/client'
import { noop } from 'functions'
import { useCallback, useState } from 'react'
import { useTherapistCalendar } from '~/domains/availabilities/calendar/hooks/useTherapistCalendar'
import { useTherapySessionFindManyFutureForTherapistByPatientId } from '~/domains/patientsManagement/hooks/useTherapySessionFindManyFutureForTherapistByPatientId'
import { useRescheduleTherapySession } from '~/domains/therapy-session/hooks/useRescheduleTherapySession'
import { useTherapistAgendaByPatientId } from '~/hooks/useTherapistAgendaByPatientId'
import { useTherapistTherapiesFetch } from '~/hooks/useTherapistTherapiesFetch'
import { useToasts } from '~/hooks/useToasts'

type Param = {
  startAt: Date
  message: string
}

type Props = {
  id: string
  onSuccess?: () => void
  patientId: string
}

export const useTherapistRescheduleSubmit = ({ id, onSuccess = noop, patientId }: Props) => {
  const [loading, setLoading] = useState(false)
  const { refetch: refetchTherapistCalendar } = useTherapistCalendar()
  const { refetch: refetchTherapistAgendaByPatientId } = useTherapistAgendaByPatientId(patientId, 'cache-only')
  const { refetch: refetchTherapySessionFindManyFutureForTherapistByPatientId } =
    useTherapySessionFindManyFutureForTherapistByPatientId(patientId, 'cache-only')
  const { refetch: refetchTherapistTherapies } = useTherapistTherapiesFetch()
  const { addToast } = useToasts()

  const [rescheduleTherapySession] = useRescheduleTherapySession()

  const onSubmit = useCallback(
    async ({ message, startAt }: Param) => {
      setLoading(true)

      try {
        const response = await rescheduleTherapySession({
          variables: {
            input: {
              id,
              startAt,
              message,
            },
          },
        })

        onSuccess()

        setLoading(false)

        const availability = response.data?.rescheduleTherapySession

        if (!availability) {
          addToast({ translationId: 'availabilities.calendar.cannotRescheduleTherapySession', type: 'alert' })

          return
        }

        addToast({ translationId: 'availabilities.calendar.therapySessionRescheduled', type: 'success' })

        refetchTherapistCalendar().catch(noop)

        refetchTherapistAgendaByPatientId().catch(noop)

        refetchTherapySessionFindManyFutureForTherapistByPatientId().catch(noop)

        refetchTherapistTherapies()
      } catch (error) {
        if (error instanceof ApolloError) {
          const message = error.message
          if (message === 'therapysession.first.alreadybooked') {
            addToast({
              translationId: 'therapySession.edit.startAt.cannotEditTherapySession.firstAlreadyExists',
              type: 'alert',
            })
            setLoading(false)
            return
          }
        }

        addToast({ translationId: 'availabilities.calendar.cannotRescheduleTherapySession', type: 'alert' })
        setLoading(false)
      }
    },
    [
      addToast,
      id,
      onSuccess,
      refetchTherapistTherapies,
      rescheduleTherapySession,
      refetchTherapistAgendaByPatientId,
      refetchTherapistCalendar,
      refetchTherapySessionFindManyFutureForTherapistByPatientId,
    ],
  )

  return [onSubmit, { loading }] as const
}
