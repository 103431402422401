import { type ComponentProps, useCallback, useMemo, useState } from 'react'
import { Flex } from 'ui-deprecated'
import { type DiagnosisKeyValue } from '~/domains/diagnosis/components/DiagnosisKeyValue'
import { DiagnosisKeyValuesList } from '~/domains/diagnosis/components/DiagnosisKeyValuesList'
import { DiagnosisCouplesSuggestionBox } from '~/domains/diagnosisCouples/components/DiagnosisCouplesSuggestionBox'
import { DiagnosisCouplesTabs } from '~/domains/diagnosisCouples/components/DiagnosisCouplesTabs'
import { useDiagnosisCouples } from '~/domains/diagnosisCouples/hooks/useDiagnosisCouples'

type Tab = 'user' | 'partner'

export const DiagnosisCouplesPreview = () => {
  const { diagnosisCouples, isEditable } = useDiagnosisCouples()

  const [activeTab, setActiveTab] = useState<Tab>('user')

  const switchToUserTab = useCallback(() => setActiveTab('user'), [])

  const switchToPartnerTab = useCallback(() => setActiveTab('partner'), [])

  const stateItems: ComponentProps<typeof DiagnosisKeyValue>[] = useMemo(
    () => [
      {
        keyTranslationId: 'patientDetail.diagnosis.couples.state.facing',
        mandatory: true,
        value: diagnosisCouples?.pathologies
          .filter(({ target }) => target === 'COUPLE')
          .map(({ pathology: { name } }) => name)
          .join(', '),
      },
    ],
    [diagnosisCouples],
  )

  const infoItems: ComponentProps<typeof DiagnosisKeyValue>[] = useMemo(() => {
    const isUserActiveTab = activeTab === 'user'

    return [
      {
        keyTranslationId: 'patientDetail.diagnosis.state.facing',
        value: diagnosisCouples?.pathologies
          .filter(({ target }) => (isUserActiveTab ? target === 'PATIENT' : target === 'PARTNER'))
          .map(({ pathology: { name } }) => name)
          .join(', '),
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.genderIdentity',
        value: isUserActiveTab
          ? diagnosisCouples?.userGenderIdentity?.name
          : diagnosisCouples?.partnerGenderIdentity?.name,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.age',
        value: isUserActiveTab ? diagnosisCouples?.userAge : diagnosisCouples?.partnerAge,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.workProfession',
        value: isUserActiveTab
          ? diagnosisCouples?.userWorkProfession?.name
          : diagnosisCouples?.partnerWorkProfession?.name,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.profession',
        value: isUserActiveTab ? diagnosisCouples?.userProfession : diagnosisCouples?.partnerProfession,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.degree',
        value: isUserActiveTab ? diagnosisCouples?.userDegree : diagnosisCouples?.partnerDegree,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.socioCulturalContext',
        value: isUserActiveTab
          ? diagnosisCouples?.userSocioCulturalContext
          : diagnosisCouples?.partnerSocioCulturalContext,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.country',
        value: isUserActiveTab ? diagnosisCouples?.userCountry?.name : diagnosisCouples?.partnerCountry?.name,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.language',
        value: isUserActiveTab ? diagnosisCouples?.userLanguage?.name : diagnosisCouples?.partnerLanguage?.name,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.pharmacologicalTreatment',
        value: isUserActiveTab
          ? diagnosisCouples?.userPharmacologicalTreatment
          : diagnosisCouples?.partnerPharmacologicalTreatment,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.historicalDiagnosis',
        value: isUserActiveTab
          ? diagnosisCouples?.userHistoricalDiagnosis
          : diagnosisCouples?.partnerHistoricalDiagnosis,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.healthIssue',
        value: isUserActiveTab ? diagnosisCouples?.userHealthIssue : diagnosisCouples?.partnerHealthIssue,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.psychiatricVulnerability',
        value: isUserActiveTab
          ? diagnosisCouples?.userPsychiatricVulnerability
          : diagnosisCouples?.partnerPsychiatricVulnerability,
      },
      {
        keyTranslationId: 'patientDetail.diagnosis.info.socialAndFamilyNetwork',
        value: isUserActiveTab
          ? diagnosisCouples?.userSocialAndFamilyNetwork
          : diagnosisCouples?.partnerSocialAndFamilyNetwork,
      },
    ]
  }, [activeTab, diagnosisCouples])

  return (
    <>
      <DiagnosisKeyValuesList
        isEditable={isEditable}
        items={stateItems}
        titleTranslationId="patientDetail.diagnosis.couples.state"
      >
        {isEditable && (
          <Flex pt={16}>
            <DiagnosisCouplesSuggestionBox bodyTranslationId="suggestionBox.diagnosis.body" />
          </Flex>
        )}
      </DiagnosisKeyValuesList>

      <Flex pt={16}>
        <DiagnosisCouplesTabs
          activeTab={activeTab}
          switchToPartnerTab={switchToPartnerTab}
          switchToUserTab={switchToUserTab}
        />
      </Flex>

      <Flex pt={24}>
        <DiagnosisKeyValuesList
          isEditable={isEditable}
          items={infoItems}
          titleTranslationId="patientDetail.diagnosis.info"
        />
      </Flex>
    </>
  )
}
