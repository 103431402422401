import { useCall, useCallStateHooks } from '@stream-io/video-react-sdk'
import { Flex } from 'cdk'
import { SPACING_XS } from 'design-tokens'
import { IconInfo } from 'icons'
import { useState } from 'react'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { ReactHookFormProvider, ReactHookFormSwitchField, useReactHookForm } from '~/domains/react-hook-form'
import { useToasts } from '~/hooks/useToasts'
import { useVideocallEventLogger } from '../../../hooks/useVideocallEventLogger'
import { SupplementaryInformation } from './SupplementaryInformation'

const DISABLE_SWITCH_DURATION = 1000

type FormValues = {
  transcribe: boolean
}

export const TranscriptionTherapist = () => {
  const [disableSwitch, setDisableSwitch] = useState(false)
  const { addToast } = useToasts()

  const call = useCall()
  const callState = useCallStateHooks()
  const logFailure = useVideocallEventLogger()

  const isTranscribing = callState.useIsCallTranscribingInProgress()
  const form = useReactHookForm<FormValues>({
    defaultValues: {
      transcribe: isTranscribing ?? false,
    },
  })

  if (call == null) {
    return null
  }

  const startTranscription = async () => {
    try {
      await call.startTranscription({ language: 'it' })
    } catch (e) {
      addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })
      logFailure('videocall.start-transcription', e)
      form.setValue('transcribe', false)
    } finally {
      setTimeout(() => setDisableSwitch(false), DISABLE_SWITCH_DURATION)
    }
  }

  const stopTranscription = async () => {
    try {
      await call.stopTranscription()
    } catch (e) {
      addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })
      logFailure('videocall.stop-transcription', e)
      form.setValue('transcribe', true)
    } finally {
      setTimeout(() => setDisableSwitch(false), DISABLE_SWITCH_DURATION)
    }
  }

  const handleChange = () => {
    setDisableSwitch(true)

    if (isTranscribing) {
      return stopTranscription()
    }

    return startTranscription()
  }

  return (
    <ReactHookFormProvider {...form}>
      <ReactHookFormSwitchField disabled={disableSwitch} name="transcribe" onChangeExternal={handleChange}>
        <Flex $align="center" $direction="row" $gap={SPACING_XS} style={{ marginLeft: '-8px' }}>
          <Text as="label" colorName="lighter" fontWeight="500" htmlFor="transcribe">
            <Translation id="therapySessionLive.transcription.therapist.switch.label" />
          </Text>
          <SupplementaryInformation>
            <IconInfo colorName="lighter" size={20} />
          </SupplementaryInformation>
        </Flex>
      </ReactHookFormSwitchField>
    </ReactHookFormProvider>
  )
}
