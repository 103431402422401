import { ArrowRight, Icon } from 'icons'
import styled from 'styled-components'
import { Button, Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { usePhoneVerificationContext } from '~/domains/phoneNumbers/hooks/usePhoneVerificationContext'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { useReservationFlow } from '~/domains/reservation/hooks/useReservationFlow'
import { type ReservationFormValues } from '../types'

const Wrapper = styled(Flex)`
  margin-right: -4px;
`

const ArrowNext = () => (
  <Wrapper pl={12}>
    <Icon Svg={ArrowRight} size={24} />
  </Wrapper>
)

export const GoNextButton = () => {
  const form = useReactHookFormContext<ReservationFormValues>()
  const { loading } = usePhoneVerificationContext()
  const { loading: reservationFlowLoading } = useReservationFlow()

  return (
    <Button
      disabled={!form.formState.isValid}
      form="reservationFlow"
      kind="primary"
      loading={loading || reservationFlowLoading || form.formState.isSubmitting}
      size="small"
      type="submit"
    >
      <Flex align="center" direction="row">
        <Translation id="actions.goNext" />

        <ArrowNext />
      </Flex>
    </Button>
  )
}
