import { Flex } from 'cdk'
import { Icon, PasteClipboard } from 'icons'
import { useCallback } from 'react'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useDownloadFile } from '~/hooks/useDownloadFile'
import { useFormFlowResponseDownload } from '~/hooks/useFormFlowResponseDownload'
import { ActionWrapper } from './ActionWrapper'

type Props = {
  surveyId: string
}

export const DownloadSurvey = ({ surveyId }: Props) => {
  const { id: userId } = useCurrentUser()
  const [downloadFormFlowResponse, { loading }] = useFormFlowResponseDownload(surveyId)
  const trackClick = useTrackEventClick()
  const { download, isDownloading } = useDownloadFile()

  const onClick = useCallback(async () => {
    const response = await downloadFormFlowResponse()

    if (response.data?.formFlowResponseDownload?.downloadUrl == null) {
      return
    }

    download(response.data?.formFlowResponseDownload?.downloadUrl)

    trackClick('agenda.download-form-flow-response', {
      payload: { id: surveyId, userId },
    })
  }, [downloadFormFlowResponse, download, surveyId, trackClick, userId])

  return (
    <ActionWrapper disabled={loading || isDownloading} onClick={onClick}>
      <Flex>
        <Icon Svg={PasteClipboard} colorName="neutral-80" size={24} />
      </Flex>
      <Flex $grow={1} $shrink={1}>
        <Text colorName="darker" fontWeight="600" kind="caption">
          <Translation id="actions.downloadSurvey" />
        </Text>
      </Flex>
      <Text colorName="primary" fontWeight="600" kind="caption">
        <Translation id="patientDetail.actions.download" />
      </Text>
    </ActionWrapper>
  )
}
