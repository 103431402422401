import { Flex, OverflowHidden } from 'cdk'
import { useMemo } from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { Divider, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useIsClinicalTestEnabledByTherapyPathType } from '~/domains/clinicalTests'
import { getPatientDetailRoute, useTherapySessionLive } from '~/domains/videocall'
import { useCurrentUserProfessionTypeValue } from '~/hooks/useCurrentUserProfessionTypeValue'

export const PatientDetailTabs = () => {
  const { pathname } = useLocation()
  const { therapyPathType } = useTherapySessionLive()
  const isClinicalTestEnabled = useIsClinicalTestEnabledByTherapyPathType(therapyPathType)
  const { isNutritionist } = useCurrentUserProfessionTypeValue()

  const {
    isDiagnosisVisible,
    isClinicalDiaryVisible,
    isClinicalTestsVisible,
    isFoodJournalVisible,
    isTherapySessionVisible,
  } = useMemo(() => {
    const isDiagnosisVisible = !!matchPath(pathname, {
      path: [getPatientDetailRoute('/diagnosis')],
    })
    const isClinicalDiaryVisible = !!matchPath(pathname, {
      path: [getPatientDetailRoute('/diary'), getPatientDetailRoute('/diary/:therapySessionId')],
    })
    const isClinicalTestsVisible = !!matchPath(pathname, {
      path: [getPatientDetailRoute('/clinical-tests'), getPatientDetailRoute('/clinical-tests/:clinicalTestAnswerId')],
    })
    const isFoodJournalVisible = !!matchPath(pathname, {
      path: [getPatientDetailRoute('/food-journal')],
    })

    return {
      isDiagnosisVisible,
      isClinicalDiaryVisible,
      isClinicalTestsVisible,
      isFoodJournalVisible,
      isTherapySessionVisible:
        (!isDiagnosisVisible && !isClinicalDiaryVisible && !isClinicalTestsVisible && !isFoodJournalVisible) ||
        !!matchPath(pathname, {
          path: [getPatientDetailRoute('/detail')],
        }),
    }
  }, [pathname])

  return (
    <OverflowHidden $borderColorName="primary-40" $borderRadius={8} $borderSize={1} $direction="row" $justify="center">
      <Flex
        $backgroundColorName={isTherapySessionVisible ? 'primary' : 'lighter'}
        $grow={1}
        $justify="center"
        $px={16}
        $py={8}
        $shrink={1}
        as={Link}
        replace
        to={getPatientDetailRoute('/detail')}
      >
        <Text
          colorName={isTherapySessionVisible ? 'white' : 'primary'}
          ellipsis
          fontWeight={isTherapySessionVisible ? '600' : '400'}
          kind="caption"
          textAlign="center"
        >
          <Translation id="patientDetail.session" />
        </Text>
      </Flex>

      <Divider direction="vertical" />

      <Flex
        $backgroundColorName={isDiagnosisVisible ? 'primary' : 'lighter'}
        $grow={1}
        $justify="center"
        $px={16}
        $py={8}
        $shrink={1}
        as={Link}
        replace
        to={getPatientDetailRoute('/diagnosis')}
      >
        <Text
          colorName={isDiagnosisVisible ? 'white' : 'primary'}
          fontWeight={isDiagnosisVisible ? '600' : '400'}
          kind="caption"
          textAlign="center"
        >
          <Translation id="patientDetail.diagnosis" />
        </Text>
      </Flex>

      <Divider direction="vertical" />

      <Flex
        $backgroundColorName={isClinicalDiaryVisible ? 'primary' : 'lighter'}
        $grow={1}
        $justify="center"
        $px={16}
        $py={8}
        $shrink={1}
        as={Link}
        replace
        to={getPatientDetailRoute('/diary')}
      >
        <Text
          colorName={isClinicalDiaryVisible ? 'white' : 'primary'}
          fontWeight={isClinicalDiaryVisible ? '600' : '400'}
          kind="caption"
          textAlign="center"
        >
          <Translation id="patientDetail.clinicalDiary" />
        </Text>
      </Flex>

      {isNutritionist && (
        <>
          <Divider direction="vertical" />

          <Flex
            $backgroundColorName={isFoodJournalVisible ? 'primary' : 'lighter'}
            $grow={1}
            $justify="center"
            $px={16}
            $py={8}
            $shrink={1}
            as={Link}
            replace
            to={getPatientDetailRoute('/food-journal')}
          >
            <Text
              colorName={isFoodJournalVisible ? 'white' : 'primary'}
              fontWeight={isFoodJournalVisible ? '600' : '400'}
              kind="caption"
              textAlign="center"
            >
              <Translation id="patientDetail.foodJournal" />
            </Text>
          </Flex>
        </>
      )}

      {isClinicalTestEnabled && (
        <>
          <Divider direction="vertical" />

          <Flex
            $backgroundColorName={isClinicalTestsVisible ? 'primary' : 'lighter'}
            $grow={1}
            $justify="center"
            $px={16}
            $py={8}
            $shrink={1}
            as={Link}
            replace
            to={getPatientDetailRoute('/clinical-tests')}
          >
            <Text
              colorName={isClinicalTestsVisible ? 'white' : 'primary'}
              fontWeight={isClinicalTestsVisible ? '600' : '400'}
              kind="caption"
              textAlign="center"
            >
              <Translation id="clinicalTests.patientDetail.tab.name" />
            </Text>
          </Flex>
        </>
      )}
    </OverflowHidden>
  )
}
