import { isAfterOrEqual, nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { isNeitherNullNorUndefined } from 'functions'
import { Icon, Plus } from 'icons'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import { Text } from 'ui'
import { Flex, Pressable } from 'ui-deprecated'
import { CenteredLoader } from '~/components/CenteredLoader'
import { DownloadSurvey } from '~/components/patientDetail/DownloadSurvey'
import { PatientDetailEmptyState } from '~/components/patientDetail/PatientDetailEmptyState'
import { SendInformedConsent } from '~/components/patientDetail/SendInformedConsent'
import { TherapySessionCardTherapist } from '~/components/TherapySessionCardTherapist'
import { Translation } from '~/components/Translation'
import { useTherapistTherapies } from '~/domains/therapies/TherapistTherapiesProvider'
import { getPatientDetailRoute } from '~/domains/videocall'
import { useCurrentUserProfessionTypeValue } from '~/hooks/useCurrentUserProfessionTypeValue'
import { useLatestClinicalBondByPatientId } from '~/hooks/useLatestClinicalBondByPatientId'
import { useTherapistAgendaByPatientId } from '~/hooks/useTherapistAgendaByPatientId'
import { type TherapistAgendaByPatientIdQuery } from '~/types/graphql'
import { ProductUserFeedbackClinicalBond } from './ProductUserFeedbackClinicalBond'
import { PsychiatricTherapySharedInformation } from './PsychiatricTherapySharedInformation'
import { RequestPsychiatrist } from './RequestPsychiatrist'

type Props = {
  isInTherapySessionLive?: boolean
  patientId: string
}

export const PatientDetailTherapySessions = ({ isInTherapySessionLive = false, patientId }: Props) => {
  const { therapies } = useTherapistTherapies()
  const { loading, error, items } = useTherapistAgendaByPatientId(patientId)
  const { latestClinicalBondByPatientId, loading: latestClinicalBondByPatientIdLoading } =
    useLatestClinicalBondByPatientId(patientId)
  const history = useHistory()
  const { pathname } = useLocation()
  const { isNutritionist, isPsychiatrist } = useCurrentUserProfessionTypeValue()
  const [showTherapySessionsButton, setShowTherapySessionsButton] = useState(false)
  const [futureSessions, setFutureSessions] = useState<TherapistAgendaByPatientIdQuery['therapistAgendaByPatientId']>(
    [],
  )
  const therapy = useMemo(() => therapies.find(({ patient }) => patient?.id === patientId), [patientId, therapies])

  const filteredItems = useMemo(
    () => items.filter(({ endAtSlot }) => pipe(endAtSlot, isAfterOrEqual(nowInMilliseconds()))),
    [items],
  )

  const nextSession = filteredItems.at(0)

  const showRequestPsychiatricVisit = !isNutritionist && !isPsychiatrist && !therapy?.hasPsychiatricSharedInformation
  const showPsychiatricInformationShared = isPsychiatrist && therapy?.hasPsychiatricSharedInformation

  useEffect(() => {
    if (loading || !filteredItems.length) {
      return
    }

    const firstFutureTherapySession = filteredItems.at(1)

    if (!firstFutureTherapySession) {
      return
    }

    setFutureSessions([firstFutureTherapySession])
    setShowTherapySessionsButton(filteredItems.length > 2 ? true : false)
  }, [filteredItems, loading])

  const onClick = useCallback(() => {
    setShowTherapySessionsButton(false)
    setFutureSessions(filteredItems.slice(1))
  }, [filteredItems])

  const onBookNextTherapySession = useCallback(() => {
    if (!therapy) {
      return
    }

    const matchPatientDetailPath = !!matchPath(pathname, { path: [getPatientDetailRoute('/detail')] })

    if (matchPatientDetailPath) {
      history.push(`/detail/therapy-session/${therapy.id}/schedule`)

      return
    }

    history.push(`/chat/${patientId}/detail/therapy-session/${therapy.id}/schedule`)
  }, [history, pathname, patientId, therapy])

  if (loading || latestClinicalBondByPatientIdLoading) {
    return <CenteredLoader />
  }

  return (
    <Flex grow={1} px={24} shrink={1}>
      <Flex pb={16} pt={24}>
        <Text colorName="primary" fontWeight="600" kind="paragraph">
          <Translation id="patientDetail.actions" />
        </Text>
      </Flex>

      {!therapy?.informedConsentsSigned && !!nextSession?.id && <SendInformedConsent id={nextSession.id} />}

      {!!latestClinicalBondByPatientId?.id && !isPsychiatrist && (
        <ProductUserFeedbackClinicalBond patientId={patientId} />
      )}

      {!!therapy?.formFlowResponse?.id && (
        <Flex pt={8}>
          <DownloadSurvey surveyId={therapy.formFlowResponse.id} />
        </Flex>
      )}

      {showRequestPsychiatricVisit && !!therapy?.id && (
        <Flex pt={8}>
          <RequestPsychiatrist therapyId={therapy.id} />
        </Flex>
      )}

      {showPsychiatricInformationShared && therapy?.id && (
        <PsychiatricTherapySharedInformation therapyId={therapy?.id} />
      )}

      {patientId && (
        <Flex>
          <Flex align="center" direction="row" justify="space-between" pt={16}>
            <Text colorName="primary" fontWeight="600" kind="paragraph">
              <Translation id="patientDetail.nextSession" />
            </Text>

            <Pressable align="center" autoWidth direction="row" onClick={onBookNextTherapySession}>
              <Flex pr={8}>
                <Text colorName="primary" fontWeight="600" kind="caption">
                  <Translation id="patientsManagement.therapySessions.book" />
                </Text>
              </Flex>

              <Flex borderColor="purple06" borderRadius={20} borderSize={1} p={8}>
                <Icon Svg={Plus} colorName="primary" size={24} />
              </Flex>
            </Pressable>
          </Flex>

          {!loading && !error && !!nextSession && (
            <Flex pt={16}>
              <Flex pb={16}>
                <TherapySessionCardTherapist
                  bonus={nextSession.bonus}
                  churn={nextSession.churn}
                  endAt={nextSession.endAt}
                  free={nextSession.free}
                  id={nextSession.id}
                  isInTherapySessionLive={isInTherapySessionLive}
                  notes={nextSession.notes}
                  patient={nextSession.patient}
                  provider={nextSession.provider}
                  recurrency={nextSession.recurrency}
                  repeatEveryWeek={nextSession.repeatEveryWeek}
                  startAt={nextSession.startAt}
                  status={nextSession.status}
                  therapist={nextSession.therapist}
                  therapyPathType={nextSession.therapyPathType}
                  typeCost={nextSession.typeCost}
                  url={nextSession.url}
                />
              </Flex>

              {!!futureSessions.length &&
                (futureSessions || []).filter(isNeitherNullNorUndefined).map((ts) => (
                  <Flex key={ts.id} pb={16}>
                    <TherapySessionCardTherapist {...ts} />
                  </Flex>
                ))}

              {showTherapySessionsButton && (
                <Flex align="center" pb={16}>
                  <Pressable autoWidth borderColor="purple06" onClick={onClick}>
                    <Flex background="white" borderColor="purple06" borderRadius={20} borderSize={1} px={16} py={8}>
                      <Text colorName="primary" fontWeight="600" kind="paragraph">
                        <Translation id="patientDetail.actions.showAll" />
                      </Text>
                    </Flex>
                  </Pressable>
                </Flex>
              )}
            </Flex>
          )}
        </Flex>
      )}

      {!nextSession && patientId && !loading && therapy && (
        <PatientDetailEmptyState patientId={patientId} therapyId={therapy.id} />
      )}
    </Flex>
  )
}
