import { addDays, differenceInDays, endOfMonth, format, isAfter, isSameDay, min, startOfMonth, subDays } from 'date-fns'
import { FormatDateEnum } from 'dates'
import { useEffect, useState } from 'react'
import { type FoodJournalMood } from '~/types/graphql'
import { useFoodJournal } from '../components/FoodJournalContext'
import { useLazyGetFoodJournalDays } from './useGetFoodJournalDays'

const formatDay = (date: Date) => format(date, FormatDateEnum.YEAR_MONTH_DAY)

export const useCalendarMoods = () => {
  const [moods, setMoods] = useState<Record<string, FoodJournalMood | null>>({})

  const { patientId, currentCalendarMonth } = useFoodJournal()

  const [fetchFoodJournalDays] = useLazyGetFoodJournalDays()

  useEffect(() => {
    ;(async () => {
      const from = subDays(currentCalendarMonth, 6)
      const to = min([addDays(endOfMonth(currentCalendarMonth), 6), new Date()])

      const moodsAlreadyFetched = formatDay(from) in moods && formatDay(to) in moods
      const shouldFetchMoods = !moodsAlreadyFetched && !isAfter(currentCalendarMonth, startOfMonth(new Date()))

      if (!shouldFetchMoods) {
        return
      }

      const { data } = await fetchFoodJournalDays({ variables: { patientId, from, to } })

      const fetchedMoods = [...Array(differenceInDays(to, from) + 1)].reduce(
        (acc, _, index) => {
          const current = addDays(from, index)

          acc[formatDay(current)] =
            data?.foodJournalDays.find(({ day }) => isSameDay(current, new Date(day)))?.mood ?? null

          return acc
        },
        {} as Record<string, FoodJournalMood | null>,
      )

      setMoods((prev) => ({ ...prev, ...fetchedMoods }))
    })()
  }, [fetchFoodJournalDays, currentCalendarMonth, patientId, moods])

  return { moods }
}
