import { useParticipantViewContext } from '@stream-io/video-react-sdk'
import { Flex, PositionAbsolute } from 'cdk'
import { BORDER_RADIUS_XS, SPACING_3XS } from 'design-tokens'
import { SerenisLogoIcon } from 'illustrations'
import { type ReactNode } from 'react'
import styled from 'styled-components'
import { Text } from 'ui'
import { ParticipantConnection } from './ParticipantConnection'

const ParticipantLabelContainer = styled(Flex)`
  background-color: rgb(20 19 35 / 50%);
`

type Props = {
  label: ReactNode
  size: 'sm' | 'md'
  withWatermark?: boolean
}

export const ParticipantViewUI = ({ label, size, withWatermark = false }: Props) => {
  const { participant } = useParticipantViewContext()

  return (
    <>
      {withWatermark && (
        <PositionAbsolute
          $backgroundColorName="neutral-10"
          $borderRadius={BORDER_RADIUS_XS}
          $opacity={0.5}
          $p={SPACING_3XS}
          $right={size === 'sm' ? '8px' : '16px'}
          $top={size === 'sm' ? '8px' : '16px'}
        >
          <SerenisLogoIcon />
        </PositionAbsolute>
      )}

      <PositionAbsolute $bottom={size === 'sm' ? '8px' : '16px'} $left={size === 'sm' ? '8px' : '16px'}>
        <ParticipantLabelContainer $align="center" $borderRadius={8} $direction="row" $px={8} $py={2}>
          <Text colorName="white" fontWeight="600" kind="caption">
            {label}
          </Text>
          <Flex $pl={4}>
            <ParticipantConnection participant={participant} />
          </Flex>
        </ParticipantLabelContainer>
      </PositionAbsolute>
    </>
  )
}
