import { Flex } from 'cdk'
import { SPACING_XS } from 'design-tokens'
import { ArrowRight, Icon } from 'icons'
import { type PropsWithChildren } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link } from 'ui'
import { type Route } from '~/utils/getRoute'

type Props = PropsWithChildren<{
  to: Route
  dataTestId?: string
}>

export const MenuLink = ({ children, dataTestId, to }: Props) => (
  <Link as={ReactRouterLink} colorName="darker" colorNameHover="primary" data-test-id={dataTestId} role="link" to={to}>
    <Flex $align="center" $direction="row" $gap={SPACING_XS} $py={8}>
      <Flex $align="center" $direction="row" $grow={1} $shrink={1}>
        {children}
      </Flex>
      <Flex>
        <Icon Svg={ArrowRight} colorName="primary" size={24} />
      </Flex>
    </Flex>
  </Link>
)
