import { useCall } from '@stream-io/video-react-sdk'
import { Flex } from 'cdk'
import { isBefore, subMinutes } from 'date-fns'
import { isBeforeOrEqual } from 'dates'
import { SPACING_XS } from 'design-tokens'
import { useEffect, useState } from 'react'
import { Modal, ModalProvider, Text } from 'ui'
import { Rating } from '~/components/Rating'
import { Translation } from '~/components/Translation'
import { useIsNativeApp } from '~/domains/appNative/hooks/useIsNativeApp'
import { useUpsertProductUserFeedbackAnswer } from '~/domains/productUserFeedback/hooks/useProductUserFeedbackAnswerUpsert'
import { useProductUserFeedbackCompleted } from '~/domains/productUserFeedback/hooks/useProductUserFeedbackCompleted'
import { useCreateProductUserFeedback } from '~/domains/productUserFeedback/hooks/useProductUserFeedbackCreate'
import { useTherapySessionLive } from '~/domains/videocall'
import { useToasts } from '~/hooks/useToasts'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { removeLocalStorage } from '~/utils/localStorage/removeLocalStorage'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'
import { browserSupportsVideocall } from '~/utils/minimumRequirements'

export const VideocallFeedbackModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const isNativeApp = useIsNativeApp()
  const { id: therapySessionId, startAt } = useTherapySessionLive()
  const { addToast } = useToasts()
  const call = useCall()

  const { data } = useProductUserFeedbackCompleted('VIDEOCALL_FEEDBACK', { therapySessionId })
  const createProductUserFeedback = useCreateProductUserFeedback()
  const upsertProductUserFeedbackAnswer = useUpsertProductUserFeedbackAnswer()

  const productUserFeedbackId = data?.productUserFeedbackCompleted?.id

  const lastFeedbackProvidedAt = getLocalStorage('videocall-last-feedback-provided-at')
  const lastFeedbackProvidedAtDate =
    lastFeedbackProvidedAt != null ? new Date(lastFeedbackProvidedAt) : subMinutes(new Date(), 10)
  const therapySessionStarted = isBefore(startAt, new Date())
  const enoughTimeSinceLastFeedback = isBeforeOrEqual(subMinutes(new Date(), 10))(lastFeedbackProvidedAtDate)
  const showFeedbackModal = therapySessionStarted && enoughTimeSinceLastFeedback

  useEffect(() => {
    if (showFeedbackModal) {
      setIsOpen(true)
    }
  }, [showFeedbackModal])

  const saveReview = async (score: number) => {
    const networkInfo = getLocalStorage('videocall-network-monitor')

    try {
      const id =
        productUserFeedbackId ??
        // We don't care about null access here, if it fails there's the catch
        (
          await createProductUserFeedback({
            variables: {
              input: {
                type: 'VIDEOCALL_FEEDBACK',
                extra: { therapySessionId },
                analytics: {
                  clientType: isNativeApp ? 'NATIVE_APP' : 'WEB',
                  supportsVIH: browserSupportsVideocall,
                  networkInfo: networkInfo != null ? JSON.parse(networkInfo) : null,
                },
              },
            },
          })
        ).data!.productUserFeedbackCreate!.id

      await upsertProductUserFeedbackAnswer({
        variables: {
          input: {
            id,
            answer: { id: 'SCORE', value: String(score) },
          },
        },
      })

      call?.submitFeedback(score)

      removeLocalStorage('videocall-network-monitor')
      setLocalStorage('videocall-last-feedback-provided-at', new Date().toISOString())
      addToast({ type: 'success', translationId: 'systemMessage.technicalFeedback.done.body' })
    } catch (error) {
      addToast({ type: 'alert', translationId: 'generic.error' })
    } finally {
      setIsOpen(false)
    }
  }

  return (
    <ModalProvider
      isOpen={isOpen}
      onIsOpenChange={(openState) => {
        setIsOpen(openState)
      }}
      preventClosingOutside
    >
      <Modal>
        <Flex $gap={SPACING_XS}>
          <Text kind="h3" textAlign="center">
            <Translation id="systemMessage.technicalFeedback.title" />
          </Text>

          <Text kind="caption" textAlign="center">
            <Translation id="systemMessage.technicalFeedback.body" />
          </Text>

          <Flex $align="center" $pt={SPACING_XS}>
            <Rating initialRating={0} onRate={saveReview} />
          </Flex>
        </Flex>
      </Modal>
    </ModalProvider>
  )
}
