import { Icon, Xmark } from 'icons'
import { Button, Modal, ModalProvider, ModalTrigger } from 'ui'
import { Translation } from '~/components/Translation'
import { type TherapistAgendaTherapySession } from '~/domains/agenda/types'
import { DeleteForTherapistEmergencyModal } from './components/DeleteForTherapistEmergencyModal'

type Props = Pick<TherapistAgendaTherapySession, 'free' | 'id' | 'startAt' | 'patient' | 'status' | 'typeCost'>

export const DeleteForTherapistEmergency = ({ free, id, startAt, patient, status, typeCost }: Props) => (
  <ModalProvider>
    <ModalTrigger>
      <Button isGhost kind="secondary" size="sm">
        <Icon Svg={Xmark} size={20} />
        <Translation id="actions.deleteForEmergency" />
      </Button>
    </ModalTrigger>

    <Modal>
      <DeleteForTherapistEmergencyModal
        free={free}
        id={id}
        patient={patient}
        startAt={startAt}
        status={status}
        typeCost={typeCost}
      />
    </Modal>
  </ModalProvider>
)
