import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useChatMessage } from '~/domains/chat/hooks/useChatMessage'
import { useProductUserFeedbackCompleted } from '~/domains/productUserFeedback/hooks/useProductUserFeedbackCompleted'
import { useGetTherapySessionById } from '~/domains/therapy-session/hooks/useGetTherapySessionById'
import { CustomMessageWrapper } from '../CustomMessageWrapper'
import { TechnicalFeedbackToComplete } from './MessageTechnicalFeedbackToComplete'

export const MessageTechnicalFeedback = () => {
  const group = useUserActingAsGroup()

  const { message } = useChatMessage<'SYSTEM_TECHNICAL_FEEDBACK'>()

  const therapySessionId = message.srns?.payload.id

  const therapySession = useGetTherapySessionById(therapySessionId)

  const { completed, error, loading, refetch } = useProductUserFeedbackCompleted('TECHNICAL_FEEDBACK', {
    therapySessionId,
  })

  if (
    !therapySession.item?.id ||
    group === 'therapist' ||
    error ||
    loading ||
    !!therapySession?.loading ||
    !!therapySession?.error
  ) {
    return null
  }

  return (
    <CustomMessageWrapper>
      {completed ? (
        <>
          <Flex pb={8}>
            <Text fontWeight="600" kind="caption">
              <Translation id="systemMessage.technicalFeedback.done.title" /> 🗳️
            </Text>
          </Flex>
          <Text fontWeight="400" kind="caption">
            <Translation id="systemMessage.technicalFeedback.done.body" />
          </Text>
        </>
      ) : (
        <TechnicalFeedbackToComplete
          onCompleted={refetch}
          therapyPathType={therapySession.item?.therapyPathType ?? null}
          therapySessionId={therapySession.item.id}
        />
      )}
    </CustomMessageWrapper>
  )
}
