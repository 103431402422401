import { addMinutes, isAfter, isBefore, subMinutes } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import {
  THERAPY_SESSION_JOINING_TIME_WINDOW_AFTER_END_AT_IN_MINUTES,
  THERAPY_SESSION_JOINING_TIME_WINDOW_BEFORE_START_AT_IN_MINUTES,
} from '~/constants'

type TimeRelatedStatus = 'IS_EARLY' | 'IS_JOINABLE' | 'IS_LATE'

export const timeRelatedStatusByStartAtAndDuration = (startAt: Date, endAt: Date): TimeRelatedStatus => {
  if (
    pipe(
      nowInMilliseconds(),
      isAfter(pipe(endAt, addMinutes(THERAPY_SESSION_JOINING_TIME_WINDOW_AFTER_END_AT_IN_MINUTES))),
    )
  ) {
    return 'IS_LATE'
  }

  if (
    pipe(
      nowInMilliseconds(),
      isBefore(pipe(startAt, subMinutes(THERAPY_SESSION_JOINING_TIME_WINDOW_BEFORE_START_AT_IN_MINUTES))),
    )
  ) {
    return 'IS_EARLY'
  }

  return 'IS_JOINABLE'
}

export const isTherapistLate = (startAt: Date): boolean =>
  pipe(nowInMilliseconds(), isAfter(pipe(startAt, addMinutes(5))))
