import { Flex } from 'cdk'
import { SPACING_XS } from 'design-tokens'
import { useFormContext } from 'react-hook-form'
import { Checkbox, Text } from 'ui'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { type CreatePatientInput } from '~/types/graphql'

export const FieldProfilation = () => {
  const { formState, register, watch } = useFormContext<CreatePatientInput>()
  const { labels } = useFormTranslations()
  const profilation = watch('profilation')

  return (
    <Flex $align="center" $direction="row" $gap={SPACING_XS}>
      <Checkbox id="profilation" {...register('profilation', { required: true })} required />
      <Text
        as="label"
        colorName={formState.touchedFields.profilation && !profilation ? 'error' : 'darker'}
        fontWeight="400"
        htmlFor="profilation"
        kind="footnote"
      >
        {labels.profilation}
      </Text>
    </Flex>
  )
}
