import { gql, useQuery } from '@apollo/client'
import {
  type QueryTherapySessionFindManyRecoverableByTherapyIdArgs,
  type TherapySessionFindManyRecoverableByTherapyIdQuery,
  type TherapySessionFindManyRecoverableByTherapyIdQueryVariables,
} from '~/types/graphql'

const QUERY = gql`
  query therapySessionFindManyRecoverableByTherapyId($therapyId: String!) {
    therapySessionFindManyRecoverableByTherapyId(therapyId: $therapyId) {
      deletedAt
      deletedReason
      id
      startAt
      therapistEmergencyReason
      therapistId
    }
  }
`

export const useTherapySessionFindManyRecoverableByTherapyId = ({
  therapyId,
}: QueryTherapySessionFindManyRecoverableByTherapyIdArgs) => {
  const { data, error, loading } = useQuery<
    TherapySessionFindManyRecoverableByTherapyIdQuery,
    TherapySessionFindManyRecoverableByTherapyIdQueryVariables
  >(QUERY, {
    variables: {
      therapyId,
    },
  })

  return {
    error: !!error,
    recoverableTherapySession: data?.therapySessionFindManyRecoverableByTherapyId.at(0),
    loading,
  }
}
