import { parseISO } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useChatMessage } from '~/domains/chat/hooks/useChatMessage'
import { getTherapySessionDayAndMonthLong } from '~/utils/dates/getTherapySessionDayAndMonth'
import { getTherapySessionStartAt } from '~/utils/dates/getTherapySessionStartAt'
import { CustomMessageWrapper } from './CustomMessageWrapper'

export const MessageRecurrencyCreated = () => {
  const group = useUserActingAsGroup()

  const { message } = useChatMessage<'SYSTEM_RECURRENCY_CREATED'>()

  const startAt = message.srns?.payload.startAt

  if (!startAt || group === 'saas-therapist') {
    return null
  }

  return (
    <CustomMessageWrapper>
      <Flex pb={8}>
        <Text fontWeight="600" kind="caption">
          <Translation id={`systemMessage.${group}.recurrencyCreated.title`} />
        </Text>
      </Flex>
      <TranslationMarkdown
        id={`systemMessage.${group}.recurrencyCreated.body`}
        kind="caption"
        values={{
          therapySessionsStartAtTime: pipe(startAt, parseISO, getTherapySessionStartAt),
          therapySessionsStartAtDay: pipe(startAt, parseISO, getTherapySessionDayAndMonthLong),
        }}
      />
    </CustomMessageWrapper>
  )
}
