import { range } from 'arrays'
import { Flex, media, PositionAbsolute, PositionRelative, Pressable } from 'cdk'
import { isValid } from 'date-fns'
import {
  BORDER_WIDTH_1,
  COLOR_LIGHTER,
  COLOR_NEUTRAL_40,
  COLOR_NEUTRAL_70,
  SPACING_MD,
  SPACING_SM,
  SPACING_XS,
  TIME_300,
} from 'design-tokens'
import { ChevronLeftCircle, ChevronRightCircle, Icon } from 'icons'
import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Carousel, useCarousel } from '~/components/Carousel'
import { GLOBAL_VALUE_SEPARATOR } from '~/constants'
import { type PadSlotsByDay } from '~/domains/reservation/utils/padSlotsByDay'
import { type UserTherapist } from '~/domains/therapist/types'
import { TIME_SLOT_AVATAR_HEADER_HEIGHT, TimeSlotDayTherapist } from './TimeSlotDayTherapist'
import { TIME_SLOT_DAY_HEADER_HEIGHT, TimeSlotDayTherapists } from './TimeSlotDayTherapists'

const Container = styled(PositionRelative)<{ $isMultiple: boolean }>`
  ${({ $isMultiple = false }) => css`
    background: linear-gradient(
      to bottom,
      ${COLOR_LIGHTER} ${TIME_SLOT_DAY_HEADER_HEIGHT}px,
      ${$isMultiple ? COLOR_NEUTRAL_70 : COLOR_NEUTRAL_40} ${TIME_SLOT_DAY_HEADER_HEIGHT}px
    );
  `};

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: calc(${BORDER_WIDTH_1} * -1);
    left: 0;
    border-bottom: ${BORDER_WIDTH_1} solid ${COLOR_NEUTRAL_40};
  }
`

type NavigationElementProps = {
  $dir: 'left' | 'right'
  $show: boolean
}

const NavigationElement = styled(PositionAbsolute)
  .attrs<NavigationElementProps>(({ $dir, $show }) => ({
    $top: `calc(50% - ${SPACING_SM})`,
    $opacity: $show ? 1 : 0,
    $left: ($dir === 'left' && `calc(${SPACING_MD} * -1)`) || 'auto',
    $right: ($dir === 'right' && `calc(${SPACING_MD} * -1)`) || 'auto',
  }))
  .withConfig({ displayName: 'TimeSlotDaysTherapistsNavigationElement' })`
  transition: opacity ${TIME_300};

  ${({ $dir }) => media.gtMd`
    left: ${$dir === 'left' ? `calc(${SPACING_MD} * -1)` : 'auto'};
    right: ${$dir === 'right' ? `calc(${SPACING_MD} * -1)` : 'auto'};
  `}

  ${({ $show }) => css`
    cursor: ${$show ? 'pointer' : 'default'};
    pointer-events: ${$show ? 'auto' : 'none'};
  `}
`

const Navigation = () => {
  const { nextDisabled, prevDisabled, goPrev, goNext } = useCarousel()

  return (
    <>
      <NavigationElement $dir="left" $show={!prevDisabled}>
        <Pressable onClick={goPrev}>
          <Icon Svg={ChevronLeftCircle} colorName="lighter" fillColorName="darker" size={24} />
        </Pressable>
      </NavigationElement>

      <NavigationElement $dir="right" $show={!nextDisabled}>
        <Pressable onClick={goNext}>
          <Icon Svg={ChevronRightCircle} colorName="lighter" fillColorName="darker" size={24} />
        </Pressable>
      </NavigationElement>
    </>
  )
}

type Props = {
  compareTherapists: boolean
  initialSelectedSlot?: string | null
  length: number
  slice: number
  selectedTherapist: UserTherapist | undefined
  slots: PadSlotsByDay
  suggestedTherapistsProfiles: UserTherapist[]
}

export const TimeSlotDaysTherapists = ({
  compareTherapists,
  initialSelectedSlot,
  length,
  slice,
  selectedTherapist,
  slots,
  suggestedTherapistsProfiles,
}: Props) => {
  const { goTo } = useCarousel()

  const [rangedData, setRangedData] = useState<PadSlotsByDay[] | null>(null)
  const [scrollToOnMount, setScrollToOnMount] = useState(initialSelectedSlot != null)

  const therapists = compareTherapists ? suggestedTherapistsProfiles : [selectedTherapist].filter((v) => v != null)

  useEffect(() => {
    if (!scrollToOnMount || initialSelectedSlot == null || !slots.length) {
      return
    }

    setScrollToOnMount(false)

    const [slot] = initialSelectedSlot.split(GLOBAL_VALUE_SEPARATOR)

    const index = slots.findIndex(([day]) => day.split('T')[0] === slot.split('T')[0])

    if (index !== -1) {
      goTo(Math.floor(index / slice))
    }
  }, [goTo, length, initialSelectedSlot, scrollToOnMount, slice, slots])

  useEffect(() => {
    setRangedData(range(length, (index) => slots.slice(index * slice, (index + 1) * slice)))
  }, [length, selectedTherapist?.id, slice, slots])

  if (rangedData == null || rangedData.length === 0) {
    return null
  }

  return (
    <PositionRelative $grow={1} $px={SPACING_XS} $shrink={1}>
      <Carousel
        itemFlexProps={{ $p: SPACING_XS, $overflow: 'clip' }}
        itemWidth="auto"
        showNavigation={false}
        showPagination={false}
        visibleItems={1}
      >
        {rangedData.map((days, index) => (
          <Container
            key={index}
            $direction="row"
            $gap={BORDER_WIDTH_1}
            $grow={1}
            $isMultiple={compareTherapists}
            $shrink={1}
          >
            {days.map(([day], index) => (
              <TimeSlotDayTherapists
                key={day}
                containerWidth={`calc(100% / ${slice})`}
                day={new Date(day)}
                isMultiple={compareTherapists}
              >
                {therapists.map((therapist) => (
                  <TimeSlotDayTherapist
                    key={`${day}-${therapist.id}`}
                    day={day}
                    isMultiple={compareTherapists}
                    slots={
                      days?.[index]?.[1]
                        ?.find(([therapistId]) => therapistId === therapist.id)?.[1]
                        ?.filter((slot): slot is Date => isValid(new Date(slot))) ?? []
                    }
                    therapistId={therapist.id}
                    therapistImage={therapist.therapist.profileImage?.s}
                    therapistName={therapist.fullName}
                  />
                ))}
              </TimeSlotDayTherapists>
            ))}
            {days.length < slice && (
              <Flex $backgroundColorName="lighter" $grow={1}>
                <Flex
                  $borderColorName="neutral-40"
                  $borderSizeBottom={1}
                  $minHeight={
                    TIME_SLOT_DAY_HEADER_HEIGHT + (compareTherapists ? TIME_SLOT_AVATAR_HEADER_HEIGHT + 1 : 0)
                  }
                />
              </Flex>
            )}
          </Container>
        ))}
      </Carousel>
      <Navigation />
    </PositionRelative>
  )
}
