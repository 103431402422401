import loadable from '@loadable/component'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom-v5-compat'
import { uidWithPrefix } from 'uid'
import { CenteredLoader } from '~/components/CenteredLoader'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatientOrTherapist } from '~/domains/auth/components/AssertPatientOrTherapist'
import { useChatToken } from '~/domains/chat/hooks/useChatToken'
import { VideocallProvider } from '~/domains/videocall'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { PageNoop } from '~/routes/PageNoop'
import { type Page } from '~/routes/types'

const LazyVideocallTroubleshootingPageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.VideocallTroubleshootingPageView,
})

export const VideocallTroubleshootingPage: Page = {
  exact: true,
  id: 'videocall.troubleshooting',
  paths: ['/videocall/troubleshooting'],
  options: {
    isVisibleAppBar: false,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: false,
    seoKey: 'videocall.troubleshooting',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatientOrTherapist>{children}</AssertPatientOrTherapist>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: ({ children }) => {
    const [searchParams] = useSearchParams()
    const { getToken, loading, token } = useChatToken()
    const { firstName, id } = useCurrentUser()
    const history = useHistory()

    const callId = searchParams.get('callId')

    useEffect(() => {
      if (callId == null) {
        history.replace({ search: `?callId=${uidWithPrefix('vih')}` })
      }
    }, [callId, history])

    useEffect(() => {
      getToken()
    }, [getToken])

    if (callId == null || token == null || loading) {
      return <CenteredLoader />
    }

    return (
      <VideocallProvider callId={callId} currentUser={{ firstName, id }} logLevel="debug" streamToken={token}>
        {children}
      </VideocallProvider>
    )
  },
  PageView: () => <LazyVideocallTroubleshootingPageView />,
}
