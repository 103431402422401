import { useEffect } from 'react'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useLanguage } from '~/i18n/hooks/useLanguage'

const FeaturebaseChangelogWidgetTrigger = () => {
  const { firstName } = useCurrentUser()
  const { language } = useLanguage()

  useEffect(() => {
    if (!(typeof window.Featurebase === 'function')) {
      return
    }

    window.Featurebase('init_changelog_widget', {
      locale: language,
      popup: {
        autoOpenForNewUpdates: true,
        enabled: true,
        usersName: firstName,
      },
      organization: 'serenis',
      theme: 'light',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export const FeaturebaseChangelogWidget = () => {
  const { isAuthenticated } = useAuthState()

  if (!isAuthenticated) {
    return null
  }

  return <FeaturebaseChangelogWidgetTrigger />
}
