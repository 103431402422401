import { type ChannelMemberResponse } from 'stream-chat'
import { useChatChannel } from '~/domains/chat/hooks/useChatChannel'
import { type ChatProps } from '~/domains/chat/types'

export const useChatPatientNullable = () => {
  const { channel } = useChatChannel()

  const patientId = channel?.data?.srns?.patient_id

  if (!patientId) {
    return null
  }

  const patient = channel.state.members[patientId]

  if (!patient) {
    return null
  }

  return patient
}

export const useChatPatient = () => {
  const patient = useChatPatientNullable()

  return patient as NonNullable<ChannelMemberResponse<ChatProps>>
}
