import { Flex, media, MountOn, PositionAbsolute, PositionRelative, Pressable } from 'cdk'
import { SPACING_2XS, SPACING_MD, SPACING_SM, SPACING_XS, TIME_150, TIME_300 } from 'design-tokens'
import { useBreakpoints } from 'hooks'
import { IconSerenisLeft, IconSerenisRight } from 'icons'
import { type RefObject, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Button, Drawer, DrawerFooter, DrawerProvider, DrawerTrigger, useDrawerContext } from 'ui'
import { Carousel, CarouselProvider, useCarousel } from '~/components/Carousel'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useCarouselSlotsForFirstBooking } from '~/domains/reservation/hooks/useCarouselSlotsForFirstBooking'
import { useReservationFlow } from '~/domains/reservation/hooks/useReservationFlow'
import { useSuspenseTherapyPathByIdQuery } from '~/domains/reservation/hooks/useTherapyPathByIdQuery'
import { type ReservationFormValues } from '~/domains/reservation/types'
import { TherapistCard } from '~/domains/therapist/TherapistOverviewCard'
import { type UserTherapist } from '~/domains/therapist/types'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { CompareCalendars } from '../common/CompareCalendars'
import { ReservationDurationText } from '../common/ReservationDurationText'
import { ReservationForm } from '../common/ReservationForm'
import { ReservationSelectedSlot } from '../common/ReservationSelectedSlot'
import { ReservationSubmitButton } from '../common/ReservationSubmitButton'
import { TimeSlotDaysTherapists } from '../TimeSlots/TimeSlotDaysTherapists'

const CarouselContainer = styled(Flex)`
  transition: transform ${TIME_150} linear;

  ${media.ltLg`
    &:has([data-floating-ui-inert]) {
      transition: transform ${TIME_150} ${TIME_300} linear;
      transform: translateX(-50%);
    }
  `}
`

const CarouselArrow = styled(PositionAbsolute)`
  transition: opacity ${TIME_150} ease-in-out;

  [disabled] & {
    cursor: default;
  }
`

type OpenCalendarHeaderProps = {
  isPsychiatry?: boolean
}

const OpenCalendarHeader = ({ isPsychiatry = false }: OpenCalendarHeaderProps) => (
  <Flex $align="center" $direction="row" $justify="space-between">
    <Flex $align="center" $grow={1}>
      {isPsychiatry ? (
        <TranslationMarkdown colorName="darker" id="reservation.bookPsychiatrySession" kind="h3" />
      ) : (
        <TranslationMarkdown colorName="darker" id="reservation.bookFreeTherapySession" kind="h3" />
      )}
    </Flex>
  </Flex>
)

type DrawerCalendarContentProps = {
  compareTherapists: boolean
  initialSelectedSlot?: string | null
  selectedTherapist: UserTherapist | undefined
  suggestedTherapistsProfiles: UserTherapist[]
  therapyPathType: TherapyTherapyPathType
  toggleCompareTherapists?: VoidFunction | undefined
  onSubmit: ({ selectedTimeSlotWithTherapist }: ReservationFormValues) => void
}

const DrawerCalendarContent = ({
  compareTherapists,
  initialSelectedSlot,
  selectedTherapist,
  suggestedTherapistsProfiles,
  therapyPathType,
  toggleCompareTherapists,
  onSubmit,
}: DrawerCalendarContentProps) => {
  const { close } = useDrawerContext()
  const { data, length, slice } = useCarouselSlotsForFirstBooking({
    compareTherapists,
    selectedTherapist,
    suggestedTherapistsProfiles,
    therapyPathType,
  })

  const { isLarge } = useBreakpoints()

  useEffect(() => {
    if (isLarge) {
      close()
    }
  }, [close, isLarge])

  return (
    <ReservationForm onSubmit={onSubmit}>
      <CarouselProvider length={length}>
        <Flex $gap={SPACING_XS} $grow={1} $pt={SPACING_MD} $shrink={1}>
          <OpenCalendarHeader isPsychiatry={therapyPathType === 'MYSELF_PSYCHIATRY'} />
          <TimeSlotDaysTherapists
            compareTherapists={compareTherapists}
            initialSelectedSlot={initialSelectedSlot}
            length={length}
            selectedTherapist={selectedTherapist}
            slice={slice}
            slots={data}
            suggestedTherapistsProfiles={suggestedTherapistsProfiles}
          />

          <DrawerFooter style={{ paddingTop: 0 }}>
            <Flex $gap={SPACING_MD} $grow={1} $shrink={1}>
              <Flex $gap={SPACING_2XS} $grow={1} $shrink={1}>
                <ReservationSelectedSlot
                  isFree={therapyPathType !== 'MYSELF_PSYCHIATRY'}
                  suggestedTherapistsProfiles={suggestedTherapistsProfiles}
                />
                <ReservationDurationText />
              </Flex>

              <Flex $align="center" $direction="row" $gap={SPACING_MD}>
                <Flex $grow={1} $shrink={1}>
                  <Button isGhost kind="primary" onClick={close}>
                    <Translation id="actions.cancel" />
                  </Button>
                </Flex>
                <Flex $grow={1} $shrink={1}>
                  <ReservationSubmitButton />
                </Flex>
              </Flex>

              <CompareCalendars
                alignCenter
                compareTherapists={compareTherapists}
                showBorder
                suggestedTherapistsProfiles={suggestedTherapistsProfiles}
                toggleCompareTherapists={toggleCompareTherapists}
              />
            </Flex>
          </DrawerFooter>
        </Flex>
      </CarouselProvider>
    </ReservationForm>
  )
}

type OpenCalendarProps = {
  compareTherapists: boolean
  initialSelectedSlot?: string | null
  onSubmit: ({ selectedTimeSlotWithTherapist }: ReservationFormValues) => void
  selectedTherapist: UserTherapist | undefined
  suggestedTherapistsProfiles: UserTherapist[]
  therapyPathType: TherapyTherapyPathType
  toggleCompareTherapists?: VoidFunction | undefined
  triggerRef?: RefObject<HTMLButtonElement>
}

export const TherapistOverviewCardOpenCalendar = ({
  compareTherapists,
  initialSelectedSlot,
  selectedTherapist,
  suggestedTherapistsProfiles,
  therapyPathType,
  toggleCompareTherapists,
  triggerRef,
  onSubmit,
}: OpenCalendarProps) => (
  <MountOn mediaQuery="lt-lg">
    <DrawerTrigger>
      <Flex $grow={1}>
        <Button ref={triggerRef} kind="primary" size="md">
          <Translation id="therapist.overviewCard.cta.showCalendar" />
        </Button>
      </Flex>
    </DrawerTrigger>

    <Drawer>
      <DrawerCalendarContent
        compareTherapists={compareTherapists}
        initialSelectedSlot={initialSelectedSlot}
        onSubmit={onSubmit}
        selectedTherapist={selectedTherapist}
        suggestedTherapistsProfiles={suggestedTherapistsProfiles}
        therapyPathType={therapyPathType}
        toggleCompareTherapists={toggleCompareTherapists}
      />
    </Drawer>
  </MountOn>
)

type BookBySuggestedTherapistsOverviewsProps = {
  initialSelectedSlot?: string | null
  onSubmit: ({ selectedTimeSlotWithTherapist }: ReservationFormValues) => void
  toggleCompareTherapists: VoidFunction
}

export const BookBySuggestedTherapistsOverviews = ({
  initialSelectedSlot,
  onSubmit,
  toggleCompareTherapists,
}: BookBySuggestedTherapistsOverviewsProps) => {
  const triggerRef = useRef<HTMLButtonElement>(null)
  const { emailVerified } = useCurrentUser()
  const { nextDisabled, prevDisabled, goNext: carouselGoNext, goPrev: carouselGoPrev, length, goTo } = useCarousel()
  const {
    autoOpenCalendar,
    compareTherapists,
    goBack,
    goNext,
    selectedTherapist,
    setAutoOpenCalendar,
    suggestedTherapistsProfiles,
  } = useReservationFlow()
  const { therapyPath } = useSuspenseTherapyPathByIdQuery()

  const hasMultipleTherapistsSuggested = suggestedTherapistsProfiles.length > 1

  useEffect(() => {
    if (initialSelectedSlot == null || !autoOpenCalendar || !emailVerified) {
      return
    }

    triggerRef.current?.click()
    setAutoOpenCalendar(false)
  }, [autoOpenCalendar, emailVerified, initialSelectedSlot, setAutoOpenCalendar])

  useEffect(() => {
    goTo(length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length])

  return (
    <PositionRelative $align="center" $maxWidth="fit-content" data-test-id="therapist-overview-card">
      <CarouselContainer $maxWidth={406}>
        <Carousel
          containerFlexProps={{ $px: hasMultipleTherapistsSuggested ? 16 : 0, $py: 4 }}
          itemFlexProps={hasMultipleTherapistsSuggested ? { $pl: 12 } : { $px: 16 }}
          itemWidth={hasMultipleTherapistsSuggested ? `calc(100% - ${SPACING_SM})` : '100%'}
          showNavigation={false}
          showPagination={false}
          visibleItems={1}
        >
          {suggestedTherapistsProfiles.map((therapist) => (
            <DrawerProvider key={therapist.id}>
              <TherapistCard
                calendarCTA={
                  <TherapistOverviewCardOpenCalendar
                    compareTherapists={compareTherapists}
                    initialSelectedSlot={initialSelectedSlot}
                    onSubmit={onSubmit}
                    selectedTherapist={selectedTherapist}
                    suggestedTherapistsProfiles={suggestedTherapistsProfiles}
                    therapyPathType={therapyPath.type}
                    toggleCompareTherapists={toggleCompareTherapists}
                    triggerRef={triggerRef}
                  />
                }
                therapistId={therapist.id}
                therapyId=""
                therapyPath={therapyPath.type}
              />
            </DrawerProvider>
          ))}
        </Carousel>
        <Pressable
          disabled={prevDisabled}
          onClick={() => {
            goBack()
            carouselGoPrev()
          }}
        >
          <CarouselArrow $left={12} $opacity={prevDisabled ? 0 : 1} $top="56px">
            <IconSerenisLeft size={24} />
          </CarouselArrow>
        </Pressable>
        <Pressable
          data-test-id="paths-carousel-next"
          disabled={nextDisabled}
          onClick={() => {
            goNext()
            carouselGoNext()
          }}
        >
          <CarouselArrow $opacity={nextDisabled ? 0 : 1} $right={12} $top="56px">
            <IconSerenisRight size={24} />
          </CarouselArrow>
        </Pressable>
      </CarouselContainer>
    </PositionRelative>
  )
}
