import { AgendaPollingEffect } from '~/domains/agenda/AgendaPollingEffect'
import { PushNotificationRequest } from '~/domains/appNative/components/PushNotificationRequest'
import { useActivateReferralEffect } from '~/domains/referral/hooks/useActivateReferralEffect'
import { useResetSuggestedTherapistCount } from '~/domains/reservation/hooks/useResetSuggestedTherapistCount'
import { AskForReview } from '~/domains/reviews'
import { usePatientAgenda } from '~/hooks/usePatientAgenda'
import { type Page } from '../types'

export const PathsLoaded: Page['PageLoaded'] = ({ children }) => {
  const { startPolling, stopPolling } = usePatientAgenda()

  useResetSuggestedTherapistCount()
  useActivateReferralEffect()

  return (
    <>
      <AgendaPollingEffect startPolling={startPolling} stopPolling={stopPolling} />
      <PushNotificationRequest />
      <AskForReview />
      {children}
    </>
  )
}
