import { gql, useQuery } from '@apollo/client'
import { type SuggestedTherapistsQuery } from '~/types/graphql'

const QUERY = gql`
  query suggestedTherapists {
    suggestedTherapists {
      therapistsIds
      isChanged
    }
  }
`

export const useSuggestedTherapists = () => {
  const { data, error, loading } = useQuery<SuggestedTherapistsQuery>(QUERY, {
    fetchPolicy: 'network-only',
  })

  return { error, loading, data }
}
