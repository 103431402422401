import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { type Page } from '~/routes/types'
import { PathsGuard } from './Guard'
import { PathsLoaded } from './Loaded'
import { PathsProvider } from './Provider'

export type PathsParams = {
  therapyId?: string
}

const LazyPageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.View,
})

export const PathsPage: Page = {
  exact: true,
  id: 'paths',
  paths: ['/paths', '/paths/:therapyId'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: true,
    seoKey: 'paths',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient target="/">
        <PathsGuard>{children}</PathsGuard>
      </AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PathsLoaded,
  PageProvider: PathsProvider,
  PageView: () => <LazyPageView />,
}
