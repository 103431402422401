import { useCallback } from 'react'
import { useCreateBatchRecurringTherapySession } from '~/domains/therapy-session/schedule/hooks/useCreateBatchRecurringTherapySession'
import { useCreateSpotTherapySession } from '~/domains/therapy-session/schedule/hooks/useCreateSpotTherapySession'
import { type BatchRecurrencyRepeat } from '~/types/graphql'

type Param = {
  onSuccess: () => void
  parentTherapySessionId: string
  recoverTherapySession?: boolean
  recoverableTherapySessionId?: string | null
  recurringTherapy: boolean
  repeat?: BatchRecurrencyRepeat
  repeatCount?: string
  repeatEvery?: string
  startAt: Date
}

type Props = {
  patientId: string
  therapyId: string
}

export const useBookTherapySessionByParentOnSubmit = ({ patientId, therapyId }: Props) => {
  const createBatchRecurringTherapySession = useCreateBatchRecurringTherapySession({ patientId, therapyId })
  const createSpotTherapySession = useCreateSpotTherapySession({ patientId, therapyId })

  return useCallback(
    async ({
      onSuccess,
      parentTherapySessionId,
      recoverTherapySession,
      recoverableTherapySessionId,
      recurringTherapy,
      repeat,
      repeatCount,
      repeatEvery,
      startAt,
    }: Param) => {
      if (recurringTherapy) {
        createBatchRecurringTherapySession({
          onSuccess,
          parentTherapySessionId,
          startAt,
          repeat: repeat || 'WEEK',
          repeatCount: Number(repeatCount),
          repeatEvery: Number(repeatEvery),
        })

        return
      }

      await createSpotTherapySession({
        onSuccess,
        parentTherapySessionId,
        recoverTherapySession,
        recoverableTherapySessionId,
        startAt,
      })
    },
    [createSpotTherapySession, createBatchRecurringTherapySession],
  )
}
