import { memo, type ReactNode } from 'react'
import { useUserRedirectEffect } from '~/hooks/useUserRedirectEffect'
import { getRoute, type Route } from '~/utils/getRoute'
import { useFeatureFlagsByFingerprint } from '../hooks/useFeatureFlagsByFingerprint'
import { type FeatureName } from '../types'

type Props = {
  children: ReactNode
  names: FeatureName[]
  target?: Route
}

const arePropsEqual = (prevProps: Props, nextProps: Props) =>
  (Object.keys(prevProps) as (keyof Props)[]).every((key) => {
    if (key === 'names') {
      return prevProps[key].every((name, index) => name === nextProps[key][index])
    }

    return Object.is(prevProps[key], nextProps[key])
  })

export const AssertFeatureFlagByFingerprintIsVariant = memo(({ children, names, target = '/' }: Props) => {
  const { isVariant } = useFeatureFlagsByFingerprint()
  const areFeatureFlagsEnabled = names.every((name) => isVariant(name))

  useUserRedirectEffect(!areFeatureFlagsEnabled, getRoute(target))

  if (!areFeatureFlagsEnabled) {
    return null
  }

  return <>{children}</>
}, arePropsEqual)
