import { useEffect } from 'react'
import { type PageKey, useSeoMeta } from '~/hooks/useSeoMeta'

type Props = {
  translationId: Extract<PageKey, `reservation.${string}`>
}

export const Head = ({ translationId }: Props) => {
  const { title } = useSeoMeta(translationId)

  useEffect(() => {
    document.title = title

    const updateMetaTag = (name: string, content: string) => {
      const metaElementInHead = document.querySelector(`meta[property='${name}']`)
      const metaElement = metaElementInHead ?? document.createElement('meta')
      metaElement.setAttribute('property', name)
      metaElement.setAttribute('content', content)

      if (metaElementInHead == null) {
        document.head.appendChild(metaElement)
      }
    }

    updateMetaTag('og:title', title)
    updateMetaTag('twitter:title', title)
  }, [title])

  return null
}
