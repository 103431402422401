import { Flex, OverflowAuto } from 'cdk'
import { BORDER_RADIUS_XS, SPACING_MD, SPACING_XS, TYPOGRAPHY_CAPTION_FONT_SIZE } from 'design-tokens'
import { useBreakpoints } from 'hooks'
import { Skeleton } from 'ui'

type Props = {
  isLoading?: boolean
}

export const TimeSlotsSkeleton = ({ isLoading }: Props) => {
  const { isLarge } = useBreakpoints()

  const days = isLarge ? [[1, 2, 3], [4, 5], [6], [7, 8], [9]] : [[1, 2, 3], [4, 5], [6]]

  return (
    <Flex $gap={SPACING_XS} $grow={1} $justify="space-between" $py={SPACING_XS} $shrink={1}>
      <Flex $grow={1} $px={SPACING_MD} $shrink={1}>
        <Flex $borderColorName="neutral-40" $borderSizeBottom={1} $direction="row" $grow={1} $shrink={1}>
          {days.map((day, index) => (
            <Flex key={index} $basis={`calc(100% / ${days.length})`} $borderColorName="neutral-40">
              <Flex $align="center" $borderColorName="neutral-40" $justify="center" $minHeight={40}>
                <Skeleton $active={isLoading} $backgroundColorName="neutral-40" $maxWidth={64} />
              </Flex>
              <OverflowAuto
                $borderColorName="neutral-40"
                $borderSizeLeft={index === 0 ? 0 : 1}
                $borderSizeTop={1}
                $gap={16}
                $grow={1}
                $pb={16}
                $pt={16}
                $px={8}
                $shrink={1}
              >
                {day.map((_, index) => (
                  <Flex key={index} $justify="center" $opacity={1 - 0.25 * index}>
                    <Flex
                      $align="center"
                      $borderColorName="neutral-40"
                      $borderRadius={BORDER_RADIUS_XS}
                      $borderSize={1}
                      $justify="center"
                      $maxHeight={42}
                      $minHeight={42}
                      $p={8}
                    >
                      <Skeleton $active={isLoading} $backgroundColorName="neutral-30" $maxWidth={44} />
                    </Flex>
                  </Flex>
                ))}
              </OverflowAuto>
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Flex $align="center">
        <Skeleton
          $active={isLoading}
          $backgroundColorName="neutral-30"
          $maxWidth={320}
          $minHeight={TYPOGRAPHY_CAPTION_FONT_SIZE}
        />
      </Flex>
    </Flex>
  )
}
