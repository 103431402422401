import { Flex } from 'cdk'
import { BORDER_RADIUS_3XS, SPACING_3XS, SPACING_SM, SPACING_XS } from 'design-tokens'
import { Icon, Minus, Plus } from 'icons'
import { type ReactElement } from 'react'
import { Button, Card, Text } from 'ui'
import { Image } from '~/components/Image'
import { Translation } from '~/components/Translation'
import { type TranslationId } from '~/i18n/types'
import { type FoodJournalDayDrinks } from '~/types/graphql'
import { Skeleton } from '../Skeleton'

export type DrinkKind = keyof FoodJournalDayDrinks

const configuration: Record<
  DrinkKind,
  {
    titleTranslationId: TranslationId
    amountSingularTranslationId: TranslationId
    amountPluralTranslationId: TranslationId
    amountMultiplier: number
    image: ReactElement
  }
> = {
  water: {
    titleTranslationId: 'nutrition.diary.drinkTracker.water',
    amountSingularTranslationId: 'nutrition.diary.drinkTracker.water.amount.singular',
    amountPluralTranslationId: 'nutrition.diary.drinkTracker.water.amount.plural',
    amountMultiplier: 0.25,
    image: <Image alt="water_icon" height={28} src="/nutrition/water-glass.png" width={28} />,
  },
  alcohol: {
    titleTranslationId: 'nutrition.diary.drinkTracker.alcohol',
    amountSingularTranslationId: 'nutrition.diary.drinkTracker.alcohol.amount.singular',
    amountPluralTranslationId: 'nutrition.diary.drinkTracker.alcohol.amount.plural',
    amountMultiplier: 1,
    image: <Image alt="water_icon" height={28} src="/nutrition/wine-glass.png" width={28} />,
  },
  beverage: {
    titleTranslationId: 'nutrition.diary.drinkTracker.beverage',
    amountSingularTranslationId: 'nutrition.diary.drinkTracker.beverage.amount.singular',
    amountPluralTranslationId: 'nutrition.diary.drinkTracker.beverage.amount.plural',
    amountMultiplier: 1,
    image: <Image alt="water_icon" height={28} src="/nutrition/beverage.png" width={28} />,
  },
}

type Props = {
  amount: number
  editable: boolean
  kind: DrinkKind
  loading?: boolean
} & (
  | { editable: false }
  | {
      editable: true
      updateDrink: (kind: DrinkKind, newAmount: number) => void
    }
)

export const DrinkTrackerOption = ({ amount, kind, loading = false, ...props }: Props) => {
  const config = configuration[kind]
  const { editable } = props

  const quantity = (
    <Text colorName="darker" kind="paragraph-strong">
      <Translation
        id={amount === 1 ? config.amountSingularTranslationId : config.amountPluralTranslationId}
        values={{ amount: amount * config.amountMultiplier }}
      />
    </Text>
  )

  return (
    <Flex $grow={1} $lgBasis={0}>
      <Card>
        <Flex $direction="row" $justify="space-between" $pb={SPACING_SM} $shrink={1}>
          <Text colorName="neutral-80" kind="caption">
            <Translation id={config.titleTranslationId} />
          </Text>

          {loading && (
            <Skeleton
              $borderRadius={BORDER_RADIUS_3XS}
              $grow={1}
              $maxWidth={80}
              $minHeight={20}
              data-test-id="food-journal-drink-loading"
            />
          )}
          {!loading && (
            <>
              {editable && quantity}
              {!editable && <Flex>{config.image}</Flex>}
            </>
          )}
        </Flex>

        <Flex $align="space-between" $direction="row" $gap={SPACING_3XS} $grow={0} $overflow="auto" $shrink={1}>
          {loading && (
            <Skeleton
              $borderRadius={BORDER_RADIUS_3XS}
              $grow={1}
              $minHeight={28}
              data-test-id="food-journal-drink-loading"
            />
          )}
          {!loading && (
            <>
              {!editable && quantity}

              {props.editable && (
                <>
                  {amount > 0 && (
                    <Flex $direction="row" $grow={0} $shrink={1} $wrap="wrap">
                      {[...Array(amount)].map((_, index) => (
                        <Flex key={index}>{config.image}</Flex>
                      ))}
                    </Flex>
                  )}

                  <Flex $direction="row" $gap={SPACING_XS} $grow={1} $justify="flex-end">
                    <Flex>
                      <Button
                        aria-label={`Remove ${kind}`}
                        disabled={amount === 0}
                        kind="secondary"
                        onClick={() => {
                          const newAmount = Math.max(amount - 1, 0)

                          if (newAmount !== amount) {
                            props.updateDrink(kind, newAmount)
                          }
                        }}
                        size="sm"
                      >
                        <Icon Svg={Minus} colorName="lighter" size={12} />
                      </Button>
                    </Flex>

                    <Flex>
                      <Button
                        aria-label={`Add ${kind}`}
                        kind="secondary"
                        onClick={() => {
                          props.updateDrink(kind, amount + 1)
                        }}
                        size="sm"
                      >
                        <Icon Svg={Plus} colorName="lighter" size={12} />
                      </Button>
                    </Flex>
                  </Flex>
                </>
              )}
            </>
          )}
        </Flex>
      </Card>
    </Flex>
  )
}
