import { parseISO } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { useMemo } from 'react'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useChatMessage } from '~/domains/chat/hooks/useChatMessage'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { type TranslationId } from '~/i18n/types'
import { type TherapistEmergencyReason } from '~/types/graphql'
import { getTherapySessionDayAndMonthLong } from '~/utils/dates/getTherapySessionDayAndMonth'
import { getTherapySessionStartAt } from '~/utils/dates/getTherapySessionStartAt'
import { CustomMessageWrapper } from './CustomMessageWrapper'

type Props = {
  reason: TherapistEmergencyReason | null
}

const SessionDeletedTherapistEmergencyTherapist = ({ reason }: Props) => {
  const { message } = useChatMessage<'SYSTEM_SESSION_DELETED_THERAPIST_EMERGENCY'>()

  const startAt = message.srns?.payload.startAt

  if (!startAt) {
    return null
  }

  return (
    <>
      <Flex pb={8}>
        <Text fontWeight="600" kind="caption">
          <Translation
            id={
              reason === 'PATIENT_REQUEST'
                ? 'systemMessage.therapist.sessionDeletedPatientRequest.title'
                : 'systemMessage.therapist.sessionDeletedTherapistEmergency.title'
            }
          />
        </Text>
      </Flex>
      <TranslationMarkdown
        id={
          reason === 'PATIENT_REQUEST'
            ? 'systemMessage.therapist.sessionDeletedPatientRequest.body'
            : 'systemMessage.therapist.sessionDeletedTherapistEmergency.body'
        }
        kind="caption"
        values={{
          therapySessionsStartAtTime: pipe(startAt, parseISO, getTherapySessionStartAt),
          therapySessionsStartAtDay: pipe(startAt, parseISO, getTherapySessionDayAndMonthLong),
        }}
      />
    </>
  )
}

const SessionDeletedTherapistEmergencyPatient = ({ reason }: Props) => {
  const { message } = useChatMessage<'SYSTEM_SESSION_DELETED_THERAPIST_EMERGENCY'>()

  const startAt = message.srns?.payload.startAt
  const therapistId = message.srns?.payload.therapistId

  const { therapies } = usePatientTherapies()

  const therapy = useMemo(
    () => therapies.find((therapy) => therapy.therapist?.id === therapistId),
    [therapies, therapistId],
  )

  const bodyTranslationId = useMemo((): TranslationId => {
    if (reason === 'FAMILY_ISSUE' || reason === 'HEALTH_ISSUE') {
      if (message.srns?.payload.free) {
        return 'systemMessage.patient.sessionDeletedTherapistEmergency.free.body'
      }

      if (message.srns?.payload.paid) {
        return 'systemMessage.patient.sessionDeletedTherapistEmergency.paid.body'
      }
    }

    if (reason === 'PATIENT_REQUEST') {
      return 'systemMessage.patient.sessionDeletedPatientRequest.body'
    }

    return 'systemMessage.patient.sessionDeletedTherapistEmergency.body'
  }, [reason, message.srns?.payload.paid, message.srns?.payload.free])

  if (!startAt || !therapy?.therapist) {
    return null
  }

  return (
    <>
      <Flex pb={8}>
        <Text fontWeight="600" kind="caption">
          <Translation
            id={
              reason === 'PATIENT_REQUEST'
                ? 'systemMessage.patient.sessionDeletedPatientRequest.title'
                : 'systemMessage.patient.sessionDeletedTherapistEmergency.title'
            }
            values={{ therapistFullName: therapy.therapist.fullName }}
          />
        </Text>
      </Flex>
      <TranslationMarkdown
        id={bodyTranslationId}
        kind="caption"
        values={{
          therapistFullName: therapy.therapist.fullName,
          therapySessionsStartAtTime: pipe(startAt, parseISO, getTherapySessionStartAt),
          therapySessionsStartAtDay: pipe(startAt, parseISO, getTherapySessionDayAndMonthLong),
        }}
      />
    </>
  )
}

export const MessageSessionDeletedTherapistEmergency = ({ reason }: Props) => {
  const group = useUserActingAsGroup()

  return (
    <CustomMessageWrapper>
      {group === 'therapist' ? (
        <SessionDeletedTherapistEmergencyTherapist reason={reason} />
      ) : (
        <SessionDeletedTherapistEmergencyPatient reason={reason} />
      )}
    </CustomMessageWrapper>
  )
}
