import { Flex } from 'cdk'
import { Card, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useTherapySessionLatestByTherapistIdAndPatientId } from '~/hooks/useTherapySessionLatestByTherapistIdAndPatientId'
import { PatientDetailEmptyStateActions } from './PatientDetailEmptyStateActions'

type Props = {
  patientId: string
  therapyId: string
}

export const PatientDetailEmptyState = ({ patientId, therapyId }: Props) => {
  const { therapySession } = useTherapySessionLatestByTherapistIdAndPatientId(patientId)

  return (
    <Flex $pt={24}>
      <Card $gap={16} $kind="placeholder">
        <Text kind="h3" textAlign="center">
          <Translation id="chat.therapist.noScheduledSessions" />
        </Text>
        {therapySession && <PatientDetailEmptyStateActions {...therapySession} therapyId={therapyId} />}
      </Card>
    </Flex>
  )
}
