import { range } from 'arrays'
import { type JournalingLogScore, type JournalingRewardType } from '~/types/graphql'

export const generateJournalingScoresFileNames = (): string[] => {
  const baseDir = '/stickers/journalingScore/'
  const scores = ['one', 'two', 'three', 'four', 'five']
  const maxVariants = 3

  return scores.reduce((acc: string[], score) => {
    for (let i = 1; i <= maxVariants; i++) {
      acc.push(`${baseDir}jrn_score_${score}_${i}.png`)
    }
    return acc
  }, [])
}

export const generateJournalingScoreFileNames = (score: Lowercase<JournalingLogScore>): string[] => {
  const baseDir = '/stickers/journalingScore/'
  const maxVariants = 3

  return range(maxVariants, (i) => `${baseDir}jrn_score_${score}_${i + 1}.png`)
}

export const generateRewardFileNames = (): string[] => {
  const baseDir = '/stickers/reward/'
  const maxRewards = 9

  return range(maxRewards, (i) => `${baseDir}reward_${i + 1}.png`)
}

export const generateJournalingRewardFileNames = (achievement: Lowercase<JournalingRewardType>): string[] => {
  const baseDir = '/stickers/achievement/'
  const maxVariants = 3

  return range(maxVariants, (i) => `${baseDir}${achievement}_${i + 1}.png`)
}
