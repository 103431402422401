import { useCallStateHooks } from '@stream-io/video-react-sdk'
import { Flex } from 'cdk'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { PageAppBarEffect } from '~/routes/PageAppBarEffect'
import { PageTrackEffect } from '~/routes/PageTrackEffect'
import { getRoute } from '~/utils/getRoute'
import { EndedPatient } from './EndedPatient'
import { EndedTherapist } from './EndedTherapist'
import { VideocallFeedbackModal } from './VideocallFeedbackModal'

export type EndedViewProps = {
  isEvaluationCompleted: boolean
  firstName: string
  onEvaluateTherapySessionClick: () => void
  onGoBackClick: () => void
  onShareStickerClick: () => void
}

export const Ended = () => {
  const { useLocalParticipant } = useCallStateHooks()
  const localParticipant = useLocalParticipant()
  const history = useHistory()

  const isTherapist = useUserActingAsGroup() === 'therapist'

  const goBackInAgenda = useCallback(() => {
    history.push(getRoute('/'))
  }, [history])

  return (
    <>
      <PageTrackEffect id="therapy-sessions.live.waiting-room.ended" />
      <PageAppBarEffect isVisibleAppBar isVisibleBackButton={false} isVisibleNotificationsInbox={false} />

      <VideocallFeedbackModal />

      <Flex $backgroundColorName="lighter" $grow={1} $justify="center">
        {isTherapist ? (
          <EndedTherapist onGoBackClick={goBackInAgenda} />
        ) : (
          <EndedPatient firstName={localParticipant?.name as string} onGoBackClick={goBackInAgenda} />
        )}
      </Flex>
    </>
  )
}
