import * as Sentry from '@sentry/nextjs'
import { type Logger } from '@stream-io/video-react-sdk'
import { isAfter } from 'date-fns'
import { useCallback, useEffect, useRef } from 'react'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { useCreateProductUserFeedback } from '~/domains/productUserFeedback/hooks/useProductUserFeedbackCreate'

const BATCH_SIZE = 50

type Log = {
  ts: string
  level: string
  message: string
  args: unknown[]
}

type Params = {
  endAt?: Date
  therapySessionId?: string
}

export const useVideocallLogger = ({ endAt, therapySessionId }: Params) => {
  const logs = useRef<Log[]>([])
  const sendPending = useRef<Promise<boolean> | null>(null)

  const { isVariant } = useFeatureFlagsByUserId()
  const isVideocallLoggingEnabled = isVariant('ff_videocall_logging')
  const createProductUserFeedback = useCreateProductUserFeedback()

  const sendBatch = useCallback(
    async (logsToSend: Log[]) => {
      if (logsToSend.length === 0) {
        return
      }

      try {
        await createProductUserFeedback({
          variables: {
            input: {
              type: 'VIDEOCALL_LOG',
              extra: { therapySessionId },
              analytics: JSON.stringify(logsToSend),
            },
          },
        })
      } catch (error) {
        Sentry.captureMessage(`Videocall log fail: ${String(error)}`)
      }
    },
    [therapySessionId, createProductUserFeedback],
  )

  const log: Logger = useCallback(
    async (level, message, ...args) => {
      if (!isVideocallLoggingEnabled || endAt == null || isAfter(new Date(), endAt)) {
        return
      }

      if (sendPending.current != null) {
        await sendPending.current
      }

      logs.current = [...logs.current, { ts: new Date().toISOString(), level, message, args }]

      if (logs.current.length >= BATCH_SIZE) {
        sendPending.current = (async () => {
          await sendBatch(logs.current)
          logs.current = []
          return true
        })()
        await sendPending.current
        sendPending.current = null
      }
    },
    [isVideocallLoggingEnabled, sendBatch, endAt],
  )

  useEffect(
    () => () => {
      sendBatch(logs.current)
    },
    [sendBatch],
  )

  return log
}
