import { useCallback } from 'react'
import { Text } from 'ui'
import { Button, Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { Modal, type ModalId, useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'

const modalId: ModalId = 'howToAddDiagnosis'

export const HowToAddDiagnosisModal = () => {
  const { close } = useModals()

  const onClick = useCallback(() => {
    close(modalId)
  }, [close])

  return (
    <Modal id={modalId}>
      <ModalCloseButton id={modalId} />

      <OverflowAuto>
        <Flex pt={8}>
          <Text fontWeight="600" kind="h3">
            <Translation id="diagnosis.howToAddDiagnosis.title" />
          </Text>
        </Flex>

        <TranslationMarkdown id="diagnosis.howToAddDiagnosis.body" kind="paragraph" pt={24} />
      </OverflowAuto>

      <Flex pt={16}>
        <Button kind="primary" onClick={onClick} type="button">
          <Translation id="diagnosis.howToAddDiagnosis.cta" />
        </Button>
      </Flex>
    </Modal>
  )
}
