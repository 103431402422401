import { type Intent } from '~/domains/auth/hooks/useSignupIntent'
import { type TherapyTherapyPathType } from '~/types/graphql'

export const getSignupIntentByTherapyPath = (therapyPath: TherapyTherapyPathType): Intent | null => {
  switch (therapyPath) {
    case 'COUPLES_PSYCHOTHERAPY':
      return 'therapyCouple'
    case 'MYSELF_PSYCHIATRY':
      return 'psychiatry'
    case 'MYSELF_PSYCHOTHERAPY':
      return 'therapyMyself'
    case 'PATH_COACHING':
      return 'coaching'
    case 'PATH_NUTRITION_DCA':
      return 'dca'
    case 'PATH_NUTRITION_WEIGHT_LOSS':
      return 'nutritionist'
    case 'PATH_SEXOLOGY':
      return 'sexology'
    case 'PATH_ASSERTIVE_COMMUNICATION':
    case 'PATH_SLEEP':
      return null
  }
}
