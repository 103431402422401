import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useFormValidation } from '~/hooks/useFormValidation'
import { SignupFormTextField } from './SignupFormTextField'

type Props = {
  disabled?: boolean
  hint?: string
  label?: string
  name?: string
  placeholder?: string
}

export const SignupFormEmailField = ({ disabled, hint, label, name, placeholder }: Props) => {
  const { isEmail } = useFormValidation()

  const {
    errors: { required },
    helperTexts,
    labels,
    placeholders,
  } = useFormTranslations()

  return (
    <SignupFormTextField
      autoComplete="email"
      disabled={disabled}
      hint={hint ?? helperTexts.emailSignup}
      inputMode="email"
      label={label ?? labels.email}
      name={name ?? 'email'}
      placeholder={placeholder ?? placeholders.email}
      rules={{
        required,
        validate: isEmail,
      }}
    />
  )
}
