import { type CSSProperties } from 'react'
import styled from 'styled-components'
import { Flex } from 'ui-deprecated'

export type TherapistCalendarEventWrapperStyle = (CSSProperties & { xOffset: number }) | undefined

export const TherapistCalendarEventWrapper = styled(Flex).attrs({
  grow: 1,
  shrink: 1,
})`
  position: absolute;
  left: 0;
  width: 100%;
  min-height: 10px;
  max-height: 100%;
  cursor: pointer;
`

TherapistCalendarEventWrapper.displayName = 'TherapistCalendarEventWrapper'
