import loadable from '@loadable/component'
import { StreamTheme } from '@stream-io/video-react-sdk'
import { MaxWidth1260px, PositionAbsolute, PositionRelative } from 'cdk'
import { assertNever } from 'functions'
import { type PropsWithChildren } from 'react'
import styled from 'styled-components'
import { BubblesBackground } from '~/components/BubblesBackground'
import { useWaitingRoom } from '~/domains/videocall/providers/WaitingRoomProvider'
import { PageTrackEffect } from '~/routes/PageTrackEffect'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { LeaveVideocall } from '../LeaveVideocall'
import { BaseLayout } from './components/BaseLayout'
import { Loading } from './components/Loading'

const LazyParticipantArrivesLate = loadable(() => import('./ParticipantArrivesLate/index.lazy'), {
  resolveComponent: (components) => components.ParticipantArrivesLate,
})

const UnclickablePositionAbsolute = styled(PositionAbsolute)`
  pointer-events: none;
`

const Content = ({ children }: PropsWithChildren) => {
  const status = useWaitingRoom()

  switch (status) {
    case 'LOADING':
      return <Loading />
    case 'PARTICIPANT_ARRIVES_LATE':
      return <LazyParticipantArrivesLate />
    case 'PARTICIPANT_ARRIVES_EARLY':
      return (
        <BaseLayout
          description="therapySessionLive.waitingRoom.early.paragraph"
          eventViewId="therapy-sessions.live.waiting-room.early"
          title="therapySessionLive.waitingRoom.early.title"
        >
          {children}
        </BaseLayout>
      )
    case 'PARTICIPANT_CAN_JOIN':
      return (
        <BaseLayout
          description="therapySessionLive.waitingRoom.joinable.paragraph"
          eventViewId="therapy-sessions.live.waiting-room.joinable"
          isJoinable
          title="therapySessionLive.waitingRoom.joinable.title"
        >
          {children}
        </BaseLayout>
      )
    case 'PARTICIPANT_CAN_START':
      return (
        <BaseLayout
          description="therapySessionLive.waitingRoom.start.paragraph"
          eventViewId="therapy-sessions.live.waiting-room.startable"
          isJoinable
          title="therapySessionLive.waitingRoom.start.title"
        >
          {children}
        </BaseLayout>
      )
    default:
      assertNever(status)
  }
}

type WaitingRoomProps = {
  therapyPathType: TherapyTherapyPathType | null
}

export const WaitingRoom = ({ therapyPathType }: WaitingRoomProps) => {
  const status = useWaitingRoom()

  return (
    <>
      <PageTrackEffect id="therapy-sessions.live.waiting-room" />

      {therapyPathType == null && (
        <UnclickablePositionAbsolute>
          <BubblesBackground therapyPathType={status === 'PARTICIPANT_ARRIVES_LATE' ? null : therapyPathType} />
        </UnclickablePositionAbsolute>
      )}

      <PositionRelative $grow={1} $shrink={1}>
        <MaxWidth1260px $grow={1} $shrink={1}>
          <PositionRelative $align="center" $direction="row" $justify="space-between" $px={24} $py={16} $zIndex={1}>
            <LeaveVideocall />
          </PositionRelative>
          <StreamTheme>
            <Content />
          </StreamTheme>
        </MaxWidth1260px>
      </PositionRelative>
    </>
  )
}
