import { type ComponentProps, forwardRef, type Ref } from 'react'
import { Controller, type RegisterOptions, useFormContext } from 'react-hook-form'
import { TextArea } from './TextArea'

type TextAreaProps = ComponentProps<typeof TextArea>

type Props = Omit<TextAreaProps, 'errorMessage' | 'value'> & {
  rules?: RegisterOptions
}

export const TherapySessionNotesTextArea = forwardRef(
  ({ disabled, name, rules, ...props }: Props, ref: Ref<HTMLTextAreaElement>) => {
    const { control } = useFormContext()

    return (
      <Controller
        control={control}
        defaultValue=""
        name={name}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
          formState: { isSubmitting, isValid },
        }) => (
          <TextArea
            {...props}
            ref={ref}
            disabled={disabled || (isSubmitting && isValid)}
            errorMessage={error?.message}
            invalid={!!error?.message}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
        rules={rules}
      />
    )
  },
)
