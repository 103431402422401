import { gql, useMutation } from '@apollo/client'
import {
  type UpdateTherapySessionNotesMutation,
  type UpdateTherapySessionNotesMutationVariables,
} from '~/types/graphql'

const MUTATION = gql`
  mutation updateTherapySessionNotes($input: UpdateTherapySessionNotesInput!) {
    updateTherapySessionNotes(input: $input) {
      notes
    }
  }
`

export const useUpdateTherapySessionNotes = () =>
  useMutation<UpdateTherapySessionNotesMutation, UpdateTherapySessionNotesMutationVariables>(MUTATION)
