import { Flex } from 'cdk'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useChatMessage } from '~/domains/chat/hooks/useChatMessage'
import { useProductUserFeedbackCompleted } from '~/domains/productUserFeedback/hooks/useProductUserFeedbackCompleted'
import { useGetTherapySessionById } from '~/domains/therapy-session/hooks/useGetTherapySessionById'
import { CustomMessageWrapper } from '../CustomMessageWrapper'
import { MessageFirstSessionFeedbackToComplete } from './MessageTechnicalFeedbackToComplete'

export const MessageFirstSessionFeedback = () => {
  const group = useUserActingAsGroup()

  const { message } = useChatMessage<'SYSTEM_FIRST_SESSION_FEEDBACK'>()

  const therapySessionId = message.srns?.payload.therapySessionId

  const therapySession = useGetTherapySessionById(therapySessionId)

  const { completed, error, loading, refetch } = useProductUserFeedbackCompleted('FIRST_SESSION_FEEDBACK', {
    therapySessionId,
  })

  if (
    !therapySession.item?.id ||
    !therapySession.item.therapyId ||
    group === 'therapist' ||
    error ||
    loading ||
    !!therapySession?.loading ||
    !!therapySession?.error
  ) {
    return null
  }

  return (
    <CustomMessageWrapper>
      {completed ? (
        <Flex $gap={8}>
          <Text fontWeight="600" kind="caption">
            <Translation id="systemMessage.firstSessionFeedback.done.title" /> 🗳️
          </Text>
          <Text fontWeight="400" kind="caption">
            <Translation id="systemMessage.firstSessionFeedback.done.body" />
          </Text>
        </Flex>
      ) : (
        <MessageFirstSessionFeedbackToComplete
          onComplete={refetch}
          therapyId={therapySession.item.therapyId}
          therapyPathType={therapySession.item.therapyPathType}
          therapySessionId={therapySession.item.id}
        />
      )}
    </CustomMessageWrapper>
  )
}
