import { type TherapyTherapyPathType } from '~/types/graphql'

const CORE_PATHS = new Set<TherapyTherapyPathType>(['MYSELF_PSYCHOTHERAPY', 'COUPLES_PSYCHOTHERAPY'])

const NUTRITION_PATHS = new Set<TherapyTherapyPathType>(['PATH_NUTRITION_WEIGHT_LOSS', 'PATH_NUTRITION_DCA'])

const REFERRAL_PATH_NOT_SUPPORTED = new Set<TherapyTherapyPathType>(['MYSELF_PSYCHIATRY', ...NUTRITION_PATHS])

const MEMBER_GET_MEMBER_PATH_NOT_SUPPORTED = new Set<TherapyTherapyPathType>(['MYSELF_PSYCHIATRY', ...NUTRITION_PATHS])

const CHANGE_THERAPIST_PATH_NOT_SUPPORTED = new Set<TherapyTherapyPathType>(['MYSELF_PSYCHIATRY', 'PATH_COACHING'])

export const isReferralSupported = (therapyPathType: TherapyTherapyPathType): boolean =>
  !REFERRAL_PATH_NOT_SUPPORTED.has(therapyPathType)

export const isMemberGetMemberSupported = (therapyPathType: TherapyTherapyPathType): boolean =>
  !MEMBER_GET_MEMBER_PATH_NOT_SUPPORTED.has(therapyPathType)

export const isChangeTherapistSupported = (therapyPathType: TherapyTherapyPathType): boolean =>
  !CHANGE_THERAPIST_PATH_NOT_SUPPORTED.has(therapyPathType)

export const isNutrition = (therapyPath: TherapyTherapyPathType): boolean => NUTRITION_PATHS.has(therapyPath)

export const isCorePath = (therapyPath: TherapyTherapyPathType): boolean => CORE_PATHS.has(therapyPath)
