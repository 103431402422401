import { gql, useMutation } from '@apollo/client'
import {
  type GetFoodJournalDayQuery,
  type GetFoodJournalDayQueryVariables,
  type SetFoodJournalDrinksMutation,
  type SetFoodJournalDrinksMutationVariables,
} from '~/types/graphql'
import { GET_FOOD_JOURNAL_DAY_QUERY } from './useGetFoodJournalDay'

type PartialKeys<T, K extends keyof T> = Omit<T, K> & { [P in K]?: Partial<T[P]> }

export const SET_FOOD_JOURNAL_DRINKS_MUTATION = gql`
  mutation setFoodJournalDrinks($input: SetFoodJournalDrinksInput!) {
    setFoodJournalDrinks(input: $input) {
      __typename
      id
      day
      patientId
      drinks {
        alcohol
        beverage
        water
      }
    }
  }
`

export const useSetFoodJournalDrinks = () => {
  const [setDrinks] = useMutation<SetFoodJournalDrinksMutation, SetFoodJournalDrinksMutationVariables>(
    SET_FOOD_JOURNAL_DRINKS_MUTATION,
    {
      update: (cache, { data }) => {
        if (data == null) {
          return
        }

        const foodJournalDay = data.setFoodJournalDrinks
        const { day, patientId } = foodJournalDay
        const variables = { patientId, day }

        const cachedFoodJournalDay = cache.readQuery<GetFoodJournalDayQuery, GetFoodJournalDayQueryVariables>({
          query: GET_FOOD_JOURNAL_DAY_QUERY,
          variables,
        })?.foodJournalDay

        if (cachedFoodJournalDay != null) {
          return
        }

        cache.writeQuery<PartialKeys<GetFoodJournalDayQuery, 'foodJournalDay'>, GetFoodJournalDayQueryVariables>({
          query: GET_FOOD_JOURNAL_DAY_QUERY,
          variables,
          data: { foodJournalDay },
        })
        cache.gc()
      },
    },
  )

  return setDrinks
}
