import { useCallback, useEffect } from 'react'
import { Switch, useHistory } from 'react-router-dom'
import { useToasts } from '~/hooks/useToasts'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { useProductUserFeedbackAnswerUpsert } from '../productUserFeedback/hooks/useProductUserFeedbackAnswerUpsert'
import { FirstSessionFeedbackRoute } from './FirstSessionFeedbackRoute'
import { Route1to3Score } from './Route1to3Score'
import { Route4to5Score } from './Route4to5Score'
import { RouteOverallScore } from './RouteOverallScore'
import { RouteWithEmpathy } from './RouteWithEmpathy'
import { RouteWithoutEmpathy } from './RouteWithoutEmpathy'
import { type FirstSessionFeedbackPath, type FormId } from './types'

type Props = {
  firstSessionFeedbackId: string
  therapyId: string
  therapyPathType: TherapyTherapyPathType | null
  onComplete: () => void
}

export const FirstSessionFeedbackRoutes = ({
  firstSessionFeedbackId,
  therapyId,
  therapyPathType,
  onComplete,
}: Props) => {
  const history = useHistory()

  const { addToast } = useToasts()

  const { productUserFeedbackAnswerUpsert } = useProductUserFeedbackAnswerUpsert(firstSessionFeedbackId)

  const onSubmit = useCallback(
    async (value: string, id: FormId, nextPath: FirstSessionFeedbackPath) => {
      try {
        const productUserFeedbackAnswerUpsertResponse = await productUserFeedbackAnswerUpsert({
          id,
          value,
        })

        if (!productUserFeedbackAnswerUpsertResponse.data?.productUserFeedbackAnswerUpsert?.id) {
          throw new Error('Unable to save response')
        }

        history.push(nextPath)
      } catch (error) {
        addToast({
          translationId: 'reviews.askReviewModal.bad.unableToSaveResponse',
          type: 'alert',
        })
      }
    },
    [addToast, history, productUserFeedbackAnswerUpsert],
  )

  useEffect(
    () => () => {
      onComplete()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <Switch>
      <FirstSessionFeedbackRoute path="/overall-score">
        <RouteOverallScore onSubmit={onSubmit} />
      </FirstSessionFeedbackRoute>
      <FirstSessionFeedbackRoute path="/4-to-5-score">
        <Route4to5Score feedbackId={firstSessionFeedbackId} therapyId={therapyId} />
      </FirstSessionFeedbackRoute>
      <FirstSessionFeedbackRoute path="/1-to-3-score">
        <Route1to3Score onSubmit={onSubmit} />
      </FirstSessionFeedbackRoute>
      <FirstSessionFeedbackRoute path="/with-empathy">
        <RouteWithEmpathy feedbackId={firstSessionFeedbackId} />
      </FirstSessionFeedbackRoute>
      <FirstSessionFeedbackRoute path="/without-empathy">
        <RouteWithoutEmpathy
          feedbackId={firstSessionFeedbackId}
          therapyId={therapyId}
          therapyPathType={therapyPathType}
        />
      </FirstSessionFeedbackRoute>
    </Switch>
  )
}
