import { pipe } from 'fp-ts/function'
import { type ClinicalBondFormValues } from '../types'

export type ClinicalBondAverageRating = 'low' | 'medium' | 'high'

export const getClinicalBondAverageRating = (values: ClinicalBondFormValues): ClinicalBondAverageRating => {
  const total = pipe(
    values.respect,
    (value) => value + values.listen,
    (value) => value + values.management,
    (value) => value + values.overall,
    (value) => value / 4,
    (value) => Math.floor(value),
  )

  if (total > 4) {
    return 'high'
  }

  if (total > 3) {
    return 'medium'
  }

  return 'low'
}
