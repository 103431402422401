import { Flex, Space } from 'cdk'
import { BORDER_RADIUS_XS, SPACING_MD, SPACING_SM } from 'design-tokens'
import { EmptyCalendar } from 'illustrations'
import { useHistory } from 'react-router-dom'
import { Link as RootLink } from 'react-router-dom-v5-compat'
import { Button, Divider, DrawerFooter, DrawerHeader, Skeleton } from 'ui'
import { CarouselProvider } from '~/components/Carousel'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { useCarouselSlots } from '~/domains/reservation/hooks/useCarouselSlots'
import { TimeSlotDaysTherapists } from '~/domains/reservation/pages/BookBySuggestedTherapists/TimeSlots/TimeSlotDaysTherapists'
import { TimeSlotsSkeleton } from '~/domains/therapy-session/components/TimeSlotsSkeleton'
import { useTherapist } from '~/hooks/useTherapist'
import { getRoute } from '~/utils/getRoute'
import { useManageSession } from '../hooks/useManageSession'
import { getManageSessionRoutes } from '../utils/getManageSessionRoutes'
import { RescheduleSessionDeleteLink } from './RescheduleSessionDeleteLink'

export const RescheduleSessionSlots = () => {
  const { watch } = useReactHookFormContext()
  const { minutes, therapyPath, session, therapyId, loading: manageSessionLoading } = useManageSession()
  const history = useHistory()
  const therapist = useTherapist()
  const {
    data,
    error: slotsError,
    length,
    loading: slotsLoading,
    slice,
  } = useCarouselSlots({
    maxVisibleColumns: 5,
    selectedTherapist: therapist,
    therapyPathType: therapyPath.type,
    therapyId,
  })
  const trackClick = useTrackEventClick()

  const isLoading = manageSessionLoading || slotsLoading

  if (isLoading || slotsError) {
    return (
      <>
        <DrawerHeader>
          <Flex $align="center" $grow={1} $shrink={1}>
            <TranslationMarkdown colorName="darker" id="therapySession.rescheduleSession.title" kind="h3" />
          </Flex>
        </DrawerHeader>
        <TimeSlotsSkeleton isLoading={isLoading} />
        <DrawerFooter>
          <Flex $gap={SPACING_SM} $grow={1} $shrink={1}>
            <Flex $direction="row" $gap={SPACING_MD} $grow={1} $justify="space-between" $shrink={1}>
              <Flex $grow={1} $shrink={1}>
                {isLoading ? (
                  <Skeleton
                    $active
                    $backgroundColorName="neutral-20"
                    $borderRadius={BORDER_RADIUS_XS}
                    $minHeight={40}
                  />
                ) : (
                  <Button data-test-id="therapy-session-cancel-button" isGhost kind="primary" onClick={history.goBack}>
                    <Translation id="actions.cancel" />
                  </Button>
                )}
              </Flex>
              <Flex $grow={1} $shrink={1}>
                {isLoading ? (
                  <Skeleton
                    $active
                    $backgroundColorName="primary-10"
                    $borderRadius={BORDER_RADIUS_XS}
                    $minHeight={40}
                  />
                ) : (
                  <Button data-test-id="therapy-session-edit-confirm-button" disabled kind="primary">
                    <Translation id="actions.confirm" />
                  </Button>
                )}
              </Flex>
            </Flex>
            <Divider colorName="neutral-40" />
            <Flex $direction="row" $grow={1} $justify="center" $shrink={1}>
              <Skeleton $active={isLoading} $backgroundColorName="neutral-30" $maxWidth={155} />
              <Space />
              <Skeleton $active={isLoading} $backgroundColorName="primary-30" $maxWidth={67} />
            </Flex>
          </Flex>
        </DrawerFooter>
      </>
    )
  }

  if (length === 0) {
    return (
      <>
        <DrawerHeader>
          <Flex $align="center" $grow={1} $shrink={1}>
            <TranslationMarkdown colorName="darker" id="therapySession.rescheduleSession.title" kind="h3" />
          </Flex>
        </DrawerHeader>
        <Flex $align="center" $pb={24}>
          <EmptyCalendar />
        </Flex>
        <TranslationMarkdown
          colorName="darker"
          id="therapySession.bookSession.empty"
          values={{ therapistFullName: therapist.fullName }}
        />
        <DrawerFooter $direction="column" $gap={SPACING_MD}>
          <Flex $grow={1} $shrink={1}>
            <RootLink
              data-test-id="therapy-session-edit-chat-button"
              onClick={() => {
                trackClick('therapy-session.go-to-professional-chat-for-rescheduling', {
                  therapistId: therapist.id,
                  therapySessionId: session?.id,
                })
              }}
              to={getRoute(`/chat/${therapist.id}`)}
            >
              <Button as="span" kind="primary">
                <Translation id="actions.open.chat" />
              </Button>
            </RootLink>
          </Flex>
          <Divider colorName="neutral-40" />
          <RescheduleSessionDeleteLink />
        </DrawerFooter>
      </>
    )
  }

  return (
    <>
      <DrawerHeader>
        <Flex $align="center" $grow={1} $shrink={1}>
          <TranslationMarkdown colorName="darker" id="therapySession.rescheduleSession.title" kind="h3" />
        </Flex>
      </DrawerHeader>
      <Flex $grow={1} $minHeight={300} $shrink={1}>
        <CarouselProvider length={length}>
          <TimeSlotDaysTherapists
            compareTherapists={false}
            length={length}
            selectedTherapist={therapist}
            slice={slice}
            slots={data}
            suggestedTherapistsProfiles={[therapist]}
          />
        </CarouselProvider>
      </Flex>
      {minutes != null && (
        <Flex $align="center">
          <TranslationMarkdown
            colorName="neutral-70"
            id="reservation.bookBySuggestedTherapist.duration"
            kind="caption"
            values={{ minutes }}
          />
        </Flex>
      )}
      <DrawerFooter>
        <Flex $gap={SPACING_SM} $grow={1} $shrink={1}>
          <Flex $direction="row" $gap={SPACING_MD} $grow={1} $justify="space-between" $shrink={1}>
            <Flex $grow={1} $shrink={1}>
              <Button data-test-id="therapy-session-cancel-button" isGhost kind="primary" onClick={history.goBack}>
                <Translation id="actions.cancel" />
              </Button>
            </Flex>
            <Flex $grow={1} $shrink={1}>
              <Button
                data-test-id="therapy-session-edit-confirm-button"
                disabled={watch('selectedTimeSlotWithTherapist') == null}
                kind="primary"
                onClick={() => history.push(getManageSessionRoutes('/reschedule-motivation'))}
              >
                <Translation id="actions.confirm" />
              </Button>
            </Flex>
          </Flex>
          <Divider colorName="neutral-40" />
          <RescheduleSessionDeleteLink />
        </Flex>
      </DrawerFooter>
    </>
  )
}
