import { Flex, MaxWidth640px, MountOn, OverflowAuto } from 'cdk'
import { useBreakpoints } from 'hooks'
import { useCallback } from 'react'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Text } from 'ui'
import { CenteredLoader } from '~/components/CenteredLoader'
import { Translation } from '~/components/Translation'
import { useDiagnosisCouples } from '~/domains/diagnosisCouples/hooks/useDiagnosisCouples'
import { PatientDetailMaxWidth } from '~/domains/patientsManagement/components/PatientDetailMaxWidth'
import { getPatientDetailRoute } from '~/domains/videocall'
import { getRoute, type Route } from '~/utils/getRoute'
import { DiagnosisCouplesPreview } from './DiagnosisCouplesPreview'

const BoxShadow = styled(Flex)`
  box-shadow: 0 -4px 10px 0 rgb(0 0 0 / 5%);
`

type Props = {
  rootPath: Extract<Route, '/chat' | '/patients'>
}

export const PatientDetailDiagnosisCouples = ({ rootPath }: Props) => {
  const { error, loading, patientId, isEditable } = useDiagnosisCouples()
  const history = useHistory()
  const { isMedium } = useBreakpoints()
  const { pathname } = useLocation()

  const handleEdit = useCallback(() => {
    const matchPatientDetailPath = !!matchPath(pathname, { path: [getPatientDetailRoute('/diagnosis')] })

    if (matchPatientDetailPath) {
      history.push(getPatientDetailRoute('/diagnosis/edit'), {
        previousRoute: getPatientDetailRoute('/diagnosis'),
      })

      return
    }

    history.push(getRoute(`${rootPath}/${patientId}/diagnosis/edit`), {
      previousRoute: getRoute(`${rootPath}/${patientId}/diagnosis`),
    })
  }, [history, pathname, patientId, rootPath])

  if (loading) {
    return <CenteredLoader />
  }

  if (error) {
    return (
      <PatientDetailMaxWidth mdPt={24} pt={16}>
        <Text fontWeight="600" kind="paragraph">
          <Translation id="generic.errorOccurred.title" />
        </Text>

        <Flex $pt={8}>
          <Text fontWeight="400" kind="paragraph">
            <Translation id="generic.errorOccurred.text" />
          </Text>
        </Flex>
      </PatientDetailMaxWidth>
    )
  }

  return (
    <>
      <MountOn mediaQuery="gt-md">
        <OverflowAuto $grow={1} $py={24} $shrink={1}>
          <PatientDetailMaxWidth>
            <DiagnosisCouplesPreview />
          </PatientDetailMaxWidth>
        </OverflowAuto>

        {isEditable && (
          <BoxShadow $py={16}>
            <PatientDetailMaxWidth>
              <MaxWidth640px>
                <Button kind="tertiary" onClick={handleEdit} size={isMedium ? 'md' : 'sm'}>
                  <Translation id="patientDetail.diagnosis.edit" />
                </Button>
              </MaxWidth640px>
            </PatientDetailMaxWidth>
          </BoxShadow>
        )}
      </MountOn>

      <MountOn mediaQuery="lt-lg">
        <Flex $grow={1} $py={16} $shrink={1}>
          <PatientDetailMaxWidth>
            <DiagnosisCouplesPreview />
          </PatientDetailMaxWidth>
          {isEditable && (
            <BoxShadow $py={16}>
              <PatientDetailMaxWidth>
                <MaxWidth640px>
                  <Button kind="tertiary" onClick={handleEdit} size={isMedium ? 'md' : 'sm'}>
                    <Translation id="patientDetail.diagnosis.edit" />
                  </Button>
                </MaxWidth640px>
              </PatientDetailMaxWidth>
            </BoxShadow>
          )}
        </Flex>
      </MountOn>
    </>
  )
}
