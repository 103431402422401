import { CallStats, SfuModels, type StreamVideoParticipant } from '@stream-io/video-react-sdk'
import { OverflowAuto, Pressable } from 'cdk'
import { Icon, type IconElement, Reports, ReportsExcellent, ReportsGood, ReportsPoor } from 'icons'
import { useMemo } from 'react'
import { Modal, ModalProvider, ModalTrigger } from 'ui'
import { useTranslation } from '~/i18n/hooks/useTranslation'

export const ParticipantConnection = ({ participant }: { participant: StreamVideoParticipant }) => {
  const connectionQualityAsString = SfuModels.ConnectionQuality[participant.connectionQuality].toLowerCase() as
    | 'excellent'
    | 'good'
    | 'poor'
    | 'unspecified'

  const connectionQualityTranslation = useTranslation(`videocall.connectionQuality.${connectionQualityAsString}`)

  const iconElement: IconElement = useMemo(() => {
    switch (participant.connectionQuality) {
      case SfuModels.ConnectionQuality.POOR:
        return ReportsPoor
      case SfuModels.ConnectionQuality.GOOD:
        return ReportsGood
      case SfuModels.ConnectionQuality.EXCELLENT:
        return ReportsExcellent
      case SfuModels.ConnectionQuality.UNSPECIFIED:
      default:
        return Reports
    }
  }, [participant.connectionQuality])

  return (
    <ModalProvider>
      <ModalTrigger>
        <Pressable className="srns-quality-connection" title={connectionQualityTranslation}>
          <Icon Svg={iconElement} size={12} />
        </Pressable>
      </ModalTrigger>
      <Modal>
        <OverflowAuto>
          <CallStats />
        </OverflowAuto>
      </Modal>
    </ModalProvider>
  )
}
