import { Icon, UserXmark } from 'icons'
import { Button, Modal, ModalProvider, ModalTrigger } from 'ui'
import { Translation } from '~/components/Translation'
import { type TherapistAgendaTherapySession } from '~/domains/agenda/types'
import { MarkAsNoShowTherapySessionModal } from './MarkAsNoShowTherapySessionModal'

type Props = Pick<TherapistAgendaTherapySession, 'id' | 'startAt' | 'patient'>

export const MarkAsNoShowTherapySession = ({ id, startAt, patient }: Props) => (
  <ModalProvider>
    <ModalTrigger>
      <Button isGhost kind="error" size="sm">
        <Icon Svg={UserXmark} size={20} />
        <Translation id="actions.markAsNoShow" />
      </Button>
    </ModalTrigger>

    <Modal>
      <MarkAsNoShowTherapySessionModal id={id} patient={patient} startAt={startAt} />
    </Modal>
  </ModalProvider>
)
