import {
  CompositeButton,
  OwnCapability,
  PermissionNotification,
  Restricted,
  useCallStateHooks,
  useRequestPermission,
} from '@stream-io/video-react-sdk'
import { Computer, Icon, PcCheck } from 'icons'
import { type PropsWithChildren, useCallback, useMemo } from 'react'
import { Text } from 'ui'
import { Tooltip, TooltipContent, TooltipTrigger } from '~/components/FloatingUI/Tooltip'
import { Translation } from '~/components/Translation'
import { useIsNativeApp } from '~/domains/appNative/hooks/useIsNativeApp'
import { useVideocallEventLogger } from '~/domains/videocall'
import { useToasts } from '~/hooks/useToasts'
import { useTranslation } from '~/i18n/hooks/useTranslation'

/**
 * Recreated Toggle Screen Share Button as suggested by Stream dev Oliver Lazoroski
 * @see https://github.com/GetStream/stream-video-js/blob/d61525733e87edec520e610d3e94c957eab207a0/packages/react-sdk/src/components/CallControls/ScreenShareButton.tsx
 */
const ScreenShareButton = ({ children }: PropsWithChildren) => {
  const { useScreenShareState } = useCallStateHooks()
  const { isMute } = useScreenShareState()
  const { isAwaitingPermission } = useRequestPermission(OwnCapability.SCREENSHARE)

  const messageApproved = useTranslation('videocall.screenShare.approved')
  const messageAwaitingApproval = useTranslation('videocall.screenShare.awaitingApproval')
  const messageRevoked = useTranslation('videocall.screenShare.revoked')

  return (
    <Restricted requiredGrants={[OwnCapability.SCREENSHARE]}>
      <PermissionNotification
        isAwaitingApproval={isAwaitingPermission}
        messageApproved={messageApproved}
        messageAwaitingApproval={messageAwaitingApproval}
        messageRevoked={messageRevoked}
        permission={OwnCapability.SCREENSHARE}
      >
        <CompositeButton active={!isMute}>{children}</CompositeButton>
      </PermissionNotification>
    </Restricted>
  )
}

export const ScreenShareControl = () => {
  const { hasPermission } = useRequestPermission(OwnCapability.SCREENSHARE)
  const { useHasOngoingScreenShare, useScreenShareState } = useCallStateHooks()
  const isSomeoneScreenSharing = useHasOngoingScreenShare()
  const { screenShare, isMute } = useScreenShareState()
  const { addToast } = useToasts()
  const isNativeApp = useIsNativeApp()
  const logFailure = useVideocallEventLogger()

  const disableScreenShareButton = useMemo(
    () => (hasPermission ? (isMute ? isSomeoneScreenSharing : false) : false),
    [hasPermission, isMute, isSomeoneScreenSharing],
  )

  const handleClick = useCallback(async () => {
    if (hasPermission) {
      try {
        await screenShare.toggle()
      } catch (error) {
        logFailure('videocall.screen-share-control.toggle', error)

        addToast({
          translationId: `videocall.device.error.${isNativeApp ? 'app' : 'browser'}`,
          type: 'alert',
        })
      }
    }
  }, [addToast, hasPermission, isNativeApp, screenShare, logFailure])

  return (
    <ScreenShareButton>
      <Tooltip kind="title" placement="top">
        <TooltipTrigger disabled={disableScreenShareButton} onClick={handleClick}>
          <Icon Svg={isMute ? Computer : PcCheck} colorName="white" size={24} />
        </TooltipTrigger>
        <TooltipContent style={{ marginTop: '-4px' }}>
          <Text fontWeight="400" kind="caption" textAlign="center">
            {disableScreenShareButton ? (
              <Translation id="videocall.share.disabled" />
            ) : (
              <Translation id={isMute ? 'videocall.share' : 'videocall.youAreSharing'} />
            )}
          </Text>
        </TooltipContent>
      </Tooltip>
    </ScreenShareButton>
  )
}
