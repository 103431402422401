import { gql, useMutation } from '@apollo/client'
import {
  type SendInformedConsentDocumentMutation,
  type SendInformedConsentDocumentMutationVariables,
} from '~/types/graphql'

const MUTATION = gql`
  mutation sendInformedConsentDocument($input: SendInformedConsentDocumentInput!) {
    sendInformedConsentDocument(input: $input) {
      id
    }
  }
`

export const useSendInformedConsentDocument = () =>
  useMutation<SendInformedConsentDocumentMutation, SendInformedConsentDocumentMutationVariables>(MUTATION)
