import { Flex, type FlexProps, Pressable } from 'cdk'
import { SPACING_0, SPACING_3XS, SPACING_LG, SPACING_MD, SPACING_SM, SPACING_XS } from 'design-tokens'
import { isNeitherNullNorUndefined } from 'functions'
import { Check, Icon } from 'icons'
import { isNumber } from 'numbers'
import { type ReactNode } from 'react'
import { Avatar, Card, Chip, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { ChangeTherapistGhostButton } from '~/domains/changeTherapist/components/ChangeTherapistGhostButton'
import { RangeIndicator } from '~/domains/therapist/TherapistOverviewCard/RangeIndicator'
import { TherapistProvider, useTherapist } from '~/hooks/useTherapist'
import { useTherapistFetch } from '~/hooks/useTherapistFetch'
import { useTherapistProfessionTypeValue } from '~/hooks/useTherapistProfessionTypeValue'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { getTherapistProfessionTranslationKey } from '~/utils/getTherapistProfessionTranslationKey'
import { shouldShowYearsOfExperience } from '~/utils/shouldShowYearsOfExperience'
import { TherapistChipSexology } from '../components/TherapistChipSexology'
import { useTherapistYearOfExperience } from '../hooks/useTherapistYearOfExperience'
import { TherapistOverviewCardDetail } from './Detail'
import { OpenResumeCTA } from './OpenResumeCTA'

type HeaderProps = {
  fullName: string
  image: string | undefined
  sex: number
  therapyPath: TherapyTherapyPathType
} & FlexProps

const Header = ({ fullName, image, sex, therapyPath, ...flexProps }: HeaderProps) => {
  const { isNutritionist, isPsychiatrist } = useTherapistProfessionTypeValue()

  return (
    <Flex {...flexProps} $align="center" $gap={SPACING_XS}>
      <TherapistOverviewCardDetail therapyPath={therapyPath}>
        <Pressable>
          <Avatar image={image} name={fullName} size="2xl" />
        </Pressable>
      </TherapistOverviewCardDetail>
      <Flex $align="center" $gap={SPACING_3XS}>
        <Text data-test-id="therapist-full-name" kind="h2">
          {fullName}
        </Text>
        <Text>
          <Translation
            id={getTherapistProfessionTranslationKey(therapyPath, sex, {
              isNutritionist,
              isPsychiatrist,
            })}
          />
        </Text>
      </Flex>
    </Flex>
  )
}

type BadgeProps = {
  registrationRegion: string
  registrationYear: number | null
} & FlexProps

const Badges = ({ registrationRegion, registrationYear, ...flexProps }: BadgeProps) => {
  const yearsOfExperience = useTherapistYearOfExperience({ registrationYear })
  const showYearsOfExperience = shouldShowYearsOfExperience(yearsOfExperience)

  const { isNutritionist, isSexologist } = useTherapistProfessionTypeValue()

  if (!showYearsOfExperience && isNutritionist) {
    return null
  }

  return (
    <Flex
      {...flexProps}
      $align="center"
      $direction="row"
      $gap={SPACING_3XS}
      $justify="center"
      $px={SPACING_MD}
      $wrap="wrap"
    >
      {showYearsOfExperience && (
        <Chip kind="info">
          <TranslationMarkdown
            colorName="blue-80"
            fontWeight="500"
            id="therapist.overviewCard.card.yearOfExperience"
            kind="caption"
            values={{ years: yearsOfExperience }}
          />
        </Chip>
      )}
      {!isNutritionist && <Chip kind="neutral">{registrationRegion}</Chip>}
      {isSexologist && <TherapistChipSexology />}
    </Flex>
  )
}

const Formality = ({ score, ...flexProps }: { score: number | undefined } & FlexProps) => (
  <Flex {...flexProps}>
    <RangeIndicator
      leftIndicator="therapist.overviewCard.style.formality.negative"
      rangeValue={score}
      rightIndicator="therapist.overviewCard.style.formality.positive"
    />
  </Flex>
)

type InformationsProps = {
  age?: number | null
  orientation?: string
  patients: number
  sex: number
  workplaces: { workplace: { id: string; name: string } }[]
} & FlexProps

const Informations = ({ age, orientation, patients, sex, workplaces, ...flexProps }: InformationsProps) => {
  const showPatientsAmount = patients >= 10
  const showWorkplaces = workplaces.length > 0

  const missingInfo = [showPatientsAmount, !showWorkplaces].filter(Boolean).length

  const { isNutritionist } = useTherapistProfessionTypeValue()

  return (
    <Flex {...flexProps} $align="flex-start" $gap={SPACING_SM} $minWidth="100%">
      {showPatientsAmount && (
        <Flex $direction="row" $gap={SPACING_XS}>
          <Flex>
            <Icon Svg={Check} size={16} />
          </Flex>
          <Flex $shrink={1}>
            <TranslationMarkdown
              colorName="darker"
              id={
                sex === 1
                  ? 'therapist.overviewCard.card.followedPeople.male'
                  : 'therapist.overviewCard.card.followedPeople.female'
              }
              values={{ count: patients }}
            />
          </Flex>
        </Flex>
      )}

      <Flex $direction="row" $gap={SPACING_XS}>
        <Flex $pt={SPACING_3XS}>
          <Icon Svg={Check} size={16} />
        </Flex>
        <Flex $shrink={1}>
          {isNutritionist && (
            <TranslationMarkdown
              colorName="darker"
              id={
                sex === 1
                  ? 'therapist.overviewCard.nutrition.title.male'
                  : 'therapist.overviewCard.nutrition.title.female'
              }
            />
          )}

          {!isNutritionist && (
            <TranslationMarkdown
              colorName="darker"
              id={sex === 1 ? 'therapist.overviewCard.online.title.male' : 'therapist.overviewCard.online.title.female'}
            />
          )}
        </Flex>
      </Flex>

      {showWorkplaces && (
        <Flex $direction="row" $gap={SPACING_XS}>
          <Flex $pt={SPACING_3XS}>
            <Icon Svg={Check} size={16} />
          </Flex>
          <Flex $shrink={1}>
            <TranslationMarkdown
              colorName="darker"
              id="therapist.overviewCard.card.workPlaces"
              values={{ workplaces: workplaces.map(({ workplace }) => workplace.name).join(', ') }}
            />
          </Flex>
        </Flex>
      )}

      {missingInfo === 1 && isNeitherNullNorUndefined(orientation) && (
        <Flex $direction="row" $gap={SPACING_XS}>
          <Flex $pt={SPACING_3XS}>
            <Icon Svg={Check} size={16} />
          </Flex>
          <Flex $shrink={1}>
            <TranslationMarkdown
              colorName="darker"
              id="therapist.overviewCard.detail.orientation"
              values={{ orientation }}
            />
          </Flex>
        </Flex>
      )}

      {missingInfo === 2 && isNumber(age) && (
        <Flex $direction="row" $gap={SPACING_XS}>
          <Flex $pt={SPACING_3XS}>
            <Icon Svg={Check} size={16} />
          </Flex>
          <Flex $shrink={1}>
            <TranslationMarkdown colorName="darker" id="therapist.overviewCard.detail.birthYear" values={{ age }} />
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

type OpenResuneProps = {
  detailFooter?: React.ReactNode
  therapyPath: TherapyTherapyPathType
}

const OpenResume = ({ detailFooter, therapyPath }: OpenResuneProps) => {
  const trackClick = useTrackEventClick()

  return (
    <TherapistOverviewCardDetail footer={detailFooter} therapyPath={therapyPath}>
      <OpenResumeCTA
        onClick={() => {
          trackClick('professional.view-curriculum')
        }}
      />
    </TherapistOverviewCardDetail>
  )
}

type Props = {
  detailFooter?: React.ReactNode
  calendarCTA: React.ReactNode
  openResumeCTA?: React.ReactNode
  therapyPath: TherapyTherapyPathType
}

export const TherapistOverviewCard = ({ detailFooter, calendarCTA, openResumeCTA, therapyPath }: Props) => {
  const { fullName, therapist } = useTherapist()

  const { age, numberOfPatients, profileImage, sex, therapistWorkplaces, therapySchool } = therapist
  const { registerRegistrationRegion, registerRegistrationYearPsychologist } = therapist
  const score = therapist.therapistProfessionalStyles?.find((style) => style.type === 'FORMALITY')?.score

  const { isNutritionist } = useTherapistProfessionTypeValue()

  return (
    <Card $grow={1} $maxHeight="fit-content">
      <Header $pt={SPACING_0} fullName={fullName} image={profileImage?.m} sex={sex} therapyPath={therapyPath} />
      <Badges
        $pt={SPACING_SM}
        registrationRegion={registerRegistrationRegion}
        registrationYear={registerRegistrationYearPsychologist}
      />
      <Formality $pt={SPACING_MD} score={score} />
      <Informations
        $pt={SPACING_MD}
        age={age}
        orientation={!isNutritionist ? therapySchool?.name : undefined}
        patients={numberOfPatients}
        sex={sex}
        workplaces={therapistWorkplaces ?? []}
      />
      <Flex $gap={SPACING_MD} $pt={SPACING_LG}>
        {calendarCTA}
        {openResumeCTA ?? <OpenResume detailFooter={detailFooter} therapyPath={therapyPath} />}
      </Flex>
    </Card>
  )
}

/**
 * NOTE(@heavybeard): This should be the only one exported in order to remove <TherapistProvider> dependecy
 * @see https://linear.app/serenis/issue/ENG-1194/therapistoverviewcard-shouldnt-depend-from-therapistprovider
 */

type TherapistCardProps = {
  calendarCTA?: ReactNode
  showChangeTherapistCTA?: boolean
  therapistId: string
  therapyId: string
  therapyPath: TherapyTherapyPathType
}

export const TherapistCard = ({
  calendarCTA,
  showChangeTherapistCTA = false,
  therapistId,
  therapyId,
  therapyPath,
}: TherapistCardProps) => {
  const { data, loading } = useTherapistFetch(therapistId)

  if (data?.findTherapistProfileById?.therapist == null || loading) {
    return null
  }

  return (
    <TherapistProvider
      therapist={{
        ...data.findTherapistProfileById,
        therapist: data.findTherapistProfileById.therapist,
      }}
    >
      <TherapistOverviewCard
        calendarCTA={calendarCTA}
        detailFooter={showChangeTherapistCTA && <ChangeTherapistGhostButton therapyId={therapyId} />}
        therapyPath={therapyPath}
      />
    </TherapistProvider>
  )
}
