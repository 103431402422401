import {
  SerenisAgenda,
  SerenisAgendaSolid,
  SerenisCalendar,
  SerenisCalendarSolid,
  SerenisChat,
  SerenisChatSolid,
  SerenisPatients,
  SerenisPatientsSolid,
} from 'icons'
import { type NavigationLink } from '../types'

export const therapistNavigationLinks: NavigationLink[] = [
  {
    id: 'agenda',
    route: '/',
    translation: 'menu.agenda.yourBookings',
    Icon: SerenisAgenda,
    IconActive: SerenisAgendaSolid,
    pathMatches: [
      '/',
      '/therapy-session/:therapyId/schedule',
      '/therapy-session/:therapySessionId/edit',
      '/therapy-session/booking/:therapy',
    ],
    exact: true,
  },
  {
    id: 'calendar',
    route: '/availabilities/calendar',
    translation: 'menu.availabilities.calendar',
    Icon: SerenisCalendar,
    IconActive: SerenisCalendarSolid,
    pathMatches: ['/availabilities/calendar', '/availabilities/weekly'],
    exact: false,
  },
  {
    id: 'chat',
    dataTestId: 'tab-item-chat',
    route: '/chat',
    translation: 'menu.chat',
    Icon: SerenisChat,
    IconActive: SerenisChatSolid,
    pathMatches: ['/chat'],
    exact: false,
  },
  {
    id: 'patients',
    dataTestId: 'tab-item-patients',
    route: '/patients',
    translation: 'menu.patients',
    Icon: SerenisPatients,
    IconActive: SerenisPatientsSolid,
    pathMatches: ['/patients'],
    exact: false,
  },
]
