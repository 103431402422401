import {
  CompositeButton,
  DeviceSelectorVideo,
  OwnCapability,
  PermissionNotification,
  Restricted,
  useCallStateHooks,
  useRequestPermission,
} from '@stream-io/video-react-sdk'
import { isNeitherNullNorUndefined } from 'functions'
import { Icon, VideoCamera, VideoCameraOff, WarningCircleSolid } from 'icons'
import { type PropsWithChildren, useCallback, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Badge, Text } from 'ui'
import { Tooltip, TooltipContent, TooltipTrigger } from '~/components/FloatingUI/Tooltip'
import { Translation } from '~/components/Translation'
import { useIsNativeApp } from '~/domains/appNative/hooks/useIsNativeApp'
import { useVideocallEventLogger } from '~/domains/videocall/hooks/useVideocallEventLogger'
import { useToasts } from '~/hooks/useToasts'
import { useTranslation } from '~/i18n/hooks/useTranslation'
import { type TranslationId } from '~/i18n/types'

/**
 * Recreated Toggle Video Button as suggested by Stream dev Oliver Lazoroski
 * @see https://github.com/GetStream/stream-video-js/blob/d61525733e87edec520e610d3e94c957eab207a0/packages/react-sdk/src/components/CallControls/ToggleVideoButton.tsx
 */
const ToggleVideoPublishingButton = ({ children }: PropsWithChildren) => {
  const { isAwaitingPermission } = useRequestPermission(OwnCapability.SEND_VIDEO)
  const { useCameraState } = useCallStateHooks()
  const { camera, devices, hasBrowserPermission, isMute, status } = useCameraState()
  const { addToast } = useToasts()
  const isNativeApp = useIsNativeApp()
  const logFailure = useVideocallEventLogger()

  const [isLoading, setIsLoading] = useState(false)

  const messageApproved = useTranslation('videocall.video.approved')
  const messageAwaitingApproval = useTranslation('videocall.video.awaitingApproval')
  const messageRevoked = useTranslation('videocall.video.revoked')
  const errorToast: Extract<TranslationId, `videocall.device.error.${string}`> =
    `videocall.device.error.${isNativeApp ? 'app' : 'browser'}`

  const handleClick = useCallback(async () => {
    if (hasBrowserPermission && isNeitherNullNorUndefined(status)) {
      try {
        setIsLoading(true)

        await camera.toggle()

        setIsLoading(false)
      } catch (error) {
        logFailure('videocall.camera-control.toggle', error)

        addToast({
          translationId: errorToast,
          type: 'alert',
        })
      } finally {
        setIsLoading(false)
      }
    }
  }, [addToast, camera, errorToast, hasBrowserPermission, status, logFailure])

  useHotkeys('ctrl+e', handleClick)

  return (
    <Restricted requiredGrants={[OwnCapability.SEND_VIDEO]}>
      <PermissionNotification
        isAwaitingApproval={isAwaitingPermission}
        messageApproved={messageApproved}
        messageAwaitingApproval={messageAwaitingApproval}
        messageRevoked={messageRevoked}
        permission={OwnCapability.SEND_VIDEO}
      >
        <CompositeButton
          Menu={
            hasBrowserPermission && isNeitherNullNorUndefined(status) && devices && devices?.length > 1
              ? DeviceSelectorVideo
              : undefined
          }
          active={isMute}
          className={hasBrowserPermission && isNeitherNullNorUndefined(status) ? '' : 'srns-permissions-denied-control'}
          disabled={isLoading || !hasBrowserPermission || !isNeitherNullNorUndefined(status)}
          onClick={handleClick}
        >
          {children}
        </CompositeButton>
      </PermissionNotification>
    </Restricted>
  )
}

export const CameraControl = () => {
  const { useCameraState } = useCallStateHooks()
  const { hasBrowserPermission, isMute, status } = useCameraState()

  if (!hasBrowserPermission || !isNeitherNullNorUndefined(status)) {
    return (
      <Tooltip kind="title" placement="top">
        <TooltipTrigger disabled>
          <Badge sup={<Icon Svg={WarningCircleSolid} colorName="yellow-60" size={24} />}>
            <ToggleVideoPublishingButton>
              <Icon Svg={VideoCameraOff} colorName="white" size={24} />
            </ToggleVideoPublishingButton>
          </Badge>
        </TooltipTrigger>
        <TooltipContent style={{ marginTop: '-4px' }}>
          <Text fontWeight="400" kind="caption">
            <Translation id="videocall.video.browserPermissions" />
          </Text>
        </TooltipContent>
      </Tooltip>
    )
  }

  return (
    <ToggleVideoPublishingButton>
      <Tooltip kind="title" placement="top">
        <TooltipTrigger>
          <Icon Svg={isMute ? VideoCameraOff : VideoCamera} colorName="white" size={24} />
        </TooltipTrigger>
        <TooltipContent style={{ marginTop: '-4px' }}>
          <Text fontWeight="400" kind="caption">
            <Translation id={isMute ? 'videocall.camera.on' : 'videocall.camera.off'} />
          </Text>
        </TooltipContent>
      </Tooltip>
    </ToggleVideoPublishingButton>
  )
}
