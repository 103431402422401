import { type TextProps } from 'ui'
import { type TherapyStatus } from '~/domains/patientsManagement/utils/therapyToPatientTherapyStatus'
import { type TranslationId } from '~/i18n/types'

type Return = {
  key: Extract<TranslationId, `patientsManagement.status.${string}`>
  colorName: TextProps['colorName']
}

export const getStatusLabel = (status: TherapyStatus): Return | null => {
  switch (status) {
    case 'INACTIVE':
      return { key: 'patientsManagement.status.inactive', colorName: 'grey-80' }
    case 'MANAGED':
      return { key: 'patientsManagement.status.managed', colorName: 'green-60' }
    case 'TO_BE_MANAGED':
      return { key: 'patientsManagement.status.toBeManaged', colorName: 'yellow-80' }
    default:
      return null
  }
}
