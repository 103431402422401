import { Flex } from 'cdk'
import { useCallback, useState } from 'react'
import { Button, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { ClinicalBondModal } from '~/domains/clinicalBond/components/ClinicalBondModal'
import { useClinicalBondReviewCreate } from '~/domains/clinicalBond/hooks/useClinicalBondReviewCreate'
import { useModals } from '~/domains/modals'
import { useTherapySessionIndex } from '~/hooks/useTherapySessionIndex'
import { useToasts } from '~/hooks/useToasts'

type Props = {
  therapistFullName: string
  therapistId: string
  therapySessionId: string
  onCompleted: () => void
}

export const MessageClinicalBondToComplete = ({
  therapistFullName,
  therapistId,
  therapySessionId,
  onCompleted,
}: Props) => {
  const { open } = useModals()

  const { productUserFeedbackCreate } = useClinicalBondReviewCreate({ therapistId, therapySessionId })

  const { index } = useTherapySessionIndex(therapySessionId)

  const [clinicalBondId, setClinicalBondId] = useState<string | null>(null)

  const [loading, setLoading] = useState(false)

  const { addToast } = useToasts()

  const onClick = useCallback(() => {
    setLoading(true)
    productUserFeedbackCreate()
      .then((response) => {
        if (!response.data?.productUserFeedbackCreate?.id) {
          addToast({
            translationId: 'generic.error',
            type: 'alert',
          })
          return
        }

        setClinicalBondId(response.data.productUserFeedbackCreate.id)

        open('clinicalBond')
      })
      .catch(() => {
        addToast({
          translationId: 'generic.error',
          type: 'alert',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [addToast, open, productUserFeedbackCreate])

  return (
    <>
      <Flex $pb={8}>
        <Text fontWeight="600" kind="caption">
          <Translation id="systemMessage.clinicalBond.title" />
        </Text>
      </Flex>
      <TranslationMarkdown
        fontWeight="400"
        id="systemMessage.clinicalBond.body"
        kind="caption"
        values={{ therapistFullName }}
      />
      <Flex $align="center" $direction="row" $pt={16} $wrap="wrap">
        <Button isLoading={loading} kind="secondary" onClick={onClick} size="sm">
          <Translation id="systemMessage.clinicalBond.cta" />
        </Button>
      </Flex>

      {clinicalBondId && index && (
        <ClinicalBondModal
          clinicalBondId={clinicalBondId}
          onCompleted={onCompleted}
          therapistId={therapistId}
          therapySessionIndex={index}
        />
      )}
    </>
  )
}
