import { type ReactNode } from 'react'
import { type FieldValues, type SubmitHandler } from 'react-hook-form'
import { Form } from 'ui-deprecated'
import { useReactHookFormContext } from '~/domains/react-hook-form'

type Props<T extends FieldValues> = {
  children?: ReactNode
  onSubmit: SubmitHandler<T>
}

export const ClinicalBondForm = <T extends FieldValues>({ children, onSubmit }: Props<T>) => {
  const form = useReactHookFormContext<T>()

  return (
    <Form grow={1} id="clinicalBond" onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
      {children}
    </Form>
  )
}
