import { ChatLines, Icon } from 'icons'
import { useCallback } from 'react'
import { Badge } from 'ui'
import { TertiaryButtonIconOnly } from '~/components/TertiaryButton/TertiaryButtonIconOnly'
import { useChatChannel } from '~/domains/chat/hooks/useChatChannel'
import { useVideocallSidePanel } from '../../../hooks/useVideocallSidePanel'

export const OpenLiveChat = () => {
  const { channel } = useChatChannel()
  const { setIsChatOpen, isChatOpen } = useVideocallSidePanel()

  const handleOnClick = useCallback(() => {
    setIsChatOpen(!isChatOpen)

    channel.markRead()
  }, [channel, isChatOpen, setIsChatOpen])

  return (
    <Badge sup={isChatOpen ? undefined : channel.state.unreadCount}>
      <TertiaryButtonIconOnly
        background={isChatOpen ? 'primary' : undefined}
        iconColorName="white"
        onClick={handleOnClick}
      >
        <Icon Svg={ChatLines} size={24} />
      </TertiaryButtonIconOnly>
    </Badge>
  )
}
