import { Flex, Space } from 'cdk'
import { secondsToHours } from 'date-fns/fp'
import { format, FormatDateEnum, nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useTherapistAvailabilitiesGivenCount } from '~/domains/availabilities/reminders/hooks/useTherapistAvailabilitiesGivenCount'

export const TherapistCalendarMonthlyHours = () => {
  const { loading, data } = useTherapistAvailabilitiesGivenCount()

  if (
    loading ||
    !data?.findTherapistProfileById?.therapist?.availableHoursPerWeek ||
    !data?.findTherapistProfileById?.monthlyAvailabilitiesGivenCount?.current
  ) {
    return null
  }

  const availableHoursPerMonth = data.findTherapistProfileById.therapist.availableHoursPerWeek * 4
  const total = pipe(data.findTherapistProfileById.monthlyAvailabilitiesGivenCount.current.available, secondsToHours)

  const monthName = pipe(nowInMilliseconds(), format(FormatDateEnum.MONTH_NAME))

  return (
    <Flex $align="center" $direction="row" $justify="flex-end" $wrap="wrap">
      <TranslationMarkdown
        colorName="black"
        colorNameBold="black"
        id="availabilities.calendar.myMonthlyHours.label"
        kind="paragraph"
        values={{ monthName }}
      />
      <Space />
      <Text colorName={total < availableHoursPerMonth ? 'red-80' : 'green-80'} fontWeight="600" kind="paragraph">
        {String(total).padStart(2, '0')}
      </Text>
      <Space />
      <Translation id="generic.hoursOn" />
      <Space />
      <Text colorName="primary" fontWeight="600" kind="paragraph">
        {availableHoursPerMonth}
      </Text>
    </Flex>
  )
}
