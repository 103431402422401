import { ApolloError } from '@apollo/client'
import { secondsInMinute } from 'date-fns/constants'
import { createContext, type PropsWithChildren, useCallback, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getTherapyDurationBySessionsBooked } from '~/domains/paths/components/conventions/utils/getTherapyDurationBySessionsBooked'
import { usePatientTherapiesQuery } from '~/domains/patient/hooks/usePatientTherapiesQuery'
import { useTherapySessionFindManyRecoverableByTherapyId } from '~/domains/therapy-session/hooks/useTherapySessionFindManyRecoverableByTherapyId'
import { useBookTherapySessionByParent } from '~/domains/therapy-session/schedule/hooks/useBookTherapySessionByParent'
import { usePatientAgenda } from '~/hooks/usePatientAgenda'
import { TherapistFetchProvider } from '~/hooks/useTherapistFetch'
import { useToasts } from '~/hooks/useToasts'
import { type BookTherapySessionByParentMutation } from '~/types/graphql'
import { type BookSessionRoute } from '../utils/getBookSessionRoutes'

type BookNewSessionParams = {
  nextRoute: BookSessionRoute
  selectedSlot: Date | null
}

type Context = {
  bookedSession: BookTherapySessionByParentMutation | undefined | null
  bookNewSession: (params: BookNewSessionParams) => Promise<void>
  isRecover: boolean
  loading: boolean
  minutes: number | null
  therapy: ReturnType<typeof usePatientTherapiesQuery>['therapies'][0]
  therapyPath: ReturnType<typeof usePatientTherapiesQuery>['therapies'][0]['therapyPath']
}

type Props = PropsWithChildren<{
  therapyId: string
}>

const BookSessionContext = createContext<Context | null>(null)

export const BookSessionProvider = ({ children, therapyId }: Props) => {
  const [loading, setLoading] = useState(false)
  const [bookedSession, setBookedSession] = useState<BookTherapySessionByParentMutation | undefined | null>(null)
  const [bookTherapySessionByParent] = useBookTherapySessionByParent()

  const { addToast } = useToasts()
  const { recoverableTherapySession } = useTherapySessionFindManyRecoverableByTherapyId({ therapyId })
  const { refetch: refetchPatientAgenda } = usePatientAgenda()
  const { therapies } = usePatientTherapiesQuery()
  const history = useHistory()

  const therapy = therapies.find(({ id }) => id === therapyId)!
  const therapyPath = therapy.therapyPath

  const sessionDuration = getTherapyDurationBySessionsBooked({
    therapyPath: therapyPath.type,
    sessionsCount: therapy?.therapySessionsBookedCount,
  })
  const minutes = sessionDuration != null ? sessionDuration / secondsInMinute : null

  const isRecover = recoverableTherapySession?.id != null

  const bookNewSession: Context['bookNewSession'] = useCallback(
    async ({ nextRoute, selectedSlot }) => {
      if (selectedSlot == null) {
        addToast({ translationId: 'therapySession.alert.pickOneSlot', type: 'alert' })
        return
      }

      setLoading(true)

      try {
        if (!therapy?.id) {
          return
        }

        const { data } = await bookTherapySessionByParent({
          variables: {
            input: {
              recoverTherapySession: isRecover,
              recoverableTherapySessionId: recoverableTherapySession?.id,
              startAt: selectedSlot,
              therapyId: therapy?.id,
            },
          },
        })

        refetchPatientAgenda()
        setBookedSession(data)

        addToast({ translationId: 'therapySession.booking.therapySessionBooked', type: 'success' })

        history.push(nextRoute)
      } catch (error) {
        const showApolloError = error instanceof ApolloError && error.message === 'therapysession.first.alreadybooked'

        addToast({
          translationId: showApolloError
            ? 'therapySession.booking.cannotBookTherapySession.firstAlreadyExists'
            : 'therapySession.booking.cannotBookTherapySession',
          type: 'alert',
        })
      } finally {
        setLoading(false)
      }
    },
    [
      addToast,
      bookTherapySessionByParent,
      history,
      isRecover,
      recoverableTherapySession?.id,
      refetchPatientAgenda,
      therapy?.id,
    ],
  )

  if (!therapy?.therapist) {
    return null
  }

  const value = {
    bookedSession,
    bookNewSession,
    isRecover,
    loading,
    minutes,
    therapy,
    therapyPath,
  }

  return (
    <TherapistFetchProvider id={therapy?.therapist.id}>
      <BookSessionContext.Provider value={value}>{children}</BookSessionContext.Provider>
    </TherapistFetchProvider>
  )
}

export const useBookSession = () => {
  const context = useContext(BookSessionContext)

  if (!context) {
    throw new Error('The `useBookSession` should be wrapped with `BookSessionProvider`.')
  }

  return context
}
