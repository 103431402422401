import { Flex } from 'cdk'
import { SPACING_XS } from 'design-tokens'
import { useFormContext } from 'react-hook-form'
import { Checkbox, Text } from 'ui'
import { useFormTranslations } from '~/hooks/useFormTranslations'

type FormValues = {
  marketingEmails?: boolean
}

export const FieldMarketingEmails = () => {
  const { register } = useFormContext<FormValues>()
  const { labels } = useFormTranslations()

  return (
    <Flex $align="center" $direction="row" $gap={SPACING_XS}>
      <Checkbox id="marketingEmails" {...register('marketingEmails')} />
      <Text as="label" fontWeight="400" htmlFor="marketingEmails" kind="footnote">
        {labels.marketingEmails}
      </Text>
    </Flex>
  )
}
