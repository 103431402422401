import { type PropsWithChildren, useMemo } from 'react'
import { useCurrentUserNullable } from '~/hooks/useCurrentUser'
import { useNetworkMonitor } from '../hooks/useNetworkMonitor'
import { useTherapySessionLive } from '../hooks/useTherapySessionLive'
import { VideocallProvider } from './VideocallProvider'

const NetworkMonitor = () => {
  useNetworkMonitor()

  return null
}

export const TherapySessionVideocallProvider = ({ children }: PropsWithChildren) => {
  const { id: currentUserId } = useCurrentUserNullable()
  const { id: therapySessionId, patient, partner, startAt, endAt, streamToken, therapist } = useTherapySessionLive()

  const currentUser = useMemo(
    () => [patient, therapist].find((user) => user.id === currentUserId) ?? partner,
    [currentUserId, patient, partner, therapist],
  )!

  const members = useMemo(
    () => [
      {
        user_id: patient.id,
        role: 'call_member',
      },
      {
        user_id: therapist.id,
        role: 'host',
        custom: {
          image: therapist.profileImage?.s,
        },
      },
    ],
    [patient, therapist],
  )

  return (
    <>
      <NetworkMonitor />

      <VideocallProvider
        callId={therapySessionId}
        currentUser={currentUser}
        endAt={endAt}
        logLevel="debug"
        members={members}
        startAt={startAt}
        streamToken={streamToken}
        therapySessionId={therapySessionId}
      >
        {children}
      </VideocallProvider>
    </>
  )
}
