import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { type TranslationId } from '~/i18n/types'
import { DiagnosisMandatoryFieldBadge } from './DiagnosisMandatoryFieldBadge'

type Props = {
  keyTranslationId: Extract<
    TranslationId,
    | 'patientDetail.diagnosis.couples.state.facing'
    | 'patientDetail.diagnosis.couples.state.otherInfo'
    | 'patientDetail.diagnosis.info.age'
    | 'patientDetail.diagnosis.info.country'
    | 'patientDetail.diagnosis.info.degree'
    | 'patientDetail.diagnosis.info.genderIdentity'
    | 'patientDetail.diagnosis.info.healthIssue'
    | 'patientDetail.diagnosis.info.historicalDiagnosis'
    | 'patientDetail.diagnosis.info.language'
    | 'patientDetail.diagnosis.info.pharmacologicalTreatment'
    | 'patientDetail.diagnosis.info.profession'
    | 'patientDetail.diagnosis.info.psychiatricVulnerability'
    | 'patientDetail.diagnosis.info.socialAndFamilyNetwork'
    | 'patientDetail.diagnosis.info.socioCulturalContext'
    | 'patientDetail.diagnosis.info.workProfession'
    | 'patientDetail.diagnosis.state.facing'
    | 'patientDetail.diagnosis.state.otherInfo'
  >
  isEditable?: boolean
  mandatory?: boolean
  value?: number | string | null
}

export const DiagnosisKeyValue = ({ keyTranslationId, isEditable = false, mandatory = false, value }: Props) => (
  <>
    <Flex align="center" direction="row">
      <Flex pr={16}>
        <Text colorName="grey-80" fontWeight="400" kind="caption">
          <Translation id={keyTranslationId} />
        </Text>
      </Flex>
    </Flex>

    <Flex pt={8}>
      <Text fontWeight="400" kind="paragraph">
        {value ? (
          value
        ) : (
          <>
            {mandatory && isEditable ? (
              <DiagnosisMandatoryFieldBadge />
            ) : (
              <Translation id="patientDetail.diagnosis.nd" />
            )}
          </>
        )}
      </Text>
    </Flex>
  </>
)
