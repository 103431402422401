import { Icon, TimerOff } from 'icons'
import { Button } from 'ui'
import { Translation } from '~/components/Translation'

type Props = {
  onClick: () => void
}

export const DeleteRecurrencyButton = ({ onClick }: Props) => (
  <Button isGhost kind="secondary" onClick={onClick} size="sm">
    <Icon Svg={TimerOff} size={20} /> <Translation id="actions.deleteRecurrency" />
  </Button>
)
