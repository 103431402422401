import { Hub, type HubCapsule } from '@aws-amplify/core'
import { useRouter } from 'next/navigation'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CenteredLoader } from '~/components/CenteredLoader'
import { useToasts } from '~/hooks/useToasts'
import { getRoute } from '~/utils/getRoute'
import { getPath } from '~/utils/nextjsRouting'
import { parseCustomStateFailureError } from '../utils/parseCustomStateFailureError'
import { LoginCallbackError } from './LoginCallbackError'

export const LoginCallback = () => {
  const router = useRouter()
  const [error, setError] = useState(false)
  const history = useHistory()
  const { addToast } = useToasts()

  const hubCallback = useCallback(
    ({ payload: { event, data } }: HubCapsule) => {
      switch (event) {
        case 'customOAuthState': {
          const route = data || getRoute('/')

          history.replace(route)

          return
        }
        case 'customState_failure': {
          const error = parseCustomStateFailureError(data.message)

          if (error === 'external-provider-disabled') {
            addToast({
              id: 'externalProviderDisabled',
              translationId: 'auth.error.externalProviderDisabled',
              type: 'info',
            })

            router.replace(getPath('/login'))

            return
          }

          setError(true)

          return
        }
      }
    },
    [addToast, history, router],
  )

  useEffect(() => {
    const error = parseCustomStateFailureError(history.location.search)

    if (error === 'external-provider-disabled') {
      addToast({
        id: 'externalProviderDisabled',
        translationId: 'auth.error.externalProviderDisabled',
        type: 'info',
      })

      router.replace(getPath('/login'))

      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  Hub.listen('auth', hubCallback)

  useEffect(() => () => Hub.remove('auth', hubCallback), [hubCallback])

  if (error) {
    return <LoginCallbackError />
  }

  return <CenteredLoader />
}
