import { useBreakpoints } from 'hooks'
import { useMemo } from 'react'
import { type UserTherapist } from '~/domains/therapist/types'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { useAvailableTimeSlotsFirstBooking } from './useAvailableTimeSlotsFirstBooking'
import { useAvailableTimeSlotsByDay } from './useTimeSlots'

const VISIBLE_DAYS: { [length: number]: { small: number; large: number; extraLarge: number } } = {
  3: { small: 1, large: 2, extraLarge: 2 },
  2: { small: 1, large: 3, extraLarge: 3 },
  1: { small: 3, large: 5, extraLarge: 7 },
}

type CarouselSlotsProps = {
  compareTherapists: boolean
  maxVisibleColumns?: number
  selectedTherapist: UserTherapist | undefined
  suggestedTherapistsProfiles: UserTherapist[]
  therapyPathType: TherapyTherapyPathType
}

export const useCarouselSlotsForFirstBooking = ({
  compareTherapists,
  maxVisibleColumns,
  selectedTherapist,
  suggestedTherapistsProfiles,
  therapyPathType,
}: CarouselSlotsProps) => {
  const { isSmallOnly, isMediumOnly, isLargeOnly } = useBreakpoints()
  const { items } = useAvailableTimeSlotsFirstBooking({
    userIds: suggestedTherapistsProfiles.map(({ id }) => id),
    therapyPathType,
  })
  const { slotsByDay } = useAvailableTimeSlotsByDay({ items, therapyPathType })

  const data = useMemo(
    () =>
      compareTherapists
        ? slotsByDay
        : (slotsByDay
            .map(([day, therapists]) => [day, therapists.filter(([id]) => selectedTherapist?.id === id)])
            .filter(([, therapists]) => therapists.length > 0) as [string, [string, Date[]][]][]),
    [compareTherapists, selectedTherapist, slotsByDay],
  )

  const currentBreakpoint = isSmallOnly || isMediumOnly ? 'small' : isLargeOnly ? 'large' : 'extraLarge'

  let slice =
    compareTherapists && suggestedTherapistsProfiles.length <= 3
      ? VISIBLE_DAYS[suggestedTherapistsProfiles.length][currentBreakpoint]
      : VISIBLE_DAYS[1][currentBreakpoint]

  if (!compareTherapists && maxVisibleColumns != null) {
    slice = slice > maxVisibleColumns ? maxVisibleColumns : slice
  }

  return {
    length: Math.ceil(data.length / slice),
    slice,
    data,
  }
}
