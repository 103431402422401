import { addDays } from 'date-fns/fp'
import { isToday } from 'date-fns/isToday'
import { format, FormatDateEnum } from 'dates'
import { pipe } from 'fp-ts/function'
import { useMemo } from 'react'
import { type DateSeparatorProps } from 'stream-chat-react'
import styled from 'styled-components'
import { shouldForwardProp, Text } from 'ui'
import { PositionAbsolute, PositionRelative } from 'ui-deprecated'
import { useTranslation } from '~/i18n/hooks/useTranslation'

const Row = styled(PositionAbsolute).withConfig({ shouldForwardProp }).attrs({
  background: 'purple04',
  basis: '1px',
  left: '0px',
  right: '0px',
  top: '50%',
  zIndex: 1,
})`
  height: 1px;
  margin-top: -0.5px;
`

export const ChannelDateSeparator = ({ date }: DateSeparatorProps) => {
  const today = useTranslation('chat.today')
  const yesterday = useTranslation('chat.yesterday')

  const formattedDate = useMemo(() => {
    if (pipe(date, isToday)) {
      return today
    }

    if (pipe(date, addDays(1), isToday)) {
      return yesterday
    }

    return pipe(date, format(FormatDateEnum.LONG_DAY_DATE_MONTH_NAME))
  }, [date, today, yesterday])

  return (
    <PositionRelative align="center" lgPy={40} mdPy={32} py={24}>
      <PositionRelative
        background="white"
        borderColor="purple04"
        borderRadius={24}
        borderSize={1}
        px={16}
        py={4}
        zIndex={2}
      >
        <Text fontWeight="600" kind="caption">
          {formattedDate}
        </Text>
      </PositionRelative>
      <Row />
    </PositionRelative>
  )
}
