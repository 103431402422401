import { Flex } from 'cdk'
import { SPACING_2XL, SPACING_LG, SPACING_SM } from 'design-tokens'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { usePatientFindById } from '~/hooks/usePatientFindById'
import { CalendarSlider } from './components/CalendarSlider'
import { DrinksTracker } from './components/DrinksTracker'
import { useFoodJournal } from './components/FoodJournalContext'
import { FoodJournalMeals } from './components/FoodJournalMeals'
import { TherapistFoodJournalMood } from './components/TherapistFoodJournalMood'
import { useGetFoodJournalDay } from './hooks/useGetFoodJournalDay'

const Content = () => {
  const { patientId, currentDay } = useFoodJournal()

  const { foodJournalDay, loading } = useGetFoodJournalDay({
    day: currentDay,
    patientId,
  })
  const { patient } = usePatientFindById(patientId)

  const emptyMood = foodJournalDay?.mood == null
  const emptyDrinks =
    foodJournalDay?.drinks.water === 0 && foodJournalDay?.drinks.alcohol === 0 && foodJournalDay?.drinks.beverage === 0
  const emptyMeals = foodJournalDay?.meals.length === 0
  // If user adds and deletes a meal then foodJournalDay will exists but will be empty
  const emptyFoodJournalDay = emptyMood && emptyDrinks && emptyMeals
  const isUnfilled = !loading && (foodJournalDay == null || emptyFoodJournalDay)

  if (!loading && isUnfilled) {
    return (
      <Text colorName="grey-80" kind="paragraph">
        <Translation id="nutrition.diary.empty" values={{ name: patient?.firstName ?? '' }} />
      </Text>
    )
  }

  return (
    <Flex $gap={SPACING_LG}>
      <TherapistFoodJournalMood />

      <Flex $gap={SPACING_SM} $grow={1} $shrink={1}>
        <DrinksTracker />

        <FoodJournalMeals />
      </Flex>
    </Flex>
  )
}

export const TherapistFoodJournalDay = () => (
  <>
    <Flex $pb={SPACING_2XL}>
      <CalendarSlider />
    </Flex>

    <Content />
  </>
)
