import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { useChatPatient } from '~/domains/chat/hooks/useChatPatient'
import { useChatPatientLastActive } from '~/domains/chat/hooks/useChatPatientLastActive'

export const ChannelHeaderTherapist = () => {
  const { user } = useChatPatient()
  const { status } = useChatPatientLastActive()

  return (
    <Flex align="center">
      <Text fontWeight="600" kind="paragraph" textAlign="center">
        {user?.fullName}
      </Text>
      <Flex pt={2}>
        <Text colorName="grey-80" fontWeight="400" kind="caption" textAlign="center">
          {status}
        </Text>
      </Flex>
    </Flex>
  )
}
