import { addMonths, getDay, getDaysInMonth, isSameDay, set, startOfMonth, subMonths } from 'date-fns'
import { useMemo } from 'react'
import { useFoodJournal } from '../components/FoodJournalContext'

type CalendarDay = {
  date: Date
  isCurrentMonth: boolean
  isToday: boolean
}

export const useCalendarRows = () => {
  const { currentCalendarMonth } = useFoodJournal()

  return useMemo(() => {
    const today = new Date()
    const firstDayOfMonthAsWeekDay = getDay(startOfMonth(currentCalendarMonth))
    // Week day starts at Sunday, adjust it to start at Monday
    const adjustedFirstDay = (firstDayOfMonthAsWeekDay + 6) % 7

    const daysInCurrentMonth = getDaysInMonth(currentCalendarMonth)
    const daysInPreviousMonth = getDaysInMonth(subMonths(currentCalendarMonth, 1))

    const calendarDays: CalendarDay[] = []

    // Fill in the previous month's days
    for (let i = adjustedFirstDay - 1; i >= 0; i--) {
      const dayDate = set(subMonths(currentCalendarMonth, 1), { date: daysInPreviousMonth - i })

      calendarDays.push({
        date: dayDate,
        isCurrentMonth: false,
        isToday: isSameDay(dayDate, today),
      })
    }

    // Fill in the current month's days
    for (let day = 1; day <= daysInCurrentMonth; day++) {
      const dayDate = set(currentCalendarMonth, { date: day })

      calendarDays.push({
        date: dayDate,
        isCurrentMonth: true,
        isToday: isSameDay(dayDate, today),
      })
    }

    // Fill in the next month's days
    const totalCells = Math.ceil(calendarDays.length / 7) * 7

    for (let i = calendarDays.length; i < totalCells; i++) {
      const dayDate = set(addMonths(currentCalendarMonth, 1), { date: i - daysInCurrentMonth - adjustedFirstDay + 1 })

      calendarDays.push({
        date: dayDate,
        isCurrentMonth: false,
        isToday: isSameDay(dayDate, today),
      })
    }

    // Break the days into rows of 7 days
    const rows: CalendarDay[][] = []

    for (let i = 0; i < calendarDays.length; i += 7) {
      rows.push(calendarDays.slice(i, i + 7))
    }

    return rows
  }, [currentCalendarMonth])
}
