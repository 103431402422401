import { type ColorName } from 'design-tokens'
import { Icon, Xmark } from 'icons'
import { useMemo } from 'react'
import { components, type MultiValueRemoveProps } from 'react-select'
import { type SelectOption } from '~/components/SelectNew/types'

export const MultiValueRemove = (props: MultiValueRemoveProps<SelectOption>) => {
  const colorName: ColorName = useMemo(() => {
    if (props.selectProps.isDisabled || props.selectProps.isLoading) {
      return 'grey-60'
    }

    return 'primary'
  }, [props])

  return (
    <components.MultiValueRemove {...props}>
      <Icon Svg={Xmark} colorName={colorName} size={16} />
    </components.MultiValueRemove>
  )
}
