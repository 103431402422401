import React, { type ReactNode, useCallback, useState } from 'react'
import { useModals } from '~/domains/modals'
import { TechnicalFeedbackModal } from '~/domains/technicalFeedback/components/TechnicalFeedbackModal'
import { useTechnicalFeedbackReviewCreate } from '~/domains/technicalFeedback/hooks/useTechnicalFeedbackReviewCreate'
import { useToasts } from '~/hooks/useToasts'
import { type TherapyTherapyPathType } from '~/types/graphql'

type TechnicalFeedbackTriggerProps = {
  trigger: ReactNode
  therapySessionId: string
  therapyPathType: TherapyTherapyPathType | null
  onCompleted: () => void
}

export const TechnicalFeedbackTrigger = ({
  trigger,
  therapySessionId,
  therapyPathType,
  onCompleted,
}: TechnicalFeedbackTriggerProps) => {
  const { open } = useModals()
  const { productUserFeedbackCreate } = useTechnicalFeedbackReviewCreate(therapySessionId)
  const [technicalFeedbackId, setTechnicalFeedbackId] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const { addToast } = useToasts()

  const handleClick = useCallback(async () => {
    setLoading(true)
    try {
      const response = await productUserFeedbackCreate()
      const id = response.data?.productUserFeedbackCreate?.id

      if (!id) {
        addToast({ translationId: 'generic.error', type: 'alert' })
        return
      }

      setTechnicalFeedbackId(id)
      open('technicalFeedback')
    } catch {
      addToast({ translationId: 'generic.error', type: 'alert' })
    } finally {
      setLoading(false)
    }
  }, [productUserFeedbackCreate, addToast, open])

  return (
    <>
      {React.cloneElement(trigger as React.ReactElement, {
        onClick: handleClick,
        loading,
      })}

      {technicalFeedbackId && (
        <TechnicalFeedbackModal
          onCompleted={onCompleted}
          technicalFeedbackId={technicalFeedbackId}
          therapyPathType={therapyPathType}
          therapySessionId={therapySessionId}
        />
      )}
    </>
  )
}
