import { gql, useMutation } from '@apollo/client'
import { type RescheduleTherapySessionMutation, type RescheduleTherapySessionMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation rescheduleTherapySession($input: RescheduleTherapySessionInput!) {
    rescheduleTherapySession(input: $input) {
      ... on TherapySession {
        __typename
        cost
        endAt
        endAtSlot
        id
        startAt
      }
    }
  }
`

export const useRescheduleTherapySession = () =>
  useMutation<RescheduleTherapySessionMutation, RescheduleTherapySessionMutationVariables>(MUTATION)
