import { Flex, MaxWidth640px } from 'cdk'
import { SPACING_MD, SPACING_XL } from 'design-tokens'
import { ArrowLeft, ArrowRight, Icon } from 'icons'
import { Button } from 'ui'
import { Translation } from '~/components/Translation'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { useStepNavigation } from '~/domains/navigation/step/useStepNavigation'

type Props = {
  nextDisabled: boolean
  onNext: () => void
}

export const ClinicalTestStepActions = ({ nextDisabled, onNext }: Props) => {
  const { current, goPrevious } = useStepNavigation()
  const trackClick = useTrackEventClick()

  return (
    <Flex $backgroundColorName="lighter" $elevationName="xxs" $zIndex={1}>
      <MaxWidth640px
        $align="center"
        $direction="row"
        $gap={SPACING_MD}
        $justify="space-between"
        $px={SPACING_XL}
        $py={SPACING_MD}
      >
        <Button
          kind="tertiary"
          onClick={() => {
            goPrevious()
            trackClick('clinical-test.step.question.previous', { path: current.path })
          }}
          type="button"
        >
          <Icon Svg={ArrowLeft} size={12} />
          <Translation id="actions.goBack" />
        </Button>

        {current.isSubmitStep ? (
          <Button
            disabled={nextDisabled}
            kind="primary"
            onClick={() => {
              onNext()
              trackClick('clinical-test.step.question.send')
            }}
            type="submit"
          >
            <Translation id="actions.send" />
          </Button>
        ) : (
          <Button
            disabled={nextDisabled}
            kind="primary"
            onClick={() => {
              onNext()
              trackClick('clinical-test.step.question.next', { path: current.path })
            }}
            type="submit"
          >
            <Translation id="actions.goNext" />
            <Icon Svg={ArrowRight} size={12} />
          </Button>
        )}
      </MaxWidth640px>
    </Flex>
  )
}
