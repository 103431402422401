import { Flex, PositionSticky } from 'cdk'
import { ArrowLeft, Icon } from 'icons'
import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Card, Text } from 'ui'
import { CenteredLoader } from '~/components/CenteredLoader'
import { TertiaryButtonIconOnly } from '~/components/TertiaryButton/TertiaryButtonIconOnly'
import { Translation } from '~/components/Translation'
import { TherapistCalendarStyle } from '~/domains/availabilities/calendar/components/TherapistCalendarStyle'
import { TherapistCalendarProvider } from '~/domains/availabilities/calendar/hooks/useTherapistCalendar'
import { useTherapyIdLocationParam } from '~/domains/changeTherapist/hooks/useTherapyIdLocationParam'
import { GenericErrorContent } from '~/domains/error/GenericError'
import { useTherapistTherapies } from '~/domains/therapies/TherapistTherapiesProvider'
import { TherapySessionScheduleView } from '~/domains/therapy-session/schedule/View'
import { useTherapistAgendaByPatientId } from '~/hooks/useTherapistAgendaByPatientId'

const PatientCalendarStyle = styled(Flex)`
  ${TherapistCalendarStyle} {
    ${Card} {
      border: none;
    }

    .calendar-weekly-hours {
      display: none;
    }
  }
`

type Props = {
  patientId: string
}

export const PatientDetailCalendar = ({ patientId }: Props) => {
  const history = useHistory()

  const { refetch } = useTherapistAgendaByPatientId(patientId)

  const therapyId = useTherapyIdLocationParam()

  const { loading, error, therapies } = useTherapistTherapies()

  const therapy = useMemo(() => therapies.find((therapy) => therapy.id === therapyId), [therapies, therapyId])

  const onError = useCallback(() => {
    history.goBack()
  }, [history])

  const handleGoBack = useCallback(() => {
    refetch()

    history.goBack()
  }, [history, refetch])

  if (loading) {
    return <CenteredLoader />
  }

  if (error || !therapy || !therapy.patient) {
    return <GenericErrorContent />
  }

  const selectedTherapy = {
    ...therapy,
    patient: {
      id: therapy.patient.id,
      fullName: therapy.patient.fullName,
    },
  }

  return (
    <PatientCalendarStyle>
      <PositionSticky
        $backgroundColorName="lighter"
        $direction="row"
        $elevationName="xxs"
        $left={0}
        $pl={16}
        $py={12}
        $right={0}
        $top={0}
        $zIndex={9}
      >
        <TertiaryButtonIconOnly onClick={handleGoBack}>
          <Icon Svg={ArrowLeft} size={24} />
        </TertiaryButtonIconOnly>
        <Flex $align="center" $grow={1} $justify="center">
          <Text fontWeight="600" kind="paragraph">
            <Translation id="therapySession.therapist.scheduleNextSession" />
          </Text>
        </Flex>
      </PositionSticky>
      <TherapistCalendarProvider onError={onError} overrideDefaultView="day">
        <TherapySessionScheduleView onScheduled={handleGoBack} therapy={selectedTherapy} />
      </TherapistCalendarProvider>
    </PatientCalendarStyle>
  )
}
