import { useMemo } from 'react'
import { type EventWrapperProps } from 'react-big-calendar'
import { type CalendarEvent } from '../types'
import { TherapistCalendarEventAvailable } from './TherapistCalendarEventAvailable'
import { TherapistCalendarEventTherapySession } from './TherapistCalendarEventTherapySession'
import { TherapistCalendarEventUnavailable } from './TherapistCalendarEventUnavailable'
import { TherapistCalendarEventWeekly } from './TherapistCalendarEventWeekly'
import { type TherapistCalendarEventWrapperStyle } from './TherapistCalendarEventWrapper'

type Props = EventWrapperProps<CalendarEvent>

export const TherapistCalendarEvent = ({ event, onClick, style }: Props) => {
  const wrapperStyle = useMemo(
    (): TherapistCalendarEventWrapperStyle => ({
      ...style,
      height: String(style?.height || '').replace('2px', '1px'),
      left: '0px',
      top: `${style?.top}%`,
      width: '100%',
      xOffset: style?.xOffset || 0,
    }),
    [style],
  )

  if (event.resource.type === 'WEEKLY') {
    return <TherapistCalendarEventWeekly style={wrapperStyle} />
  }

  if (event.resource.available) {
    return <TherapistCalendarEventAvailable event={event} onClick={onClick} style={wrapperStyle} />
  }

  if (event.resource.type === 'SPOT') {
    return <TherapistCalendarEventUnavailable event={event} onClick={onClick} style={wrapperStyle} />
  }

  if (event.resource.type === 'THERAPY_SESSION') {
    return <TherapistCalendarEventTherapySession event={event} onClick={onClick} style={wrapperStyle} />
  }

  return null
}
