import { secondsToMinutes } from 'date-fns'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useSuspenseTherapyPathByIdQuery } from '~/domains/reservation/hooks/useTherapyPathByIdQuery'
import { getTherapySessionDurationByTherapyPath } from '~/utils/getTherapySessionDurationByTherapyPath'

export const ReservationDurationText = () => {
  const { therapyPath } = useSuspenseTherapyPathByIdQuery()
  const { onboardingTherapySessionDuration, firstTherapySessionDuration } = getTherapySessionDurationByTherapyPath(
    therapyPath.type,
  )

  return (
    <TranslationMarkdown
      colorName="neutral-70"
      id="reservation.bookBySuggestedTherapist.duration"
      kind="caption"
      values={{
        minutes: secondsToMinutes(onboardingTherapySessionDuration ?? firstTherapySessionDuration),
      }}
    />
  )
}
