import { parseISO } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { useMemo } from 'react'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useChatMessage } from '~/domains/chat/hooks/useChatMessage'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { getTherapySessionDayAndMonthLong } from '~/utils/dates/getTherapySessionDayAndMonth'
import { getTherapySessionStartAt } from '~/utils/dates/getTherapySessionStartAt'
import { CustomMessageWrapper } from './CustomMessageWrapper'

const SessionRescheduledByOthersPatient = () => {
  const { message } = useChatMessage<'SYSTEM_SESSION_RESCHEDULED_BY_OTHERS'>()

  const startAt = message.srns?.payload.startAt
  const previousStartAt = message.srns?.payload.previousStartAt
  const therapistId = message.srns?.payload.therapistId

  const { therapies } = usePatientTherapies()

  const therapy = useMemo(
    () => therapies.find((therapy) => therapy.therapist?.id === therapistId),
    [therapies, therapistId],
  )

  if (!startAt || !previousStartAt || !therapy?.therapist) {
    return null
  }

  return (
    <>
      <Flex pb={8}>
        <Text fontWeight="600" kind="caption">
          <Translation
            id="systemMessage.patient.sessionRescheduledByOthers.title"
            values={{ therapistFullName: therapy.therapist.fullName }}
          />
        </Text>
      </Flex>
      <TranslationMarkdown
        id="systemMessage.patient.sessionRescheduledByOthers.body"
        kind="caption"
        values={{
          therapySessionsStartAtTime: pipe(startAt, parseISO, getTherapySessionStartAt),
          therapySessionsStartAtDay: pipe(startAt, parseISO, getTherapySessionDayAndMonthLong),
          therapySessionsPreviousStartAtTime: pipe(previousStartAt, parseISO, getTherapySessionStartAt),
          therapySessionsPreviousStartAtDay: pipe(previousStartAt, parseISO, getTherapySessionDayAndMonthLong),
        }}
      />
    </>
  )
}

const SessionRescheduledByOthersTherapist = () => {
  const { message } = useChatMessage<'SYSTEM_SESSION_RESCHEDULED_BY_OTHERS'>()

  const startAt = message.srns?.payload.startAt
  const previousStartAt = message.srns?.payload.previousStartAt

  if (!startAt || !previousStartAt) {
    return null
  }

  return (
    <>
      <Flex pb={8}>
        <Text fontWeight="600" kind="caption">
          <Translation id="systemMessage.therapist.sessionRescheduledByOthers.title" />
        </Text>
      </Flex>
      <TranslationMarkdown
        id="systemMessage.therapist.sessionRescheduledByOthers.body"
        kind="caption"
        values={{
          therapySessionsStartAtTime: pipe(startAt, parseISO, getTherapySessionStartAt),
          therapySessionsStartAtDay: pipe(startAt, parseISO, getTherapySessionDayAndMonthLong),
          therapySessionsPreviousStartAtTime: pipe(previousStartAt, parseISO, getTherapySessionStartAt),
          therapySessionsPreviousStartAtDay: pipe(previousStartAt, parseISO, getTherapySessionDayAndMonthLong),
        }}
      />
    </>
  )
}

export const MessageSessionRescheduledByOthers = () => {
  const group = useUserActingAsGroup()

  return (
    <CustomMessageWrapper>
      {group === 'therapist' ? <SessionRescheduledByOthersTherapist /> : <SessionRescheduledByOthersPatient />}
    </CustomMessageWrapper>
  )
}
