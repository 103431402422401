import { addSeconds } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { noop } from 'functions'
import { useCallback, useState } from 'react'
import { type SlotInfo } from 'react-big-calendar'
import { CenteredLoader } from '~/components/CenteredLoader'
import { TherapistCalendar } from '~/domains/availabilities/calendar/components/TherapistCalendar'
import { type CalendarEventWithNonNullableTherapySession } from '~/domains/availabilities/calendar/types'
import { isCalendarEventPast } from '~/domains/availabilities/calendar/utils/isCalendarEventPast'
import { parseEndAndStartFromCalendar } from '~/domains/availabilities/calendar/utils/parseEndAndStartFromCalendar'
import { useModals } from '~/domains/modals'
import { useTherapistAgendaByPatientIdForBookByParent } from '~/hooks/useTherapistAgendaByPatientIdForBookByParent'
import { type TherapistTherapiesQuery } from '~/types/graphql'
import { getTherapySessionAvailabilityDurationInSeconds } from '~/utils/getTherapySessionAvailabilityDurationInSeconds'
import { BookTherapySessionByParentModals } from '../components/BookTherapySessionByParentModals'
import { useTherapySessionFindManyRecoverableByTherapyId } from '../hooks/useTherapySessionFindManyRecoverableByTherapyId'

type Props = {
  therapy: Omit<TherapistTherapiesQuery['therapies'][number], 'patient'> & { patient: { id: string; fullName: string } }
  onScheduled: () => void
}

export const TherapySessionScheduleView = ({ therapy, onScheduled }: Props) => {
  const [slot, setSlot] = useState<CalendarEventWithNonNullableTherapySession | null>(null)

  const { recoverableTherapySession } = useTherapySessionFindManyRecoverableByTherapyId({
    therapyId: therapy.id,
  })

  const { loading, therapySession } = useTherapistAgendaByPatientIdForBookByParent(therapy.patient.id)

  const { open } = useModals()

  const onClose = useCallback(() => {
    setSlot(null)
  }, [])

  const onSelectSlot = useCallback(
    (payload: SlotInfo) => {
      const { end, start } = parseEndAndStartFromCalendar(payload)

      if (isCalendarEventPast({ end, start })) {
        return
      }

      if (!therapySession || !therapySession.therapyPathType) {
        return
      }

      const therapySessionDuration = getTherapySessionAvailabilityDurationInSeconds(therapySession.therapyPathType)

      setSlot({
        end: pipe(start, addSeconds(therapySessionDuration)),
        resource: {
          available: false,
          id: therapySession.id,
          therapySession: {
            id: therapySession.id,
            patient: therapySession.patient,
            status: therapySession.status,
            therapyPathType: therapySession.therapyPathType,
            typeCost: therapySession.typeCost,
          },
          type: 'THERAPY_SESSION',
        },
        start,
      })

      open(`bookTherapySessionByParent:${therapy.id}`)
    },
    [open, therapy.id, therapySession],
  )

  if (loading) {
    return <CenteredLoader />
  }

  return (
    <>
      <TherapistCalendar onSelectEvent={noop} onSelectSlot={onSelectSlot} />

      {!!slot && (
        <BookTherapySessionByParentModals
          onClose={onClose}
          onScheduled={onScheduled}
          recoverableTherapySession={recoverableTherapySession}
          slot={slot}
          therapyId={therapy.id}
        />
      )}
    </>
  )
}
