import { createSoundDetector, useCallStateHooks, useConnectedUser, VideoPreview } from '@stream-io/video-react-sdk'
import { noop } from 'functions'
import { type PropsWithChildren, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Avatar } from 'ui'
import { Flex, PositionRelative } from 'ui-deprecated'
import { CenteredLoader } from '~/components/CenteredLoader'

const VideoContainer = styled(Flex)`
  width: 100%;
  aspect-ratio: 16/9;
`

const DisabledVideoPreview = () => {
  const connectedUser = useConnectedUser()

  if (!connectedUser) {
    return null
  }

  return (
    <VideoContainer align="center" grow={1} justify="center" shrink={1}>
      <Avatar image={connectedUser.image} name={connectedUser.name} size="lg" />
    </VideoContainer>
  )
}

const StartingCameraPreview = () => (
  <VideoContainer align="center" grow={1} justify="center" shrink={1}>
    <CenteredLoader />
  </VideoContainer>
)

export const CurrentUserCamera = ({ children }: PropsWithChildren) => {
  const { useMicrophoneState } = useCallStateHooks()
  const { isEnabled, mediaStream } = useMicrophoneState()

  const [isSpeech, setIsSpeech] = useState(false)

  useEffect(() => {
    if (!isEnabled || !mediaStream) {
      return
    }

    const disposeSoundDetector = createSoundDetector(
      mediaStream,
      ({ isSoundDetected }) => setIsSpeech(isSoundDetected),
      {
        detectionFrequencyInMs: 80,
        destroyStreamOnStop: false,
      },
    )

    return () => {
      disposeSoundDetector().catch(noop)
    }
  }, [isEnabled, mediaStream])

  return (
    <PositionRelative grow={1} justify="center" shrink={1}>
      <VideoPreview
        DisabledVideoPreview={DisabledVideoPreview}
        NoCameraPreview={DisabledVideoPreview}
        StartingCameraPreview={StartingCameraPreview}
        className={isSpeech ? 'str-video__participant-view--speaking' : ''}
      />
      {children}
    </PositionRelative>
  )
}
