import { Flex } from 'cdk'
import { SPACING_SM } from 'design-tokens'
import { Icon, LogOut } from 'icons'
import { memo } from 'react'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useAuthMethods } from '~/domains/auth/hooks/useAuthMethods'
import { MenuAction } from '../common/MenuAction'

export const MenuItemLogout = memo(() => {
  const { signOut } = useAuthMethods()

  return (
    <MenuAction dataTestId="menu-logout" onClick={signOut}>
      <Flex $pr={SPACING_SM}>
        <Icon Svg={LogOut} colorName="error" size={24} />
      </Flex>

      <Flex $align="flex-start" $grow={1} $shrink={1}>
        <Text colorName="error" fontWeight="500" kind="paragraph">
          <Translation id="menu.logout" />
        </Text>
      </Flex>
    </MenuAction>
  )
})

MenuItemLogout.displayName = 'MenuItemLogout'
