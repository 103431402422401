import { type PropsWithChildren } from 'react'
import { Redirect } from 'react-router-dom'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { getRoute } from '~/utils/getRoute'

type HomeGuardProps = PropsWithChildren

export const HomeGuard = ({ children }: HomeGuardProps) => {
  const isPatient = useUserActingAsGroup() === 'patient'

  if (isPatient) {
    return <Redirect to={getRoute('/paths')} />
  }

  return children
}
