import { Button } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useReactHookFormContext } from '~/domains/react-hook-form'

export const NextButton = () => {
  const form = useReactHookFormContext()

  return (
    <Button
      disabled={!form.formState.isValid}
      form="technicalFeedback"
      kind="primary"
      loading={form.formState.isSubmitting}
      type="submit"
    >
      <Translation id="actions.continue" />
    </Button>
  )
}
