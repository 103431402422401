import { Flex } from 'cdk'
import { addMinutes, isBefore, isWithinInterval, subMinutes } from 'date-fns'
import { type PropsWithChildren, useMemo } from 'react'
import { matchPath } from 'react-router-dom'
import { Modal, ModalProvider, ModalTitle, useModalContext } from 'ui'
import { Translation } from '~/components/Translation'
import {
  THERAPY_SESSION_JOINING_TIME_WINDOW_AFTER_END_AT_IN_MINUTES,
  THERAPY_SESSION_JOINING_TIME_WINDOW_BEFORE_START_AT_IN_MINUTES,
} from '~/constants'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { AppointmentCard, PayOrJoinAppointmentButton } from '~/domains/paths'
import { usePatientAgenda } from '~/hooks/usePatientAgenda'
import { useRootLocation } from '~/hooks/useRootHistory'
import { type TherapySessionStatus } from '~/types/graphql'
import { getRoutes } from '~/utils/getRoutes'

type UpcomingAppointmentModalActionsProps = {
  price: number
  status: TherapySessionStatus
  therapySessionId: string
}

const UpcomingAppointmentModalActions = ({ price, status, therapySessionId }: UpcomingAppointmentModalActionsProps) => {
  const { close } = useModalContext()

  const shouldCloseModal = !['PAYMENT_FAILED', 'PAYMENT_FAILED_FIRST_ATTEMPT'].includes(status)

  return (
    <Flex $grow={1} $shrink={1}>
      <PayOrJoinAppointmentButton
        onClick={shouldCloseModal ? close : undefined}
        price={price}
        status={status}
        therapySessionId={therapySessionId}
      />
    </Flex>
  )
}

const UpcomingAppointmentModalContent = () => {
  const { agenda, loading } = usePatientAgenda()

  const upcomingTherapySession = useMemo(
    () =>
      (agenda?.nextTherapySessions ?? []).find(({ endAt, startAt }) =>
        isWithinInterval(new Date(), {
          end: addMinutes(endAt, THERAPY_SESSION_JOINING_TIME_WINDOW_AFTER_END_AT_IN_MINUTES),
          start: subMinutes(startAt, THERAPY_SESSION_JOINING_TIME_WINDOW_BEFORE_START_AT_IN_MINUTES),
        }),
      ),
    [agenda?.nextTherapySessions],
  )

  if (loading || upcomingTherapySession == null) {
    return null
  }

  const { cost, endAt, id, startAt, status, therapyPathType } = upcomingTherapySession

  return (
    <ModalProvider initialIsOpen>
      <Modal>
        <Flex $gap={16}>
          <ModalTitle data-test-id="upcoming-appointment-modal-title">
            <Translation
              id={isBefore(new Date(), startAt) ? 'therapySession.upcoming.title' : 'therapySession.ongoing.title'}
            />
          </ModalTitle>
          <AppointmentCard
            endAt={endAt}
            price={cost ?? 0}
            startAt={startAt}
            therapyPath={therapyPathType ?? 'MYSELF_PSYCHOTHERAPY'}
          />
          <UpcomingAppointmentModalActions price={cost ?? 0} status={status} therapySessionId={id} />
        </Flex>
      </Modal>
    </ModalProvider>
  )
}

type UpcomingAppointmentModalGuardProps = PropsWithChildren

const UpcomingAppointmentModalGuard = ({ children }: UpcomingAppointmentModalGuardProps) => {
  const location = useRootLocation()
  const { isAuthenticated } = useAuthState()
  const isPatient = useUserActingAsGroup() === 'patient'

  const isVideocallScreens = matchPath(location.pathname, {
    path: getRoutes(
      '/videocall/troubleshooting',
      '/therapy-session/:therapySessionId/live',
      '/therapy-session/:therapySessionId/live/external',
    ),
  })

  if (isVideocallScreens || !isPatient || !isAuthenticated) {
    return null
  }

  return children
}

export const UpcomingAppointmentModal = () => (
  <UpcomingAppointmentModalGuard>
    <UpcomingAppointmentModalContent />
  </UpcomingAppointmentModalGuard>
)
