import { Flex, PositionRelative, Pressable } from 'cdk'
import { OPACITY_0, OPACITY_100, SPACING_3XS, SPACING_SM } from 'design-tokens'
import { IconSerenisMenu, IconSerenisMenuSolid } from 'icons'
import { memo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useTabNavigationMenu } from '~/domains/navigation/NewNavigation'
import { getRoutes } from '~/utils/getRoutes'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { type NavigationProps } from '../../types'
import { FlexWithTransition } from '../common/FlexWithTransition'
import { NavLinkContainer } from '../common/NavLinkContainer'
import { UnreadDot } from '../common/UnreadDot'

/**
 * TODO (amin-khayam)
 * - remove this component when the new page /settings is ready.
 * - add this link to patientNavigationLinks and therapistNavigationLinks
 */

export const LinkMenu = memo(({ extended, isMobile = false }: NavigationProps) => {
  const { pathname } = useLocation()

  const active = !!matchPath(pathname, {
    path: getRoutes(
      '/historical-therapy-sessions',
      '/invoices',
      '/notifications',
      '/notifications/journaling',
      '/plan',
      '/settings/account',
      '/settings/billing',
      '/settings/invite',
      '/settings/payments',
    ),
  })

  const unreadDotSubmitFeedbackSeen = getLocalStorage('unread-dot-submit-feedback-seen') === 'true'
  const unreadDotChangelogSeen = getLocalStorage('unread-dot-changelog-seen') === 'true'

  const { open, toggleMenu } = useTabNavigationMenu()

  return (
    <Flex data-test-id="tab-item-settings">
      <Pressable onClick={toggleMenu}>
        <NavLinkContainer
          $direction={isMobile ? 'column' : 'row'}
          $gap={isMobile ? SPACING_3XS : SPACING_SM}
          $isMobile={isMobile}
        >
          <Flex>
            <PositionRelative>
              {active || open ? (
                <IconSerenisMenuSolid colorName="primary-50" size={24} />
              ) : (
                <IconSerenisMenu colorName="neutral-110" size={24} />
              )}
              {(!unreadDotSubmitFeedbackSeen || !unreadDotChangelogSeen) && <UnreadDot />}
            </PositionRelative>
          </Flex>
          {!isMobile ? (
            <FlexWithTransition $opacity={extended ? OPACITY_100 : OPACITY_0}>
              <Text
                colorName={active || open ? 'primary-50' : 'neutral-110'}
                kind={active || open ? 'paragraph-strong' : 'paragraph'}
              >
                <Translation id="menu.menu" />
              </Text>
            </FlexWithTransition>
          ) : (
            <Text colorName={active ? 'primary-50' : 'neutral-110'} kind="micro">
              <Translation id="menu.menu" />
            </Text>
          )}
        </NavLinkContainer>
      </Pressable>
    </Flex>
  )
})
