import {
  SerenisChat,
  SerenisChatSolid,
  SerenisJournaling,
  SerenisJournalingSolid,
  SerenisTherapy,
  SerenisTherapySolid,
  SerenisTickets,
  SerenisTicketsSolid,
} from 'icons'
import { type NavigationLink } from '../types'

const CHAT: NavigationLink = {
  id: 'chat',
  dataTestId: 'tab-item-chat',
  route: '/chat',
  translation: 'menu.chat',
  Icon: SerenisChat,
  IconActive: SerenisChatSolid,
  pathMatches: ['/chat'],
  exact: false,
}

const DIARIES: NavigationLink = {
  id: 'diaries',
  dataTestId: 'tab-item-diaries',
  route: '/diaries',
  translation: 'menu.diaries',
  Icon: SerenisJournaling,
  IconActive: SerenisJournalingSolid,
  pathMatches: ['/diaries', '/journaling', '/food-journal'],
  exact: false,
}

const PATHS: NavigationLink = {
  id: 'therapies',
  dataTestId: 'tab-item-paths',
  route: '/paths',
  translation: 'menu.therapies',
  Icon: SerenisTherapy,
  IconActive: SerenisTherapySolid,
  pathMatches: ['/paths'],
  exact: false,
}

const INVITE: NavigationLink = {
  id: 'invite',
  dataTestId: 'tab-item-invite',
  route: '/invite',
  translation: 'menu.invite',
  Icon: SerenisTickets,
  IconActive: SerenisTicketsSolid,
  pathMatches: ['/invite'],
  exact: false,
}

// Don't change the order of the links

export const usePatientNavigationLinks = (): NavigationLink[] => [PATHS, CHAT, INVITE, DIARIES]
