import { Flex, Form } from 'cdk'
import { useHistory } from 'react-router-dom'
import { Button, DrawerFooter, DrawerHeader, DrawerTitle } from 'ui'
import { Translation } from '~/components/Translation'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { ReactHookFormRadioFieldVariant } from '~/domains/react-hook-form/components/ReactHookFormRadioFieldVariant'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { type TranslationId } from '~/i18n/types'
import { type ManageSessionDeletedType, type ManageSessionDeleteFormValues } from '../types'
import { getManageSessionRoutes } from '../utils/getManageSessionRoutes'

type Option = {
  id: Extract<TranslationId, `therapySession.delete.${string}`>
  value: ManageSessionDeletedType
}

const DELETE_OPTIONS: Option[] = [
  { id: 'therapySession.delete.singleTherapySession', value: 'DELETE_SINGLE_THERAPY_SESSION' },
  { id: 'therapySession.delete.batchRecurrencyTherapySessions', value: 'DELETE_BATCH_RECURRENCY_THERAPY_SESSIONS' },
]

export const DeleteSessionRecurrency = () => {
  const { errors } = useFormTranslations()
  const form = useReactHookFormContext<ManageSessionDeleteFormValues>()
  const history = useHistory()

  return (
    <Form
      $gap={16}
      $grow={1}
      $shrink={1}
      onSubmit={form.handleSubmit(() => {
        history.push(getManageSessionRoutes('/delete'))
      })}
    >
      <DrawerHeader />
      <DrawerTitle>
        <Translation id="therapySession.delete.optionsModal.message.title" />
      </DrawerTitle>
      <Flex $gap={8}>
        {DELETE_OPTIONS.map(({ id, value }) => (
          <ReactHookFormRadioFieldVariant
            key={id}
            name="deletedType"
            rules={{ required: errors.required }}
            value={value}
          >
            <Translation id={id} />
          </ReactHookFormRadioFieldVariant>
        ))}
      </Flex>
      <DrawerFooter>
        <Flex $gap={8} $grow={1} $mdDirection="row-reverse" $shrink={1}>
          <Flex $basis="50%" $grow={1} $shrink={1}>
            <Button kind="primary" type="submit">
              <Translation id="actions.proceed" />
            </Button>
          </Flex>
          <Flex $basis="50%" $grow={1} $shrink={1}>
            <Button isGhost kind="primary" onClick={history.goBack}>
              <Translation id="actions.back" />
            </Button>
          </Flex>
        </Flex>
      </DrawerFooter>
    </Form>
  )
}
