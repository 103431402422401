import { useInbox } from '@trycourier/react-inbox'
import { CourierProvider } from '@trycourier/react-provider'
import { type PropsWithChildren, useEffect } from 'react'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { getEnv } from '~/utils/getEnv'
import { useCourierToken } from './useCourierToken'

const CourierDataEffect = () => {
  const { fetchMessages, getUnreadMessageCount } = useInbox()

  useEffect(() => {
    fetchMessages()
    getUnreadMessageCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

type NotificationsProviderProps = PropsWithChildren

export const NotificationsProvider = ({ children }: NotificationsProviderProps) => {
  const { id: userId } = useCurrentUser()
  const { token } = useCourierToken()

  if (!token) {
    return null
  }

  return (
    <CourierProvider authorization={token} clientKey={getEnv('COURIER_CLIENT_KEY')} userId={userId}>
      <CourierDataEffect />
      {children}
    </CourierProvider>
  )
}
