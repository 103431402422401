import { useEffect } from 'react'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { useCurrentUserNullable } from '~/hooks/useCurrentUser'
import { useLanguage } from '~/i18n/hooks/useLanguage'

export const useFeaturebaseIdentifyEffect = () => {
  const { company, createdAt, email, hashFb, firstName, fullName, id, lastName } = useCurrentUserNullable()

  const { language } = useLanguage()

  const group = useUserActingAsGroup()

  const { getAllFlags } = useFeatureFlagsByUserId()

  useEffect(() => {
    if (!id || !email || !firstName || !fullName || !createdAt) {
      return
    }

    try {
      if (!(typeof window.Featurebase === 'function')) {
        return
      }

      const flags = getAllFlags()

      window.Featurebase('identify', {
        companies: company?.id ? [company] : [],
        createdAt: createdAt.toISOString(),
        customFields: {
          ...flags,
          firstName,
          fullName,
          group,
          lastName,
        },
        email,
        userId: id,
        locale: language,
        name: group === 'therapist' ? fullName : firstName,
        organization: 'serenis',
        userHash: String(hashFb),
      })
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }, [company, createdAt, email, firstName, fullName, getAllFlags, group, hashFb, id, language, lastName])

  return null
}
