import { type PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Flex, media } from 'ui-deprecated'
import { useVideocallSidePanel } from '../../hooks/useVideocallSidePanel'
import { VideocallPatientDetail } from './contents/PatientDetail'
import { VideocallChat } from './contents/VideocallChat'

const FlexFixedWidth = styled(Flex).attrs({ mdBasis: '420px' })`
  ${media.medium`
    min-width: 475px;
    max-width: 475px;
  `}
`

const SidePanelContainer = ({ children }: PropsWithChildren) => (
  <FlexFixedWidth grow={1} mdP={16} shrink={1}>
    {children}
  </FlexFixedWidth>
)

export const SidePanelContent = () => {
  const { isChatOpen, isPatientDetailOpen } = useVideocallSidePanel()

  return (
    <>
      {isChatOpen && (
        <SidePanelContainer>
          <VideocallChat />
        </SidePanelContainer>
      )}
      {isPatientDetailOpen && (
        <SidePanelContainer>
          <VideocallPatientDetail />
        </SidePanelContainer>
      )}
    </>
  )
}
