'use client'

import { noop } from 'functions'
import { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'
import { FeaturebaseSurveyWidget } from '~/components/Featurebase/SurveyWidget'
import { useFeaturebaseIdentifyEffect } from '~/domains/analytics/hooks/useFeaturebaseIdentifyEffect'
import { useMixPanelIdentifyEffect } from '~/domains/analytics/hooks/useMixPanelIdentifyEffect'
import { TherapistAvailabilitiesGivenCountProvider } from '~/domains/availabilities/reminders/hooks/useTherapistAvailabilitiesGivenCount'
import { ChatProvider } from '~/domains/chat/hooks/useChat'
import { ChatUnreadCountProvider } from '~/domains/chat/hooks/useChatUnreadCount'
import { useChatUnreadCountListenerEffect } from '~/domains/chat/hooks/useChatUnreadCountListenerEffect'
import { ActivateConventionModal } from '~/domains/convention/components/ActivateConventionModal'
import { FeatureFlagsByFingerprint, FeatureFlagsByUserId } from '~/domains/featureFlags'
import { FormFlowsTextValuesProvider } from '~/domains/formFlow/hooks/useFormFlowTextValues'
import { useUpdateIntercomFeatureFlags } from '~/domains/intercom/hooks/useUpdateIntercomFeatureFlags'
import { Modals } from '~/domains/modals'
import { ModalsProvider } from '~/domains/modals/hooks/useModals'
import { NavigationProvider } from '~/domains/navigation/hooks/useNavigation'
import { ReferralActivationModal } from '~/domains/referral/components/ReferralActivationModal'
import { UpcomingAppointmentModal } from '~/domains/therapy-session/UpcomingAppointmentModal'
import { RootHistoryProvider } from '~/hooks/useRootHistory'
import { MainPageLayout } from '~/Layout/MainPageLayout'

const SetupEffect = () => {
  useChatUnreadCountListenerEffect()
  useMixPanelIdentifyEffect()
  useFeaturebaseIdentifyEffect()
  useUpdateIntercomFeatureFlags()

  return null
}

export default function App() {
  const [domLoaded, setDomLoaded] = useState(false)

  useEffect(() => {
    setDomLoaded(true)
  }, [])

  // @TODO: https://linear.app/serenis/issue/ENG-1599/remove-dom-loaded-effect-after-pages-are-all-migrated
  // Required to avoid /login loop, will be removed after all pages are migrated
  if (!domLoaded) {
    return null
  }

  return (
    <BrowserRouter getUserConfirmation={noop}>
      <CompatRouter>
        <RootHistoryProvider>
          <FormFlowsTextValuesProvider>
            <ModalsProvider>
              <Modals />
              <FeatureFlagsByFingerprint>
                <FeatureFlagsByUserId>
                  <ChatUnreadCountProvider>
                    <ChatProvider>
                      <TherapistAvailabilitiesGivenCountProvider>
                        <NavigationProvider>
                          <FeaturebaseSurveyWidget />
                          <SetupEffect />
                          <ActivateConventionModal />
                          <MainPageLayout />
                          <ReferralActivationModal />
                          <UpcomingAppointmentModal />
                        </NavigationProvider>
                      </TherapistAvailabilitiesGivenCountProvider>
                    </ChatProvider>
                  </ChatUnreadCountProvider>
                </FeatureFlagsByUserId>
              </FeatureFlagsByFingerprint>
            </ModalsProvider>
          </FormFlowsTextValuesProvider>
        </RootHistoryProvider>
      </CompatRouter>
    </BrowserRouter>
  )
}
