import { type PropsWithChildren } from 'react'
import { Redirect } from 'react-router-dom'
import { useTherapySessionLive } from '~/domains/videocall'
import { getRoute } from '~/utils/getRoute'

type Props = PropsWithChildren

export const TherapySessionLivePageGuard = ({ children }: Props) => {
  const therapySessionLive = useTherapySessionLive()

  if (therapySessionLive.provider !== 'HOUSE') {
    return <Redirect to={getRoute('/')} />
  }

  return <>{children}</>
}
