import { Flex } from 'cdk'
import { format } from 'date-fns'
import { FormatDateEnum } from 'dates'
import {
  BORDER_RADIUS_4XS,
  BORDER_RADIUS_CIRCLE,
  BORDER_RADIUS_MD,
  BORDER_WIDTH_1,
  COLOR_DARKER,
  COLOR_NEUTRAL_20,
  COLOR_NEUTRAL_30,
  COLOR_NEUTRAL_50,
  COLOR_NEUTRAL_60,
  COLOR_PRIMARY_50,
  type ColorName,
  SPACING_3XS,
  SPACING_4XS,
  SPACING_SM,
} from 'design-tokens'
import { useBreakpoints } from 'hooks'
import styled, { css } from 'styled-components'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { type TranslationId } from '~/i18n/types'
import { type FoodJournalMood } from '~/types/graphql'
import { Skeleton } from './Skeleton'

const formatDay = (date: Date) => format(date, FormatDateEnum.DAY_OF_MONTH)

const TRANSLATION_ID_BY_DAY: Record<number, TranslationId> = {
  0: 'nutrition.diary.day.sunday',
  1: 'nutrition.diary.day.monday',
  2: 'nutrition.diary.day.tuesday',
  3: 'nutrition.diary.day.wednesday',
  4: 'nutrition.diary.day.thursday',
  5: 'nutrition.diary.day.friday',
  6: 'nutrition.diary.day.saturday',
}

type WrapperProps = {
  $clickableIfSelected: boolean
  $disabled: boolean
  $isOutsideCurrentMonth: boolean
  $selected: boolean
}

const Wrapper = styled(Flex)<WrapperProps>`
  color: ${COLOR_DARKER};

  ${({ $clickableIfSelected, $selected }) =>
    (!$selected || $clickableIfSelected) &&
    css`
      &:hover {
        background-color: ${COLOR_NEUTRAL_20};
        cursor: pointer;
      }
    `}

  ${({ $disabled, $isOutsideCurrentMonth, $selected }) => {
    if ($selected && $disabled) {
      return css`
        border-color: ${COLOR_PRIMARY_50};
        background-color: ${COLOR_NEUTRAL_30};
      `
    }

    if ($selected) {
      return css`
        border-color: ${COLOR_PRIMARY_50};
        background-color: ${COLOR_NEUTRAL_20};
      `
    }

    if ($isOutsideCurrentMonth) {
      return css`
        background-color: ${COLOR_NEUTRAL_20};
        color: ${COLOR_NEUTRAL_50};
      `
    }

    if ($disabled) {
      return css`
        background-color: ${COLOR_NEUTRAL_30};
        color: ${COLOR_NEUTRAL_60};
      `
    }
  }}
`

const dayRecapTypeToColor = (type: FoodJournalMood | null): ColorName => {
  switch (type) {
    case 'EXCELLENT':
      return 'green-60'
    case 'AVERAGE':
      return 'yellow-60'
    case 'JUST_OK':
      return 'red-80'
    default:
      return 'neutral-60'
  }
}

type Props = {
  compactOnMobile?: boolean
  day: string
  disabled?: boolean
  isOutsideCurrentMonth?: boolean
  loading: boolean
  onClick: VoidFunction
  selected: boolean
  clickableIfSelected?: boolean
  type: FoodJournalMood | null
}

export const CalendarDay = ({
  clickableIfSelected = false,
  compactOnMobile = false,
  day,
  disabled = false,
  isOutsideCurrentMonth = false,
  loading,
  onClick,
  selected,
  type,
}: Props) => {
  const { isLarge } = useBreakpoints()

  const date = new Date(day)
  const isCompactContent = compactOnMobile && !isLarge

  return (
    <Wrapper
      $backgroundColorName="lighter"
      $basis={0}
      $borderColorName="neutral-30"
      $borderRadius={BORDER_RADIUS_MD}
      $borderSize={BORDER_WIDTH_1}
      $clickableIfSelected={clickableIfSelected}
      $disabled={disabled}
      $grow={1}
      $isOutsideCurrentMonth={isOutsideCurrentMonth}
      $justify="center"
      $p={SPACING_SM}
      $selected={selected}
      as="button"
      onClick={onClick}
      type="button"
    >
      <Text kind="public-h3-strong">{formatDay(date)}</Text>

      {!isCompactContent && (
        <Flex $pt={SPACING_4XS}>
          <Text kind="caption-strong">
            <Translation id={TRANSLATION_ID_BY_DAY[date.getDay()]} />
          </Text>
        </Flex>
      )}

      {loading && (
        <Flex $pt={SPACING_3XS}>
          <Skeleton
            $borderRadius={BORDER_RADIUS_4XS}
            $minHeight={16}
            data-test-id="food-journal-calendar-day-skeleton"
          />
        </Flex>
      )}

      {!loading && (
        <Flex $align="center" $direction="row" $justify="center" $minHeight={12} $pt={SPACING_3XS}>
          {disabled ? (
            <Flex $backgroundColorName="neutral-60" $minHeight={1} $minWidth={4} data-test-id="DISABLED" />
          ) : (
            <Flex
              $backgroundColorName={dayRecapTypeToColor(type)}
              $borderRadius={BORDER_RADIUS_CIRCLE}
              $minHeight={8}
              $minWidth={8}
              data-test-id={type ?? 'UNFILLED'}
            />
          )}
        </Flex>
      )}
    </Wrapper>
  )
}
