import { Form } from 'cdk'
import { type ReactNode } from 'react'
import { type SubmitHandler } from 'react-hook-form'
import { useReactHookFormContext } from '../react-hook-form'
import { type FirstSessionFeedback } from './types'

type Props = {
  onSubmit: SubmitHandler<FirstSessionFeedback>
  children: ReactNode
}

export const FirstSessionFeedbackForm = (props: Props) => {
  const form = useReactHookFormContext<FirstSessionFeedback>()
  return (
    <Form id="firstSessionFeedback" onSubmit={form.handleSubmit(props.onSubmit)}>
      {props.children}
    </Form>
  )
}
