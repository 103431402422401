import { OwnCapability, useBackgroundFilters, useRequestPermission } from '@stream-io/video-react-sdk'
import { Flex } from 'ui-deprecated'
import { BackgroundFilterControl } from './BackgroundFilterControl'
import { CameraControl } from './CameraControl'
import { CloseVideocall } from './CloseVideocall'
import { MicrophoneControl } from './MicrophoneControl'
import { ScreenShareControl } from './ScreenShareControl'
import { SpeakerControl } from './SpeakerControl'

export const VideocallControls = () => {
  const { hasPermission } = useRequestPermission(OwnCapability.SCREENSHARE)
  const { isSupported } = useBackgroundFilters()

  return (
    <Flex align="center" direction="row" justify="center" pb={8} pt={16} wrap="wrap">
      <Flex className="srns-microphone-button" mdPx={12} pb={8} px={4}>
        <MicrophoneControl />
      </Flex>
      <Flex className="srns-camera-button" mdPx={12} pb={8} px={4}>
        <CameraControl />
      </Flex>
      {navigator.mediaDevices && 'getDisplayMedia' in navigator.mediaDevices && hasPermission && (
        <Flex className="srns-screen-share-button" mdPx={12} pb={8} px={4}>
          <ScreenShareControl />
        </Flex>
      )}
      {isSupported && (
        <Flex className="srns-background-filter-button" mdPx={12} pb={8} px={4}>
          <BackgroundFilterControl />
        </Flex>
      )}
      <Flex className="srns-audio-output-button" mdPx={12} pb={8} px={4}>
        <SpeakerControl />
      </Flex>
      <Flex className="srns-close-videocall-button" mdPx={12} pb={8} px={4}>
        <CloseVideocall />
      </Flex>
    </Flex>
  )
}
