import { Flex, Space } from 'cdk'
import { Link } from 'react-router-dom'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useManageSession } from '../hooks/useManageSession'
import { getManageSessionRoutes } from '../utils/getManageSessionRoutes'

export const RescheduleSessionDeleteLink = () => {
  const { session } = useManageSession()

  return (
    <Flex $direction="row" $grow={1} $justify="center" $shrink={1}>
      <Text colorName="neutral-70">
        <Translation id="manageTherapySession.variant1.cannotReschedule" />
      </Text>
      <Space />
      <Link
        data-test-id="therapy-session-delete-link"
        to={session?.recurrency ? getManageSessionRoutes('/delete-recurrency') : getManageSessionRoutes('/delete')}
      >
        <Text kind="paragraph-strong">
          <Translation id="actions.erase" />
        </Text>
      </Link>
    </Flex>
  )
}
