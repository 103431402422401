import { gql, useMutation } from '@apollo/client'
import { type CreateBatchRecurrencyMutation, type CreateBatchRecurrencyMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation createBatchRecurrency($input: CreateBatchRecurrencyInput!) {
    createBatchRecurrency(input: $input) {
      id
    }
  }
`

export const useCreateBatchRecurrency = () =>
  useMutation<CreateBatchRecurrencyMutation, CreateBatchRecurrencyMutationVariables>(MUTATION)
