import React, { type ReactNode, useCallback, useState } from 'react'
import { MemoryRouter } from 'react-router-dom'
import { Modal, ModalProvider, ModalTrigger } from 'ui'
import { useModals } from '~/domains/modals'
import { useProductUserFeedbackCreate } from '~/domains/productUserFeedback/hooks/useProductUserFeedbackCreate'
import { useToasts } from '~/hooks/useToasts'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { ReactHookFormProvider, useReactHookForm } from '../react-hook-form'
import { FirstSessionFeedbackRoutes } from './FirstSessionFeedbackRoutes'
import { type FirstSessionFeedback } from './types'

type TechnicalFeedbackTriggerProps = {
  trigger: ReactNode
  therapyPathType: TherapyTherapyPathType | null
  therapySessionId: string
  therapyId: string
  onComplete: () => void
}

export const FirstSessionFeedbackTrigger = ({
  trigger,
  therapyPathType,
  therapySessionId,
  therapyId,
  onComplete,
}: TechnicalFeedbackTriggerProps) => {
  const { open } = useModals()
  const { productUserFeedbackCreate } = useProductUserFeedbackCreate('FIRST_SESSION_FEEDBACK', { therapySessionId })
  const [feedbackId, setFeedbackId] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const { addToast } = useToasts()

  const form = useReactHookForm<FirstSessionFeedback>({
    mode: 'all',
    defaultValues: {
      overallScore: 0,
      empathy: undefined,
      feedback: '',
    },
  })

  const handleClick = useCallback(async () => {
    setLoading(true)
    try {
      const response = await productUserFeedbackCreate()
      const id = response.data?.productUserFeedbackCreate?.id

      if (!id) {
        addToast({ translationId: 'generic.error', type: 'alert' })
        return
      }

      setFeedbackId(id)

      open('technicalFeedback')
    } catch {
      addToast({ translationId: 'generic.error', type: 'alert' })
    } finally {
      setLoading(false)
    }
  }, [productUserFeedbackCreate, addToast, open])

  return (
    <ModalProvider>
      <ModalTrigger>
        {React.cloneElement(trigger as React.ReactElement, {
          onClick: handleClick,
          loading,
        })}
      </ModalTrigger>

      <Modal>
        {feedbackId && (
          <ReactHookFormProvider {...form}>
            <MemoryRouter
              initialEntries={['/overall-score', '/4-to-5-score', '/1-to-3-score', '/with-empathy', '/without-empathy']}
              initialIndex={0}
            >
              <FirstSessionFeedbackRoutes
                firstSessionFeedbackId={feedbackId}
                onComplete={onComplete}
                therapyId={therapyId}
                therapyPathType={therapyPathType}
              />
            </MemoryRouter>
          </ReactHookFormProvider>
        )}
      </Modal>
    </ModalProvider>
  )
}
