import { Flex, MaxWidth640px } from 'cdk'
import { Button, Text } from 'ui'
import { PageRoute } from '~/components/PageRoute'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { StartTherapyPathIcon } from '~/domains/agenda/components/StartTherapyPath/components/StartTherapyPathIcon'
import { ChangeTherapistGhostButton } from '~/domains/changeTherapist/components/ChangeTherapistGhostButton'
import { Head } from '~/domains/reservation/components/Head'
import { useRootHistory } from '~/hooks/useRootHistory'
import { PageScrollEffect } from '~/routes/PageScrollEffect'
import { type TherapyTherapyPathType } from '~/types/graphql'
import { getRoute } from '~/utils/getRoute'
import { isChangeTherapistSupported } from '~/utils/therapyPaths'
import { mapTherapyAlreadyOngoingType } from '../utils/mapTherapyAlreadyOngoingType'

type Props = {
  therapyPathType?: TherapyTherapyPathType
  therapistFullName?: string
  therapistId?: string
  therapyId?: string
}

export const TherapyAlreadyOngoing = ({ therapyPathType, therapistFullName, therapistId, therapyId }: Props) => {
  const rootHistory = useRootHistory()

  if (!therapyPathType || !therapyId) {
    return null
  }

  const canChangeTherapist = isChangeTherapistSupported(therapyPathType)

  return (
    <>
      <PageScrollEffect />

      <Head translationId="reservation.therapyAlreadyOngoing" />

      <PageRoute id="therapy-already-ongoing">
        <MaxWidth640px $align="stretch" $grow={1} $justify="center" $p={24}>
          <Flex $align="center" $pb={8}>
            <StartTherapyPathIcon size={80} therapyPathType={therapyPathType} />
          </Flex>

          <Text fontWeight="600" kind="h1" textAlign="center">
            <Translation id={mapTherapyAlreadyOngoingType[therapyPathType].title} />
          </Text>

          <Flex $pt={16}>
            {therapistFullName ? (
              <TranslationMarkdown
                id="reservation.therapyAlreadyOngoing.description.1"
                kind="paragraph"
                textAlign="center"
                values={{ therapistFullName }}
              />
            ) : (
              <Text kind="paragraph" textAlign="center">
                <Translation id="reservation.therapyAlreadyOngoing.description.1.withoutTherapistFullName" />
              </Text>
            )}

            {canChangeTherapist && (
              <Flex $pt={32}>
                <Text kind="paragraph" textAlign="center">
                  <Translation id="reservation.therapyAlreadyOngoing.description.2" />
                </Text>
              </Flex>
            )}
          </Flex>

          <Flex $gap={8} $pt={24}>
            <Button
              kind="primary"
              onClick={() => {
                if (!therapistId) {
                  return null
                }

                rootHistory.push(getRoute(`/chat/${therapistId}`))
              }}
              type="submit"
            >
              <Translation id="actions.goToChat" />
            </Button>

            {canChangeTherapist && <ChangeTherapistGhostButton therapyId={therapyId} />}
          </Flex>
        </MaxWidth640px>
      </PageRoute>
    </>
  )
}
