import { ErrorFromResponse, useCall } from '@stream-io/video-react-sdk'
import { useCallback, useRef, useState } from 'react'
import { Button } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useVideocallEventLogger } from '~/domains/videocall'
import { useToasts } from '~/hooks/useToasts'
import { useWaitingRoom } from '../../../../providers/WaitingRoomProvider'

const MAX_JOIN_ATTEMPTS = 3

export const JoinButton = () => {
  const call = useCall()
  const status = useWaitingRoom()
  const attempts = useRef(0)
  const { addToast } = useToasts()
  const logFailure = useVideocallEventLogger()
  const [isJoining, setIsJoining] = useState(false)

  const handleJoinClick = useCallback(async () => {
    if (!call) {
      return
    }

    attempts.current += 1
    setIsJoining(true)

    try {
      await call.join()
    } catch (error) {
      const isRecoverable = !(error instanceof ErrorFromResponse && error.unrecoverable)

      if (isRecoverable && attempts.current < MAX_JOIN_ATTEMPTS) {
        handleJoinClick()
        return
      }

      attempts.current = 0
      addToast({ translationId: 'videocall.join.error', type: 'alert' })
      logFailure(isRecoverable ? 'videocall.call.join' : 'videocall.call.join.unrecoverable', error)
    }

    setIsJoining(false)
  }, [call, addToast, logFailure])

  return (
    <Button disabled={isJoining || !call} kind="primary" loading={isJoining} onClick={handleJoinClick}>
      <Translation id={status === 'PARTICIPANT_CAN_START' ? 'videocall.start' : 'videocall.join'} />
    </Button>
  )
}
