import { Space } from 'cdk'
import { parseISO } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { useMemo } from 'react'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useChatMessage } from '~/domains/chat/hooks/useChatMessage'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { type TranslationId } from '~/i18n/types'
import { getTherapySessionDayAndMonthLong } from '~/utils/dates/getTherapySessionDayAndMonth'
import { getTherapySessionStartAt } from '~/utils/dates/getTherapySessionStartAt'
import { CustomMessageWrapper } from './CustomMessageWrapper'

const FirstSessionBookedTherapist = () => {
  const { message } = useChatMessage<'SYSTEM_FIRST_SESSION_BOOKED'>()

  const startAt = message.srns?.payload.startAt
  /**
   * @description Need to migrate all chat messages to use therapyPath from therapy
   * @link https://linear.app/serenis/issue/PRD-2264/deprecate-therapy-path-column-on-therapy-sessions
   */
  const notes = message.srns?.payload.notes ?? null
  const therapyPath = message.srns?.payload.therapyPath
  const therapyPathTypeFromPayload = message.srns?.payload.therapyPathType

  const therapyPathType = therapyPathTypeFromPayload ?? therapyPath

  const titleTranslationId = useMemo((): TranslationId => {
    if (therapyPathType === 'PATH_ASSERTIVE_COMMUNICATION') {
      return 'systemMessage.therapist.firstBooking.title.assertiveCommunication'
    }

    if (therapyPathType === 'PATH_COACHING') {
      return 'systemMessage.therapist.firstBooking.title.coaching'
    }

    if (therapyPathType === 'PATH_SLEEP') {
      return 'systemMessage.therapist.firstBooking.title.sleep'
    }

    return 'systemMessage.therapist.firstBooking.title'
  }, [therapyPathType])

  if (!startAt) {
    return null
  }

  const bodyTranslationId = therapyPathType?.startsWith('PATH')
    ? 'systemMessage.therapist.firstBooking.bodyWithTherapyPath'
    : 'systemMessage.therapist.firstBooking.body'

  return (
    <>
      <Flex pb={8}>
        <Text fontWeight="600" kind="caption">
          <Translation id={titleTranslationId} values={{ therapyPath: String(therapyPathType) }} />
          <Space />
          🎯
        </Text>
      </Flex>
      <Flex>
        <Text fontWeight="400" kind="caption">
          <TranslationMarkdown
            id={bodyTranslationId}
            kind="paragraph"
            values={{
              therapySessionsStartAtTime: pipe(startAt, parseISO, getTherapySessionStartAt),
              therapySessionsStartAtDay: pipe(startAt, parseISO, getTherapySessionDayAndMonthLong),
              therapyPath: String(therapyPathType),
            }}
          />
        </Text>
        {!!notes && (
          <Flex pt={8}>
            <TranslationMarkdown
              id="systemMessage.therapist.firstBooking.notes"
              kind="paragraph"
              values={{
                notes,
              }}
            />
          </Flex>
        )}
      </Flex>
    </>
  )
}

const FirstSessionBookedPatient = () => {
  const { message } = useChatMessage<'SYSTEM_FIRST_SESSION_BOOKED'>()

  const { therapies } = usePatientTherapies()

  const startAt = message.srns?.payload.startAt
  const therapistId = message.srns?.payload.therapistId

  const therapy = useMemo(
    () => therapies.find((therapy) => therapy.therapist?.id === therapistId),
    [therapies, therapistId],
  )

  if (!therapy?.therapist || !startAt) {
    return null
  }

  return (
    <>
      <Flex pb={8}>
        <Text fontWeight="600" kind="caption">
          <Translation id="systemMessage.patient.firstBooking.title" />
        </Text>
      </Flex>
      <TranslationMarkdown
        id="systemMessage.patient.firstBooking.body"
        kind="caption"
        values={{
          therapySessionsStartAtTime: pipe(startAt, parseISO, getTherapySessionStartAt),
          therapySessionsStartAtDay: pipe(startAt, parseISO, getTherapySessionDayAndMonthLong),
          therapistFullName: therapy.therapist?.fullName,
        }}
      />
    </>
  )
}

export const MessageFirstSessionBooked = () => {
  const group = useUserActingAsGroup()

  return (
    <CustomMessageWrapper>
      {group === 'therapist' ? <FirstSessionBookedTherapist /> : <FirstSessionBookedPatient />}
    </CustomMessageWrapper>
  )
}
