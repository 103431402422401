import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useProductUserFeedbackAnswerUpsert } from '~/domains/productUserFeedback/hooks/useProductUserFeedbackAnswerUpsert'
import { ReactHookFormRating } from '~/domains/react-hook-form/fields/FieldRating'
import { useToasts } from '~/hooks/useToasts'
import { type ClinicalBondFormValues, type StepProps } from '../types'
import { getClinicalBondRoute } from '../utils/getClinicalBondRoute'
import { BackButton } from './BackButton'
import { ClinicalBondForm } from './ClinicalBondForm'
import { NextButton } from './NextButton'

export const ClinicalBondStepListen = ({ clinicalBondId, therapy }: StepProps) => {
  const history = useHistory()

  const { addToast } = useToasts()

  const isCoachingPath = therapy?.therapyPath?.type === 'PATH_COACHING'

  const { productUserFeedbackAnswerUpsert } = useProductUserFeedbackAnswerUpsert(clinicalBondId)

  const onSubmit = useCallback(
    async (values: ClinicalBondFormValues) => {
      try {
        const productUserFeedbackAnswerUpsertResponse = await productUserFeedbackAnswerUpsert({
          id: 'LISTEN',
          value: values.listen.toString(),
        })

        if (!productUserFeedbackAnswerUpsertResponse.data?.productUserFeedbackAnswerUpsert?.id) {
          addToast({
            translationId: 'reviews.askReviewModal.bad.unableToSaveResponse',
            type: 'alert',
          })

          return
        }

        history.push(getClinicalBondRoute('/management'))
      } catch (error) {
        addToast({
          translationId: 'reviews.askReviewModal.bad.unableToSaveResponse',
          type: 'alert',
        })
      }
    },
    [addToast, history, productUserFeedbackAnswerUpsert],
  )

  return (
    <ClinicalBondForm onSubmit={onSubmit}>
      <OverflowAuto>
        <Text colorName="grey-80" fontWeight="400" kind="caption">
          2/4
        </Text>
        <Flex pt={16}>
          <Text fontWeight="600" kind="h3">
            <Translation
              id={isCoachingPath ? 'clinicalBond.listen.coaching.title' : 'clinicalBond.listen.title'}
              values={{ therapistFullName: therapy?.therapist?.fullName || '' }}
            />
          </Text>
        </Flex>
        <Flex pt={24}>
          <ReactHookFormRating name="listen" rules={{ min: 1, max: 5 }} />
        </Flex>
      </OverflowAuto>
      <Flex pt={24}>
        <NextButton />
      </Flex>
      <Flex pt={8}>
        <BackButton />
      </Flex>
    </ClinicalBondForm>
  )
}
